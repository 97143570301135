import React, { useEffect, useState } from "react";
import { ModuleTitle } from "../../../components/module-title";
import { useAppContext } from "../../../components/activity/AppContext";
import Pagination from "../../../components/Pagination/pagination";
import Table from "../../../components/Table/table";
import ApplytoProject from "./applytoproject";
import { AssetActions } from "./asset.actions";
import CreateFile from "./createfile";
import "./index.css";
import styles from "./assets.module.css";
import SmallPopup from "../../../components/layouts/smallPopup";
import EditAssetFile from "./editAssetFile";
import { useLocation } from "react-router-dom";

function Assets({perm}) {
  const app = useAppContext();
  //const contextMenu = ["Apply to Project", "Delete", "Download"];
  const [displayRows, setDisplayRows] = useState({ offset: 0, limit: 10 });
  const [assets, setAssets] = useState([]);
  const [assetPopUp, setAssetPopUp] = useState({ popup: false, update: false });
  const [modifyAsset, setModifyAsset] = useState({
    type: "javascript",
    file: "",
  });
  // const [popUp, setPopUp] = useState(false);
  const [userDataCount, setUserDataCount] = useState(0);
  //const [item, setItem] = useState({});
  const [applyPopUp, setapplyPopUp] = useState({ show: false, asset: "" });
  const [editProject, setEditproject] = useState({ show: false, asset: "" });
  const [filter, setFilter] = useState({});
  const [headerColumns, setHeaderColumns] = useState([]);
  const [assetDelete, setAssetDelete] = useState({ name: "", delete: false });
  const location = useLocation();
  location.state = {permission:perm}

  const contextMenu = (item) => {
    if (item.type === "CSS" || item.type === "JS") {
      let cont = ["Apply to Project", "Delete", "Download", "Edit"]
      if(!location.state.permission?.canUpdate) cont = cont.filter((e)=>e!=="Edit" && e!=="Apply to Project")
      if(!location.state.permission?.canDelete) cont = cont.filter((e)=>e!=="Delete")
      return cont
    } else {
      let cont = ["Apply to Project", "Delete", "Download"]
      if(!location.state.permission?.canUpdate) cont = cont.filter((e)=>e!=="Apply to Project")
      if(!location.state.permission?.canDelete) cont = cont.filter((e)=>e!=="Delete")
      return cont
    }
  }

  const getAssetsMasterData = async () => {
    const actions = AssetActions(app());
    const [error, data] = await actions.getList(
      displayRows.limit,
      displayRows.offset,
      filter
    );
    if (!error) {
      // const [err, prjData] = await actions.getProjects()
      setUserDataCount(data?.noOfRecords);
      let tempAssets = [];
      let tempProjectsOptions = [];
      let tempLastModifiedBy = [];
      // let tempFileType = [];
      // if (!err) {
      //   // prjData.data.forEach(({name})=>tempProjectsOptions.push(name))
      // }
      for (let asset of data.data) {
        let tempFileSize = asset.size;
        if (tempFileSize < 1023) tempFileSize = asset.size + " B";
        else tempFileSize = Math.round(asset.size / 1024) + " KB";
        tempAssets.push({ ...asset, size: tempFileSize });
        // if (asset.projects)
        // if (!tempProjectsOptions.includes(asset.projects))
        // tempProjectsOptions.push(asset.projects);
        tempProjectsOptions = tempProjectsOptions.concat(asset.projects?.split(','))
        if (!tempLastModifiedBy.includes(asset.lastModifiedBy))
          tempLastModifiedBy.push(asset.lastModifiedBy);
        // if (!tempFileType.includes(asset.type)) tempFileType.push(asset.type);
      }
      if(!headerColumns.length){
        setHeaderColumns([
          { id: "name", name: "File Name", type: "input", width: "150px" },
          { id: "size", name: "Size", type: "input", width: "150px",disabled:true },
          {
            id: "type",
            name: "Type",
            // type: "select",
            type: "input",
            width: "150px",
            // value: tempFileType,
          },
          {
            id: "projects",
            name: "Used in",
            type: "select",
            // value: tempProjectsOptions,
            value: [...new Set(tempProjectsOptions)],
            width: "400px",
          },
          {
            id: "lastModifiedBy",
            name: "Last Modified By",
            type: "select",
            value: tempLastModifiedBy,
            width: "190px",
          },
          {
            id: "modifiedDate",
            name: "Last Modified On",
            type: "input",
            width: "190px",
          },
          { id: "moreVertIcon", type: "moreVertIcon", width: "64px" },
        ]);
      }
      
      setAssets(tempAssets);
    }
  };
  useEffect(() => {
    getAssetsMasterData();
  }, [displayRows, filter]);

  const onHeaderChange = (id, value) => {
    setFilter((val) => ({ ...val, [id]: value }));
  };

  const contextOnChange = (option, value) => {
    switch (option) {
      case "Create":
        setAssetPopUp({ popup: true, update: false });
        setModifyAsset({ type: "JS", file: "" });
        break;
      case "Apply to Project":
        setapplyPopUp({ show: true, asset: value });
        break;
      case "Delete":
        setAssetDelete({ name: value, delete: true });
        break;
      case "Download":
        download(value.name);
        break;
      case "Edit":
        setEditproject({
          show: true,
          asset: value,
        });
        break;
      case "Cancel":
        setAssetPopUp({ popup: false, update: false });
        setModifyAsset({ type: "JS", file: "" });
        break;
      default:
        break;
    }
  };

  const download = async (name) => {
    const actions = AssetActions(app());
    const [error, data] = await actions.download(name);
  };

  const handleCancel = () => {
    //setEditProject({ show: false, asset: "" });
    getAssetsMasterData();
    setapplyPopUp({ show: false, asset: "" });
  };

  const deleteAsset = async (asset) => {
    setAssetDelete({ name: "", delete: false });
    const actions = AssetActions(app());
    const [error, data] = await actions.deleteFile(asset.name);
    if (!error) getAssetsMasterData();
  };

  return (
    <div>
      <ModuleTitle title="Assets">
      {
        location.state.permission?.canCreate
        ?<input
          data-clickable-testid="upload"
          type="button"
          className="primary"
          onClick={() => contextOnChange("Create")}
          value={"+ Upload"}
        />
        :<></>
      }
      </ModuleTitle>
      <div className={styles.assetsOuterContainer}>
        <div className={styles.assetsContainer}>
          <div className={styles.assetsInnerContainer}>
            <div className={styles.assetsTableContainer}>
              <Table
                data={assets}
                headerColumns={headerColumns}
                width={"100%"}
                modifyPopUp={contextMenu}
                contextOnChange={contextOnChange}
                handleHeaderInputChange={onHeaderChange}
                handleHeaderSelectChange={onHeaderChange}
                name={"assets-table"}
                assetType={["JS", "CSS"]}
              />
            </div>
            <Pagination
              userDataCount={userDataCount}
              displayRows={displayRows}
              setDisplayRows={setDisplayRows}
            />
          </div>
          {assetPopUp.popup ? (
            <CreateFile
              createFile={assetPopUp.popup}
              handleCancel={() =>
                setAssetPopUp({ popup: false, update: false })
              }
              item={modifyAsset}
              isUpdate={assetPopUp.update}
              getAssetsMasterData={getAssetsMasterData}
              accept={"." + modifyAsset?.type}
            />
          ) : (
            ""
          )}
          {applyPopUp.show ? (
            <ApplytoProject
              applyPopUp={applyPopUp}
              handleCancel={handleCancel}
            />
          ) : (
            ""
          )}
          {assetDelete.delete ? (
            <SmallPopup
              popUp={assetDelete.delete}
              title={"Confirmation"}
              subtitle={"Are you sure you want to delete?"}
              handlePopUp={() => setAssetDelete({ name: "", delete: false })}
              handleSubmit={() => deleteAsset(assetDelete.name)}
            />
          ) : (
            ""
          )}
          {editProject.show ? (
            <EditAssetFile
              setEditproject={setEditproject}
              editProject={editProject}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
export default Assets;
