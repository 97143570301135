import { download, downloadFileFromUrl, get, getUrl, post, put } from "../../url"

export const requestQueueStatus = async () => {
    const path = `${getUrl()}/scheduler`;
    const [error, data] = await get(path);
    return [error, data]

}

export const getSchdulerList = async (offset, limit, schedulerFilter) => {
    const filter = getFilter(schedulerFilter)
    const path = `${getUrl()}/configurator/v1.0/scheduler/list`;
    const select = `$select=id,name,description,serviceType,serviceName,isEnabled,frequency,frequencyParam,projectComponentId,parameters,bosname,eventId,nextExecution,lastExecution&$sort=asc%20name&limit=${limit}&offset=${offset}${filter}`
    const [error, data] = await get(`${path}?${select}`);
    return [error, data];
}

export const getSchdulerDetail = async (schedulerName) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler/${schedulerName}`
    const [error, data] = await get(path);
    return [error, data];
}

export const checkSchdulerExists = async (schedulerName) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler/${schedulerName}/check`
    const [error, data] = await get(path);
    return [error, data];
}

export const getSchedulerLoglist = async (offset, limit, schedulerId, loglist) => {
    const filter = getFilter(loglist)
    const path = `${getUrl()}/configurator/v2.0/scheduler/${schedulerId}/logs`
    const select = `$select=id,scheduleId,scheduleAt,lastExecuted,status,flag,schedulerName&$sort=desc%20id&limit=${limit}&offset=${offset}${filter}`
    const [error, data] = await get(`${path}?${select}`);
    return [error, data];
}

export const createSchedulerBasedOnTime = async (payload = {}) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler`
    const [error, data] = await post(path, payload);
    return [error, data];

}

export const createSchedulerBasedOnEvent = async (payload = {}) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler`
    const [error, data] = await post(path, payload);
    return [error, data];
}

export const updateScheduler = async (payload = {}, name) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler/${name}`
    const [error, data] = await put(path, payload);
    return [error, data];
}

export const getReportURL = async (schedulerId, logId) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler/${schedulerId}/logs/${logId}/report`
    const [error, data] = await get(path);
    return [error, data]
}

export const getAllProject = async () => {
    const path = `${getUrl()}/configurator/v1.0/project/list`
    const select = `$select=name,description,status,id&$sort=asc%20name`
    const [error, data] = await get(`${path}?${select}`);
    return [error, data];

}

export const getBOS = async (name) => {
    const path = `${getUrl()}/configurator/v1.0/bos/listofBos?project_name=${name}`
    const [error, data] = await get(path);
    return [error, data];

}

export const getParameter = async (projectName, bosName, serviceId) => {
    const path = `${getUrl()}/configurator/v1.0/bos/list?project_name=${projectName}&$bos_name=${bosName}&$bos_uuid=${serviceId}`
    const [error, data] = await get(path);
    return [error, data];
}

export const getReport = async (url) => {
    const path = url
    const res = await download(path);
    return res;
}

export const getEditDetails = async (name) => {
    const path = `${getUrl()}/configurator/v1.0/scheduler/${name}`
    const [error, data] = await get(path);
    return [error, data];
}
export const getFilter = (schedulerFilter) => {
    let filter = "&$filter=service_type EQ 'BOS'";
    let obj = [];

    Object.keys(schedulerFilter).map(function (key, index) {
        if (schedulerFilter[key]) {
            if (key === "serviceName") {
                obj.push("service_name" + ' LIKE ' + encodeFilter(schedulerFilter[key]));
            }
            else if (schedulerFilter[key] === "Enabled") {
                let isEnabled = 1
                obj.push(`isEnabled` + ' EQ ' + isEnabled);
            }
            else if (schedulerFilter[key] === "Disabled") {
                let isEnabled = 0
                obj.push(`isEnabled` + ' EQ ' + isEnabled);

            }
            else if (schedulerFilter[key] === "Draft") {
                let isEnabled = 2
                obj.push(`isEnabled` + ' EQ ' + isEnabled);

            }
            else {
                obj.push(key + ' LIKE ' + encodeFilter(schedulerFilter[key]));
            }
        }

    });
    if(obj.length > 0)  filter += " AND "+obj.join(" AND ");
    return filter;

}

export const fetchUser = async (userName) => {
    const path = `${getUrl()}/configurator/v1.0/user/${userName}`
    const [error, data] = await get(path);
    return [error, data];
}

const encodeFilter = (input) => {
    return encodeURIComponent("'%" + input + "%'");
}

