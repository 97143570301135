import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  FilledInput,
  NativeSelect,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Table from '../../../../../common/components/Table';
import { configMessage } from '../../../../../common/messages/config';
import TransferIcon from '../../../../../assets/mdi_transfer.svg'
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '../../../../../views/dialog';
import { Autocomplete } from '@mui/material';

let defaultMessage = `I wanted to inform you that several workflow items previously assigned to [Old User's Name] have now been transferred to [New User's Name]. Please review these items at your earliest convenience to ensure a smooth transition.`

export default function (props) {
  const {
    showDialog,
    classes,
    dispatch,
    data,
    userList,
    bfList,
    handleTransfer,
  } = props;
  // const [inputData, setInputData] = useState({});
  const [fromUser, setFromUser] = useState()
  const [inputVal1,setInputVal1] = useState('')
  const [inputVal2,setInputVal2] = useState('')
  const [toUser, setToUser] = useState()
  const [completedRange, setCompletedRange] = useState("0")
  const [mailBodyMsg, setMailBodyMsg] = useState(defaultMessage)
  const [page, setPage] = useState("selectBf")
  const [filteredBfList, setFilteredBfList] = useState(null)
  const [fromUserAlertMsg, setFromUserAlertMsg] = useState('')
  const [toUserAlertMsg, setToUserAlertMsg] = useState('')
  const [emailAlertMsg, setEmailAlertMsg] = useState('')

  const init=()=>{
    setFromUser('')
    setToUser('')
    setCompletedRange('none')
  }
  const handleSubmit = () => {
    let payload = {
        from: fromUser.loginName,
        to: toUser.loginName,
        completedRange: completedRange,
        mailBody: mailBodyMsg.replace(`[Old User's Name]`,fromUser.fullName).replace(`[New User's Name]`,toUser.fullName),
        businessFunction:""
    }
    handleTransfer(payload, init)
  }

  const pageRedirection = (pageNo) => {
    setPage(pageNo)
  }

  const validate = () => {
    let flag = false
    if(!fromUser?.loginName) {
      flag = true
      setFromUserAlertMsg('Select a user')
    }
    if(!toUser?.loginName) {
      flag = true
      setToUserAlertMsg('Select a user')
    }else {
      if(fromUser?.loginName == toUser?.loginName) {
        flag = true
        setToUserAlertMsg("From and To User can not be same")
      }
    }
    if(!mailBodyMsg){
      flag = true
      setEmailAlertMsg(`*required`)
    }
    if(!flag)
    pageRedirection('reviewData')
  }

  const modalTitle = () => {
    if(page == 'selectBf'){
        return (
            <Box>
              <Typography className={classes.modalTitle}>
                {"Business Function List"}
              </Typography>
              <Box style={{ marginTop: '16px' }}>
                    <Typography variant='h5'>
                    {"These are the list of Workflow Business functions"}
                    </Typography>
                </Box>
            </Box>
          );
    }
    else if(page == 'selectUser'){
        return (
            <Box>
              <Typography className={classes.modalTitle}>
                {"Transfer Work Items"}
              </Typography>
            </Box>
          );
    }
    else if(page == 'reviewData'){
        return (
            <Box>
              <Typography className={classes.modalTitle}>
                {"Review Transfer"}
              </Typography>
            </Box>
          );
    }
  };

  const caseInsesitiveSearch = (searchString,option) => {
    if(searchString=="") return true
    if (searchString) {
      let text = option.fullName + " ("+option.email+")"
      return text.toLowerCase().includes(searchString.toLowerCase())
    }
    return false;
  }

  const container = () => {
    if(page=='selectBf'){
        return (
              <Grid sm={12} style={{display:"flex",justifyContent:"center"}} className={classes.modalContainer}>
                  <Grid sm={6} style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'}}>
                    <Table 
                        tableData={filteredBfList?filteredBfList:bfList}
                        tableColumns={[{id:"businessKey",label:"Business Function", align:"left",type:"text",style:{paddingRight:8, cursor:"auto"}}]}
                        tableHeight={300}
                        handleChange={(value)=>{setFilteredBfList(bfList.filter(e=>e.businessKey.toLowerCase().includes(value.toLowerCase())))}}
                    />
                  </Grid>
              </Grid>
          );
    }
    else if(page=='selectUser'||page=='reviewData'){
        return(
            <Grid sm={12} className={classes.modalContainer}>
              <FormGroup /*onSubmit={(e) => this.validateform(e)}*/>
                <Typography variant="h5" style={{fontWeight:500,fontSize:"19px"}}>
                    User selection
                </Typography>
                <Grid container sm={12}>
                    <Grid item sm={5}>
                        <InputLabel htmlFor='fromUser' className='nameLayout'>
                            From
                        </InputLabel>
                        {page=="selectUser"?
                        <Autocomplete
                        freesolo
                        fullWidth
                        options={userList}
                        disabled={page=="reviewData"}
                        id={"from-user-autoComplete"}
                        value={fromUser}
                        filterOptions={o=>o.filter(option=>caseInsesitiveSearch(inputVal1,option))}
                        onChange={(e,v,r)=>{setFromUser(v);setFromUserAlertMsg('')}}
                        inputValue={inputVal1}
                        onInputChange={(event,value)=>setInputVal1(value)}
                        getOptionLabel={(option) => option.fullName+" ("+option.email+")"}
                        forcePopupIcon={false}
                        renderOption={(props,option,state)=>{
                          return (
                            <li {...props}>
                            {option.fullName}
                            <br/>
                            ({option.email})
                            </li>
                          )
                        }}
                        renderInput={(params)=>(
                          <TextField
                          {...params}
                          name='fromUser'
                          type='text'
                          style={{margin:0}}
                          // onChange={(e)=>{setInputVal1(e.target.value)}}
                          InputProps={{
                            ...params.InputProps,
                            style:{width:"100%",height:"100%",paddingLeft:"8px",paddingRight:"0px"},
                            endAdornment:(
                              <InputAdornment >
                              <SearchIcon style={{fill:`rgba(0,0,0,0.5)`}}/>
                              </InputAdornment>
                            ),
                          }}
                          />
                        )}
                        />
                        :
                        <Typography style={{backgroundColor:"transparent", paddingBottom:"0px", paddingLeft:"8px",lineHeight:"2.3"}}>
                          {fromUser.fullName}{` (${fromUser.email})`}
                          {/* <br/> */}
                          
                        </Typography>
                        }
                        
                        <small className='text-muted red' style={{ color: 'red', marginTop:'2px' }}>
                            {fromUserAlertMsg}
                        </small>
                    </Grid>
                    <Grid item sm={2} style={{display: 'flex',justifyContent: 'center',alignItems: 'flex-end',paddingBottom:`${fromUserAlertMsg||toUserAlertMsg?'16px':'0px'}`}}>
                        <img src={TransferIcon}/>
                    </Grid>
                    <Grid item sm={5}>
                        <InputLabel htmlFor='toUser' className='nameLayout'>
                            To
                        </InputLabel>
                        {page=="selectUser"?
                        <Autocomplete
                        freesolo
                        fullWidth
                        options={userList.filter(user=>user.isActive)}
                        disabled={page=="reviewData"}
                        id={"from-user-autoComplete"}
                        value={toUser}
                        filterOptions={o=>o.filter(option=>caseInsesitiveSearch(inputVal2,option))}
                        onChange={(e,v,r)=>{setToUser(v);setToUserAlertMsg('')}}
                        inputValue={inputVal2}
                        onInputChange={(event,value)=>setInputVal2(value)}
                        getOptionLabel={(option) => option.fullName + " ("+option.email+")"}
                        forcePopupIcon={false}
                        renderOption={(props,option,state)=>{
                          return (
                            <li {...props}>
                            {option.fullName}
                            <br/>
                            ({option.email})
                            </li>
                          )
                        }}
                        renderInput={(params)=>(
                          <TextField
                          {...params}
                          name='toUser'
                          type='text'
                          style={{margin:0}}
                          // onChange={(e)=>{setInputVal1(e.target.value)}}
                          InputProps={{
                            ...params.InputProps,
                            style:{width:"100%",height:"100%",paddingLeft:"8px",paddingRight:"0px"},
                            endAdornment:(
                              <InputAdornment >
                              <SearchIcon style={{fill:`rgba(0,0,0,0.5)`}}/>
                              </InputAdornment>
                            ),
                          }}
                          />
                        )}
                        />
                        :
                        <Typography style={{backgroundColor:"transparent", paddingBottom:"0px", paddingLeft:"8px", lineHeight:'2.3'}}>
                          {toUser.fullName}{` (${toUser.email})`}
                          {/* <br/> */}
                          
                        </Typography>
                        }
                        <small className='text-muted red' style={{ color: 'red', marginTop:'2px' }}>
                            {toUserAlertMsg}
                        </small>
                    </Grid>

                </Grid>
                <Typography variant="h5" style={{fontWeight:500,fontSize:"19px",marginTop:'20px'}}>
                    Work items selection
                </Typography>
                <Grid container sm={12} spacing={2}>
                    <Grid item sm={3}>
                        <InputLabel htmlFor='fromUser' className='nameLayout'>
                            Open
                        </InputLabel>
                        <Select
                            value={"all"}
                            style={{width:"100%"}}
                            disabled
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={3}>
                    <InputLabel htmlFor='fromUser' className='nameLayout'>
                            Pending
                        </InputLabel>
                        <Select
                            value={"all"}
                            style={{width:"100%"}}
                            disabled
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={3}>
                        <InputLabel htmlFor='toUser' className='nameLayout'>
                            Completed
                        </InputLabel>
                        <Select
                            value={completedRange}
                            style={{width:"100%"}}
                            disabled={page=='reviewData'?true:false}
                            onChange={(e)=>setCompletedRange(e.target.value)}
                        >
                            <MenuItem value={"0"}>None</MenuItem>
                            <MenuItem value={"30"}>30 days</MenuItem>
                            <MenuItem value={"60"}>60 days</MenuItem>
                            <MenuItem value={"90"}>90 days</MenuItem>
                        </Select>
                    </Grid>

                </Grid>
                <Typography style={{fontWeight:400,fontSize:"13px", color:"rgba(0,0,0,0.65)",marginTop:'10px',paddingBottom:"0px", backgroundColor:"transparent"}}>
                    Note: The Balance completed work-items will be archived and will be accessible to the workflow administrator via the console
                </Typography>
                <Typography variant="h5" style={{fontWeight:500,fontSize:"19px",marginTop:'20px', marginBottom:'10px'}}>
                    Email message to the user
                </Typography>
                <Box className='descBoxLayout'>
                  <FilledInput
                    type='text'
                    name='email'
                    className="descriptionTextBox"
                    value={page=="reviewData"?mailBodyMsg.replace(`[Old User's Name]`,fromUser.fullName).replace(`[New User's Name]`,toUser.fullName):mailBodyMsg}
                    onChange={(e)=>{setMailBodyMsg(e.target.value);if(e.target.value != '' && emailAlertMsg != '') setEmailAlertMsg('')}}
                    disableUnderline
                    multiline
                    style={{lineHeight:"32px",...(page=="reviewData"?{backgroundColor:"transparent",color:'rgba(0, 0, 0, 0.87)'}:{})}}
                    disabled={page=="reviewData"}
                    rows={2}
                  />
                  <small className='text-muted red' style={{ color: 'red' }}>
                    {emailAlertMsg}
                  </small>
                </Box>
              </FormGroup>
            </Grid>
        )
    }
    else if(page=='reviewData'){
        return(
            <Grid sm={12} className={classes.modalContainer}>
                
            </Grid>
        )
    }

  };

  const modalFooter = () => {
    if(page == 'selectBf'){
        return (
            <Box className={classes.footerContainer} sm={12}>
              <Grid
                sm={12}
                style={{ display: 'flex' }}
                alignItems='center'
                justify='flex-end'
              >
                <Button color='secondary' variant='contained' onClick={props.onClose}>
                  Cancel
                </Button>
                <Button color='primary' variant='contained' onClick={()=>pageRedirection('selectUser')}>
                  Next
                </Button>
              </Grid>
            </Box>
          );
    }
    else if(page == 'selectUser'){
        return (
            <Box className={classes.footerContainer} sm={12}>
              <Grid
                sm={12}
                style={{ display: 'flex' }}
                alignItems='center'
                justify='flex-end'
              >
                <Button color='secondary' variant='contained' onClick={()=>pageRedirection('selectBf')}>
                  Back
                </Button>
                <Button color='primary' variant='contained' onClick={validate}>
                  Review
                </Button>
              </Grid>
            </Box>
          );
    }
    else if(page == 'reviewData'){
        return (
            <Box className={classes.footerContainer} sm={12}>
              <Grid
                sm={12}
                style={{ display: 'flex' }}
                alignItems='center'
                justify='flex-end'
              >
                <Button color='secondary' variant='contained' onClick={()=>pageRedirection('selectUser')}>
                  Back
                </Button>
                <Button color='primary' variant='contained' onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Box>
          );
    }
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialog}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      onClose={props.onClose}
    />
  );
}
