import { validate, required, validateName } from '../../../../../../common/validations/Validations';
import projectMessages from '../../../../../../common/validationMessages/project';

const validateValue = (errors, name, value) => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    switch(name){
        case "name":
            if(value=="") {
                msg = projectMessages.E8010;
                isValid = required(errors,name,value,msg)
            }
            else {
                msg = projectMessages.E8010a;
                isValid = validateName(errors,name,value,msg)
            }
            break;
        case "deviceType":
            msg = projectMessages.E8011;
            isValid = required(errors,name,value,msg)
            break;
        default:
            break;
    }
    return isValid;
}

const validateValues = (values, errors, fields) => {
    let validate = [];
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if(fields.includes(name)){
            validate.push(validateValue(errors,name,value))
        }
    })
    return validate.includes(false);
}

export {validateValue, validateValues}
