import store from '../stores';
import * as actions from '../actions/business-function';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { s3Url } from '../utils/common';
import {
  BUSINESS_FUNCTION_API,
  CONFIGURATOR_API,
  FUNCTION_API,
} from '../constants';
import { loadApiMessage } from '../actions/user-task';
import {
  getPage,
  updatePage,
} from './app-designer';
import { DEFAULT_SCREEN_WITH_MENU } from '../constants';
import { SHAPE_TYPES } from '../libraries/mxgraph-wrapper/shape/types';
import { configMessage } from '../common/messages/config';

export function alertShow(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.type.toLowerCase(),
  };
  store.dispatch(showNotification(param));
}

export function fetchBusinessFunctionTools(data) {
  let path = '/json/business-function-tools.json';
  if (data == 'userTask') {
    path = '/json/userTask.json';
  } 
  if (data == 'timerEvents') {
    path = '/json/timer-events-tools.json';
  }

  return fetch(path)
    .then((res) => res.json())
    .then(
      (res) => {
        let result = res;
        if (data != 'userTask' && data != 'timerEvents') {
          if(data === "WFM"){
            result = result.filter(e => e.bfType.includes("WFM"));
          }else{
            result = result.filter(e => e.bfType.includes("NWFM"));
          }
          // store.dispatch(actions.loadBusinessFunctionTools([]));
          store.dispatch(actions.loadBusinessFunctionTools(result));
        } else if (data === 'timerEvents') {
          return result;
        } else {
          return result;
        }
      },
      (error) => {}
    );
}

export async function fetchUserRoles(projectName, functionName) {
  let roles = [];
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${functionName}/laneroles?project_name=${projectName}`,
  };
  await DataService.read(params).then(
    (result) => {
      if (result.data.status.type === 'S') {
        roles = result.data.data;
        store.dispatch(actions.saveUserRoles(result.data.data));
      }
    },
    (error) => {}
  );
  return roles;
}

export function createApp(projectName, businessFuncName, app, fetchApp = true) {
  let data = {
    businessFunctionName: businessFuncName,
    project_name: projectName,
    name: app.appName,
    type: app.appType,
    icon: app.icon,
    roleName: app.role,
    menu: app.menu,
    workflow: app.workflow,
    serviceStart: app.serviceStart,
  };

  if (data.type === 'Ofl') {
    data = { ...data, offlineProperty: app.offlineProperty };
  }

  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/apps`,
    data: data,
  };

  DataService.create(params)
    .then(
      (res) => {
        if (res.data.status.type === 'S') {
          store.dispatch(actions.createApp(res.data.uuid));
          if (fetchApp) {
            fetchAppList(projectName, businessFuncName);
          }
        }
      },
      (error) => {}
    )
    .catch((e) => {});
}

export function UploadAppIcon(
  app,
  project,
  businessFuncName,
  RoleName,
  appkey,
  callback
) {
  let val = app.icon;
  // if (val.startsWith('"') && val.endsWith('"')) {
  //   val = val.substr(1).slice(0, -1);
  // }
  var appKeyVal = (appkey != undefined && appkey.length > 0) ? `&appkey=${appkey}` : "";
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/apps/uploadAppIcon?project_name=${project}&function_name=${businessFuncName}&lane_name=${RoleName}${appKeyVal}`,
    data: val,
  };
  DataService.update(params)
    .then(
      (res) => {
        store.dispatch(actions.updateIcon(res.data.url));
        callback(app, res.data.url, res.data.uuid);
      },

      (error) => {}
    )
    .catch((e) => {});
}
export function GetAppIcon(
  project,
  businessFuncName,
  url,
  callback = () => null
) {
  //getAppIcon?businessfunction={prjectname}.{BFname}&path={path}
  const path = `/${BUSINESS_FUNCTION_API}/apps/getAppIcon?businessfunction=${project}.${businessFuncName}&path=${url}`;
  const params = {
    uri: path,
  };

  DataService.read(params).then((res) => {
    callback(res.data?.url || '');
  });
}
export function updateApp(
  app,
  appKey,
  isRole,
  projectName,
  businessFuncName,
  callback = () => null
) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/apps/${appKey}`,
    data: app,
  };

  DataService.update(params)
    .then(
      (res) => {
        if (res.status === 200) {
          let isSuccess = {
            status: res.data.status.type === 'S' ? 'success' : 'error',
            message: res.data.status.message,
            isRole: isRole,
          };
          store.dispatch(actions.updateApp(isSuccess));
          if (isSuccess.status === 'success' && app.offlineProperty) {
            callback();
          }
        }
        fetchAppList(projectName, businessFuncName);
      },
      (error) => {}
    )
    .catch((e) => {});
}
function getPageListPromise({ projectName, functionName }) {
  let filter = '$select=id,name,dataObject,uid';
  const params = {
    uri: `/${BUSINESS_FUNCTION_API}/page/${functionName}/list?project_name=${projectName}&${filter}`,
    data: '',
  };
  return DataService.read(params)
    .then((result) => {
      if (result.status === 200) {
        if (
          result.data.status.type === 'S' &&
          Object.keys(result.data).length
        ) {
          return result.data.data;
        }
      }
    })
    .catch((error) => {
      return error;
    });
}
function behaviourListPromise({ projectName, functionName }) {
  let filter =
    '$select=id,companyId,projectId,name,uiPageId,uiPageName,eventName';
  let params = {
    uri: `${BUSINESS_FUNCTION_API}/behavior/${functionName}/behaviourList?project_name=${projectName}&${filter}`,
    data: '',
  };
  return DataService.read(params).then(
    (res) => {
      if (res.status === 200) {
        if (res.data.status.type === 'E' || res.data.status.type === 'error') {
          res.data.status.type = 'error';
          return res.data;
        } else if (res.data.status.type === 'S') {
          res.data.status.type = 'success';
          return res.data.data;
        }
      }
    },
    (error) => {
      if (error.response) {
        return error.response;
      }
    }
  );
}
function loadUIJSONPromise(params) {
  return DataService.download(params)
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      return { error: e };
    });
}
export function fetchBusinessFunctionDetails(
  projectName,
  businessName,
  isEdit,
  onlyVar,
  cb,
  stopLoop = false
) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}?project_name=${projectName}`,
  };
  let functionName = businessName;
  let project = projectName;
  DataService.read(params)
    .then(
      (res) => {
        if (res.data.status.type === 'S') {
          if (cb) {
            cb(res.data.detail, () => {
              store.dispatch(actions.businessFunctionDetails(res.data.detail));
            });
            store.dispatch(actions.businessFunctionDetails(res.data.detail));
          } else {
            store.dispatch(actions.businessFunctionDetails(res.data.detail));
          }

          if (!isEdit) {
            const params = {
              uri: s3Url(res.data.detail.ui),
            };
            // get UI JSON
            if (!onlyVar) {
              /* DataService.download(params).then((result) => {
                store.dispatch(actions.loadFunctionObject(result.data));
              }).catch(e => {
                store.dispatch(actions.loadFunctionObject({ error: e }));
              });*/
              let bfParam = { projectName, functionName };

              Promise.all([
                getPageListPromise(bfParam),
                behaviourListPromise(bfParam),
                loadUIJSONPromise(params),
              ]).then((results) => {
                var res = results[results.length - 1];
                res = typeof res !== "string" ? res : {}; 
                res.PageList = results[0];
                res.PageScriptList = results[1];
                store.dispatch(actions.loadFunctionObject(res));
              });
            }

            if (res.data.detail.data) {
              const params1 = {
                uri: s3Url(res.data.detail.data),
              };
              // Get variable JSON
              DataService.download(params1)
                .then((result) => {
                  //remove toBeDel
                  let data = result.data;
                  if(result.data?.variable)
                  {
                    let toBeDeletedIndex = result.data?.variable.findIndex((item) => item.dataType === 'Table' && item.name === "Tobedeleted" && item.variableType === "Output");
                   console.log(toBeDeletedIndex);
                    if(toBeDeletedIndex >= 0){
                      let item = data?.variable.splice(toBeDeletedIndex,1);
                      console.log(item);
                      result.data = data;
                    }
                  }
                  let variables = result.data?.variable
                    ? result.data
                    : { variable: [] };
                  store.dispatch(actions.loadFunctionVariables(result.data));
                })
                .catch((e) => {});
            } else {
              //Load empty vaiables if bf has no variables
              if (!stopLoop)
                saveVariables([], projectName, businessName, 'init');
            }
          }
        }
      },
      (error) => {
        store.dispatch(actions.loadFunctionObject({ error }));
      }
    )
    .catch((e) => {
      store.dispatch(actions.loadFunctionObject({ error: e }));
    });
}

export function fetchAppList(pName, fName) {
  const path = `/${BUSINESS_FUNCTION_API}/apps?businessfunction=${pName}.${fName}`;
  const params = {
    uri: path,
  };

  store.dispatch(actions.updateStatus('GET_APPLIST_REQUEST'));
  DataService.read(params).then((res) => {
    if (res.status === 200) {
      store.dispatch(actions.saveAppList(res.data.listData));
    }
  });
}
export function loadAppsList(data) {
  store.dispatch(actions.saveAppList(data));
}
export function editBusinessFunction(projectName, businessName, input) {
  let data = {
    name: input.name,
    description: input.desc,
    // roleId: input.roleId
  };
  if(input.roleId && (input.roleId !== "")) data.roleId = input.roleId
  if(input.associatedBFName && (input.associatedBFName !=="")) data.associatedBFName = input.associatedBFName

  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}?project_name=${projectName}`,
    data: data,
  };

  DataService.update(params).then(
    (res) => {
      if (res.status === 200) {
        let isSuccess = {
          status: res.data.status.type === 'S' ? 'success' : 'error',
          message: res.data.status.message,
          isCreating: false,
        };
        store.dispatch(actions.editBusinessFunction(isSuccess));
        //  alertShow(res.data.status)
      }
    },
    (error) => {}
  );
}

export function updateBusinessFunction(projectName, businessName, input) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}?project_name=${projectName}`,
    data: input,
  };
  DataService.update(params).then(
    (res) => {
      if (res.status === 200) {
        let isSuccess = {
          status: res.data.status.type === 'S' ? 'success' : 'error',
          message: res.data.status.message,
        };
        fetchBusinessFunctionTools(input.type);
        store.dispatch(actions.updateBusinessFunction(isSuccess));
      }
    },
    (error) => {}
  );
}

export async function createRole(role) {
  let isSuccess = { status: 'error', message: 'Internal error' };
  const uri = `/${CONFIGURATOR_API}/role`;
  const data = { role: role };
  await DataService.create({ uri, data }).then((res) => {
    const { status } = res;
    if (status === 200) {
      isSuccess = {
        status: res.data.status.type === 'S' ? 'success' : 'error',
        message: res.data.status.message,
      };
      store.dispatch(actions.createRoleSuccess(isSuccess));
    }
  });
  return isSuccess;
}

export function saveSnapshot(file, projectName, fName) {
  const path = `/${BUSINESS_FUNCTION_API}/function/${fName}/svgfile?project_name=${projectName}`;
  const formData = new FormData();
  formData.append('fileUpload', file);
  const params = {
    uri: path,
    data: formData,
  };
  DataService.update(params).then((res) => {});
}

export function saveGraphJson(json, projectName, businessName) {
  const path = `/${BUSINESS_FUNCTION_API}/function/${businessName}/ui?project_name=${projectName}`;
  const params = {
    uri: path,
    data: json,
  };

  DataService.update(params).then((res) => {
    if (res.status === 200) {
      store.dispatch(actions.saveAppList(json.lanes));
    }
  });
}

export function saveVariables(
  variables,
  projectName,
  businessName,
  type,
  variableType,
  callback = () => null
) {
  const path = `/${BUSINESS_FUNCTION_API}/function/${businessName}/data?project_name=${projectName}`;
  const params = {
    uri: path,
    data: { variable: variables },
  };

  DataService.update(params).then((res) => {
    if (res.status === 200) {
      if (variableType !== undefined) {
        alertShow({
          message: `Variable ${variableType} successfully `,
          type: 'success',
        });
      }
      if (type === 'init') {
        store.dispatch(actions.loadFunctionVariables({ variable: [] }));
        store.dispatch(actions.createVariableSuccess(true));
        fetchBusinessFunctionDetails(projectName, businessName, false, true);
      } else {
        if (type === 'exposedVar') {
          let data = { name: 'variable', status: 'success' };
          // store.dispatch(loadApiMessage(data));
          alertShow({
            message: `Variable ${variableType || 'updated'} successfully`,
            type: 'success',
          });
          callback();
        } else {
          store.dispatch(actions.createVariableSuccess(true));
        }
        fetchBusinessFunctionDetails(projectName, businessName, false, true);
      }
    }
  });
}

export function clearCreateVariable(val) {
  store.dispatch(actions.createVariableSuccess(val));
}

export function updateUserTask(
  task,
  newName,
  projectName,
  functionName,
  method
) {
  if (!task) {
    return;
  }
  const path = `/${BUSINESS_FUNCTION_API}/pageflow/${task}?project_name=${projectName}&function_name=${functionName}`;
  const params = {
    uri: path,
    data: { name: newName },
  };
  if (method === 'create') {
    DataService.create(params).then((res) => {
      if (res.status === 200) {
      }
    });
  } else {
    DataService.update(params).then((res) => {
      if (res.status === 200) {
      }
    });
  }
}

export function deleteUserTask(task, projectName, functionName) {
  const path = `/${BUSINESS_FUNCTION_API}/pageflow/${task}?project_name=${projectName}&function_name=${functionName}`;
  const params = {
    uri: path,
  };

  DataService.delete(params).then((res) => {
    if (res.status === 200) {
    }
  });
}

export function checkNewVersionInQA(
  projectName,
  businessFunctionName,
  cb
) {

  var params = {
    uri:
    `/${BUSINESS_FUNCTION_API}/function/${businessFunctionName}/` +
    `checkNewVersionInQA?project_name=${projectName}`,
    data: {}
  };  

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data) {
        // console.log("checkVersion", data)
        cb.successCb()
        // cb.failureCb()
        // store.dispatch(transportAction.loadVersionList(data));
      } else cb.failureCb()
    },
    (error) => {
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
}

export function transportVersion(data) {
  var params = {};
  if (data.screenType !== undefined && data.screenType === 'services') {
    params = {
      uri: `/baas/v1.0/baas/${data.bosUuid}/transport?project_name=${data.project}&version=${data.version}&comment=${data.comment}`,
    };
  } else {
    params = {
      uri: `/${BUSINESS_FUNCTION_API}/function/${data.fname}/transportFunction?project_name=${data.project}&version=${data.version}&comment=${data.comment}`,
    };
  }

  DataService.create(params).then(
    (result) => {
      if (result.status === 200) {
        let isSuccess = {
          status: result.data.status.type === 'S' ? 'success' : 'error',
          message: result.data.status.message,
        };

        if (result.data.status.code == 0) {
          alertShow(result.data.status);
        } else {
          store.dispatch(actions.transportVersionResponse(result.data));
        }
      }
    },
    (error) => {}
  );
}

export function transportVersionForService(data) {
  var params = {};

  params = {
    uri: `/baas/v1.0/baas/${data.bosUuid}/transport?project_name=${data.project}&version=${data.version}&comment=${data.comment}`,
  };

  DataService.update(params).then(
    (result) => {
      if (result.status === 200) {
        let isSuccess = {
          status: result.data.status.type === 'S' ? 'success' : 'error',
          message: result.data.status.message,
        };

        if (result.data.status.code == 0) {
          alertShow(result.data.status);
        } else {
          store.dispatch(actions.transportVersionResponse(result.data));
        }
      }
    },
    (error) => {}
  );
}

export function renameBOS(projectName, bosName, bosUID) {
  let params = {
    uri: `/${CONFIGURATOR_API}/file/bosUpdate?project_name=${projectName}&$bos_name=${bosName}&$bos_uuid=${bosUID}`,
    data: '',
  };
  let isSuccess = {};

  DataService.update(params).then(
    (res) => {
      if (res.status === 200) {
        isSuccess = {
          type: res.data.status.type === 'S' ? 'success' : 'error',
          message: res.data.status.message,
        };
        // if(isSuccess.type === 'error')
        //   alertShow(isSuccess);
        return isSuccess;
      }
    },
    (error) => {
      isSuccess = {
        type: 'error',
        message: error.message,
      };
      return isSuccess;
    }
  );
}

export function cloneBos(projectName, bosName, parentBosId, childId) {
  let params = {
    uri: `/${CONFIGURATOR_API}/file/cloneBOS?project_name=${projectName}&$bos_uuid=${parentBosId}`,
    data: {
      name: bosName,
      uuid: childId,
    },
  };

  DataService.create(params).then(
    (res) => {},
    (error) => {}
  );
}

export function clearTransportFunctionResp() {
  store.dispatch(actions.transportVersionResponse({}));
}

export function createSnaphshot(snapshot, fName) {
  const doc = document.implementation.createHTMLDocument('');
  snapshot.removeAttribute('xmlns');
  snapshot.style.transform = 'scale(0.3)';
  snapshot.style['transform-origin'] = 'top';
  snapshot.style['-webkit-transform-origin-x'] = 'left';
  snapshot.setAttribute('xmlns:xhtml', doc.documentElement.namespaceURI);
  snapshot.style.minHeight = '900px';
  var serializedSVG = new XMLSerializer().serializeToString(snapshot);
  let file = new Blob([serializedSVG], { type: 'image/svg+xml' });
  file.name = fName + '.svg';
  return file;
}

export function handleScreenChange(size) {
  if (size === 'exitFullScreen') {
    document.getElementsByClassName('header')[0].style.display = '';
    document.getElementsByClassName('tab-container')[0].style.paddingTop =
      '64px';
    if (
      document.getElementsByClassName('process-panel') &&
      document.getElementsByClassName('process-panel').length
    ) {
      document.getElementsByClassName('process-panel')[0].style.top = '113px';
    }
    document.getElementsByClassName('sub-header')[0].style.top = '64px';
  } else {
    document.getElementsByClassName('header')[0].style.display = 'none';
    document.getElementsByClassName('tab-container')[0].style.paddingTop =
      '0px';
    if (
      document.getElementsByClassName('process-panel') &&
      document.getElementsByClassName('process-panel').length
    ) {
      document.getElementsByClassName('process-panel')[0].style.top = '50px';
    }
    document.getElementsByClassName('sub-header')[0].style.top = '0px';
  }
}

export function cloneComponents(
  projectName = '',
  bfName = '',
  payload = [],
  callback = () => null
) {
  let params = {
    uri: `/${FUNCTION_API}/${bfName}/groupClone?project_name=${projectName}`,
    data: { copy: payload },
  };

  DataService.update(params).then(
    (res) => {
      let bfParam = { projectName, functionName: bfName };

      Promise.all([
        getPageListPromise(bfParam),
        behaviourListPromise(bfParam),
      ]).then((results) => {
        var res = {};
        res.PageList = results[0];
        res.PageScriptList = results[1];
        store.dispatch(actions.loadScreenListObject(res));
        callback();
      });
    },
    (error) => {
      alertShow(error);
    }
  );
}

export function createAppMenu(projectName, businessFunctionName, menuUuid, menuName, menuLinks, pageUuid, pageName, cb) {
  let data = {
    projectName,
    businessFunctionName,
    menuName,
    menuUuid,
    links: menuLinks
  };

  if(pageUuid){
    data.pageUuid = pageUuid
    data.pageName = pageName
  }

  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/pageMenu`,
    data: data,
  };

  DataService.create(params)
    .then(
      (res) => {
        if (res.data.status.type === 'S') {
          // Rendering new screenshot in the new screen, this needs be optimized...
          let bfParam = { projectName, functionName: businessFunctionName };
          Promise.all([
            getPageListPromise(bfParam),
            behaviourListPromise(bfParam),
          ]).then((results) => {
            var res = {};
            res.PageList = results[0];
            res.PageScriptList = results[1];
            store.dispatch(actions.loadScreenListObject(res));
            if (typeof cb === "function"){
              cb()
            }
          }); 

          // For storing menu page json data and the default image in the redux store (menuData) 
          getMenuDetails(projectName, businessFunctionName, menuUuid);
        }else{
          cb(res.data.status?.message || "Oops something went wrong! pls try again later!")
        }
      },
      (error) => { }
    )
    .catch((e) => { });
}

export function getMenuDetails(projectName, functionName, pageName){
  getPage({
    projectName,
    pageName,
    functionName,
  })
    .then(async (res) => {

      let uiObject, dataObject;

      if (res?.detail?.uiObject){
        let response = await fetch(res.detail.uiObject)
        uiObject = await response.json()
      }
      if (res?.detail?.dataObject) {
        let response = await fetch(res.detail.dataObject)
        dataObject = await response.text()
      }
      store.dispatch(actions.loadScreenMenuObject({
        uid: pageName,
        uiObject,
        dataObject
      }));

    })
    .catch((error) => {

    });
};

export function saveMenuDetails(projectName, functionName, menuUid, links) {
  
  // Need to correct initial menu page json data, and improve this function with error handling...
  let menuObj = store.getState().businessFunction?.menuData[menuUid];
  if(menuObj){
    let { uiObject, dataObject } = menuObj
    if (uiObject) {
      uiObject.data.page.propertyValue.component.links = links;
      updatePage({
        data: {
          projectName: projectName,
          uiObject: JSON.stringify(uiObject),
          dataObject: dataObject,
        },
        pageName: menuUid,
        functionName: functionName,
      });

      store.dispatch(actions.loadScreenMenuObject({
        uid: menuUid,
        uiObject: uiObject
      }));
    }
  }

};

export function getPageMenuAsync(projectName, businessName, pageUid) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}?project_name=${projectName}`,
  };

  return new Promise((resolve, rej)=>{
    DataService.read(params)
      .then((res) => {
        if (res.data.status.type === 'S') {
          const params = {
            uri: s3Url(res.data.detail.ui),
          };
          loadUIJSONPromise(params)
          .then((laneJson) => {
              let pageNode, menuInfo;
              if (laneJson.graph.lanes) {
                laneJson.graph.lanes.forEach(lane => {
                  if (!pageNode) {
                    pageNode = lane.children.find((node) => node.uid === pageUid);
                    if(!pageNode){
                      let groupNodes = [];
                      lane.children.map(e => {
                        if(e.type === SHAPE_TYPES.TASK) {
                          groupNodes.push( ...(e.childTask || []));
                        }
                      });
                      if(groupNodes?.length > 0){
                        pageNode = groupNodes.find(e => e.uid === pageUid);
                      }
                    }
                    if (pageNode) {
                      menuInfo = lane.children.find((node) => node.type === SHAPE_TYPES.MENU);
                    }
                  }
                })
              }
              if (menuInfo) {
                getPage({
                  projectName,
                  pageName: menuInfo.uid,
                  functionName: businessName,
                  isMenu: true
                })
                  .then(async (res) => {
                    let uiObject;
                    if (res?.detail?.uiObject) {
                      let response = await fetch(res.detail.uiObject)
                      uiObject = await response.json()
                    }
                    resolve(uiObject);
                  })
                  .catch(rej);
              }else{
                resolve();
              }
          }).catch(rej);
        }else{
          rej("Something went wrong!")
        }
      })
      .catch(rej);
  })
}
  
export function updateUIJson(graphJson) {
  store.dispatch(actions.loadFunctionObject(graphJson));
}
export function fetchSystemList(systemTypes = null, callback = () => null){
  let params = {
    uri: `/${CONFIGURATOR_API}/sap/systems`,
    data: { systemTypes },
  };

  DataService.create(params).then(
    (res) => {
      let list = res.data?.listData || [];
      callback(list);
    },
    (error) => {
      
    }
  );
}