import React, { useEffect, useState } from 'react';
import { useScheduler } from '../../schedulerContext';

export const InputTable = ({ data })=> {
  const { parameters, setParameters } = useScheduler();

  
  const [values, setValues] = useState(
    Object.keys(data).reduce((acc, key) => {
      acc[data[key].name] = parameters.input?.[data[key].name] || '';
      return acc;
    }, {})
  );

  const handleInputChange = (event, variable) => {
    setValues(prevValues => {
      const updatedValues = { ...prevValues, [variable]: event.target.value };

   
      setParameters(p => ({ ...p, input: updatedValues }));

      return updatedValues;
    });

  }

 

  const tableRows = Object.values(data).map(item => (
    <tr key={item.name}>
      <td>{item.caption}</td> 
      <td>{item.dataType}</td>
      <td>
          <input 
            style={{width:"120px",outline:"none"}}
            type="text" 
            value={values[item.name] || ''} 
            onChange={event => handleInputChange(event, item.name)} 
            spellCheck={'false'}
          />
      </td>
    </tr>
  ));

  return (
    <div style={{maxWidth:"350px",width:"350px"}}>
      <table>
        <thead>
          <tr>
            <th style={{maxWidth:"120px",width:"120px"}}>Variable</th>
            <th style={{maxWidth:"120px",width:"120px"}}>Datatype</th>
            <th style={{maxWidth:"120px",width:"120px"}}>Value</th>
          </tr>
        </thead>
        {
          (data && Object.keys(data).length > 0) ?  <tbody>
          {tableRows}
        </tbody>:   <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: 'center' }}>
                No data available
              </td>``
            </tr>
          </tbody>
        }
       
      </table>
    </div>

  );
}


