const configMessages = {
    T1001:"Traditional web applications enhanced with modern web technologies, enabling a more a more app-like experience. Great choice when web is the primary interface for your users ",
    T1002:"Traditional web applications enhanced with ",
    T1003:"Apps with a consistent look-n-feel across web and all mobile devices. Easy to build and easy to roll-out, upgrade and maintain ",
    T1004:"Apps with a consistent look-n-feel across web ",
    T1005:"Apps built for mobile devices using native mobile technologies. an excellent choice when user-experience and performance is paramount ",
    T1006:"Apps built for mobile devices using native ",
    T1007:"Traditional web applications"
}

export default configMessages;