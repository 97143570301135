import React, { useState } from 'react'
import styles from './iosConfiguration.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import { validateValue, validateValues, validateImage } from './validations';
import { readFileAsyncImage } from '../../../../../../common';
import { arrayBufferToBase64 } from '../../../../../../common/utils/utils';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function IosConfiguration ({ pageNumber, projectName, setContainerAppDetails, containerAppId, handleClose, resetCADetails, rolePopUp, containerAppDetails }) {

    const initialData = {
        iosCertificate: "",
        mobileProvisioning: "",
        pushCertificate: "",
        iosCertPassword: "",
        pushCertPassword: "",
        iphone4: "",
        iphone5: "",
        iphone6: "",
        iphonePlus: "",
        ipadLandscape: "",
        ipadPortrait: "",
        defaultLandscape: "",
        defaultPortrait: ""
    }

    const mapInitialData = (initialData) => {
        if( containerAppDetails.containerAppCertificates != undefined){
            let dataObj = initialData
            let activeFileObj = {
                iosCertificate: "",
                mobileProvisioning: "",
                pushCertificate: "",
                iphone4: "",
                iphone5: "",
                iphone6: "",
                iphonePlus: "",
                ipadLandscape: "",
                ipadPortrait: "",
                defaultLandscape: "",
                defaultPortrait: ""
            }
            containerAppDetails.containerAppCertificates.map( e => {
                if(e.kind == "C") {
                    dataObj.iosCertificate = e
                    dataObj.iosCertPassword = e.password
                    activeFileObj.iosCertificate = e.certificate
                }
                else if(e.kind == "P") {
                    dataObj.pushCertificate = e
                    dataObj.pushCertPassword = e.password
                    activeFileObj.pushCertificate = e.certificate
                }
                else if(e.kind == "M") {
                    dataObj.mobileProvisioning = e
                    activeFileObj.mobileProvisioning = e.certificate
                }
                else if (e.kind == "SSIP4") {
                    dataObj.iphone4 = e
                    if(e?.uuid) activeFileObj.iphone4 = e.uuid
                }
                else if (e.kind == "SSIP5") {
                    dataObj.iphone5 = e
                    if(e?.uuid) activeFileObj.iphone5 = e.uuid
                }
                else if (e.kind == "SSIP6") {
                    dataObj.iphone6 = e
                    if(e?.uuid) activeFileObj.iphone6 = e.uuid
                }
                else if (e.kind == "SSIP6PLUS") {
                    dataObj.iphonePlus = e
                    if(e?.uuid) activeFileObj.iphonePlus = e.uuid
                }
                else if (e.kind == "SSIPADRETINALANDSCAPE") {
                    dataObj.ipadLandscape = e
                    if(e?.uuid) activeFileObj.ipadLandscape = e.uuid
                }
                else if (e.kind == "SSIPADRETINAPORTRAIT") {
                    dataObj.ipadPortrait = e
                    if(e?.uuid) activeFileObj.ipadPortrait = e.uuid
                }
                else if (e.kind == "SSDEFAULTLANDSCAPE") {
                    dataObj.defaultLandscape = e
                    if(e?.uuid) activeFileObj.defaultLandscape = e.uuid
                }
                else if (e.kind == "SSDEFAULTPORTRAITUPSIDEDOWN") {
                    dataObj.defaultPortrait = e
                    if(e?.uuid) activeFileObj.defaultPortrait = e.uuid
                }
            })
            return [dataObj, activeFileObj]
        }
    }
    const [dataObj, activeFileObj] = mapInitialData(initialData)
    const [data, setData] = useState(dataObj)
    const [iosData, setIosData] = useState(data)
    const [activeFile, setActiveFile] = useState(activeFileObj)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)

    const kind = {
        iosCertificate: "C",
        mobileProvisioning: "M",
        pushCertificate: "P",
        iosCertPassword: "C",
        pushCertPassword: "P",
        iphone4: "SSIP4",
        iphone5: "SSIP5",
        iphone6: "SSIP6",
        iphonePlus: "SSIP6PLUS",
        ipadLandscape: "SSIPADRETINALANDSCAPE",
        ipadPortrait: "SSIPADRETINAPORTRAIT",
        defaultLandscape: "SSDEFAULTLANDSCAPE",
        defaultPortrait: "SSDEFAULTPORTRAITUPSIDEDOWN"
    }


    const app = useAppContext()
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false)


    
    const updateContainerAppDetails = async (newValues, errors) => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
        if(!err){
            const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                if(!err2) {
                    setContainerAppDetails({...data2.detail, genarate:false})
                }

            if(data.status.type!=="E") {
                if(!saveChangesPopup) {
                    if(data2.detail.containerApp.deviceType.includes("Android")) pageNumber(clickedBack?4:6)
                    else pageNumber(clickedBack?4:5) // check this whether calledback cond. needed or not
                }else closePopup(false)
            }
        }
    }
    const generateContainerApp = async (newValues, errors) => {
        const prjAction = ProjectSettingsActions(app());
        pageNumber(7)
        const [err, data] = await prjAction.createNewCA(projectName, {...newValues, genarate:true})
        // console.log("genarate results error data", err, data)
        if(!err) {
            const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                if(!err2) {
                    setContainerAppDetails({...containerAppDetails,...data2.detail})
                }

            if(data.status.type!=="E") {
                closePopup(false)
                // pageNumber(8)
            }
        }
        else {
            closePopup(false)
        }
    }

    const onSubmit = async (values, errors) => {
        let deviceType = containerAppDetails.containerApp.deviceType
        let fields = getValidationFields();
        let result = await validateValues(values, errors, fields, fileDefaultProps)
        if(!result) {
            let updateIosCertificate = {...iosData.iosCertificate, certificate:activeFile.iosCertificate, fileName:activeFile.iosCertificate.substring(activeFile.iosCertificate.lastIndexOf("/")+1),password:values.iosCertPassword, kind:kind.iosCertificate}
            let updatePushCertificate = {...iosData.pushCertificate, certificate:activeFile.pushCertificate, fileName:activeFile.pushCertificate.substring(activeFile.pushCertificate.lastIndexOf("/")+1),password:values.pushCertPassword, kind:kind.pushCertificate}
            let updateMobileProvisioning = {...iosData.mobileProvisioning, certificate:activeFile.mobileProvisioning, fileName:activeFile.mobileProvisioning.substring(activeFile.mobileProvisioning.lastIndexOf("/")+1),password:"", kind:kind.mobileProvisioning}

            let updateIphone4 = {...iosData.iphone4, certificate:iosData.iphone4.certificate, fileName:iosData.iphone4["certificate"].substring(iosData.iphone4["certificate"].lastIndexOf("/")+1), password:"", kind:kind.iphone4 }
            let updateIphone5 = {...iosData.iphone5, certificate:iosData.iphone5.certificate, fileName:iosData.iphone5["certificate"].substring(iosData.iphone5["certificate"].lastIndexOf("/")+1), password:"", kind:kind.iphone5 }
            let updateIphone6 = {...iosData.iphone6, certificate:iosData.iphone6.certificate, fileName:iosData.iphone6["certificate"].substring(iosData.iphone6["certificate"].lastIndexOf("/")+1), password:"", kind:kind.iphone6 }
            let updateIphonePlus = {...iosData.iphonePlus, certificate:iosData.iphonePlus.certificate, fileName:iosData.iphonePlus["certificate"].substring(iosData.iphonePlus["certificate"].lastIndexOf("/")+1), password:"", kind:kind.iphonePlus }
            let updateIpadLandscape = {...iosData.ipadLandscape, certificate:iosData.ipadLandscape.certificate, fileName:iosData.ipadLandscape["certificate"].substring(iosData.ipadLandscape["certificate"].lastIndexOf("/")+1), password:"", kind:kind.ipadLandscape }
            let updateIpadPortrait = {...iosData.ipadPortrait, certificate:iosData.ipadPortrait.certificate, fileName:iosData.ipadPortrait["certificate"].substring(iosData.ipadPortrait["certificate"].lastIndexOf("/")+1), password:"", kind:kind.ipadPortrait }
            let updateDefaultLandscape = {...iosData.defaultLandscape, certificate:iosData.defaultLandscape.certificate, fileName:iosData.defaultLandscape["certificate"].substring(iosData.defaultLandscape["certificate"].lastIndexOf("/")+1), password:"", kind:kind.defaultLandscape }
            let updateDefaultPortrait = {...iosData.defaultPortrait, certificate:iosData.defaultPortrait.certificate, fileName:iosData.defaultPortrait["certificate"].substring(iosData.defaultPortrait["certificate"].lastIndexOf("/")+1), password:"", kind:kind.defaultPortrait }


            let newValues = {...containerAppDetails,
                                containerAppCertificates:[
                                    ...containerAppDetails["containerAppCertificates"].filter(e => !Object.values(kind).includes(e.kind)),
                                    updateIosCertificate,
                                    updateMobileProvisioning,
                                    updateIphone4,
                                    updateIphone5,
                                    updateIphone6,
                                    updateIphonePlus,
                                    updateIpadLandscape,
                                    updateIpadPortrait,
                                    updateDefaultLandscape,
                                    updateDefaultPortrait
                                ]}
            if (updatePushCertificate.fileName) newValues = {...newValues, containerAppCertificates:[...newValues["containerAppCertificates"], updatePushCertificate]}
            // updateContainerAppDetails(newValues, errors)
            if(!saveChangesPopup && !clickedBack) {
                if(!deviceType.includes("Android")) generateContainerApp(newValues, errors)
                else {
                    hadEdit?updateContainerAppDetails(newValues, errors)
                    :pageNumber(6)
                }
            }
            else {
                updateContainerAppDetails (newValues, errors)
            }

        }
        else {
            if(clickedBack) setClickedBack(false)
        }
    }

    const UploadFile = ({title, name, displaySelect=true, width="100%", acceptType="", bottomText=""}) => {
  
        return (
            <div style={{width}}>
            <Label text={title}/>
            {displaySelect
            ?(
                <>
                <DragAndDropArea style={styles.iconDiv} name={name}>
                    <div className={styles.iconDisplayDiv}>
                        <div className={styles.iconImgCont} data-text-testid={name+"_file"}>
                            {!activeFile[name]?<div className={styles.iconImgFillCont}></div>
                            :activeFile[name].lastIndexOf("/")!==-1?activeFile[name].substring(activeFile[name].lastIndexOf("/")+1):activeFile[name]}
                        </div>
                        <Label className={styles.resolutionText} text={""} />
                    </div>
                    <div className={styles.iconUploadDiv}>
                        <Label text={"Drop a file here"}/>
                        <Label text={"or"}/>
                        <File type="file" id={name} name={name} accept={acceptType} style={{display:"none"}}/>
                        <Label className={"primary"} data-clickable-testid={"select-a-file"} style={{height: "calc(var(--spacer) * 4px)"}} text={"Select a file"} htmlFor={name}/> 
                        <Label className={styles.uploadFileText} text={bottomText} />
                    </div>
                </DragAndDropArea>
                <Error name={name}/>
                </>)
            :(
                <>
                <DragAndDropArea style={styles.uploadSplashScreenCont} name={name}>
                    <File type="file" name={name} id={name} accept={acceptType} style={{display:"none"}}/>
                    <div style={{width:"100%"}}>
                    <Label className={activeFile[name]?styles.hide:styles.uploadFileText} text={<div className={styles.bottomText}>{bottomText}</div>} htmlFor={name}/>
                        {activeFile[name]&&<div className={styles.imgCont}><img data-image-testid={name} src={activeFile[name]} style={{maxHeight:"68px"}}/></div>}
                    </div>
                </DragAndDropArea>
                <Error name={name}/>
                </> 
            )
            }
            </div>
        )
    }

    const fileDefaultProps = {
        iphone4: {size:1000000, acceptType:"image/*", width:640, height:960},
        iphone5: {size:1000000, acceptType:"image/*", width:640, height:1136},
        iphone6: {size:1000000, acceptType:"image/*", width:750, height:1334},
        iphonePlus: {size:1000000, acceptType:"image/*", width:1242, height:2208},
        ipadLandscape: {size:1000000, acceptType:"image/*", width:2048, height:1536},
        ipadPortrait: {size:1000000, acceptType:"image/*", width:1536, height:2048},
        defaultLandscape: {size:1000000, acceptType:"image/*", width:1024, height:768},
        defaultPortrait: {size:1000000, acceptType:"image/*", width:768, height:1024},

        iosCertificate: {acceptType:"application/x-pkcs12"},
        mobileProvisioning: {acceptType:".mobileprovision"},
        pushCertificate: {acceptType:"application/x-pkcs12"}

    }

    const onWatch = async (errors, values) => {
        setHadEdit(1)
        // console.log("values", values)
        // validateValue(errors, values.name, values.value)
        let name = values.name;
        let value = values.value;
        if(!(values.name == "iosCertPassword" || values.name == "pushCertPassword")) {
            let fileDefaults = fileDefaultProps[name];
            let result;
            if(!(values.name == "iosCertificate"|| values.name == "mobileProvisioning" || values.name == "pushCertificate")) {
                result = await validateImage(errors, name, value, fileDefaults.size, fileDefaults.acceptType, fileDefaults.width, fileDefaults.height )
            }
            else {
                result = validateValue(errors, values.name, values.value, fileDefaults.acceptType)
            }
            if(result) {
                let imageArrayBuffer = await readFileAsyncImage(values.value);
                setActiveFile( (prev) => ({...prev, [name]:(name=="iosCertificate"||name=="mobileProvisioning"||name=="pushCertificate")?value.name:`data:${values.value.type};base64,`+arrayBufferToBase64(imageArrayBuffer)}))
                await handleFileupload(errors,name, "ios_"+values.value.name,kind[name], imageArrayBuffer)
            }
            else {
                setActiveFile( (prev) => ({...prev, [name]:(name=="iosCertificate"||name=="mobileProvisioning"||name=="pushCertificate")?value.name:""}))
            }
        }else {
            validateValue(errors, values.name, values.value)
            setIosData( {...iosData, [name]:value})
        }
    }

    const handleFileupload = async (errors, name, fileName, comment, imageArrayBuffer) => {
        if(imageArrayBuffer) {
            const actions = ProjectSettingsActions(app())
            // errors.set(name, "uploading...")
            const [err, result] = await actions.uploadFile(projectName, containerAppId, comment, fileName, imageArrayBuffer)
            // errors.clear(name)
            if (!err) {
                // setUploadFileUrl({name:result.url})
                // console.log("beforecheck",iosData, {...iosData, [name]:{...iosData[name],certificate:result.url}})

                setIosData( {...iosData, [name]:{...iosData[name],certificate:result.url}})
                if(name==="iosCertificate"||name==="mobileProvisioning"||name==="pushCertificate")
                    setActiveFile ( (prev) => ({...prev, [name]:result.url}))

                // setData((prev) => ({...prev, [name]:{...data[name],certificate:result.url}}))
            }
        }
    }

    const getValidationFields = () => {
        let fields = [...Object.keys(iosData)]
        if(!(iosData?.pushCertificate?.certificate)) fields = fields.filter(e => e!=="pushCertPassword"&&e!=="pushCertificate")
        return fields;
    }
    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout( () => {
            if(hadEdit){
                submitHandler(e)
            }
            else {
                pageNumber(4)
            }
        },0)
    }
    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }
    const { Form, Label, Input, Error, File, DragAndDropArea, submitHandler } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"iosConfiguration"}
            title="Let's capture all the Apple related details"
            subtitle="Please upload all the below information as required by Apple for iOS apps"        
        >
            <Form>
                <div className={styles.formContent}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.contents}>
                            <div className={styles.individualContent}>
                                <UploadFile title={"Certificate"} name={"iosCertificate"} bottomText={".p12 only"} acceptType={"application/x-pkcs12"}/>
                            </div>
                            {iosData.iosCertificate?(<div className={styles.individualContent}>
                                <Label text={"Certificate Password"}/>
                                <Input style={{width:"100%"}} type="password" name={"iosCertPassword"} placeholder={"password"}/>
                                <Error name="iosCertPassword"/>
                            </div>):""}
                            <div className={styles.individualContent}>
                                <UploadFile title={"Mobile Provisioning"} name={"mobileProvisioning"} bottomText={".mobileprovision only"} acceptType={".mobileprovision"}/>
                            </div>
                            <div className={styles.individualContent}>
                                <UploadFile title={"Push Certificate (optional)"} name={"pushCertificate"} bottomText={".p12 only"} acceptType={"application/x-pkcs12"}/>
                            </div>
                            {iosData.pushCertificate?(<div className={styles.individualContent}>
                                <Label text={"Push Certificate Password"}/>
                                <Input style={{width:"100%"}} type="password" name={"pushCertPassword"} placeholder={"password"}/>
                                <Error name="pushCertPassword"/>
                            </div>):""}
                        </div>
                        <div className={styles.contents}>
                            <h4
                                className={styles.afModuleSubTitleVaried}
                            >
                                Splash Screens
                            </h4>
                            <div className={styles.individualContent}>
                                <UploadFile title={"iPhone 4"} bottomText={"(640px x 960px)"} name={"iphone4"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                                <UploadFile title={"iPhone 5"} bottomText={"(640px x 1136px)"} name={"iphone5"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                            </div>
                            <div className={styles.individualContent}>
                                <UploadFile title={"iPhone 6 to 11"} bottomText={"(750px x 1334px)"} name={"iphone6"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                                <UploadFile title={"iPhone Plus Devices"} bottomText={"(1242px x 2208px)"} name={"iphonePlus"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                            </div>
                            <div className={styles.individualContent}>
                                <UploadFile title={"iPad-Retina-Landscape"} bottomText={"(2048px x 1536px)"} name={"ipadLandscape"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                                <UploadFile title={"iPad-Retina-Portrait"} bottomText={"(1536px x 2048px)"} name={"ipadPortrait"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                            </div>
                            <div className={styles.individualContent}>
                                <UploadFile title={"Default-Landscape"} bottomText={"(1024px x 768px)"} name={"defaultLandscape"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                                <UploadFile title={"Default-Portrait"} bottomText={"(768px x 1024px)"} name={"defaultPortrait"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='afPopupFooter'>
                    <div>
                        <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                        <Input type="submit" value={containerAppDetails.containerApp.deviceType.includes("Android")?"Next":"Generate"} style={{width:"fit-content",padding:"calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px)"}}/>
                    </div>
                </div>
            </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default IosConfiguration