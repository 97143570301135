import React, { useEffect, useState } from 'react';
import useStyles from './style';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { TextField, InputAdornment } from '@material-ui/core';



function AutoComplete(props) {

    const [val, setVal] = useState(props.selectedRole);
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles();

 useEffect(()=>{
   setVal(props.selectedRole);
   if(props.setRole){
     props.setRole(props.selectedRole)
   }
 },[props.selectedRole])
 
    const handleChange =(event, newValue) => {
      setVal(newValue);
      if(props.setRole){
        props.setRole(newValue)
      }
      if(props.handleSelectValue){
        props.handleSelectValue(newValue);
      }
    };

  return (
           <Autocomplete
              value={val}
              className={classes.roleDropdown}
              classes={{paper:classes.paper,
                    popper:classes.popper,
                    option:classes.option}}
              open={props?.open?props.open:true}
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={props.options.map((option) => option.name ? option.name : option)}
              onChange={handleChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                margin="normal"
                variant={props?.variant?props.variant:"outlined"}
                autoFocus
                InputProps={{ ...params.InputProps, 
                    // type: 'search',
                    placeholder:'Search', 
                    startAdornment: (
                     <InputAdornment>
                       <SearchIcon fontSize='small'/>
                     </InputAdornment>
                      ) }}
                />
              )}
             />
    );
}

export default AutoComplete;
