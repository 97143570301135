import React, { useState, useEffect } from 'react';
import useStyles from './style';

import {
    Box,
    Grid,
    FilledInput,
} from '@material-ui/core';

import DragDrop from '../../../../../../common/components/FileUploadMini/index';
import { fileUploadAPI, clearState } from '../../../../../../helpers/container-app-generation';

export default function (props) {
    const classes = useStyles();

    const { fileUrl, containerAppDetails, setModified, isWebOnly } = props;
    const [dataValueProps, setDataVal] = useState([]);
    const [alertValidate, setAlertValidate] = useState(false);

    const [keyStore, setKeyStore] = useState();
    const [gsJson, setGsjson] = useState('');
    const [faSDKJson, setfaSDKJson] = useState('');
    const [keyPass, setKeyPass] = useState('');
    const [gcmc, setGcmc] = useState();
    const [apiKey, setAPIKey] = useState();
    const [phone, setPhone] = useState();
    const [tablet, setTablet] = useState();
    const [dataValue, setDataValue] = useState([]);

    let androidData = {
        "kind": "",
        "certificate": "",
        "fileName": "",
        "password": ""
    }

    useEffect(() => {
        if (containerAppDetails.containerAppCertificates != undefined
            && containerAppDetails.containerAppCertificates.length !== 0) {
            if (dataValue.length == 0) {
                // setDataVal(containerAppDetails.containerAppCertificates);
            }
            containerAppDetails.containerAppCertificates.map((e) => {
                if (e.kind == 'K') {
                    setKeyStore(e);
                    setKeyPass(e.password);
                }
                else if (e.kind == 'GCM') {
                    setGcmc(e.certificate);
                }
                else if (e.kind == 'API') {
                    setAPIKey(e.certificate);
                }
                else if (e.kind == 'SS') {
                    setPhone(e);
                }
                else if (e.kind == 'SSTAB') {
                    setTablet(e);
                }
                else if (e.kind == 'GSJSON') {
                    setGsjson(e);
                }
                else if (e.kind == 'FASDKJSON') {
                    setfaSDKJson(e);
                }
            })
            setDataValue(containerAppDetails.containerAppCertificates);
            handleSubmit(containerAppDetails.containerAppCertificates);

        }
        else {
            setKeyStore('');
            setKeyPass('');
            setGcmc('');
            setAPIKey('');
            setPhone('');
            setTablet('');
            setGsjson('');
            setfaSDKJson('');
            handleSubmit();
        }
    }, [containerAppDetails]);

    useEffect(() => {
        if (props.alertShow !== alertValidate) {
            setAlertValidate(props.alertShow);
        }

        if (fileUrl != '') {
            let objectData = {
                ...androidData,
                certificate: fileUrl.url,
                fileName: fileUrl.name,
                kind: fileUrl.kind
            }

            if (fileUrl.kind == 'K') {
                objectData = {
                    ...objectData,
                    password: keyPass
                }
                setKeyStore(objectData);
            }
            else if (fileUrl.kind == 'SS') {
                setPhone(objectData);
            }
            else if (fileUrl.kind == 'SSTAB') {
                setTablet(objectData);
            }else if (fileUrl.kind == 'GSJSON') {
                setGsjson(objectData);
            }else if (fileUrl.kind == 'FASDKJSON') {
                setfaSDKJson(objectData);
            }

            let data = handleJSON(objectData);
            props.androidDataValues(data);
            handleSubmit(data, fileUrl.kind);
            clearState();

        }

    }, [fileUrl, props.alertShow, setKeyStore, setPhone, setTablet,setGsjson,setfaSDKJson]);


    const handleJSON = (value) => {
        let filteredValue = dataValue.filter((e) => e.kind !== value.kind);

        let finalSet = [...filteredValue];
        if (["GCM", "API"].includes(value.kind) && value.certificate !== "")
            finalSet = [...filteredValue, value];
        else if (!["GCM", "API"].includes(value.kind))
            finalSet = [...filteredValue, value];

        setDataValue(finalSet);
        return finalSet;
    }

    const handleFile = (file, kind) => {
        fileUploadAPI(
            file,
            containerAppDetails.containerApp.id,
            props.projectDetail.name,
            kind
        );
        setModified(true);
    }

    const handlePassword = (value) => {
        let data = dataValue;
        if (dataValue.length > 0) {
            data = dataValue.map((e, i) => {
                if (e.kind == 'K') return { ...e, password: value }
                else return e;
            })
        }
        props.androidDataValues(data);
        handleSubmit(data);
        setModified(true);
    }

    const handleInput = (value, type) => {

        let objectData = {
            ...androidData,
            certificate: value,
            fileName: value,
            kind: type
        }

        if (type == 'GCM') {
            setGcmc(value);
        }
        else if (type == 'API') {
            setAPIKey(value);
        }

        let data = handleJSON(objectData);
        // console.log(data , " : finalset");
        props.androidDataValues(data);
        handleSubmit(data);
        setModified(true);
    }

    const validateValues = (kind, params) => {
        let data = params !== undefined ? params : dataValue;
        if (Object.keys(data).length !== 0) {
            if (kind == 'pass') {
                let filteredValue = data.filter((e) => e.kind == 'K');
                if (filteredValue[0] && filteredValue[0].password !== '') {
                    return true;
                }
                return false;
            }
            else {
                let filteredValue = data.filter((e) => e.kind == kind);
                if (Object.keys(filteredValue).length !== 0 && filteredValue !== [] && filteredValue !== undefined) {
                    return true;
                }
                return false;
            }
        }
        return false;

    }

    const handleSubmit = (params, kind = '') => {
        if (params != undefined) {
            setDataValue(params);
        }
        let data = params != undefined ? params : dataValue;
        if ((keyStore !== undefined && keyStore !== '' || validateValues('K', data) || kind == 'K') &&
            (keyPass !== undefined && keyPass !== '' || validateValues('pass', data)) &&
            (phone !== undefined && phone !== '' || validateValues('SS', data) || kind == 'SS') &&
            (tablet !== undefined && tablet !== '' || validateValues('SSTAB', data) || kind == 'SSTAB')) {
            props.valuesFilled(true, data);
        }
        else if (data.length != 0) {
            props.valuesFilled(false, data);
        }
        else if (props.pageCompletes) {
            props.valuesFilled(false, data);
        }
        if(isWebOnly){
            props.valuesFilled(true, data);
        }
    }



    return (
        <Grid xs={12} className={classes.androidContainer}>
            {/* <ClickAwayListener onClickAway={handleSubmit}> */}
            <Grid xs={isWebOnly ? 12 : 6} className='android-Box1'>
                {!isWebOnly && (<>
                    <Box>
                        <DragDrop
                            button={true}
                            title='Keystore'
                            extensionLabel='.keystore only.'
                            extension='.keystore'
                            value={keyStore}
                            notSelected={alertValidate}
                            selectedFile={(e) => handleFile(e, 'K')}
                        />


                    </Box>
                    {keyStore !== undefined && keyStore !== "" &&
                        <Box className='form-input'>
                            <label className={classes.boxlabel}>Keystore Password</label>

                            <FilledInput
                                placeholder="password"
                                value={keyPass}
                                type='password'
                                id='certPass'
                                onChange={(e) => {
                                    setKeyPass(e.target.value);
                                    handlePassword(e.target.value);
                                }}
                                onBlur={(e) => {
                                    handlePassword(e.target.value)
                                }}
                                disableUnderline
                                inputProps={{ maxLength: 200 }}
                                classes={{ root: classes.formInput }}
                            />
                            {(alertValidate && keyPass === '')
                                ? <small className='red'>Please enter the Password</small>
                                : ''}
                        </Box>
                    }
                    <Box className='form-input'>
                        <label className={classes.boxlabel}>Google Cloud Messaging Code (Optional)</label>

                        <FilledInput
                            placeholder="GCM code"
                            value={gcmc}
                            id='gcmc'
                            onChange={(e) => {
                                setGcmc(e.target.value);
                                handleInput(e.target.value, 'GCM')
                            }}
                            onBlur={(e) => {
                                handleInput(e.target.value, 'GCM')
                            }}
                            disableUnderline
                            inputProps={{ maxLength: 200 }}
                            classes={{ root: classes.formInput }}
                        />

                    </Box></>)}
                <Box className='form-input'>
                    <label className={classes.boxlabel}>API Key (Optional)</label>

                    <FilledInput
                        placeholder="API key"
                        value={apiKey}
                        id='apiKey'
                        onChange={(e) => {
                            setAPIKey(e.target.value);
                            handleInput(e.target.value, 'API')
                        }}
                        onBlur={(e) => {
                            handleInput(e.target.value, 'API')
                        }}
                        disableUnderline
                        inputProps={{ maxLength: 200 }}
                        classes={{ root: classes.formInput }}
                    />

                </Box>
            </Grid>
            {!isWebOnly && (<Grid xs={6} className='android-Box2'>
                <Box>
                    <label>Splash Screens</label>
                    <Box className='screen-box'>
                        <Box>
                            <DragDrop
                                button={false}
                                title='Android Phone'
                                label='(1242px x 2208px)'
                                value={phone}
                                notSelected={alertValidate}
                                size={{ width: '1242', height: '2208' }}
                                selectedFile={(e) => handleFile(e, 'SS')}
                                extension='image/*'
                                isUploading={validateValues('SS')}
                            />
                        </Box>
                        <Box>
                            <DragDrop
                                button={false}
                                value={tablet}
                                notSelected={alertValidate}
                                size={{ width: '1536', height: '2048' }}
                                selectedFile={(e) => handleFile(e, 'SSTAB')}
                                title='Android Tablet'
                                label='(1536 x 2048)'
                                extension='image/*'
                                isUploading={validateValues('SSTAB')}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className='form-input'>
                        <DragDrop
                            button={true}
                            title='google-services.json (Optional)'
                            extensionLabel='.json only.'
                            extension='.json'
                            value={gsJson}
                            notSelected={alertValidate}
                            selectedFile={(e) => handleFile(e, 'GSJSON')}
                        />
                    </Box>
                    <Box className='form-input'>
                        <DragDrop
                            button={true}
                            title='firebase-adminsdk.json (Optional)'
                            extensionLabel='.json only.'
                            extension='.json'
                            value={faSDKJson}
                            notSelected={alertValidate}
                            selectedFile={(e) => handleFile(e, 'FASDKJSON')}
                        />
                    </Box>

            </Grid>)}
            {/* </ClickAwayListener> */}
        </Grid>
    )
}