import React, { Component, version } from 'react';
import { connect } from 'react-redux'
import { CreateProejctDetails } from './layout';
import {
  fetchProjectsList,
  fetchAccordionData,
  rejectSelectedVersion,
  transportVersion,
  alertShow,
  updateSelectedVersion,
  clearDeleteResponse,
  addOrRemoveLanguage,
  getLanguageList,
  readLanguage
} from '../../helpers/create-project-details';


import { checkNewVersionInQA, fetchBusinessFunctionDetails } from '../../helpers/business-function';
import ReactDOM from 'react-dom';
import PillirGraph from '../../libraries/mxgraph-wrapper';
import { SHAPE_TYPES } from '../../libraries/mxgraph-wrapper/shape/types';
import { s3Url } from '../../utils/common';
import WorkflowGraph from '../business-function/components/graph/workflow';
import _ from 'lodash';

class ProjectDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      progressData: {},
      isRejected: {},
      isAccepted: {},
      postData: {},
      modalChangeText: {},
      currentUserTaskName: '',
      openUserTask: false,
      userTaskCell: null,
      masterListLang:[]
    }
    this.pillirGraph = null;
    this.UserTaskGraph = null;
    this.PageList = null;
    this.PageScriptList = null;
    this.graphContainer = React.createRef();
    this.userTask = React.createRef();
    this.navigateTab = this.navigateTab.bind(this)
    this.rejectTest = this.rejectTest.bind(this)
    this.acceptTest = this.acceptTest.bind(this)
    this.showAlert = this.showAlert.bind(this)
    this.reloadVersionList = this.reloadVersionList.bind(this);
    this.navToUserTask = this.navToUserTask.bind(this);
    this.navToBusinessFunction = this.navToBusinessFunction.bind(this);
    this.getScreenImage = this.getScreenImage.bind(this);
    this.openMultiLingual =this.openMultiLingual.bind(this);
    this.checkNewVersionInPRODbeforeTransport = this.checkNewVersionInPRODbeforeTransport.bind(this);
  }


  navigateTab = (path, url) => {
    this.props.history.push(`/Project/${this.props.match.params.id}/${path}`);
  }

  reloadVersionList() {
    let project = this.props.match.params.id
    let businessFunction = this.props.match.params.ProcessId
    fetchAccordionData(project, businessFunction,"","");
    fetchBusinessFunctionDetails(project, businessFunction, false)
    // this.fetchBusinessFuncGraph();
    // console.log("11111111111111");

  }

  componentDidMount() {
    this.fetchBusinessFuncGraph();
    getLanguageList((l)=>{
      this.setState({masterListLang:l});
    });
  }

  fetchBusinessFuncGraph() {
    let project = this.props.match.params.id
    let businessFunction = this.props.match.params.ProcessId
    fetchProjectsList();
    fetchAccordionData(project, businessFunction, "", "");
    fetchBusinessFunctionDetails(project, businessFunction, false, false,
      (details, cb) => { this.loadGraph(details.type === 'WFM'); }
    )
    // this.loadGraph();
  }

  _isGraphReadOnly = () => {
    return true;
  }
  openMultiLingual = ()=>{
    console.log("Open Menu");
    this.setState({ shareConfirm: true });

  }

  loadGraph(isWorkflow = false) {
    let container = ReactDOM.findDOMNode(this.graphContainer.current);
    // Checks if the browser is supported
    if (!isWorkflow)
      this.pillirGraph = new PillirGraph(container, this._isGraphReadOnly());
    else
      this.pillirGraph = new WorkflowGraph(container, this._isGraphReadOnly());
    this.pillirGraph.addLaneEventListener = () => { this.setState({ selectedRole: undefined, showRolePopup: true }) };
    this.pillirGraph.laneRoleData = [];
    this.pillirGraph.navToUserTask = this.navToUserTask;
    this.pillirGraph.readOnly = true;
    this.pillirGraph.getScreenImage = this.getScreenImage;
    this.pillirGraph._isGraphReadOnly = this._isGraphReadOnly;
    this.pillirGraph.openMultiLingual = this.openMultiLingual;

  }
  navToUserTask = (cell) => {

    this.pillirGraph.graph.selectionModel.clear();
    this.pillirGraph.graph.stopEditing();
    this.setState({ openUserTask: true });
    this.loadUserTask(cell);
  }

  navToBusinessFunction = () => {
    this.setState({ openUserTask: false, userTaskCell: undefined });
    // this.fetchBusinessFuncGraph();
  }

  loadUserTask = (userTaskCell) => {

    let userTaskContainer = ReactDOM.findDOMNode(this.userTask.current);
    this.UserTaskGraph = new PillirGraph(userTaskContainer, true);
    this.UserTaskGraph.processRequireLane = false;

    this.UserTaskGraph.editor.type = 'userTaskDetail';
    this.UserTaskGraph.type = 'userTaskDetail';
    this.UserTaskGraph.screens = [];
    this.UserTaskGraph.getScreenImage = this.getScreenImage;

    let bfd = this.pillirGraph.toJSON();
    let tasks = [];
    bfd.graph.lanes.map(e => { tasks = [...tasks, ...e.children] });
    let c = tasks.filter(e => e.type === SHAPE_TYPES.TASK && e.uid === userTaskCell.uid);
    if (c && c.length) {
      c.map(e => {
        if (e.childTask)
          tasks = [...tasks, ...e.childTask]
      });
    }
    this.UserTaskGraph.businessFunctionData = tasks;
    this.UserTaskGraph.isLoadingGraph = true;

    let task = c[0].childTask;
    this.UserTaskGraph.fromJSON({ task: task }, this.UserTaskGraph.graph);
    this.UserTaskGraph.setGraphTitle(this.props.match.params.ProcessId + '/' + userTaskCell.value);
    this.UserTaskGraph.navToBusinessFunction = this.navToBusinessFunction;
    this.UserTaskGraph.isLoadingGraph = false;

    this.setState({ userTaskCell: userTaskCell, userTaskGraph: this.UserTaskGraph });
  }

  getScreenImage = (obj) => {
    let response = [];
    if (obj != undefined) {
      var result = this.PageList.find(e => e.name === obj);

      if (result) {
        if (this.state.openUserTask) {
          this.UserTaskGraph.screens.push({ id: obj, image: s3Url(result.dataObject), pageId: result.id });
        } else {
          this.pillirGraph.screens.push({ id: obj, image: s3Url(result.dataObject), pageId: result.id });
        
        }

        const behaviorList = this.PageScriptList;
        if (behaviorList) {
          var newList = [];
          for (let i = 0; i < behaviorList.length; i++) {

            newList.push({ events: behaviorList[i].eventName, ...behaviorList[i] })

          }

          newList.map(child => {
            if (result.name == child.uiPageName) {
              if (Object.keys(response).length !== 0 && response.length !== 0) {
                let pushed = false;
                response.map(data => {
                  if (data.pageName == child.uiPageName) {
                    data.childTask.push(child);
                    pushed = true;
                  }
                })
                if (!pushed) {
                  response.push({ pageId: result.id, pageName: result.name, childTask: [child] });
                }
              }
              else {
                response.push({ pageId: result.id, pageName: result.name, childTask: [child] });
              }
            }
          })

        }

      }
    }
    return response;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.graphJson !== this.props.graphJson) {
      if (nextProps.graphJson.graph || nextProps.graphJson['drawing json']) {
        let json = nextProps.graphJson['drawing json'] ?
          nextProps.graphJson['drawing json'] : nextProps.graphJson
        this.PageList = json.PageList ? json.PageList : [];
        this.PageScriptList = json.PageScriptList ? json.PageScriptList : [];
        this.pillirGraph.isLoadingGraph = true;
        this.pillirGraph.fromJSON(json, this.pillirGraph.graph)
        this.pillirGraph.isLoadingGraph = false;
      }
    }
    if (nextProps.isRejected !== this.props.isRejected) {
      this.setState({ isRejected: nextProps.isRejected })
    }
    if (nextProps.isAccepted !== this.props.isAccepted) {
      this.setState({ isAccepted: nextProps.isAccepted })
    }
  }

  //  static getDerivedStateFromProps(props, state) {
  //   if (props.isRejected) {
  //     return {
  //       isRejected: props.isRejected,
  //       isAccepted :props.isAccepted
  //     };
  //   }
  //   return null;
  // }

  componentDidUpdate(prevProps, prevState) {
    let { projects, loadingProjects } = this.props;
    if (prevProps.projects !== projects && !loadingProjects) {
      const progressData = {
        warning: 0,
        active: 0,
        delete: 0,
        total: projects && projects.length
      }
      projects && projects.map(item => { progressData[item.status] = progressData[item.status] + 1 });
      this.setState({ progressData });
    }

    if (Object.keys(this.props.updateObjVersion).length > 0) {
      if (this.props.updateObjVersion.status != undefined) {

      }
      // clearDeleteResponse("accept");
    }
  }

  handleModal = ({ event, key, action }) => {
    if (action === "action") {
      //action logic or function goes here
    } else if (action === "onChange") {
      this.setState({ [key]: event.target.value });
    }
  }

  reset = () => {
    this.setState({ modalChangeText: {} })
  }

  rejectTest = (version, reasons) => {
    let fName = this.props.match.params.ProcessId
    let project = this.props.match.params.id
    rejectSelectedVersion(project, fName, version, reasons)
  }

  acceptTest = (data) => {

    let fName = this.props.match.params.ProcessId
    let project = this.props.match.params.id

    // transportVersion(project, fName, data.version)

    var postData = {
      bfName: fName,
      pName: project,
      version: data.version.version,
      status: data.type,
      comment: data.comment,
      reason: (data.reason != undefined) ? data.reason : ""
    }
    // updateSelectedVersion(postData);
    this.setState({ postData: postData });
  }

  versionApi = () => {
    if (this.state.postData) {
      updateSelectedVersion(this.state.postData);
    }
  }

  showAlert(message, type) {
    var param = {
      message: message,
      type: type
    }
    alertShow(param)
  }

  changeModalText = (value, type) => {
    const input = value;
    if ((input.length <= 250 && /^[a-zA-Z0-9\_\-\s]+$/i.test(input) && !(value.length === 1 && value === " ")) || input.length == 0) {
      var dict = this.state.modalChangeText;
      dict[type] = value;
      this.setState({ modalChangeText: dict })
    }
  }

  handleChange = (obj, data) => {
    const val = {
      limit: data,
      offset: 0,
    };
    //fetchAssetList(obj, val);
    console.log('projects used', {obj,data});
  };

  handlePermissionModal = (action, data, listProject) => {
    if (action == 'close') {
      this.setState({ shareConfirm: false });
    } else if (action == 'submit') {
        this.setState({ shareConfirm: false, });
    }
  };

  onChangesCheckbox = (data,cb)=>{
    let langs=this.state.masterListLang;
    let lobj=langs.find(l=>l.name===data.name);
    if(lobj){
      let payload={
        code:lobj.code,
        share:data.status
      }
      
      let fName = this.props.match.params.ProcessId;
      let project = this.props.match.params.id;
      let version =this.props.bfDetails.version;
      addOrRemoveLanguage(project,fName,version,payload,cb);
    }
  }

  onEditClick=(data)=>{
      let langs=this.state.masterListLang;
      let lobj=langs.find(l=>l.name===data.name);
      if(lobj){
        let lcode=lobj.code;
        let fName = this.props.match.params.ProcessId;
        let project = this.props.match.params.id;
        let version =this.props.bfDetails.version;
        let detail={fName,project,version,lcode};
        readLanguage(project,fName,version,lcode,(data)=>{
            let r=[];
            Object.keys(data.en).forEach(key=>{
              r.push({id:key,english:data.en[key],target:data[lcode][key]})
            })
            detail.payload=data[lcode];
            this.setState({editConfirm:{target:lobj.name,rows:r,detail}})
        });
      }
  }
  handleEditClose = ()=>{
     this.setState({editConfirm:null}); 
  }

  checkNewVersionInPRODbeforeTransport(cb) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    checkNewVersionInQA(project, businessFunction, cb)
  }



  render() {
    const { progressData } = this.state;
    const { projects, accordionData, loadingAccordionData, loadingProjects } = this.props;
    return (
      <CreateProejctDetails
        {...this.props}
        {...this.state}
        versionApi={this.versionApi}
        navigateTab={this.navigateTab}
        handleModal={this.handleModal}
        reset={this.reset}
        rejectTest={this.rejectTest}
        acceptTest={this.acceptTest}
        showAlert={this.showAlert}
        reloadVersionList={this.reloadVersionList}
        isRejected={this.state.isRejected}
        isAccepted={this.state.isAccepted}
        projects={projects}
        accordions={accordionData}
        progressData={progressData}
        loadingProjects={loadingProjects}
        loadingAccordionData={loadingAccordionData}
        previewAndPublish={this.props.previewAndPublish}
        appTransport={this.props.appTransport}
        changeModalText={this.changeModalText}
        handlePermissionModal={this.handlePermissionModal}
        checkNewVersionInPROD={this.checkNewVersionInPRODbeforeTransport}
        shareConfirm={this.state.shareConfirm}
        handleChange={this.handleChange}
        onChangesCheckbox={this.onChangesCheckbox}
        onEditClick={this.onEditClick}
        handleEditClose={this.handleEditClose}
        ref={{
          graphContainerRef: this.graphContainer,
          userTask: this.userTask,
        }}
      />
    )
  }
}

const mapStateToProps = ({ CreateProejctDetails, businessFunction, transport, previewAndPublish }) => {
  return {
    loadingProjects: CreateProejctDetails.loadingProjects,
    loadingAccordionData: CreateProejctDetails.loadingAccordionData,
    projects: CreateProejctDetails.projects,
    accordionData: CreateProejctDetails.accordionData,
    graphJson: businessFunction.graphJson,
    isRejected: CreateProejctDetails.isRejected,
    isAccepted: CreateProejctDetails.isAccepted,
    updateObjVersion: CreateProejctDetails.updateObjVersion,
    bfDetails: businessFunction.details,
    appTransport: transport,
    previewAndPublish: previewAndPublish,
    
  }
}

export default connect(mapStateToProps)(ProjectDetail)
