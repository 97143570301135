import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import {
  Paper,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Checkbox,
  Icon,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  RadioGroup,
  Radio,
  Button,
  LinearProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
  MoreVert,
  Check,
  BorderColor,
  BorderColorOutlined,
  CheckBoxOutlineBlank,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  CheckBox,
  ChevronRight,
  CheckOutlined,
  InfoRounded,
  ArrowRightAlt,
  ErrorOutline,
} from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StyledTableCell from '../TableCell';
import TableHeadSearchInput from '../TableHeadSearchInput';
import TableHeadSelectInput from '../TableHeadSelectInput';
import TableHeadMultiSelectInput from '../TableHeadMultiSelectInput';
import TableRowInput from '../TableRowInput';
import TableHeadPopper from '../TableHeadPopper';
import DropDown from '../DropDown/index.js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleSwitch from '../../components/ToggleSwitch/index';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import schedulerEditIcon from '../../../assets/images/Fillschedit.png';
import { pillirConstants } from '../../../constants';
import { configMessage } from '../../messages/config';
import useStyles from './style';
const StyledTableRow = withStyles((theme) => ({
  selected: {
    backgroundColor: 'transparent !important',
  },
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const CustomizedCircularProgress = withStyles((theme) => ({
  root: {
    color: theme.color.notsocoolgray,
  },
}))(CircularProgress);

const CustomizedLinearProgress = withStyles((theme) => ({
  root: {
    height: `${theme.spacer * 0.5}px`,
    backgroundColor: theme.color.coolgray10,
    borderRadius: '2px',
  },
  barColorPrimary: {
    borderRadius: '2px',
  },
}))(LinearProgress);

const CustomCheckBox = withStyles({
  root: {
    marginTop: '2px',
    color: '#CDD4E4',
    '&$checked': {
      color: '#CDD4E4',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default function (props) {
  const {
    tableColumns,
    classes,
    tableData,
    onTableRowClick = () => { },
    onInfoPress,
    emptyText,
    tableHeight,
    tableMinHeight = 'auto',
    rowKeySuffix,
    rowKey,
    loading,
    defaultRadioSelectIndex,
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const [rowCheckList, setrowCheckList] = React.useState([]);
  const [columnCheck, setcolumnCheck] = React.useState(false);
  const [allSelect, setallSelect] = React.useState([]);
  const tableRef = useRef();
  const openMenu = Boolean(anchorMenuEl);
  const ITEM_HEIGHT = 48;
  const styledClasses = useStyles();

  useLayoutEffect(() => {
    if (props.checkDisable != undefined && props.checkDisable == true) {
      setrowCheckList([]);
      setcolumnCheck(false);
      props.handleTableCheckBox();
    }
  });

  const handleChange = (event, column) => {
    const value =
      column.type == 'tag_text' ? event.target.value : event.target.value;
    props.handleChange(value, column);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (props.screenType === 'UserList') {
      setcolumnCheck(false);
      if (tableRef.current) {
        tableRef.current.scrollLeft = 0;
      }
      setrowCheckList([]);
      setcolumnCheck(false);
    }
    if (defaultRadioSelectIndex != undefined) {
      setSelectedRowIndex(defaultRadioSelectIndex);
    }
  }, [tableData]);

  const selectColumnCheck = (event) => {
    var array = [];
    if (!columnCheck) {
      {
        tableData.map((row, index) => {
          props.screenType === 'UserList'
            ? array.push(row.loginName)
            : props.screenType === 'workflowconsole' ? array.push(row.workflowId) : array.push(index);
        });
      }
      setrowCheckList(array);
      console.log("setrowCheckList", array);
    } else {
      setrowCheckList([]);
      console.log("setrowCheckList", []);
    }

    setcolumnCheck(!columnCheck);
    if (props.screenType === 'UserList') {
      props.selectColumnCheck(array);
    }
  };
  const selectRowCheck = (e, index , row) => {
    e.stopPropagation();
    if(props.screenType === 'workflowconsole'){
      if(rowCheckList.includes(row.workflowId)){
        console.log("rowCheckList",rowCheckList)
        const filteredArray = rowCheckList.filter(el => el != row.workflowId)
        console.log("filteredArray",filteredArray)
        setrowCheckList(filteredArray);
        setcolumnCheck(false);
        setallSelect(!allSelect);
      }
      else{
        // var array = rowCheckList;
        // array.push(index);
        console.log(rowCheckList)
        setrowCheckList((p)=>([...p,row.workflowId]));
        setallSelect(!allSelect);
        console.log("setrowCheckList", array);
      }
    }
    else{
      if (rowCheckList.includes(index)) {
        var array = rowCheckList;
        var currentIndex = array.indexOf(index);
        array.splice(currentIndex, 1);
        setrowCheckList(array);
        setcolumnCheck(false);
        setallSelect(!allSelect);
        console.log("setrowCheckList", array);
      } else {
        var array = rowCheckList;
        array.push(index);
        setrowCheckList(array);
        setallSelect(!allSelect);
        console.log("setrowCheckList", array);
      }
    }

  };
  const dateFormat = (dateTime) => {
    if (typeof dateTime !== 'undefined') {
      var d = new Date(dateTime.toString());
      var formattedDate =
        d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate();
      var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
      var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      var formattedTime = hours + ':' + minutes;
      return formattedDate + ' ' + formattedTime;
    } else {
      return;
    }
  };
  const handleToggleSwitch = (checkedValue, name) => {
    props.handleToggleSwitch(checkedValue, name);
  };

  const handleButtonClick = (checkedValue, name) => {
    props.handleButtonClick(checkedValue, name);
  };

  const isFunction = (key = "") => typeof props[key] === "function";

  const renderColCheckboxWithMenu = (column) => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        style={column.style}
      >
        <Box style={column.menuStyle}>
          <DropDown
            data={
              rowCheckList.length > 0
                ? props.roleNameSelected
                  ? props.roleHeaderDropdownData
                  : props.headerMenuOptions
                : []
            }
            clickEvent={(event, key) => {
              if (rowCheckList.length > 0) {
                props.handleMenuDropdown(event, key, rowCheckList, 'column');
              }
            }}
            icon='moreOptionsIcon'
            className={
              props.screenType === 'UserList' && rowCheckList.length < 1
                ? styledClasses.moreOptionCursor
                : ''
            }
          />
        </Box>
        {props.screenType === 'UserList' ? (
          <Checkbox
            disableRipple
            checked={columnCheck}
            icon={
              <CheckBoxOutlineBlank
                style={{ fontSize: 16, color: '#CDD4E4' }}
              />
            }
            checkedIcon={
              <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
            }
            onChange={(e) => selectColumnCheck(e)}
          />
        ) : (
          <Checkbox
            checked={columnCheck}
            icon={
              <CheckBoxOutlineBlank
                style={{
                  fontSize: 12,
                  width: 18,
                  height: 18,
                  color: 'white',
                  border: '1px solid #CDD4E4',
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{
                  fontSize: 12,
                  width: 18,
                  height: 18,
                  color: 'black',
                  fontColor: 'black',
                  border: '1px solid #CDD4E4',
                }}
              />
            }
            onChange={(e) => selectColumnCheck(e)}
          />
        )}
      </Grid>
    );
  };

  const renderColCheckbox = () => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <IconButton edge='end' onClick={handleClick}>
          <MoreVert style={{ fontSize: 16, color: '#CDD4E4' }} />
        </IconButton>
        <Checkbox
          checked={columnCheck}
          icon={
            <CheckBoxOutlineBlank style={{ fontSize: 16, color: '#CDD4E4' }} />
          }
          checkedIcon={<CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />}
          onChange={(e) => selectColumnCheck(e)}
        />
      </Grid>
    );
  };

  const renderColCheckboxTwo = () => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >

        <Checkbox
          checked={columnCheck}
          style={{padding:'0px', margin:'auto'}}
          icon={
            <CheckBoxOutlineBlank
              style={{
                fontSize: 12,
                width: 18,
                height: 18,
                color: 'white',
                border: '1px solid #CDD4E4',
              }}
            />
          }
          checkedIcon={
            <CheckOutlined
              style={{
                fontSize: 12,
                width: 18,
                height: 18,
                color: 'black',
                fontColor: 'black',
                border: '1px solid #CDD4E4',
              }}
            />
          }
          onChange={(e) => selectColumnCheck(e)}
        />
      </Grid>
    );
  };

  const renderColNoIconCheckbox = () => {
    return (
      <Grid
        container
        direction='row'
        justify='space-around'
        alignItems='center'
      >
        {/* <Checkbox
          checked={columnCheck}
          icon={
            <CheckBoxOutlineBlank style={{ fontSize: 16, color: '#CDD4E4' }} />
          }
          checkedIcon={<CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />}
          onChange={(e) => selectColumnCheck(e)}
        /> */}
      </Grid>
    );
  };
  const renderColButton = (column) => {
    return (
      <StyledTableCell key={column.id} align={column.align}>
        <Grid container direction='row' justify='space-around'>
          <Button
            variant='contained'
            color={column.color}
            onClick={(event, column) => handleButtonClick(event, column)}
            style={{
              justifyContent: 'left',
            }}
          >
            {column.buttonText}
          </Button>
        </Grid>
      </StyledTableCell>
    );
  };

  const renderColPlainText = (column) => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <span
          style={{
            maxWidth: column.style?.maxWidth,
            color: '#8C8C8C',
            fontSize: column.style?.fontSize || 13,
            fontWeight: 400,
          }}
        >
          {column.label || ''}
        </span>
      </Grid>
    );
  };

  const renderColMore = (column) => {

    return (

      <>
        {props.screenType === "workflowconsole" ?
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
            style={column.style}
          >
            <Box style={column.menuStyle}>
              <DropDown
                data={
                  isFunction("rowMenuOptions") ?  
                    props.rowMenuOptions({}, column, rowCheckList, -1) : 
                    props.rowMenuOptions
                }
                clickEvent={(event, key) => {
                  // if (rowCheckList.length > 0) {
                    props.handleMenuDropdown(event, key, rowCheckList, 'column');
                  // }
                }}
                icon='moreOptionsIcon'
              />
            </Box>
          </Grid> :

          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <IconButton
              edge='end'
              onClick={handleClick}
              className={
                props.screenType === 'userproperties' &&
                styledClasses.hideMoreOption
              }
            >
              <MoreVert
                style={{
                  fontSize: 16,
                  color: '#CDD4E4',
                  visibility:
                    props.screenType === 'userproperties' ? 'hidden' : 'visible',
                }}
              />
            </IconButton>
          </Grid>
        }
      </>
    );
  };

  const renderColMultiSelectDropdown = (column) => {
    return (
      <TableHeadMultiSelectInput
        dataValue={column.value}
        handleChange={(event) => handleChange(event, column)}
        options={column.dropdownItems}
        labelKey={column?.labelKey}
        valueKey={column?.valueKey}
        placeHolder={column?.placeHolder}
        disabled={column?.disabled}
        value={[]}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
      ></TableHeadMultiSelectInput>
    );
  };

  const renderColSingleSelectDropdown = (column) => {
    return (
      <TableHeadSelectInput
        handleChange={handleChange}
        options={column.dropdownItems}
        labelKey={column?.labelKey}
        valueKey={column?.valueKey}
        placeHolder={column?.placeHolder}
        disabled={column?.disabled}
        column={column}
        value={[]}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
        screenType={props.screenType}
      ></TableHeadSelectInput>
    );
  };

  const renderColText = (column) => {
    return (
      <TableHeadSearchInput
        value={column.value}
        placeholder={column.label}
        disabled={column?.disabled}
        onChange={(event) => handleChange(event, column)}
        screenType={props.screenType}
       // disabled={column?.disabled}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
      />
    );
  };

  const renderColPopper = (column) => {
    return (
      <TableHeadPopper 
      column={column}
      />
    )
  }

  const handleClickMenu = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    setAnchorMenuEl(null);
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {tableColumns.map((column) => {
          let tableHeadCellInput;
          let style = column.style ? column.style : {};
          if(column?.width) style = {...style, width: column.width}
          if (column.type === 'text') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'date') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'tag_text') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'fit_tag_text') {
            tableHeadCellInput = renderColText(column);
          } else if (
            column.type === 'dropdown' ||
            column.type === 'dropdown_with_tag'
          ) {
            if (column.isMultiSelect === true)
              tableHeadCellInput = renderColMultiSelectDropdown(column);
            else tableHeadCellInput = renderColSingleSelectDropdown(column);
          } else if (column.type === 'dropdown_with_toggle') {
            if (column.isMultiSelect === true)
              tableHeadCellInput = renderColMultiSelectDropdown(column);
            else tableHeadCellInput = renderColSingleSelectDropdown(column);
          } else if (column.type === 'toggle') {
            tableHeadCellInput = renderColSingleSelectDropdown(column);
          } else if (column.type === 'checkbox') {
            tableHeadCellInput = renderColCheckbox();
          }
          else if (column.type === 'checkbox-2') {
            tableHeadCellInput = renderColCheckboxTwo();
          }
          else if (column.type === 'checkbox-only') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'checkbox_with_menu') {
            tableHeadCellInput = renderColCheckboxWithMenu(column);
          } else if (column.type === 'more') {
            tableHeadCellInput = renderColMore(column);
          } else if (column.type === 'more_without_header') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'hyperlink') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'text_input') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'checkbox_without_icon') {
            tableHeadCellInput = renderColNoIconCheckbox(column);
          } else if (column.type === 'radio') {
            tableHeadCellInput = renderColNoIconCheckbox(column);
          } else if (column.type === 'row_dropdown') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'icon-button') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'button-w-header') {
            tableHeadCellInput = renderColButton(column);
          } else if (column.type === 'popper') {
            tableHeadCellInput = renderColPopper(column);
          }

          return (
            <StyledTableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                ...style,
              }}
            >
              {tableHeadCellInput}
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  const isRevertDisabledRow = (row) => {
    if (
      row.status !== undefined &&
      row.status === 'Approved' &&
      props.screenType === 'transportData'
    ) {
      return true;
    } else if (
      row.version != undefined &&
      props.revertDisableVersion != undefined &&
      props.revertDisableVersion === row.version &&
      props.screenType !== 'transportData'
    ) {
      return true;
    }
    return false;
  };

  const specifyRowHide = (row, column) => {
    if (column.specifyHide != undefined && column.specifyHide) {
      return true;
    } else if (
      row.version != undefined &&
      props.revertDisableVersion != undefined &&
      props.revertDisableVersion === row.version &&
      props.screenType !== 'transportData'
    ) {
      return true;
    }
    return false;
  };

  const tableBody = (row, index) => {
    const {
      isBackgroundChanged = false,
      backgroundColorField = '',
      handleCheckBox = () => null,
      handleActionButton = () => null,
      handleRowInputChange = () => null,
      handleIconClick = () => null,
      handleCallToAction = () => null,
      handleRowDropdownChange = () => null,
      onTableRowDoubleClick = () => null,
      onTableEditClick = () => null,
      canUpdate = true,
    } = props;
    return (
      <StyledTableRow
        hover
        key={
          rowKeySuffix && rowKey
            ? `tablerow_${rowKeySuffix}_${row[rowKey]}`
            : `tablerow_${Date.now()}${index}`
        }
        onClick={(event) => {
          const isCurrentRowDisable = isRevertDisabledRow(row);
          if (!isCurrentRowDisable) {
            onTableRowClick(event, row, index);
            setSelectedRowIndex(index);
          }
        }}
        onDoubleClick={(event) => onTableRowDoubleClick(event, row, index)}
        style={
          isBackgroundChanged
            ? {
              backgroundColor: row[backgroundColorField]
                ? '#F8D2D4'
                : 'transparent',
            }
            : {}
        }
      // selected={rowCheckList.includes(index) || columnCheck ? true : false}
      >
        {tableColumns.map((column) => {
          const type = column?.isRowType
            ? row.columnType
            : column.valueType
              ? column.valueType
              : column.type;
          const icon = column.icon;
          const url = column.url;
          const value = row[column.id];
          const placeholder = column.placeholder || '';
          const label = column.label || '';
          const inputStyle = column.inputStyle || {};
          const specifyRowDisable = specifyRowHide(row, column);
          const isCurrentRowDisable = isRevertDisabledRow(row);
          let style =
            column.rowStyle != undefined
              ? column.rowStyle
              : column.style
                ? column.style
                : {};
          let textStyle = {};
          if (column.maxWidth) {
            textStyle = {
              ...style,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: column.maxWidth,
              display: 'inline-block',
            };
          }
          if ((type === 'text'||type === 'popper') && value !== undefined && !column.sourceInfo) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  paddingLeft: 10,
                  ...style,
                  color:
                    specifyRowDisable && isCurrentRowDisable
                      ? '#BEBEBE'
                      : style.color,
                }}
              >
                <span style={{ ...textStyle }} title={value}>
                  {value}
                </span>
              </StyledTableCell>
            );
          } else if (
            type === 'text' &&
            value !== undefined &&
            column.sourceInfo &&
            column.sourceInfo.type === 'json-string'
          ) {
            let data = {};
            try {
              data = JSON.parse(value);
            } catch (e) {
              data = null;
            }
            return (
              <StyledTableCell
                key={column.id}
                // align={column.align}
                className={classes.flex}
                style={{
                  ...style,
                  display: 'flex !important',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                }}
              >
                <span style={{ ...textStyle, maxWidth: column.maxWidth }}>
                  {data?.[column.sourceInfo.key]}
                </span>
                {column.icon === 'info' && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => onInfoPress(row)}
                  >
                    <InfoRounded
                      style={{ fontSize: 18, color: '#CDD4E4', margin: 8 }}
                    />
                  </IconButton>
                )}
              </StyledTableCell>
            );
          } else if (type === 'date' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                {dateFormat(value)}
              </StyledTableCell>
            );
          } else if (type === 'tag_text' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Box
                  variant='div'
                  style={{
                    backgroundColor: '#F0F2F7',
                    padding: 10,
                    borderRadius: 4,
                    margin: '6px 0px',
                    width: 'inherit',
                  }}
                >
                  {' '}
                  {value}{' '}
                </Box>
              </StyledTableCell>
            );
          } else if (type === 'fit_tag_text' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Box
                  variant='div'
                  style={{
                    backgroundColor: '#F0F2F7',
                    padding: 10,
                    borderRadius: 4,
                    margin: '6px 0px',
                    width: 'fit-content',
                  }}
                >
                  {' '}
                  {value}{' '}
                </Box>
              </StyledTableCell>
            );
          } else if (type === 'dropdown_with_tag' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Grid container>
                  {value.map((val) => {
                    return (
                      <Box
                        variant='div'
                        style={{
                          backgroundColor: '#F0F2F7',
                          padding: 8,
                          borderRadius: 4,
                          margin: '6px 4px',
                          width: 'fit-content',
                        }}
                        item
                      >
                        {' '}
                        {val}{' '}
                      </Box>
                    );
                  })}
                </Grid>
              </StyledTableCell>
            );
          } else if (
            type === 'hyperlink' &&
            value !== undefined &&
            url !== undefined
          ) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Box
                  variant='div'
                  style={{
                    backgroundColor: 'rgb(240 242 247 / 50%)',
                    padding: 10,
                    borderRadius: 4,
                    margin: '6px 0px',
                    width: 'fit-content',
                  }}
                >
                  {' '}
                  {url != '' ? (
                    <a
                      href={url}
                      style={{
                        textDecoration: 'none',
                        color: '#0062FF',
                        fontWeight: '500',
                      }}
                    >
                      {value}
                    </a>
                  ) : (
                    <label
                      style={{
                        textDecoration: 'none',
                        color: '#0062FF',
                        fontWeight: '500',
                      }}
                    >
                      {value}
                    </label>
                  )}{' '}
                </Box>
              </StyledTableCell>
            );
          } else if (type === 'dropdown' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                {typeof value === 'string' ? (
                  column.isRowMultiTags ? (
                    <Grid
                      style={{
                        display: '-webkit-inline-box',
                        flexWrap: 'wrap',
                        marginLeft: '-8px',
                      }}
                    >
                      {value.split(',').map((val, index) => {
                        return (
                          <Box
                            variant='div'
                            style={{
                              backgroundColor: '#F0F2F7',
                              padding: 8,
                              borderRadius: 4,
                              margin: '6px 0px',
                              width: 'fit-content',
                              marginLeft: '8px',
                            }}
                          >
                            {val}
                          </Box>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      variant='div'
                      style={
                        props.screenType === 'UserList'
                          ? {
                            Width: '180px',
                            maxWidth: '180px',
                            minWidth: '180px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            backgroundColor: 'transparent',
                            padding: 8,
                            borderRadius: 4,
                            margin: '6px 0px',
                          }
                          : {
                            backgroundColor: 'transparent',
                            padding: 8,
                            borderRadius: 4,
                            margin: '6px 0px',
                            width: 'fit-content',
                          }
                      }
                      title={value}
                    >
                      {value}
                    </Box>
                  )
                ) : (
                  <div>
                    {
                      value ? (
                        <div
                          style={{
                            height: 16,
                            width: 16,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 8,
                            backgroundColor: '#F0F2F7',
                          }}
                        >
                          <Check style={{ fontSize: 12, color: '#0062FF' }} />
                        </div>
                      ) : (
                        ''
                      )
                      // <Close style={{ fontSize: 12, color: '#0062FF' }} />
                    }
                  </div>
                )}
              </StyledTableCell>
            );
          } else if (type === 'dropdown_with_toggle' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={schedulerEditIcon}
                    onClick={(e) => onTableEditClick(e, row, index)}
                    style={{
                      fontSize: ' 0.90em',
                      marginRight: '45px',
                      marginLeft: '10px',
                    }}
                  />
                  {
                  // row['eventId'] !== undefined ||
                  //   row['nextExecution'] !== undefined 
                    value !== 2 ? (
                    canUpdate ? (
                      <ToggleSwitch
                        id={row}
                        status={value}
                        toggle={handleToggleSwitch}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    <Typography
                      variant='h5'
                      style={{
                        position: 'relative',
                        left: '-10px',
                        color: '#A4A4A4',
                      }}
                    >
                      Draft
                    </Typography>
                  )}
                </div>
              </StyledTableCell>
            );
          } else if (type === 'toggle') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  marginLeft: 8,
                  ...style,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {canUpdate ? (
                    <ToggleSwitch
                      id={row}
                      status={value}
                      toggle={handleToggleSwitch}
                      style={{ marginLeft: '0px' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </StyledTableCell>
            );
          } else if (type === 'menu') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={column.style}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  style={column.style}
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props.rowMenuOptions}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, 'row');
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (
            type === 'checkbox_with_menu' &&
            props.screenType === 'UserList'
          ) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                >
                  {/* <IconButton edge='end'>
                    <ChevronRight style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton> */}
                  <Box style={column?.menuStyle}>
                    <DropDown
                      // data={row.roleName && props.roleNameSelected ? props.roleRowDropdownData(row) : props.rowMenuOptions(row)}
                      data={props.rowMenuOptions(row)}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>

                  <Checkbox
                    disableRipple
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index);
                    }}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox_with_menu') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={column.style}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  style={column.style}
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props.rowMenuOptions}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, 'row');
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>

                  <Checkbox
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'white',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'black',
                          fontColor: 'black',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    onChange={(e) => selectRowCheck(e, index)}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <IconButton edge='end'>
                    <ChevronRight style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton>

                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index);
                    }}
                  />
                </Grid>
              </StyledTableCell>
            );
          }
          else if (type === 'checkbox-2') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >

                  <Checkbox
                    checked={
                      props.screenType === "workflowconsole" ? rowCheckList.includes(row?.workflowId) : 
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    style={{padding:"0px", margin:'auto'}}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'white',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'black',
                          fontColor: 'black',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index , row);
                      handleCheckBox(e, row, index);
                    }}
                  />
                </Grid>
              </StyledTableCell>
            );
          }
          else if (type === 'radio') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  {specifyRowDisable && isCurrentRowDisable ? (
                    <></>
                  ) : (
                    <RadioGroup
                      name={'tableRadioButton-' + index}
                      value={selectedRowIndex.toString()}
                      onChange={(e) => {
                        onTableRowClick(e, row, index);
                        setSelectedRowIndex(index.toString());
                      }}
                    >
                      <Radio
                        onChange={(e) => {
                          onTableRowClick(e, row, index);
                          setSelectedRowIndex(index.toString());
                        }}
                        checkedIcon={
                          <span
                            className={clsx(
                              styledClasses.radioIcon,
                              styledClasses.radioCheckedIcon
                            )}
                          />
                        }
                        icon={<span className={styledClasses.radioIcon} />}
                        value={index.toString()}
                        id={'radio-' + index}
                        key={'radio-' + index}
                        name={'radio-button-' + index}
                      />
                    </RadioGroup>
                  )}
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'action') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <IconButton
                    onClick={(e) => {
                      handleActionButton(e, row, index);
                    }}
                  >
                    {/* {
                      row[column.id] !== configMessage.E4501 &&
                      <Check style={{ fontSize: 16, color: '#CDD4E4' }} className={row[column.id]}/>
                    } */}
                    {
                      row[column.id] === configMessage.E4501 &&
                      <ErrorOutline style={{ fontSize: 18 }} className={row[column.id]}/>
                    }
                  </IconButton>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox_without_icon') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      props.screenType=="libraryCAG"?row[column.id]:(rowCheckList.includes(index) || columnCheck ? true : false)
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 8,
                          width: 16,
                          height: 16,
                          color: '#fff',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 8,
                          width: 16,
                          height: 16,
                          color: '#222',
                          fontColor: '#222',
                          border: '1px solid #CDD4E4',
                          backgroundColor: '#F0F2F7',
                        }}
                      />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index);
                    }}
                   // checked={value}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'text_with_checkbox' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={true}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 20, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 20, color: '#CDD4E4' }} />
                    }
                  // onChange={onSelectAllClick}
                  />
                  {value}
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'more') {
            let options = [];
            if (props.rowMenuOptions && !isFunction("rowMenuOptions")) {
              options = [...props.rowMenuOptions];
              if (props.source === 'plugin' && row.type && row.type !== 'WS') {
                options.pop();
              }
            }
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={
                        isFunction("rowMenuOptions") ? 
                          props.rowMenuOptions(row, column, rowCheckList, index) : 
                          options
                      }
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'more_without_header') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props?.rowMenuOptions(row) || []}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox-only') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                justify={column.justify}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={row[column.id]}
                    disabled={column.disabled}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => selectRowCheck(e, index)}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'icon') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <IconButton
                    style={column.iconStyle ? column.iconStyle : {}}
                    edge='end'
                    onClick={() => handleIconClick(row)}
                  >
                    {value.toLowerCase() === 'finished' && (
                      <CheckCircleOutlineRounded
                        style={{ fontSize: 24, color: '#3bab52' }}
                      />
                    )}
                    {value.toLowerCase() === 'failed' && (
                      <ErrorOutlineRounded
                        style={{ fontSize: 24, color: '#db252e' }}
                      />
                    )}
                    {value.toLowerCase() === 'pending' && (
                      <CustomizedCircularProgress size={24} />
                    )}
                    {value.toLowerCase() === 'draft' && (
                      <BorderColorOutlined
                        style={{ fontSize: 18, color: '#0062ff' }}
                      />
                    )}
                    {value.toLowerCase() === 'info' && (
                      <InfoRounded style={{ fontSize: 18 }} color='primary' />
                    )}
                    {value.toLowerCase() === 'arrowright' && (
                      <ArrowRightAlt
                        style={{
                          fontSize: 18,
                          color: isBackgroundChanged
                            ? row[backgroundColorField]
                              ? '#db252e'
                              : '#CDD4E4'
                            : '#CDD4E4',
                        }}
                      />
                    )}
                  </IconButton>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'icon-button') {
            let Icon = value;
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <IconButton
                    disabled={
                      column['disabled']
                        ? column['disabled']
                        : column['disableField']
                          ? row[column['disableField']]
                          : false
                    }
                    style={
                      row.iconStyle
                        ? row.iconStyle
                        : column.iconStyle
                          ? column.iconStyle
                          : {}
                    }
                    onClick={() => handleIconClick(row)}
                  >
                    {Icon ? <Icon style={{ fontSize: '24px' }} /> : ''}
                  </IconButton>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'text_input') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <TableRowInput
                    value={value}
                    onChange={(e) => handleRowInputChange(e, row, index)}
                    placeholder={placeholder}
                    style={inputStyle}
                    inputProps={{ maxLength: column.maxLength || 200 }}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'button' || type === 'button-w-header') {
            const ButtonIcon = row?.ButtonIcon;
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >    
                {row.status === "error" ?(
                  <IconButton onClick={() => handleCallToAction(row, index)}>
                   <ErrorOutlineIcon style={{
                     color:"#d8000c"
                   }} />
                  </IconButton>
                ):(
                  <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => handleCallToAction(row, index)}
                  style={{
                    minWidth: '120px',
                    justifyContent: 'center',
                  }}
                  startIcon={ButtonIcon && <ButtonIcon />}
                >
                  {row?.buttonText && row?.buttonText}
                </Button>
                )}
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'row_dropdown') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <TableHeadSelectInput
                    handleChange={(e) => handleRowDropdownChange(e, row)}
                    isRowInput={true}
                    value={value}
                    options={
                      row?.dropdownItems?.length > 0
                        ? row.dropdownItems.map((e) => {
                          return { label: e, value: e };
                        })
                        : []
                    }
                    column={column}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'progress') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid container direction='row' alignItems='center'>
                  <CustomizedLinearProgress
                    value={40}
                    style={{ width: column.style.minWidth }}
                    variant={'determinate'}
                    color={'primary'}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              />
            );
          }
        })}
      </StyledTableRow>
    );
  };

  return (
    <Box>
      <TableContainer
        className={classes?.container}
        style={
          props.screenType == 'Project' || props.screenType === 'transportData'
            ? {}
            : props.screenType == 'userproperties'
              ? { overflowX: 'initial' }
              : { overflowY: 'scroll' }
        }
        ref={tableRef}
      >
        <Grid
          component='div'
          style={
            tableHeight == 'auto'
              ? { minHeight: tableMinHeight }
              : { height: tableHeight, minHeight: tableMinHeight }
          }
        >
          <Table stickyHeader aria-label='sticky table' style={props.screenType==='workflowconsole'?{tableLayout:'fixed'}:{}}>
            <TableHead>{tableHeader()}</TableHead>
            <TableBody
              style={
                props.screenType == 'userproperties'
                  ? { overflowY: 'scroll' }
                  : {}
              }
            >
              {tableData &&
                tableData.map((row, index) => {
                  return tableBody(row, index);
                })}
            </TableBody>
          </Table>

          {loading && (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '16px 0',
              }}
            >
              <CustomizedCircularProgress />
            </Box>
          )}

          {!loading && tableData && tableData.length === 0 && (
            <Box
              className={classes?.emptyListTxtMsg}
              style={{
                textAlign: 'center',
                marginTop: '100px',
                color: 'lightgray',
              }}
            >
              <label>{emptyText ? emptyText : pillirConstants.noRecords}</label>
            </Box>
          )}
        </Grid>
      </TableContainer>
    </Box>
  );
}

{
  /* <Box>
<IconButton edge="end" onClick={handleClickMenu}>
  <MoreVert style={{ fontSize: 16, color: '#CDD4E4' }} />
</IconButton>
<Menu id="long-menu" anchorEl={anchorMenuEl} keepMounted open={openMenu} onClose={handleCloseMenu}
  PaperProps={{
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: '20ch',

    },
  }}>
  {props.menuOptions.map((option) => (
    <MenuItem key={option} onClick={(event) => handleMenuItemClick(event)}>
      {option}
    </MenuItem>
  ))}
</Menu></Box> */
}
