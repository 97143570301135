import React, { useState, useEffect } from 'react';
import ExpandIcon from '../../assets/database-icons/expand-icon';
import { ReactComponent as AddLaneIcon } from '../../libraries/mxgraph-wrapper/images/addLane.svg';
import { ReactComponent as UserIcon } from '../../assets/userrole.svg';
import { Drawer, Typography, IconButton, CircularProgress } from '@material-ui/core';
import NewFunction from '../project-detail/new-function';
import NavItem from '../../common/components/NavItem';
import SearchInputField from '../../common/components/SearchInputField';
import TabMenu from './components/tab-menus';
import VariableBar from '../app-designer/components/variable-bar';
import OfflineProperties from './components/offline-properties';
import ConditionProperties from './components/condition-properties';
import CaseProperties from './components/case-properties';
import GroupProperties from './components/group-properties';
import MenuProperties from "./components/menu-properties";
import WorkflowStateSidebar from './components/workflow-state-sidebar';
import { getVarType } from '../../helpers/app-designer';
import KeyboardEventHandler from './keyevent-handler';
import { setRevertCompleted } from '../../helpers/preview-and-publish';
import ReactDOM from 'react-dom';
import {
  Container,
  Grid,
  Tab,
  Box,
  ClickAwayListener,
  Menu,
  MenuItem,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Button,
} from '@material-ui/core';
import VariablePopUp from '../../containers/integration-builder/components/variablepopup/index';
import LockNotification from '../../common/components/LockNotification';
import SubHeader from '../../common/components/SubHeader';
import RoleDropDown from './components/role-dropdown/index';
import PromptDialog from '../../common/components/PromptDialog';
import DMNtable from '../../containers/business-function/components/dmn-table';
import RevertVersion from '../business-function/components/revert-version';
import AppPreview from '../business-function/components/app-preview';
import InboxPreview from '../business-function/components/inbox-preview';
import Publish from '../business-function/components/publish';
import PreviewMobile from '../business-function/components/preview-mobile';
import PreviewBrowser from '../business-function/components/preview-browser';
import PreviewModal from '../business-function/components/app-process-model';
import Transport from '../business-function/components/app-transport';
import { preview, alertCopyMessage } from '../../helpers/preview-and-publish';
import { clearTransportFunctionResp } from '../../helpers/business-function';
import { SHAPE_TYPES } from '../../libraries/mxgraph-wrapper/shape/types';
import { getEnvironment, getLoggedInEnv, getPermissions, variablePermissions } from '../../utils/common';
import AssignmentProperties from './components/assignment-properties';
import EmailProperties from './components/email-properties';
import AddNewRole from './components/add-role';
import ChooseIcon from './components/icon-list';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import { apmMessage } from '../../common/messages/apm';
import AddLane from './components/add-lane';
import { AddOutlined, ExpandMore } from '@material-ui/icons';
import WorkItemFilter from './components/workitem-filter';
import TimerProperties from './components/timer-properties';

const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: "Home", url: "", selected: true });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: "Database", url: "Database", selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: "Drive", url: "Drive", selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: "Settings", url: "Settings", selected: false });
  }

  return arr;
};

export const ProcessDesignLayout = React.forwardRef((props, ref) => {
  // const {showModal}=props;
  const { sideBarRef, graphContainerRef, onCreateLane, userTask, timerEvents } = ref;
  const classes = useStyles();
  const [editFunctionModel, setEditFunctionModel] = useState(false);
  const [newVariable, setNewVariable] = useState(props.addVarModel);
  const [variableIndex, setVariableIndex] = useState();
  const [variableViewMode, setVariableViewMode] = useState(false);
  const [openConfirm, setOpenConfirm] = useState({ flag: false, varName: "" });
  const [openTransportConfirm, setOpenTransportConfirm] = useState(false)
  const [openEditVarConfirm, setOpenEditVarConfirm] = useState({
    flag: false,
    editObj: {},
  });
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showRevertModal, setshowRevertModal] = useState(false);
  const [showAppPreviewModal, setShowAppPreviewModal] = useState(false);
  const [showPreviewModal, setPreviewModal] = useState(false);
  const [showTransportModal, setShowTransportModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [publishModalShow, setPublishModalShow] = React.useState(false);
  const [selectedLaneMenu, setSelectedLaneMenu] = useState({});
  const [showPreviewMobile, setShowPreviewMobile] = useState(false);
  const [showPreviewBrowser, setShowPreviewBrowser] = useState({
    show: false,
    params: {},
  });
  const [iconList, setIconList] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [tab, setTab] = useState("toolbox");
  const [searchTerm, setSearchTerm] = useState("");
  const [varList, setVarList] = useState(props.varList);
  const [tools, setTools] = useState(props.tools);
  const [userTaskTools, setUserTools] = useState(props.userTaskTools);
  const [timerEventsTools, setTimerEventsTools] = useState(props.timerEventsTools);
  const [createDrag, setCreateDrag] = useState(false);
  const [globalVariables, setGlobalVariables] = useState([]);
  const [projectVariables, setProjectVariables] = useState([]);
  const [bfVariables, setBFVariables] = useState([]);
  const [workItems, setWorkItems] = useState([]);
  const [deleteRole, setDeleteRole] = useState(
    props.deleteRole,
    props.laneName
  );
  const [transportConfirm,setTransportConfirm] = useState(false);


  let workItemData = [
    { name: `id`, isMenu: false, dataType: 'String' },
    { name: `title`, isMenu: false, dataType: 'String' },
    { name: `subject`, isMenu: false, dataType: 'String' },
    { name: `state`, isMenu: false, dataType: 'String' },
    { name: `createdBy`, isMenu: true, dataType: "String" },
    { name: `updatedBy`, isMenu: true, dataType: "String" },
    { name: `assignedToUser`, isMenu: false, dataType: "Table" },
    { name: `assignedToRole`, isMenu: false, dataType: "String" },
    { name: `updatedOn`, isMenu: false, dataType: "Date" },
    { name: `createdOn`, isMenu: false, dataType: "Date" },
  ];
  const { canUpdate = () => null } = props;
  useEffect(() => {
    setVarList(props.varList);
  }, [props.varList]);
  useEffect(() => {
    setTools(props.tools);
  }, [props.tools]);
  useEffect(() => {
    setUserTools(props.userTaskTools);
    // console.log(props.userTaskTools);
  }, [props.userTaskTools]);
  useEffect(() => {
    setTimerEventsTools(props.timerEventsTools);
    // console.log(props.timerEventsTools);
  }, [props.timerEventsTools]);

  // Reset tool list when navigate to/from business-function/userTask
  useEffect(() => {
    setSearchTerm("");
    setTools(props.tools);
    setUserTools(props.userTaskTools);
    setTimerEventsTools(props.timerEventsTools); //?
  }, [props.userTaskCell]);

  useEffect(() => {
    if (props.details?.type === "WFM" && props.showModal) {
      setShowAppPreviewModal(true);
    }
    if (props.showModal) {
      setPreviewModal(true);
    } else {
      setPreviewModal(false);
    }
  }, [props.details, props.showModal]);

  useEffect(() => {
    if (_checkWorkflowFunction()) {
      workItemData = workItemData.map((item) => {
        let wItem = [{ id: "name", name: "name" }];
        if (item.isMenu) {
          if (props.userPropertyList && props.userPropertyList.length > 0) {
            props.userPropertyList.map((val) => {
              wItem.push({ id: val.propertyUuid, name: val.name });
            });
          }
        }
        return { ...item, item: wItem };
      });
      setWorkItems(workItemData);
    } else {
      setWorkItems(null);
    }
  }, [props.userPropertyList]);

  // useEffect(() => {
  // if (props.previewAndPublish.reverted === "yes" && revertFinish === true) {
  //   setTimeout(function () { //Start the timer
  //     props.reloadCanvasRevert();
  //     setRevertFinish(false);

  //   }.bind(this), 1000)
  // }

  // }, [revertFinish]);

  useEffect(() => {
    if (!!sideBarRef && !!sideBarRef.current) {
      const tasksDrag = ReactDOM.findDOMNode(
        sideBarRef.current
      ).querySelectorAll(".sideBarItem");
      if (props.pillirGraph)
        props.pillirGraph.createDragableElt(
          tasksDrag,
          tools,
          canUpdate() ? createDrag : false
        );
      if (tools.length > 0) setCreateDrag(false);
    }
  }, [tools, props.pillirGraph]);

  /* useEffect(() => {
    const tasksDrag = ReactDOM.findDOMNode(sideBarRef.current).querySelectorAll(
      '.sideBarUserTaskItem'
    );
    if (props.userTaskGraph)
      props.userTaskGraph.createDragableElt(tasksDrag, userTaskTools, false);
  }, [userTaskTools, props.userTaskGraph]); */

  useEffect(() => {
    setCreateDrag(true);
  }, []);

  useEffect(() => {
    return () => {
      handleMxpopupclose();
    };
  }, []);

  useEffect(() => {
    const projVar = filterTableValues(props.projVariable);
    const globalVar = filterTableValues(props.globalVariable);
    const bfVar = filterTableValues(props.varList.variable);
    setProjectVariables(_checkWorkflowFunction() ? projVar : null);
    setGlobalVariables(_checkWorkflowFunction() ? globalVar : null);
    setBFVariables(bfVar);
  }, [props.projVariable, props.varList.variable, props.globalVariable]);

  useEffect(() => {
    if (props.addVarModel) setNewVariable(false);
  }, [props.addVarModel]);
  useEffect(() => {
    if (props.deleteRole) setDeleteRole(false);
  }, [props.deleteRole]);


  const canDelete = () => {
    return getPermissions()?.projects?.business_function?.canDelete;
  };

  const handleNewVariable = (e) => {
    if (variablePermissions()?.canCreate) {
      setVariableViewMode(true);
    } else {
      setVariableViewMode(false);
    }
    setNewVariable(true);
    props.clearCreateSuccess(false);
  };

  const filterTableValues = (val) => {
    if (val && val.length > 0) {
      return val.filter((item) => item.type !== "Table");
    }
    return [];
  };

  const handleEditVariable = (params) => {
    let arr = [];
    arr.push(params);
    props.clearCreateSuccess(false);
    setVariableIndex(arr);
    if (variablePermissions()?.canUpdate) {
      setVariableViewMode(true);
    } else {
      setVariableViewMode(false);
    }
    setNewVariable(true);
  };

  const openDeleteRole = () => {
    props.handleDeleteRole(props.laneName, props.laneCell);
    props.handleClose();
  };

  const closeEditvariable = () => {
    setOpenEditVarConfirm({ flag: false, editObj: {} });
    setNewVariable(false);
    setVariableIndex();
    setVariableViewMode(false);
  };

  const submitEditVariable = () => {
    let obj = openEditVarConfirm.editObj;
    props.handleAddVariable(obj.obj, obj.flag, obj.variableIndex);
    closeEditvariable();
  };

  const openConfirmPopup = (varName) => {
    setOpenConfirm({ flag: true, varName: varName });
  };

  const popupClose = () => {
    setOpenConfirm({ flag: false, varName: "" });
  };

  const closeTransportConfirm = () => {
    setOpenTransportConfirm(false)
    // setShowTransportModal(false);
    // setCurrentPage(1);
    setTransportConfirm(false)
  }

  const handleTransportConfirm = () => {
    setTransportConfirm(true)
    setShowTransportModal(true);
    clearTransportFunctionResp();
    // closeTransportConfirm()
  }

 
  const deleteVariable = () => {
    props.deleteVariable(openConfirm.varName);
    popupClose();
  };

  const handleMxpopupclose = () => {
    let el = document.getElementsByClassName("mxPopupMenu");
    if (el[0]) el[0].remove();
  };

  const togglePreviewMenu = (event) => {
    if (props.details.type === "WFM") {
      setShowAppPreviewModal(true);
    } else {
      if (anchorEl) {
        setAnchorEl(null);
        setPublishModalShow(false);
        setSelectedLaneMenu({});
      } else {
        if (lanesAvail()) {
          if (isValidAppPreview(props.graphJson.lanes[0].name)) {
            setAnchorEl(event.currentTarget);
            setPublishModalShow(true);
          }
        }
      }
    }
  };

  const handleCopyAlert = () => {
    alertCopyMessage();
  };

  const toogleSelectedLaneMenu = (e) => {
    let laneMenus = { ...selectedLaneMenu };
    laneMenus[e] = !laneMenus[e];
    setSelectedLaneMenu(laneMenus);
  };

  const lanesAvail = () => {
    if (Object.keys(props.graphJson).length > 0) {
      if (props.graphJson.lanes != undefined) {
        return props.graphJson.lanes.length > 0 ? true : false;
      }
    }
    return false;
  };

  const isValidAppPreview = (lName) => {
    let isEnd = false,
      isUserTask = false;
    let lanesVal = props.graphJson.graph.lanes;
    lanesVal.map((lane) => {
      lane.children.map((ch) => {
        if (ch.type === "End") isEnd = true;
        if (
          lName === lane.name &&
          (ch.type === "Task" ||
            ch.type === "Screen" ||
            ch.type == "ServiceTask")
        )
          isUserTask = true;
      });
    });
    return isEnd && isUserTask;
  };

  const handlePublishOption = (index) => {
    if (lanesAvail()) {
      handleClickPreviewOption(
        props.graphJson.lanes[0],
        previewPlatoformOptions[index].id,
        0
      );
    }
  };

  const handleClickPreviewOption = (lane, option, index) => {
    let isPreview = isValidAppPreview(lane.name);
    let ProjectName = props.match.params.id || "";
    let BusinessFunction = props.match.params.ProcessId || "";
    let Lane = lane.name || "";
    if (index === 0) {
      if (true) {
        if (option === "inbrowser") {
          preview(ProjectName, BusinessFunction, Lane);
          setShowPreviewBrowser({
            show: true,
            params: {
              ProjectName,
              BusinessFunction,
              Lane,
            },
          });
        } else if (option === "indevice") {
          preview(ProjectName, BusinessFunction, Lane);
          setShowPreviewBrowser({
            show: true,
            params: {
              ProjectName,
              BusinessFunction,
              Lane,
            },
          });
          // togglePreviewMenu();
          // setShowPreviewMobile(true);
        }
      } else {
        // props.showAlert(apmMessage.W3506);
      }
    } else {
      props.showAlert(apmMessage.W3507);
    }
  };

  const handleVersionRevertShow = () => {
    setshowRevertModal(true);
  };

  const previewPlatoformOptions = [
    { name: "In Browser", id: "inbrowser" },
    { name: "In Device", id: "indevice" },
  ];

  const renderLaneItems = () => {
    let laneItems = [];
    if (
      props.graphJson &&
      props.graphJson.lanes &&
      props.graphJson.lanes.length > 0
    ) {
      props.graphJson.lanes.map((lane, i) => {
        laneItems.push(
          <>
            <ListItem
              button
              key={lane.name}
              onClick={() => {
                toogleSelectedLaneMenu(lane.name);
              }}
              divider={
                i < props.graphJson.lanes.length - 1 ||
                selectedLaneMenu[lane.name]
              }
            >
              <ListItemText primary={lane.name} />
              {selectedLaneMenu[lane.name] ? (
                <ExpandIcon className="expandLess" />
              ) : (
                <ExpandIcon />
              )}
            </ListItem>
            <Collapse
              key={`collapsed_${lane.name}`}
              component="li"
              in={selectedLaneMenu[lane.name]}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>
                {previewPlatoformOptions.map((o, k) => {
                  return (
                    <ListItem
                      button
                      key={o.id}
                      onClick={() => handleClickPreviewOption(lane, o.id, i)}
                      classes={{
                        root: classes.deviceOptionItem,
                      }}
                      divider={
                        k === previewPlatoformOptions.length - 1 &&
                        i < props.graphJson.lanes.length - 1 &&
                        selectedLaneMenu[lane.name]
                      }
                    >
                      <ListItemText
                        key={`optiontext_${o.id}`}
                        primary={o.name}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </>
        );
      });
    } else {
      laneItems.push(<MenuItem>No Lane Exist</MenuItem>);
    }
    return laneItems;
  };


  const tabMenu2 = (
    <Grid xs={5} className={`databaseRightGrid`}>
      <TabMenu
        {...props}
        classes={classes}
        setOpenTransportConfirm={()=>{
          props.checkNewVersionInQA({
            successCb:()=>setOpenTransportConfirm(true),
            failureCb:()=>{
              setShowTransportModal(true);
              clearTransportFunctionResp();
            }})
        }}
        setShowTransportModal={(show) => {
          setShowTransportModal(show);
          clearTransportFunctionResp();
        }}
        setShowPublishModal={(show) => {
          setShowPublishModal(show);
        }}
        togglePreviewMenu={togglePreviewMenu}
        handleVersionRevertShow={handleVersionRevertShow}
      />
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={togglePreviewMenu}
        classes={{
          paper: classes.previewMenu,
        }}
      >
        {renderLaneItems()}
      </Menu> */}
    </Grid>
  );

  const middleTab =
    props.type === "function" ? (
      <Grid xs={2} className={classes.middleTab}>
        <NavItem
          key="edit-function"
          className={classes.navItem}
          identifier="edit-function"
          title={props.businessFunction.name}
          items={
            getPermissions()?.projects?.business_function?.canUpdate
              ? [{ name: "Settings" },{ name: "Edit App Details"}]
              : []
          }
          editFunction={() => setEditFunctionModel(true)}
          openEditAppDetails={props.openEditAppDetails}
        />
      </Grid>
    ) : (
      <Grid xs={6} />
    );

  const variableMenuValue = () => {
    let array = [];
    if (variablePermissions()?.canDelete) {
      array.push({ name: "Edit" });
      array.push({ name: "Delete" });
    } else {
      array.push({ name: "Edit" });
    }
    return array;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    searchItems(e.target.value);
  };

  const searchItems = (value) => {
    if (props.varList && props.varList.variable) {
      let arr1 = props.varList.variable.filter((v) =>
        v.name.toLowerCase().includes(value.toLowerCase())
      );
      if (arr1 && arr1.length) setVarList({ variable: arr1 });
    }
    let arr2 = props.tools.filter((t) =>
      t.label.toLowerCase().includes(value.toLowerCase())
    );
    setTools(arr2);
    let arr3 = props.userTaskTools.filter((t) =>
      t.label.toLowerCase().includes(value.toLowerCase())
    );
    setUserTools(arr3);
    let arr4 = props.timerEventsTools.filter((t) =>
      t.label.toLowerCase().includes(value.toLowerCase())
    );
    setTimerEventsTools(arr4);
  };

  const switchTab = (tab) => {
    setTab(tab);
    setSearchTerm("");
    searchItems("");
  };

  const _checkWorkflowFunction = () => {
    if (props?.details?.type === "WFM") {
      return true;
    }
    return false;
  };
  const parseMenuLink = () => {
    let links = [];
    if (props.currentCell?.data) {
      try {
        links = JSON.parse(props.currentCell.data);
      } catch (error) {}
    }
    return links;
  };


  const getLinkOptions = () => {
    let options = props.currentCell?.parent?.children;
    return options;
  };

  return (
    <>
      {props.match.params.id && (
        <Box
          component="div"
          key={props.match.params.id}
          className={
            props.fullScreenMode
              ? "business-header active-business-header"
              : "business-header"
          }
        >
          {/* TOBEMODIFIED: Canview permission breaks the graph loading and it crashes when i refresh the page*/}
          <SubHeader
            type="function"
            menus={!props.isLoginMicroApp() ? subHeaderMenus() : []}
            middleTab={middleTab}
            rightTab={tabMenu2}
            navigateTab={props.navigateTab}
          ></SubHeader>
          <Container
            className={classes.businessProcessContent}
            style={{ paddingLeft: 0 }}
          >
            <Box>
              <Box
                component="div"
                className={`process-panel ${classes.processPanel}`}
              >
                <Box component="div" className={classes.sidebarHeader}>
                  {/* {canUpdate()
                  ?  */}
                  <>
                    <label
                      onClick={() => {
                        switchTab("toolbox");
                      }}
                      className={tab === "toolbox" ? classes.selectedTab : ""}
                    >
                      Components
                    </label>
                    <label
                      onClick={() => {
                        switchTab("variable");
                      }}
                      style={{ marginLeft: "106px" }}
                      className={tab === "variable" ? classes.selectedTab : ""}
                    >
                      Variable
                    </label>
                  </>
                  {/* : <>
                    <label
                    style={{ marginLeft: '120px' }}
                    className={classes.selectedTab}
                  >
                    {' '}Variable{' '}
                  </label></>
                  } */}
                </Box>

                {/* {
                canUpdate() ? */}
                <Box
                  className={classes.panelTools}
                  style={{ display: tab !== "variable" ? undefined : "none" }}
                  ref={sideBarRef}
                >
                  {tools.map((item) => {
                    return (
                      <Box
                        component="div"
                        style={{
                          display: !props.openUserTask&&!props.openTimerEvents ? undefined : "none",
                        }}
                        className={classes.gridItem}
                      >
                        <Box
                          className={classes.businessProcessTools}
                          key={item.type}
                        >
                          <Box
                            className={`sideBarItem ${classes.cardHeader} ${
                              !canUpdate() ? classes.noCursor : ""
                            }`}
                            data-value={item.type}
                          ></Box>
                          <Box className={classes.cardBody}>
                            <Box className={classes.cardTitle}>
                              {item.label}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                  {props.openUserTask &&
                    userTaskTools.map((item) => (
                      <Box component="div" className={classes.gridItem}>
                        <Box
                          className={classes.businessProcessTools}
                          key={item.type}
                        >
                          <Box
                            className={`sideBarUserTaskItem ${
                              classes.cardHeader
                            } ${!canUpdate() ? classes.noCursor : ""}`}
                            data-value={item.type}
                          ></Box>
                          <Box className={classes.cardBody}>
                            <Box className={classes.cardTitle}>
                              {item.label}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {props.openTimerEvents &&
                    timerEventsTools.map((item) => (
                      <Box component="div" className={classes.gridItem}>
                        <Box
                          className={classes.businessProcessTools}
                          key={item.type}
                        >
                          <Box
                            className={`sideBarTimerEventsItem ${
                              classes.cardHeader
                            } ${!canUpdate() ? classes.noCursor : ""}`}
                            data-value={item.type}
                          ></Box>
                          <Box className={classes.cardBody}>
                            <Box className={classes.cardTitle}>
                              {item.label}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {[...Array(5)].map(() => {
                    return (
                      <Box component="div" className={classes.gridItem}></Box>
                    );
                  })}
                </Box>
                {/* : (<Box component='div' className={classes.varList}>
                    {varList &&
                      varList.variable &&
                      varList.variable.map((variable, i) => {
                        return (
                          <Box component='div' className={classes.variable}>
                            <Box component='div' className={classes.varName}>
                              {variable.name}
                            </Box>
                            <Box component='div' className={classes.varVal}>
                              {variable.dataType?variable.dataType:'Table'}
                            </Box>
                            <NavItem
                              key='variableMoreIcon'
                              className={classes.varMoreIcon}
                              identifier='variableMoreIcon'
                              items={variableMenuValue()}
                              deleteVariable={() =>
                                openConfirmPopup(variable.name)
                              }
                              editVariable={() => {
                                handleEditVariable(variable)
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>)} */}

                {tab === "variable" && (
                  <Box component="div" className={classes.varList}>
                    {varList &&
                      varList.variable &&
                      varList.variable.map((variable, i) => {
                        return (
                          <Box component="div" className={classes.variable}>
                            <Tooltip title={variable.name}>
                              <Box component="div" className={classes.varName}>
                                {variable.name}
                              </Box>
                            </Tooltip>
                            <Box component="div" className={classes.varVal}>
                              {variable.type !== "Table"
                                ? variable.dataType
                                : "Table"}
                            </Box>
                            <NavItem
                              key="variableMoreIcon"
                              className={classes.varMoreIcon}
                              identifier="variableMoreIcon"
                              items={variableMenuValue()}
                              deleteVariable={() =>
                                openConfirmPopup(variable.name)
                              }
                              editVariable={() => {
                                handleEditVariable(variable);
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                )}
                <Box component="div" className={classes.sidemenuSearch}>
                  {getPermissions()?.projects?.business_function
                    ?.business_function_variable?.canCreate &&
                    tab === "variable" && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "75%", marginBottom: 8 }}
                        onClick={(e) => handleNewVariable(e)}
                        startIcon={<AddIcon />}
                      >
                        {" "}
                        Add variable{" "}
                      </Button>
                    )}
                  <SearchInputField
                    changeValue={handleSearch}
                    value={searchTerm}
                    propContainer={{
                      searchContainer: classes.searchContainer,
                      searchIcon: classes.searchIcon,
                      startAdornment: false,
                      endAdornment: true,
                    }}
                  />
                </Box>
              </Box>
              {/* } */}
              {
                props.isLoading &&(<>
                  <Grid xs={9} className='process-canvas'>
                    <CircularProgress color="secondary" className={classes.loader}/>
                  </Grid>
                  <div  
                  id={'overlayDiv'}
                  style={{
                    zIndex: 1200,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    //background: 'rgba(76, 149, 205, 0.7)',
                  }}></div>
                </>)
              }
              {props.openUserTask && props.match.params.id ? (
                <Grid xs={9} className={classes.graphArea}
                  style= {{ visibility: props.isLoading ? "hidden" : "visible" }}
                >
                  {' '}
                  {/* 'process-canvas'> */}
                  <ClickAwayListener onClickAway={handleMxpopupclose}>
                    <Box
                      component="div"
                      className={classes.graphContainer}
                      ref={userTask}
                      id="divGraph"
                    ></Box>
                  </ClickAwayListener>
                  <KeyboardEventHandler {...props} />
                </Grid>
              ) : props.openTimerEvents && props.match.params.id ? (
                <Grid xs={9} className={classes.graphArea}
                  style= {{ visibility: props.isLoading ? "hidden" : "visible" }}
                >
                  {' '}
                  {/* 'process-canvas'> */}
                  <ClickAwayListener onClickAway={handleMxpopupclose}>
                    <Box
                      component="div"
                      className={classes.graphContainer}
                      ref={timerEvents}
                      id="divGraph"
                    ></Box>
                  </ClickAwayListener>
                  <KeyboardEventHandler {...props} />
                </Grid>
              ) :(
                <Grid xs={9} className='process-canvas'
                  style= {{ visibility: props.isLoading ? "hidden" : "visible" }}
                >
                  <ClickAwayListener
                    onClickAway={() => {
                      if (!props.openUserTask && !props.openTimerEvents ) handleMxpopupclose();
                    }}
                  >
                    <Box
                      component="div"
                      className={classes.graphContainer}
                      ref={graphContainerRef}
                      id="divGraph"
                    ></Box>
                  </ClickAwayListener>
                  {/* <Box className={classes.collapsedLane}>
                    {
                        props.lanes.length > 0 &&
                        props.lanes.map(e => {
                          if(e.name !== props.currentLane?.role)
                            return(
                              <Box className={classes.indvLane}>
                                <UserIcon />
                                <Typography className={'role'}>{e.name || ""}</Typography>
                                <ExpandMore />
                              </Box>
                            )
                          else return null;
                        })
                    }
                    <Box className={classes.indvLane}>
                      <IconButton className={classes.newLaneBtn}
                        onClick={props.addLaneButtonClick}>
                        <AddLaneIcon />
                      </IconButton>
                    </Box>
                  </Box> */}
                  <KeyboardEventHandler {...props} />
                </Grid>
              )}
              <Grid>
                {
                  props.variablePanel &&
                  <ConditionProperties
                    show={props.variablePanel}
                    showAlert={props.showAlert}
                    onClose={props.setVariablePanel}
                    projVariable={projectVariables}
                    variableList={bfVariables}
                    globalVariable={globalVariables}
                    workItemList={workItems}
                    userPropertyList={props.userPropertyList}
                    updateConditionProperties={props.updateConditionProperties}
                    currentCell={props.currentCell}
                    searchExistingCell={props.searchExistingCell}
                    checkDataTypes={props.checkDataTypes}
                    makeConditionString={props.makeConditionString}
                  />
                }
                  {/* <VariableBar
                    varList={varList}
                    updateComponent={props.selectedXORvariable}
                    isValidVariable={(varName) => {
                      let varType = getVarType(varName, varList.variable);
                      return varType !== 'Table';
                    }}
                    onClose={props.setVariablePanel}
                    setVariableKeyName={(keyName) => {
                      if (!keyName) {
                        props.setVariablePanel();
                      }
                    }}
                    projVariable={projectVariables}
                    variableList={bfVariables}
                    globalVariable={globalVariables}
                    userPropertyList={props.userPropertyList}
                    selectedVariable={props.currentCell}
                  /> */}
              </Grid>
              <Grid>
                <Drawer
                  anchor="right"
                  open={props.openCasePropertyPanel}
                  onClose={() => props.closeCasePropertyPanel()}
                >
                  <CaseProperties
                    showAlert={props.showAlert}
                    onClose={props.closeCasePropertyPanel}
                    projVariable={projectVariables}
                    variableList={bfVariables}
                    globalVariable={globalVariables}
                    workItemList={workItems}
                    userPropertyList={props.userPropertyList}
                    updateCaseProperties={props.updateCaseProperties}
                    currentCell={props.currentCell}
                    searchExistingCell={props.searchExistingCell}
                  />
                </Drawer>
                <Drawer
                  anchor="right"
                  open={props.openTimerPropertyPanel}
                  onClose={() => props.closeTimerPropertyPanel()}
                >
                  <TimerProperties
                    showAlert={props.showAlert}
                    onClose={props.closeTimerPropertyPanel}
                    updateTimerProperties={props.updateTimerProperties}
                    currentCell={props.currentCell}
                    searchExistingCell={props.searchExistingCell}
                  />
                </Drawer>
                <Drawer
                  anchor="right"
                  open={props.openGroupPropertyPanel}
                  onClose={() => props.closeGroupPropertyPanel()}
                >
                  <GroupProperties
                    showAlert={props.showAlert}
                    onClose={props.closeGroupPropertyPanel}
                    updateGroupProperties={props.updateGroupProperties}
                    currentCell={props.currentCell}
                    searchExistingCell={props.searchExistingCell}
                  />
                </Drawer>
                <Drawer
                  anchor="right"
                  open={props.openMenuPropertyPanel}
                  onClose={() => props.closeMenuPropertyPanel()}
                >
                  {props.openMenuPropertyPanel && (
                    <MenuProperties
                      onClose={props.closeMenuPropertyPanel}
                      updateMenuProperties={props.updateMenuProperties}
                      links={parseMenuLink()}
                      options={getLinkOptions()}
                      navigateToMenuDesigner={props.navigateToMenuDesigner}
                      menuId={props.currentCell?.uid}
                      isWorkflow={props.isWorkflow}
                      isLoginMicroApp={props.isLoginMicroApp}
                    />
                  )}
                </Drawer>
              </Grid>

              <Grid>
                { props.openEmailPanel &&
                  <EmailProperties
                    workItemList={workItems}
                    alertShow={props.showAlert}
                    show={props.openEmailPanel}
                    currentCell={props.currentCell}
                    projVariable={projectVariables}
                    globalVariable={globalVariables}
                    onClose={props.toggleEmailPanel}
                    getSystemList={props.getSystemList}
                    roles={props.lanesList || []}
                    userPropertyList={props.userPropertyList}
                    variableList={props.varList?.variable || []}
                    containerApps={props.containerAppGenerations}
                    getContainerAppsList={props.getContainerAppsList}
                    updateBlockProperties={props.updateBlockProperties}
                  />
                }
              </Grid>
              <Grid>
                { props.openAssignmentPanel &&
                  <AssignmentProperties
                    workItemList={workItems}
                    alertShow={props.showAlert}
                    currentCell={props.currentCell}
                    show={props.openAssignmentPanel}
                    projVariable={props.projVariable}
                    globalVariable={props.globalVariable}
                    onClose={props.toggleAssignmentPanel}
                    userPropertyList={props.userPropertyList}
                    variableList={props.varList?.variable || []}
                    updateBlockProperties={props.updateBlockProperties}
                  />
                }
              </Grid>
              <Grid>
                <Drawer
                  anchor="right"
                  open={props.openOfflinePropSidebar}
                  onClose={() => props.toggleOfflinePropSidebar()}
                >
                  <OfflineProperties
                    onClose={props.toggleOfflinePropSidebar}
                    offlineProperty={props.offlinePropertyObj.offlineProperty}
                    saveOfflineProperty={props.saveOfflineProperty}
                    offlinePropertyData={props.offlinePropertyObj || {}}
                  />
                </Drawer>
              </Grid>
              <Grid>
                <Drawer
                  anchor="right"
                  open={props.openStateSidebar}
                  onClose={() => props.toggleStateSideBar()}
                >
                  <WorkflowStateSidebar
                    onClose={props.toggleStateSideBar}
                    addNewWorkflowState={props.addNewWorkflowState}
                    deleteWorkflowState={props.deleteWorkflowState}
                    workflowStates={props.workflowStates}
                  />
                </Drawer>
              </Grid>
              <Grid>
                <Drawer
                  anchor='right'
                  open={!!props.showWorkItemFilter}
                  onClose={() => props.openWorkItemFilter('')}
                >
                  <WorkItemFilter
                    getpillirGraphLanes={()=>props.getpillirGraphLanes()}
                    workItemList={workItems}
                    variableList={bfVariables}
                    projVariable={projectVariables}
                    globalVariable={globalVariables}
                    onClose={props.openWorkItemFilter}
                    laneName={props.showWorkItemFilter?.Lname}
                    LaneCell={props.showWorkItemFilter}
                    checkDataTypes={props.checkDataTypes}
                    userPropertyList={props.userPropertyList}
                    makeConditionString={props.makeConditionString}
                    updateWorkItemFilter={props.updateWorkItemFilter}
                  />
                </Drawer>
              </Grid>
            </Box>
          </Container>
          {/* Edit model for business function */}
          <NewFunction
            type="editFunction"
            show={editFunctionModel}
            creatingFunction={props.isEditFunction}
            editBusinessFunction={props.editBusinessFunction}
            reloadFunctionList={props.reloadFunctionList}
            createBusinessFunction={props.createBusinessFunction}
            businessFunctions={props.businessFunctions}
            businessFunction={props.businessFunction}
            templateCardData={props.templateCardList}
            navigate={props.navigateToBuisnessFunction}
            clicked={() => setEditFunctionModel(false)}
            openAddRoleModel={props.openAddRoleModel}
            roles={props.appAdminRoleList}
            addedNewRole={props.addedNewRole}
            selectedRole={props.selectedRole}
          />
          <AddNewRole
            roles={props.roles}
            createRole={props.createRole}
            showDialog={props.openAddRole}
            closeRoleModel={props.closeAddRole}
            roleCreateResp={props.roleCreateResp}
          />
          <ChooseIcon
            showDialog={iconList}
            closeModel={() => {
              setIconList(false);
            }}
            saveSelectedIcon={(icon) => {
              setSelectedIcon(icon);
            }}
          />
          {newVariable && (
            <VariablePopUp
              show={newVariable}
              close={(e) => {
                setNewVariable(false);
                setVariableIndex();
              }}
              isViewOnly={!variableViewMode}
              addNewParameters={(obj, type, flag) => {
                if (variableIndex)
                  setOpenEditVarConfirm({
                    flag: true,
                    editObj: { obj, flag, variableIndex },
                  });
                else props.handleAddVariable(obj, flag, variableIndex);
              }}
              rightPanel={{ bfVar: props.varList }}
              variableType="bfVar"
              varType="business"
              editVariableData={variableIndex}
              persistState={_checkWorkflowFunction()}
              isOffline = {props._checkIsOffline()}
            />
          )}
          {/*  Role dropdown model business function */}
          <RoleDropDown
            businessFunction={props.businessFunction}
            businessFunName={props.businessFunName}
            project={props.match.params.id}
            show={props.showRolePopup}
            roles={props.roles}
            lanes={props.lanes}
            addedNewRole={props.addedNewRole}
            isFirstLane={props.isFirstLane}
            closeRoleModel={props.closeRoleModel}
            changeRole={(oldRole, newRole) => props.editRole(oldRole, newRole)}
            selectedRole={props.selectedRole}
            selectedApp={props.selectedApp}
            openAddRoleModel={props.openAddRoleModel}
            editAppDetails={props.editAppDetails}
            openDeleteRole={props.openDeleteRole}
            openInboxPreview={props.openInboxPreview}
            searchExistingApp={(name) => props.searchExistingApp(name)}
            searchExistingRole={(name) => props.searchExistingRole(name)}
            showCreateLanePopup={(obj) => props.showCreateLanePopup(obj)}
            searchRole={props.searchRole}
            searchApp={props.searchApp}
            openIconList={() => {
              setIconList(true);
            }}
            selectedIcon={selectedIcon}
            saveAppInfo={props.saveAppInfo}
            updateAppInfo={props.updateAppInfo}
            isUpdateApp={props.isUpdateApp}
            uuid={props.appKey}
            showAlert={props.showAlert}
          />
          {props.deleteRole && (
            <PromptDialog
              open={props.deleteRole}
              close={() => props.handleClose()}
              yes={() => openDeleteRole()}
              data={{
                text: apmMessage.W3528.replace("${name}", props.laneName),
                title: apmMessage.W3509,
                action: "warning",
              }}
            />
          )}
          {/*For confirmation popup*/}
          {openConfirm.flag && (
            <PromptDialog
              open={openConfirm.flag}
              close={() => popupClose()}
              yes={() => deleteVariable()}
              data={{
                text: apmMessage.W3508,
                title: apmMessage.W3509.replace("Sure ?", "sure to delete <<"+openConfirm.varName+">> ?"),
                action: "warning",
              }}
            />
          )}
          {openTransportConfirm && (
            <PromptDialog
              open={openTransportConfirm}
              close={() => closeTransportConfirm()}
              yes={() => handleTransportConfirm()}
              data={{
                text: apmMessage.W3535.replace('${env}','QA'),
                title: apmMessage.W3536,
                action: "warning",
              }}
            />
          )}
          {openEditVarConfirm.flag && (
            <PromptDialog
              open={openEditVarConfirm.flag}
              close={() => closeEditvariable()}
              yes={() => submitEditVariable()}
              data={{
                text: apmMessage.W3510,
                title: apmMessage.W3500,
                action: "warning",
              }}
            />
          )}
          {/* DMN table pop up  */}
          {props.openDMNTable && (
            <DMNtable
              dmnData={props.openDMNTable}
              urlParams={props.match.params}
              closeDMNTable={props.closeDMNTable}
              projVariable={projectVariables}
              variableList={bfVariables}
              globalVariable={globalVariables}
              workItemList={workItems}
              addDMNConnector={props.addDMNConnector}
              editDMNConnector={props.editDMNConnector}
              userPropertyList={props.userPropertyList}
              focusDMNConnector={props.focusDMNConnector}
              showAlert={(msg) => props.showAlert(msg)}
              checkDataTypes={props.checkDataTypes}
            />
          )}
          {/* Revert Version Modal*/}
          {showRevertModal && (
            <RevertVersion
              show={showRevertModal}
              close={() => setshowRevertModal(false)}
              urlObject={props.match.params}
              previewAndPublish={props.previewAndPublish || {}}
              screenType={"business_function"}
              bfDetails={props.details}
              reloadFunction={props.reloadFunctionList}
              revertFinish={props.setupRevertFinish}
            />
          )}
          {props.inboxPreview && (
            <InboxPreview
              {...props}
              projVariable={projectVariables}
              variableList={bfVariables}
              globalVariable={globalVariables}
              workItemList={workItems}
              addDMNConnector={props.addDMNConnector}
              editDMNConnector={props.editDMNConnector}
              userPropertyList={props.userPropertyList}
              focusDMNConnector={props.focusDMNConnector}
              urlObject={props.match.params}
            />
          )}
          {/* APP PREVIEW Modal*/}
          {showAppPreviewModal && (
            <AppPreview
              {...props}
              show={showAppPreviewModal}
              lastDialog={showPreviewModal}
              close={() => setShowAppPreviewModal(false)}
              urlObject={props.match.params}
              previewAndPublish={props.previewAndPublish || {}}
              appList={props.appList[0]}
              handlePublishOption={handlePublishOption}
            />
          )}

          {/* Publish modal */}
          {showPublishModal && (
            <Publish
              show={showPublishModal}
              bfDetails={props.details}
              close={() => setShowPublishModal(false)}
              urlObject={props.match.params}
              appList={props.appList[0]}
              apps={props.appList}
              previewAndPublish={props.previewAndPublish || {}}
              screenType={"business-function"}
            />
          )}
          {/* Preview Mobile Generation modal */}
          {showPreviewMobile && (
            <PreviewMobile
              show={showPreviewMobile}
              close={() => setShowPreviewMobile(false)}
              {...props}
            />
          )}
          {/* Transport modal */}
          {showTransportModal && (
            <Transport
              displayType={"transport"}
              show={showTransportModal}
              transportObject={props.appTransport}
              isTransported={props.isTransported}
              tVersionResp={props.tVersionResp}
              transport={props.transport}
              showAlert={props.showAlert}
              urlObj={props.match.params}
              previewAndPublish={props.previewAndPublish || {}}
              currentPage={1}
              isLoading={true}
              onClose={() => {
                setShowTransportModal(false);
                setTransportConfirm(false)
              }}
              resetIsTransported={props.resetIsTransported}
              screenType={"business_function"}
              setOpenTransportConfirm={(setPage)=>{
                props.checkNewVersionInQA({
                  successCb:()=>setOpenTransportConfirm(true),
                  failureCb:(data)=>{
                    setShowTransportModal(true);
                    clearTransportFunctionResp();
                    setPage(2)
                  }})
              }}
              transportConfirm={transportConfirm}
              setTransportConfirm={setTransportConfirm}
            />
          )}
          {/* Show Preview Status modal */}
          {Boolean(anchorEl) && (
            <PreviewModal
              {...props}
              show={publishModalShow}
              appList={props.appList[0]}
              onClose={() => togglePreviewMenu()}
              handleCopyAlert={handleCopyAlert}
              handlePublishOption={handlePublishOption}
              previewAndPublish={props.previewAndPublish || {}}
              urlObject={showPreviewBrowser.params}
            />
          )}
          {getPermissions()?.projects?.business_function?.canView &&
            !(canUpdate() || canDelete()) && <LockNotification show={true} />}
          {props.newLaneModal && (
            <AddLane
              roles={props.roles}
              lanes={props.lanes}
              show={props.newLaneModal}
              addLane={props.addLane}
              selectedApp={props.selectedApp}
              addedNewRole={props.addedNewRole}
              selectedRole={props.selectedRole}
              closeLaneModal={props.closeLaneModal}
              businessFunName={props.businessFunName}
              openAddRoleModel={props.openAddRoleModel}
            />
          )}
        </Box>
      )}
    </>
  );
});
