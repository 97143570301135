import React, { useEffect, useState } from "react";
import { useAppContext } from "../../components/activity/AppContext";
import { messages } from "./messages";
import { validateColumn, validateIndex, validateChange } from "./validation";
import styles from "./database.module.css";
import DataGrid from "../../components/dataGrid/dataGrid";
import { DatabaseActions } from "./database.actions";
import { generateUuid } from "../../common";
import Tabs from "../../components/tab/tabs";


function CreateTable(props) {

    const app = useAppContext();
    const [isModified, setIsModified] = useState(true);
    const rowDefault = { id: 1, columnName: "", dataType: '', dataLength: 0, primary: false, notNull: false, default: "", autoIncrement: false, comments: "", delete: "" };
    const indicesRowDefault = { id: 1, name: "", column: '', orderBy: '', refTables: '', refColumns: '', refTablesValues: [], refColumnsValues: [], actualColValues: [], delete: "" };
    const mapDataLength = { int: 10, bigint: 19, timestamp: 0, date: 0 };
    const mapEditable = { varchar: true, text: true, blob: true, binary: true, float: true, decimal: true, double: true, }
    const getRowDefault = () => {
        let def = { ...rowDefault }
        def.uid = generateUuid()
        return def
    }
    const getIndicesRowDefault = () => {
        let def = { ...indicesRowDefault }
        def.uid = generateUuid()
        return def
    }
    const [rows, setRowState] = useState([]);
    const [existingCols, setExistingCols] = useState([]);
    const [existingIndices, setExistingIndices] = useState([]);
    const [indicesRow, setIndicesRow] = useState([getIndicesRowDefault()]);
    const [colChanges, setColChanges] = useState([]);
    const [deletedColumns, setDeletedColumns] = useState([]);
    const [deletedIndices, setDeletedIndices] = useState([]);
    const [indexChanges, setIndexChanges] = useState([]);
    const [colNames, setColNames] = useState([]);
    const [newTableName, setNewTableName] = useState("");
    const [primaryKeyColumns, setPrimaryKeyColumns] = useState({});
    const [isPrimaryColumn, setPrimaryColumn] = useState([]);
    const [dataRefresh, setDataRefresh] = useState(false)
    const [indicesRows, setIndicesRows] = useState([]);

    const {
        item = [],
        tableNameList = [],
        projectName = "",
        getList = () => null,
        handleCancelTab = () => null,
        handleTabCloseWithoutCheck = () => null,
        index
    } = props;
    const [tableName, setTableName] = useState(item.tableName);
    const resetTableForm = () => {
        setColChanges([]);
        setIndexChanges([]);
        setDeletedColumns([])
        setDeletedIndices([])
        setRows([getRowDefault()]);
        setIndicesRow([getIndicesRowDefault()]);
        // setTableName("");
    };

    const columns = [
        { id: 'columnName', name: 'Column', width: 50, editable: true, layoutType: "dataGrid", type: "inp", placeholder: "Column Name" },
        { id: 'dataType', name: 'Data Type', width: 20, editable: true, layoutType: "dataGrid", type: "sel", options: ["int", "bigint", "double", "float", "decimal", "timestamp", "varchar", "text", "blob", "binary", "date"] },
        { id: 'dataLength', name: 'Length', width: 20, editableWithCond: { refCol: "dataType", mapEditable: mapEditable, mapDataLength: mapDataLength }, layoutType: "dataGrid", type: "inp", placeholder: "length" },
        { id: 'primary', name: 'Primary Key', width: 30, editable: true, layoutType: "dataGrid", type: "ckbx" },
        { id: 'notNull', name: 'Not Null', width: 20, editable: true, layoutType: "dataGrid", type: "ckbx" },
        { id: 'default', name: 'Default', width: 20, editable: true, layoutType: "dataGrid", type: "inp", placeholder: "default" },
        { id: 'autoIncrement', name: 'Auto Increment', width: 20, editable: true, layoutType: "dataGrid", type: "ckbx" },
        { id: 'delete', name: 'Delete', width: 10, editable: true, layoutType: "dataGrid", type: "img" }
    ];
    const indicesColumns = [
        { id: 'name', name: 'Name', width: 50, editable: true, layoutType: "dataGrid", type: "inp" },
        { id: 'column', name: 'Column', width: 50, editable: true, layoutType: "dataGrid", type: "sel-col", options: colNames },
        { id: 'orderBy', name: 'orderBy', width: 50, editable: true, layoutType: "dataGrid", type: "sel-col", options: ['ASC', 'DESC'] },
        { id: 'refTables', name: 'Reference Tables', width: 50, editable: true, layoutType: "dataGrid", type: "sel", options: tableNameList },
        { id: 'refColumns', name: 'Reference Column', width: 50, editable: true, layoutType: "dataGrid", type: "sel-row" },// , options: fetchColumn},
        { id: 'delete', name: 'Delete', width: 10, editable: true, layoutType: "dataGrid", type: "img" }
    ];

    const readTable = async (tblName = tableName) => {
        const database = DatabaseActions(app());
        let [err, data] = await database.getTableColumns(tblName);
        updateColsWithDeleteButton(data.totalColumn);
        setColNames(data.colNames);
        updateIndicesWithDeleteButton(data.indices);
        setTableName(tblName);
        setNewTableName(tblName)
        setDataRefresh(false)
    }
    const readTableCoulmn = async (tblName = tableName) => {
        const database = DatabaseActions(app());
        let [err, data] = await database.getTableColumns(tblName);

    }

    useEffect(() => {
        if (!item.isNew)
            readTable();
        else
            setRowState([getRowDefault()])
    }, []);

    const setRows = (row) => {
        setRowState(row)
        setIsModified(false)
    }

    const updateIndicesWithDeleteButton = (totalIndices) => {
        let indices = [];
        totalIndices.forEach((indice, index) => {
            let indiceObj = { ...indice }
            if (!indiceObj.delete) {
                indiceObj.delete = indiceObj.name
            }
            if (!indiceObj.uid)
                indiceObj.uid = generateUuid()
            indiceObj.existingName = indiceObj.name
            indices.push(indiceObj)
        })
        setExistingIndices(JSON.parse(JSON.stringify(indices)))
        setIndicesRow(indices);
    }

    const updateColsWithDeleteButton = (totColNames) => {
        let columns = [];
        let primaries = {}
        let primaryCols = [...isPrimaryColumn]
        totColNames.forEach((col, index) => {
            let colObj = { ...col }
            if (!colObj.delete) {
                colObj.delete = colObj.columnName
            }
            colObj.default = colObj.columnDefault ? colObj.columnDefault : ""
            if (!colObj.uid)
                colObj.uid = generateUuid()
            columns.push(colObj)
            primaries[colObj.columnName] = colObj.primary
            primaryCols.push(colObj.primary)
        })
        setPrimaryKeyColumns(primaries)
        setPrimaryColumn(primaryCols)
        setExistingCols(JSON.parse(JSON.stringify(columns)))
        setRowState(columns);
    }

    const deleteColumn = (cols, type, uid) => {

        const deletedElement = rows.find((row) => (row.uid === uid))
        let availableRows = []
        availableRows = rows.filter(row => row.uid !== uid)
        let tempNames = [];
        availableRows.forEach((item, index) => {
            tempNames.push(item.columnName);
        });
        // console.log(cols,availableRows,tempNames)
        setRowState(availableRows)
        setColNames(tempNames);

        if (!item.isNew) {
            if (deletedElement && !deletedElement.new) {
                setDeletedColumns([...deletedColumns, cols])
                setIsModified(false)
            }
        }

        setColChanges(
            availableRows.map((row, idx) => ({ rowId: idx }))
        )


    }

    const deleteIndex = (idx, cType, uid) => {
        const deletedElement = indicesRow.find((row) => (row.uid === uid))
        let availableRows = []
        availableRows = indicesRow.filter(row => row.uid !== uid)
        setIndicesRow(availableRows)
        if (!item.isNew) {
            if (deletedElement && !deletedElement.new) {
                setDeletedIndices([...deletedIndices, { name: idx, type: cType }])
                setIsModified(false)
            }
        }

    }

    const handleColumnChange = (rowId, key, value) => {
        if (colChanges.length > 0) {
            let tempList = colChanges;
            let check = true;
            tempList.forEach((item, index) => {
                if (item.rowId === rowId) {
                    if (validateColumn(key, value, app(), colChanges)) {
                        item[key] = value;
                    }
                    else {
                        if (key === "primary") {
                            item[key] = false;
                        }
                    }
                    check = false;
                }
            });
            if (check)
                tempList.push({ rowId: rowId, [key]: value });
            setColChanges(tempList);
        }
        else {
            if (validateColumn(key, value, app(), colChanges)) {
                colChanges.push({ rowId: rowId, [key]: value });
                setColChanges(colChanges);
            }
        }
        let temp = colChanges;
        let tempNames = [...colNames];
        temp.forEach((item, index) => {
            if (item.rowId === rowId) {
                if (tempNames[rowId])
                    tempNames[rowId] = item.columnName;
                else
                    tempNames.push(item.columnName);
            }
        });
        setColNames(tempNames);
        setIsModified(false);
    }

    const getColNames = () => {
        return colNames;
    }
    const handleIndexChange = (rowId, key, value) => {
        let tempList = [...indexChanges];
        if (indexChanges.length > 0) {
            let check = true;
            tempList.forEach((item, index) => {
                if (item.rowId === rowId && validateIndex(key, value, app())) {
                    item = JSON.parse(JSON.stringify(indicesRow[rowId]))
                    item[key] = value;
                    item.rowId = rowId
                    check = false;
                    tempList[index] = item
                }
                else if (indicesRow[rowId]) {
                    let item = JSON.parse(JSON.stringify(indicesRow[rowId]))
                    item[key] = value;
                    item['rowId'] = rowId
                    if (indexChanges[rowId])
                        tempList[rowId] = item
                    else
                        tempList.push(item)
                    check = false;
                }
            });
            if (check) {
                tempList.push({ rowId: rowId, [key]: value });
            }
        }
        else {
            if (validateIndex(key, value, app())) {
                if (indicesRow[rowId]) {
                    let item = JSON.parse(JSON.stringify(indicesRow[rowId]))
                    item[key] = value;
                    item['rowId'] = rowId
                    if (indexChanges[rowId])
                        tempList[rowId] = item
                    else
                        tempList.push(item)
                } else {
                    tempList.push({ rowId: rowId, [key]: value });
                }
            }
        }
        setIndexChanges([...tempList]);
        setIsModified(false);
    }

    const handleColumn = () => {
        let rowAddon = JSON.parse(JSON.stringify(getRowDefault()));
        rowAddon.id = rows.length + 1;
        rowAddon.new = true;
        setRows([...rows, rowAddon]);

        const newRowId = rows.length; // or rowAddon.id - 1
        setTimeout(() => {
            const columnNameInput = document.getElementById(`columnName-${newRowId}`);
            if (columnNameInput) {
                columnNameInput.focus();
            }

        }, 100);
    }

    const handleIndex = () => {
        let rowAddon = JSON.parse(JSON.stringify(getIndicesRowDefault()));
        rowAddon.id = indicesRow.length + 1;
        rowAddon.new = true;
        setIndicesRow([...indicesRow, rowAddon]);

        const newRowId = indicesRow.length; // or rowAddon.id - 1
        setTimeout(() => {
            const columnNameInput = document.getElementById(`name-${newRowId}`);
            if (columnNameInput) {
                columnNameInput.focus();
            }

        }, 100);
    }

    const saveTable = () => {
        //   setDataRefresh(true)
        constructQuery();
    }

    async function constructQuery() {
        let res = validateChange(tableName, colChanges, indexChanges, rows, indicesRow, deletedColumns, deletedIndices, newTableName);
        if (res.isValid) {
            let query;
            let cols = colChanges;
            let cName, dataType, length, primary, notNull, defaultValue, autoIncr;
            let constraintName, chosenColumn, referenceTable, referenceColumn, orderByColumn;
            if (item.isNew) {
                let pk = false;
                query = `create table ${tableName}`;
                if (colChanges.length > 1 || (colChanges.length === 1 && rows[0].columnName != '')) {
                    query += ` ( `;
                    rows.forEach((item, index) => {
                        notNull = (item['notNull'] === true) ? 'not null' : 'null';
                        length = Number(item['dataLength']);
                        if (length)
                            query += `${item['columnName']} ${item['dataType']}(${item['dataLength']}) ${notNull} `;
                        else if (item['dataType'] === 'date' || item['dataType'] === 'timestamp')
                            query += `${item['columnName']} ${item['dataType']} `;
                        else
                            query += `${item['columnName']} ${item['dataType']} ${notNull} `;
                        if (item['default']) {
                            if (item['dataType'] === 'timestamp') {
                                query += ` default ${item['default']} `;
                            } else if (item['dataType'] === 'date')
                                query += ` default (${item['default']}) `;
                            else
                                query += ` default '${item['default']}' `;
                        }
                        if (item['autoIncrement'])
                            query += ` auto_increment  `;
                        query += `, `
                        if (item['primary'])
                            pk = item['columnName'];
                    });
                    if (pk)
                        query += `primary key (${pk})`;
                    query = query.trim();
                    if ((query.length - 1) === query.lastIndexOf(','))
                        query = query.substr(0, query.length - 1);
                    query += ` ); `;
                }
                let subQuery = '';
                if (indexChanges.length > 1 || (indexChanges.length === 1 && indexChanges[0].name != '')) {
                    indicesRows.sort((a,b)=>a.rowId - b.rowId);
                    indicesRows.forEach((item, index) => {
                        constraintName = item['name'];
                        chosenColumn = item['column'];
                        referenceTable = item['refTables'];
                        referenceColumn = item['refColumns'];
                        subQuery += `alter table ${tableName}  `;
                        orderByColumn = item['orderBy'] || 'ASC';
                        if (referenceTable && referenceColumn)
                            subQuery += ` add  constraint ${constraintName} foreign key (${chosenColumn}) references ${referenceTable}(${referenceColumn});`;
                        else
                            subQuery += ` add  index ${constraintName} (${chosenColumn} ${orderByColumn});`;
                    });
                }
                query += subQuery;
            } else {
                let noPk = true;
                query = '';
                if (colChanges.length > 1 || (colChanges.length === 1 && rows[0].columnName != '')) {
                    cols.forEach((item, index) => {
                        query += `alter table ${tableName}`;
                        let row = rows[item.rowId];
                        let alterType = 'modify';
                        if (row.new)
                            alterType = 'add';
                        // console.log(item,row?.uid,existingCols[item.rowId]?.uid,cName,existingCols[item.rowId]?.columnName)
                        cName = item['columnName'] || row['columnName'];
                        dataType = item['dataType'] || row['dataType'];
                        length = Number(row['dataLength'] || item['dataLength']);
                        primary = item['primary'] || row['primary'];
                        notNull = item['notNull'] || row['notNull'];
                        notNull = (notNull === true) ? 'not null' : 'null';
                        defaultValue = item['default'] || row['default'];
                        autoIncr = item['autoIncrement'] || row['autoIncrement'];
                        if (existingCols[item.rowId] && row.uid === existingCols[item.rowId].uid && cName !== existingCols[item.rowId].columnName) {
                            query += ` CHANGE ${existingCols[item.rowId].columnName} ${cName} `
                        } else
                            query += ` ${alterType} column ${cName}`
                        if (length)
                            query += ` ${dataType} (${length}) ${notNull} `;
                        else if (dataType === 'date' || dataType === 'timestamp')
                            query += ` ${dataType}`;
                        else
                            query += ` ${dataType} ${notNull} `;
                        if (defaultValue) {
                            if (dataType === 'timestamp') {
                                query += ` default ${defaultValue} `;
                            } else if (dataType === 'date')
                                query += ` default (${defaultValue}) `;
                            else
                                query += ` default '${defaultValue}' `;
                        }
                        if (autoIncr)
                            query += ` auto_increment  `;
                        if (primary) {
                            if (!primaryKeyColumns[cName]) {
                                if (isPrimaryColumn.includes(true) && !query.includes('DROP PRIMARY KEY'))
                                    query += ` , DROP PRIMARY KEY`;
                                query += ` , ADD PRIMARY KEY (${cName})  `;
                            }
                        } else if (primaryKeyColumns[cName]) {
                            query += ` , DROP PRIMARY KEY`;
                        }
                        query += `;`;
                    });
                    //need diff flow if there is no primarykey exists
                    // if(noPk)
                    //     query+= ` , DROP PRIMARY KEY `;
                    query = query.trim();
                    if ((query.length - 1) === query.lastIndexOf(',')) {
                        query = query.substr(0, query.length - 1);
                    }
                    if ((query.length - 1) !== query.lastIndexOf(';'))
                        query += `;`;
                }
                let subQuery = '';
                if (indexChanges.length > 1 || (indexChanges.length === 1 && indexChanges[0].name != '')) {
                   // console.log(existingIndices, indicesRow, indexChanges);
                    //console.log("indc",indicesRows);
                    indicesRows.sort((a,b)=>a.rowId - b.rowId);
                   // console.log("iorted",indicesRows);
                    indicesRows.forEach((item, index) => {
                        let row = existingIndices[item.rowId];
                        let indiceRow = indicesRow[item.rowId];
                        constraintName = item['name'] //|| indiceRow['name'];
                        chosenColumn = item['column'] //|| indiceRow['column'];
                        referenceTable = item['refTables'] //|| indiceRow['refTables'];
                        referenceColumn = item['refColumns'] //|| indiceRow['refColumns'];
                        orderByColumn = item['orderBy'] || 'ASC';
                        if (row?.type)
                            subQuery += `alter table ${tableName} drop ${row.type === "FOREIGN KEY" ? "FOREIGN KEY" : "INDEX"} ${row.existingName} ;`;
                        subQuery += `alter table ${tableName} `;
                        if (referenceTable && referenceColumn)
                            subQuery += ` add index ${constraintName}_idx (${chosenColumn}) ;alter table ${tableName} add constraint ${constraintName} foreign key (${chosenColumn}) references ${referenceTable}(${referenceColumn});`;
                        else
                            subQuery += ` add index ${constraintName} (${chosenColumn} ${orderByColumn});`;
                    });
                }
                query += subQuery;
                if (deletedIndices && deletedIndices.length > 0) {
                    let qry = ""
                    deletedIndices.map(({ name, type }, idx) => {
                        qry += ` alter table ${tableName} drop ${type === "FOREIGN KEY" ? "FOREIGN KEY" : "INDEX"} ${name};`;
                        // if(type === "FOREIGN KEY") qry += ` alter table ${tableName} drop INDEX ${name};` // to drop index auto generated by foreign key too
                    })
                    query += qry
                }
                if (deletedColumns && deletedColumns.length > 0) {
                    let qry = ""
                    deletedColumns.map((col, idx) => {
                        qry += ` alter table ${tableName} drop column ${col};`;
                    })
                    query += qry
                }
                if (tableName !== newTableName) {
                    query += `alter table ${tableName} rename ${newTableName};`;
                }
            }

            //return
            const database = DatabaseActions(app());
            let [err, data] = await database.executeQuery(query, projectName);
            if (!err && !data.error || data.status != 'Error') {
                setTableName(newTableName)
                resetTableForm();
                getList();
                readTable(newTableName || tableName)
                // if (item.isNew) {
                    handleTabCloseWithoutCheck(index, 'Table')
                // }
            } else {
                // app().notify({ message: data.errmsg, type: "E" });
                // if (!item.isNew) {
                //     resetTableForm();
                //     readTable(newTableName)
                // }
             }
        } else {
            app().notify({ message: res.errorTxt, type: "E" });
        }
    }

    const updateTableName = (e) => {
        if (item.isNew)
            setTableName(e.target.value);
        else
            setNewTableName(e.target.value)
        setIsModified(false)
    }

    const checkIndices = () => {
        const desiredKey = 'orderBy';
        indicesRow.forEach(obj => {
            if (!(desiredKey in obj)) {
                // Key does not exist in this object, so add it
                obj[desiredKey] = '';
            }
        });
        return indicesRow;
    }

    // adding this useEffect because in indices , multiple select option does not change
    useEffect(() => {
        // console.log("Hello", indexChanges);
        // Create a new array to hold the updated indicesRows
        const updatedIndicesRows = [...indicesRows];
      
        indexChanges.forEach(indexChange => {
          const existingIndexRow = updatedIndicesRows.find(row => row.rowId === indexChange.rowId);
      
          if (existingIndexRow) {
            // Update the existing object with the new values from indexChange
            Object.assign(existingIndexRow, indexChange);
          } else {
            // If the rowId doesn't exist, add the object to updatedIndicesRows
            updatedIndicesRows.push(indexChange);
          }
        });
        // Set the updated array as the new state
        setIndicesRows(updatedIndicesRows);
      }, [indexChanges]);
      

    // useEffect(item, [item]);
    return (
        <div label='Table' tId={item.name} canClose={`${item.canClose}`} >
            <div className={styles.databaseHeader}>
                <input type="text" data-input-testid={"enterTableName"} className={styles.tableLabel} id="name" name="name" defaultValue={tableName} placeholder="Type the table name here" data-error={!tableName} maxLength={30} onChange={updateTableName} />
            </div>
            <Tabs headerStyle={""} contentStyle={styles.alterTableContentStyle} canOpen={false}>
                <div isClick={true} label="Columns" tId="Columns" name={"Columns"} sid={{ marginLeft: '45%' }} className={styles.databaseInnerCon}>
                    <div className={styles.columnButtonContainer}>
                        <input data-clickable-testid={"addColumn"} className={`primary`} type="submit" value="+ Column" onClick={handleColumn} />
                    </div>
                    <div className={styles.columnContainer}>
                        <DataGrid name={'Columns'} rows={rows} setRows={setRows} columns={columns} handler={handleColumnChange} setState={setRows} options={[]} deleteColumnHandler={deleteColumn} deleteTitle={messages.deleteCol} deleteSubTititle={messages.deleteColumnConfirmationText} colChanges={colChanges} />
                    </div>
                </div>
                <div isClick={true} label="Indices" tId="Indices" name={"Indices"} sid={{}} className={styles.databaseInnerCon}>
                    <div className={styles.columnButtonContainer}>
                        <input data-clickable-testid={"addIndex"} className={`primary`} type="submit" value="+ Index" onClick={handleIndex} />
                    </div>
                    <div className={styles.columnContainer}>
                        <DataGrid name={'Indices'} rows={checkIndices()} columns={indicesColumns} handler={handleIndexChange} setState={setIndicesRow} getColNames={getColNames} options={colNames} deleteColumnHandler={deleteIndex} deleteTitle={messages.deleteIndice} deleteSubTititle={messages.deleteIndiceConfirmationText} refresh={dataRefresh} />
                    </div>
                </div>
            </Tabs>
            <div className={styles.submitContainer}>
                <input data-clickable-testid={"Cancel"} className={`secondary`} type="submit" value={messages.cancel} onClick={() => handleCancelTab(index, 'Table')} />
                <input data-clickable-testid={"Save"} className={`primary`} type="submit" value={messages.save} disabled={isModified} onClick={saveTable} />
            </div>
        </div>
    )
}
export default CreateTable;