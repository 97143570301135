import React, { useState } from 'react';
import useStyles from './style';
import {
    Box,
    Grid,
    Button,
    Container
} from '@material-ui/core';
import RevertVersion from '../../business-function/components/revert-version';
import Transport from '../../business-function/components/app-transport';
import { getEnvironment, getPermissions, variablePermissions } from '../../../utils/common';
import PublishModal from "../../business-function/components/publish";
import ContentHeader from "../../../common/components/ContentHeader";
import TransportPanel from './component/transport-panel';
import Service from "./component/service-list"
import NewService from './component/new-service';
import BaasApiKey from './component/baasapikey';
import AddIcon from '@material-ui/icons/Add';
import MetaData from './component/metadata';
import Modal from '../../../views/dialog';
import {
    AcceptTest,
    AcceptTestForQuality,
    ApproveTestForQuality,
    RejectForQuality,
    RejectTest,
    NewTest,
    Results,
    Footer,
    ModalTitle
  } from '../../create-project-detail/components/AcceptTest';
  

export default function (props) {
    const [showTransportPanel, setShowTransportPanel] = useState({ flag: false, details: {} });
    const [showMetaData, setShowMetaData] = useState({ flag: false, details: {} });
    const [serviceType, setServiceType] = useState({ edit: false, details: {} });
    const [showNewServicesDialog, setShowNewServicesDialog] = useState(false);
    const [showBaasApiKeyDialog, setshowBaasApiKeyDialog] = useState(false);
    const [showTransportModal, setShowTransportModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showRevertModal, setshowRevertModal] = useState(false);
    const [selMenuRow, setSelMenuRow] = useState({});
    const [open, setOpen] = React.useState(false);
    const [testType, setTestType] = React.useState(null);
    const [errorDict, setErrorDict] = React.useState({});
    const [versionDetail, setVersionDetail] = React.useState({});
    const [transportPage, setTransportPage] = React.useState(1);
    const [refresh, setRefresh] = useState(false);
    const [revertDisVersion, setRevertDisVersion] = useState({});
    const classes = useStyles();

    React.useEffect(() => {

        if (props.updateObjVersion != undefined && Object.keys(props.updateObjVersion).length > 0) {
          if (testType === "reject") {
            if (props.updateObjVersion.status.code == 1) {
              setShowTransportModal(true)
            }
          }
          setOpen(false);
        }
    
        if (Object.keys(props.updateObjVersion).length > 0) {
          if (props.updateObjVersion.status != undefined) {
            var time = 1000;
            
            setTimeout(
              () => {
                props.reloadVersionList(selMenuRow);
                setRefresh(!refresh)
              },
              time
            );
          }
        }
        else {
          setRefresh(!refresh)
        }
    
        // if(testType === "accept" &&  props.updateObjVersion != undefined && Object.keys(props.updateObjVersion).length > 0)
        // {
        //   clearDeleteResponse("accept");
        // }
      }, [props.updateObjVersion]);


    React.useEffect(() => {
      
      props.previewAndPublish.versionList && props.previewAndPublish.versionList.map(({ projects, ...rest }, i) => {
        if(selMenuRow.version === rest.version) {
          setRevertDisVersion(rest.version);
        }
      })

    }, [props.previewAndPublish.versionList, selMenuRow])


      React.useEffect(() => {
        if (['failed', 'finish'].includes(props.previewAndPublish.statusDetail.status)) {
          setTimeout(
            () => {
              props.reloadVersionList(selMenuRow);
              setRefresh(!refresh)
            },
            1000
          );
        }
      }, [props.previewAndPublish.statusDetail])

      let environment = getEnvironment(sessionStorage.getItem('env'))
      let baasPermissions = getPermissions()?.library?.services || {};
    

    const handleMenuOptions = (key, row) => {
        if (key === 'open') {
            props.redirectToIB(row.serviceId)
        } else if (key === "publish") {
            setShowPublishModal(true);
        } else if (key === "transport") {
            if(environment === 'Development'){
                setShowTransportModal(true);
            } else {
                setShowTransportPanel({flag: true, details: row});
            }
        } else if (key === "revert") {
            setshowRevertModal(true);
        } else if (key === 'meta-data') {
            setShowMetaData({ flag: true, details: row });
        } else if (key === 'rename') {
            handleOpenNewService(true, true, row);
        }
        setSelMenuRow(row);
    }

    const handleOpenNewService = (isOpen = true, isEdit = false, row = {}) => {
        setServiceType({ edit: isEdit, details: row });
        setShowNewServicesDialog(isOpen);
    }

    const refreshTableList = () => {
      props.handlePagination({
        rowsPerPage : props.pagination.limit || 10,
        newPageNumber: props.pagination.offset || 0
      });
    }


    const handleReject = (data) => {
        toggleModal('reject')
        // setVersion(data.version);
        let postData = data;
        postData["reason"] = props.modalChangeText.reason;
        setVersionDetail(postData);
      }

      const changeModalText = (value, type) => {
        // const input = value;
        // if ((input.length <= 250 &&  /^[a-zA-Z0-9\_\-\s]+$/i.test(input) && !(value.length === 1 && value === " ")) || input.length == 0) {
        //   var dict = this.state.modalChangeText;
        //   dict[type] = value;
        //   this.setState({ modalChangeText: dict })
        // }

        let modalChangeText = {
            ...props.modalChangeText,
            [type] : value
        };
        props.reset(modalChangeText);
      }

      const handleModal = ({ event, key, action }) => {
        // if (action === "action") {
        //   //action logic or function goes here
        // } else if (action === "onChange") {
        //   this.setState({ [key]: event.target.value });
        // }
      }

      const handleAcceptTest = (data) => {

        if (testType === "approve" && environment === "Production") {
          props.acceptTest(data);
          setTimeout(function () {
            props.versionApi();
          }.bind(this), 200)
        }
        else {
          setShowTransportModal(true)
          setTransportPage(2);
          setTimeout(function () { //Start the timer
            setOpen(!open);
          }.bind(this), 100)
          var dataParam = data;
          dataParam.projectName = selMenuRow.projectName;
          dataParam.bosUuid = selMenuRow.serviceId;
          props.acceptTest(dataParam)
        }
      }
    
      const handleRejectConfirm = (data) => {
        if (testType === "reject") {
          if(data.reason === undefined || data.reason.length === 0) {
            setErrorDict({ "reason": "Show"});
          }
          else {
            setErrorDict({});
            var dataParam = data;
            dataParam.projectName = selMenuRow.projectName;
            dataParam.bosUuid = selMenuRow.serviceId;
            props.acceptTest(dataParam)
            setTimeout(function () {
              props.versionApi();
            }.bind(this), 200)
          }
        }
      }
    
      const handleAcceptVesrion = (data) => {
        toggleModal(data.status === "New" ? "accept" : "approve");
        setVersionDetail(data);
      }

      const toggleModal = (type) => {
        setErrorDict({});
        props.reset();
        setOpen(!open);
        !open && setTestType(type);
      }


    const handleBackTransport = () => {
        setOpen(!open);
        setTimeout(function () { //Start the timer
          setShowTransportModal(false)
        }.bind(this), 100)
      }

      const body = {
        "accept": <AcceptTestForQuality type={testType} versionDetail={versionDetail} onChangeData={props.modalChangeText} changeAction={props.changeModalText} errorDict={errorDict} />,
        "reject": <AcceptTestForQuality type={testType} versionDetail={versionDetail} onChangeData={props.modalChangeText} changeAction={changeModalText} errorDict={errorDict} />,
        "new": <NewTest handleModal={handleModal} onClose={toggleModal} />,
        "approve": <AcceptTestForQuality type={testType} versionDetail={versionDetail} onChangeData={props.modalChangeText} changeAction={props.changeModalText} errorDict={errorDict} />,
        "cardModal": <Results handleModal={handleModal} onClose={toggleModal} />,
        "rejectConfirm": <RejectForQuality type={testType} updateObjVersion={props.updateObjVersion} />,
      }
    
      const footer = {
        "accept": <Footer type={testType} versionDetail={versionDetail} acceptTest={(e) => handleAcceptTest(e)} onClose={toggleModal} onChangeData={props.modalChangeText} />,
        "reject": <Footer type={testType} versionDetail={versionDetail} acceptTest={(e) => handleRejectConfirm(e)} onClose={toggleModal} onChangeData={props.modalChangeText} />,
        "approve": <Footer type={testType} versionDetail={versionDetail} acceptTest={(e) => handleAcceptTest(e)} onClose={toggleModal} onChangeData={props.modalChangeText} />,
        "rejectConfirm": <Footer type={testType} versionDetail={versionDetail} acceptTest={(e) => handleAcceptTest(e)} onClose={toggleModal} onChangeData={props.modalChangeText} />,
      }
    

    return (

        <Box component="div">
            {
            baasPermissions?.canView &&
            <Grid container spacing={3}>
                <Grid item xs={12} className="layout">
                    <ContentHeader
                        left={8}
                        right={4}
                        title="Services"
                        subtitle={""}
                    >
                        <Box variant="div">
                            {
                                baasPermissions?.baas_api_key?.canView &&
                                <Button color="secondary" variant="contained"
                                    onClick={(e) => setshowBaasApiKeyDialog(true)}>
                                    {"BaaS API Key"}
                                </Button>
                            }
                            {
                                baasPermissions?.baas_api_key?.canView &&
                                showBaasApiKeyDialog &&
                                <BaasApiKey
                                    show={showBaasApiKeyDialog}
                                    BaasApiKey={props.baasApiKey}
                                    onClose={(e) => setshowBaasApiKeyDialog(false)}
                                    permissions = {baasPermissions?.baas_api_key || {}}
                                />
                            }
                        </Box>
                        {(environment === "Development") &&
                         baasPermissions?.canCreate ?
                            <Box variant="div" >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleOpenNewService()}
                                    startIcon={<AddIcon />}
                                >
                                    New Service
                                </Button>
                            </Box>
                            :
                            <></>}

                    </ContentHeader>
                    {showNewServicesDialog &&
                        <NewService
                            type={serviceType}
                            status={props.status}
                            show={showNewServicesDialog}
                            redirectToIB={props.redirectToIB}
                            handleServiceEdit={props.handleServiceEdit}
                            onClose={(e) => handleOpenNewService(false)}
                            handleNewServiceValue={props.handleNewServiceValue}
                        />
                    }
                    {showPublishModal && (
                        <PublishModal
                            screenType={"services"}
                            show={showPublishModal}
                            bfDetails={props.details}
                            close={() => {
                              setShowPublishModal(false);
                              refreshTableList();
                            }}
                            previewAndPublish={props.previewAndPublish || {}}
                            appList={selMenuRow != undefined ? selMenuRow : {}}
                            urlObject={
                                selMenuRow != undefined ?
                                    {
                                        id: selMenuRow.projectName,
                                        ProcessId: selMenuRow.serviceId
                                    } : {}
                            }
                        />
                    )}

                    {showTransportModal && (
                        (environment === "Development") ? 
                        <Transport
                            currentPage={1}
                            isLoading={true}
                            screenType={"services"}
                            displayType={"transport"}
                            show={showTransportModal}
                            transport={props.transport}
                            showAlert={props.showAlert}
                            tVersionResp={props.tVersionResp}
                            transportObject={props.appTransport}
                            isTransported={props.isTransported}
                            onClose={() => {
                              setShowTransportModal(false);
                              refreshTableList();
                            }}
                            setOpenTransportConfirm={(setPage)=>{
                              // props.checkNewVersionInQA({
                                // successCb:()=>setOpenTransportConfirm(true),
                                // failureCb:(data)=>{
                                  setShowTransportModal(true);
                                  // clearTransportFunctionResp();
                                  setPage(2)
                                // }})
                            }}
                            resetIsTransported={props.resetIsTransported}
                            previewAndPublish={props.previewAndPublish || {}}
                            versionDetail={{}}
                            urlObj={
                                selMenuRow != undefined ?
                                    {
                                        id: selMenuRow.projectName,
                                        ProcessId: selMenuRow.serviceId,
                                        name: selMenuRow.name
                                    } : {}
                            }
                        /> : 
                        <Transport
                        currentPage={(testType === "reject") ? 3 : 2}
                        versionApi={props.versionApi}
                        isLoading={(testType == "accept") ? false : true}
                        screenType={"services"}
                        displayType={testType}
                        show={showTransportModal}
                        transport={{}}
                        showAlert={false}
                        tVersionResp={props.updateObjVersion}
                        transportObject={props.appTransport}
                        isTransported={false}
                        onClose={() => {
                          setShowTransportModal(false);
                          refreshTableList();
                        }}
                        setOpenTransportConfirm={(setPage)=>{
                          // props.checkNewVersionInQA({
                            // successCb:()=>setOpenTransportConfirm(true),
                            // failureCb:(data)=>{
                              setShowTransportModal(true);
                              // clearTransportFunctionResp();
                              setPage(2)
                            // }})
                        }}
                        resetIsTransported={{}}
                        previewAndPublish={props.previewAndPublish || {}}
                        versionDetail={versionDetail}
                        handleBackTransport={handleBackTransport}
                        toggleModal={toggleModal}
                        urlObj={
                            selMenuRow != undefined ?
                                {
                                    id: selMenuRow.projectName,
                                    ProcessId: selMenuRow.serviceId,
                                    name: selMenuRow.name
                                } : {}
                        }
                    />
                    )}
                    {
                        showTransportPanel.flag &&
                        <TransportPanel 
                            {...props}
                            show = {showTransportPanel.flag} 
                            accordions={props.accordions}
                            refresh={refresh}
                            toggleModal={toggleModal}
                            handleReject={handleReject}
                            handleAcceptVesrion={handleAcceptVesrion}
                            rowDetails={selMenuRow != undefined ? selMenuRow : {}}
                            onClose = {() => {
                              setShowTransportPanel(
                                {flag: false, details: {} }
                              );
                              refreshTableList();
                            }}
                        />
                    }
                    {showRevertModal && (
                        <RevertVersion
                            show={showRevertModal}
                            screenType={"services"}
                            revertDisVersion={revertDisVersion}
                            close={() => {
                              setshowRevertModal(false);
                              refreshTableList();
                            }}
                            previewAndPublish={props.previewAndPublish || {}}
                            refresh={refresh}
                            urlObject={
                                selMenuRow != undefined ?
                                    {
                                        id: selMenuRow.projectName,
                                        ProcessId: selMenuRow.serviceId
                                    } : {}
                            }
                        />
                    )}
                    {showMetaData.flag &&
                        <MetaData
                            details={showMetaData.details}
                            parameterValue={props.metaData}
                            onClose={(e) => setShowMetaData({ flag: false, details: {} })}
                        />
                    }
                    <Container>
                        <Service
                            // projectName={props}
                            status={props.status}
                            projects={props.projects}
                            permissions={baasPermissions}
                            serviceData={props.serviceData}
                            handleChange={props.handleChange}
                            redirectToIB = {props.redirectToIB}
                            handleNoOfRows={props.handleNoOfRows}
                            handleMenuOptions={handleMenuOptions}
                            handlePagination={props.handlePagination}
                            clearSearchValue={props.clearSearchValue}
                            totalServiceRecords={props.totalServiceRecords}
                            {...props}
                          
                        />
                    </Container>
                        {
                            <Modal
                                className={classes.modalContainer}
                                open={open}
                                onClose={toggleModal}
                                maxWidth="md"
                                dialogoContent={body[testType]}
                                dialogFooter={(testType == "approve" ||
                                    testType == "reject" ||
                                    testType == "accept" ||
                                    testType == "rejectConfirm") ? footer[testType] : undefined}
                                width={['new'].indexOf(testType) !== -1 ? true : false}
                            />
                        }
                </Grid>
            </Grid>
            }
        </Box>
    );
}

