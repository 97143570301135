import { useAppContext } from "../../components/activity/AppContext";
import { libraryMessage } from "../../common/messages/library.js";
import Pagination from "../../components/Pagination/pagination";
import DeleteWarning from "../../components/layouts/smallPopup";
import ProjectShare from "../../components/share/index";
import Table from "../../components/Table/table";
import { DriveActions } from "./drive.actions";
import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import { messages } from "./messages";
import NewFolder from "./newFolder";
import Drivedetails from "./driveDetails";
import { CopyAll } from "@mui/icons-material";
import DriveCopyOrMove from "./driveCopyOrMove";
import { useLocation } from "react-router-dom";

function DriveList(props) {
    const app = useAppContext();
    const location = useLocation();
    const headers = [
        { name: messages.name, id: "extension", type: "image" },
        { name: messages.name, id: "displayname", type: "input" },
        { name: messages.createdBy, id: "createdbyname", type: "input" },
        { name: messages.modified, id: "modifiedon", type: "input", disabled: true },
        { name: messages.fileSize, id: "size", type: "input", disabled: true },
        { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon" }
    ];

    const {
        list = {},
        pagination = {},
        projectName = "",
        getList = () => null,
        setFilter = () => null,
        setPagination = () => null,
        setSelectedPath = () => null,
        isLibrary = false,
        name = "file-folder-list",
        folderList = {},
        setReload = () => null
    } = props;

    const [openPermission, setOpenPermission] = useState(false);
    const [showDelWarning, setShowDelWarning] = useState(null);
    const [sharedFileName, setSharedFileName] = useState();
    const [driveDetailModal, setDriveDetailModal] = useState(false)
    const [sharedDrive, setSharedDrive] = useState()
    const [driveDetail, setDriveDetail] = useState()
    const [rename, setRename] = useState(null);
    const [copyOrMove, setCopyOrMove] = useState(null);
    const[node,setNode] =  useState(null);

    // const [clickCount, setClickCount] = useState(0)
    let clickCount = 0
    let timer = ""

    useEffect(()=>{
       setFilter({});
     },[]);

    const isLib = ()=>{
        return name.includes('library')
    }

    const getMenus = (item = {}) => {
        let menus = [];
        if (isLib()) {
            if (item?.type !== "File")
                if(location.state.permission?.canUpdate)
                menus.push("Permissions")
        } else {
            if (item?.type === "File")
                menus.push("Download")
            else
                if(location.state.permission?.canUpdate)
                menus.push("Send to Library")
            if(location.state.permission?.canUpdate)
            menus.push(
                "Move",
                "Copy",
                "Rename",
            );
            if(location.state.permission?.canDelete)
            menus.push("Delete")
        }
        return menus;
    }

    const onHeaderChange = (id, value) => {

        let keys = {
            [headers[0].id]: "display_name",
            [headers[1].id]: headers[1].id,
            [headers[2].id]: headers[2].id,
        }

        setFilter((prev) => ({ ...prev, [keys[id]]: { value, op: "LIKE" } }));

    }

    const downloadTheFile = async (value = {}) => {
        const drive = DriveActions(app())
        await drive.GetFileUrl(projectName, value.path, value.displayname);
    }

    const deleteFolderFile = async () => {
        const drive = DriveActions(app())
        const [err] = await drive.DeleteFolderFile(projectName, showDelWarning.path);
        if (!err) {
            setShowDelWarning(null);
            getList();
        }
    }

    const shareToLibrary = async (payload = {}) => {
        const drive = DriveActions(app())
        await drive.ShareToLibrary(projectName, payload.path);
    }


    const menuOnClick = (option, value) => {
        if (option === "Download") {
            downloadTheFile(value);
        } else if (option === 'Move') {
            setCopyOrMove('Move');
            setNode(value);
        } else if (option === 'Copy') {
            setCopyOrMove('Copy');
            setNode(value);
        } else if (option === 'Rename') {
            setRename(value);
        } else if (option === 'Delete') {
            setShowDelWarning(value);
        } else if (option === 'Permissions') {
            openPermissionDialog(value.displayname)
        } else if (option === "Send to Library") {
            shareToLibrary(value);
        }

    }

    const openPermissionDialog = async (name,filter) => {
        if(name){
            const drive = DriveActions(app())
            const [err, data] = await drive.sharedDriveDetail(name,filter);
            if (!err) {
                setSharedDrive(data)
                setOpenPermission(true)
                setSharedFileName(name)
            }
        }
    }

    const refreshPermissionData = async (name) => {
        const drive = DriveActions(app())
        const [err, data] = await drive.sharedDriveDetail(name);
        if (!err) {
            setSharedDrive(data)
        }
    }


    const handleSingleAndDoubleClick = (item, event) => {
        clickCount = clickCount + 1
        if (clickCount === 1) {
            timer = setTimeout(function () {
                clickCount = 0;
                onRowClick(item, event);
            }, 300);
        } else if (clickCount === 2) {
            clearTimeout(timer);
            clickCount = 0;
            onRowDoubleClick(item, event)
        }
    }


    const onRowDoubleClick = (item, event) => {
        if(!isLib()){
            event.preventDefault()
            if (!item?.extension) {
                setSelectedPath((prev) => [...prev, item]);
            }
        }
    }

    const onRowClick = (item, event) => {
        // event.preventDefault()
        // if (event.detail === 1) {
        //     setDriveDetailModal(true);
        //     setDriveDetail(item)
        // }
        setDriveDetailModal(true);
        setDriveDetail(item)
    }

    const saveSharedProjects = async (sharedProjects) => {
        const drive = DriveActions(app())
        const [err, data] = await drive.shareDriveToProject(sharedFileName, sharedProjects);
        if (!err) {
            // setOpenPermission(false)
            // refreshPermissionData(sharedFileName)
        }
    }

    return (

        <>
            <h3 className={styles.sectionTitle} data-text-testid={isLibrary ? libraryMessage.T7504 : messages.files}>
                {isLibrary ? libraryMessage.T7504 : messages.files}
            </h3>
            <Table
                modifyPopUp={getMenus}
                data={list.data || []}
                headerColumns={headers}
                name={name}
                onRowClick={onRowClick}
                contextOnChange={menuOnClick}
                onRowDoubleClick={onRowDoubleClick}
                handleSingleAndDoubleClick={handleSingleAndDoubleClick}
                handleHeaderInputChange={onHeaderChange}
            />
            <Pagination
                displayRows={pagination}
                userDataCount={list.total}
                setDisplayRows={setPagination}
            />
            <ProjectShare
                name={sharedFileName}
                loadData={openPermissionDialog}
                data={sharedDrive}
                popUp={openPermission}
                setPopUp={setOpenPermission}
                saveSharedProjects={saveSharedProjects}
            />
            {
                !!showDelWarning &&
                <DeleteWarning
                    popUp={!!showDelWarning}
                    title={messages.deleteTitle}
                    handleSubmit={deleteFolderFile}
                    handlePopUp={() => setShowDelWarning(null)}
                    subtitle={`${messages.deleteSubTitle} '${showDelWarning.displayname}'?`}
                />
            }
            {
                rename &&
                <NewFolder
                    show={true}
                    update={rename}
                    getList={getList}
                    projectName={projectName}
                    setShow={() => setRename(null)}
                />
            }
            {driveDetailModal &&
                <Drivedetails
                    driveDetailModal={driveDetailModal}
                    setDriveDetailModal={setDriveDetailModal}
                    driveDetail={driveDetail}
                />
            }{
                copyOrMove &&
                <DriveCopyOrMove
                    show={true}
                    copyOrMove={copyOrMove}
                    file={node}
                    list={folderList}
                    getList={getList}
                    projectName={projectName}
                    setShow={() => setCopyOrMove(null)}
                />
            }
        </>
    )
}

export default DriveList;