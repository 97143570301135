import store from '../stores';
import {
  loadContainerAppDetails,
  loadContainerAppList,
  updateContainerApp,
  createContainerApp,
  uploadedFileUrl,
  setRequest,
  clearCAGState,
  successContainerApp,
  loadMicroAppList,
} from '../actions/container-app-generation';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { CONTAINER_APP_API } from '../constants';
import { configMessage } from '../common/messages/config';
import {
  fileToBlob,
  downloadFileFromUrl,
  _underscoreEncode,
} from '../utils/common';

/**
 * Get Project List
 */

export function setContainerAppId(id) {
  store.dispatch(createContainerApp(id));
}

export function clearCagState() {
  store.dispatch(clearCAGState());
}

export function clearState() {
  store.dispatch(uploadedFileUrl(''));
}

export function fetchContainerAppList(searchValue, props, projectId) {
  let filter = '';
  let limit = '';
  if (Object.keys(searchValue).length !== 0) {
    if (searchValue.name) {
      if (filter) {
        filter += `AND c.name LIKE %27%25${
          searchValue.name ? searchValue.name : ''
        }%25%27 `;
      } else {
        filter += `c.name LIKE %27%25${
          searchValue.name ? searchValue.name : ''
        }%25%27 `;
      }
    }
    if (searchValue.appType) {
      if (filter) {
        filter += `AND c.app_type LIKE %27%25${
          searchValue.appType ? searchValue.appType : ''
        }%25%27 `;
      } else {
        filter += `c.app_type LIKE %27%25${
          searchValue.appType ? searchValue.appType : ''
        }%25%27 `;
      }
    }
    if (searchValue.caType) {
      if (filter) {
        filter += `AND c.ca_type LIKE %27%25${
          searchValue.caType ? searchValue.caType : ''
        }%25%27 `;
      } else {
        filter += `c.ca_type LIKE %27%25${
          searchValue.caType ? searchValue.caType : ''
        }%25%27 `;
      }
    }
    if (searchValue.deviceType) {
      if (filter) {
        filter += `AND c.device_type LIKE %27%25${
          searchValue.deviceType ? searchValue.deviceType : ''
        }%25%27 `;
      } else {
        filter += `c.device_type LIKE %27%25${
          searchValue.deviceType ? searchValue.deviceType : ''
        }%25%27 `;
      }
    }
    if (searchValue.version) {
      if (filter) {
        filter += `AND version LIKE %27%25${
          searchValue.version ? searchValue.version : ''
        }%25%27 `;
      } else {
        filter += `version LIKE %27%25${
          searchValue.version ? searchValue.version : ''
        }%25%27 `;
      }
    }
    if (searchValue.status) {
      if (filter) {
        filter += `AND c.status LIKE %27%25${
          searchValue.status ? searchValue.status : ''
        }%25%27 `;
      } else {
        filter += `c.status LIKE %27%25${
          searchValue.status ? searchValue.status : ''
        }%25%27 `;
      }
    }
  }

  if (props) {
    limit = `&limit=${props.limit}&offset=${
      props.offset === 0 ? props.offset : props.limit * props.offset
    }`;
  }
  // let filters = `name LIKE '%25q%25'`
  let params = {
    uri: `${CONTAINER_APP_API}/list?project_name=${projectId}&$select=id,deviceType,buildNumber,updateTime,version,status,name,caType,appType,otaUrl&$filter=${filter}&$sort=desc%20c.id${limit}`,
  };
  DataService.read(params).then(
    (result) => {
      const response = result.data;
      store.dispatch(loadContainerAppList(response.data, response.noOfRecords));
    },
    (error) => {
      // console.log('CAG error: ', error);
    }
  );
}

export function fetchContainerAppsList(projectId) {
  let filter = 'c.status LIKE %27%25Finished%25%27';

  let params = {
    uri: `${CONTAINER_APP_API}/list?project_name=${projectId}&$select=id,name&$filter=${filter}&$sort=desc%20c.id`,
  };
  DataService.read(params).then(
    (result) => {
      const response = result.data;
      store.dispatch(loadContainerAppList(response.data, response.noOfRecords));
    },
    (error) => {
      // console.log('CAG error: ', error);
    }
  );
}

export function fetchContainerAppDetails(container_app_id, projectId) {
  let params = {
    uri:
      `${CONTAINER_APP_API}/` +
      container_app_id +
      `/detail?project_name=${projectId}`,
  };
  DataService.read(params).then(
    (result) => {
      let response = result.data;
      store.dispatch(loadContainerAppDetails(response.detail));
    },
    (error) => {
      // console.log('CAG error: ', error);
    }
  );
}

export async function loadCreateContainerApp(props, projectId) {
  let response;
  let params = {
    uri: `${CONTAINER_APP_API}/create?project_name=${projectId}`,
    data: props,
  };

  store.dispatch(setRequest());
  await DataService.create(params).then(
    (result) => {
      response = result.data;
      let cagId = '';
      if (response.uuid) {
        setContainerAppId(response.uuid);
        cagId = response.uuid;
      } else if (response.status.code)
        cagId = store.getState().containerApp.cagId;

      if (props.genarate) {
        store.dispatch(successContainerApp());
        alertShow({ code: 1, message: configMessage.S4503 });
      } 
      else fetchContainerAppDetails(cagId, projectId);
      // else store.dispatch(loadContainerAppDetails(props));
    },
    (error) => {
      if (props.genarate) {
        store.dispatch(successContainerApp());
      }
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
  return response;
}

export function loadUpdateContainerApp(json, appId, projectId) {
  let params = {
    uri: `${CONTAINER_APP_API}/create?project_name=${projectId}`,
    data: json,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(updateContainerApp());
      alertShow(result.data.status);
      fetchContainerAppDetails(appId, projectId);
    },
    (error) => {
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
}

export function moveContainerAppToLibrary(appId, searchValue, pagination, projectId) {
  let params = {
    uri: `${CONTAINER_APP_API}/${appId}/moveToLib`,
    // data: json,
  };

  DataService.create(params).then(
    (result) => {
      alertShow({ code: 1, message: result.data});
      fetchContainerAppList(searchValue, pagination, projectId)
    },
    (error) => {
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
}

export function downloadApp(container_app_id, deviceType) {
  let params = {
    uri:
      `${CONTAINER_APP_API}/` +
      container_app_id +
      '/' +
      deviceType +
      '/download',
  };
  DataService.read(params).then(
    (result) => {
      if (result.data && result.data.hasOwnProperty('status')) {
        if (result.data.status.code === 0)
          alertShow({ code: 0, message: result.data.status.message });
        else if (result.data.url) {
          downloadFileFromUrl(result.data.url);
          alertShow({ code: 1, message: configMessage.S4502 });
        }
      } else if (result.data.error) {
        errorData(result);
        alertShow({ code: 0, message: configMessage.E4504 });
      }
    },
    (error) => {
      // console.log('error:', error);
    }
  );
}

export function fileUploadAPI(fileData, appId, projectName, kind) {
  let fileName = _underscoreEncode(fileData.name);
  let ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  // let rootExtension = props.extension.substring(props.extension.lastIndexOf('.') + 1);

  // if(ext.toLowerCase()==='png'){

  fileToBlob(fileData, (blob) => {
    const params = {
      uri: `${CONTAINER_APP_API}/${appId}/upload?project_name=${projectName}&comment=${kind}&$file_name=${fileName}`,
      data: blob,
    };
    DataService.create(params).then(
      (result) => {
        if (result.data.status.type.toLowerCase() == 's') {
          let data = {
            url: result.data.url,
            kind: kind,
            name: fileName,
          };
          store.dispatch(uploadedFileUrl(data));
          // alertShow(result.data.status);
        }
      },
      (error) => {
        // console.log('upload Image: ', error);
      }
    );
  });

  //   }

  // else {
  //     const formData = new FormData();
  //     formData.append('file', file);

  //   const params = {
  //     uri: `${CONTAINER_APP_API}/${appId}/upload?project_name=${projectName}&comment=${kind}&$file_name=${fileName}`,
  //     data: formData,
  //   };

  //   DataService.create(params).then(
  //     (result) => {
  //       if(result.data.status.type.toLowerCase()=='s'){
  //         let data={
  //           url:result.data.url,
  //           kind:kind,
  //           name:file.name,}
  //         store.dispatch(uploadedFileUrl(data));
  //         // alertShow(result.data.status);
  //       }
  //     },
  //     (error) => {
  //       console.log('upload File: ', error);
  //     }
  //   );
  //   }
}

const errorData = (result) => {
  let errorData = result.data;
  errorData.status = configMessage.E4501;
  errorData.data = errorData.errmsg;

  alertShow(errorData);
};

export const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? configMessage.S4501 : configMessage.E4501,
  };
  store.dispatch(showNotification(param));
};

export function fetchMicroAppList(projectName, filter = '') {
  filter = filter ? "&$filter=b.type eq 'APP'" : '';
  let params = {
    uri: `${CONTAINER_APP_API}/apps?project_name=${projectName}&$select=id,name,businessFunctionName,projectName,businessFunctionId,version${filter}`
  };
  DataService.read(params).then(
    (result) => {
      store.dispatch(loadMicroAppList(result.data.data));
    },
    (error) => {
      // console.log('Micro app list fetch ERROR', error);
    }
  );
}
