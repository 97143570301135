import { checkDuplicateKey, validateValue, validateValues, checkKeyExists } from './variableValidations';
import React, { useEffect, useState } from 'react';
import styles from "./index.module.css";
import { useForm } from '../forms/Form';
import PopUp from '../layouts/PopUp';
import Column from './column';

// this component is used by library->variable, APM, BPM, only in APM workflow persist and non-persist is needed and APM should not have value field
function VariablePopup(props) {
    let formStyle = { margin: "1rem" }
    const {
        isUpdate = false,
        variableData = {},
        variablePopup = false,
        saveOrUpdate = () => null,
        setVariablePopup = () => null,
        type = "bfVar",
        // loadVariables,
        variables,
        isWorkflow = false,
        isAPM = false,
    } = props;

    const [table, setTable] = useState(false);
    const dataType = ["String", "Number", "Decimal"];
    const [columnError, setColumnError] = useState({ key: "" });
    const [variable, setVariable] = useState({ name: "", type: "Value", dataType: "String", persist: "true" });
    const [errorValue, setErrorValue] = useState("");
    const [checked, setChecked] = useState(true);
    const [valueType, setValueType] = useState("text");
    const [focusName, setFocusName] = useState(false)
    const [focusVal, setFocusVal] = useState(false)
    const [focusKey, setFocusKey] = useState(false)
    const [colIndex, setColIndex] = useState(0);
    const [changeState, setChangeState] = useState(0);
    const [fieldError,setFieldError] = useState(false);

    const refresh = () => {
        setChangeState((prev)=>prev+1)
    }

    useEffect(() => {
        if (isUpdate === true) {
            if (variableData.type === "Table") {
                let newVariableData = { ...variableData, type: "Table", Table: "table", fields: variableData.field.fields, tableValue: JSON.stringify(variableData.field.value) }
                if(isWorkflow) newVariableData.persist = variableData.persist?"true":"false"
                setVariable(newVariableData)
                setTable(true)
                setChecked(false)
            }
            else {
                let newVariableData = {
                    ...variableData, dataType: variableData.type, type: "Value",
                    fields: [{ key: "", dataType: "String", isPrimary: false, isIndex: false }]
                }
                if(isWorkflow) newVariableData.persist = variableData.persist?"true":"false"
                setVariable(newVariableData);
            } 
        }
        else {
            let newVariableData = {
                name: "", type: "Value", dataType: "String", value: "", tableValue: "",
                fields: [{ key: "", dataType: "String", isPrimary: false, isIndex: false }]
                }
            if(isWorkflow) newVariableData.persist = "true"
            setVariable(newVariableData)
        }
    }, [isUpdate, variableData]);

    const onChange = (index, key, value, errors) => {
        setColumnError(errors);
        setErrorValue("");
        if (key === "isPrimary") {
            variable.fields = variable.fields.map((field, idx) => {
                if (idx === index) {
                    if (key === "isPrimary") {
                        return { ...field, [key]: true }
                    }
                    return { ...field, [key]: value }
                }
                else {
                    if (key === "isPrimary") {
                        return { ...field, [key]: false }
                    }
                    return field;
                }
            })
        }
        else {
            variable.fields[index][key] = value;
        }
        setVariable(variable)
    }

    const containsAlphabetsAndNumbers = (str)=> {
       
        const alphabetsRegex = /[a-zA-Z]/;
        const numbersRegex = /[0-9]/;
    
     
        return alphabetsRegex.test(str) && numbersRegex.test(str);
    }

    const onWatch = (errors, data) => {

        // if(data.name === "description"){
        //     if(!containsAlphabetsAndNumbers(data.value)){
        //         setVariable({...variable,description:variable.description.slice(0, -1)})
        //     }
        // }
     
        // setErrorValue("");
        validateValue("", "dataType", data.dataType)
        validateValue(errors, data.name, data.value, isAPM)
        variable[data.name] = data.value;
        setVariable(variable);
        if (data.value === "Value") {
            setTable(false)
            setChecked(true)
            setVariable({ ...variable,dataType:"String", value: "" });
        } else if (data.value === "Table") {
            setTable(true)
            setChecked(false)
        }
        if (data.name === "dataType") {
            (data.value === "String" ? setValueType("text") : setValueType("number"))
            setVariable({ ...variable, value: "" });
        }
        if (data.name === "persist") {
            refresh()
        }
    }
    const handleValue = (tableData, feilds, errors, isAPM=false) => {
        try {
            if (!tableData.startsWith('[') && !tableData.endsWith(']')) {
                throw ""
            }

            let tempValue = JSON.parse(tableData);
            let tableField = Object.keys(tempValue[0]);
            let fieldValue = Object.values(tempValue[0]);

            for (let field of feilds) {
                if (!tableField.includes(field.key)) {
                    throw "";
                } else {
                    let index = tableField.indexOf(field.key);
                    if (typeof fieldValue[index] !== (field.dataType === "Decimal" ? "number" : field.dataType.toLowerCase())) {
                        throw "";
                    }
                    if (typeof fieldValue[index] === "number") {
                        if (field.dataType === "Number" && !fieldValue[index].toString().match(/^([+-]?[0-9]*)?$/)) {
                            throw "";
                        } else if (field.dataType === "Decimal" && !fieldValue[index].toString().match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)) {
                            throw "";
                        }
                    }
                }
            }
            return true;
        }
        catch (e) {
            if(isAPM) return true
            if(!tableData) errors.set("tableValue", "Please enter table value") 
            else errors.set("tableValue", "Please provide valid table values")
            return false;
        }
    }

    const checkVariablesAlreadyExist = (name) => {
        let variableExist = false;
        if (!variables?.length) return false;
        variables.forEach((variable) => {
            if (variableData?.name === name) return;
            if (variable.name === name) variableExist = true;
        })
        return variableExist;
    }

    const checkFields = (fields)=>{
        let res = true;

        fields.forEach(field =>{
            if(!field.key) res = false;
        })
        
        setFieldError(!res);

        return res;
    }

    const onSubmit = async (values, errors) => {
        console.log(values)
        let currentValue = {};
        if (values.type === "Value") {
            validateValue("", "dataType", values.dataType)
            const valueData = { name: values.name, dataType: values.dataType, value: values.value,description:values.description }

            if (validateValues(valueData, errors, isAPM)) {
                currentValue = {
                    id: values.id,
                    name: values.name,
                    type: values.dataType,
                    dataType: values.dataType,
                    value: values.value,
                    field: { fields: false, value: values.value },
                    description:values.description
                }
                if(isWorkflow) currentValue.persist = values.persist==="true"?true:false

                if (checkVariablesAlreadyExist(values.name)) {
                    errors.clear("name");
                    errors.set("name", "Variable Name already exist")
                    return;
                }

                saveOrUpdate(isUpdate, currentValue);
                setVariablePopup(false)
            } else {
                return false;
            }
        } else if (values.type === "Table") {
            const valueData = { name: values.name, key: values.fields}
            if (!handleValue(values.tableValue, values.fields, errors, isAPM) || !validateValues(valueData, errors, isAPM) || !checkDuplicateKey(errors, values.fields) || !checkFields(values.fields) ) {
                return false;
            } 
            else {
                currentValue = {
                    id: values.id,
                    name: values.name,
                    type: values.type,
                    value: false,
                    field: {
                        fields: variable.fields,
                        value: isAPM?"":JSON.parse(values.tableValue)
                    },
                    description:values.description
                }
                if(isWorkflow) currentValue.persist = values.persist==="true"?true:false
                if (checkVariablesAlreadyExist(values.name)) {
                    errors.clear("name");
                    errors.set("name", "Variable Name already exist")
                    return;
                }
                saveOrUpdate(isUpdate, currentValue);
                setVariablePopup(false)
            }
        }

        // loadVariables()

    };

    const deleteColumn = (index) => {
        let _variable = variable;
        _variable.fields.splice(index, 1);
        setVariable({ ...variable, ..._variable });
    }

    const addColumn = () => {
        let valueArr = variable.fields.map(function (item) { return item["key"] });
        let duplicatekey = "";
        let isDuplicate = valueArr.some(function (item, idx) {
            duplicatekey = item;
            return valueArr.indexOf(item) != idx
        });
        let haveValue = true;
        variable.fields.map((fl) => {
            for (let val in fl) {
                if (val === "key" && !fl[val]) {
                    haveValue = false;
                    setColumnError({ ...columnError, key: "Please enter column name" });
                    break;
                }
                if (isDuplicate) {
                    haveValue = false;
                    setColumnError({ ...columnError, key: duplicatekey + " - column already exists." });
                    break;
                }
            }
        })
        if (haveValue) {
            let _variable = variable;
            _variable.fields.push({ key: "", dataType: "String", isPrimary: false, isIndex: false });
            setVariable({ ...variable, ..._variable });
        }
    }

    const alphabetOnly = (e) => {
        const { value } = e.target;
        const re = /^[0-9A-Za-z_]+$/;
        if ((value === "" || re.test(value)) && value.length <= 45) {
            setVariable(val => ({ ...val, name: value }));
        }
        setFocusName(true);
        setFocusVal(false);
    }
    
    const basedOnTypeValidation = (e) => {
        const { value } = e.target;
        const dataType = variable.dataType;
        if (dataType === "Decimal") {
            if (value.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)?$/))
                setVariable(val => ({ ...val, value: value }));
        } else if (dataType === "Number") {
            if (value.match(/^([+-]?[0-9]*)?$/))
                setVariable(val => ({ ...val, value: value }));
        }
        else
            setVariable(val => ({ ...val, value: value }));
        setFocusVal(true)
        setFocusName(false)
    }

    const alphabetOnlyKey = (e) => {
        const { value } = e.target;

        const re = /^[0-9A-Za-z_ ]+$/;
        if ((value === "" || re.test(value)) && value.length <= 50) {
            let data = variable;
            let fields = variable['fields'];
            fields[colIndex].key = value;
            data['fields'] = fields;
            setVariable(data);
        }
        setFocusKey(true)
    }

    const { Form, Label, Input, Error, Select, Radio, TextArea } = useForm(variable, onSubmit, onWatch);

    return (
        <PopUp setPopUp={setVariablePopup} popUp={variablePopup} title={`${isUpdate ? "Edit" : "Add"} Variable`} testId={isUpdate === true ? "edit-variable" : "add-variable"}>
            <Form>
                <div className={styles.popupContainer} style={{ margin: "1rem" }}>
                    <div className={styles.formContainer}>
                        <div style={{ width: "50%" }}>
                            <Label text="Variable Name" htmlFor="Variable Name" />
                            <Input type='text' style={{ width: "100%" }} id="name" name={"name"} autoComplete="off" placeholder="Name" onChange={alphabetOnly} disabled={isUpdate} value={variable.name} autoFocus={focusName} />
                            <Error name={'name'} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Label text="Description" htmlFor="description" />
                            <Input type='text' style={{ width: "100%" }} id="description" name={"description"} autoComplete="off" placeholder="Description" />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Label text="Type" htmlFor="Type" />
                            <div className={styles.radioVariable}>
                                <Radio type="radio" defaultValue="Value" checked={variable.type === "Value"} name="type" id="type" style={{ marginRight: "10px" }} /><small style={{ marginRight: "1rem" }}>Value </small>
                                <Radio type="radio" defaultValue="Table" checked={variable.type === "Table"} name="type" id="type" style={{ marginRight: "10px" }} /><small>Table</small>
                            </div>
                            {/* <Error name={'type'} /> */}
                        </div>
                        {isWorkflow &&
                        <div style={{ width: "50%" }}>
                            <Label text="Persist" htmlFor="persist" />
                            <div className={styles.radioVariable}>
                                <Radio type="radio" defaultValue={"true"} checked={variable.persist === "true"} name="persist" id="persist" style={{ marginRight: "10px" }} /><small style={{ marginRight: "1rem" }}>Persist</small>
                                <Radio type="radio" defaultValue={"false"} checked={variable.persist === "false"} name="persist" id="persist" style={{ marginRight: "10px" }} /><small>Don't Persist</small>
                            </div>
                            {/* <Error name={'persist'} /> */}
                        </div>
                        }
                        {!table
                            ? <div style={{ width: "50%" }}>
                                <Label text="Data Type" htmlFor="Data Type" />
                                <Select
                                    style={{ width: "100%" }}
                                    name="dataType"
                                    id="dataType"
                                >
                                    {dataType.map((title, index) => {
                                        return <option key={index} value={title.name}>{title}</option>
                                    })}
                                </Select>
                                <Error name={'dataType'} />
                                {!isAPM &&
                                <div style={{ width: "100%", marginTop: "12px" }}>
                                    <Label text="Value" htmlFor="value" />
                                    <Input style={{ width: "100%" }} className={styles.variableValue} type="text" id="value" name="value" value={variable.value} onChange={basedOnTypeValidation} autoFocus={focusVal} />
                                    <Error name={'value'} />
                                </div>
                                }
                            </div>
                            : <div style={{ display: "flex", flexDirection: "column"/*, rowGap: "5px"*/ }}>
                                <div className={styles.columnContainer}>
                                    <Label text="Column Name" htmlFor="Column Name" style={{ width: "25%" }} className={styles.columnInnerContainer} />
                                    <Label text="Column Type" htmlFor="Data Type" style={{ width: "25%" }} className={styles.columnInnerContainer} />
                                    <Label text="Primary" htmlFor="Primary" style={{ width: "15%" }} className={styles.columnInnerContainer} />
                                    <Label text="Apply Index" htmlFor="Apply Index" style={{ width: "15%" }} className={styles.columnInnerContainer} />
                                    <div style={{ width: "10%" }} className={styles.columnInnerContainer}></div>
                                </div>
                                {variable?.fields?.map((item, index) => {
                                    // let spltkey = item.key.split('$');
                                    // item.key = spltkey[spltkey.length - 1];
                                    // setColIndex(index);
                                    return (<Column data={item} setFieldError={setFieldError} Label={Label} Error={Error} onChange={onChange} columnError={columnError}
                                        index={index} dataType={dataType} deleteColumn={deleteColumn} addColumn={addColumn}
                                        setColumnError={setColumnError} fields={variable.fields} keyValidation={alphabetOnlyKey} focus={focusKey} />
                                    )
                                })}
                                <Error name={"key"} />
                                {
                                    fieldError ? <div className={styles.errorMessage} data-error-testid={"key"} >Please enter column name</div> : ""
                                }
                                
                                <Input type="button" className={`secondary ${styles.addField}`} value={"+ Add field"} onClick={addColumn} />
                                {!isAPM &&
                                <div style={{ width: "50%", marginTop: "14px" }}>
                                    <Label text="Value" htmlFor="tableValue" />
                                    <TextArea style={{ width: "100%" }} className={styles.tableValue} type="text" id="tableValue" name="tableValue" defaultValue={variable.tableValue}
                                        placeholder={`[{"column1":"stringValue","column2":numberValue}]\n* Enter number/decimal value without quotes\n* Enter string value with quotes`} />
                                    <Error name={'tableValue'} />
                                    {/* <div className="errorMessage" data-error-testid="tableValue">{errorValue}</div> */}
                                </div>
                                }
                            </div>}
                    </div>
                </div>
                <div className="afFooter">
                    <Input className='secondary' type="button" size="small" value={"Cancel"} onClick={() => setVariablePopup(false)} />
                    <Input type="submit" value={"Save"} />
                </div>
            </Form>
        </PopUp >
    )
}

export default VariablePopup;
