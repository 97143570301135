import React, {useState, useEffect} from "react";
import { useAppContext } from "../../../components/activity/AppContext";
import { ModuleTitle } from "../../../components/module-title";
import styles from "./containerAppsLibrary.module.css";
import ProjectDepTable from "../../project/settings/project-deployment-table/projectDepTable";
import { useLocation } from "react-router-dom";

function ContainerAppsLibrary({perm}) {
    const app = useAppContext();
    const [openCAG,setOpenCAG] = useState()
    const location = useLocation();
    location.state = {permission:perm}

    const newApp = () => {
        setOpenCAG(true)
    }

    return (
        <>
            <ModuleTitle title="Container Apps" subTitle="Container Apps built here can incorporate applications developed in various projects." >
                {location.state.permission?.canCreate
                    ?<button
                        data-clickable-testid="container-apps"
                        className={`${styles.buttonWidth} primary `}
                        onClick={newApp} >
                        + New App
                    </button>
                    :<></>
                }
            </ModuleTitle>
            {location.state.permission?.canView
            ?(
                <div className={styles.caOuterContainer}>
                <div className={styles.caContainer}>
                    <div className={styles.caInnerContainer}>
                        {/* <div className={styles.caTableContainer}> */}
                    <ProjectDepTable fromScreen={'Library'} openCAG={openCAG} setOpenCAG={setOpenCAG} perm={perm}/>
                {/* </div> */}
                </div>
                </div>
                </div>
            )
            :<></>
            }
        </>
    )

}

export default ContainerAppsLibrary