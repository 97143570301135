import { ModuleTitle } from "../../../components/module-title"
import { messages } from "./schedulerMessage"
import AddIcon from '@mui/icons-material/Add';
import "./scheduler.css"
import Table from "../../../components/Table/table";
import Pagination from "../../../components/Pagination/pagination";
import { NewSchedulerStart } from "./schedulerPopups/NewScheduleForm/NewScheduleForm";
import React, { useEffect, useState } from "react";
import { TimeBasedSchedule } from "./schedulerPopups/TimeBasedSchedule/TimeBasedSchedule";
import { SelectBos } from "./schedulerPopups/SelectBos/SelectBos";
import { Parameters } from "./schedulerPopups/Parameters/Parameters";
import { TriggerPopups } from "./schedulerPopups/TriggerPopups/TriggerPopups";
import { FinalStep } from "./schedulerPopups/FinalStep/FinalStep";
import eye from "./SchedulerAssets/eye.png"
import edit from "./SchedulerAssets/edit.png"
import { Loglist } from "./schedulerPopups/Loglist/Loglist";
import { useScheduler } from "./schedulerContext";
import { useAppContext } from "../../../components/activity/AppContext";
import { SchedulerActions } from "./scheduler.action";
import { LogReport } from "./schedulerPopups/LogReport/LogReport";
import { useLocation } from "react-router-dom";
import { getAuth } from "../../../auth/user";


function Scheduler({perm}) {

    let style = { marginBottom: "1rem" }

    const app = useAppContext();
    const location = useLocation();

    const { loglist, setLoglist, finalStep, setFinalStep, setEdit, show, setShow, timeBased, setTimeBased,
        bospopup, setBospopup, parameter, setParameter, trigger, setTrigger, createScheduler,
        params, setParams, timeBasedSchedulerForm, setTimeBasedSchedulerForm,
        freq, setFreq, freqObj, setFreqObj, paramObject, setParamObject,
        isTimeBased, setIsTimeBased, setId, setPrevName, isEnabled, setIsEnabled,
        setCreateScheduler, report, setReport, allProjects, setAllProjects,parameters,
        setParameters,allBos,setAllBos,setSave,setTimeBasedEdit } = useScheduler()

    const UpdateSchedulers = async (payload, id) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.UpdateScheduler(payload, id);
        if (!error) {
            console.log(data)
        }
    }

    const handleUpdate = (payload, prevName) => {
        UpdateSchedulers(payload, prevName)
        handleClose()
        setTimeout(() => setCreateScheduler(!createScheduler), 1000)

    }

    const [schedulerId, setSchedulerId] = useState(0);

    const loadBos = async (name) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.GetBos(name);
        if (!error) {
            setAllBos(data.data)
        }
    }

    const handleImagePopup = (name, item) => {
        if (name === "Logs") {
            setLoglist(true)
            setSchedulerId(item.id)
        }
        else if (name === "Report") {
            const actions = SchedulerActions(app());
           
            if(item.status === "A"){
                actions.ShowAlert();
            }
            else{
            setReport(true)
            loadLogReport(item.scheduleId, item.id)

            }
            
        }
        else if (name === "All") {
            if (item.isEnabled === 0) {
                handleUpdate({ ...item, isEnabled: 1 }, item.name)
            }
            if (item.isEnabled === 1) {
                handleUpdate({ ...item, isEnabled: 0 }, item.name)
            }
        }
        else {
            try{
                setEdit(true)
                setFinalStep(true)
                const projectName = item.serviceName.split(".")[0];
                const bosName = item.serviceName.split(".")[1]
                setIsEnabled(item.isEnabled)
                setPrevName(item.name)
                setId(item.id)
                setTimeBasedSchedulerForm({
                    ...timeBasedSchedulerForm, name: item.name,
                    description: item.description,
                    serviceType: item.serviceType
                })
                setParams({
                    ...params,
                    serviceId: item.bosname,
                    projectComponentId: item.projectComponentId,
                    bosName: bosName,
                    projectName:projectName
                })
                setParamObject(item.parameters)
                const para = item.parameters ? JSON.parse(item.parameters) : parameters
                setParameters(para)
                const frequency = item.frequencyParam ? JSON.parse(item.frequencyParam) : freq
                setFreq(frequency)

                setAllProjects(
                    allProjects.map((project)=>{
                        if(project.name === params.projectName){
                            // return {...project,select:true}
                            project.select = true;
                            return project;
                        }
                        else{
                            project.select = false;
                            return project
                        }
                    })
                )
                loadBos(projectName)
                setAllBos(
                    allBos.map((bos)=>{
                        if(bos.name === params.bosName){
                            bos.select = true;
                            return bos;
                        }
                        else{
                           bos.select = false;
                           return bos;
                        }
                    })
                )

            }
            catch(err){
                console.log(err)
            }

            // setFreq(item.frequencyParam);
        }

    }


    const roleData = [
        { name: "Enabled" },
        { name: "Disabled" },
        { name: "Draft" },
    ]

    const schedulerTableHeader = [
        { id: "name", name: "Name", type: "input",  Width: '250px',
            maxWidth: '250px', },
        { id: "description", name: "Description", type: "input",  width: '300px',
            maxWidth: '300px', },
        { id: "serviceName", name: "serviceName", type: "input", width: '150px', },
        { id: "lastExecution", name: "Last Execution", type: "input",  disabled: "true" },
        { id: "nextExecution", name: "Next Execution", type: "input",  disabled: "true" },
        { id: "logs", name: "Logs",  image: eye, size: "32px", handleImagePopup: { handleImagePopup } },
        { id: "edit", name: "", image: edit, size: "24px", handleImagePopup: { handleImagePopup } },
        { id: "All", type: "role", width: "80px", name: "All", icon: true, handleImagePopup: handleImagePopup },

    ]

    if(!location.state.permission?.canUpdate) {
        schedulerTableHeader = schedulerTableHeader.filter((e)=>e.id!=="edit")
    }

    const handleBosSelection = () =>{
        if(allProjects.length){
            setAllProjects(
                allProjects.map((project)=>{
                    if(project.select){
                        return {...project,select:false}
                    }
                    else{
                        return {...project,select:false}
                    }
                })
            )

        }

        if(allBos.length){
            setAllBos([])

        }

        setParams({
            ...params,
            serviceId: "",
            projectComponentId: "",
            bosName: "",
            projectName:""
        })

    }

    const handleOpen = () => {
        handleBosSelection()
        setShow(true)
        setSave(false)
        setTimeBasedEdit(false)
    }

    const handleClose = () => {
        if (show) setShow(false)
        if (timeBased) setTimeBased(false)
        if (bospopup) setBospopup(false)
        if (parameter) setParameter(false)
        if (trigger) setTrigger(false)
        if (finalStep) setFinalStep(false)
        if (loglist) setLoglist(false)
        if (report) setReport(false)
        
    }

    const [schedulerFilter, setSchedulerFilter] = useState({
        bosname: "",
        description: "",
        frequency: "",
        frequencyParam: "",
        id: "",
        isEnabled: "",
        lastExecution: "",
        name: "",
        nextExecution: "",
        parameters: "",
        projectComponentId: "",
        serviceName: "",
        serviceType: ""
    })
    const [data, setData] = useState({totalRecords:0,data:[]})
    //const [totalRecords, setTotalRecords] = useState(0)
    const [displayRows, setDisplayRows] = useState({ offset: 0, limit: 10 });
    const [logReportData, setLogReportData] = useState("")

    const loadSchedulerList = async () => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetSchdulerList(displayRows.offset, displayRows.limit, schedulerFilter)
        if (!error) {
            //setTotalRecords(data.noOfRecords);
            setData({totalRecords:data.noOfRecords,data:data.data})
        }
    }

    const onChange = (key, value) => {
        let _schedulerFilter = { ...schedulerFilter, [key]: value }
        setSchedulerFilter(_schedulerFilter);
    }

    const loadLogReport = async (schedulerId, logId) => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetReportURL(schedulerId, logId)
        if (!error) {
            const res = await actions.GetReport(data.url);
            setLogReportData(res);
        }
    }
    const loadProjectsList = async () => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetAllProject()
        if (!error) {
            setAllProjects(data.data)
        }
    }

    useEffect(() => {
        loadSchedulerList()
    }, [displayRows, schedulerFilter, createScheduler])


    useEffect(() => {
        loadProjectsList();
    }, [])

    return <div className="scheduler-container">
        <ModuleTitle
            title={messages.scheduler}
        >
          <button
            className="primary"
            style={{ width: "auto" }}
            data-clickable-testid="newScheduler"
            onClick={handleOpen}
          >
            + New Schedule
          </button>
        </ModuleTitle>
        <div className="afModule">
            <div style={{ boxShadow: "0px 2px 6px rgb(0 0 0 / 8%)" }}>
            <Table
                    headerColumns={schedulerTableHeader}
                    roleData={roleData}
                    data={data.data}
                    name={"scheduler"}
                    handleHeaderInputChange={(id, value) => onChange(id, value)}
                    handleHeaderSelectChange={(id, value) => onChange(id, value)} />
            <Pagination
                    userDataCount={data.totalRecords}
                    displayRows={displayRows}
                    setDisplayRows={setDisplayRows} />
            </div>
      </div>
        {
            show && <NewSchedulerStart show={show} handleClose={handleClose} />
        }
        {
            timeBased && <TimeBasedSchedule show={timeBased} handleClose={handleClose} />
        }
        {
            bospopup && <SelectBos show={bospopup} handleClose={handleClose} />
        }

        {
            parameter && <Parameters show={parameter} handleClose={handleClose} />
        }
        {
            trigger && <TriggerPopups show={trigger} handleClose={handleClose} />
        }
        {
            finalStep && <FinalStep show={finalStep} handleClose={handleClose} loadSchedulerList={loadSchedulerList} />
        }
        {
            loglist && <Loglist show={loglist} handleClose={handleClose} schedulerId={schedulerId} handleImagePopup={handleImagePopup} />
        }
        {
            report && <LogReport show={report} handleClose={handleClose} logReportData={logReportData} />
        }

    </div>

}

export default Scheduler;