import React, { useState, useEffect } from 'react'
import styles from './appPermissions.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import Table from '../../../../../../components/Table/table';
import PopUp from '../../../../../../components/layouts/PopUp';
import { ReactComponent as LPoint } from '../../../../../../images/icons/cag/l-point.svg'
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function AppPermissions({ pageNumber, projectName, containerAppDetails, setContainerAppDetails, bfList, resetCADetails, handleClose, rolePopUp, fromScreen }) {
    const [data, setData] = useState({
        universalWorkList: "Yes",
        businessFunctionId: "",
    })
    const [containerAppAccess, setContainerAppAccess] = useState(containerAppDetails?.containerAppAccess||[])
    const [initialBfList, setInitialBfList] = useState(bfList || [])
    const [formattedData, setFormattedData] = useState([])
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)
    const [isWebApp,_] = useState(containerAppDetails.containerApp.appType==="webApp")
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false)
    const app = useAppContext()
    const inlineStyles = {
        radioWrapper : { paddingTop: '16px', display: 'inline-flex', gap:"16px" },
        radioCont : {display: 'inline-flex', gap:"9px", padding:"1px"},
        radioLabel : {marginTop:"2px", fontSize:"16px"}
    }

    const formatData = (bfList) => {
        let temp = {}
        bfList.map((item) => {
            temp[item.businessFunctionName]={...temp[item.businessFunctionName],[item.name]:item.version,appName:[item.name], bfId:item.businessFunctionId}
        })
        let finalData = []
        Object.keys(temp).map( (e) => {
            if(fromScreen != "Library"){
                finalData.push({businessFunctionName:e, selectBusinessFunction:"", name:temp[e]["bfId"], isChecked:containerAppAccess.find((el)=>el?.businessFunctionId==temp[e]["bfId"]), })
                Object.keys(temp[e]).map((e1) => {
                    if(e1!=="bfId"&&e1!="appName")
                    finalData.push({businessFunctionName:(<><span style={{padding:"0 8px 0 8px"}}><LPoint/></span>{e1}</>),version:temp[e][e1], style:{display:"none"}})
                })
            }else{
                let laneList = Object.keys(temp[e]).filter(e => e!="bfId"&&e!="appName")
                if(laneList.length > 1){
                    finalData.push({businessFunctionName:e, selectBusinessFunction:"",appName:"--WORKFLOW APP--", name:temp[e]["bfId"], isChecked:containerAppAccess.find((el)=>el?.businessFunctionId==temp[e]["bfId"]),version:temp[e][laneList[0]] })
                }else{
                    finalData.push({businessFunctionName:e, selectBusinessFunction:"",appName:temp[e]["appName"], name:temp[e]["bfId"], isChecked:containerAppAccess.find((el)=>el?.businessFunctionId==temp[e]["bfId"]),version:temp[e][laneList[0]] })
                }
            }
        })
        return finalData
    }
    
    useEffect( () => {
        setFormattedData(formatData(initialBfList))
    },[initialBfList])

    // const finalData = formatData(initialBfList)

    const mockData1 = [
        {businessFunctionName:"ToDoApp",selectBusinessFunction:"", },
        {businessFunctionName:(<><span style={{padding:"0 8px 0 8px"}}><LPoint/></span>ToDoApp</>),version:"1.0",}
    ]

    let headers = []
    if(fromScreen == "Library"){
        headers.push(
            { name: "Application Name", id: "appName", type: "input", width: "40%" }
        )
    }
    headers.push({ name: "Business Function Name", id: "businessFunctionName", type: "input", width: "40%" })
        headers.push({ name: "Version", id: "version", type: "input", width:"40%" })
        headers.push({ name: "", id: "selectBusinessFunction", type: "checkBox",  width:"20%" })

    const updateContainerAppDetails = async (newValues) => {
        if(!isWebApp || (clickedBack || saveChangesPopup)) { /* write the draft changes condition */
            const prjAction = ProjectSettingsActions(app());
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
            if(!err){
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...data2.detail, genarate:false})
                    }
    
                if(data.status.type!=="E") saveChangesPopup?closePopup(false):pageNumber(clickedBack?1:3)
            }
        }
        else {
            const prjAction = ProjectSettingsActions(app());
            pageNumber(7)
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues, genarate:true})
            if(!err) {
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...containerAppDetails,...data2.detail})
                    }
    
                if(data.status.type!=="E") {
                    // console.log("containerAppDetails", containerAppDetails)
                    closePopup(false)
                    // pageNumber(8)
                }else{
                    //show failed logs
                }
            }
            else {
                // console.log("error", err)
                // pageNumber(8)
                closePopup(false)
            }
    
        }
    }

    const onSubmit = () => {
        if(!hadEdit&&!clickedBack&&!isWebApp) {pageNumber(3);return}
        let newValues = {...containerAppDetails,containerAppAccess}
        updateContainerAppDetails(newValues)
    }

    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout(()=>{
            if(hadEdit){
                submitHandler(e)
            }
            else {
        pageNumber(1)
    }
        },0)
    }

    const getBusinessFunctionList = async (includeUniversalWorkList) => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.getBusinessFunctionList(projectName, includeUniversalWorkList)
        if(!err) {
            setInitialBfList(data.data)
        }
    }

    const onHeaderChange = (id, value) => {
        let filteredData = bfList.filter((e) => e[id!="appName"?id:"name"].toLowerCase().includes(value.toLowerCase()))
        setInitialBfList(filteredData)
    }

    const onCheckBoxChange = (checked, name) => {
        setHadEdit(1)
        if(checked){
            setContainerAppAccess((prev)=>[...prev,{businessFunctionId:name}])
        }
        else {
            setContainerAppAccess((prev)=>prev.filter((e)=>e.businessFunctionId!=name))
        }
    }

    const onWatch = (errors, values) => {
        setHadEdit(1)
        if(values.name == "universalWorkList") {
            getBusinessFunctionList (values.value == "Yes")
        }
    }

    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const { Form, Label, Input, Error, Radio, submitHandler } = useForm(data, onSubmit, onWatch);
    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"appPermissions"}
            title="Choose your EdgeReady Applications"
            subtitle="Enable what your end-users users should see when they login into this new application"
                >
            <Form>
            <div className={styles.formContent}>
            {/* <div className={styles.universalWorkList}>

                <h4
                    className={styles.afModuleSubTitle}
                        data-text-testid={"isUniversalWorlist?"}
                >
                    Does the app include universal worklist?
                </h4>
                    <div style={inlineStyles.radioWrapper}>
                        <div style={inlineStyles.radioCont}>
                        <Radio type="radio" id="Yes" name="universalWorkList" testId={'Yes'} value="Yes" className={styles.radio}/>
                        <Label text={"Yes"} style={inlineStyles.radioLabel}/>
                        </div>
                        <div style={inlineStyles.radioCont}>
                        <Radio type="radio" id="No" name="universalWorkList" testId={"No"} value="No" className={styles.radio} />
                        <Label text={"No"} style={inlineStyles.radioLabel}/>
                        </div>
                </div>

            </div> */}
                
                    <div className={styles.formField}>
                        <p style={{fontSize:"13px"}}>Edge Ready Applications
                        </p>
                    </div>
                    <div className={styles.formField}>
                        <div className={styles.tableWrapper}>
                        <Table
                            name={"Applications Table"}
                            data={formattedData || []}
                            headerColumns={headers}
                        // contextOnChange={menuOnClick}
                            handleHeaderInputChange={onHeaderChange}
                            checkBoxOnChange={onCheckBoxChange}
                        />
                    </div>
                </div>
            </div>
                <div className='afPopupFooter'>
                    <div>
                        <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                        <Input type="submit" value={isWebApp?"Generate":"Next"} style={isWebApp?{width:"fit-content",padding:"calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px)"}:{}}/>
                    </div>
                </div>
            </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default AppPermissions