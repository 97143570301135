import styles from './popup.module.css'
import React from "react";

const PopUp = (props) => {
    const {
        title = "",
        subtitle = "",
        popUp = false,
        titleClassName = "",
        setPopUp = () => null,
        testId = "",
        textTestId = "",
        bodyClassName = styles.afWrapper,
        afPopUpBodyStyle={},
        afPopUpHeaderStyle={},
        relocate,
        lane,
        setLane
    } = props;


    const handlePopup=()=>{
        setPopUp(false)
        if(relocate && !lane) relocate()  
    }
    

    return (
        <>
            {popUp
                ? (
                    <div className={styles.afMainWrapper}
                        data-context-testid={testId ? testId : "popupContext"} >
                        <div className={bodyClassName}>
                            <span
                                data-clickable-testid="popup-close"
                                className={styles.afCloser}
                                onClick={handlePopup}>X</span>
                            <p data-text-testid={textTestId || "popup-title"} style={afPopUpHeaderStyle} className={`${styles.afHeader} ${titleClassName}`}>
                                {title}
                            </p>
                            {
                                subtitle &&
                                <p className={styles.afSubHeader} data-text-testid={subtitle || "popup-subtitle"}>
                                    {subtitle}
                                </p>
                            }
                            <div className={styles.afPopupBody} style={afPopUpBodyStyle}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                ) : ""
            }
        </>
    )
}

export default PopUp;
