import React, { useEffect, useState } from "react";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import { validateMinutes, validateTime, validateHours } from "../../../../businessFunctions/validations";
import { useScheduler } from "../../schedulerContext";
import styles from "./TriggerPopups.module.css"
import CloseIcon from '@mui/icons-material/Close';
import { SchedulerActions } from "../../scheduler.action";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { generateUuid } from "../../../../../common";
import { v4 as uuidv4 } from 'uuid';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';





export const TriggerPopups = ({ show, handleClose }) => {

    const app = useAppContext();
    const [value, setValue] = useState([generateUuid()])


    const { setParameter, setFinalStep, setEdit, triggerEdit, setTriggerEdit, setOption, draft, timeBasedSchedulerForm,
        params, createScheduler, setCreateScheduler, setDraft, edit, paramObject, parameters, freq, setFreq } = useScheduler();



    const [data, setData] = useState({})
    const [count, setCount] = useState(0)
    const [freqs, setFreqs] = useState(freq[0].times ? freq : [
        {
            id: uuidv4(),
            day: "1st",
            drp: "hours",
            hr: "",
            isOpended: false,
            min: "",
            times: "",
            week: "Monday"
        }
    ])
    const time = ["hours", "minutes", "days", "weeks", "months"]
    const weeks = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const days = [
        { id: '1', value: '1st' },
        { id: '2', value: '2nd' },
        { id: '3', value: '3rd' },
        { id: '4', value: '4th' },
        { id: '5', value: '5th' },
        { id: '6', value: '6th' },
        { id: '7', value: '7th' },
        { id: '8', value: '8th' },
        { id: '9', value: '9th' },
        { id: '10', value: '10th' },
        { id: '11', value: '11th' },
        { id: '12', value: '12th' },
        { id: '13', value: '13th' },
        { id: '14', value: '14th' },
        { id: '15', value: '15th' },
        { id: '16', value: '16th' },
        { id: '17', value: '17th' },
        { id: '18', value: '18th' },
        { id: '19', value: '19th' },
        { id: '20', value: '20th' },
        { id: '21', value: '21th' },
        { id: '22', value: '22th' },
        { id: '23', value: '23rd' },
        { id: '24', value: '24th' },
        { id: '25', value: '25th' },
        { id: '26', value: '26th' },
        { id: '27', value: '27th' },
        { id: '28', value: '28th' },
        { id: '29', value: '29th' },
        { id: '30', value: '30th' },
        { id: '31', value: '31st' },
    ];

    const onWatch = (errors, data) => {

    }

    const onSubmit = async (values, errors) => {


        if (draft) {
            // setFreq([...freq.slice(1), { ...freqObj, times: values.time, drp: values.type }])

            const payload = {
                bosname: params.serviceId,
                description: timeBasedSchedulerForm.description,
                eventId: null,
                freq: freq,
                isEnabled: 2,
                isTimeBased: true,
                name: timeBasedSchedulerForm.name,
                parameters: JSON.stringify(parameters),
                projectComponentId: params.projectComponentId,
                runAsUser: params.runAsUser,
                serviceName: params.projectName+"."+params.bosName,
                serviceType: timeBasedSchedulerForm.serviceType
            }

    
            CreateScheduler(payload)
            handleClose()
            setTimeout(() => setCreateScheduler(!createScheduler), 1000)
            setDraft(false)
        }
        else if (edit) {
            handleCancel()
        }
        else {
            if (freq[freq.length - 1].times) {
                handleNext();
            }

        }

    }

    const { Form, Label, Input, Error, Select } = useForm(data, onSubmit, onWatch);

    const handleBack = () => {
        handleClose()
        setParameter(true)
    }

    const handleNext = () => {
        handleClose()
        setEdit(false)
        setFinalStep(true)
    }
    const handleCancel = () => {
        handleClose()
        setTriggerEdit(false)
        setFinalStep(true)
    }

    const CreateScheduler = async (payload) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.CreateSchedulerBasedonTime(payload);
        if (!error) {
            console.log(data)
        }
    }

    const handleDraft = () => {
        setDraft(true)
    }

    const handleTimes = (times, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, times: times }
                }
                else return el;
            })
        )
    }

    const handleDrp = (drp, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, drp: drp }
                }
                else return el;
            })
        )
    }

    const handleAddTrigger = () => {
        if (freqs[freqs.length - 1].times) {
            setFreqs(
                [...freqs, {
                    id: uuidv4(),
                    day: "1st",
                    drp: "hours",
                    hr: "",
                    isOpended: false,
                    min: "",
                    times: "",
                    week: "Monday"
                }]
            )

        }

        setCount((p) => p + 1);

    }

    const handleDeleteTrigger = (id) => {
        setFreqs(
            freqs.filter((el) => el.id !== id)
        )
    }

    const handleisOpended = (id, flag) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, isOpended: flag }
                }
                else return el;
            })
        )
    }

    const handleHour = (hr, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, hr: hr }
                }
                else return el;
            })
        )
    }

    const handleMin = (min, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, min: min }
                }
                else return el;
            })
        )
    }
    const handleWeek = (week, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, week: week }
                }
                else return el;
            })
        )
    }
    const handleDay = (day, id) => {
        setFreqs(
            freqs.map((el) => {
                if (el.id === id) {
                    return { ...el, day: day }
                }
                else return el;
            })
        )
    }

    useEffect(() => {
        setFreq(freqs)
    }, [freqs])
    
    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"trigger-scheduler"} titleClassName={styles.triggerHeader} title={"When do you want to trigger it?"}>
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        {
                            freqs.length ? freqs.map((el) => {
                                return <div key={el.id}>
                                    <div className={styles.triggerComp}>
                                        <label>Repeat every</label>
                                        <input className={styles.triggerCompInput} type="number" value={el.times} onChange={(e) => handleTimes(e.target.value, el.id)} />
                                        <select className={styles.triggerCompSelect} style={{ backgroundColor: "transparent" }} value={el.drp} onChange={(e) => handleDrp(e.target.value, el.id)}>
                                            {
                                                time.map((el) => {
                                                    return <option key={el}>{el}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            freqs.length > 1 ?
                                                <ClearIcon
                                                    fontSize="string"
                                                    className={styles.deleteTrigger}
                                                    onClick={() => handleDeleteTrigger(el.id)}
                                                /> : ""
                                        }


                                    </div>
                                    {/* days */}
                                    {
                                        el.drp === "days" && <div className={styles.triggerCompDays}>
                                            <label>at</label>
                                            {
                                                !el.isOpended ? <AddIcon fontSize="string"
                                                    className={styles.plusIcon} onClick={() => handleisOpended(el.id, true)} /> :
                                                    <>
                                                        <input className={styles.triggerCompInput}
                                                            type="number" placeholder="Hours" value={el.hr} onChange={(e) => handleHour(e.target.value, el.id)} />
                                                        <input className={styles.triggerCompInput}
                                                            type="number" placeholder="Minutes" value={el.min} onChange={(e) => handleMin(e.target.value, el.id)} />
                                                        <ClearIcon fontSize="string"
                                                            onClick={() => handleisOpended(el.id, false)}
                                                            className={styles.deleteTrigger} />
                                                    </>
                                            }


                                        </div>
                                    }


                                    {/* weeks */}
                                    {
                                        el.drp === "weeks" && <div>
                                            <div className={styles.triggerCompDays}>
                                                <label>on</label>
                                                <select className={styles.triggerCompSelect} style={{ backgroundColor: "transparent", marginLeft: "10px" }} value={el.week} onChange={(e) => handleWeek(e.target.value, el.id)}>
                                                    {
                                                        weeks.map((el) => {
                                                            return <option key={el}>{el}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className={styles.triggerCompDays}>
                                                <label>at</label>
                                                {
                                                    !el.isOpended ? <AddIcon fontSize="string"
                                                        className={styles.plusIcon} onClick={() => handleisOpended(el.id, true)} /> :
                                                        <>
                                                            <input className={styles.triggerCompInput}
                                                                type="number" placeholder="Hours" value={el.hr} onChange={(e) => handleHour(e.target.value, el.id)} />
                                                            <input className={styles.triggerCompInput}
                                                                type="number" placeholder="Minutes" value={el.min} onChange={(e) => handleMin(e.target.value, el.id)} />
                                                            <ClearIcon fontSize="string"
                                                                onClick={() => handleisOpended(el.id, false)}
                                                                className={styles.deleteTrigger} />
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    }


                                    {/* months */}
                                    {
                                        el.drp === "months" && <div>
                                            <div className={styles.triggerCompMonths}>
                                                <label style={{ margin: "0 8px" }}>on the</label>
                                                <select className={styles.triggerCompSelect} style={{ backgroundColor: "transparent", marginLeft: "10px" }} value={el.day} onChange={(e) => handleDay(e.target.value, el.id)}>
                                                    {
                                                        days.map((el) => {
                                                            return <option key={el.id}>{el.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className={styles.triggerCompDays}>
                                                <label>at</label>
                                                {
                                                    !el.isOpended ? <AddIcon fontSize="string"
                                                        className={styles.plusIcon} onClick={() => handleisOpended(el.id, true)} /> :
                                                        <>
                                                            <input className={styles.triggerCompInput}
                                                                type="number" placeholder="Hours" value={el.hr} onChange={(e) => handleHour(e.target.value, el.id)} />
                                                            <input className={styles.triggerCompInput}
                                                                type="number" placeholder="Minutes" value={el.min} onChange={(e) => handleMin(e.target.value, el.id)} />
                                                            <ClearIcon fontSize="string"
                                                                onClick={() => handleisOpended(el.id, false)}
                                                                className={styles.deleteTrigger} />
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    }



                                </div>
                            }) : ""
                        }

                        {
                            freqs.length < 3 ? (<small className={styles.addTrigger}
                                onClick={handleAddTrigger}>Add another trigger</small>) :
                                <small className={styles.addTrigger} style={{ color: "gray" }}
                                >Add another trigger</small>
                        }
                        {
                            (!freqs[freqs.length - 1].times && count > 0) ? <small className={styles.error}>Please enter scheduler value</small> : ""
                        }

                    </div>
                </div>

                <div className={styles.afFooter}>
                    {
                        !triggerEdit ?
                            <>
                                <Input
                                    type="button"
                                    value={"Back"}
                                    className="secondary"
                                    onClick={handleBack}


                                />
                                <Input
                                    type="submit"
                                    value={"Save Draft"}
                                    className="secondary"
                                    onClick={handleDraft}

                                />
                                <Input type="submit" className="primary" value={"Next"} />
                            </> :
                            <>
                                <Input
                                    type="button"
                                    value={"Cancel"}
                                    className="secondary"
                                    onClick={handleCancel}
                                />
                                <Input type="submit" className="primary" value={"Save"} />
                            </>
                    }

                </div>
            </Form>
        </PopUp>
    </>
}