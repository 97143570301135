import React, { useState, useRef } from 'react'
import styles from './otaInstallation.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { ReactComponent as IosLogo } from '../../../../../../images/icons/cag/ioslogo.svg'
import { ReactComponent as AndroidLogo } from '../../../../../../images/icons/cag/androidlogo.svg'
import { ReactComponent as WebLogo } from '../../../../../../images/icons/cag/weblogo.svg'
import QRCode from "react-qr-code";
import { downloadFileFromUrl } from '../../../../../../api/url';
import { useAppContext } from '../../../../../../components/activity/AppContext';

function OTAInstallation({ pageNumber, handleClose, rolePopUp, containerAppDetails, otaUrl, resetCADetails }) {
    const [data, setData] = useState({
        name: ""
    })
    const qrCodeRef = useRef();
    const [showButtons, setShowButtons] = useState(false)
    const app = useAppContext()

    const onSubmit = () => {
        // console.log("onSubmitOTAIntallation")
    }

    const onWatch = (values, errors) => {
        // console.log(values)
    }

    const getQrCodeDataUrl = () => {
        let svgDoc = qrCodeRef.current.querySelector('svg');
        let serializeDoc = new XMLSerializer().serializeToString(svgDoc);
        // console.log("serializeDoc", serializeDoc)
        let result = window.btoa(serializeDoc);
        // console.log("result", result)
        return `data:image/svg+xml;base64,${result}`;
    }

    const handleDownloadQRCode = (e) => {
        downloadFileFromUrl(getQrCodeDataUrl(),"otaURLQrCode.svg")
    }

    const handleCopyOTAURL = (e) => {
        e.preventDefault()
        const {notify} = app()
        navigator.clipboard.writeText(otaUrl)
        notify({message:"OTA URL Copied to Clipboard", type:"S"})
    }

    const { Form, Label, Input, Error, TextArea } = useForm(data, onSubmit, onWatch);

    const handleClosePopup = (bool) => {
        resetCADetails()
        handleClose(bool)
    }

    let OTA = containerAppDetails?.containerApp?.otaUrl || otaUrl

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            title="OTA Installation"
            subtitle=""
        >
            {otaUrl?<div className={styles.mainWrapper}>
                <div style={{width:"50%"}}>
                    <div className={styles.rowCont}>
                        <div className={styles.numberCont}>1</div>
                        <div>Download/share the app from the ota url, using the barcode</div>
                    </div>
                    <div className={styles.rowCont}>
                        <div className={styles.numberCont}>2</div>
                        <div>Sign in with your EdgeReady credentials</div>
                    </div>
                    <div className={styles.rowCont}>
                        <div className={styles.numberCont}>3</div>
                        <div>Setup the required permissions</div>
                    </div>
                    <div className={styles.rowCont}>
                        <div className={styles.numberCont}>4</div>
                        <div>You would find the apps in the home screen</div>
                    </div>
                </div>
                <div>
                    <div 
                        className={styles.qrCodeCont} 
                        ref={qrCodeRef}
                        onMouseEnter={()=>{setShowButtons(true)}}
                        onMouseLeave={()=>{setShowButtons(false)}}
                        >
                        <QRCode value={OTA?OTA:""}
                            size={200}
                            // className={!showButtons?styles.svg:styles.svgHide}
                        />
                    {showButtons&&<div className={styles.buttonCont}>
                        <Input data-clickable-testid="download" type="button" style={{ minWidth:"150px",zIndex:"0" }} className="primary" value={"Download QRCode"} onClick={handleDownloadQRCode} />
                        <Input data-clickable-testid="copy" type="button" style={{ minWidth: "150px",zIndex:"0" }} className="primary" value={"Copy OTA URL"} onClick={handleCopyOTAURL} />

                    </div>}
                    </div>
                    <div className={styles.logoCont}>
                        <IosLogo/>
                        <AndroidLogo/>
                        <WebLogo/>
                    </div>
                </div>
            </div>:<h5 className={styles.emptyAppText}>No App Builds Exist</h5>}
        </PopUp>
    )
}

export default OTAInstallation;