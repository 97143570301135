import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerAppsLayout from "./layout.js"
import {
  alertCopyMessage,
} from "../../../helpers/project-settings";
import { fetchContainerAppList } from "../../../helpers/container-app-generation";
import {
  fetchProjectDetails,
  saveProjectPathUrl,
} from "../../../helpers/project-detail";
import { toTitleCase } from "../../../utils/common";
import { fetchpublishedloginlist } from "../../../helpers/all-logins.js";

let mobAppSearchValue = {};
let reset = false;

class ContainerApps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerAppGenerations: [],
      cagPagination: { limit: 10, offset: 0 },

      cagConfigModal: false,
    };

    this.handleCopyAlert = this.handleCopyAlert.bind(this);
  }

  componentWillMount() {
    mobAppSearchValue = {};
  }

  componentDidMount() {
    this.initAPIs();
  }

  setPaginationReset = () => {
    reset = false;
  }

  initAPIs = () => {
    const { match, history } = this.props;
    let projectId = match.params.id || "services_workspace";
    // fetchProjectDetails(projectId);
    fetchContainerAppList( {}, { limit: 10, offset: 0 }, projectId );
    // saveProjectPathUrl( projectId, history.location.pathname );
    fetchpublishedloginlist();
    this.setState({
      cagConfigModal: false,
      containerAppGenerations: [],
      cagPagination: { limit: 10, offset: 0 }
    });
    mobAppSearchValue = {};
    reset = true;
  }

  triggerCagInterval = () => {
    const { match } = this.props;
    let projectId = match.params.id || "services_workspace";
    if (!this.timerHandle) {
      this.timerHandle = setInterval(() => {
        if (!this.state.cagConfigModal) {
          fetchContainerAppList(
            mobAppSearchValue,
            this.state.cagPagination,
            projectId
          );
        }
      }, 30000);
    }
  };

  clearCagInterval = () => {
    if (this.timerHandle) clearInterval(this.timerHandle);
  };

  componentWillUnmount() {
    this.clearCagInterval();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { containerAppGenerations } = this.props;

    if(this.props.match.params.id !== prevProps.match.params.id){
      this.initAPIs();
    }

    let cag =
      containerAppGenerations &&
      containerAppGenerations.map((i) => {
        return {
          ...i,
          version:
          i.version && i.buildNumber ? `${i.version}/${i.buildNumber}` : "",
          deviceType: (i.deviceType && i.deviceType !== "") ? i.deviceType?.split(",") : [],
          appType: toTitleCase(i.appType?i.appType:""),
          caType: toTitleCase(i.caType?i.caType:""),
        };
      });
    if (containerAppGenerations !== prevProps.containerAppGenerations) {
      this.setState({ containerAppGenerations: cag });
      let pendingCag =
        cag.filter((e) => e.status?.toLowerCase() === "pending") || [];
      if (pendingCag?.length > 0) this.triggerCagInterval();
      else this.clearCagInterval();
    }
  }

  handleTableCheckBox() {
    this.setState({ checkDisable: false });
  }

  handleCopyAlert(otaUrl, isWeb = false) {
    navigator.clipboard.writeText(otaUrl);
    alertCopyMessage(isWeb);
  }

  variableCagListAPI(data) {
    let val = {
      limit: data.limit,
      offset: data.page,
    };
    this.setState({ cagPagination: val });
    fetchContainerAppList(mobAppSearchValue, val, this.props.match.params.id || 'services_workspace');
  }

  handleCagPagination = (pagination) => {
    this.setState({ cagPagination: pagination });
    this.variableCagListAPI(pagination);
  };

  handleChangeMobileApp = (obj) => {
    mobAppSearchValue = obj;
    fetchContainerAppList(
      mobAppSearchValue,
      {
        offset: 0,
        limit: 10,
      },
      this.props.match.params.id || 'services_workspace'
    );
  };

  render() {
    return (
      <ContainerAppsLayout
        {...this.props}
        handleCopyAlert={this.handleCopyAlert}
        tableNameList={this.props.tableNameList}
        microAppList={this.props.microAppList}
        containerAppsData={this.state.containerAppGenerations}
        handleCagPagination={this.handleCagPagination}
        cagId={this.props.cagId}
        cagStatus={this.props.cagStatus}
        containerAppDetails={this.props.containerAppDetails}
        handleChangeMobileApp={this.handleChangeMobileApp}
        cagConfigModal={this.state.cagConfigModal}
        setCagConfigModal={(e) => this.setState({ cagConfigModal: e })}
        setPaginationReset={this.setPaginationReset}
        reset = {reset}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projectDetail: state.project.process,
    totalVariableRecords: state.projectSettings.totalVariableRecords,
    variableDropDownList: state.projectSettings.variableDropDownList,
    projectUpdatePostDetail: state.projectSettings.projectUpdatePostDetail,
    containerAppGenerations: state.containerApp.generations,
    microAppList: state.containerApp.microAppList,
    cagNoOfRecords: state.containerApp.noOfRecords,
    cagId: state.containerApp.cagId,
    containerAppDetails: state.containerApp.containerAppDetails,
    fileUrl: state.containerApp.fileUrl,
    cagStatus: state.containerApp.status,
    tableNameList: state.databaseDesigner.tableNameList,
    publishedLogins: state.alllogins.publishedLogins,
  };
};
export default connect(mapStateToProps)(ContainerApps);
