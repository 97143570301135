import React, { useEffect, useMemo, useRef, useState, } from "react";

const Errors = (setErrorState) => {
  const errs = {};
  const refresh = () => {
    setErrorState((c) => c + 1);
  };
  const clear = (name) => {
    if (errs[name]) {
      delete errs[name];
      refresh();
    }
  };

  const clearAll = () => {
    if (errs) {
      Object.keys(errs).forEach((key) => delete errs[key]);
      refresh();
    }
  };

  const set = (name, msg) => {
    errs[name] = msg;
    refresh();
  };
  const message = (name) => errs[name];
  const indicator = (name) => (errs[name] ? { "data-error": true } : {});
  return {
    clear,
    set,
    message,
    indicator,
    clearAll,
  };
};

function makeForm(errors, initial) {
  let values = { ...initial };
  let onWatch = null;
  let onSubmit = null;
  const data = {
    name: "",
    value: "",
  };

  const changeHandler = (e) => {
    const name = e.target.name;
    let value = ""
    if(e.target.type === "checkbox"){
      value = e.target.checked
    } else if(e.target.type === "file"){
      value = e.target?.files[0]
    } else {
      value = e.target.value;
    }
    const newValues = {
      ...values,
      [name]: value,
    };
    values = newValues;
    data.name = name;
    data.value = value;
    data.type = e.type;
    data.dataType = values.dataType;
    data.index = e.target?.attributes?.index?.value
    if (onWatch) {
      onWatch(errors, data);
      if (data.value !== value) {
        if (e.target.type === "checkbox") {
          e.target.checked = data.value;  
        } else e.target.value = data.value;
        const newValues = {
          ...values,
          [name]: data.value,
        };
        values = newValues;
      }
    }
  };

  const checkboxHandler = (e) => {
    const name = e.target.name
    let value = e.target.value
    // if(e.target.checked){
    //   value = [value, e.target.value]
    // }
    // else{
    //   value = value.filter(item=>item!==e.target.value)
    // }
    const newValues = {
      ...values,
      [name]: value,
    };
    values = newValues;
    data.name = name;
    data.value = value;
    data.checked = e.target.checked
    data.type = e.type;
    data.dataType = values.dataType;
    data.index = e.target.attributes.index?.value
    if (onWatch) {
      onWatch(errors, data);
      }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (await onSubmit(values, errors)) {
      try {
        e.target.reset();
        values = { ...initial };
      } catch (err) {
        console.log(err);
      }
    }
  };

  const Form = ({ children, style={} }) => {
    return (
      <form className="form" style={style} onSubmit={submitHandler}>
        {children}
      </form>
    );
  };

  const Label = ({ text, children,  testTextId ,  ...props }) => {
    let testId = testTextId ? testTextId : text;
    if(typeof text === "object")
      testId = props.testId
    return (
      <label data-text-testid={testId} {...props}>
        {children}
        {text}
      </label>
    );
  };

  const Input = ({ name, type, ...props }) => {
    const extras = {};
    if (type === "checkbox") {
      extras["data-option-testid"] = name;
      extras["defaultChecked"] = initial[name]
    } else if (type === "submit" || type==="button") {
      extras["data-clickable-testid"] = props.value || "Submit";
    } else if (type !== "submit") {
      extras["data-input-testid"] = name;
      extras["defaultValue"] = initial[name];
    } 
    return (
      <input
        name={name}
        type={type}
        {...errors.indicator(name)}
        {...extras}
        onChange={changeHandler}
        // onBlur={changeHandler}
        {...props}
      />
    );
  };

  const File = ({ name, type, ...props}) => {
    const extras = {};
    return (
      <>
        <input 
          name={name}
          type={"file"}
          value={props.value}
          data-input-testid={name}
          {...errors.indicator(name)}
          {...extras}
          onChange={changeHandler}
          {...props}
        />
      </>
    )
  }

  const FileUploadDragAndDrop = ({children, style, name, ...props}) => {
    const dropArea = useRef(null);

    useEffect( () => {
        let dropRef = dropArea.current;
        dropRef.addEventListener('dragover', handleDrag);
        dropRef.addEventListener('drop', handleDrop);
        return () => {
            dropRef.removeEventListener('dragover', handleDrag);
            dropRef.removeEventListener('drop', handleDrop);
        }
    },[])

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const fileObject = {target:{name:name, files:[e.dataTransfer.files?.[0]], type:"file" }}
        changeHandler(fileObject)
    }
    return (
      <div 
        onDrop={handleDrop} 
        ref={dropArea}
        className={style}
        {...props}
      >
        {children}
      </div>
    )
  }

  const Checkbox = ({ name, type, ...props }) => {
    const extras = {};
    extras["defaultChecked"] = initial[name]?.includes(props.value)||false;
    extras["data-option-testid"] = props?.testId?props.testId:name;
    return (
        <input
          name={name}
          type={"checkbox"}
          value={props.value}
          {...errors.indicator(name)}
          {...extras}
          {...props}
          onChange={checkboxHandler}
        />
    )
  };

  const Radio = ({ name, type, ...props }) => {
    const extras = {};
    extras["data-option-testid"] = props?.testId?props.testId:name;
    extras["defaultChecked"] = initial[name] === props.value;
    // extras["defaultValue"] = initial[name];
    return (
      <>
        <input
          name={name}
          type={"radio"}
          value={props.value}
          {...errors.indicator(name)}
          {...extras}
          onChange={changeHandler}
          // onBlur={changeHandler}
          {...props}
        />
        {props.labelStyle?<div style={props.labelStyle}>{props.displayName} </div>: props.displayName}
      </>
    );
  };

  const Select = ({ name, ...props }) => {
    const extras = {};
    extras["defaultValue"] = initial[name];
    return (
      <select
        name={name}
        data-option-testid={name}
        {...props}
        {...extras}
        {...errors.indicator(name)}
        onChange={changeHandler}
        // onBlur={changeHandler}
      />
    );
  };

  const TextArea = ({ name, ...props }) => {
    const extras = {};
    extras["defaultValue"] = initial[name];
    return (
      <textarea
        name={name}
        type={"text"}
        data-input-testid={name}
        {...props}
        {...extras}
        {...errors.indicator(name)}
        onChange={changeHandler}
        // onBlur={changeHandler}
      />
    );
  };

  const Error = ({ name, message, ...props }) => {
    let msg = "";
    if (message) msg = message;
    else if (name) msg = errors.message(name);
    return (
      <div
        className="errorMessage"
        data-error-testid={name ? name : "api"}
        {...props}
      >
        {msg}
      </div>
    );
  };

  const setOnWatch = (f) => {
    onWatch = f;
  };

  const setOnSubmit = (f) => {
    onSubmit = f;
  };

  const form = {
    Form,
    Label,
    Input,
    Select,
    Error,
    errors,
    TextArea,
    setOnWatch,
    setOnSubmit,
    Radio,
    File,
    Checkbox,
    FileUploadDragAndDrop,
    submitHandler,
  };
  return form;
}

function useForm(values, onSubmit, onWatch) {
  const [, setErrorState] = useState(0);
  const errorsRef = useRef(Errors(setErrorState));
  const form = useMemo(() => makeForm(errorsRef.current, values), [values]);
  form.setOnWatch(onWatch);
  form.setOnSubmit(onSubmit);

  useEffect(() => {
    errorsRef.current.clearAll();
  }, [values]);

  return {
    Form: form.Form,
    Label: form.Label,
    Input: form.Input,
    Select: form.Select,
    Error: form.Error,
    TextArea: form.TextArea,
    errors: form.errors,
    Radio: form.Radio,
    File: form.File,
    Checkbox: form.Checkbox,
    DragAndDropArea: form.FileUploadDragAndDrop,
    submitHandler: form.submitHandler
  };
}

export { useForm };
