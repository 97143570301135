import React, { useEffect, useState } from 'react';
import { useScheduler } from '../../schedulerContext';

export const  SystemsTable = ({ systems = [] }) => {
    const { parameters, setParameters } = useScheduler();
    const [credentials, setCredentials] = useState(
        systems.reduce((acc, system) => {
          acc[system] = { 
            userId: parameters.system?.find(s => s.name === system)?.userId || '', 
            password: parameters.system?.find(s => s.name === system)?.password || ''
          };
          return acc;
        }, {})
      );
    
      const handleInputChange = (event, system, field) => {
        setCredentials(prevCredentials => ({
          ...prevCredentials,
          [system]: { ...prevCredentials[system], [field]: event.target.value }
        }));
      };
    
      useEffect(() => {
        const transformedData = systems.map(sys => ({
          name: sys,
          userId: credentials[sys]?.userId || "",
          password: credentials[sys]?.password || ""
        }));
    
        setParameters(p => ({ ...p, system: transformedData }));
      }, [credentials, systems]);

    



  return (
    <div style={{ maxWidth: "350px",width:"350px"}}>
      <table>
        <thead>
          <tr>
            <th style={{maxWidth:"120px",width:"120px"}}>System</th>
            <th style={{maxWidth:"120px",width:"120px"}}>User ID</th>
            <th style={{maxWidth:"120px",width:"120px"}}>Password</th>
          </tr>
        </thead>
        {
            (systems && systems.length) ? 
            <tbody>
            {systems.map(system => (
              <tr key={system}>
                <td>{system}</td>
                <td>
                  <input
                    type="text"
                    style={{width:"120px",outline:"none"}}
                    spellCheck={'false'}
                    value={credentials[system]?.userId || ""}
                    onChange={e => handleInputChange(e, system, 'userId')}
                  />
                </td>
                <td>
                  <input
                    type="password"
                    style={{width:"120px",outline:"none"}}
                    spellCheck={'false'}
                    value={credentials[system]?.password || ""}
                    onChange={e => handleInputChange(e, system, 'password')}
                  />
                </td>
              </tr>
            ))}
          </tbody> : <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: 'center' }}>
                No data available
              </td>``
            </tr>
          </tbody>
        }
        
      </table>
    </div>
  );
}


