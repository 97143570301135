import { deleteAllCookies } from "./cookieServices";
import store from "../stores";
import { handleSessionExpired } from "../actions/sign-out";
import {
  clearPermissions,
  unselectProject,
  closeAllProjects,
} from "../helpers/app-root";
import {
  PREFIX_ER_STORAGE_PLATFORM,
  loginConstants,
  ENVIRONMENT_KEY,
  DEFAULT_INITIAL_STATE,
  DEFAULT_END_STATE,
  regularExpConstants,
} from "../constants";
import { pageViewPolicy } from "./policy";
import { SHAPE_TYPES } from "../libraries/mxgraph-wrapper/shape/types";
export function s3Url(url) {
  // if (url) {
  //   return url.replace(/=/g, '/u003d');
  // }
  return url;
}

export function buildUrl() {
  const domain = new URL(window.location);
  if (
    !domain.hostname.includes("localhost") &&
    !domain.hostname.includes("dev-dm4")
  ) {
    return `/`;
  } else {
    return `https://dft6.bifreedom.com/`;
  }
}

export function getCurrentDomain() {
  let url = new URL(window.location);
  if (
    !url.hostname.includes("localhost") &&
    !url.hostname.includes("dev-dm4")
  ) {
    return url.protocol + "//" + url.hostname;
  } else {
    return `https://dft6.bifreedom.com/`;
  }
}

export function redirectionToRestriction() {
  // let history = useHistory();
  // history.push('/restricted');
}
export function escapeRegExp(str) {
  if (typeof str === 'string' || str instanceof String) {
    return "";
  }
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export function caseInsesitiveSearch(searchString, text) {
  if ((typeof searchString === 'string' || searchString instanceof String)
    && (typeof text === 'string' || text instanceof String)
  ) {
    return text.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
  }
  return false;
}


export function fileToBlob(file, cb) {
  let reader = new FileReader();
  reader.onload = function (e) {
    let blob = new Blob([this.result], { type: file.type });
    blob.name = file.name;
    cb(blob);
  };
  reader.readAsArrayBuffer(file);
}

export function setSessionVal(key, value) {
  sessionStorage.setItem(`${loginConstants.URL_PREFIX}${key}`, value);
}

export function getSessionVal(key) {
  const val = sessionStorage.getItem(`${loginConstants.URL_PREFIX}${key}`);
  return val;
}

export function domainURL(data) {
  let s3url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.hostname.includes("localhost")) {
    let url = data === "iframe" ? "3003" : "3000";
    return `${s3url}:${url}/`;
  } else {
    return `${s3url}/preview/index.html`;
  }
}

export function isJSONEqual(object1, object2) {
  if (JSON.stringify(object1) === JSON.stringify(object2)) return true;
  return false;
}

export function downloadFileFromUrl(value, name) {
  const url = s3Url(value);
  const a = document.createElement("a");
  a.href = url;
  a.download = name ? name : "";
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}





export function specialCharValidate(value) {
  let iChars = "!`@#$%^&*()+=[]\\';,./{}|\":<>?~";
  let initial = false;
  for (let i = 0; i < value.length; i++) {
    if (iChars.indexOf(value.charAt(i)) != -1) {
      initial = true;
    }
  }
  return initial;
}

export function generateUid() {
  // let length = 8, id='';
  // let timestamp = +new Date;
  // var ts = timestamp.toString();
  // var parts = ts.split( "" ).reverse();
  // for( var i = 0; i < length; ++i ) {
  //  let min = 0, max = parts.length - 1
  //  var index = Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  //  id += parts[index];
  // }

  // return id;

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getCookies(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function getEnvironment(key) {
  const environments = {
    dev: "Development",
    qua: "Quality",
    prod: "Production",
  };
  return environments[key] ? environments[key] : "";
}

export function getLoggedInEnv() {
  return window.sessionStorage.getItem(ENVIRONMENT_KEY);
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function executeLogout() {
  let url = sessionStorage.getItem("reLoginUrl")
  clearSessionInfo();
  if (url) {
    localStorage.setItem("reLoginUrl", url)
    store.dispatch(handleSessionExpired())
  }
}

export function clearSessionInfo() {
  // clearPermissions();
  // unselectProject();
  // closeAllProjects();
  deleteAllCookies();
  sessionStorage.clear();
  localStorage.removeItem("auth")
}

function renderWithChildPerm(obj, url) {
  let childperm = { ...obj };
  obj.childPermissions &&
    obj.childPermissions.map(
      (e) =>
      (childperm = {
        ...childperm,
        [e.name.toLowerCase().replaceAll(" ", url ? "" : "_")]:
          renderWithChildPerm(e, url),
      })
    );

  return childperm;
}

export function getPermissions(url = false) {
  // let permissions = store.getState().application.userPermissions || [];
  let permissions = sessionStorage.getItem(
    `${PREFIX_ER_STORAGE_PLATFORM}userPermission`
  );
  permissions = JSON.parse(permissions);

  let perm = {};

  permissions &&
    permissions.map((e) => {
      perm = {
        ...perm,
        [e.name.toLowerCase().replace(" ", "")]: renderWithChildPerm(e, url),
      };
    });

  return perm;
}

export function pageViewPermissions() {
  let perms = getPermissions()?.projects?.business_function?.pageview;
  if (!perms) {
    perms = {};
  }
  // return perms;
  return new pageViewPolicy(perms);
}

export const getStepTransitions = (graphJson, pageName, isWorkFlow = false, overRidetransitionId = false, isGroup = false) => {
  graphJson = _deepCloneJSON(graphJson);
  let endDefault = 'EndDefault';

  const getNextStep = (obj, step, target = "stepId") => {
    return obj.find((e) => e[target] === step);
  };

  const getStep = (stepName) => {
    let step = {};
    let stepLane = {};

    if (graphJson && graphJson.steps) {
      stepLane = graphJson.steps.find((sT) => {
        return sT.name === pageName;
      });

      step = graphJson.steps.find((sT) => {
        return sT.name === stepName && sT.laneName === stepLane.laneName;
      });

      if (!step) {
        step = getNextStep(graphJson.steps, stepName, "name");
      }
      if (step && step.name === "End" && !isWorkFlow) {
        step = { ...step, name: `End/Home` };
      } else if (step && step.name === endDefault && isWorkFlow && !isGroup) {
        let endNextStep =
          getNextStep(graphJson.stepTransitions, step.uid)?.nextStepName || "";
        let nextParentStep =
          getNextStep(
            graphJson.parentStepTransitions,
            endNextStep,
            "stepName"
          ) || "";
        let getStepType =
          getNextStep(
            graphJson.parentSteps,
            nextParentStep.nextStepId,
            "uid"
          ) || "";
        step = {
          ...step,
          type: getStepType?.type || "",
          name: nextParentStep?.nextStepName,
          uid: ` _End`,
        };
      }
    }
    return step;
  };

  let stepTransitions = [];
  if (graphJson && graphJson.stepTransitions) {
    stepTransitions = graphJson.stepTransitions.filter((sT) => {
      return sT.stepName === pageName;
    });
  }

  if (stepTransitions.length > 0) {
    stepTransitions = [
      ...stepTransitions.map((stepInfo) => {
        let obj = getStep(stepInfo.nextStepName);
        if (obj) {
          let stepObj = {
            name: obj?.name || '',
            type: obj?.type || '',
            objectId: obj?.uid || obj?.name || '',
            uid: obj?.uid,
          };
          if (overRidetransitionId) {
            return {
              ...stepObj,
              objectId: stepInfo?.uid || '',
              uid: stepInfo?.uid,
            }
          }
          return stepObj;
        }
        return null;
      }),
    ];
    stepTransitions = stepTransitions.filter(st => (st != null));
  }
  stepTransitions.push({
    name: "Go Back",
    objectId: "-1",
    type: "System",
  });
  stepTransitions.push({
    name: "LogOut",
    objectId: "-2",
    type: "System",
  });

  return stepTransitions;
};

export const getStepNavigation = (graphJson, pageName, isBehavior = false) => {
  graphJson = _deepCloneJSON(graphJson);
  let stepTransitions = [];
  let childObj = [];
  let taskObj = [];
  let stepObj = [];
  let pageObj = [];

  const getTaskGroup = (obj, pageObj) => {
    let lastObj = [];
    let itemObj = [];
    let navigateObj = [];
    navigateObj = obj.filter((item) => {
      return pageObj?.id === item.start && item.type === "connector";
    });

    if (navigateObj.length > 0) {
      itemObj = navigateObj.map((item) => {
        return item.end;
      });

      lastObj = obj.filter((e) => itemObj.includes(e.id));
    }

    return lastObj;
  };

  graphJson.graph &&
    graphJson.graph.lanes &&
    graphJson.graph.lanes.map((item) => {
      childObj = [...childObj, ...(item?.children || [])];
    });

  pageObj = childObj.find((item) => {
    return item.name === pageName && item.type === SHAPE_TYPES.SCREEN;
  });

  if (!pageObj) {
    taskObj = childObj.find((e) => {
      if (e.type === "Task" && e.childTask) {
        let pageTask = e.childTask.find((f) => f.name === pageName);
        if (pageTask) {
          pageObj = pageTask;
          return e;
        }
      }
    });
    stepObj = getTaskGroup(taskObj?.childTask || [], pageObj);
    /*
     *   Commenting this part as this create's issue in preview.
     *   Reverting as ENDDEFAULT in navigate to dropdown.
     */
    if (isBehavior) {
      let endTask = stepObj.find((f) => f.type === "EndDefault");
      if (endTask) {
        stepObj = stepObj.filter((f) => f.type !== "EndDefault");
        stepObj = [...stepObj, ...(getTaskGroup(childObj, taskObj) || [])];
      }
    }
  } else {
    stepObj = getTaskGroup(childObj, pageObj);
  }

  stepTransitions = stepObj.map((obj) => {
    if (obj.executeAsWorkflow) {
      obj.uid = ` _End`;
    }
    if (["EndDefault", "End"].includes(obj.type)) {
      obj.name = obj.type;
    }
    if (obj.uid) {
      return {
        ...obj,
        objectId: obj?.uid || obj?.name || '',
      };
    }
  });

  stepTransitions.push({
    name: "Go Back",
    objectId: "-1",
    type: "System",
  });
  stepTransitions.push({
    name: "LogOut",
    objectId: "-2",
    type: "System",
  });

  return stepTransitions;
};

export function isNoPageHasPermissions() {
  let permissions = getPermissions();
  let flag = true;

  permissions &&
    Object.keys(permissions).map((e) => {
      if (permissions[e].canView === true) flag = false;
    });

  return flag;
}

export function checkIfCurrentPageHasPermission() {
  let url = window.location.pathname.split("/");
  url = url.filter((e, i) => i !== 0);

  if (url.length > 0) {
    let val = {};
    /*
     *   checks if the url is in project path
     *   if yes url to be traced without the id like project_id , b_func_id
     *   else proceed with normal tracing through the url
     */

    if (url[0].toLowerCase() === "project") {
      val = getPermissions(true);
      if (url.length <= 2) {
        val = val?.projects;
      } else if (url.length > 2) {
        val = val?.projects?.[url[2].toLowerCase()];
      }
      // url.map((e,i) => {
      //   if(i === 0)
      //     val = val?.projects;
      //   else if(i %2 === 0){
      //     val = val?.[e.toLowerCase()];
      //   }
      // })
    } else {
      val = getPermissions();
      url.map((e) => {
        val = val?.[e.toLowerCase()];
      });
    }
    /***      Redirects if the page has no permissions    ***/

    if (
      val &&
      !val?.canView &&
      !["/home", "/changepassword", "/userprofile", "/forbidden", "/"].includes(
        window.location.pathname.toLowerCase()
      )
    )
      window.location.href = "/forbidden";
  }
}

export function variablePermissions() {
  let permissions = getPermissions();
  return (
    permissions?.projects?.business_function?.business_function_variable || {}
  );
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function _underscoreEncode(str) {
  const val = str.trim().replace(/ /g, "_");
  return val;
}

//saving current path for opened projects
export function saveCurrentPath(openedProject, currentProject, self) {
  openedProject.map((e) => {
    if (e.name == currentProject) {
      e.currentPath = self.props.history.location.pathname;
    }
  });
}

export function dateFormat(value) {
  if (value) {
    const val = new Date(value);
    const day = `0${val.getDate()}`.slice(-2);
    const month = `0${val.getMonth() + 1}`.slice(-2);
    const year = val.getFullYear();
    //const hhmmss = `00:00:00`;
    return `${month}/${day}/${year}`;
  } else {
    return "";
  }
}

export function showDateFormat(value) {
  if (value) {
    const val = new Date(value);
    const day = `0${val.getDate()}`.slice(-2);
    const month = `0${val.getMonth() + 1}`.slice(-2);
    const year = val.getFullYear();
    const hhmmss = `00:00:00`;
    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
}

export function getUserPropertyId(value, id = "", rid = "") {
  let userList = store.getState().settings.userPropertyList;
  const key = value.split(".")[1];
  let dt = userList.find((e) => key == e[id]);
  if (dt) {
    return value.replaceAll(dt[id], dt[rid]);
  } else return value;
}

export function sanitizeValue(value) {
  return value.replace(/[^\w\d\s-\_\.]+/g, ""); // Remove special character
}

export function getProperyParseValue(val) {
  if (
    val.startsWith("$") ||
    val.startsWith("GETUSERNAME") ||
    regularExpConstants.NUMERIC_PATTERN.test(val) ||
    val.startsWith('"')
  ) {
    val = val;
  } else if (val.startsWith("GETUSERPROPERTY")) {
    val = getUserPropertyId(val, "name", "propertyUuid");
  } else {
    // val = `\"${val}\"`;
  }
  return val;
}

export function constructFilterProperty(properties) {

  const getFormattedValue = (value) => {
    return value && !value.startsWith('$') ? `'${value}'` : value;
  }

  let f = "";
  const dataValidation = properties.every(property => property.name && property.condition && property.value)
  if (dataValidation) {
    properties.map((e) => {
      if (e.name && e.value) {
        if (f) f += ` ${e.op}, `;
        f += `${e.condition}(${getFormattedValue(e.name)},${getFormattedValue(e.value)}${!e.value_2 ? '' : `,${getFormattedValue(e.value_2)}`})`;
      }
    });
  }
  return f;
}

export function constructProperty(data) {
  const getData = (row = {}, column = []) => {
    if (column.length === 0) return "";
    else {
      let f = "";
      let operators = {
        "=": "EQ",
        "!=": "NE",
        "<": "LT",
        ">": "GT",
        "<=": "LE",
        ">=": "GE",
      };
      column.map((e, index) => {
        let val = row[e]?.value;
        let col = "";
        if (e.startsWith("GETUSERPROPERTY")) {
          col = getUserPropertyId(e, "name", "propertyUuid");
        } else {
          col = e;
        }
        val = getProperyParseValue(val);
        if (val) {
          if (f) f += ` AND `;
          f += `${col} ${operators[row[e]?.op || "="]} ${val || ""}`;
        }
      });
      return f;
    }
  };

  return data.rows.map((item) => {
    return {
      id: item.id,
      expr: getData(item, data.columns),
      output: item.output,
    };
  });
}

export function decodeProperty(data) {
  let { columnNames, dmnTable } = data;
  let col = [];
  col = columnNames.map((item) => {
    if (item.startsWith("GETUSERPROPERTY")) {
      return getUserPropertyId(item, "propertyUuid", "name");
    } else {
      return item;
    }
  });
  let operators = { EQ: "=", NE: "!=", LT: "<", GT: ">", LE: "<=", GE: ">=" };
  const getData = (arr = "") => {
    let [leftOperand, operator, ...rightOperand] = arr.split(" ");
    rightOperand = rightOperand?.join(" ") || "";
    let val = rightOperand?.startsWith("GETUSERPROPERTY")
      ? getUserPropertyId(rightOperand, "propertyUuid", "name")
      : rightOperand || "";
    let col = leftOperand?.startsWith("GETUSERPROPERTY")
      ? getUserPropertyId(leftOperand, "propertyUuid", "name")
      : leftOperand || "";
    // if (val.startsWith('"') && val.endsWith('"')) {
    //   val = val.substr(1).slice(0, -1);
    // }
    return {
      [col]: { op: operators[operator || "EQ"], value: val },
    };
  };
  if (dmnTable && dmnTable.length === 0) return { columns: [], rows: [] };
  else {
    let payload = dmnTable.map((e, index) => {
      let data = e.expr.split("AND");
      let d = { ...e };
      col.forEach((item) => {
        d = { ...d, [item]: { op: "=", value: "" } };
      });
      data.map((e) => {
        d = { ...d, ...getData(e.trim()) };
      });
      return d;
    });
    return { columns: col, rows: payload };
  }
}

export function getDefaultWorkflowStates() {
  return [
    {
      uid: generateUid(),
      value: DEFAULT_INITIAL_STATE,
    },
    {
      uid: generateUid(),
      value: DEFAULT_END_STATE,
    },
  ];
}

export function _deepCloneJSON(json) {
  return JSON.parse(JSON.stringify(json));
}

export function getVarType(vars = [], arr = []) {
  let type = "";
  if (vars[1]) {
    let varData = arr.find((e) => e?.name === vars[1]) || {};
    type = varData?.dataType || varData?.type || "";
    if (varData && vars[2]) {
      let varData2 = varData?.fields?.find((e) => e?.key === vars[2]) || {};
      type = varData2?.value || "";
    }
  }
  return type;
}

export function isValidLHSRHSCondition(
  lhsVar = "",
  rhsVar = "",
  arrOfVars = {}
) {
  if (lhsVar && rhsVar) {
    let lhsVars = lhsVar?.split(".") || [];
    let rhsVars = rhsVar?.split(".") || [];
    let lhsType = getVarType(lhsVars, arrOfVars[lhsVars?.[0] || ""]) || "";
    let rhsType = getVarType(rhsVars, arrOfVars[rhsVars?.[0] || ""]) || "";
    return !lhsType || !rhsType || lhsType === rhsType;
  } else {
    return true;
  }
}

export function safelyParseJSON(json) {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    console.error("safelyParseJSON: data is incorrect to parse in json", json);
  }
  return parsed;
}
