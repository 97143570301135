// import styles from "./index.module.css";
import React, { useEffect, useState } from "react";
import PopUp from "../layouts/PopUp";
import Table from "../Table/table";
import { messages } from "./messages.js";
import styles from './share.module.css'

function ProjectShareDatabase(props) {
    const { popUp, setPopUp, data, saveSharedProjects,name,loadData , sharedData , perm } = props;
    const [filter,setFilter] = useState({})
    const [list, setList] = useState([{ project: '', use: false }])

    const headers = [
        { name: messages.tableHeader1, id: "project", type: "input", width:"80%" },
        { name: messages.tableHeader2, id: "use", type: "checkboxSelect", value: ['Yes', 'No'], width:"20%" },
    ];

    const close = () => {
        setFilter({})
        setPopUp(false)
    }

    const update = (checked,name) => {
        const prj = { "projectName": name, "use":checked }
        saveSharedProjects(prj)
        loadData(perm)
        formatData()
    }

    const formatData = () => {
        setList([]);
        let prjs = [];

        if (data) {
            const sharedDataMap = new Map(sharedData.map(({ projectName, isShared }) => [projectName, isShared]));

            prjs = Object.values(data).map((projectName) => ({
                project: projectName,
                use: sharedDataMap.has(projectName) ? true : false
            }));
        }

        if (filter && filter.project) {
            prjs = prjs.filter(({ project }) =>
                project.includes(filter.project)
            );
        }

        let filterUse = filter.use === 'Yes' ? true : filter.use === 'No' ? false : ""

        if (filter && filter.use) {
            prjs = prjs.filter(({ use }) =>
                use === filterUse
            );
        }

        setList(prjs);
    };

    const updateFilter = async (id,value)=>{
        let obj = {...filter};
        obj[id]=value
        setFilter(obj)
    } 

    useEffect(() => {
        formatData()
    }, [filter])

    useEffect(() => {
        formatData()
    }, [data])

    return (
        <PopUp title={messages.title} popUp={popUp} setPopUp={close} name={messages.title} testId={"manage-permissions"}>
            <div className={styles.mainWrapper}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tabelAlign}>
                        <Table
                            data={list || []}
                            headerColumns={headers}
                            name={"permissions-list"}
                            handleHeaderInputChange={(id, value) => updateFilter(id, value)}
                            handleHeaderSelectChange={(id, value) => updateFilter(id, value)}
                            checkBoxOnChange={(check, name) => update(check, name)}
                        />
                    </div>
                </div>
            </div>
        </PopUp>
    )
}

export default ProjectShareDatabase;