import React, { useState, useEffect } from 'react'
import { useForm } from '../../../../../../components/forms/Form';
import styles from './masterTable.module.css'
import Table from '../../../../../../components/Table/table';
import PopUp from '../../../../../../components/layouts/PopUp';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function MasterTable({ pageNumber, handleClose, projectName, containerAppDetails, setContainerAppDetails, resetCADetails, tableList, rolePopUp }) {
    const [data, setData] = useState({
        filter: "",
    })
    const [containerAppTables, setContainerAppTables] = useState(containerAppDetails?.containerAppTables||[])
    const getDataFilterValues = (obj) => {
        containerAppTables.map(e => {if(obj[e.name]==undefined)Object.defineProperty(obj,e.name,{value:e.filter})})
        return obj
    }
    const [initialTableList, setInitialTableList] = useState(tableList || [])
    const [selectedTables, setSelectedTables] = useState(containerAppTables.map((el) => {return el.name})||[])
    const [dataFilterValues, setDataFilterValues] = useState(getDataFilterValues({})||{})
    const [formattedData, setFormattedData] = useState([])
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false)
    const app = useAppContext()

    useEffect( () => {
        setFormattedData(formatData(initialTableList))
    },[initialTableList])

    const headers = [
        { name: "Table Name", id: "name", type: "input", width: "200px" },
        { name: "Data Filters", id: "filter", type: "", width: "400px", style: {maxWidth: "400px", color: "rgb(140, 140, 140)", fontSize: "13px", fontWeight: "400", paddingLeft:'20px'}},
        { name: "", id: "selectTable", type: "checkBox", },
    ];

    const updateContainerAppDetails = async (newValues) => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
        if(!err){
            const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                if(!err2) {
                    setContainerAppDetails({...data2.detail, genarate:false})
                    setContainerAppTables(data2.detail.containerAppTables)
    }

            if(data.status.type!=="E") saveChangesPopup?closePopup(false):pageNumber(clickedBack?2:4)
        }
    }

    const onSubmit = (values, errors) => {
        if(!hadEdit&&!clickedBack) {pageNumber(4);return}
        let temp = []
        selectedTables.map( (name) => {
            if(dataFilterValues[name]) {
                let [dataFilterKey, dataFilterValue] = dataFilterValues[name].split("=")
                temp.push({name:name, filter:dataFilterKey+"="+(dataFilterValue?.replace(/[']/g,"%27"))})
            }else {
                temp.push({name:name, filter:""})
            }
        })
        setContainerAppTables(temp)
        let newValues = {...containerAppDetails, containerAppTables:temp}
        updateContainerAppDetails(newValues)
    }

    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout(()=>{
            if(hadEdit){
                submitHandler(e)
            }
            else {
        pageNumber(2)
    }
        },0)    
    }

    const onHeaderChange = (id, value) => {
        let filteredData = tableList.filter((e) => e[id].toLowerCase().includes(value.toLowerCase()))
        setInitialTableList(filteredData)
    }

    const onCheckBoxChange = (checked, name) => {
        setHadEdit(1)
        if(checked){
            if(!selectedTables.includes(name))
            setSelectedTables((prev)=>[...prev, name])
        }
        else {
            setSelectedTables((prev)=>prev.filter((e)=>e!==name))
        }
    }

    const onWatch = (errors, values) => {
        setHadEdit(1)
        setDataFilterValues((prev)=> ({...prev, [values.name]:values.value}))
    }
    const { Form, Label, Input, Error, Select, submitHandler } = useForm(data, onSubmit, onWatch);

    const formatData = (tableList) => {
        let finalData = []
        finalData = (tableList.map( (table) => {
            let isChecked = containerAppTables.find((el) => {return el?.name==table.name})
            let filterValue = containerAppTables.find((el) => {return el.name==table.name?el.filter:""})?.filter
            let temp = {}
            temp["name"] = table.name;
            temp["filter"] = (<Input type={"text"} style={{width:"100%"}} name={table.name} defaultValue={filterValue?.replace(/[%]27/g, "'")}  placeholder={"Where (Example: Name='$LOGINID$')"}/>);
            temp["isChecked"] = isChecked;
            temp["selectTable"] = "";
            return temp
        }))
        return finalData
    }
    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"masterTables"}
            title="Master tables"
            subtitle=" These table data are built along with the application and can be commonly used across Applications"
                >
                <Form style={{ width: '100%' }}>
                <div className={styles.tableWrapper}>
                                <Table
                        name={"Master Table"}
                        // data={list.data || []}
                        data={formattedData || []}
                                    headerColumns={headers}
                                // contextOnChange={menuOnClick}
                        handleHeaderInputChange={onHeaderChange}
                        checkBoxOnChange={onCheckBoxChange}
                                />
                            </div>
                    <div className='afPopupFooter'>
                        <div>
                            <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                            <Input type="submit" value={"Next"} />
                        </div>
                    </div>
                </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default MasterTable