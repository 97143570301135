import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ModuleTitle } from '../../../../components/module-title';
import Pagination from '../../../../components/Pagination/pagination';
import Table from '../../../../components/Table/table';
import DeploymentPopup from '../deploymentPopup/deploymentPopup';
import { useAppContext } from '../../../../components/activity/AppContext';
import { ProjectSettingsActions } from '../projectsettings.actions';
import styles from './projectDepTable.module.css'
import OTAInstallation from '../deploymentPopup/components/ota-installation/otaInstallation';
import SmallPopup from '../../../../components/layouts/smallPopup';
import {ReactComponent as EditIcon} from '../../../../images/icons/cag/editIcon.svg'
import {ReactComponent as FinishedIcon} from '../../../../images/icons/cag/finishedIcon.svg'
import {ReactComponent as FailedIcon} from '../../../../images/icons/cag/failedIcon.svg'
import {ReactComponent as PlusIcon} from '../../../../images/icons/cag/addIcon.svg'
import { downloadFileFromUrl } from '../../../../api/url';

function ProjectDepTable(props) {
    const {perm} = props
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [deploymentPopup, setDeploymentPopup] = useState(false)
    const [OTAInstallationPopup, setOTAInstallationPopup] = useState(false)
    const [deploymentFailedPopup, setDeploymentFailedPopup] = useState(false)
    const [list, setList] = useState({ total: 0, data: [] });
    const buildNumberDefault = {kind:"BUILD_NUMBER", fileName:"", certificate:"1.0", password:""}
    const containerAppDefault = {
        containerApp: {name:"", appType:"hybrid", caType:"enterprise", deviceType:""}, 
        containerAppCertificates: [buildNumberDefault,], 
        containerAppAccess: [],
        containerAppTables: [],
        containerAppBinaries: [],
        genarate: false,
    }
    const [loginAppList,setLoginAppList] = useState([])
    const [containerAppDetails, setContainerAppDetails] = useState(containerAppDefault)
    const [CAGFailedMsg, setCAGFailedMsg] = useState("failed")
    const [otaUrl, setOtaUrl] = useState("")
    // const menus = ["Edit", "Download .apk", "Download .ipa", "OTA" ];
    const webMenu = ["Copy URL"]
    const draftMenu = perm?perm.canUpdate?["Edit"]:[]:["Edit"]
    const androidMenu = ["Download .apk"]
    const iosMenu = ["Download .ipa"]
    const otaMenu = ["OTA"]

    const [filter, setFilter] = useState({});
    const { id: projectName } = useParams();
    const [timeOutArr, setTimeOutArr] = useState("")
    const app = useAppContext()

    const resetContainerAppDetails = () => {
        setContainerAppDetails(containerAppDefault)
    }

    const getList = async () => {
        const prjAction = ProjectSettingsActions(app())
        const [err, data] = await prjAction.getList(projectName||'services_workspace', pagination.limit, pagination.offset, filter)
        if (!err) {
            formatData(data)
        }
    }

    const separateDeviceType = (deviceType) => {
        return (
            <div style={{display:"flex", flexWrap:"wrap"}} data={deviceType}>
            {deviceType.split(",").map((e) => (<div className={styles.deviceType}>{e}</div>))}
            </div>
        )
    }

    const formatData = (data) => {
        let json = { 'total': data.noOfRecords }
        let array = []
        Object.values(data.data).forEach((object) => {
            let obj = { ...object, statusIcon: object.status=="Draft"?perm?perm.canUpdate?<EditIcon />:<></>:<EditIcon />:object.status=="Finished"?<FinishedIcon/>:object.status=="Failed"?<FailedIcon/>:<div className={styles.spinner}></div> ,
                                    deviceType: separateDeviceType(object.deviceType), 
                                    style:{appType:{textTransform:"capitalize"},
                                            caType:{textTransform:"capitalize"} }}
            let bldNmbr = obj.buildNumber && obj.version ? obj.version + '/' + obj.buildNumber : obj.version ? obj.version : obj.buildNumber ? obj.buildNumber : "";
            obj.versionAndBuild = bldNmbr
            array.push(obj)
        })
        json.data = array
        setList(json)
        if(array.find((e) => e.status == "Pending")) {
            clearTimeout(timeOutArr)
            let timerId = setTimeout( () => getList(), 1000*30)
            setTimeOutArr(timerId)
            // timeOutArr.forEach((e)=>clearTimeout(e))
            // setTimeOutArr(()=>[])
            // setTimeOutArr(prev => [...prev, setTimeout( () => getList(), 1000*30)])
        }
        else {
            clearTimeout(timeOutArr)
            // timeOutArr.forEach((e)=>clearTimeout(e))
            // setTimeOutArr(()=>[])
        }

    }

    const getPublishedLoginAppsList = async () => {
        const prjAction = ProjectSettingsActions(app())
        const [err, data] = await prjAction.getPublishedLoginAppsList();
        if(!err) {
            setLoginAppList(data.data)
        }
    }

    const getContainerAppDetails = async (containerAppId) => {
        const prjAction = ProjectSettingsActions(app())
        const [err, data] = await prjAction.getContainerAppDetails(projectName||'services_workspace', containerAppId);
        if(!err)    return data.detail
    }

    const handleDeploymentPopup = () => {
        setDeploymentPopup(true)
        clearTimeout(timeOutArr)
        // timeOutArr.forEach((e)=>clearTimeout(e))
    }

    useEffect(()=>{
        if(props.openCAG !== undefined && props.openCAG){
            setDeploymentPopup(true)
            clearTimeout(timeOutArr)
            // timeOutArr.forEach((e)=>clearTimeout(e))
        }
        
    },[props.openCAG])

    const handleRolePopup = (e) => {
        setDeploymentPopup(e)
        if(props.setOpenCAG) props.setOpenCAG(e) 
    }
    
    const handleImagePopup = async (containerAppId) => {
        let data = await getContainerAppDetails(containerAppId)
        let containerAppStatus = data.containerApp.status
        let deviceType = data.containerApp.deviceType
        if (containerAppStatus == "Draft") {
            setContainerAppDetails({...data, genarate:false})
            setDeploymentPopup(true)
            clearTimeout(timeOutArr)
            // timeOutArr.forEach((e)=>clearTimeout(e))
        } else if (containerAppStatus == "Failed") {
            setCAGFailedMsg(data.containerApp.message)
            setDeploymentFailedPopup(true)
        } else if (containerAppStatus == "Finished"){
            if(deviceType==="Web") {
                navigator.clipboard.writeText(data.containerApp.otaUrl)
                const {notify} = app()
                notify({message:"Web URL Copied to Clipboard", type:"S"})
            }
            else {
                setOtaUrl(data.containerApp.otaUrl)
                setOTAInstallationPopup(true)
    }

        } else {
            // console.log("show pending popup")
        }

    }

    const onHeaderChange = (id, value) => {

        let keys = {
            [headers[0].id]: "c.name",
            [headers[1].id]: "c.app_type",
            [headers[2].id]: "c.device_type",
            [headers[3].id]: "c.ca_type",
            [headers[4].id]: headers[4].id,
            [headers[5].id]: headers[5].id,
            [headers[6].id]: "c.status"
        }

        setFilter((prev) => ({ ...prev, [keys[id]]: value }))

    }

    const downloadAppsBinary = async (fileType, containerAppId) => {
        let deviceType = fileType===".apk"?"android":"ios"
        const prjAction = ProjectSettingsActions(app())
        const [err, data] = await prjAction.getApps(deviceType, containerAppId)
        if(!err) {
            downloadFileFromUrl(data.url, `app-${deviceType}.zip`)
        }
    }

    const menuOnClick = async (option, value) => {
        if ( option === "Edit" ) {               //menus[0] = Edit
            let data = await getContainerAppDetails(value.id)
            setContainerAppDetails({...data, genarate:false})
            setDeploymentPopup(true)
            // timeOutArr.forEach((e)=>clearTimeout(e))
            clearTimeout(timeOutArr)
        }
        else if( option === "OTA" ) {
            setOtaUrl(value.otaUrl)
            setOTAInstallationPopup(true)
            // timeOutArr.forEach((e)=>clearTimeout(e))
            clearTimeout(timeOutArr)
        }
        else if( option === "Download .apk" ) {
            downloadAppsBinary(".apk", value.id)
        }
        else if( option === "Download .ipa" ) {
            downloadAppsBinary(".ipa", value.id)
        }
        else if( option === "Copy URL") {
            navigator.clipboard.writeText(value.otaUrl)
            const {notify} = app()
            notify({message:"Web URL Copied to Clipboard", type:"S"})
        }
    }

    const headers = [
        { name: "Name", id: "name", type: "input", width: "200px" },
        { name: "Type", id: "appType", type: "input", width: "100px" },
        { name: "Platform", id: "deviceType", type: "select", value: ["Ios", "Android", "Windows"], width: "150px" },
        { name: "Distribution", id: "caType", type: "select", value: ["Enterprise", "Appstore"], width: "100px" },
        { name: "Version/Build", id: "versionAndBuild", type: "input", value: "", width: "80px", disabled: true },
        { name: "Last Updated On", id: "updateTime", type: "input", value: "", width: "150px", disabled: true },
        { name: "Status", id: "status", type: "select", value: ["Finished", "Draft", "Failed", "Pending"], width: "100px" },
        { name: "", id: "statusIcon", type: "statusIcon", width: "20px", handleImagePopup: handleImagePopup, style:{width: "20px"} },
        { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon", width: "20px", style:{paddingRight:"8px"} },
    ];

    const getMenu = (data) => {
        const getDraftMenu = () => {
            return data.status == "Pending"?[]:draftMenu
        }
        if (data.status=="Draft"&&data?.otaUrl=="undefined") return draftMenu
        else{
            switch (data.deviceType.props.data) {
                case "Web":
                    return [...getDraftMenu(), ...webMenu]
                case "Android":
                    return [...getDraftMenu(), ...androidMenu, ...otaMenu]
                case "iOS":
                    return [...getDraftMenu(), ...iosMenu, ...otaMenu]
                default:
                    return [...getDraftMenu(), ...iosMenu, ...androidMenu, ...otaMenu]
            }
        }
    }

    useEffect(() => {
        getList()
    }, [pagination, filter]);

    useEffect( () => {
        getPublishedLoginAppsList()
        return ()=> {
            clearTimeout(timeOutArr)
            // timeOutArr.forEach((e)=>clearTimeout(e))
            }
    }, [])

    const caProjectTable = () => (
        <div className={styles.tableWrapper}>
        <div className={styles.subWrapper}>
        <Table
            name="containerAppsTable"
            modifyPopUp={getMenu}
            data={list.data || []}
            headerColumns={headers}
            contextOnChange={menuOnClick}
            handleHeaderInputChange={onHeaderChange}
            handleHeaderSelectChange={onHeaderChange}
        />
        <Pagination
            displayRows={pagination}
            userDataCount={list.total || []}
            setDisplayRows={setPagination}
        />
        </div>
    </div>
    )
    const caLibraryTable = () => (
        <>
        <div className={styles.caTableContainer}>
        <Table
            name="containerAppsTable"
            modifyPopUp={getMenu}
            data={list.data || []}
            headerColumns={headers}
            contextOnChange={menuOnClick}
            handleHeaderInputChange={onHeaderChange}
            handleHeaderSelectChange={onHeaderChange}
        />
        </div>
        <Pagination
            displayRows={pagination}
            userDataCount={list.total || []}
            setDisplayRows={setPagination}
        />
        </>
    )

    return (
        <div className={styles.mainWrapper}>

            {/* <ModuleTitle title="Deployment" subTitle="Variables defined here are globally available in all projects. Changes affect apps immediately.">
                <button
                    className='primary'
                    style={{ width: "auto" }}
                    onClick={() => handleDeploymentPopup()}
                >
                    + New
                </button>
            </ModuleTitle> */}
            {props.fromScreen!=="Library" && (
                <div className={styles.tableHeader}>
                    <h1
                        data-text-testid={"title"}
                        className={styles.afModuleTitle}
                    >
                        Deployment
                    </h1>
                    <button
                        className='primary'
                        style={{ width: "auto", fontSize:"14px", fontWeight:"500" }}
                        onClick={() => handleDeploymentPopup()}
                        data-clickable-testid="new"
                    >
                        {/* <span><PlusIcon style={{}}/></span> */}
                        + New
                    </button>
                </div>
            )}
            {props.fromScreen!=="Library"?(caProjectTable()):(caLibraryTable())}

            {deploymentPopup &&
                <DeploymentPopup rolePopUp={deploymentPopup} fromScreen={props.fromScreen} setRolePopUp={handleRolePopup} projectName={projectName||'services_workspace'} setContainerAppDetails={setContainerAppDetails} updateCAList={getList} otaUrl={otaUrl} containerAppDetails={containerAppDetails} loginAppList={loginAppList} resetCADetails={resetContainerAppDetails}/>}
            {OTAInstallationPopup &&
                <OTAInstallation rolePopUp={OTAInstallationPopup} handleClose={()=>setOTAInstallationPopup(false)} otaUrl={otaUrl} containerAppDetails={containerAppDetails} resetCADetails={resetContainerAppDetails}/>}
            {deploymentFailedPopup &&
                <SmallPopup title={"Failed"} subtitle={CAGFailedMsg} showButton={false} popUp={deploymentFailedPopup} handlePopUp={()=>setDeploymentFailedPopup(false)}/>}
        </div>
    )
}

export default ProjectDepTable