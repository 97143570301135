const projectMessages = {
    //settings messages
    E8001: "Please enter the Project Name",
    E8002: "Please enter the Description",

    //configuration messages
    E8010: "Please enter application name",
    E8010a: "Please enter a valid application name",
    E8011: "Please select atleast one device",

    //applicationDetails messages
    E8020: "Field is empty",
    E8021: "Please enter in this format. i.e: 'com.companyname.appname'",
    E8022: "starts with 1.0 and above",
    E8023: "starts with 1.0 and above",
    E8026: "Please upload app icon",
    E8027: "Size should be less than 1mb",
    E8028: "invalid Resolution",
    E8029: "invalid format",
    E8030: "Please select a login Application",

    //androidConfiguration messages
    E8090: "Please upload the file",
    E8090a: "Invalid File",
    E8091: "Please enter the Password",
    E8092: "Invalid Resolution",
    E8093: "Invalid format",
    E8094: "Size should be less than 1mb"


} 

export default projectMessages