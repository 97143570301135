import styles from "./smallPopup.module.css";
import React from "react";
import { ReactComponent as ErrorIcon } from "../../images/errorIcon.svg";
import { ReactComponent as WarningIcon } from "../../images/warningIcon.svg";

const SmallPopup = (props) => {
  const { popUp, testId, title, subtitle, handlePopUp, handleSubmit, warning, showButton=true, icon, handleDelete, id, close,
    buttonName1, buttonName2, buttonTestId1, buttonTestId2, style={} } = props;
    
  return (
    <>
      {popUp ? (
        <div className={styles.mainWrapper} data-context-testid={testId ? testId:title} style={{ color: "black", ...style }}>
          <div className={styles.wrapper}>
            <div
              data-clickable-testid={"popup-close"}
              className={styles.popupCloser}
              onClick={handlePopUp}
            >
              <span className={styles.close}>X</span>
            </div>
            <div className={styles.textAlign}>
              <span className={styles.header} data-text-testid={icon=="saveChanges"?"title":"deleteTitle"}>

                {icon=="warning"?<WarningIcon />:icon=="saveChanges"?<WarningIcon />:<ErrorIcon />}

                {title}
              </span>
              <span
                className={styles.content}
                data-text-testid={icon=="saveChanges"?"subtitle":"deleteSubtitle"}
              >
                {subtitle}
              </span>
            </div>
            {showButton?<div className={styles.buttonContainer}>
              {close ? <button
                className={`secondary ${styles.secondaryButton}`}
                onClick={handlePopUp}
                data-clickable-testid="close"
              >
                Close
              </button>
                :
                warning ? <>
                  <button
                    className={`secondary ${styles.secondaryButton}`}
                    onClick={handlePopUp}
                    data-clickable-testid={buttonTestId1 ? buttonTestId1 : "deleteNo"}
                  >
                    No
                  </button>
                  <button
                    className={`primary ${styles.primaryButton}`}
                    onClick={() => handleDelete(id)}
                    data-clickable-testid={buttonTestId2 ? buttonTestId2 : "deleteYes"}
                  >
                    Yes
                  </button>
                </> :
                  <>
                    <button
                      className={`secondary ${styles.secondaryButton}`}
                      onClick={handlePopUp}
                      data-clickable-testid={buttonTestId1 ? buttonTestId1 : "deleteNo"}
                    >
                      {buttonName1 ? buttonName1 : 'Cancel'}
                    </button>
                    <button
                      className={`primary ${styles.primaryButton}`}
                      onClick={handleSubmit}
                      data-clickable-testid={buttonTestId2 ? buttonTestId2 : "deleteYes"}
                    >
                      {buttonName2 ? buttonName2 : 'Delete'}
                    </button>
                  </>
              }

            </div>:""}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SmallPopup;
