import React, { useState } from 'react'
import styles from './androidConfiguration.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import { validateValue, validateValues, validateImage } from './validations';
import { readFileAsyncImage } from '../../../../../../common';
import { arrayBufferToBase64 } from '../../../../../../common/utils/utils';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function AndroidConfiguration ({ projectName, containerAppId, pageNumber, handleClose, rolePopUp, containerAppDetails, setContainerAppDetails, resetCADetails}) {
    
    const initialData = {
        keyStore: "",
        gcmc: "",
        apiKey: "",
        keyPassword: "",
        phone: "",
        tablet: "",
    }

    const mapInitialData = (initialData) => {
        if( containerAppDetails.containerAppCertificates != undefined){
            let dataObj = initialData
            let activeFileObj = {
                keyStore: "", 
                phone:"", 
                tablet:""
            }
            containerAppDetails.containerAppCertificates.map( e => {
                if(e.kind == "K") {
                    dataObj.keyStore = e
                    dataObj.keyPassword = e.password
                    activeFileObj.keyStore = e.certificate
                }
                else if (e.kind == "GCM") 
                    dataObj.gcmc = e.certificate
                else if (e.kind == "API") 
                    dataObj.apiKey = e.certificate
                else if (e.kind == "SS") {
                    dataObj.phone = e
                    if(e?.uuid) activeFileObj.phone = e.uuid
                }
                else if (e.kind == "SSTAB") {
                    dataObj.tablet = e
                    if(e?.uuid) activeFileObj.tablet = e.uuid
                }
            })
            return [dataObj,activeFileObj]
        }
    }
    const [dataObj, activeFileObj] = mapInitialData(initialData)
    const [data, setData] = useState(dataObj)
    const [androidData, setAndroidData] = useState(data)
    const [activeFile, setActiveFile] = useState(activeFileObj)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)

    const kind = {
        keyStore: "K",
        gcmc: "GCM",
        apiKey: "API",
        keyPassword: "K",
        phone: "SS",
        tablet: "SSTAB"
    }

    const app = useAppContext()
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false) 

    const updateContainerAppDetails = async (newValues, errors) => {
        // console.log("newValues", newValues)
        if(clickedBack || saveChangesPopup) { /* write the draft changes condition */
            const prjAction = ProjectSettingsActions(app());
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
            if(!err){
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...data2.detail, genarate:false})
                    }

                if(data.status.type!=="E") {
                    if(!saveChangesPopup) {
                        if (data2.detail.containerApp.deviceType.includes("iOS")) pageNumber(5)
                        else pageNumber(4)
                    }else closePopup(false)
                }
            }
        }
        else {
            const prjAction = ProjectSettingsActions(app());
            pageNumber(7)
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues, genarate:true})
            if(!err) {
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...containerAppDetails,...data2.detail})
                    }

                if(data.status.type!=="E") {
                    // console.log("containerAppDetails", containerAppDetails)
                    // pageNumber(8)
                    closePopup(false)
                }
            }
            else {
                // console.log("error", err)
                // pageNumber(8)
                closePopup(false)
            }

        }

    }

    const onSubmit = async (values, errors) => {
        let fields = getValidationFields();
        let result = await validateValues(values, errors, fields, fileDefaultProps)
        if(!result) {
            let updateKeyStore = {...androidData.keyStore, certificate:activeFile.keyStore, fileName:activeFile.keyStore.substring(activeFile.keyStore.lastIndexOf("/")+1),password:values.keyPassword, kind:kind.keyStore}
            let updatePhoneSS = {...androidData.phone, certificate:androidData.phone.certificate, fileName:androidData.phone["certificate"].substring(androidData.phone["certificate"].lastIndexOf("/")+1), password:"", kind:kind.phone }
            let updateTabletSS = {...androidData.tablet, certificate:androidData.tablet.certificate, fileName:androidData.tablet["certificate"].substring(androidData.tablet["certificate"].lastIndexOf("/")+1), password:"", kind:kind.tablet }
            let updateGcmc = {certificate:values.gcmc, fileName: values.gcmc, kind: kind.gcmc,password:""}
            let updateApiKey = {certificate:values.apiKey, fileName:values.apiKey, kind: kind.apiKey, password:""}
            let newValues = {...containerAppDetails,
                                containerAppCertificates:[
                                    ...containerAppDetails["containerAppCertificates"].filter(e => !Object.values(kind).includes(e.kind)),
                                    updateKeyStore,
                                    updatePhoneSS,
                                    updateTabletSS,
                                ]}
            if(updateGcmc.fileName) newValues = {...newValues, containerAppCertificates:[...newValues["containerAppCertificates"], updateGcmc]}
            if(updateApiKey.fileName) newValues = {...newValues, containerAppCertificates:[...newValues["containerAppCertificates"], updateApiKey]}
            updateContainerAppDetails(newValues, errors)
        }
        else {
            if (clickedBack) setClickedBack(false)
        }
        // pageNumber(7)
    }

    const UploadFile = ({title, name, displaySelect=true, width="100%", acceptType="", bottomText=""}) => {

        return (
            <div style={{width}}>
            <Label text={title}/>
            {displaySelect
            ?(
                <>
                <DragAndDropArea style={styles.iconDiv} name={name}>
                    <div className={styles.iconDisplayDiv}>
                        <div className={styles.iconImgCont} data-text-testid={name+"_file"}>
                            {!activeFile[name]?<div className={styles.iconImgFillCont}></div>
                            :activeFile[name].lastIndexOf("/")!==-1?activeFile[name].substring(activeFile[name].lastIndexOf("/")+1):activeFile[name]}
                        </div>
                        <Label className={styles.resolutionText} text={""} />
                    </div>
                    <div className={styles.iconUploadDiv}>
                        <Label text={"Drop a file here"}/>
                        <Label text={"or"}/>
                        <File type="file" id={name} name={name} accept={acceptType} style={{display:"none"}}/>
                        <Label className={"primary"} data-clickable-testid={"select-a-file"} style={{height: "calc(var(--spacer) * 4px)"}} text={"Select a file"} htmlFor={name}/> 
                        <Label className={styles.uploadFileText} text={bottomText} />
                    </div>
                </DragAndDropArea>
                <Error name={name}/>
                </>)
            :(
                <>
                <DragAndDropArea style={styles.uploadSplashScreenCont} name={name}>
                    <File type="file" name={name} id={name} accept={acceptType} style={{display:"none"}}/>
                    <div style={{width:"100%"}}>
                    <Label className={activeFile[name]?styles.hide:styles.uploadFileText} text={<div className={styles.bottomText}>{bottomText}</div>} htmlFor={name}/>
                        {activeFile[name]&&<div className={styles.imgCont}><img data-image-testid={name} src={activeFile[name]} style={{maxHeight:"68px"}}/></div>}
                    </div>
                </DragAndDropArea>
                <Error name={name}/>
                </>
            )
            }
            </div>
        )
    }

    const fileDefaultProps = {
        phone: {size:1000000, acceptType:"image/*", width:1242, height:2208},
        tablet: {size:1000000, acceptType:"image/*", width:1536, height:2048},
        keyStore: {acceptType:".keystore"}
    }

    const onWatch = async (errors, values) => {
        setHadEdit(1)
        // validateValue(errors, values.name, values.value)
        let name = values.name;
        let value = values.value;
        if(values.name == "phone" || values.name == "tablet" || values.name == "keyStore") {
            let fileDefaults = fileDefaultProps[name];
            let result;
            if(values.name !== "keyStore") {
                result = await validateImage(errors, name, value, fileDefaults.size, fileDefaults.acceptType, fileDefaults.width, fileDefaults.height )
            }
            else {
                result = validateValue(errors, values.name, values.value, fileDefaults.acceptType)
            }
            // console.log("outsideResult", result)
            if(result) {
                let imageArrayBuffer = await readFileAsyncImage(values.value);
                setActiveFile( (prev) => ({...prev, [name]:name=="keyStore"?value.name:`data:${values.value.type};base64,`+arrayBufferToBase64(imageArrayBuffer)}))
                await handleFileupload(errors,name, "android_"+values.value.name,kind[name], imageArrayBuffer)
            }
            else {
                setActiveFile( (prev) => ({...prev, [name]:name=="keyStore"?value.name:""}))
            }
        }else {
            validateValue(errors, values.name, values.value)
            setAndroidData((prev) => ({...prev, [name]:value}))
        }
    }

    const handleFileupload = async (errors, name, fileName, comment, imageArrayBuffer) => {
        if(imageArrayBuffer) {
            const actions = ProjectSettingsActions(app())
            const [err, result] = await actions.uploadFile(projectName, containerAppId, comment, fileName, imageArrayBuffer)
            if (!err) {
                // setUploadFileUrl({name:result.url})
                // console.log("beforecheck",androidData, {...androidData, [name]:{...androidData[name],certificate:result.url}})
                if(name=="keyStore") setActiveFile( (prev) => ({...prev, keyStore:result.url}))
                setAndroidData((prev) => ({...prev, [name]:{...androidData[name],certificate:result.url}}))
                // setData((prev) => ({...prev, [name]:{...data[name],certificate:result.url}}))
            }
        }
    }

    const getValidationFields = () => {
        return [...Object.keys(androidData)];
    }
    // console.log(hadEdit)
    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout( () => {
            if(hadEdit){
                submitHandler(e)
            }
            else {
                pageNumber(containerAppDetails.containerApp.deviceType.includes("iOS")?5:4)
            }
        },0)
    }

    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const { Form, Label, Input, Error, File, DragAndDropArea, submitHandler } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"androidConfiguration"}
            title="Let's capture all the Google related details"
            subtitle="Please upload all the below information as required by Google for Android Apps"        
        >
            <Form>
                <div className={styles.formContent}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.contents}>
                            <div className={styles.individualContent}>
                                <UploadFile title={"Keystore"} name={"keyStore"} bottomText={".keystore only"} acceptType={".keystore"}/>
                            </div>
                            {androidData.keyStore?(<div className={styles.individualContent}>
                                <Label text={"Keystore Password"}/>
                                <Input style={{width:"100%"}} type="password" name={"keyPassword"} placeholder={"password"}/>
                                <Error name="keyPassword"/>
                            </div>):""}
                            <div className={styles.individualContent}>
                                <Label text={"Google Cloud Messaging Code (Optional)"}/>
                                <Input style={{width:"100%"}} type="text" name={"gcmc"} placeholder={"GCM code"}/>
                                <Error name="gcmc" /> 
                            </div>
                            <div className={styles.individualContent}>
                                <Label text={"API Key (Optional)"}/>
                                <Input style={{width:"100%"}} type="text" name={"apiKey"} placeholder={"API key"}/>
                                <Error name="apiKey" />
                            </div>
                        </div>
                        <div className={styles.contents}>
                            <h4
                                className={styles.afModuleSubTitleVaried}
                            >
                                Splash Screens
                            </h4>
                            <div className={styles.individualContent}>
                                <UploadFile title={"Android Phone"} bottomText={"(1242px x 2208px)"} name={"phone"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                                <UploadFile title={"Android Tablet"} bottomText={"(1536px x 2048px)"} name={"tablet"} acceptType={"image/*"} displaySelect={false} width={{width:"100%"}}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='afPopupFooter'>
                    <div>
                        <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                        <Input type="submit" value={"Generate"} style={{width:"fit-content",padding:"calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px)"}} />
                    </div>
                </div>
            </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default AndroidConfiguration