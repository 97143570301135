import React from "react";
import { connect } from "react-redux";
// import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import DashboardPage from "../dashboard/";
import AllProjects from "../all-projects";
import ProcessDesigner from "../process-designer/";
//import DatabaseDesigner from "../database-designer/";
import ProjectDetail from "../project-detail/";
import AppDesigner from "../app-designer";
import BusinessFunction from "../business-function";
import UserTask from "../user-task";
// import TimerEvents from "../timer-events";
import Modernizer from "../modernizer";
import Forbidden from "../forbidden";
import SummaryAnalytics from "../analytics/summary";
import AppAnalytics from "../analytics/app";
import PlatformAnalytics from "../analytics/platform";
import ConflictResolution from "../analytics/conflict-resolution";
import Collaborator from "../collaborator";
import Organisation from "../settings/organisation";
import UserRole from "../settings/roles";
import Variables from "../settings/variables";
import TenantPersonalization from "../settings/tenant-personalization";
import SessionPage from "../settings/session-settings";
import ConnectivityPage from "../settings/connectivity";
import MailConfigPage from "../settings/mail";
import ConnectionManagerPage from "../settings/connection-manager";
import LogsPage from "../settings/logs";
import TenantAdmin from "../settings/tenant-admin";
import PackageSubscription from "../settings/subscrption-packages";
import LibrarySchedulers from "../library/schedulers";
import LibraryDrive from "../library/drive";
import LibraryThemes from "../library/themes";
import LibraryAssets from "../library/assets";
import LibraryLogins from "../library/logins";
import LibraryDatabase from "../library/database";
import LibraryServices from "../library/services";
import LibraryVariables from "../library/variables";
import LibraryContainerApps from "../library/container-apps"
import LibraryLogs from "../library/logs";
import ProjectSettings from "../project-settings";
import IntegrationBuilder from "../integration-builder/index";
import AppPreviewComponent from "../app-preview";
import Header from "../../common/components/Header";
import NotificationAlert from "../../common/components/NotificationAlert/index";
import SignIn from "../sign-in/index";
import TaskManagement from "../task-management";
import DrivePage from "../drive";
import UserProfile from "../user-profile/";
import ChangePassword from "../change-password";
import ProtectedRoute from "./protected-route";
import AllItems from "../all-items";
import UniversalWorklist from "../workflow-console/universal-worklist";
import WorkflowVariables from "../workflow-console/variables";
import ConflictReport from "../workflow-console/conflict-report";

import CreateProjectDetail from "../create-project-detail";
import PageNotFound from "../pagenotfound";
import InboxPreview from "../business-function/components/inbox-preview";
// import userTaskDetails from '../user-task-details';
// import eventBus from '../../utils/eventBus';

import {
  openProject,
  unselectProject,
  closeProject,
  closeNotification,
  currentAdminTab,
  fetchUserPermissions,
  closeAllProjects,
  alertShow,
} from "../../helpers/app-root";
import { fetchProfile } from "../../helpers/user-profile";
import { saveProjectPathUrl } from "../../helpers/project-detail";
import { handleLogout } from "../../helpers/sign-in";
import {
  executeLogout,
  isNoPageHasPermissions,
  checkIfCurrentPageHasPermission,
  getLoggedInEnv
} from '../../utils/common';
import { getCookie } from '../../utils/cookieServices';
import { AppProvider } from "../../minified/components/activity/AppContext";
import { AuthProvider } from "../../minified/auth/auth";
import Administration from "../../minified/pages/routes/administration";
import Library from "../../minified/pages/routes/library";
import Database from "../../minified/pages/database/database.v6";
import HelpPanel from "../help-panel"
import SignOut from "../sign-out";

class ApplicationRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logout = this.logout.bind(this);
    this.handleOpenProject = this.handleOpenProject.bind(this);
    // fetchUserPermissions();
  }

  componentDidMount() {
    if(!!getCookie('accessToken') && getLoggedInEnv()){
      fetchUserPermissions();
      fetchProfile();
    }
    this._loadWindowObjects();
  }

  _loadWindowObjects = () => {
    window.alertShow = alertShow;
  };

  handleOpenProject = (project) => {
    openProject(project);
  };

  handleUnselectProject = () => {
    unselectProject();
  };

  closeProject = (project) => {
    closeProject(project);
  };

  async logout() {
    await handleLogout();
    executeLogout();
    // closeAllProjects();
  }

  renderRoute(props) {
    const authUser = getCookie('accessToken') && getLoggedInEnv() &&
      !JSON.parse(getCookie('tempPassword'));
    if (authUser) {
      return <Redirect to="/home" />;
    } else if(!this.props.isSessionExpired) {
      return <SignIn {...props} />;
    } else if(this.props.isSessionExpired){
      return <SignOut />
    }
  }

  componentDidUpdate(prevProps) {
    const { permissionStatus, history, isSessionExpired } = this.props;

    if (isSessionExpired && !prevProps.isSessionExpired) {
      //redirect to logout
      history.push('/signout'); // Redirect to logout page
    }

    if (permissionStatus === "PERMISSIONS_SUCCESS") {
      // checkIfCurrentPageHasPermission();
      if (
        isNoPageHasPermissions() &&
        !["/ChangePassword", "/UserProfile", "/", "/home", "/signout"].includes(
          window.location.pathname
        )
      )
        window.location.href = "/UserProfile";
      else checkIfCurrentPageHasPermission();
    }
  }

  render() {
    return (
      <>
      <AppProvider>
      <AuthProvider>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => this.renderRoute(props)}
              />
              <Route
                exact
                path="/signout"
                component={SignOut}
              />
              <Route path="/Collaborator" component={Collaborator} />
              <ProtectedRoute
                exact
                path="/Preview/:Project/:BusinessFunction/:Lane"
                component={AppPreviewComponent}
              />

                <ProtectedRoute
                exact
                path="/Preview/:Project/:BusinessFunction/:Lane/:toConsole"
                component={AppPreviewComponent}
              />

              {/* <ProtectedRoute path="/Preview" component={AppPreviewComponent} /> */}

              <ProtectedRoute
                exact
                path="/ChangePassword"
                component={ChangePassword}
              />
              <div className="tab-container">
                <div className="header">
                  <Header
                    userPermissions={this.props.userPermissions}
                    openedProjects={this.props.openedProjects}
                    closeProject={this.closeProject}
                    selectedProject={this.props.selectedProject}
                    handleUnselectProject={this.handleUnselectProject}
                    handleOpenProject={this.handleOpenProject}
                    saveProjectPathUrl={saveProjectPathUrl}
                    profile={this.props.profile}
                    currentAdminTab={currentAdminTab}
                    logout={this.logout}
                    {...this.props}
                  />
                </div>
                <Switch>
                  <ProtectedRoute
                    exact
                    path="/home"
                    component={DashboardPage}
                  />
                  <ProtectedRoute path={"/Administration"} component={Administration} />
                  <ProtectedRoute path={"/Library"} component={Library} />
                  <ProtectedRoute
                    exact
                    path="/Projects"
                    component={AllProjects}
                  />
                  <ProtectedRoute
                    exact
                    path="/Console"
                    component={UniversalWorklist}
                  />
                  <ProtectedRoute
                    exact
                    path="/Console/Variables"
                    component={WorkflowVariables}
                  />
                  <ProtectedRoute exact path="/AllItems" component={AllItems} />

                  <ProtectedRoute
                    exact
                    path="/Project"
                    component={ProjectDetail}
                  />
                  <ProtectedRoute
                    exact
                    path="/ProcessDesigner"
                    component={ProcessDesigner}
                  />
                  <ProtectedRoute
                    exact
                    path="/BusinessFunction"
                    component={BusinessFunction}
                  />
                  <ProtectedRoute exact path="/UserTask" component={UserTask} />
                  {/* <ProtectedRoute exact path="/TimerEvents" component={TimerEvents} /> */}
                  <ProtectedRoute
                    exact
                    path="/TaskManagement"
                    component={TaskManagement}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/Settings"
                    component={ProjectSettings}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id"
                    component={ProjectDetail}
                  />
                  { <ProtectedRoute
                    exact
                    path="/Project/:id/Database"
                    component={Database}
                  /> }
                  <ProtectedRoute
                    exact
                    path="/Project/:id/import_logs"
                    component={Modernizer}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/TaskManagement"
                    component={TaskManagement}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessProcess/:ProcessId"
                    component={ProcessDesigner}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId"
                    key="/Project/:id/BusinessFunction/:ProcessId"
                    component={
                      window.sessionStorage.getItem("env") === "dev"
                        ? BusinessFunction
                        : CreateProjectDetail
                    }
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskName"
                    key="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskName"
                    component={
                      window.sessionStorage.getItem("env") === "dev"
                        ? BusinessFunction
                        : CreateProjectDetail
                    }
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/TimerEvents/:TimerName"
                    key="/Project/:id/BusinessFunction/:ProcessId/TimerEvents/:TimerName"
                    component={
                        BusinessFunction
                    }
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/App/:LaneName/InboxPreview"
                    key="/Project/:id/BusinessFunction/:ProcessId/App/:LaneName/InboxPreview"
                    component={InboxPreview}
                  />
                  {/* <ProtectedRoute exact path="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskId" component={UserTask} /> */}
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/App/:PageId"
                    key="/Project/:id/BusinessFunction/:ProcessId/App/:PageId"
                    component={AppDesigner}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskName/App/:PageId"
                    key="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskName/App/:PageId"
                    component={AppDesigner}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/UserTask/:TaskName/builder/:builderId"
                    component={IntegrationBuilder}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/TimerEvents/:TimerName/builder/:builderId"
                    component={IntegrationBuilder}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/BusinessFunction/:ProcessId/builder/:builderId"
                    component={IntegrationBuilder}
                  />
                  {/* <ProtectedRoute
                    exact
                    path="/Library/Services/:builderId"
                    component={IntegrationBuilder}
                  /> */}
                  <ProtectedRoute
                    exact
                    path="/test"
                    component={IntegrationBuilder}
                  />

                  {/* <ProtectedRoute exact path='/Project/:id/BusinessProcess/:ProcessId/BusinessFunction' component={userTaskDetails} /> */}
                  <ProtectedRoute
                    exact
                    path="/UserProfile"
                    component={UserProfile}
                  />
                  <ProtectedRoute
                    exact
                    path="/Project/:id/Drive"
                    component={DrivePage}
                  />
                  {/*<ProtectedRoute path={"/Project"} component={Projects} />*/}
                  <ProtectedRoute
                    exact
                    path="/CreateProjectDetail"
                    component={CreateProjectDetail}
                  />
                  {/* <ProtectedRoute
                    exact
                    path="/Library/Schedulers"
                    component={LibrarySchedulers}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Services"
                    component={LibraryServices}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Assets"
                    component={LibraryAssets}
                  />
                  {/* <ProtectedRoute 
                    exact
                    path="/Library/Logs"
                    component={LibraryLogs}
                  /> */}
                  <ProtectedRoute
                    exact
                    path="/Library/Logins"
                    component={LibraryLogins}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Database"
                    component={LibraryDatabase}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Drive"
                    component={LibraryDrive}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Themes"
                    component={LibraryThemes}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Variables"
                    component={LibraryVariables}
                  />
                  <ProtectedRoute
                    exact
                    path="/Library/Database/Project/Database"
                    component={Database}
                  /> 
                  <ProtectedRoute
                    exact
                    path="/Library/ContainerApps"
                    component={LibraryContainerApps}
                  />
                  <ProtectedRoute
                    exact
                    path="/Analytics/Summary"
                    component={SummaryAnalytics}
                  />
                  <ProtectedRoute
                    exact
                    path="/Analytics/App"
                    component={AppAnalytics}
                  />
                  <ProtectedRoute
                    exact
                    path="/Analytics/Platform"
                    component={PlatformAnalytics}
                  />
                  <ProtectedRoute
                    exact
                    path="/Analytics/ConflictResolution"
                    component={ConflictResolution}
                  />
                  <ProtectedRoute
                    exact
                    path="/Console/Conflicts"
                    component={ConflictReport}
                  />
                  <ProtectedRoute
                    exact
                    path="/forbidden"
                    component={Forbidden}
                  />
                  <ProtectedRoute component={PageNotFound} />
                </Switch>
              </div>
            </Switch>
          </ScrollToTop>
        </Router>
      </AuthProvider>
      </AppProvider>

        <NotificationAlert
          open={this.props.alertNotification.show}
          status={this.props.alertNotification.type}
          response={this.props.alertNotification.message}
          handleClose={() => closeNotification()}
        />
        <HelpPanel />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPermissions: state.application.userPermissions,
    permissionStatus: state.application.permissionStatus,
    openedProjects: state.application.openedProjects,
    selectedProject: state.application.selectedProject,
    alertNotification: state.application.notification,
    profile: state.profile.profile,
    proxyLoginStatus: state.admin.proxyLoginStatus,
    isSessionExpired: state.signOut.isSessionExpired
  };
};

export default connect( mapStateToProps)(withRouter(ApplicationRoot));
