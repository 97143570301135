import { validate, required, validateNumeric, validateFileSize, validateFileType, validateImageResolution  } from '../../../../../../common/validations/Validations';
import projectMessages from '../../../../../../common/validationMessages/project';

const   validateValue = (errors, name, value, targetType="") => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    switch(name){
        case "keyStore":
            if(value=="") {
                msg = projectMessages.E8090;
                isValid = required(errors,name,value,msg)
            }
            else {
                msg = projectMessages.E8090a;
                let temp={}
                if(!value?.type||value.type=="") {
                    let type = value?.name?value.name.split(".")[1]:value.fileName.split(".")[1]
                    temp.type = "." + type
                }
                isValid = validateFileType(errors, name, value?.type?value:temp, msg, targetType)
            }
            break;
        case "keyPassword":
                msg = projectMessages.E8091;
                isValid = required(errors, name, value, msg, )
            break;

        default:
            break;
    }
    return isValid;
}

const validateImage = async (errors, name, value, fileSize="", fileType="", imgWidth=0, imgHeight=0) => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    if(value=="") {
        msg = projectMessages.E8090;
        isValid = required(errors, name, value, msg)
    }
    else if (value?.type) {
    if(value.size>fileSize){
        msg = projectMessages.E8094;
        isValid = validateFileSize(errors, name, value, msg, fileSize)
    }
    else if(!value.type.includes(fileType.split("/")[0])){
        msg = projectMessages.E8093;
        isValid = validateFileType(errors, name, value, msg, fileType.split("/")[0])
    }else{
        // console.log("insideValidateImageResolution")
        msg = projectMessages.E8092;
        isValid = await validateImageResolution(errors, name, value, msg, imgWidth, imgHeight)
    }
    }
    return isValid
}

const validateValues = async (values, errors, fields, fileDefaultProps) => {
    let validate = [];
    await Promise.all(Object.keys(values).map(async (name) => {
        const value = values[name]
        if(fields.includes(name)){
            if(name=="phone"||name=="tablet"){
                validate.push(await validateImage(errors,name,value, fileDefaultProps[name].size, fileDefaultProps[name].acceptType, fileDefaultProps[name].width, fileDefaultProps[name].height))
            }
            else {
                validate.push(validateValue(errors,name,value))
            }
        }
    }))
    return validate.includes(false);
}

export {validateValue, validateImage, validateValues}
