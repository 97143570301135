import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntegrationBuilderLayout from './layout';
import * as helpers from '../../helpers/integrationbuilder.js';
import { Application } from './resources/resources/js/application';
import { Utils } from './resources/resources/js/services';
import { Messages } from './resources/resources/js/message';
import { testGenerate, loadIntegrationBuilderRightPanel } from '../../actions/integrationbuilder';
import {
    ChangeCommand
} from "./resources/resources/js/commands"
import {
    MappingColumnEvent
} from "./resources/resources/js/builderEvents"
import { fetchSapFunctions } from '../../helpers/integrationbuilder';
import {
    deleteNode,
    minimizeEvent,
    expandEvent,
    commentEvent,
    uncommentEvent,
    cutEvent,
    copyEvent,
    undoEvent,
    redoEvent,
    pasteEvent,
} from './resources/resources/js/builderEvents';
import {saveProjectPathUrl} from "../../helpers/project-detail";
import { getPermissions } from '../../utils/common';

let rightPanel = {};
let currentProjectName;
let initialLoad = true;

let system;
let leftFields = [];
let rightFields = [];
let matchedFields = {};
let newLeftFields = [];
let newRighttFields = [];
let dropDownValues = [];
let dropDownTableValues = [];
let allRightFieldValues = [];
let xmlConvert = require('xml-js');
class IntegrationBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredVairableData: [],
            showMatchFields: false,
            showAlertDialog: false,
            showMatchFieldsType2: false,
            showDropdown: false,
            anchorEl: null,
            dropDownSelectType: '',
            dropDownVariableType: '',
            dropdownPosition: {},
            dropDownSelectValue: '',
            rightPanelData: {},
            isScrolled: false,
            tableMappingType: '',
            tableNames: {},
            dropDownEvent: '',
            selectedNodeName: '',
            mapType: '',
            variables: [],
            selectedDropdownValues: '',
            dropdownBlockType: '',
            showContextMenu: false,
            conextMenuPosition: {},
            sapDropdownValues: [],
            conextMenuData: [
                'Minimize',
                'Expand',
                'Delete node',
                'Cut',
                'Copy',
                'Paste',
                'Comment',
                'Uncomment',
                'Undo',
                'Redo',
            ],
            currentContextMenuItem: '',
            showCustomContextMenu: false,
            customContextMenuData: '',
            customConextMenuPosition: {},
            showBlocksDropdown: false,
            blocksDropdownPosition: {},
            selectedBlockDropdownValue: '',
            blockDropdownType: '',
            isSearchData : false,
            loading: false,
            loadingAxis: {x: '47%', y: '47%'},
            showIfElseSettings: {flag: false, details: {}},
            isHeaderButtonEnabled : {
                undo: false,
                redo: false,
                full_screen: true,
                undoc: true,
                test: true
            }
        }
        this.finalMatchedFields = {}
    }

    /* navigate sub header */
    navigateTab = (path, url) => {
        this.props.history.push(`/Project/${this.props.match.params.id}/${url}`);
        this.props.history.push(path);
    }

    /* Table mapping */
    toggleMatchFields = (obj, type = '') => {
        matchedFields = {};
        if (obj) {
            leftFields = obj.toCol;
            rightFields = obj.setCol;
            this.setTable = obj.setTable;
            this.toTable = obj.toTable;
            this.node = obj.node;
            this.property = obj.mapping;
            this.mappedObj = obj.mappedObj;
            this.dataobj = obj;
            this.toType = obj.toType;
            this.mapped = obj.mapped;
        }

        this.leftTableName = this.toTable?.split('$')[this.toTable?.split('$')?.length - 1]
        this.rightTableName = this.setTable?.split('$')[this.setTable?.split('$')?.length - 1]
        this.setState({
            tableNames: {
                leftTable: this.leftTableName?.toString(),
                rightTable: this.rightTableName?.toString(),
            },
            mapType: this.toType
        })
        if (type === 'show') {
            leftFields = obj?.mappedObj?.toTable;
            rightFields = obj?.mappedObj?.setTable;
            this.tableMapping(leftFields, rightFields, 'show');
            this.setState({
                tableMappingType: type,
                showMatchFields: !this.state.showMatchFields,
            })
        }
        else if (type === 'con') {
            leftFields = obj?.toCol;
            rightFields = obj?.setCol;
            if (!obj.setCol || obj?.setCol?.length < 0) {
                rightFields = [];
            }
            this.setState({
                tableMappingType: type,
                // showMatchFieldsType2: !this.state.showMatchFieldsType2,
                showAlertDialog: true
            })
            this.tableMapping(leftFields, rightFields);
        }
        else if (type === 'map') {
            if (obj.toType === 'set') {
                leftFields = obj?.setCol;
                rightFields = obj?.toCol;
                this.setState({
                    tableNames: {
                        leftTable: this.rightTableName?.toString(),
                        rightTable: this.leftTableName?.toString(),
                    },
                })
                if (obj.mappedObj && obj.mappedObj.toTable?.length > 0 && obj.mappedObj.setTable?.length > 0) {
                    let setTableValue = obj.mappedObj.setTable.map(e => e.name);
                    // let toTableValue = obj.mappedObj.toTable.map(e => e.name);
                    let filteredLeftField = obj.setCol.filter(e => !setTableValue.includes(e.name));
                    // let filteredRightField = obj.toCol.filter(e => !toTableValue.includes(e.name));
                    let leftFieldsTemp  = [...obj?.mappedObj.setTable, ...filteredLeftField];
                    let rightFieldsTemp = [...obj?.mappedObj.toTable] //, ...filteredRightField];
                    // this.tableMapping(rightFields, leftFields, 'map');
                    this.tableMapping(leftFieldsTemp, rightFieldsTemp, 'map');
                } else {
                    // this.tableMapping(rightFields, leftFields, 'map');
                    let leftFieldsTemp = [...obj?.setCol];
                    let rightFieldsTemp = [];
                    this.tableMapping(leftFieldsTemp, rightFieldsTemp, 'map');
                }
                allRightFieldValues = obj?.toCol.map(e => { return e.name; });
            } else {
                leftFields = obj?.toCol;
                rightFields = obj?.setCol;
                if (obj.mappedObj && obj.mappedObj.toTable?.length > 0 && obj.mappedObj.setTable?.length > 0) {
                    // let setTableValue = obj?.mappedObj.setTable.map(e => e.name);
                    let toTableValue = obj?.mappedObj.toTable.map(e => e.name);
                    // let filteredRightField = obj.setCol.filter(e => !setTableValue.includes(e.name));
                    let filteredLeftField = obj.toCol.filter(e => !toTableValue.includes(e.name));
                    let leftFieldsTemp = [...obj?.mappedObj.toTable, ...filteredLeftField];
                    let rightFieldsTemp = [...obj?.mappedObj.setTable] //, ...filteredRightField];
                    this.tableMapping(leftFieldsTemp, rightFieldsTemp, 'map');
                } else {
                    let leftFieldsTemp = [...obj?.toCol];
                    let rightFieldsTemp = [];
                    this.tableMapping(leftFieldsTemp, rightFieldsTemp, 'map');
                }
                allRightFieldValues = obj?.setCol.map(e=> {return e.name; });
            }
            this.setState({
                tableMappingType: type,
                showMatchFields: !this.state.showMatchFields,
            })
        }
        else if (type === 'closealert') {
            this.setState({
                showAlertDialog: false,
                // showMatchFieldsType2: !this.state.showMatchFieldsType2,
            })
            // window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [this.dataobj]));
            // this.node[this.property] = this.dataobj;
            matchedFields.setTable = [];
            matchedFields.toTable = [];
            this.node[this.property] = matchedFields;
            let obj = {};
            obj.setTable = this.setTable;
            obj.toTable = this.toTable;
            obj.type = this.toType;
            obj.mapping = this.property;
            obj.mapped = this.mapped;
            MappingColumnEvent(obj, this.node);
        }
        else if (type === 'alertYes') {
            this.setState({
                showAlertDialog: false,
                // showMatchFieldsType2: !this.state.showMatchFieldsType2,
            })
            let fieldsMatch;
            if (leftFields?.length > 0) {
                fieldsMatch = this.copyTable1Values(leftFields, this.setTable, 'set');
            }
            else if (rightFields?.length > 0) {
                fieldsMatch = this.copyTable1Values(rightFields, this.toTable, 'to');
            }
            window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [fieldsMatch]));
            this.node[this.property] = fieldsMatch;
            let obj = {};
            obj.setTable = this.setTable;
            obj.toTable = this.toTable;
            obj.type = this.toType;
            obj.mapping = this.property;
            obj.mapped = this.mapped;
            MappingColumnEvent(obj, this.node);
        }
        else if (type === 'openalert') {
            this.setState({
                showAlertDialog: true
            })
        }
        else if (type === 'closedialog') {
            this.setState({
                showMatchFieldsType2: !this.state.showMatchFieldsType2,
                showAlertDialog: false,
                mapType: ''
            })
            // window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [null]));
            // this.node[this.property] = null;
        }
        else if (type === 'closetype1') {
            this.setState({
                showMatchFields: !this.state.showMatchFields,
                mapType: ''
            })
            rightFields = [];
            leftFields = [];
            matchedFields = {};
            newLeftFields = [];
            newRighttFields = [];
            allRightFieldValues = [];
            // this.node[this.property] = this.mappedObj;
            // this.node[this.mapped] = true;
            // window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [null]));
            // window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [null]));
            // this.node[this.property] = null;
        }
        else if (type === 'updatetype1') {
            this.setState({
                showMatchFields: !this.state.showMatchFields,
                mapType: ''
            })
            window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [this.finalMatchedFields]));
            this.node[this.property] = this.finalMatchedFields;
            this.node[this.mapped] = false;
            let obj = {};
            obj.setTable = this.setTable;
            obj.toTable = this.toTable;
            obj.type = this.toType;
            obj.mapping = this.property;
            obj.mapped = this.mapped;
            MappingColumnEvent(obj, this.node);
        }
        else if (type === 'updatetype2') {
            this.setState({
                showMatchFieldsType2: !this.state.showMatchFieldsType2,
                mapType: ''
            })
            window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [matchedFields]));
            this.node[this.property] = matchedFields;
            let obj = {};
            obj.setTable = this.setTable;
            obj.toTable = this.toTable;
            obj.type = this.toType;
            obj.mapping = this.property;
            obj.mapped = this.mapped;
            MappingColumnEvent(obj, this.node);
        }
        else {
            this.setState({
                showMatchFieldsType2: !this.state.showMatchFieldsType2,
                showAlertDialog: true
            })
            if (this.node && this.property) {
                window.application.addCommand(new ChangeCommand(this.node, [this.property], [this.node[this.property]], [matchedFields]));
                this.node[this.property] = matchedFields;
            }
            let obj = {};
            obj.setTable = this.setTable;
            obj.toTable = this.toTable;
            obj.type = this.toType;
            obj.mapping = this.property;
            obj.mapped = this.mapped;
            MappingColumnEvent(obj, this.node);
        }
    }

    /* map left and right table */
    tableMapping = (data1, data2, type = '') => {
        let leftSplitValues = [];
        let rightSplitValues = [];
        if (data1?.length > 0) {
            newLeftFields = [...data1];
            for (let item of data1) {
                const colValues = type === 'show' ? item instanceof Object ? item.name : item : item.name;
                leftSplitValues.push(colValues);
                newLeftFields = leftSplitValues;
            }
        }else {
            newLeftFields = [];
        }
        if (data2?.length > 0) {
            newRighttFields = [...data2];
            let rightItems = [...data2];
            // console.log(data2, " :: data2");
            for (let item of data2) {
                const colValues = type === 'show' ? item instanceof Object ? item.name : item : item.name;
                rightSplitValues.push(colValues);
                newRighttFields = rightSplitValues;
                // allRightFieldValues = [...rightSplitValues];
            }
        }else {
            newRighttFields = [];
        } 
        if (type === 'map') {
            if (newLeftFields.length !== newRighttFields.length) {
                if (newLeftFields.length > newRighttFields.length) {
                    newRighttFields = this.mapTable(newLeftFields, newRighttFields, 'left');
                    // allRightFieldValues.push(`Don't Match`);
                }
                else if (newLeftFields.length < newRighttFields.length) {
                    newRighttFields = this.mapTable(newRighttFields, newLeftFields, 'right')
                }
            }
            // if (this.toType?.toLowerCase() !== 'set') {
                // allRightFieldValues.push(`Don't Match`)
            // }
        }
    }

    mapTable = (data1, data2, type) => {
        let abc = [];
        for (const [index, item] of data1.entries()) {
            if (!data2[index]) {
                if (type === 'right') {
                    break;
                }
                else {
                    // abc.push(`Don't Match`);
                    abc.push(Messages.E006);
                }
            } else {
                if (type === 'left') {
                    abc.push(data2[index])
                }
                else if (type === 'right') {
                    abc.push(data1[index])
                }
            }
        }
        return abc;
    }

    multiplyArray = (arr, length) => {
        return Array.from({ length }, () => arr).flat()
    }

    /* reload the variable */
    refresh = () => {
        // let filteredVairableData = { ...filteredVairableData };
        let variableData = { ...rightPanel };
        const loadParam = window.application.ui.parameter;
        rightPanel = loadParam;
        this.setState({ rightPanelData: rightPanel });
        this.props.reloadVariableData(rightPanel);
        //window.application.save();
        // this.setState({ filteredVairableData: filteredVairableData });
    }

    addNewParameters = (parameters, variableType, childVariable, callback = () => null) => {
        helpers.updateParameters(parameters, variableType);
        callback();
    }

    updateVariableParameters = (parameters, variableType) => {
        helpers.updateParameters(parameters, variableType, true);
    }

    fetchVariableData = (name, variableType) => {
        const loadParam = window.application.ui.parameter;
        if (loadParam.hasOwnProperty(variableType)) {
            const filteredVairableData = Object.values(loadParam[variableType]).filter((item) => {
                const variableName = item.Xpath?.split('$')[item.Xpath?.split('$')?.length - 1]
                return (variableName === name);
            });
            this.setState({ filteredVairableData });
            return filteredVairableData;
        }
    }

    handleDeleteVariable = (data, variableType) => {
        window.application.ui.remove(data.name, variableType);
        const loadParam = window.application.ui.parameter;
        rightPanel = loadParam;
        this.setState({ rightPanelData: rightPanel });
        this.props.reloadVariableData(rightPanel);
        window.application.save();
    }

    componentDidMount() {
        const { permissionStatus } = this.props;
        currentProjectName = this.props.match.params.id;
        helpers.fetchIntegrationBuilderLeftPanel();
        helpers.fetchIntegrationBuilderRightPanel();
        helpers.fetchIntegrationBuilderBlocksDropdown();
        saveProjectPathUrl(this.props.match?.params?.id,this.props?.history?.location?.pathname);

        window["application"] = new Application();
        window.application.projectName = this.props.match.params?.id;
        window.application.businessFunctionName = this.props.match.params?.ProcessId;
        if( permissionStatus === 'PERMISSIONS_SUCCESS' ) {
            let perm = getPermissions()?.projects?.business_function?.integration_builder;
            let canUpdate = perm?.canUpdate || perm?.canCreate || perm?.canDelete;
            window.application.canView = perm?.canView || false;
            window.application.canCreate = perm?.canCreate || false;
            window.application.canUpdate = perm?.canUpdate || false;
            window.application.canDelete = perm?.canDelete || false;
            if(!canUpdate){
                window.application.readOnly = true; 
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { permissionStatus, match, history } = this.props;
        let prevBuilderId = prevProps?.match?.params?.builderId;
        let isPermissionStatusChanged = prevProps.permissionStatus !== permissionStatus;
        if(match?.params?.builderId !== prevBuilderId && !initialLoad) {
            initialLoad = true;
            window.application.canvas.clear();
            window.application.canvas.deactivate();
            window["utils"] = null;
            window["application"] = null;
            saveProjectPathUrl(match.params.id, history.location.pathname);

            window["application"] = new Application();
            window.application.integrationBuilderName = "";
            window.application.projectName = match.params.id;
            window.application.businessFunctionName = match.params.ProcessId;
        }
        if (initialLoad) {
            window["utils"] = new Utils();
           
            window.application.projectName = this.props.match.params.id;
            window.application.businessFunctionName = this.props.match.params.ProcessId;
            if (this.props.match.params.TaskName) {
                window.application.userTaskName = this.props.match.params.TaskName;
                window.application.from = 'BirdsEyeView';
            }
            // window.application.integrationBuilderName = this.props.match.params.builderId;
            window.application.integrationBuilderId = this.props.match.params.builderId;
            // if (!!this.props.history.location.state) {
            //   window.application.integrationBuilderId = this.props.history.location.state.uid;
            // } else {
            //   window.application.integrationBuilderId = window.name;
            // }
            if (!!this.props.history.location.state) {
                window.application.integrationBuilderName = this.props.history.location.state?.name || "";
                window.application.isDownloadBOS = this.props.history.location.state?.isOfflineBOS || false;
                window.application.isWorkflow = this.props.history.location.state?.isWorkflow || false;
                window.application.isOffline = this.props.history.location.state?.isOffline || false;
            }else {
                window.application.integrationBuilderName = window?.name || "";
            }
            window.application.reload = this.refresh;
            window.application.toggleMatchFields = this.toggleMatchFields;
            window.application.matchedFields = matchedFields;
            window.application.toggleLoader = this.toggleLoader;
            window.application.toggleDropdown = this.toggleDropdown;
            window.application.toggleContextMenu = this.toggleContextMenu;
            window.application.toggleBlocksDropdown = this.toggleBlocksDropdown;
            window.application.toggleIfElseSettings = this.toggleIfElseSettings;
            window.application.toggleContextMenuDynamic = this.handleCustomContextMenu;
            window.application.handleBlocksDropdownClose = this.handleBlocksDropdownClose;
            window.application.enableHeaderButtons = this.enableHeaderButtons;
            window.application.getHeaderButtonState = this.getHeaderButtonState;
            window.application.handleCloseContextMenu = this.handleCloseContextMenu;
            window.application.getVariablesList = this.getVariablesList;
            window.application.handleDropDownClose = this.handleDropDownClose;
            window.application.xmlToJson = this.xmlToJson;
            window.application.dropDownSelectValue = this.state.dropDownSelectValue;
            window.application.addLeftPanelNewCategory = this.addLeftPanelNewCategory;
            window.application.load();
            const loadParam = window.application.ui.parameter;
            const systemVariable = window.application.ui.system;
            rightPanel = loadParam;
            this.setState({ rightPanelData: rightPanel })
            system = systemVariable;
            initialLoad = false;
        }
        if (!this.isArrayEqual(system, window.application?.ui?.system)) {
            system = window.application?.ui?.system;
        }

        if( isPermissionStatusChanged && permissionStatus === 'PERMISSIONS_SUCCESS') {
            let perm = getPermissions()?.projects?.business_function?.integration_builder
            let canUpdate = perm?.canUpdate || perm?.canCreate || perm?.canDelete;
            window.application.canView = perm?.canView || false;
            window.application.canCreate = perm?.canCreate || false;
            window.application.canUpdate = perm?.canUpdate || false;
            window.application.canDelete = perm?.canDelete || false;
            if(!canUpdate){
                window.application.readOnly = true;
            }
        }
    }

    isArrayEqual = (a, b) => {
        return a?.sort()?.toString() == b?.sort()?.toString()
        // Array.isArray(a) &&
        //     Array.isArray(b) &&
        //     a.length === b.length &&
        //     a.every((val, index) => val === b[index]);

    }

    componentWillUnmount() {
        system = '';
        leftFields = [];
        rightFields = [];
        matchedFields = {};
        newLeftFields = [];
        newRighttFields = [];
        dropDownValues = [];
        dropDownTableValues = [];
        allRightFieldValues = [];
        rightPanel = {};
        initialLoad = true;
        this.finalMatchedFields = {};
        this.setState({
            mapType: '',
            sapDropdownValues: [],
            blockDropdownType: ''
        })
        this.webservices = ''
        this.props.clearOutputXmlData();
        window.name = '';
    }

    /* test generate */
    generateTest(obj) {
        const jsonValue = window.application?.ui?.toRunTimeJSON();
        // const params = window.application?.ui?.parameter?.toObject(true);
        // let temp = {};
        // temp["input"] = params.Input;
        // temp["output"] = params.Output;
        // temp["local"] = params.Local;
        // temp["env"] = params.Environment;
        // temp = { ...temp, ...jsonValue[0] };
        const data = { ...obj, doc: JSON.stringify(jsonValue), bosName: window.application.integrationBuilderName }
        helpers.generateTest(data, window.application.projectName);
    }

    /* search blocks in accordion */
    searchBlocks = (searchText) => {
        if (searchText.target.value) {
            this.setState({ isSearchData: true });
            helpers.searchBlocks(searchText.target.value);
        } else {
            this.setState({ isSearchData: false });
            helpers.fetchIntegrationBuilderLeftPanel()
        }
    }

    newTableData = (data, type) => {
        let newLeftTableData = [];
        let newRightTableData = [];
        if (type === 'left') {
            for (var item of data) {
                const leftPath = leftFields[0]?.xpath;
                const getPath = leftPath?.split('$')?.slice(0, -1);
                newLeftTableData.push({ name: item, xpath: `${getPath?.join('$')}$${item}` })
            }
            return newLeftTableData;
        }
        else if (type === 'right') {
            for (var item of data) {
                const rightPath = rightFields[0]?.xpath;
                const getPath = rightPath?.split('$')?.slice(0, -1);
                newRightTableData.push({ name: item, xpath: `${getPath?.join('$')}$${item}` })
            }
            return newRightTableData
        }
    }

    /* on save table map */
    matchFields = (data) => {
        let finalValues = [];
        // for (const item of Object.entries(data)) {
        //     if (!item.includes(`Don't Match`)) {
        //         finalValues.push(item)
        //     }
        // }
        // finalValues = Object.fromEntries(data);
        const table1 = this.newTableData(Object.keys(data), 'left');
        const table2 = this.newTableData(Object.values(data), 'right');
        if (this.toType?.toLowerCase() !== 'set') {
            matchedFields.toTable = table1;
            matchedFields.setTable = table2;
        } else {
            matchedFields.setTable = table1;
            matchedFields.toTable = table2;
        }
        this.finalMatchedFields = { ...matchedFields }
    }

    /* dropdown onclick */
    toggleDropdown = (event, selectType, variableType, existingValue) => {
        dropDownTableValues = [];
        dropDownValues = [];
        let dropdownData = [...event.node[event.values]];
        this.dropdownItemClick = event.itemClick;
        this.event = event;
        this.selectDropDownType = selectType;
        this.searchDropdownType = event.sapModuleListApi;

        if (dropdownData?.length > 0 && selectType === 'single') {
            let tableDropdownData = dropdownData.filter((item => {
                return item.value?.includes('BOS')
            }))
            let serviceDropdownData = dropdownData.filter((item => {
                this.webServiceType = item?.type;
                return item?.type === 'Table' || item.Xpath
            }))
            if (tableDropdownData?.length > 0) {
                const newDropDownTableVal = this.splitTableColumnValue(tableDropdownData)
                dropDownTableValues = newDropDownTableVal;
            } else if (serviceDropdownData?.length > 0) {
                this.webservices = 'webservice';
                const newDropDownTableVal = this.splitTableColumnValue(serviceDropdownData, 'webservice')
                dropDownTableValues = newDropDownTableVal;
            } else {
                dropDownValues = dropdownData
            }
        }
        else if (dropdownData?.length > 0 && selectType === 'multiple') {
            let tableDropdownData = dropdownData.filter((item => {
                return item.value?.includes('BOS')
            }))
            if (tableDropdownData?.length > 0) {
                const newDropDownTableVal = this.splitTableColumnValue(tableDropdownData)
                dropDownTableValues = newDropDownTableVal;
            } else {
                dropDownValues = dropdownData
            }
        }
        this.setState({
            dropDownSelectType: selectType,
            dropDownVariableType: variableType,
            dropdownPosition: { left: this.event.event.clientX, top: this.event.event.clientY },
            showDropdown: true,
            anchorEl: this.state.anchorEl ? null : this.event.event.currentTarget,
            selectedDropdownValues: existingValue?.length > 0 ? existingValue : '',
            dropdownBlockType: this.event.dropdownType
        });
        this.setState({
            selectedNodeName: window.application?.selectedNode?.nodeName,
            dropDownEvent: this.event
        }, () => {
            // this.handleCanvasClick();
        })
    }

    /*
    * toggleIfElseSettings Method toggles the ifElse Settings Dropdown. 
    *  Method call: 
    *       this.toggleIfElseSettings (event , details {}, callback func);
    *  By default:
    *    event : null (Should contain event with clientX, clientY)
    *    obj : details to pre-populate in the dropdown , if exists
    *    callback : function to callback when action performed
    */


    toggleIfElseSettings = (event, obj = {}, callback = () => null) => {
        if(event)
            this.setState({
                showIfElseSettings: {
                    flag: true, 
                    details: { obj, callback }
                },
                dropdownPosition: { 
                    left: event.event.clientX, 
                    top: event.event.clientY 
                },
            })
        else
            this.setState({
                showIfElseSettings: { flag: false, details: {} },
                dropdownPosition: {}
            })
    }

    /*
    * ToggleLoader shows / hide the loader in the center of the page by default
    *  Method call: 
    *       this.toggleLoader (true/false , x-position, y-position);
    *  By default:
    *    x-position: '47%' center from left
    *    y-position: '47%' center from top
    */

    toggleLoader = (flag, x = '47%', y = '47%') => {
        this.setState({
            loading: flag,
            loadingAxis: { x , y }
        })
    }

    /**
     * 
     * Toggle Disable/Enable the Buttons on Header Right Tab
     * like UNDO, REDO, FULL SCREEN, UNDOC, TEST
     * Method call:
     *      this.enableHeaderButtons (object)
     * By Default:
     *      object = {
     *          undo: true,
     *          redo: true,
     *          undoc: true,
     *          full_screen: true,
     *          test: true
     *      }
     */

    enableHeaderButtons = (obj = {}) => {
        this.setState((prev) => ({ 
            isHeaderButtonEnabled : { 
                ...prev.isHeaderButtonEnabled,
                ...obj
            } 
        }));
    }

    getHeaderButtonState = () => {
        return this.state.isHeaderButtonEnabled;
    }

    /* close dropdown */
    handleDropDownClose = (e) => {
        dropDownTableValues = [];
        dropDownValues = [];
        this.setState({
            anchorEl: false,
            showDropdown: false,
            sapDropdownValues: [],
        })
        this.webservices = ''
    }

    /* selected dropdown target value */
    selectDropDownValue = (targetValue) => {
        this.targetValue = targetValue;
        if (targetValue.itemData) {
            this.dropdownItemClick(targetValue.itemData);
        }
        else {
            this.dropdownItemClick(targetValue);
        }
        this.setState({ dropDownSelectValue: targetValue });
    }

    /* split value from bos */
    splitTableColumnValue = (data, ddType = '') => {
        let newDropDownTableVal = [];
        for (const item of data) {
            const columnValue = item?.value?.split('$')?.slice(-1)?.toString();
            const variableType = item.type ? item.type : item.Xpath ? item.Xpath.slice('$')[1] : '';
            const obj = {};
            obj.value = item.value ? item.value : item.name
            obj.name = item.name
            obj.type = variableType
            obj.isTable = item.type?.toLowerCase() === 'table' ? true : item.isTable
            if (ddType === 'webservice') {
                obj.itemData = item;
            }
            newDropDownTableVal.push(obj);
        }
        return newDropDownTableVal;
    }

    /* handle canvas onclick outside */
    handleCanvasClick = (event1, canvasEvent) => {
        /* if canvas object nodename not same from dropdown node -> close dropdown*/
        const nodeName = window.application?.selectedNode?.nodeName;
        if (!(canvasEvent && event1.clientX == canvasEvent.event.clientX && event1.clientY == canvasEvent.event.clientY)){
            if (nodeName !== this.state.selectedNodeName) {
                this.setState({
                    showDropdown: false
                })
            }
            const dropdown = document.getElementById('IbDropdownDialog');
            /* if event triggered on empty space of canvas -> close dropdown */
            if (!window.application?.canvas?.canvas?.getActiveObject()) {
                this.setState({
                    showDropdown: false
                })
            }
            if (this.state.isScrolled) {
                this.setState({
                    isScrolled: false,
                })
            }
        }
    }

    /* handle canvas scroll */
    handleCanvasScroll = () => {
        /* if context menu is visble make canvas scroll false or else make true */
        const contextMenu = document.getElementById('contextMenu');
        const contextMenuStyle = contextMenu?.style?.display
        if (contextMenu && contextMenuStyle !== 'none') {
            this.setState({
                isScrolled: true
            })
        } else {
            this.setState({
                isScrolled: false
            })
        }
        this.setState({
            showContextMenu: false
        })
    }

    /* copy left table value to right table value in table mapping */
    copyTable1Values = (data1, table, type) => {
        // this.copiedLeftValues = data2;
        let data2 = [];
        for (let i in data1) {
            let val = {
                name: data1[i].name,
                xpath: table + '$' + data1[i].name,
                dataType: 'String',
                default: 'String',
                isAsc: false,
                isIndex: false,
                isPrimary: false,
                isSortedCol: false
            }
            data2.push(val);
        }
        if (type == 'set') {
            matchedFields.setTable = data2;
            matchedFields.toTable = data1;
        }
        else {
            matchedFields.setTable = data1;
            matchedFields.toTable = data2;
        }
        return matchedFields;
    }

    xmlToJson = (data) => {
        return xmlConvert.xml2js(data, { compact: true });
    }

    /* handle sap field search */
    handleDropdownSearch = async (searchValue) => {
        let postData = {};
        postData["server"] = this.event.node['_system'].system;
        //if (this.event?.node?._system?.password && this.event?.node?._system?.password?.trim() !== '') {
            postData["password"] = this.event.node?._system?.password;
            postData["include"] = this.event.node?.include === 'yes' ? true : false;
            postData["search"] = searchValue;//"BAPI_PO_GET*";
            const resultData = await fetchSapFunctions(postData, this.props.match.params.id);
            if (resultData?.length > 0) {
                this.setState({
                    sapDropdownValues: resultData
                });
            //}else{
            //    window.application.handleError("Error", "E009");
            }
        //}
    }

    /* handle sap list data */
    sapDropdownData = (data) => {
        this.setState({
            sapDropdownValues: data
        });
        return data;
    }

    /* handle right click context menu */
    toggleContextMenu = (event) => {
        let node = window.application.selectedNode;
        let contextMenu = window.application.contextMenu;
        let obj = Object.create(null);
        obj = { ...contextMenu };
        let menuItem = [];
        for (var item in obj) {
            menuItem.push(obj[item]);
        }
        if (node) {
            if (node.type != "fun") {
                if (window.application.canDelete){
                    if (!node.parent) {
                        contextMenu.deleteNode.disabled();
                    } else {
                        contextMenu.deleteNode.enabled();
                    }
                }
                if (window.application.canCreate || window.application.canUpdate){
                    if (node.minimize == 0) {
                        contextMenu.minimize.enabled();
                        contextMenu.expand.disabled();
                    } else {
                        contextMenu.minimize.disabled();
                        contextMenu.expand.enabled();
                    }
                    if (node.isCommented) {
                        contextMenu.uncommentNode.enabled();
                        contextMenu.commentNode.disabled();
                    } else {
                        contextMenu.uncommentNode.disabled();
                        contextMenu.commentNode.enabled();
                    }
                }
            }
            else {
                contextMenu.minimize.disabled();
                contextMenu.expand.disabled();
                contextMenu.cutNode.disabled();
                contextMenu.copyNode.disabled();
                contextMenu.pasteNode.disabled();
                contextMenu.commentNode.disabled();
                contextMenu.uncommentNode.disabled();
            }
        }
        this.setState({
            showContextMenu: !this.state.showContextMenu,
            conextMenuPosition: {
                position: 'absolute',
                top: event.clientY,
                left: event.clientX,
            },
            conextMenuData: menuItem
        })

    }

    /* handle call back context menu click */
    handleContextMenuClick = (menu) => {
        this.setState({
            currentContextMenuItem: menu
        })
        switch (menu) {
            case 'Minimize':
                let minimizeNodeInstance = new minimizeEvent()
                minimizeNodeInstance.execute();
                break;
            case 'Expand':
                let expandNodeInstance = new expandEvent()
                expandNodeInstance.execute();
                break;
            case 'Delete node':
                let deleteNodeInstance = new deleteNode()
                deleteNodeInstance.execute();
                break;
            case 'Cut':
                let cutNodeInstance = new cutEvent()
                cutNodeInstance.execute();
                // contextMenu.doCopy();
                break;
            case 'Copy':
                let copyNodeInstance = new copyEvent()
                copyNodeInstance.execute();
                // contextMenu.doCut();
                break;
            case 'Paste':
                let pasteNodeInstance = new pasteEvent()
                pasteNodeInstance.execute();
                // contextMenu.doPaste();
                break;
            case 'Comment':
                let commentNodeInstance = new commentEvent()
                commentNodeInstance.execute();
                break;
            case 'Uncomment':
                let uncommentNodeInstance = new uncommentEvent()
                uncommentNodeInstance.execute();
                break;
            case 'Undo':
                let undoNodeInstance = new undoEvent()
                undoNodeInstance.execute();
                break;
            case 'Redo':
                let redoNodeInstance = new redoEvent()
                redoNodeInstance.execute();
                break;
        }
        this.setState({
            showContextMenu: false
        })
    }

    /* handle close context menu */
    handleCloseContextMenu = () => {
        this.setState({
            showContextMenu: false
        })
    }

    /* dynamic context menu options */
    handleCustomContextMenu = (event, data) => {
        this.customMenuItemClick = data;
        this.setState({
            showCustomContextMenu: true,
            customContextMenuData: data,
            customConextMenuPosition: {
                position: 'absolute',
                top: event.clientY,
                left: event.clientX,
            }
        })
    }

    /* callback for custom context menu click */
    handleCustomContextMenuClick = (menu, index) => {
        if (this.state.customContextMenuData?.[index]) {
            this.customMenuItemClick[index].event();
        }
        this.setState({
            showCustomContextMenu: false
        })
    }

    /* close context menu */
    handleCloseCustomContextMenu = () => {
        this.setState({
            showCustomContextMenu: false
        })
    }

    /* open math and function blocks dropdown */
    toggleBlocksDropdown = (event, itemClick, existingValue, blockType) => {
        this.blockEvent = event;
        this.targetBlockDropdownClick = itemClick;
        this.setState({
            showBlocksDropdown: true,
            blocksDropdownPosition: {
                position: 'absolute',
                top: event.event.clientY,
                left: event.event.clientX,
            },
            selectedBlockDropdownValue: existingValue?.length > 0 ? existingValue : '',
            blockDropdownType: blockType
        })
    }

    /* close math and function blocks dropdown*/
    handleBlocksDropdownClose = () => {
        this.setState({
            showBlocksDropdown: false
        })
    }

    /* handle call back for math and function blocks dropdown item click */
    handleSelectedBlockDropdownValue = (targetValue) => {
        this.setState({ selectedBlockDropdownValue: targetValue })
        this.targetBlockDropdownClick(targetValue);
    }

    /* add dynamic new category to left panel */
    addLeftPanelNewCategory = (obj) => {
        helpers.addLeftPanelNewCategory(obj);
    }

    getVariablesList = () => {
        return this.state.variables;
    }

    render() {
        return (
            <>
                <IntegrationBuilderLayout
                    navigateTab={this.navigateTab}
                    addNewParameters={this.addNewParameters}
                    searchBlocks={this.searchBlocks}
                    fetchVariableData={this.fetchVariableData}
                    filteredVairableData={this.state.filteredVairableData}
                    system={system}
                    generateTest={this.generateTest}
                    toggleMatchFields={this.toggleMatchFields}
                    showMatchFields={this.state.showMatchFields}
                    showMatchFieldsType2={this.state.showMatchFieldsType2}
                    leftFields={newLeftFields}
                    rightFields={newRighttFields}
                    showAlertDialog={this.state.showAlertDialog}
                    matchFields={this.matchFields}
                    dropDownValues={dropDownValues}
                    dropDownTableValues={dropDownTableValues}
                    selectDropDownValue={this.selectDropDownValue}
                    handleDropDownClose={this.handleDropDownClose}
                    allRightFieldValues={allRightFieldValues}
                    handleCanvasClick={this.handleCanvasClick}
                    handleCanvasScroll={this.handleCanvasScroll}
                    tableMappingType={this.state.tableMappingType}
                    copyTable1Values={this.copyTable1Values}
                    handleDeleteVariable={this.handleDeleteVariable}
                    handleDropdownSearch={this.handleDropdownSearch}
                    sapDropdownData={this.sapDropdownData}
                    handleContextMenuClick={this.handleContextMenuClick}
                    handleCloseContextMenu={this.handleCloseContextMenu}
                    handleCustomContextMenuClick={this.handleCustomContextMenuClick}
                    handleCloseCustomContextMenu={this.handleCloseCustomContextMenu}
                    handleBlocksDropdownClose={this.handleBlocksDropdownClose}
                    toggleBlocksDropdown={this.toggleBlocksDropdown}
                    toggleIfElseSettings={this.toggleIfElseSettings}
                    updateVariableParameters={this.updateVariableParameters}
                    handleSelectedBlockDropdownValue={this.handleSelectedBlockDropdownValue}
                    canvasEvent = {this.event}
                    setVariables = {(e) => this.setState({variables: e})}
                    {...this.props}
                    {...this.state}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    leftPanel: state.IntegrationBuilder.leftPanel,
    rightPanel: rightPanel,
    outputXmlData: state.IntegrationBuilder.outputXmlData,
    blocksDropdown: state.IntegrationBuilder.blocksDropdown,
    permissionStatus: state.application.permissionStatus
})

const mapDispatchToProps = (dispatch) => ({
    clearOutputXmlData: () => dispatch(testGenerate('')),
    reloadVariableData: (data) => dispatch(loadIntegrationBuilderRightPanel(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(IntegrationBuilder);