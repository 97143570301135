import { Box, Grid } from '@material-ui/core';
import { HomeTwoTone, ShoppingCartOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import IconPicker from '../../../../../common/components/icon-viewer/iconPicker';
import useStyles from './../style';
import * as mui from '@material-ui/icons';

export default function IconBuilder(props) {
  const {
    iconRef,
    activeImage,
    setActiveImage,
    setIconErr,
    iconErr,
    selectedApp,
    disabled
  } = props;
  const [iconColor, seticonColor] = useState('#FFFFFF');
  const [backgroundColor, setbackgroundColor] = useState('#0F98ED');
  const [openPicker, setOpenPicker] = useState(null);
  const [selectedIcon, setselectedIcon] = useState(null);
  const [customImage, setCustomImage] = useState(false);
  const [svgElement,setSvgElement] = useState(null)

  const classes = useStyles();

  useEffect(() => {
    if (activeImage) {
      if (activeImage.includes('image/svg')) {
        const urlSplit = activeImage.split('base64,');
        const imageData = urlSplit[1];
        const d = document.createElement('div');
        let val = imageData;
        if (val.endsWith('"')) {
          val = val.slice(0, -1);
        }
        try {
          d.innerHTML = window.atob(val);
          const iconName = d.querySelector('svg').getAttribute('data-name');
          const color = d.querySelector('svg').getAttribute('data-color');
          const bg = d.querySelector('svg').getAttribute('data-bg');

          seticonColor(color || '#E91E63');
          setbackgroundColor(bg || '#FDD13A');
          if(iconName){
            setselectedIcon({
              Component: mui[`${iconName}TwoTone`],
              importName: `${iconName}TwoTone`,
              name: iconName,
              theme: iconName,
            });
          }
          else{
            const base64Data = activeImage.split(',')[1];
            const decodedSVG = atob(base64Data);
        
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(decodedSVG, "image/svg+xml");
            const svgElement = svgDoc.documentElement; 

 
            
            setSvgElement(svgElement);
            setActiveImage(activeImage + "customSvg")
            setCustomImage(true)
            seticonColor('#000000');
            
          }
       
        } catch (e) {
          // console.log('Image encoding error: ', e);
        }
      } else {
        setCustomImage(true);
      }
    }
  }, [activeImage]);



  const onColorChange = (color, type = '') => {
    if (type === 'ICON_COLOR') {
      seticonColor(color);
    } else {
      setbackgroundColor(color);
    }
  };

  const saveActiveImage = (val) => {
    setselectedIcon(null);
    setActiveImage(val);
  };

  const handleSelectedIcon = (val) => {
    setActiveImage(null);
    setselectedIcon(val);
  };

  return (
    <Grid
      xs={12}
      container
      justify='flex-start'
      alignItems='flex-start'
      style={{ marginTop: 16 }}
    >
      <Box component='div' xs={4} style={disabled?{opacity:0.3}:{}} className={classes.PickerContainer}>
        <Box component='div'>{props.title || 'Icon'}</Box>
        <Box
          component='div'
          ref={iconRef}
          className={classes.Picker}
          style={disabled?{cursor:'pointer',opacity:0.3}:{}}
          onClick={() => {
            if(!disabled){
              setOpenPicker({
                type: 'ICON_COLOR',
                category: 'ICON',
                ref: iconRef.current,
              });
            }
          }}
        >
          {activeImage && customImage ? (
            // <img src={activeImage} className={classes.appIcon} />
            <div dangerouslySetInnerHTML={{ __html: activeImage.includes("customSvg")  ? svgElement.outerHTML : activeImage }} className={classes.appIcon} ></div>
          ) : selectedIcon && !!selectedIcon.Component ? (
            <selectedIcon.Component
              fill={iconColor}
              data-name={selectedIcon.name}
              data-color={iconColor}
              data-bg={backgroundColor}
              style={{
                color: iconColor,
                backgroundColor: backgroundColor,
                width: '74px',
                cursor: 'pointer',
                height: '74px',
                cursor: 'pointer',
                borderRadius: '16px',
                fontSize: 40,
                padding: '16px',
              }}
            />
          ) : (
            <ShoppingCartOutlined
              fill={iconColor}
              data-name='Home'
              data-color={iconColor}
              data-bg={backgroundColor}
              style={{
                color: iconColor,
                backgroundColor: backgroundColor,
                width: '74px',
                cursor: 'pointer',
                height: '74px',
                cursor: 'pointer',
                borderRadius: '16px',
                fontSize: 40,
                padding: '16px',
              }}
            />
          )}
        </Box>
      </Box>
      <IconPicker
        setOpenPicker={setOpenPicker}
        activeImage={activeImage}
        iconErr={iconErr}
        setActiveImage={saveActiveImage}
        setIconErr={setIconErr}
        openPicker={openPicker}
        setselectedIcon={handleSelectedIcon}
        onColorChange={onColorChange}
        classes={classes}
        enableIconColorPicker={true}
        enableBgColorPicker={true}
        enableFileUpload={true}
        iconColor={iconColor}
        backgroundColor={backgroundColor}
        selectedApp={selectedApp}
      />
    </Grid>
  );
}
