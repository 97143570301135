import React, { useState, useEffect } from 'react'
// import PopUp from '../../../../components/layouts/PopUp'
// import Stepper from '../../../../components/stepper/stepper';
import AdvancedConfiguration from './components/advanced-configuration/advancedConfiguration';
import AppPermissions from './components/app-permissions/appPermissions';
import ApplicationDetails from './components/application-details/applicationDetails';
import Configuration from './components/configuration/configuration';
import MasterTable from './components/master-table/masterTable';
import IosConfiguration from './components/ios-configuration/iosConfiguration';
import AndroidConfiguration from './components/android-configuration/androidCofiguration';
import AppGeneration from './components/app-generation/appGeneration';
import OTAInstallation from './components/ota-installation/otaInstallation';
import { ProjectSettingsActions } from '../projectsettings.actions';
import { useAppContext } from '../../../../components/activity/AppContext';
import PwaConfiguration from './components/pwa-configuration/pwaConfiguration';

function DeploymentPopup(props) {
    const [page, setPage] = useState(0);
    const { rolePopUp, setRolePopUp, projectName, updateCAList, loginAppList, containerAppDetails, setContainerAppDetails, resetCADetails } = props;
    const [containerAppId, setContainerAppId] = useState(containerAppDetails.containerApp?.id||"")
    const [tableList, setTableList] = useState([])
    const [bfList, setBfList] = useState([])
    const app = useAppContext()

    const pageNumber = (number) => {
        setPage(number);
    };

    const getTableList = async () => {
        const prjAction = ProjectSettingsActions(app())
        const [err, data] = await prjAction.getTableList(projectName)
        if (!err) {
            setTableList(data.data)
        }
    }

    const getBusinessFunctionList = async () => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.getBusinessFunctionList(projectName)
        // const [err, data] = await prjAction.getBusinessFunctionList("Prasannaj_workspace")
        
        if(!err) {
            setBfList(data.data)
        }
    }

    useEffect( () => {
        getTableList()
        getBusinessFunctionList()
        return ()=>{updateCAList()}
    }, [])

    const pageToDisplay = () => {
        if (page === 0) {
            return <Configuration
                rolePopUp={rolePopUp}
                pageNumber={pageNumber}
                projectName={projectName}
                handleClose={setRolePopUp}
                setContainerAppId={setContainerAppId}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
            />
        } else if (page === 1) {
            return <ApplicationDetails
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                containerAppId={containerAppId}
                handleClose={setRolePopUp}
                loginAppList={loginAppList}
                pageNumber={pageNumber} />
        } else if (page === 2) {
            return <AppPermissions
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                handleClose={setRolePopUp}
                pageNumber={pageNumber}
                bfList={bfList}
                fromScreen={props.fromScreen}
            />
        } else if (page === 3) {
            return <MasterTable
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                handleClose={setRolePopUp}
                pageNumber={pageNumber}
                tableList={tableList}
            />
        } else if (page === 4) {
            if(containerAppDetails?.containerApp.appType==="pwa") {
                return <PwaConfiguration 
                    rolePopUp={rolePopUp}
                    projectName={projectName}
                    containerAppId={containerAppId}
                    containerAppDetails={containerAppDetails}
                    setContainerAppDetails={setContainerAppDetails}
                    resetCADetails={resetCADetails}
                    handleClose={setRolePopUp}
                    pageNumber={pageNumber}
                />
            }
            return <AdvancedConfiguration
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                handleClose={setRolePopUp}
                pageNumber={pageNumber}
            />
        } else if (page === 5) {
            return <IosConfiguration
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppId={containerAppId}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                handleClose={setRolePopUp}
                pageNumber={pageNumber}
            />
        } else if (page === 6) {
            return <AndroidConfiguration
                rolePopUp={rolePopUp}
                projectName={projectName}
                containerAppId={containerAppId}
                containerAppDetails={containerAppDetails}
                setContainerAppDetails={setContainerAppDetails}
                resetCADetails={resetCADetails}
                handleClose={setRolePopUp}
                pageNumber={pageNumber}
            />
        } else if (page === 7) {
            return <AppGeneration
                rolePopUp={rolePopUp}
                handleClose={setRolePopUp}
                resetCADetails={resetCADetails}
                pageNumber={pageNumber}
            />
        } else if (page === 8) {
            return <OTAInstallation
                rolePopUp={rolePopUp}
                containerAppDetails={containerAppDetails}
                handleClose={setRolePopUp}
                resetCADetails={resetCADetails}
                pageNumber={pageNumber}
            />
        }
    }

    return (
        <>
            {rolePopUp &&
                <div>{pageToDisplay()}</div>
            }
        </>
    )
}

export default DeploymentPopup