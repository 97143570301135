var currentDataType = ""
const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const alphaNum = (errors, name, value, msg) => {
    const re = /^[A-Za-z_0-9]+$/;
    if (!re.test(value)) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name);
    return true;
}

const validateName = (errors, value) => {
    if (required(errors, 'name', value, "Please enter name value")) {
        return alphaNum(errors, 'name', value, "Use alphabets, numbers, and '_' only");
    } else {
        return false;
    }
}

const validateDescription = (errors, value) => {
    if (required(errors, 'description', value, "Please enter description value")) {
        return alphaNum(errors, 'description', value, "Use alphabets, numbers, and '_' only");
    } else {
        return false;
    }
}

const type = (errors, value) => {
    return required(errors, 'type', value, "Please select a data type")
}

const dataValue = (errors, value) => {
    let errorValue = required(errors, 'value', value, "Please enter the value")
    if (errorValue) {
        if (currentDataType === "Decimal") {
            if (value.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)) {
                return errorValue
            } else {
                return required(errors, 'value', "", "Please enter a valid Decimal value")
            }
        } else if (currentDataType === "Number") {
            if (value.match(/^([+-]?[0-9]*)?$/)) {
                return errorValue
            } else {
                return required(errors, 'value', "", "Please enter a valid Number")
            }
        }
    }
    return errorValue
}

const key = (errors, value) => {
    if (required(errors, 'key', value, "Please enter column name")) {
        return alphaNum(errors, 'key', value, "Use alphabets, numbers, and '_' only");
    } else {
        return false;
    }
}

const checkDuplicateKey = (errors, value) => {
    for (let obj of value) {
        for (let item of value) {
            if (value.indexOf(obj) !== value.indexOf(item)) {
                if (obj.key === item.key) {
                    errors.set("key", "Key already exists.")
                    return false;
                }
            }
        }
    }
    errors.clear("key")
    return true;
}

const columnType = (errors, value) => {
    return required(errors, 'columnType', value, "Please select column type")
}

const tableValue = (errors, value) => {
    return required(errors, 'tableValue', value, "Please enter table value")
}


const validateValue = (errors, name, value) => {
    switch (name) {
        case 'name':
            return validateName(errors, value)
        case "description":
             return validateDescription(errors,value)
        case 'type':
            return type(errors, value)
        case 'dataType':
            currentDataType = value
            return true;
        case "value":
            return dataValue(errors, value)
        case "key":
            return key(errors, value)
        case "fields":
            return checkDuplicateKey(errors, value)
        case "columnType":
            return columnType(errors, value)
        case "tableValue":
            return tableValue(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    currentDataType = values.dataType
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value))
            valid = false
    })
    return valid
}

export { validateValue, validateValues, required, validateName, dataValue, type, key, checkDuplicateKey, tableValue }
