import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import { validateValue, validateValues } from "../../validations";
import { SchedulerActions } from "../../scheduler.action";
import { useScheduler } from "../../schedulerContext";

import styles from "./TimeBasedSchedule.module.css"

export const TimeBasedSchedule = ({ show, handleClose }) => {
    let style = { marginTop: "8px" };

    const app = useAppContext();

    const { timeBasedEdit, setTimeBasedEdit, setFinalStep, setTimeBased, setShow, setBospopup,
        timeBasedSchedulerForm, setTimeBasedSchedulerForm,
        params, createScheduler, setCreateScheduler, edit, draft, setDraft, allBos, setAllBos, save, setSave } = useScheduler();

    const [data, setData] = useState({ name: timeBasedSchedulerForm.name, description: timeBasedSchedulerForm.description, service: timeBasedSchedulerForm.serviceType })


    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
    }

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors)) {
            return false;
        }
        else {
            // 
            let err;
            if(timeBasedSchedulerForm.name !== values.name){
                err = await checkSchedulerExists(values.name)
            }
            // console.log(err, draft, edit)
            if (err) {
                errors.clear('name')
                errors.set('name', err)
                return false;
            }
            if (!err) {
                if (draft) {
                    const payload = {
                        bosname: params.serviceId,
                        description: values.description,
                        eventId: null,
                        freq: null,
                        isEnabled: 2,
                        isTimeBased: true,
                        name: values.name,
                        parameters: "{}",
                        projectComponentId: 0,
                        runAsUser: params.runAsUser,
                        serviceName: params.projectName+"."+params.bosName,
                        serviceType: timeBasedSchedulerForm.serviceType
                    }


                    CreateScheduler(payload)
                    handleClose()
                    setTimeout(() => setCreateScheduler(!createScheduler), 1000)
                    setDraft(false)

                }
                else if (edit) {
                    handleCancel()
                }
                else if (save) {
                    setTimeBased(false)
                    setTimeBasedEdit(false)
                    setSave(false)
                    // handleNext()
                    setFinalStep(true)
                }
                else {
                    handleNext()
                }
            }




        }
        setTimeBasedSchedulerForm({ ...timeBasedSchedulerForm, name: values.name, description: values.description })
    }

    const { Form, Label, Input, Error, TextArea, Select } = useForm(data, onSubmit, onWatch);

    const handleBack = () => {
        handleClose()
        setShow(true)
    }

    const handleNext = () => {
        loadBos()
        handleClose()
        setBospopup(true)
    }

    const handleCancel = () => {
        handleClose()
        setTimeBasedEdit(false)
        setTimeBased(false)
        setFinalStep(true)
    }

    const handleBos = (e) => {
        setTimeBasedSchedulerForm({ ...timeBasedSchedulerForm, serviceType: "BOS" })
    }

    const CreateScheduler = async (payload) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.CreateSchedulerBasedonTime(payload);
        if (!error) {
            console.log(data)
        }
    }

    const checkSchedulerExists = async (name) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.verifySchedulerExists(name);
        if (error) {
            return error
        }
    }

    const handleDraft = () => {
        setDraft(true)
    }

    const loadBos = async () => {
        if (params.bosName) {
            const actions = SchedulerActions(app());
            const [error, data] = await actions.GetBos(params.bosName);
            if (!error) {
                console.log(data)
                setAllBos(data.data)
            }
        }
    }



    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"time-based-schedule"} title={"Time-based schedule"} titleClassName={styles.timeBasedScheduleHeader}>
            <Form>
                <div className={styles.formArea} style={{margin:0}}>
                    <div className={styles.column}>

                        <Label text={"Name your schedule"} htmlFor="name" style={style} />
                        <Input type="text" id="name" name="name" maxLength={45} style={{width:"100%"}}/>
                        <Error name={'name'} style={{marginBottom:"5px"}} />

                        <Label text={"Description"} htmlFor="description" style={style} />
                        <TextArea type="text" id="description" name="description" maxLength={250} />
                        <Error name={'description'} style={{marginBottom:"5px"}} />

                        <Label text={"What do you want to invoke?"} htmlFor="service" style={style} />
                        <Select type="text" id="serviceType" name="serviceType" defaultValue={timeBasedSchedulerForm.serviceType} data-option-testid="service" onClick={handleBos}  >
                            <option disabled selected={true} >Select service type</option>
                            <option selected={timeBasedSchedulerForm.serviceType === "BOS" ? true : false}
                            >BOS</option>
                        </Select>
                        <Error name={'service'} style={{marginBottom:"5px"}} />


                    </div>
                </div>

                <div className={styles.afFooter}>

                    {
                        !timeBasedEdit ?
                            <>
                                <Input
                                    type="button"
                                    value={"Back"}
                                    className="secondary"
                                    onClick={handleBack}


                                />
                                <Input
                                    type="submit"
                                    value={"Save Draft"}
                                    className="secondary"
                                    onClick={handleDraft}

                                />
                                <Input type="submit" className="primary" value={"Next"} />
                            </> :
                            <>
                                <Input
                                    type="button"
                                    value={"Cancel"}
                                    className="secondary"
                                    onClick={handleCancel}
                                />
                                <Input type="submit" className="primary" value={"Save"} />
                            </>
                    }

                </div>
            </Form>
        </PopUp>
    </>
}