import React, { createContext, useContext, useState } from "react";
import Scheduler from "./scheduler";
import { getAuth } from "../../../auth/user";
import { v4 as uuidv4 } from 'uuid';
export const SchedulerContext = createContext();

export const SchedulerProvider = ({ children, perm }) => {
    const [loglist, setLoglist] = useState(false);
    const [finalStep, setFinalStep] = useState(false);
    const [edit, setEdit] = useState(false);
    const [timeBasedEdit, setTimeBasedEdit] = useState(false)
    const [bospopupEdit, setBospopupEdit] = useState(false)
    const [parameterEdit, setParameterEdit] = useState(false)
    const [triggerEdit, setTriggerEdit] = useState(false)
    const [show, setShow] = useState(false);
    const [timeBased, setTimeBased] = useState(false);
    const [bospopup, setBospopup] = useState(false);
    const [parameter, setParameter] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [report, setReport] = useState(false)
    const [isTimeBased, setIsTimeBased] = useState(false)


    const [timeBasedSchedulerForm, setTimeBasedSchedulerForm] = useState({
        name: "",
        description: "",
        serviceType: "BOS"
    })

    const [params, setParams] = useState({
        projectName: "",
        bosName: "",
        serviceId: "",
        projectComponentId: "",
        runAsUser: getAuth()?.auth?.loginName || ""
    })

    const [paramObject, setParamObject] = useState({})
    const [parameters, setParameters] = useState({ input: {}, system: []})
    const [data, setData] = useState([])
    const [systemData, setSystemData] = useState([])

    const [freq, setFreq] = useState([
        {
            id: "4dsvsdv",
            day: "1st",
            drp: "hours",
            hr: "",
            isOpended: false,
            min: "",
            times: "",
            week: "Monday"
        }
    ])

    const [freqObj, setFreqObj] = useState({
        id: "4dsvsdv",
        day: "1st",
        drp: "hours",
        hr: "",
        isOpended: false,
        min: "",
        times: "",
        week: "Monday"
    })

    const [createScheduler, setCreateScheduler] = useState(false);

    const [id, setId] = useState(0);

    const [prevName, setPrevName] = useState("")

    const [isEnabled, setIsEnabled] = useState("")

    const [draft, setDraft] = useState(false)
    const [allProjects, setAllProjects] = useState([])
    const [allBos, setAllBos] = useState([]);
    const [option, setOption] = useState("");
    const [save, setSave] = useState(false);

    const updateParams = (key, value) => {
        let input = { ...parameters.input }
        let params = { ...parameters }
        console.log(input, params)
        input[key] = value
        params.input = input
        console.log(input, params)
        setParameters({ ...params })
    }

    const updateSystemParams = (idx, sysName, key, value) => {
        console.log(idx, sysName, key, value)
        let system = [...parameters.system]
        let params = { ...parameters }
        let obj = { ...system[idx] }
        if (!obj.system) {
            obj = { "system": sysName, "userId": "", "password": "" }
        }
        obj[key] = value
        system[idx] = obj
        params.system = system
        console.log(system, params)
        setParameters({ ...params })
    }


    return <SchedulerContext.Provider value={{
        loglist, setLoglist, finalStep, setFinalStep, edit,
        setEdit, timeBasedEdit, setTimeBasedEdit, show, setShow, timeBased, setTimeBased,
        bospopup, setBospopup, parameter, setParameter, trigger, setTrigger, setBospopupEdit, bospopupEdit,
        parameterEdit, setParameterEdit, triggerEdit, setTriggerEdit, params, setParams, timeBasedSchedulerForm,
        setTimeBasedSchedulerForm, freq, setFreq, freqObj, setFreqObj, paramObject, setParamObject,
        isTimeBased, setIsTimeBased, createScheduler, setCreateScheduler, id, setId, prevName,
        setPrevName, isEnabled, setIsEnabled, report, setReport, draft, setDraft, save, setSave,
        allProjects, setAllProjects, allBos, setAllBos, option, setOption, parameters, setParameters, updateParams, updateSystemParams,
        data , setData , systemData , setSystemData
    }}
    ><Scheduler perm={perm}/></SchedulerContext.Provider>
}

export const useScheduler = () => useContext(SchedulerContext)