import { NavLink, useLocation } from "react-router-dom";
import styles from './index.module.css';
import React from "react";


function SubHeader(props) {

    const { links } = props;
    const { pathname } = useLocation();

    //sort wrto menuSeq
    links.sort((a,b)=>a.menuSeq-b.menuSeq)

    const loadLinks = (link) => {
        const name = link.displayLable || link.name;
        const path = link.name.toLowerCase().replace(" ","");
        let prop = {};
        prop["to"] = {pathname: path === "home" ? link.path : path, state: {permission:link}};
        prop["className"] = styles.navLinkSub;
        prop["data-clickable-testid"] = path || name;
        // prop["permission"] = link; 

        if (path && pathname.toLowerCase().includes(path)) {
            prop["className"] = styles.navLinkSubActive;
        }

        return (
            <NavLink {...prop}> {name} </NavLink>
        )
    };

    return (

        <header className={styles.afSubHeader}>
            {links.map((link, index) => {
                if(link.canView)
                    return (<div key={"sh_" + index}>{loadLinks(link)}</div>)
                
            })}
        </header>
    );
}

export default SubHeader;

