import React, { useState, useEffect, useRef } from 'react';
import Table from '../../../../../common/components/Table';
import {
  InputLabel,
  Box,
  Grid,
  TablePagination
} from '@material-ui/core';
import { configMessage } from '../../../../../common/messages/config';
import useStyles from './style';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

let pagination = {
    newPageNumber: 0,
    rowsPerPage: 10,
};

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const microAppsColumns = [
  {
    id: 'name',
    label: configMessage.T4615,
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '150px',
      maxWidth: '150px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'businessFunctionName',
    label: 'Business Function',
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '150px',
      maxWidth: '150px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'version',
    label: 'version',
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '80px',
      maxWidth: '80px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'checked',
    label: '',
    align: 'right',
    type: 'checkbox_without_icon',
    style: {
      marginLeft: 24,
      minWidth: '50px',
      maxWidth: '50px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
];

export default function (props) {
  const { appDetails, setAppDetails, microAppList, setModified } = props;
  const classes = useStyles();

  const [microAppTableData, setmicroAppTableData] = useState([]);
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState([])
  const microAppListDataRef = useRef([])

  useEffect(() => {
    let data=[]
    if(microAppList.length > 0){
        let bfIds = microAppList.map(e => { return e.businessFunctionId; });
        bfIds = [ ...new Set(bfIds)];
        data = bfIds.map(e => {
          let childs = microAppList.filter(f => f.businessFunctionId === e);
          let selected = appDetails.containerAppAccess?.find((item) => {
            return e == item.businessFunctionId;
          });
          return {
            businessFunctionId: e,
            businessFunctionName: childs[0]?.businessFunctionName?`${childs[0]?.projectName}.${childs[0]?.businessFunctionName}`:"",
            name: childs.length>1?"--WORKFLOW APP--":childs[0]?.name,
            version: childs[0]?.version || "1.0",
            checked: selected ? true : false,
            child: childs
          }
        })
      }
      microAppListDataRef.current = data
      setCount(data.length)
      handleTableData()
  }, [microAppList]);

  const handleTableData = (pagination, filter) => {
    let noOfRows = pagination?pagination.rowsPerPage:rowsPerPage
    let startidx = (pagination?pagination.newPageNumber:page)*noOfRows
    let tableList = filter?filter:microAppListDataRef.current
    let newArr = tableList.slice(startidx,startidx+noOfRows)
    let arr = newArr.map(e=>{
        let selected = appDetails.containerAppAccess?.find((item) => {
            return e.businessFunctionId == item.businessFunctionId;
          });
          if(selected) return {...e, checked:true}
          else return {...e, checked:false}
    })
    setmicroAppTableData(arr)
    setCount(tableList.length)
  }

  const constructFinalPayload = (kind, data) => {
    let payload = { ...appDetails };
    let containerAppId = payload.containerApp?.id || "";
    if (kind === 'MICROAPP') {
      let temp = [...payload.containerAppAccess];
      if (data.checked) {
        temp.push({ businessFunctionId: '' + data.businessFunctionId  });   //, containerAppId
      } else {
        temp = payload.containerAppAccess.filter(
          (val) => parseInt(val['businessFunctionId']) !== data.businessFunctionId
        );
      }
      payload = { ...payload, containerAppAccess: [...temp] };
    }

    setAppDetails(payload);
  };

  const changePermissionTableValue = (checked, filter, index) => {
    // let dataArr = [...microAppTableData];
    let temp = microAppTableData.map((e) => {
      if (e.businessFunctionId === filter.businessFunctionId) {
        const data = { ...e, checked: checked };
        return data;
      } else return e;
    });
    // if (mAapSearchQuery !== '') {
    //   let tempA = microAppTableData.map((e) =>
    //    e.businessFunctionId === filter.businessFunctionId
    //       ? { ...e, checked: checked }
    //       : e
    //   );
    //   setMAppSearchData(tempA);
    // }
    setmicroAppTableData(temp);
    setModified(true);
  };

  const handleTableCheckBox = (e, row, index) => {
    if (e.target.checked) {
      changePermissionTableValue(true, { businessFunctionId: row.businessFunctionId }, index);
      constructFinalPayload('MICROAPP', {
        businessFunctionId: row.businessFunctionId,
        checked: true,
      });
    } else {
      changePermissionTableValue(false, { businessFunctionId: row.businessFunctionId }, index);
      constructFinalPayload('MICROAPP', {
        businessFunctionId: row.businessFunctionId,
        checked: false,
      });
    }
    setModified(true);
  };
  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(recordsPerPage);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
  };

  const handlePagination = (value,data) => {
    pagination = {
      rowsPerPage: value.rowsPerPage,
      newPageNumber: value.newPageNumber,
    };
    if(!data) {
      data=filter.reduce((accumulator,filterItem)=>{
        return accumulator.filter((item) => {
            return item[filterItem.columnName]?.toLowerCase()?.search(filterItem.value?.toLowerCase()) !== -1;
          })
    },microAppListDataRef.current)
    }
    handleTableData(pagination, data)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
  };

  useEffect(()=>{

    // setMAppSearchData(filteredArr);
    // handlePagination({ newPageNumber: 0, rowsPerPage },filteredArr);
    // // setModified(true);
  },[filter])

  const handleMAppQueryChange = (value, column) => {
    let newFilter=[...filter]
    let filterIdx = filter.findIndex(f=>f.columnName==column.id)
    if(filterIdx !=-1) {
        newFilter[filterIdx] = {columnName:column.id, value:value}
    }else{
        newFilter.push({columnName:column.id, value:value})
    }
    setFilter(newFilter)
    let tableList=newFilter.reduce((accumulator,filterItem)=>{
        return accumulator.filter((item) => {
            return item[filterItem.columnName]?.toLowerCase()?.search(filterItem.value?.toLowerCase()) !== -1;
          })
    },microAppListDataRef.current)
    // setMAppSearchQuery(value);
    handlePagination({ newPageNumber: 0, rowsPerPage },tableList);
  };

  return (
    <Grid xs={12} container className={classes.appPermissionsContainer}>
      <Grid xs={9} className={classes.appBox} item>
        <InputLabel className={classes.appTableBox}>
          {configMessage.T4597}
        </InputLabel>
        <Box component='div' className={classes.tableBox}>
          <Table
            classes={classes}
            tableData={microAppTableData}
            tableColumns={microAppsColumns || []}
            handleChange={(e, c) => handleMAppQueryChange(e, c)}
            handleCheckBox={handleTableCheckBox}
            screenType={'libraryCAG'}
            tableHeight='320px'
            checkDisable={props.checkDisable || []}
          />
          <TablePagination
            rowsPerPageOptions={paginationDropDownList}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
            }
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
                classes: { icon: classes.selectIcon },
            }}
            showFirstButton
            showLastButton
          />
        </Box>
      </Grid>
    </Grid>
  );
}
