import React, { useState } from 'react'
import styles from './appGeneration.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import CreateAppSVG from '../../../../../../images/creating-app.svg'


function AppGeneration({ pageNumber, handleClose, rolePopUp }) {
    const [data, setData] = useState({
        name: ""
    })

    const onSubmit = () => {
        // console.log("onSubmit")
        pageNumber(8)
    }

    const onWatch = (values, errors) => {
        // console.log(values)
    }

    const handleBack = () => {
        pageNumber(6)
    }
    const { Form, Label, Input, Error, TextArea } = useForm(data, onSubmit, onWatch);
    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClose}
            titleClassName={styles.afModuleTitle}
            title="Generating Application"
            subtitle=""
        >
               
                <Form style={{ width: '100%' }}>
                    <div className={styles.imgCont}>
                        <img src={CreateAppSVG} />
                    </div>
                
                    {/* <div className='afPopupFooter'>
                        <div>
                            <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                            <Input type="submit" value={"Next"} />
                        </div>
                    </div> */}
                </Form>
        </PopUp>
    )
}

export default AppGeneration