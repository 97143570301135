import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  settingMainLayout: {
    padding: "24px 24px 0px 24px",
    // width: `${theme.spacer * 180}px`,
    height: "auto",
    alignContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: `${theme.spacer * 10}px`,
  },
  settingDetailContainer: {
    display: "flex !important",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    height: `${theme.spacer * 5}px`,
    marginTop: `${theme.spacer * 7}px`,
  },
  ContainerAppDetail : {
    display: "flex !important",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    height: `${theme.spacer * 5}px`,
    marginTop: `${theme.spacer * 3.5}px`,
    marginBottom : `${theme.spacer * 3.5}px`
  },
  headerButton: {
    alignContent: 'end'
  },
  headerTitle: {
    fontWeight: "400",
    fontSize: `${theme.spacer * 4}px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 5}px`,
  },
  subTitleLayout: {
    fontWeight: 500,
    fontSize: `18px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 5}px`,
    marginTop: `0px`,
    marginLeft: "4px",
    lineHeight: `${theme.spacer * 5}px`,
  },
  newButtonLayout: {
    marginLeft: "auto",
    marighRight: "0px",
    marginTop: `0px`,
    height: `${theme.spacer * 5}px`,
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
  },
  buttonGrid: {
    textAlign: "center",
    display: "flex"
  },
  subContainer: {
    display: "flex !important",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    height: `auto`,
    marginTop: `${theme.spacer * 2}px`,
    backgroundColor: "transparent",
    marginBottom: `${theme.spacer * 2}px`,
  },
  leftTopSubBox: {
    padding: "0px 12px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "auto",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
  },
  leftBottomSubBox: {
    padding: "0px 12px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "auto",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
    marginTop: `${theme.spacer * 4}px`,
  },
  rightSubBox: {
    padding: "0px 0px 0px 0px",
    minHeight: `${theme.spacer * 38}px`,
    maxWidth: "auto",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
  },
  subBox: {
    padding: "0px 0px 0px 0px",
    minHeight: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
  },
  innerBox: {
    display: 'flex',
    padding: "0px 24px 0px 24px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    overflowY: "scroll",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)"
  },
  innerRightBox: {
    padding: "0px 0px 0px 0px",
    minHeight: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
  },
  nameLayout: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "32px",
    height: `${theme.spacer * 4}px`,
    width: "auto",
    marginRight: `${theme.spacer * 3}px`,
    textAlign: "left"
  },
  nameLayoutNormalTop: {
    marginTop: `${theme.spacer * 3}px`,
  },
  nameLayoutErrorTop: {
    marginTop: `${theme.spacer * 1}px`,
  },
  descriptionLayout: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "32px",
    height: `${theme.spacer * 4}px`,
  },
  descNormalTop: {
    marginTop: `${theme.spacer * 3}px`,
  },
  descErrorTop: {
    marginTop: `${theme.spacer * 1}px`,
  },
  descriptionTextBox: {
    height: `80px`,
    overflow: "scroll",
    display: "inline-block",
  },
  firstFooter: {
    padding: "0px 0px 0px 0px",
    backgroundColor: `${theme.color.white} !important`,
    marginBottom: `${theme.spacer * 3}px`,
    alignSelf: "flex-end",
    marginTop: "15px",
    paddinBottom: "16px",

  },
  saveButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "2px",
    marginRight: "0px",
    height: `${theme.spacer * 5}px`,
  },
  cancelButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "2px",
    marginRight: "12px",
    height: `${theme.spacer * 5}px`,
  },
  deploySubtitle: {
    fontWeight: "500",
    fontSize: `18px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 3}px`,
    marginTop: `4px`,
  },
  leftBottomInnerBox: {
    // display: 'flex',
    padding: "0px 0px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    overflow: "hidden",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
  },
  leftBottomTextBox: {
    height: `${theme.spacer * 6}px`,
    width: "100%",
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    backgroundColor: theme.color.white,
    borderTopLeftRadius: `${theme.spacer}px`,
    borderTopRightRadius: `${theme.spacer}px`,
    borderBottomColor: theme.color.coolgray10,
    borderBottom: "1px solid",
  },
  container: {
    maxHeight: "100vh",
    boxShadow: 'none',
  },
  container: {
    minHeight: "254px",
    overflowY: "scroll",
    position: "sticky",
    borderRadius: "8px",
  },
  noRecordLayout: {
    width: "auto",
    height: "auto",
    position: "absolute",
    backgroundColor: "transparent",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: "400",
    fontSize: `${theme.spacer * 3}px`,
    color: `${theme.color.gray60} !important`,
  },
  selectIcon : {
    width : '18px'
  }
}));
export default useStyles;