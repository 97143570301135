import { getUrl, get, post, getFilter, upload } from "../url";

export async function PublishedLoginAppsList () {
    let path = `${getUrl()}/library/v1.0/logins/publishedLogins?`
    let select = `$select=id,name`

    const [error, data] = await get(`${path}${select}`)
    return [error, data];
}

export async function ContainerAppDetails (prjName, containerAppId) {
    let path = `${getUrl()}/configurator/v1.0/container/${containerAppId}/detail`
    let projectNameParam = `project_name=${prjName}`

    const [error, data] = await get(`${path}?${projectNameParam}`)
    return [error, data]
}

export async function ContainerAppsList(prjName,limit,offset,filter){
    let path = `${getUrl()}/configurator/v1.0/container/list?project_name=${prjName}`;
    let select = `$select=id,deviceType,buildNumber,updateTime,version,status,name,caType,appType,otaUrl`;
    let limitParam = `limit=${limit}`;
    let offsetParam = `offset=${offset}`;
    let sort = `$sort=desc c.id`;
    let filterParam = getFilter(filter)

    const [error, data] = await get(`${path}&${select}${filterParam}&${sort}&${limitParam}&${offsetParam}`);
    return [error,data];
}

export async function createContainerApp (prjName, payload = {}) {
    let path = `${getUrl()}/configurator/v1.0/container/create?project_name=${prjName}`;

    const [error, data] = await post(path, payload);
    return [error, data];
}

export async function ProjectTableList (prjName) {
    let path = `${getUrl()}/configurator/v1.0/project/${prjName}/listTables`
    let filter = "?$filter=type%20EQ%20%27DB%27%20or%20type%20EQ%20%27DB_VIEW%27" //$filter: type EQ 'DB' or type EQ 'DB_VIEW'
    const [error, data] = await get(path+filter)
    return [error, data];
}

export async function BusinessFunctionList (prjName, includeUniversalWorkList=true) {
    let path = `${getUrl()}/configurator/v1.0/container/apps`
    let projectNameParam = `?project_name=${prjName}`
    let select = `&$select=id,name,businessFunctionName,businessFunctionId,version`
    let filter = includeUniversalWorkList?"":`&$filter=b.type%20eq%20%27APP%27`
    const [error, data] = await get(path+projectNameParam+select+filter)
    return [error, data]
}

export async function UploadFile( prjName, containerAppId, comment, fileName, file) {
    let path = `${getUrl()}/configurator/v1.0/container/${containerAppId}/upload`
    let projectNameParam = `project_name=${prjName}`
    let commentParam = `comment=${comment}`
    let fileNameParam = `$file_name=${fileName}`
    const uri = `${path}?${projectNameParam}&${commentParam}&${fileNameParam}`
    const [error, data] = await upload(uri, file);
    return [error, data]
}

export async function getAppsBinary(deviceType, containerAppId) {
    console.log(deviceType, containerAppId)
    let path = `${getUrl()}/configurator/v1.0/container/${containerAppId}/${deviceType}/download`
    const [error, data] = await get(path)
    return [error, data]
}