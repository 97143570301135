import React, { useEffect, useState } from 'react';
import { NavButton } from '../../../../../common/components/NavButton';
import CellIcon from '../../../../../assets/images/input-cell-icon.js';
import { ArrowForwardOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import {
  List,
  Popover,
  ListItem,
  ListItemText,
  FilledInput,
} from '@material-ui/core';

export default function AddInput(props) {
  const { showBfVariable = true, showUserProperty = true } = props;
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
  const [variableList, setVariableList] = useState([]);
  const [val, setVal] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [thirdAnchorEl, setThirdAnchorEl] = useState(null);
  const [selectedSecondVar, setSelectedSecondVar] = useState('');
  const [workItem, setWorkItem] = useState([]);
  const classes = useStyles();

  const _getPrimaryList = () => {
    const { showBfVariable = true, showUserProperty = true } = props;
    let showGlobalVariable = props.globalVariable || false;
    let showProjVariable = props.projVariable || false;
    let showWorkItem = props.workItems || false;
    let allowConstantValue = props?.additonalProps?.allowConstantValue || false;

    let list = [];

    if(showBfVariable){   
      list.push({ key: '$BF.', label: 'Variable' })
    }
    if (showUserProperty){
      list.push({ key: '$USER.', label: 'User Property' });
    }
    if (showGlobalVariable) {
      list.push({ key: '$GLOBAL.', label: 'Global Variable' })
    }
    if (showProjVariable) {
      list.push({ key: '$PROJECT.', label: 'Project Variable' })
    }
    if (showWorkItem) {
      list.push({ key: '$WORKITEM.', label: 'WorkItem' })
    }
    if (allowConstantValue) {
      list.push({ key: '$VALUE.', label: 'Value' })
    }

    return list;
  }
  const primaryList = _getPrimaryList();

  const handleListItemClick = (e, key) => {
    if (key === "$VALUE.") {
      if (props?.additonalProps?.handleValueSelection) {
        props.additonalProps.handleValueSelection();
      }
      setAnchorEl(null)
    } else {
      let vl = [];
      if (key === '$PROJECT.') vl = props.projVariable;
      else if (key === '$USER.') vl = props.userPropertyList;
      else if (key === '$GLOBAL.') vl = props.globalVariable;
      else if (key === '$WORKITEM.') vl = props.workItems;
      else vl = props.bfVariable;
      if (vl && vl.length > 0) {
        vl = vl.filter((f) => !props.usedColumns.includes(key + f.name));
      }
      setSecondaryAnchorEl(e.currentTarget);
      setSelectedType(key);
      setVariableList(vl);
    }
  };

  const handleListSecClick = (e, value) => {
    if (!value.isMenu) {
      props.handleAddVariable(selectedType + value.name);
      setSelectedVariable(selectedType + value.name);
      handleSecondaryClose();
      setAnchorEl(null);
    } else {
      setWorkItem(value.item);
      setSelectedVariable(selectedType + value.name);
      setThirdAnchorEl(e.currentTarget);
    }
  };

  const handleListThirdClick = (e, value) => {
    let val = '';
    if (value.id === 'name') {
      val = `GETUSERNAME(${selectedVariable})`;
    } else {
      val = `GETUSERPROPERTY(${selectedVariable},'${value?.id}')`;
    }
    setSelectedVariable(val);
    props.handleAddVariable(val);
    handleThirdClose();
    handleSecondaryClose();
    setAnchorEl(null);
  };

  const handleSecondaryClose = () => {
    setSecondaryAnchorEl(null);
    setSelectedType('');
    setVariableList([]);
  };

  const handleThirdClose = () => {
    setThirdAnchorEl(null);
    setSelectedSecondVar('');
    setWorkItem([]);
  };

  useEffect(() => {
    setSelectedVariable(props.selectedVariable);
    setVal(props.selectedVariable);
  }, [props.selectedVariable]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (val !== selectedVariable) {
        setSelectedVariable(val);
        props.handleAddVariable(val);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [val]);

  return (
    <>
      {props.callType && props.callType === 'condition' ? (
        <FilledInput
          style={!props.disabled ? { backgroundColor: 'white' } : {}}
          onChange={e => setVal(e.target.value)}
          value={val}
          disabled={props.disabled}
          endAdornment={
            <CellIcon
              className={classes.cursorPointer}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
          }
          disableUnderline
        />
      ) : props.callType && props.callType === 'DMN' ? (
        <FilledInput
          className={!props.disabled ? `${classes.inputField}` : ``}
          onChange={e => setVal(e.target.value)}
          value={val}
          disabled={props.disabled}
          endAdornment={
            <CellIcon
              className={classes.cursorPointer}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
          }
          disableUnderline
        />
      ) : (
        <NavButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-describedby={'add-input'}
          className={classes.addInput}
          startIcon={<AddIcon />}
          variant='contained'
          color='primary'
        >
          Add input
        </NavButton>
      )}

      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          id={'add-input-dropdown'}
          onClose={() => setAnchorEl(null)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List
            component='nav'
            aria-label='main mailbox folders'
            className={classes.sapListItem}
          >
            {primaryList.map((item, index) => {
              return (
                <ListItem
                  button
                  className={`${classes.enabledListItem} ${
                    selectedType === item.key ? 'active' : ''
                  }`}
                  onClick={(e) => handleListItemClick(e, item.key)}
                >
                  <ListItemText
                    primary={item.label}
                    className={classes.enabledItem}
                  />
                  {item.key !== "$VALUE." && <ArrowForwardOutlined />}
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
      {secondaryAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={secondaryAnchorEl}
          onClose={handleSecondaryClose}
          open={Boolean(secondaryAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {variableList?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {variableList.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListSecClick(e, item)}
                  >
                    <ListItemText
                      primary={item.name}
                      className={classes.enabledItem}
                    />
                    {item.isMenu && <ArrowForwardOutlined />}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
      {thirdAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={thirdAnchorEl}
          onClose={handleThirdClose}
          open={Boolean(thirdAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {workItem?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {workItem.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListThirdClick(e, item)}
                  >
                    <ListItemText
                      primary={item.name}
                      className={classes.enabledItem}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
    </>
  );
}
