import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import { validateValue, validateValues } from "../../../../businessFunctions/validations";
import { SchedulerActions } from "../../scheduler.action";
import { useScheduler } from "../../schedulerContext";
import styles from "./FinalStep.module.css"
import editIcon from "../../../../../images/editicon.svg";
import { getAuth } from "../../../../../auth/user";

export const FinalStep = ({ show, handleClose, loadSchedulerList }) => {
    const app = useAppContext();
    let style = { marginTop: "8px" };
    let style1 = { cursor: "pointer" };

    const [data, setData] = useState("")
    const [popupTitle, setPopupTitle] = useState("")
    const [popupTestId, setPopupTestId] = useState("")

    const { edit, setTimeBasedEdit, setTimeBased, setBospopup, setParameter,
        setTrigger, setBospopupEdit, setTriggerEdit, setParameterEdit, timeBasedSchedulerForm,setParams,parameters,
        params, freq, paramObject, isTimeBased, setCreateScheduler, createScheduler, id, prevName, isEnabled, save, setSave , setEdit } = useScheduler();

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {

        if (!validateValues(values, errors))
            return false;

        // let payload = { ...values };
        // const project = ProjectsActions(app())
        // const [error, data] = await project.createNewProject(payload);

        // if (error || !data.status?.code) {
        //     setApiError(error || data.status?.message);
        //     return false;
        // } else {
        //     handleClose();
        //     navigate(`/project/${values.name}`);
        //     return true;
        // }
    }

    const { Form, Label, Input } = useForm(data, onSubmit, onWatch);

    useEffect(() => {
        if (edit) {
            setPopupTestId("edit-schedule")
            setPopupTitle("Edit schedule")
        } else {
            setPopupTestId("confirm-schedule")
            setPopupTitle("Final step,confirm your schedule")
        }

    })

    const handleEdit1 = () => {
        handleClose()
        setTimeBased(true)
        setTimeBasedEdit(true)
        setSave(true)
    }

    const handleEdit2 = () => {
        handleClose()
        setBospopup(true)
        setBospopupEdit(true)
        setSave(true)
    }

    const handleEdit3 = () => {
        handleClose()
        setParameter(true)
        setParameterEdit(true)
        setSave(true)
    }

    const handleEdit4 = () => {
        handleClose()
        setTrigger(true)
        setTriggerEdit(true)
        setSave(true)
    }

    const CreateScheduler = async (payload) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.CreateSchedulerBasedonTime(payload);
        if (!error) {
            console.log(data)
        }
    }

    const UpdateSchedulers = async (payload, id) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.UpdateScheduler(payload, id);
        if (!error) {
            console.log(data)
        }
    }

    const handleCreate = (el) => {
        const payload = {
            bosname: params.serviceId,
            description: timeBasedSchedulerForm.description,
            eventId: null,
            freq: freq,
            isEnabled: el === "enabled" ? 1 : 0,
            isTimeBased: isTimeBased,
            name: timeBasedSchedulerForm.name,
            parameters: JSON.stringify(parameters),
            projectComponentId: params.projectComponentId,
            runAsUser: params.runAsUser,
            serviceName: params.projectName+"."+params.bosName,
            serviceType: timeBasedSchedulerForm.serviceType
        }

        CreateScheduler(payload)
        handleClose()
        setTimeout(() => setCreateScheduler(!createScheduler), 1000)
        setParams({
            projectName: "",
            bosName: "",
            serviceId: "",
            projectComponentId: "",
        })
        setSave(false)
        setEdit(false)
   
               

    }

    const handleUpdate = (el) => {

        const payload = {
            bosname: params.serviceId,
            description: timeBasedSchedulerForm.description,
            eventId: null,
            freq: freq,
            id: id,
            isEnabled: el === "enabled" ? 1 : 0,
            isTimeBased: true,
            name: timeBasedSchedulerForm.name,
            parameters: paramObject,
            projectComponentId: params.projectComponentId,
            runAsUser: params.runAsUser,
            serviceName: params.projectName+"."+params.bosName,
            serviceType: timeBasedSchedulerForm.serviceType
        }

        UpdateSchedulers(payload, prevName)
        handleClose()
        setTimeout(() => setCreateScheduler(!createScheduler), 1000)
        setParams({
            projectName: "",
            bosName: "",
            serviceId: "",
            projectComponentId: "",
            runAsUser: getAuth().auth.loginName
        })
        setSave(false)
        setEdit(false)
        

    }

    const constructParamters = () =>{
        try{
            const newParamter = {
                ...parameters,
                bosName:params.bosName,
                projectName:params.projectName,
                uuid:params.serviceId,
    
            }

            return JSON.stringify(newParamter,null,2)
        }
        catch(err){
            console.log(err);
        }
      

        
    }

   


    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={popupTestId} titleClassName={styles.finalHeader} title={popupTitle} >
            {/* <h1 className={styles.finalHeader}>
                {edit ? "Edit schedule" : "Final step,confirm your schedule"}
            </h1> */}
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        <div className={styles.finalComp}>
                            <Label text={"Name"} style={style} className={styles.label} />
                            <Label text={timeBasedSchedulerForm.name} style={style} className={styles.label2} />
                            <img src={editIcon} data-clickable-testid={"Name"} style={style1} onClick={handleEdit1} />
                        </div>
                        <div className={styles.finalComp}>
                            <Label text={"Description"} style={style} className={styles.label} />
                            <Label text={timeBasedSchedulerForm.description} style={style} className={styles.label2} />
                            <img src={editIcon} data-clickable-testid={"Description"} style={style1} onClick={handleEdit1} />
                        </div>
                        <div className={styles.finalComp}>
                            <Label text={"Type"} style={style} className={styles.label} />
                            <Label text={timeBasedSchedulerForm.serviceType} style={style} className={styles.label2} />
                            <img src={editIcon} data-clickable-testid={"Type"} style={style1} onClick={handleEdit1} />
                        </div>
                        <div className={styles.finalComp}>
                            <Label text={"BOS"} style={style} className={styles.label} />
                            <Label text={params.bosName} style={style} className={styles.label2} />
                            <img src={editIcon} data-clickable-testid={"BOS"} style={style1} onClick={handleEdit2} />
                        </div>
                        {/* JSON.stringify(parameters, null, 2) */}
                        <div className={styles.finalComp} >
                            <Label text={"Parameters"} style={style} className={styles.label} />
                            <Label text={constructParamters()} style={{...style,lineHeight:"20px",height:150,overflow:"hidden",textOverflow:"ellipsis"}} className={styles.param} />
                            <img src={editIcon} data-clickable-testid={"Parameters"} style={style1} onClick={handleEdit3} />
                        </div>
                        <div className={styles.finalComp} style={style}>
                            <Label text={"Triggers"} style={style} className={styles.label} />
                            <div style={{ width: "14rem" }}>
                                {
                                    freq.length ? freq.map((el) => {
                                        return <div key={el.id}  >

                                            {
                                                el.drp === "days" && <Label text={`Repeat Every ${el.times} ${el.drp} ${el.hr} hrs ${el.min} mins`} />
                                            }
                                            {
                                                el.drp === "weeks" && <Label text={`Repeat Every ${el.times} ${el.drp} ${el.week} ${el.hr} hrs ${el.min} mins`} />
                                            }
                                            {
                                                el.drp === "months" && <Label text={`Repeat Every ${el.times} ${el.drp} ${el.day} day ${el.hr} hrs ${el.min} mins`} />

                                            }
                                            {
                                                (el.drp === "hours" || el.drp === "minutes") && <Label text={`Repeat Every ${el.times} ${el.drp}`} />
                                            }

                                        </div>
                                    }) : ""
                                }
                            </div>

                            <img src={editIcon} data-clikable-testid={"Triggers"} style={style1} onClick={handleEdit4} />

                        </div>
                    </div>
                </div>
                <div className={styles.finalButton}>
                    {
                        !edit ? <>
                            <Input
                                type="button"
                                value={"Create without enabling"}
                                className="secondary"
                                onClick={() => handleCreate("disabled")}
                            />
                            <Input type="submit" className="primary" value={"Create and enable schedule"} onClick={() => handleCreate("enabled")} /></> :
                            <>
                                <Input
                                    type="button"
                                    value={"Save without enabling"}
                                    className="secondary"
                                    onClick={() => handleUpdate("disabled")}
                                />
                                <Input type="submit" className="primary" value={"Save and enable schedule"} onClick={() => handleUpdate("enabled")} /></>
                    }

                </div>


            </Form>
        </PopUp>
    </>
}