import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import React from 'react';
import { ContainerAppsList, createContainerApp,  ProjectTableList, BusinessFunctionList, ContainerAppDetails, PublishedLoginAppsList, UploadFile, getAppsBinary } from '../../../api/projects/deployment';
import { projectNameAndDescription, updateProjectNameAndDescription, } from '../../../api/projects/projectSettings';
import messages from '../../projects/projectsMessages';
import { readFileAsync, readFileAsyncImage } from '../../../common';

export function ProjectSettingsActions({ show, close, notify }){

  const getList = async (prjName,limit,offset,filter) =>{
    // show(()=><ActivityIndicator text={messages.loading}/>)
    const [error, data] = await ContainerAppsList(prjName,limit,offset,filter);
    // close()
    if(error)
      return [error, data];
    if(data.code >= 400)
      return [data.message, data];
    return [null,data];
  }

  const getTableList = async (prjName) => {
    const [error, data] = await ProjectTableList(prjName);
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  const getBusinessFunctionList = async (prjName, includeUniversalWorkList=true) => {
    show(()=><ActivityIndicator />)
    const [error, data] = await BusinessFunctionList(prjName, includeUniversalWorkList);
    close();
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  const getPublishedLoginAppsList = async () => {
    const [error, data] = await PublishedLoginAppsList();
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  const getContainerAppDetails = async (prjName, containerAppId) => {
    const [error, data] = await ContainerAppDetails(prjName, containerAppId);
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  const createNewCA = async (prjName, payload) => {
    // show(()=><ActivityIndicator text={messages.creatingCA}/>)
    if(payload?.genarate?!payload.genarate:true)show(()=><ActivityIndicator /*text={`updating ${payload.containerApp.name}...`}*/ />)
    const [error, data] = await createContainerApp(prjName, payload);
    close()
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }

    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }

    // notify({ message: data.status.message, type: data.status.type })
    if(payload?.genarate) notify({ message: "Successfully initiated", type: data.status.type })
    return [null, data];
  }

  const getProjectDetails = async (projectName) => {
    show(()=>(<ActivityIndicator text={"Retrieving Project Details..."} />))
    const [error, data] = await projectNameAndDescription(projectName);
    close()
    if(error)
      return [error, data];
    if(data.code >= 400)
      return [data.message, data];
    return [null,data];
  }

  const updateProjectDetails = async (projectName, payload) => {
    show(()=>(<ActivityIndicator text={"Updating Project Details..."} />))
    const [error, data] = await updateProjectNameAndDescription(projectName, payload);
    close()
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }

    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }

    // notify({ message: data.status.message, type: "S" })
    return [null, data];
  } 

  const uploadFile = async (prjName, containerAppId, comment, fileName,blob) => {
    show(()=>(<ActivityIndicator text={`Uploading ${fileName}...`} />))
    const [error, data] = await UploadFile(prjName, containerAppId, comment, fileName, blob)
    close()
    if (error)
      return [error, null];
    if (data.code >= 400)
      return [data.message, null];

    if (data.status) {
      // notify(data.status);
    }
    return [null, data];
  }

  const getApps = async (deviceType, containerAppId) => {
    const [error, data] = await getAppsBinary(deviceType, containerAppId)
    console.log(data)
    if(error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if(data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    notify({ message: data.status.message=="Success"?"App successfully downloaded":data.status.message, type: "S" })
    return [null, data];
  }
  
  return {
    getList, createNewCA, getProjectDetails, updateProjectDetails,
    getTableList, getBusinessFunctionList, getPublishedLoginAppsList, 
    getContainerAppDetails, uploadFile, getApps
  }
}