import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  FilledInput,
  NativeSelect,
} from '@material-ui/core';
import { SaveAlt, Check } from '@material-ui/icons';
import Table from '../../../../../common/components/Table';
import { configMessage } from '../../../../../common/messages/config';
import AutoComplete from '../../../../../common/components/AutoComplete';

import Dialog from '../../../../../views/dialog';

export default function (props) {
  const {
    showDialog,
    dialogType,
    classes,
    dispatch,
    data,
    handleSelect
  } = props;
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    if (data) {
      setInputData(data);
    }
  }, [data]);

  const clearForm = () => {};

  const submitForm = async () => {
    if (true) {
      let response = {};
      dispatch({ type: 'showAlert', showAlert: true, alertMsg: response });
      setTimeout(
        () => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }),
        3000
      );
      if (response.status === 'success') {
        clearForm();
        dispatch({ type: 'showDialog', value: !showDialog });
      }
    }
  };
  const handleSubmit = () => {

  }

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {"User List"}
        </Typography>
      </Box>
    );
  };

  const container = () => {
    return (
      <>
        <Grid sm={12} className={classes.modalContainer}>
            <AutoComplete 
                selectedRole={props.owner}
                setRole={props.setOwner}
                options={props.options}
                // handleSelectValue={}
            />
        </Grid>
      </>
    );
  };

  const modalFooter = () => {
    return (
      <Box className={classes.footerContainer} sm={12}>
        <Grid
          sm={12}
          style={{ display: 'flex' }}
          alignItems='center'
          justify='flex-end'
        >
          <Button color='primary' variant='contained' onClick={handleSelect}>
            Save
          </Button>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialog}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      onClose={props.onClose}
    />
  );
}
