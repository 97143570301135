import { validate, required, validateName, validateLink } from '../../../../../../common/validations/Validations';
import projectMessages from '../../../../../../common/validationMessages/project';

const validateValue = (errors, name, value) => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    switch(name){
        case "cameraDescription":
            if(value=="") {
                msg = "Please enter the description";
                isValid = required(errors,name,value,msg)
            }
            else {
                isValid = required(errors, name, value)
            }
            break;
        case "gpsDescription":
            if(value=="") {
                msg = "Please enter the description";
                isValid = required(errors,name,value,msg)
            }
            else {
                isValid = required(errors, name, value)
            }
            break;
        case "contactsDescription":
            if(value=="") {
                msg = "Please enter the description";
                isValid = required(errors,name,value,msg)
            }
            else {
                isValid = required(errors, name, value)
            }
            break;
        case "bluetoothDescription":
            if(value=="") {
                msg = "Please enter the description";
                isValid = required(errors,name,value,msg)
            }
            else {
                isValid = required(errors, name, value)
            }
            break;
        case "externalLink":
            if(value!==""&&value!==undefined&&value?.length!==0){
                msg = "Invalid Link";
                isValid = validateLink(errors,name,value,msg)
            }
            else {
                isValid = validateLink(errors, name, value, msg)
            }
            break;
        default:
            if(value!==""&&value!==undefined&&value?.length!==0){
                msg = "Invalid Link";
                isValid = validateLink(errors,name,value,msg)
            }
            else {
                msg = "random"
                isValid = required(errors, name, !value, msg)
            }
            break;
    }
    return isValid;
}

const validateValues = (values, errors, fields) => {
    let validate = [];
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if(fields.includes(name)){
            validate.push(validateValue(errors,name,value))
        }
    })
    return validate.includes(false);
}

export {validateValue, validateValues}
