import React ,{ useEffect, useState } from "react";
import Pagination from "../../components/Pagination/pagination";
import Table from "../../components/Table/table";
import { useAppContext } from "../../components/activity/AppContext";

function TableList(props) {

    const [pagination, setPagination] = useState({});
    const [dataCount, setDataCount] = useState("");
    const [tableHeader, setTableHeader] = useState([]);
    const [data, setData] = useState([]);
    const [err, setErr] = useState(false);
    const app = useAppContext(); 

    const {
        list = {}
    } = props;

    useEffect(() => { 
        let headers = [];
        let tData = [];
        if(list && list.list)
        {
           
            list.list.forEach((rec, index1) => {
                let  json = {};
                list.columns.map((key, index) => {
                json[key] = rec[index];
                });
                tData.push(json);
            });
            setData(tData);
            setDataCount(tData.length);
            setPagination({ offset: 0, limit: 10 });
            
            list.columns.map((key, index) => {
                headers.push({
                    name: key, id: key, type: "text"
                });
            });
            setTableHeader(headers);
            setErr(false);
        }
        else if(list.error)
        {
            // console.log('errored msg',list)
            // app().notify({ message: list.errmsg, type: "E" });
            setErr(true);
        }
        if(headers.length==0){
            headers.push({name: '', id: '', type: "text"})
            setTableHeader(headers);
        }
    },[list]);
    
    if(!err)
    {
        return (
            <>
                <Table
                    data={data || []}
                    headerColumns={tableHeader}
                    name={props.name}
                />
                {/* {list.list && data.length === 0 && <div style={{ textAlign: "center", marginTop: "30px" }}><label>No records</label></div>} */}
                {/* {data.length > 10 && 
                    <Pagination
                        displayRows={pagination}
                        userDataCount={dataCount}
                        setDisplayRows={setPagination} 
                    />
                } */}
            </>
        )
    }
    return null;
}

export default TableList;