import { exportTableData } from "../../api/library/database-tables/database";
import { ModuleTitle } from "../../components/module-title";
import React, { useEffect, useState } from "react";
import Tree from "../../components/tree/tree";
import styles from "./database.module.css";
import Tabs from "../../components/tab/tabs";
import TableList from "./table";
import ProjectShare from "../../components/share";
import { useAppContext } from "../../components/activity/AppContext";
import SmallPopup from "../../components/layouts/smallPopup";
import { useParams } from "react-router-dom";
import ImportTable from "./importTable";
import { messages } from "./messages";
import Query from "./query";
import CreateTable from "./createTable";
import { DatabaseActions } from "./database.actions";
import ProjectShareDatabase from "../../components/share/ProjectShareDatabase";

function Database() {

    
    const [list, setList] = useState([]);
    const [sharedList, setSharedList] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [tabsList, setTabsList] = useState([{ name: `Query_0`, id: 'Query_0', type: 'Query', query: "" , data: [], canClose: true}]);
    const [tableName, setTableName] = useState("");
    const [tabsListUsed, setTabsListUsed] = useState(['0']);
    const [tabsListUnUsed, setTabsListUnUsed] = useState(['4','3','2','1']);
    const [openPermission, setOpenPermission] = useState(false);
    const [sharedTableDetail, setSharedTableDetail] = useState();
    const [sharedTableName,setSharedTableName] = useState() ;
    const rowDefault = { id: 1, columnName: "", dataType: '', dataLength: 0, primary: false, notNull: false, default: "", autoIncrement: false, comments: "" };
    const indicesRowDefault = { id: 1, name: "", column: '', refTables: '', refColumns: '', refTablesValues:[],refColumnsValues:[], actualColValues:[]};
    const [closeTable, setCloseTable] = useState({ name: "", close: false, index:-1 });
    const { id: pName } = useParams();
    const app = useAppContext();   
    const [openImport, setOpenImport] = useState(false);
    const [deleteTable, setDeleteTable] = useState({ name: "", delete: false });
    const listModifyOptions = ["Get Records", "Alter", "Delete", "Share", "Import", "Export"];
    const listModifyOptionsLibrary = ["Get Records", "Export"];//,"Permissions"
    const [tableNameList, setTableNamesList] = useState([]);
    const [sharedData,setSharedData] = useState([])
    const [perm,setPerm] = useState("")
    let projectName = '';
    if(pName){
        projectName=pName
    }else{
        listModifyOptionsLibrary.push("Permissions")
    }


    useEffect(() => {
       getList();
       getSharedList();
    },[]);
    
    
    useEffect(() => {
        // console.log(tabsList);
    }, [tabsList]);

    const setTableNameList = (list) =>{
        setTableNamesList((prev) => ([...(new Set([ ...prev , ...list]))]))
    }
    
    const getList = () => {
        const getAll = async () => {
            const database = DatabaseActions(app());
            let [err, data] = await database.getAllTables(projectName);
            if (!err && !data.error) {
                let tables = [];
                let tableNames = [];
                data.data.map((table, index) => {
                    if(table.name != tableName)
                        tableNames.push(table.name);
                    tables.push({
                        name: table.name, isLeaf: false, children: [
                            // { name: "Columns", isLeaf: false, children: [], tableName: table.name, projects: table.projects,  }
                        ], projects: table.projects, tableName: table.name, load: getColumns
                    });
                })
                setTableNameList(tableNames);
                setList(tables);
            }
        }
        getAll();
    }

     const getSharedList = () => {
        const getSharedAll = async () => {
            const database = DatabaseActions(app());
            let [err, data] = await database.getSharedTables(pName);
            if (!err && !data.error) {
                let tables = [];
                let tableNames = [];
                data.data.map((table, index) => {
                    if(table.name != tableName)
                        tableNames.push(table.name);
                    tables.push({
                        name: table.name, isLeaf: false, children: [
                            // { name: "Columns", isLeaf: false, children: [], tableName: table.name, projects: table.projects, load: getSharedColumns }
                        ], projects: table.projects, tableName: table.name, load: getSharedColumns,isLib:true
                    });
                })
                setTableNameList(tableNames);
                setSharedList(tables);
            }
        }
        getSharedAll();
    }

    const getSharedColumns = async (table, l) => {
        const database = DatabaseActions(app());
        let [err, data] = await database.getTableColumns(table.tableName);
        let _tables = l.map((tab) => {
            if (tab.name == table.tableName) {
                tab.children = data.totalColumn;
            }
            return tab;
        });
        setSharedList(_tables);
    }

    const getColumns = async (table, l) => {
        const database = DatabaseActions(app());
        let [err, data] = await database.getTableColumns(table.tableName);
        let _tables = l.map((tab) => {
            if (tab.name == table.tableName) {
                tab.children = data.totalColumn;
            }
            return tab;
        });
        setList(_tables);
    }

    // const getTableColumns = async (table, l) => {
    //     const database = DatabaseActions(app());
    //     let [err, data] = await database.getTableColumns(table.tableName);
    //     let _tables = l.map((tab) => {
    //         if (tab.name == table.tableName) {
    //             tab.children[0].children = data.totalColumn;
    //         }
    //         return tab;
    //     });
    //     return _tables
    // }

    const addTab = () => {
       if(tabsListUnUsed.length === 0) {
            app().notify({ message: messages.maxTabErr, type: "E" }); 
            return -1;
        }
        else{
            let count = tabsListUnUsed.pop();
            setTabsListUnUsed(tabsListUnUsed);
            let tabName = `Query_${count}`;
            tabsListUsed.push(count);
            setTabsListUsed(tabsListUsed);
            return tabName;
        }

    }

    // const removeTab = (tab) => {
    //     let count = tab.split('Query_')[1];
    //     tabsListUnUsed.push(count);
    //     setTabsListUnUsed(tabsListUnUsed);
    //     const index = tabsListUsed.indexOf(count);
    //     if (index > -1) 
    //         tabsListUsed.splice(index, 1);
    //     setTabsListUsed(tabsListUsed);
    // }

    const getRecords = async (value) => {
        let tList = {};
        let tabName = addTab();
        if(tabName && tabName != -1)
        {
            let project = projectName? projectName : 'null';
            let q = `select * from ${value.name}`;
            tList = { name: `${tabName}`, id: tabName,  type: 'Query', query: q, data: [], canClose: true, canExecute:true,isLib:value.isLib};
            setTabsList((prev) => ([ ...prev, tList ]));
            setActiveTab(`${tabName}`);
        }
    }
    
    const alterTable = async (value) => {
        let table = value.name;
        let project = value.projects ? (value.projects).split(',')[0] : 'services_workspace';
        let tList = {};
        let tableList = tabsList.filter(tab =>  tab.type === 'Table');
        if (tableList.length === 0)
        {
            tList = { name: 'Table', id: 'Table', type: 'Table', tableName: table, data: '', canClose: true,isNew:false};
            setTableName(table);
            setTabsList((prev) => ([ ...prev, tList ]));
            setActiveTab('Table');
        }else{
            app().notify({ message: messages.tabOpenErr, type: "W" });  
        }
        
    }

    const handleMenuClick = (e, value) => {
        switch (e) {
            case 'Get Records':
                getRecords(value);
                break;
            case 'Alter':
                alterTable(value);
                break;
            case 'Delete':
                setDeleteTable({name:`${value.name}`,delete:true});
                break;
            case "Share":
                shareTable(value);
                break;
            case "Import":
                importTable(value);
                break;
            case "Export":
                exportTable(value);
                break;
            case "Permissions":
                setPerm(value.name)
                openPermissionDialog(value.name)
                break;
        }
    }

    const openPermissionDialog = async (name,filter)=>{
        if(name){
            const database = DatabaseActions(app());
            const [err,data] = await database.getTableLibraryDetail(name,filter);
            // console.log(err,data)
            if(!err){
                setSharedData(data?.data)
                setSharedTableDetail(data?.detail?.projectList)
                setOpenPermission(true)
                setSharedTableName(name)
            }
        }
    }

    const saveSharedProjects = async (sharedProjects) =>{
        const database = DatabaseActions(app());
        const [err,data] = await database.updateTablePermission(sharedTableName,sharedProjects);
        if(!err){
            // setOpenPermission(false)
        }
    }

    const handleCancelTab = (index) => {
        setCloseTable({ name: "", close: true, index:index });
    }
    const handleTabClose = (index,value) => {
        if(value === 'Table')
        {
            setCloseTable({ name: "", close: true, index:index });
        }
        else
        handleTabCloseWithoutCheck(index,value);
    }
   
    const handleTabCloseWithoutCheck = ( i,value) => {
        const tempList=[...tabsList];
        tempList.splice(i,1);
        const length = tempList.length
        let flag = true
        if(tempList.length === 0) {
            tempList.push({ name: `Query_0`, id: 'Query_0', type: 'Query', query: "" , data: [], canClose: true});
            flag=false;
        }
        setActiveTab(tempList[0].id);
        setTabsList(tempList);
        if(value === 'Table') {
            setCloseTable({ name: "", close: false,index:i });
            //resetTableForm();
        } else {
            let idx = value.substring(value.lastIndexOf('_')+1)
            if(length===0 && idx!=='0'){
                let unUsed = tabsListUnUsed.filter(val => val!='0')
                unUsed.push(idx)
                setTabsListUnUsed([...unUsed])
                let used = ['0']
                setTabsListUsed([...used])
            }else if(flag){
                let unUsed = [...tabsListUnUsed]
                unUsed.push(idx)
                setTabsListUnUsed([...unUsed])
                let used = tabsListUsed.filter(val => val!=idx)
                setTabsListUsed([...used])
            }
        }
    }

    const handleTabOpen = () => { 
        
        let tabName = addTab();
        if(tabName!=-1){
            let tList = { name: `${tabName}`, id: `${tabName}`, type: 'Query', query: "" , data: [], canClose: true};
            setTabsList((prev) => ([ ...prev, tList ]));  
            setActiveTab(tabName); 
        }
    }

    const handleAddTable = () => { 
        if(tabsList.length < 5)
        {
            let tableList = tabsList.filter(tab =>  tab.type === 'Table');
            if (tableList.length === 0)
            {
                let tList = { name: 'Table', id: 'Table', type: 'Table', tableName: '', data: '', canClose: true,isNew:true};
                setTabsList((prev) => ([ ...prev, tList ]));
                setActiveTab('Table');
            }else{
                app().notify({ message: messages.tabOpenErr, type: "E" });
            }
        }   
        else{   
            app().notify({ message: messages.maxTabErr, type: "E" });  
        }
    }

    const updateQuery = (index,val) => { 
        tabsList[index].query=val;
    }

    const importTable = async (value) => {
        setOpenImport(true);
        setTableName(value.name);
    }

    const exportTable = async (value) => {
        const database = DatabaseActions(app());
        await database.getTableExport(value.name,projectName);
    }

    const shareTable = async (value) => {
        const database = DatabaseActions(app());
        let [err,data] = await database.shareTableData(value.name,projectName);
        if(!err && data === null)
            getSharedList();
        else if(!err && !data.error || data.status != 'Error')
            getSharedList();

    }

    const handleDeleteTable = async (value) => {

        const database = DatabaseActions(app());
        let [err,data] = await database.deleteTableData(value,projectName);
        setDeleteTable({name : "", delete:false});
        if(!err && data === null){
            getList();
            getSharedList();
        }else if(!err && !data.error || data.status != 'Error'){
            getList();
            getSharedList();
        }
    }
            
    return (
        <>
            <div className={styles.databaseCon}>
                <div className={styles.afModule}>
                    <Tabs tabsClassName={"left"} canOpen={false}>
                        <div label="Tables" tId="Tables" name={"Tables"} className="tabbackground" sid={{width:'50%'}}>
                            <div className={styles.tree}>
                                {<Tree data={list} name={"Tables"} options={listModifyOptions} handleMenuClick={handleMenuClick} />}
                            </div>
                            <div className={styles.addButtonContainer}>
                                <input data-clickable-testid={"addTable"} className={`primary ${styles.addButton}`} type="submit" value={messages.addTable} onClick={handleAddTable} />
                            </div>
                        </div>
                        <div label={messages.library} tId="Library" name={"Library"} sid={{width:'50%'}}>
                            <div className={styles.tree}>
                                {<Tree data={sharedList} name={"Library"} options={listModifyOptionsLibrary} handleMenuClick={handleMenuClick} />}
                            </div>
                        </div>
                    </Tabs>
                </div>
                <div className={styles.gridCon}>
                    <div className={styles.gridInnerCon}>
                    {closeTable.close
                            ? <SmallPopup
                                popUp={closeTable.close}
                                title={messages.closeTable}
                                subtitle={messages.saveTableErr}
                                handlePopUp={() => setCloseTable({ name: "", close: false,index:-1 })}
                                handleSubmit={() => handleTabCloseWithoutCheck(closeTable.index,'Table')}
                                buttonName1={'Cancel'}
                                buttonTestId1={'CloseNo'}
                                buttonName2={'Close'}
                                buttonTestId2={'CloseYes'}
                                />
                            : ""}
                    {deleteTable.delete
                    ? <SmallPopup
                        popUp={deleteTable.delete}
                        title={messages.deleteTable}
                        subtitle={messages.deleteConfirmationText}
                        handlePopUp={() => setDeleteTable({ name: "", delete: false })}
                        handleSubmit={() => handleDeleteTable(deleteTable.name)} 
                        />
                    : ""}
                
                  <Tabs activeTab={activeTab || false}  handleTabClose={handleTabClose} canOpen={true} handleTabOpen={handleTabOpen}>
                            {tabsList && tabsList.length > 0 && tabsList.map((item, index) => {
                               if(item.type === messages.query)
                               {
                                //    console.log("ib ",item);
                                    return (
                                    <div label={item.name} tId={item.name} index={index}  canClose="true">
                                      <Query 
                                        item={item} 
                                        index={index} 
                                        handleQueryTab={updateQuery} 
                                        projectName={projectName}
                                    />
                                    </div>);
                               }else if(item.type === 'Table'){
                                   return (
                                    <div label='Table' tId={item.name} index={index} canClose="true">
                                     <CreateTable 
                                        index={index}
                                        item={item} 
                                        tableNameList={tableNameList} 
                                        projectName={projectName} 
                                        getList={getList} 
                                        handleCancelTab={handleCancelTab}  
                                        handleTabCloseWithoutCheck={handleTabCloseWithoutCheck}
                                    /></div>);
                               }
                            }) }
                        </Tabs> 
                    </div>
                    <ImportTable
                        setShow = {setOpenImport}
                        projectName={projectName}
                        tableName = {tableName}
                        show = {openImport}
                    />
                </div>
            </div>
            <ProjectShareDatabase popUp={openPermission} setPopUp={setOpenPermission} data={sharedTableDetail} saveSharedProjects ={saveSharedProjects} name={sharedTableName} loadData={openPermissionDialog} sharedData={sharedData} perm={perm} />
        </>
    );
}

export default Database;