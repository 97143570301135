import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import styles from './configuration.module.css'
import configMessages from './configMessages'
import SmallPopup from '../../../../../../components/layouts/smallPopup';
import { validateValue, validateValues} from './validations';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import { useAppContext } from '../../../../../../components/activity/AppContext';


function Configuration({ pageNumber, handleClose, rolePopUp, setContainerAppId, containerAppDetails, setContainerAppDetails, projectName, resetCADetails }) {
    const app = useAppContext()
    const [containerApp, setContainerApp] = useState(
        {...containerAppDetails.containerApp}
    )
    const [hadGeneratedBefore,] = useState(containerAppDetails?.containerApp?.otaUrl!==undefined?containerAppDetails.containerApp.otaUrl!==""?true:false:false)
    const [initialDeviceType,] = useState(containerAppDetails?.containerAppBinaries?.map(e => e.deviceType)||[])
    const [containerAppName, setContainerAppName] = useState(containerAppDetails.containerApp.name)
    const [deviceType, setDeviceType] = useState(containerAppDetails.containerApp.deviceType.length?containerAppDetails.containerApp.deviceType.split(","):[])
    const [hadEdit, setHadEdit] = useState(0)
    const [pwaShowMore, setPwaShowMore] = useState(false)
    const [hybridShowMore, setHybridShowMore] = useState(false)
    const [nativeShowMore, setNativeShowMore] = useState(false)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)
    const inlineStyle = {
        checkbox:{marginTop:"0px", marginRight:"9px"}
    }

    const handleShowMore = ( item, view ) => {
        switch(item) {
            case "pwa":
                setPwaShowMore(!pwaShowMore);
                break;
            case "hybrid":
                setHybridShowMore(!hybridShowMore);
                break;
            case "native":
                setNativeShowMore(!nativeShowMore);
                break;
            default:
                break;
        }
    }

    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }
    
    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const handleBack = () => {
        pageNumber(0)
        handleClose(false) //toclosepopup
        resetCADetails()
    }

    const buildNumberChange = () => {
        function updateBuildNumber() {
            let existingBuildCertificate = containerAppDetails?.containerAppCertificates.find((item)=>item.kind==="BUILD_NUMBER")
            existingBuildCertificate.certificate = Number(existingBuildCertificate.certificate)+1
                return existingBuildCertificate
        }
        return containerAppDetails?.containerApp?.status
                ? containerAppDetails?.containerApp.status !== "Draft"
                ? {containerAppCertificates:
                    [...containerAppDetails.containerAppCertificates.filter((item)=>item.kind!=="BUILD_NUMBER"),
                    updateBuildNumber()]
                 }
                :{}
                :{}
    }

    const createContainerApp = async (value) => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.createNewCA(projectName, {...containerAppDetails,containerApp:value, ...buildNumberChange()})
        if(!err){
            if(data?.uuid){
                setContainerAppId(data.uuid)
            }
            const [err2, data2] = await prjAction.getContainerAppDetails(projectName, data?.uuid||containerApp?.id)
            if(!err2) {
                setContainerAppDetails({...data2.detail, genarate:false})
    }

            if(data.status.type!=="E") saveChangesPopup?closePopup(false):pageNumber(1)
            else {
                if(data.status.message.includes("E4091")) {
                    errors.set("name", data.status.message)
                }
            }
        }
    }

    const needBuildNumberChange = () => {
        if(containerApp?.status) {
            if(containerApp?.status!=="Draft") return true
            else return false
        } else {
            return false
        }
    }

    const onSubmit = (values, errors) => {
        values = {...values,deviceType:deviceType.join(",")}
        let fields = getValidationFields(values.type);
        let result = validateValues(values, errors, fields)
        if(!result) {
            if(!hadEdit&&!needBuildNumberChange()) {pageNumber(1);return}
            setContainerApp((prev)=>({...prev,...values, deviceType:deviceType.join(',')}))
            createContainerApp({...containerApp,...values})
        }

    }

    const onWatch = (errors, values) => {
        setHadEdit(1)
        let newValues = [...deviceType]
        if(values.name=="name") {
            values.value = values.value.replace(/[^\w\s]+/g, '')
            setContainerAppName(values.value)
        }
        else if(values.name=="appType") {
            
            if(values.value!=="hybrid") {
                setDeviceType(['Web'])
                setContainerApp((prev) => ({...prev, deviceType:"Web", appType:values.value, name:containerAppName}))
            } else {
                setDeviceType(["Android"])
                // setContainerApp((prev) => ({...prev, deviceType:"Android", appType:"hybrid", name:containerAppName}))
                setContainerApp((prev) => ({...prev, deviceType:"Android", appType:"hybrid", name:containerAppName}))
            }
        }
        else if(values.name=="deviceType"){
            if (values.checked) {
                newValues = [...deviceType, values.value]
                setDeviceType([...deviceType, values.value])
            } else {
                newValues = deviceType.filter((item)=>item!==values.value)
                deviceType.filter((item)=>item!==values.value)
                setDeviceType(deviceType.filter((item)=>item!==values.value))
            }
        }
        else if(values.name=="caType"){
            setContainerApp((prev) => ({...prev, caType:values.value}))
        }
        validateValue(errors, values.name, values.name!=="deviceType"?values.value:newValues.join(","))
    }

    const { Form, Label, Input, Error, Select, Radio, errors, Checkbox, submitHandler } = useForm(containerApp, onSubmit, onWatch);

    const getValidationFields = (type) => {
        return Object.keys(containerApp);
    }

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"configutation"}
            title="Do you want to deploy a new application?"
            subtitle="This enables your end-users to access your new application via the deployment platform of your choice."
                >
            <Form>
                <div className={styles.formContent}>
                    <div className={styles.formField}>
                        <Label text="Name" htmlFor={"name"}/>
                        <Input type="text" style={{ width: "100%" }} id="name"
                            name={"name"} autoComplete="off" maxLength={"45"}/>
                        <Error name={"name"} />
                    </div>
                    <div className={styles.formField}>
                        <Label text="Type" htmlFor={"appType"}/>
                        <div className={styles.radioField}>
                            <Radio name={"appType"} type="radio" testId={"webApp"} value={"webApp"} className={styles.radio} disabled={hadGeneratedBefore} />
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"webApp_title"} style={{color:`${!hadGeneratedBefore?"black":""}`}}>Web App</p>
                                <p className={styles.radioDesc} data-text-testid={"webApp_description"} >{configMessages.T1007} </p>
                            </div>
                        </div>
                        <div className={styles.radioField}>
                            <Radio name={"appType"} type="radio" testId={"pwa"} value={"pwa"} className={styles.radio} disabled={true} /*disabled={hadGeneratedBefore}*/ />
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"pwa_title"} /*style={{color:`${!hadGeneratedBefore?"black":""}`}}*/>Progressive Web App</p>
                                <p className={styles.radioDesc} data-text-testid={"pwa_description"} >{pwaShowMore?configMessages.T1001:configMessages.T1002}
                                <Link className={styles.showMoreLink} data-clickable-testid={"pwa_description_toggle"} onClick={()=>handleShowMore("pwa", pwaShowMore)}>{pwaShowMore?"show less":"show more"}</Link>
                                </p>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className={styles.formField}> */}

                        <div className={styles.radioField}>
                            <Radio type="radio" name={"appType"} testId={"hybrid"} value={"hybrid"} className={styles.radio} disabled={hadGeneratedBefore}/>
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"hbrid_title"} style={{color:`${!hadGeneratedBefore?"black":""}`}}>Hybrid</p>
                                <p className={styles.radioDesc} data-text-testid={"hybrid_description"}>{hybridShowMore?configMessages.T1003:configMessages.T1004}
                                <Link className={styles.showMoreLink} data-clickable-testid={"hybrid_description_toggle"} onClick={()=>handleShowMore("hybrid", hybridShowMore)}>{hybridShowMore?"show less":"show more"}</Link>
                                </p>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className={styles.formField}> */}

                        <div className={styles.radioField}>
                            <Radio type="radio" name={"appType"} testId={"Native"} value={"Native"} disabled={true} className={styles.radio} />
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"native_title"}>Native (coming Soon)</p>
                                <p className={styles.radioDesc} data-text-testid={"native_description"}>{nativeShowMore?configMessages.T1005:configMessages.T1006} 
                                <Link className={styles.showMoreLink} data-clickable-testid={"native_description_toggle"} onClick={()=>handleShowMore("native", nativeShowMore)}>{nativeShowMore?"show less":"show more"}</Link>
                                </p>
                                </div>
                        </div>
                    </div>
                    <div className={styles.formField}>
                        <Label text="Distribution" htmlFor={"caType"}/>
                        <div className={styles.radioField}>
                            <Radio type="radio" name={"caType"} testId={"enterprise"} value={"enterprise"} className={styles.radio} />
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"enterprise_title"} style={{color:"black"}}>Enterprise</p>
                                <p className={styles.radioDesc} data-text-testid={"enterprise_description"}>Application can be deployed through MDM and OTA url</p></div>
                        </div>
                    </div>
                    <div className={styles.formField}>
                        <div className={styles.radioField}>
                            <Radio type="radio" name={"caType"} testId={"appstore"} value={"appstore"} className={styles.radio} />
                            <div className={styles.radioWrapper}>
                                <p className={styles.radioTitle} data-text-testid={"appstore_title"} style={{color:"black"}}>App Store</p>
                                <p className={styles.radioDesc} data-text-testid={"appstore_description"}>Application can be deployed to Appstore/Playstore</p></div>
                        </div>
                    </div>
                    <div className={styles.formField}>
                        <Label text="Platform" htmlFor={"deviceType"}/>
                        <div className={styles.checkboxWrapper}>
                        {!["pwa","webApp"].includes(containerApp.appType)&&
                            <div className={styles.checkboxField}>
                                <Checkbox type="checkbox" name="deviceType" testId={"iOS"} value={"iOS"} style={inlineStyle.checkbox} selecteditem={deviceType} disabled={hadGeneratedBefore}/>
                                <Label text="iOS" style={{color:`${!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)"}`}}/>
                            </div>}
                        {!["pwa","webApp"].includes(containerApp.appType)&&
                            <div className={styles.checkboxField}>
                                <Checkbox type="checkbox" name="deviceType" testId={"Android"} value={"Android"} style={inlineStyle.checkbox} selecteditem={deviceType} disabled={hadGeneratedBefore}/>
                                <Label text="Android" style={{color:`${!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)"}`}}/>
                            </div>}
                        {!["pwa","webApp"].includes(containerApp.appType)&&
                            <div className={styles.checkboxField}>
                                <Checkbox type="checkbox" name="deviceType" testId={"Windows"} value={"Windows"} style={inlineStyle.checkbox} disabled={true}/>
                                <Label style={{color:"rgba(0, 0, 0, 0.38)"}} text="Windows (Coming Soon)" />
                            </div>}
                        {["pwa","webApp"].includes(containerApp.appType)&&
                            <div className={styles.checkboxField}>
                                <Checkbox type="checkbox" name="deviceType" testId={"Web"} value={"Web"} style={inlineStyle.checkbox} selecteditem={deviceType} disabled={true} /*disabled={hadGeneratedBefore}*//>
                                <Label text="Web" /*style={{color:`${!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)"}`}}*/ style={{color:"rgba(0, 0, 0, 0.38)"}}/>
                            </div>}
                        </div>
                        <Error name="deviceType"/>
                    </div>
                </div>
                <div className='afPopupFooter'>
                    <div>
                        <Input data-clickable-testid="Cancel" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Cancel"} onClick={handleBack} />
                        <Input type="submit" value={"Next"} />
                    </div>
                </div>
            </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>    
    )
}

export default Configuration