import React, { useEffect, useState } from "react";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import Table from "../../../../../components/Table/table";
import { validateValue, validateValues } from "../../../../businessFunctions/validations";
import styles from "./Loglist.module.css"
import clock from "../../SchedulerAssets/clock.png"
import log from "../../SchedulerAssets/log.png"
import Pagination from "../../../../../components/Pagination/pagination";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { SchedulerActions } from "../../scheduler.action";

export const Loglist = ({ show, handleClose, schedulerId, handleImagePopup }) => {
    let style = { marginTop: "8px" };

    const app = useAppContext();

    const [data, setData] = useState([])

    const [tableData, settableData] = useState([]);

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {

        if (!validateValues(values, errors))
            return false;
    }

    const { Form, Label, Input, Error, TextArea, Select } = useForm(data, onSubmit, onWatch);

    const tableLog = [
        { id: "schedulerName", name: "Name", type: "input", disabled: true },
        { id: "scheduleAt", name: "Schedule At", type: "input", disabled: true },
        { id: "lastExecuted", name: "Last Executed", type: "input", disabled: true },
        { id: "report", name: "Report", toggleImage: true, image1: clock, image2: log, handleImagePopup: handleImagePopup },
    ]

    const [loglist, setLoglist] = useState({
        flag: "",
        id: "",
        lastExecuted: "",
        scheduleAt: "",
        scheduleId: "",
        schedulerName: "",
        status: ""
    })

    const [totalRecords, setTotalRecords] = useState(0)
    const [displayLogRows, setDisplayLogRows] = useState({ offset: 0, limit: 10 })

    const loadSchedulerLogList = async () => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetSchedulerLoglist(displayLogRows.offset, displayLogRows.limit, schedulerId, loglist)
        if (!error) {
            setTotalRecords(data.noOfRecords)
            // setData(data.data)
            settableData(data.data)
        }
    }

    const onChange = (key, value) => {
        let schedulerLoglist = { ...loglist, [key]: value }
        setLoglist(schedulerLoglist)
    }

    useEffect(() => {
        loadSchedulerLogList()
    }, [displayLogRows, loglist])


    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"scheduler-log-list"} >
            <h1 className={styles.logHeader} data-text-testid="title">
                Scheduler Log List
            </h1>
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>

                        <div className={styles.logTables}>
                            <Table
                                data={tableData}
                                headerColumns={tableLog}
                                handleHeaderInputChange={(id, value) => onChange(id, value)}
                                handleHeaderSelectChange={(id, value) => onChange(id, value)}
                                name={"scheduler-log-list"}
                            />
                        </div>

                        <Pagination
                            userDataCount={totalRecords}
                            displayRows={displayLogRows}
                            setDisplayRows={setDisplayLogRows} />
                    </div>
                </div>

                <div className={styles.afFooter}>
                    <Input type="submit" className="primary" value={"Cancel"} onClick={handleClose} />
                </div>
            </Form>
        </PopUp>
    </>
}