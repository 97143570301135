import React, { useState } from 'react'
import styles from './pwaConfiguration.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function PwaConfiguration ({ projectName, containerAppId, pageNumber, handleClose, rolePopUp, containerAppDetails, setContainerAppDetails, resetCADetails}) {
    
    const initialData = {
        apiKey: "",
    }

    const mapInitialData = (initialData) => {
        if( containerAppDetails.containerAppCertificates != undefined){
            let dataObj = initialData
            containerAppDetails.containerAppCertificates.map( e => {
                if (e.kind == "API") 
                    dataObj.apiKey = e.certificate
            })
            return [dataObj]
        }
    }
    const [dataObj] = mapInitialData(initialData)
    const [data, setData] = useState(dataObj)
    const [pwaData, setPwaData] = useState(data)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)

    const kind = {
        apiKey: "API",
    }

    const app = useAppContext()
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false) 

    const updateContainerAppDetails = async (newValues, errors) => {
        if(clickedBack || saveChangesPopup) { /* write the draft changes condition */
            const prjAction = ProjectSettingsActions(app());
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
            if(!err){
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...data2.detail, genarate:false})
                    }

                if(data.status.type!=="E") {
                    if(!saveChangesPopup) {
                        pageNumber(3)
                    }else closePopup(false)
                }
            }
        }
        else {
            const prjAction = ProjectSettingsActions(app());
            pageNumber(7)
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues, genarate:true})
            if(!err) {
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...containerAppDetails,...data2.detail})
                    }

                if(data.status.type!=="E") {
                    // pageNumber(8)
                    closePopup(false)
                }
            }
            else {
                closePopup(false)
            }

        }

    }

    const onSubmit = async (values, errors) => {
        let updateApiKey = {certificate:values.apiKey, fileName:values.apiKey, kind: kind.apiKey, password:""}
        let newValues = {...containerAppDetails,
                            containerAppCertificates:[
                                ...containerAppDetails["containerAppCertificates"].filter(e => !Object.values(kind).includes(e.kind)),
                            ]}
        if(updateApiKey.fileName) newValues = {...newValues, containerAppCertificates:[...newValues["containerAppCertificates"], updateApiKey]}
        updateContainerAppDetails(newValues, errors)
        // pageNumber(7)
    }

    const onWatch = async (errors, values) => {
        setHadEdit(1)
        setPwaData((prev) => ({...prev, [values.name]:values.value}))
    }

    const getValidationFields = () => {
        return [...Object.keys(pwaData)];
    }
    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout( () => {
            if(hadEdit){
                submitHandler(e)
            }
            else {
                pageNumber(3)
            }
        },0)
    }

    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const { Form, Label, Input, Error, submitHandler } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            title="Let's capture all the Web App related details"
            subtitle="Please upload all the below information as required by * for Progressive Web Apps"        
        >
            <Form>
                <div className={styles.formContent}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.contents}>
                            <div className={styles.individualContent}>
                                <Label text={"API Key (Optional)"}/>
                                <Input style={{width:"100%"}} type="text" name={"apiKey"} placeholder={"API key"}/>
                                <Error name="apiKey" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='afPopupFooter'>
                    <div>
                        <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                        <Input type="submit" value={"Generate"} style={{width:"fit-content",padding:"calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px)"}} />
                    </div>
                </div>
            </Form>
            {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default PwaConfiguration