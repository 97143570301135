import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import Table from '../../../common/components/Table';
import ContainerAppDialog from '../../project-settings/components/container-app-generation';
import { TablePagination } from '@material-ui/core';
import DeleteDialog from '../../../common/components/DeletePrompt/index';
import PromptDialog from '../../../common/components/PromptDialog';
import {
  setContainerAppId,
  clearCagState,
  fetchContainerAppDetails,
  fetchMicroAppList,
  downloadApp,
} from '../../../helpers/container-app-generation';
import OtaInstallation from '../../project-settings/components/download-modal/ota-installation';
import { fetchTableNameList } from '../../../helpers/database-designer';
import { alertShow } from '../../../helpers/container-app-generation';
import DownloadModal from '../../project-settings/components/download-modal';
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  FormGroup,
  InputLabel,
  FilledInput,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getPermissions } from '../../../utils/common';
import { configMessage } from '../../../common/messages/config';
import ContentHeader from '../../../common/components/ContentHeader';

const deviceConstants = {
  ios: 'iOS',
  android: 'Android',
  windows: 'Windows',
};

const staticStyle = {
  marginLeft: 24,
  overflowWrap: 'break-word',
  paddingTop: '5px',
  paddingBottom: '5px',
};

const containerAppsTableColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    type: 'text',
    style: { ...staticStyle, width: '150px' },
  },
  {
    id: 'appType',
    label: 'Type',
    align: 'left',
    type: 'text',
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'deviceType',
    label: 'Platform',
    align: 'left',
    type: 'dropdown_with_tag',
    placeHolder: 'Platform',
    dropdownItems: [
      { value: deviceConstants.ios, label: deviceConstants.ios },
      { value: deviceConstants.android, label: deviceConstants.android },
      { value: deviceConstants.windows, label: deviceConstants.windows },
    ],
    isRowMultiTags: true,
    style: { ...staticStyle, width: '100px' },
  },
  {
    id: 'caType',
    label: configMessage.T4624,
    align: 'left',
    type: 'dropdown',
    isMultiSelect: false,
    placeHolder: configMessage.T4624,
    dropdownItems: [
      { label: configMessage.T4581, value: configMessage.T4624.toLowerCase() },
      { label: configMessage.T4648, value: configMessage.T4648.toLowerCase() },
    ],
    style: { ...staticStyle, width: '60px' },
  },
  {
    id: 'version',
    label: configMessage.T4649,
    align: 'left',
    type: 'text',
    disabled: true,
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'updateTime',
    label: configMessage.T4650,
    align: 'left',
    type: 'text',
    disabled: true,
    style: { ...staticStyle, width: '140px', paddingLeft: '100px' },
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    type: 'dropdown',
    isMultiSelect: false,
    dropdownItems: [
      { label: 'Finished', value: 'Finished' },
      { label: 'Draft', value: 'draft' },
      { label: 'Failed', value: 'failed' },
      { label: 'Pending', value: 'pending' },
    ],
    placeHolder: 'Status',
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'status',
    label: 'statusIcon',
    align: 'center',
    type: 'icon',
    style: { ...staticStyle, width: '40px' },
  },
  {
    id: 'more',
    label: '',
    align: 'right',
    type: 'more_without_header',
    style: { ...staticStyle, width: '40px' },
  },
];

export default function (props) {
  const classes = useStyles();
  const { cagConfigModal = 0, setCagConfigModal = () => null } = props;
  const [AppsrowsPerPage, setAppsRowsPerPage] = React.useState(10);
  const [Appspage, setAppsPage] = React.useState(0);
  const { projectDetail, projectVariables, invokeProjectUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [downloadAppLoading, setDownloadAppLoading] = useState(false);
  const [flow, setFlow] = useState('');
  const [cagModalClear, setCagModalClear] = React.useState(false);
  const [mobAppSearchValues, setMobAppSearchValues] = React.useState({});
  const [clearInputFields, setClearInputFields] = React.useState(false);
  const [statusDescriptionPopup, setStatusDescriptionPopup] = React.useState({
    show: false,
    message: '',
  });
  const [showDownloadModal, setShowDownloadModal] = React.useState({
    show: false,
    data: {},
  });
  useEffect(() => {
    if(props.reset){
      setAppsPage(0);
      setAppsRowsPerPage(10);
      props.setPaginationReset();
    }
  }, [props.reset]);

  useEffect(() => {
    if (loading && !!props.containerAppDetails?.containerApp) {
      if (statusDescriptionPopup.show) {
        setStatusDescriptionPopup((prevState) => ({
          ...prevState,
          message: props.containerAppDetails.containerApp?.message || 'failed',
        }));
      }
      setLoading(false);
    }

    if (downloadAppLoading && !!props.containerAppDetails?.containerApp) {
      if (showDownloadModal.show) {
        setShowDownloadModal((prevState) => ({
          ...prevState,
          data: props.containerAppDetails || {},
        }));
      }
      setDownloadAppLoading(false);
    }
  }, [props.containerAppDetails]);

  const staticStyle = {
    marginLeft: 24,
    overflowWrap: 'break-word',
    paddingTop: '5px',
    paddingBottom: '5px',
  };

  // const containerAppsTableColumns = containerAppConstants.containerAppTable;

  const deleteData = {
    title: `Confirmation`,
    text: `Are you sure you want to delete?`,
    action: 'delete',
  };

  const dropdownData = () => {
    let array = [];

    if (getPermissions()?.library?.container_apps?.canUpdate === true) {
      array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    }
    if (getPermissions()?.library?.container_apps?.canDelete === true) {
      array.push({ id: 2, title: 'Delete', selected: false, key: 'delete' });
    }

    return array;
  };

  const cagDropdownData = (row = {}) => {
    let array = [];
    if (getPermissions()?.library?.container_apps?.canUpdate && row.status !== "Pending")
      array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    if(row?.deviceType?.includes('iOS') && row.updateTime)
      array.push({ id: 2, title: 'Download .ipa', selected: false, key: 'download-ipa' });
    if(row?.deviceType?.includes('Android') && row.updateTime)
      array.push({ id: 3, title: 'Download .apk', selected: false, key: 'download-apk' });
    if(row.updateTime && !(row.deviceType?.length === 1 && row.deviceType?.[0] === "Web"))
      array.push({ id: 4, title: 'OTA', selected: false, key: 'download-ota' });
    if(row.deviceType?.length === 1 && row.deviceType?.[0] === "Web")
      array.push({ id: 5, title: 'Copy URL', selected: false, key: 'web-url' });

    return array;
  };

  const headerDropDownData = () => {
    let array = [];

    if (getPermissions()?.library?.container_apps?.canDelete === true) {
      array.push({ id: 1, title: 'Delete', selected: false, key: 'delete' });
    }

    return array;
  };

  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const handleChangeAppsPage = (event, newPage) => {
    props.handleCagPagination({
      limit: AppsrowsPerPage,
      page: newPage,
    });
    setAppsPage(newPage);
  };

  const handleChangeAppsRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setAppsRowsPerPage(recordsPerPage);
    props.handleCagPagination({ limit: recordsPerPage, page: 0 });
    setAppsPage(0);
  };

  const openCagModal = (cagId = null) => {
    fetchTableNameList(props.match.params.id||'services_workspace');
    fetchMicroAppList(props.match.params.id||'services_workspace');
    if (cagId !== null) {
      setFlow('edit');
      setContainerAppId(cagId);
      setLoading(true);
      fetchContainerAppDetails(cagId, props.match.params.id||'services_workspace');
    } else {
      setFlow('new');
    }
    setCagModalClear(false);
    setCagConfigModal(true);
  };

  const closeCagModal = () => {
    setCagModalClear(true);
    setFlow('');
    clearCagState();
    setCagConfigModal(false);
    setClearInputFields(true);
    setMobAppSearchValues('');
    props.handleChangeMobileApp('');
  };

  const openStatusLog = (status = 'failed', message = '', data = {}) => {
    if (status.toLowerCase() == 'failed') {
      setLoading(true);
      fetchContainerAppDetails(data.id, props.match.params.id||'services_workspace');
      setStatusDescriptionPopup({
        show: true,
        status: status,
        message: '',
      });
    } else if (status.toLowerCase() == 'draft') {
      if (
        getPermissions()?.library?.container_apps?.canUpdate
      ) {
        openCagModal(data.id);
      }
    } else if (status.toLowerCase() === 'finished') {
      console.log(data);
      if(data.deviceType?.length === 1 && data.deviceType?.[0] === "Web") {
        props.handleCopyAlert(data.otaUrl, true);
      } else {
        openDownloadModal(data);
      }
    }
  };

  const openDownloadModal = (data) => {
    fetchContainerAppDetails(data.id, props.match.params.id||'services_workspace');
    setDownloadAppLoading(true);
    setShowDownloadModal({ show: true, data: [] });
  };

  const handleMoreCagOptions = (event, key, type, data) => {
    if (key.key === 'edit') {
      openCagModal(data.id);
    } else if (key.key === 'download-ota') {
      // if (['finished', 'failed'].includes(data.status.toLowerCase()))
      if(data.updateTime)
        openDownloadModal(data);
      else {
        let message = configMessage.T4598;
        alertShow({
          code: 0,
          message: message[data.status.toLowerCase()],
        });
      }
    } else if(key.key === 'download-ipa'){
      downloadApp(data.id, 'ios');
    } else if(key.key === 'download-apk'){
      downloadApp(data.id, 'android');
    } else if(key.key === 'web-url') {
      event.preventDefault();
      props.handleCopyAlert(data.otaUrl, true);
    }
  };

  const handleChangeMobileApp = (value, column) => {
    setMobAppSearchValues((prevState) => {
      return { ...prevState, [column.id]: value };
    });
    const searchValues = { ...mobAppSearchValues, [column.id]: value };
    props.handleChangeMobileApp(searchValues);
    setClearInputFields(false);
  };

  return (
    <Box>
      {getPermissions()?.library?.container_apps?.canView && (
        <Box >
            {getPermissions()?.library?.container_apps?.canView && (
                <ContentHeader
                    left={9}
                    right={3}
                    title='Container Apps'
                    subtitle={
                      'Container Apps built here can incorporate applications developed in various projects.'
                    }
                  >  
                    <Box variant='div' className={classes.headerButton}>
                    {
                      getPermissions()?.library?.container_apps?.canCreate &&(
                        <Button
                        variant='contained'
                        color='primary'
                        onClick={() => openCagModal(null)}
                        startIcon={<AddIcon />}
                      >
                        New App
                      </Button>
                      )
                    }
                    </Box>
                    <ContainerAppDialog
                        show={cagConfigModal}
                        onClose={closeCagModal}
                        cagModalClear={cagModalClear}
                        setCagModalClear={setCagConfigModal}
                        handleVariablesOp={props.handleVariablesOp}
                        projectDetail={props.projectDetail}
                        loading={loading}
                        setLoading={setLoading}
                        tableNameList={props.tableNameList}
                        microAppList={props.microAppList}
                        screenType={'library'}
                        flow={flow}
                        setFlow={setFlow}
                        {...props}
                    />
                </ContentHeader>
                )}
          <Grid container className={classes.settingMainLayout}>
            <Grid item xs={12}>
                {getPermissions()?.library?.container_apps?.canView && (
                  <Grid
                    Container
                    item
                    className={classes.subContainer}
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Container className={classes.subBox}>
                        <Box className={classes.innerRightBox}>
                          <Box style={{ position: 'relative' }}>
                            <Box>
                              <Table
                                classes={classes}
                                tableData={props.containerAppsData}
                                tableColumns={containerAppsTableColumns}
                                handleChange={handleChangeMobileApp}
                                screenType={'Project'}
                                rowMenuOptions={cagDropdownData}
                                headerMenuOptions={headerDropDownData()}
                                handleIconClick={(e) =>
                                  openStatusLog(e.status, e.message || '', e)
                                }
                                handleMenuDropdown={handleMoreCagOptions}
                                checkDisable={props.checkDisable}
                                handleTableCheckBox={props.handleTableCheckBox}
                                clearInputFields={clearInputFields}
                              />
                              <TablePagination
                                rowsPerPageOptions={paginationDropDownList}
                                component='div'
                                count={props.cagNoOfRecords}
                                rowsPerPage={AppsrowsPerPage}
                                page={Appspage}
                                SelectProps={{
                                  IconComponent: KeyboardArrowDownIcon,
                                  classes: { icon: classes.selectIcon },
                                }}
                                onChangePage={handleChangeAppsPage}
                                onChangeRowsPerPage={
                                  handleChangeAppsRowsPerPage
                                }
                                showFirstButton
                                showLastButton
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Box>
      )}
      <DeleteDialog
        open={props.deleteConfirm}
        close={() => props.handleDeleteConfirm('cancel')}
        delete={() => props.handleDeleteConfirm('submit')}
        data={deleteData}
      />
      {statusDescriptionPopup.show ? (
        <PromptDialog
          open={statusDescriptionPopup.show}
          close={() => {
            setContainerAppId(null);
            setStatusDescriptionPopup({ show: false, message: '' });
          }}
          data={{
            text: statusDescriptionPopup.message,
            title: statusDescriptionPopup.status,
            action: 'error',
          }}
          showFooter={false}
          loading={loading}
        />
      ) : (
        ''
      )}
      <OtaInstallation
        open={showDownloadModal.show}
        close={() => {
          setShowDownloadModal({ show: false, data: {} });
        }}
        handleCopyAlert={props.handleCopyAlert}
        data={showDownloadModal.data}
        loading={downloadAppLoading}
      />
    </Box>
  );
}
