import React, { useState } from 'react'
import { useAppContext } from '../../../../../../components/activity/AppContext';
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import styles from './applicationDetails.module.css'
import { validateImage, validateValue, validateValues } from './validations';
import { readFileAsync, readFileAsyncImage } from '../../../../../../common';
import { arrayBufferToBase64 } from '../../../../../../common/utils/utils';
import SmallPopup from '../../../../../../components/layouts/smallPopup';

function ApplicationDetails({ projectName, loginAppList, containerAppId, containerAppDetails, setContainerAppDetails, pageNumber, handleClose, rolePopUp, resetCADetails }) {
    const containerApp = {
        ...containerAppDetails.containerApp
    }

    const buildNumberDefault = {
        kind:"BUILD_NUMBER", fileName:"", certificate:"1.0", password:"",
        ...containerAppDetails?.containerAppCertificates.find((item)=>item.kind==="BUILD_NUMBER")
    }

    const loginAppDefault = {
        certificate: "", fileName: "", kind: "LOGIN", password: "",
        ...containerAppDetails?.containerAppCertificates.find((item)=>item.kind==="LOGIN")
    }

    const iconDefault = {
        certificate: "", fileName: "", kind: "A", password: "", uuid: null,
        ...containerAppDetails?.containerAppCertificates.find((item)=>item.kind==="A")
    }

    const [data,] = useState({
        appIdentifier: containerApp?.appIdentifier || "",
        version: containerApp?.version||"",
        buildNumber: buildNumberDefault?.certificate || "1.0",
        loginApplication: (loginAppDefault?.certificate
                            +":"+loginAppDefault?.fileName) || "",
        icon: iconDefault?.uuid || "",
        urlScheme: containerApp?.urlScheme || "plr://"
    })
    const [hadGeneratedBefore,] = useState(containerAppDetails?.containerApp?.otaUrl!==undefined?containerAppDetails.containerApp.otaUrl!==""?true:false:false)
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)
    const [isPWA, setIsPWA] = useState(containerApp.appType==="pwa");
    const [isWebApp, setIsWebApp] = useState(containerApp.appType==="webApp")
    const [uploadFileUrl, setUploadFileUrl] = useState( iconDefault.certificate||"")
    const [activeImage, setActiveImage] = useState(iconDefault?.uuid || "")
    const app = useAppContext()
    const watch = ["appIdentifier", "version", "buildNumber", "loginApplication", "icon"]

    const updateContainerAppDetails = async (newValues, errors) => {
        const prjAction = ProjectSettingsActions(app());
        const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
        if(!err){
            const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                if(!err2) {
                    setContainerAppDetails({...data2.detail, genarate:false})
                }
            if(data.status.type!=="E") saveChangesPopup?closePopup(false):pageNumber(clickedBack?0:2)
            else {
                if(data.status.message.includes("E4091")) {
                    errors.set("appIdentifier", data.status.message)
                }
            }
        }

    }

    const onSubmit = async ( values, errors ) => {
        let fields = getValidationFields(values.type);
        let result = await validateValues(values, errors, fields, 1000000, "image/png", 1024, 1024)
        if(!result) {
            if(isWebApp){
                if(!hadEdit&&!clickedBack) {pageNumber(2);return}
                let updateContainerApp = {...containerApp, version:values.version, urlScheme:values.urlScheme}
                let updateLoginApp = {...loginAppDefault, certificate:values.loginApplication.split(":")[0], fileName:values.loginApplication.split(":")[1]}
                let updateBuildNumber = {...buildNumberDefault,certificate:values.buildNumber}
                let newValues={...containerAppDetails,
                    containerApp:updateContainerApp,
                    containerAppCertificates:[
                        ...containerAppDetails["containerAppCertificates"].filter(e => !(e.kind=="BUILD_NUMBER"||e.kind=="LOGIN"||e.kind=="A")),
                        updateBuildNumber, 
                        updateLoginApp,
                        ]}
                    updateContainerAppDetails(newValues, errors)
            } else{
                if(uploadFileUrl!==""){
                    if(!hadEdit&&!clickedBack) {pageNumber(2);return}
                    // setContainerApp((prev)=>({...prev,...values}))
                    // createContainerApp()
                    let updateContainerApp = {...containerApp, version:values.version, urlScheme:values.urlScheme}
                    if(!isPWA)   updateContainerApp.appIdentifier = values.appIdentifier
                    let updateBuildNumber = {...buildNumberDefault,certificate:values.buildNumber}
                    let updateIcon = {...iconDefault,certificate:uploadFileUrl, fileName:uploadFileUrl.substring(uploadFileUrl.lastIndexOf("/")+1)}
                    let updateLoginApp = {...loginAppDefault, certificate:values.loginApplication.split(":")[0], fileName:values.loginApplication.split(":")[1]}
                    let newValues={...containerAppDetails,
                                        containerApp:updateContainerApp,
                                        containerAppCertificates:[
                                            ...containerAppDetails["containerAppCertificates"].filter(e => !(e.kind=="BUILD_NUMBER"||e.kind=="LOGIN"||e.kind=="A")),
                                            updateBuildNumber, 
                                            updateLoginApp, 
                                            updateIcon]}
                    updateContainerAppDetails(newValues, errors)
                }
            }
        }
        else {
            if(clickedBack) setClickedBack(false)
        }
    }

    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout( () => {
            if(hadEdit){
                submitHandler(e)
            }
            else {
        pageNumber(0)
    }
        },0)

    }
    const onWatch = async (errors, values) => {
        setHadEdit(1)
        if(values.name === "version" || values.name === "buildNumber") {
            values.value = values.value.replace(/[^\d\.]/g, '')
        }
        else if(values.name === "appIdentifier") {
            values.value = values.value.toLowerCase().replace(/[^a-z0-9\.]+/g, '')
        }
        else if(values.name === "urlScheme") {
            values.value = values.value.toLowerCase().replace(/[^a-z0-9\.]+/g, '') + "://"
        }
        validateValue(errors, values.name, values.value)
        if(values.name==="icon"){
            let result = await validateImage(errors, values.name, values.value, 1000000, "image/png", 1024, 1024)
            if(result){
                let imageArrayBuffer = await readFileAsyncImage(values.value);
                setActiveImage("data:image/png;base64,"+arrayBufferToBase64(imageArrayBuffer))
                let binaryData = await readFileAsync(values.value)
                // let blob = new Blob([dataImage], {type: values.value.type})
                // blob.name=values.value.name
                handleFileupload(errors,values.value.name, imageArrayBuffer)        
            }
            else{
                setActiveImage("")
            }
        }
    }

    const handleFileupload = async (errors, fileName, imageArrayBuffer) => {
        let comment = "A"
        if(imageArrayBuffer) {
            const actions = ProjectSettingsActions(app())
            const [err, result] = await actions.uploadFile(projectName, containerAppId, comment, fileName, imageArrayBuffer)
            if (!err) {
                setUploadFileUrl(result.url)
            }
        }
    }

    const { Form, Label, Input, Error, Select, File, DragAndDropArea, submitHandler } = useForm(data, onSubmit, onWatch);
    
    const getValidationFields = (type) => {
        return [...isPWA?watch.filter(e => e !== "appIdentifier"):isWebApp?["loginApplication","version"]:watch];
    }

    return (
        <PopUp
            popUp={rolePopUp}
            titleClassName={styles.afModuleTitle}
            setPopUp={handleClosePopup}
            testId={"applicationDetails"}
            title=" Let’s capture some App details"
            subtitle="This information is required to build your new application"
        >
        <Form>
                <div className={styles.formContent}>
                    {isWebApp?(
                    <div className={styles.formField}>
                    <div style={{ width: "100%" , flexGrow:"1"}}>
                        <Label text={"Version"} />
                        <Input type="text"  id={"version"} style={{width:"100%"}} disabled={false}
                            name={"version"} placeholder={"Version"} autoComplete="off" 
                            maxLength={"45"}/>
                        <Error name={"version"} style={{marginBottom:"16px"}}/>
                        </div>
                    </div>
                    ):(
                    <div className={styles.formFieldVaried}>
                    <div style={{width:"10%", flexGrow:"1"}}>
                        <Label text="Scheme" style={{color:`${!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)"}`}}/>
                        <Input type="text" id="urlScheme" name={"urlScheme"} style={{width:"100%"}} disabled={hadGeneratedBefore} />
                        <Error name={"urlScheme"} />
                    </div>
                    <div style={{ width: "80%" , flexGrow:"1"}}>
                        <Label text={!isPWA?"Package Name / App Identifier":"Version"} style={{color:`${!isPWA?!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)":""}`}}/>
                        <Input type="text"  id={!isPWA?"appIdentifier":"version"} style={{width:"100%"}} disabled={!isPWA?hadGeneratedBefore:false}
                            name={!isPWA?"appIdentifier":"version"} placeholder={!isPWA?"Ex: com.companyname.appname":"Version"} autoComplete="off" 
                            maxLength={!isPWA?"100":"45"}/>
                        <Error name={!isPWA?"appIdentifier":"version"} style={{marginBottom:"16px"}}/>
                        </div>
                    </div>
                    )}
                {!(isPWA||isWebApp)&&<div className={styles.formFieldVaried}>
                    <div style={{flexGrow:"1"}}>
                        <Label text="Version" />
                        <Input type="text" id="version" maxLength={"45"}
                            style={{ width: "100%" }} name={"version"} placeholder={"Version"} autoComplete="off" />
                        <Error name={"version"} />
                    </div>
                    <div style={{flexGrow:"1"}}>
                        <Label text="Build Number" style={{color:`${!hadGeneratedBefore?"":"rgba(0, 0, 0, 0.38)"}`}}/>
                        <Input type="text" id="buildNumber" style={{ width: "100%" }} disabled={true}
                            name={"buildNumber"} autoComplete="off" />
                        <Error name={"buildNumber"} />
                    </div>
                </div>}
                    <div className={styles.formField}>
                        <Label text="Login Application" />
                        <Select type="text" style={{ width: "100%" }} id="loginApplication"
                            name={"loginApplication"} autoComplete="off" >
                        <option value=':' disabled hidden>
                            </option>
                        {loginAppList.map((item) => {
                            return (
                                <option key={item.id} value={item.id+":"+item.name} >
                                    {item.name}
                                </option>
                            )
                        })}
                        </Select>
                    <Error name={"loginApplication"} />
                    </div>
                    {!isWebApp &&
                    <div className={styles.formField}>
                        <Label text="Icon" />
                        <DragAndDropArea style={styles.iconDiv} name={"icon"}>
                            <div className={styles.iconDisplayDiv}>
                                <div className={styles.iconImgCont}>
                                    {!(activeImage)?<div className={styles.iconImgFillCont}></div>:<div className={styles.iconCont}><img data-image-testid={"App Icon"} src={activeImage||data.icon} width="80px" height="80px" className={styles.img}/></div>}
                                </div>
                                <Label className={styles.resolutionText} text={"1024 x 1024 px"} />
                            </div>
                            <div className={styles.iconUploadDiv}>
                                <Label text={"Drop a file here"}/>
                                <Label text={"or"}/>
                                <File type="file" id={"icon"} name={"icon"} accept="image/png" style={{display:"none"}} />
                                <Label className={"primary"} data-clickable-testid={"select-a-file"} style={{height: "calc(var(--spacer) * 4px)"}} text={"Select a file"} htmlFor={"icon"}/>
                                <Label className={styles.uploadFileText} text={".png files only"} />
                            </div>
                        </DragAndDropArea>
                        <Error name={"icon"}/>
                    </div>
                    }
            </div>
            <div className='afPopupFooter'>
                <div>
                    <Input data-clickable-testid="back" type={"button"} style={{ marginRight: "12px" }} className="secondary" value={"Back"}  onClick={handleBack}/>
                    <Input type="submit" value={"Next"} />
                </div>
            </div>
        </Form>
        {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}


export default ApplicationDetails;