import { showNotification } from "../../actions/app-root";
import { handleSessionExpired } from "../../actions/sign-out";
import { pillirConstants } from "../../constants";
import store from "../../stores";
import { clearAuth } from "../auth/user";

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}

export const readFileAsyncImage = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function generateUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function forceLogout() {
  clearAuth();
  setTimeout(()=>{
    // store.dispatch(handleSessionExpired())
    window.location.href = "/signout"
  },0)
  // store.dispatch(showNotification({
  //   type: 'error',
  //   show: true,
  //   message : pillirConstants.sessionExpired
  // }))
}