import React, { useEffect, useState } from 'react';
import { getTableColumns } from '../../api/library/database-tables/database';
import { DatabaseActions } from '../../pages/database/database.actions';
import { useAppContext } from '../activity/AppContext';
import { useForm } from '../forms/Form';
import SelectComponent from '../selectComponent/selectComponent';
import Table from '../Table/table';
import styles from "./dataGrid.module.css";
//import { ReactComponent as DeleteIcon } from "../../images/deleteIcon.svg"
import SmallPopup from "../../components/layouts/smallPopup";
import { validateColumn } from '../../pages/database/validation';

export default function DataGrid(props) {

    const app = useAppContext();
    const { rows = [], columns = [], handler, setState, getColNames, deleteColumnHandler, deleteTitle, deleteSubTititle, name, options, colChanges, refresh = false } = props;
    const [updatedRows, setUpdateRows] = useState([]);
    const [updatedOptions, setUpdateOptions] = useState([]);
    const [refTableColumns, setRefTableColumns] = useState(false);
    const [isSelectUpdate, setIsSelectUpdate] = useState(false)
    const [count, setCount] = useState(0);
    const [deletePopup, setDeletePopup] = useState({ name: "", type: "", delete: false })
    const [rowData, setRowData] = useState([...rows])//(JSON.parse(JSON.stringify([...rows])))
    const [deleteColumnId, setDeleteColumnId] = useState("")
    const TableRowData = ({ val, rowId, objId, type, options, id, name, idxType, editable, placeholder }) => {
        const [value, setValue] = useState(val);

        const onWatch = (errors, data) => {
            if (data.name === "primary") {
                if (data.value === true) {
                    const res = validateColumn(data.name, data.value, app(), rows)
                    data.value = res;
                }

                // data.value = res;
            }
            let tempRows = [...rows];
            tempRows[id][objId] = data.value;
            if (data.type == "blur" || data.type === 'change') {
                if (objId === 'column' || objId === 'name') {
                    let cols = getColNames();
                    tempRows.forEach((item, index) => {
                        item.actualColValues = cols;
                    });
                } else if (objId === 'columnName') {
                    tempRows[id]['name'] = data.value;
                    tempRows[id]['delete'] = data.value;
                }
                let c = count + 1;
                setCount(c);
                setState(tempRows);
                setValue(data.value);
                handler(id, objId, data.value);

            }
        }

        const onSubmit = (values, errors) => { }


        const onSelect = async (value) => {
            setValue(value);
            if (value) {
                rows[id][objId] = value;
                setState(rows);
                if (objId === 'refTables') {
                    const database = DatabaseActions(app());
                    let [err, data] = await database.getTableColumns(value);
                    let tempRows = [...rows];
                    tempRows[id]['refColumnsValues'] = data.colNames;
                    if (data.colNames.length > 0) {
                        setRefTableColumns(true);
                    }
                    setState(tempRows);
                }
                handler(id, objId, value);
                let c = count + 1;
                setCount(c);
                setIsSelectUpdate(true)
            }
            handler(id, objId, value);
        }


        const onClick = async (value) => {
            if (objId === 'column') {
                let cols = getColNames();
                let tempRows = [...rows];
                tempRows.forEach((item, index) => {
                    item.actualColValues = cols;
                });
                setState(tempRows);
            }
            let c = count + 1;
            setCount(c);
        }

        const handleDelete = (name, idxType, flag, uid) => {
            setDeleteColumnId(uid)
            setDeletePopup({ 'name': name, type: idxType, delete: flag })
        }


        const { Form, Input } = useForm(rows, onSubmit, onWatch);

        const renderData = (type, options) => {
            if (type === "inp") {
                return (
                    <span style={{ zIndex: "98" }}>
                        <Input className={styles.inputStyle} id={`${objId}-${id}`} placeholder={placeholder} name={objId} type={`${options ? options : "text"}`} value={value || ""} disabled={!editable} />
                    </span>
                );
            } else if (type === "sel") {
                return (<SelectComponent id={objId} value={value} selected={value ? value : ""} options={options} onSelect={onSelect} disabled={!editable} />)
            } else if (type === "sel-row") {
                return (<SelectComponent id={objId} value={value} selected={value ? value : ""} options={rows[id]['refColumnsValues']} onSelect={onSelect} disabled={!editable} />)
            } else if (type === "sel-col") {
                return (<SelectComponent id={objId} value={value} selected={value ? value : ""} options={isIndices() ? options : rows[id]['actualColValues']} onSelect={onSelect} disabled={!editable} />)
            } else if (type === "ckbx") {
                return (
                    <span style={{ zIndex: "98", paddingLeft: "20px" }}>
                        <Input type={"checkbox"} id={objId} name={objId} checked={value} disabled={!editable} />
                    </span>)
            } else if (type === "img") {
                return (<span style={{ paddingLeft: "10px" }} data-clickable-testid="delete-key" onClick={() => handleDelete(rows[id].name, idxType, true, rows[id].uid)}><img src="/images/variables/delete.png" /></span>)
            } else {
                return <span style={{ paddingLeft: "15px", fontSize: "13px", backgroundColor: "inherit" }}>{value}</span>
            }
        }
        return (
            <Form>
                <div key={id}>
                    {renderData(type, options)}
                </div>
            </Form>
        );
    }

    const isIndices = () => {
        return name === 'Indices' ? true : false;
    }

    const rowsData = () => {
        let tempUpdateRows = [];
        rows.map((ro, index) => {
            let tempObj = {};
            let tempType = "";
            let tempOptions = [];
            for (let [key, value] of Object.entries(ro)) {
                let placeholder = ""
                let editable = ""
                for (let i = 0; i < columns.length; i++) {
                    if (key === columns[i].id) {
                        tempType = columns[i].type;
                        tempOptions = (isIndices() && tempType == 'sel-col') ? columns[i].id === 'orderBy' ? columns[i].options : options : columns[i].options
                        placeholder = columns[i].placeholder
                        if (columns[i]?.editableWithCond) {
                            const { refCol, mapEditable, mapDataLength } = columns[i].editableWithCond
                            const mapCol = ro[refCol]
                            editable = mapEditable[mapCol] ? mapEditable[mapCol] : false
                            value = mapDataLength[mapCol] ? mapDataLength[mapCol] : value
                            ro[columns[i].id] = value
                            placeholder = editable ? placeholder : "-"
                        }
                        else {
                            editable = columns[i]?.editable === undefined ? true : columns[i].editable
                        }
                    }
                }
                tempObj = {
                    ...tempObj,
                    layoutType: "dataGrid",
                    [key]: <TableRowData
                        id={index}
                        rowId={ro.id}
                        objId={key}
                        val={key === 'orderBy' ? value ? value : "ASC" : value}
                        type={tempType}
                        columns={columns}
                        editable={editable}
                        name={isIndices() ? ro.name : ro.columnName}
                        idxType={isIndices() ? ro.type : ""}
                        placeholder={placeholder}
                        options={tempOptions} />
                }
            }
            tempUpdateRows.push(tempObj);

            tempObj = {};
            tempType = [];
        })
        if (updatedRows.length != rows.length || updatedOptions.length != options?.length || refTableColumns || isSelectUpdate || refresh) {
            setUpdateRows(tempUpdateRows);
            setUpdateOptions(options);
            setRefTableColumns(false);
            setIsSelectUpdate(false)
        }

    }

    useEffect(rowsData, [rows, options]);

    const deleteButton = (col, idxType) => {
        deleteColumnHandler(col, idxType, deleteColumnId)
        setDeletePopup({ name: "", type: '', delete: false })
    }


    return (
        <div className={styles.gridContainer} >
            <Table
                name={name}
                headerColumns={columns}
                data={updatedRows} />
            {deletePopup.delete
                ? <SmallPopup
                    popUp={deletePopup.delete}
                    title={deleteTitle}
                    subtitle={deleteSubTititle}
                    handlePopUp={() => setDeletePopup({ name: "", type: "", delete: false })}
                    handleSubmit={() => deleteButton(deletePopup.name, deletePopup.type)}
                />
                : ""}
        </div >
    );
}