import React, { Component } from "react";
import { connect } from "react-redux";
import ProjectSettingLayout from "./layout.js";
import {
  fetchVariableList,
  handleProjectUpdate,
  searchProjectsVariables,
  createProjectVariables,
  updateProjectVariables,
  deleteProjectVariable,
  clearVariableData,
  clearProjectUpdate,
  alertCopyMessage,
} from "../../helpers/project-settings";

import { fetchContainerAppList, moveContainerAppToLibrary } from "../../helpers/container-app-generation";

import {
  fetchProjectDetails,
  saveProjectPathUrl,
} from "../../helpers/project-detail";
import { openProject, closeProject } from "../../helpers/app-root";
import { toTitleCase } from "../../utils/common";
import { fetchpublishedloginlist } from "../../helpers/all-logins.js";

let mobAppSearchValue = {};
let reset = false;

class ProjectSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invokeProjectUpdate: false,
      updateData: {},
      showNewVariableModal: false,
      deleteConfirm: false,
      variableOpIndex: [],
      filterDict: {},
      checkDisable: false,
      paginationData: { limit: 10, page: 0 },
      projNameDisable: true,
      projDescDisable: true,

      containerAppGenerations: [],
      cagPagination: { limit: 10, offset: 0 },

      cagConfigModal: false,
    };

    this.timer = 0;
    this.handleTimer = this.handleTimer.bind(this);
    this.handleProjectUpdateValue = this.handleProjectUpdateValue.bind(this);
    this.handleVariablesOp = this.handleVariablesOp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleMoveCAtoLibrary = this.handleMoveCAtoLibrary.bind(this)
    this.handleVarMenuDropdown = this.handleVarMenuDropdown.bind(this);
    this.handlePagination = this.handlePagination.bind(this);

    this.handleNewVariableModal = this.handleNewVariableModal.bind(this);
    this.handleEditVarPrefill = this.handleEditVarPrefill.bind(this);
    this.handleTableCheckBox = this.handleTableCheckBox.bind(this);
    this.handleCopyAlert = this.handleCopyAlert.bind(this);

    this.projNameRef = React.createRef();
    this.projDescRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.doubleClickProject = this.doubleClickProject.bind(this);
  }

  componentWillMount() {
    mobAppSearchValue = {};
  }

  componentDidMount() {
    // document.addEventListener('mousedown', this.handleClickOutside);
    // this.variableListAPI(this.state.paginationData);
    this.initAPIs();
  }

  setPaginationReset = () => {
    reset = false;
  }

  initAPIs = () => {
    const { match, history } = this.props;
    let projectId = match.params.id;
    fetchProjectDetails(projectId);
    fetchContainerAppList( {}, { limit: 10, offset: 0 }, projectId );
    saveProjectPathUrl( match.params.id, history.location.pathname );
    fetchpublishedloginlist();
    this.setState({
      cagConfigModal: false,
      containerAppGenerations: [],
      cagPagination: { limit: 10, offset: 0 }
    });
    mobAppSearchValue = {};
    reset = true;
  }

  triggerCagInterval = () => {
    const { match } = this.props;
    let projectId = match.params.id;
    if (!this.timerHandle) {
      this.timerHandle = setInterval(() => {
        if (!this.state.cagConfigModal) {
          fetchContainerAppList(
            mobAppSearchValue,
            this.state.cagPagination,
            projectId
          );
        }
      }, 30000);
    }
  };

  clearCagInterval = () => {
    if (this.timerHandle) clearInterval(this.timerHandle);
  };

  componentWillUnmount() {
    this.clearCagInterval();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { containerAppGenerations } = this.props;

    if(this.props.match.params.id !== prevProps.match.params.id){
      this.initAPIs();
    }

    if (Object.keys(this.props.variableCreateDetails).length > 0) {
      if (this.props.variableCreateDetails.status.code == 1) {
        this.variableListAPI(this.state.paginationData);
        clearVariableData();
        this.setState({ showNewVariableModal: false, checkDisable: true });
      }
    }

    if (Object.keys(this.props.variableUpdateDetails).length > 0) {
      if (this.props.variableUpdateDetails.status.code == 1) {
        this.variableListAPI(this.state.paginationData);
        clearVariableData();
        this.setState({ showNewVariableModal: false, checkDisable: true });
      }
    }

    if (Object.keys(this.props.projectUpdateDetails).length > 0) {
      if (this.props.projectUpdateDetails.status.code == 1) {
        if (this.state.updateData.nameText != undefined) {
          let project = this.props.projectDetail;
          project.savedUrlPath = `/Project/${this.props.projectDetail.name}/`;
          closeProject(project);

          project = {
            ...project,
            name: this.state.updateData.nameText,
            savedUrlPath: `/Project/${this.state.updateData.nameText}/`,
          };
          openProject(project);

          let data = this.props.projectDetail;
          data["name"] = this.state.updateData.nameText;
          data["description"] = this.state.updateData.descText;

          // fetchProjectDetails(this.props.projectDetail.name);
          this.handleProjectDetails();

          this.props.history.index = -1;
          this.props.history.replace(
            `/Project/${this.state.updateData.nameText}`
          );

          this.props.history.index = 0;
          this.props.history.replace(
            `/Project/${this.state.updateData.nameText}/Settings`
          );
        }

        this.setState({ updateData: {} });
      } else {
        this.setState({ updateData: {} });
        this.handleProjectDetails();
      }
      clearProjectUpdate();
      this.setState({ invokeProjectUpdate: false });
    }
    let cag =
      containerAppGenerations &&
      containerAppGenerations.map((i) => {
        return {
          ...i,
          version:
          i.version && i.buildNumber ? `${i.version}/${i.buildNumber}` : "",
          deviceType: (i.deviceType && i.deviceType !== "") ? i.deviceType?.split(",") : [],
          appType: toTitleCase(i.appType?i.appType:""),
          caType: toTitleCase(i.caType?i.caType:""),
        };
      });
    if (containerAppGenerations !== prevProps.containerAppGenerations) {
      this.setState({ containerAppGenerations: cag });
      let pendingCag =
        cag.filter((e) => e.status?.toLowerCase() === "pending") || [];
      if (pendingCag?.length > 0) this.triggerCagInterval();
      else this.clearCagInterval();
    }
  }

  handleTableCheckBox() {
    this.setState({ checkDisable: false });
  }

  handleCopyAlert(otaUrl, isWeb = false) {
    navigator.clipboard.writeText(otaUrl);
    alertCopyMessage(isWeb);
  }

  handleClickOutside(event) {
    if (
      this.projNameRef &&
      !this.projNameRef.current.contains(event.target) &&
      this.projDescRef &&
      !this.projDescRef.current.contains(event.target)
    ) {
      if (
        this.state.projNameDisable == false ||
        this.state.projDescDisable == false
      ) {
        this.setState({ projDescDisable: true });
        this.setState({ projNameDisable: true });
        this.handleProjectDetails();
      }
    } else if (
      this.projNameRef &&
      !this.projNameRef.current.contains(event.target) &&
      this.state.projNameDisable == false
    ) {
      this.setState({ projNameDisable: true });
    } else if (
      this.projDescRef &&
      !this.projDescRef.current.contains(event.target) &&
      this.state.projDescDisable == false
    ) {
      this.setState({ projDescDisable: true });
    }
  }
  handleProjectDetails() {
    if (Object.keys(this.state.updateData).length == 0) {
      // if (
      //   Object.keys(this.state.updateData).length == 0 &&
      //   this.state.projNameDisable == true &&
      //   this.state.projDescDisable == true
      // )
      if (Object.keys(this.props.projectDetail).length === 0) {
        fetchProjectDetails(this.props.match.params.id);
      } else {
        fetchProjectDetails(this.props.projectDetail.name);
      }
    }
  }

  handleVarMenuDropdown(event, key, data, type) {
    if (type == "row") {
      if (key.key == "edit") {
        this.setState({
          showNewVariableModal: true,
          variableOpIndex: [`${data}`],
        });
      } else if (key.key == "delete") {
        this.setState({ deleteConfirm: true, variableOpIndex: [`${data}`] });
      }
    } else {
      this.setState({ deleteConfirm: true, variableOpIndex: data });
    }
  }

  variableListAPI(data) {
    let val = {
      projectName: this.props.match.params.id,
      limit: data.limit,
      offset: data.page * data.limit,
      filter: this.state.filterDict,
    };
    fetchVariableList(val);
  }

  variableCagListAPI(data) {
    let val = {
      limit: data.limit,
      offset: data.page,
    };
    this.setState({ cagPagination: val });
    fetchContainerAppList(mobAppSearchValue, val, this.props.match.params.id);
  }

  handleNewVariableModal(event, data) {
    this.setState({ showNewVariableModal: data });
  }

  handleEditVarPrefill(data) {
    this.setState({ variableOpIndex: [] });
  }

  handleProjectUpdateValue(data) {
    if (
      Object.keys(data).length > 0 &&
      Object.keys(this.props.projectUpdateDetails).length == 0
    ) {
      this.setState({ updateData: data });
      handleProjectUpdate(data);
      clearInterval(this.timer);
    } else {
      clearInterval(this.timer);
    }
  }

  handleVariablesOp(data, isEdit) {
    if (isEdit) {
      updateProjectVariables(data);
    } else {
      createProjectVariables(data);
    }
  }

  handleDeleteConfirm(data) {
    if (this.state.variableOpIndex.length > 0 && data == "submit") {
      let name = "";
      this.state.variableOpIndex.map((value) => {
        let variableInfo = this.props.projectVariables[value];
        if (name.length == 0) {
          name = variableInfo.name;
        } else {
          name = name + "," + variableInfo.name;
        }
      });
      let val = {
        projectName: this.props.projectDetail.name,
        variableName: name,
      };
      deleteProjectVariable(val);
    }
    this.setState({ deleteConfirm: false, variableOpIndex: [] });
  }

  deleteVariables(data, isEdit) {
    if (isEdit) {
      updateProjectVariables(data);
    } else {
      createProjectVariables(data);
    }
  }

  handleMoveCAtoLibrary(appId) {
    moveContainerAppToLibrary(
      appId, 
      mobAppSearchValue,
      this.state.cagPagination,
      this.props.match.params.id
    )
  }

  handleChange = (value, column) => {
    let dict = this.state.filterDict;
    dict[column.id] = value;
    this.setState({ filterDict: dict });

    searchProjectsVariables(this.state.filterDict);
  };

  handlePagination = (pagination) => {
    this.setState({ paginationData: pagination });
    this.variableListAPI(pagination);
  };

  handleCagPagination = (pagination) => {
    this.setState({ cagPagination: pagination });
    this.variableCagListAPI(pagination);
  };

  navigateTab = (path) => {
    const { match, history } = this.props;
    let fullPath = `/Project/${match.params.id}${path}`;
    history.push(fullPath);
  };

  handleTimer = () => {
    clearInterval(this.timer);
    this.timer = setInterval(
      () => this.setState({ invokeProjectUpdate: true }),
      1500
    );
  };

  doubleClickProject = (e, data) => {
    data == "name"
      ? this.setState({ projNameDisable: false })
      : this.setState({ projDescDisable: false });
  };

  handleChangeMobileApp = (obj) => {
    mobAppSearchValue = obj;
    fetchContainerAppList(
      mobAppSearchValue,
      {
        offset: 0,
        limit: 10,
      },
      this.props.match.params.id
    );
  };

  render() {
    return (
      <ProjectSettingLayout
        {...this.props}
        variableOpIndex={this.state.variableOpIndex}
        deleteConfirm={this.state.deleteConfirm}
        checkDisable={this.state.checkDisable}
        showNewVariableModal={this.state.showNewVariableModal}
        handleProjectUpdateValue={this.handleProjectUpdateValue}
        navigateTab={this.navigateTab}
        handleChange={this.handleChange}
        handleVariablesOp={this.handleVariablesOp}
        handleNewVariableModal={this.handleNewVariableModal}
        handleEditVarPrefill={this.handleEditVarPrefill}
        handleDeleteConfirm={this.handleDeleteConfirm}
        handleVarMenuDropdown={this.handleVarMenuDropdown}
        handleTableCheckBox={this.handleTableCheckBox}
        handlePagination={this.handlePagination}
        handleCopyAlert={this.handleCopyAlert}
        handleMoveCAtoLibrary={this.handleMoveCAtoLibrary}
        invokeProjectUpdate={this.state.invokeProjectUpdate}
        handleTimer={this.handleTimer}
        projNameRef={this.projNameRef}
        projDescRef={this.projDescRef}
        projNameDisable={this.state.projNameDisable}
        projDescDisable={this.state.projDescDisable}
        tableNameList={this.props.tableNameList}
        microAppList={this.props.microAppList}
        doubleClickProject={this.doubleClickProject}
        containerAppsData={this.state.containerAppGenerations}
        handleCagPagination={this.handleCagPagination}
        cagId={this.props.cagId}
        cagStatus={this.props.cagStatus}
        containerAppDetails={this.props.containerAppDetails}
        handleChangeMobileApp={this.handleChangeMobileApp}
        cagConfigModal={this.state.cagConfigModal}
        setCagConfigModal={(e) => this.setState({ cagConfigModal: e })}
        setPaginationReset={this.setPaginationReset}
        reset = {reset}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projectVariables: state.projectSettings.filterVariables,
    projectUpdateDetails: state.projectSettings.projectUpdateDetails,
    variableCreateDetails: state.projectSettings.variableCreateDetails,
    variableUpdateDetails: state.projectSettings.variableUpdateDetails,
    projectDetail: state.project.process,
    totalVariableRecords: state.projectSettings.totalVariableRecords,
    variableDropDownList: state.projectSettings.variableDropDownList,
    projectUpdatePostDetail: state.projectSettings.projectUpdatePostDetail,
    containerAppGenerations: state.containerApp.generations,
    microAppList: state.containerApp.microAppList,
    cagNoOfRecords: state.containerApp.noOfRecords,
    cagId: state.containerApp.cagId,
    containerAppDetails: state.containerApp.containerAppDetails,
    fileUrl: state.containerApp.fileUrl,
    cagStatus: state.containerApp.status,
    tableNameList: state.databaseDesigner.tableNameList,
    publishedLogins: state.alllogins.publishedLogins,
  };
};
export default connect(mapStateToProps)(ProjectSettings);
