const required = (errors, name, value, msg) =>{
  if(!value){
    errors.set(name,msg)
    return false;
  }
  errors.clear(name)
  return true;
}

const validateName = (errors, name, value, message, isFolderName=false) => {
  let flag=false;
  if(typeof value !='boolean'){
     flag = !(value && value.trim()!='' ) ? true:false;
  }
  if(!flag) {
    const regex = isFolderName ? /[^a-zA-Z0-9\s_]/ : /[^a-zA-Z0-9\s_\/]/;
    flag = regex.test(value); 
  }
  return updateValidation(errors, flag, name, message);
}

const validateAppIdentifierName = (errors, name, value, message) => {
  let flag=false;
  if(!flag)
    flag = /^(com).[a-z0-9].[a-z0-9]/.test(value)?false:true;
  return updateValidation(errors, flag, name, message);
}

const validateFileSize = (errors, name, value, message, targetSize) => {
  let flag = false;
  if(!flag)
    flag = value.size>targetSize?true:false
  return updateValidation(errors, flag, name, message);
}

const validateFileType = (errors, name, value, message, targetType) => {
  let flag = false;
  if(!flag)
    flag = value?.type?.includes(targetType)?false:true
  return updateValidation(errors, flag, name, message)
}

const validateImageResolution = async(errors, name, value, message, targetWidth, targetHeight) => {
  const addImageProcess = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve([img.height, img.width])
      img.onerror = reject
      img.src = src
    })
  }

  const readFileAsyncImage = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  let flag = false
  if(!flag) {
    let result = await readFileAsyncImage(value);
    let [height, width] = await addImageProcess(result);
    flag = (width !==targetWidth) || (height !==targetHeight)
  }
  return updateValidation(errors, flag, name, message)            
}

const validateNumeric = (errors, name, value, message) => {
  let flag = false;
  if(value && !isNaN(Number(value))) {
    flag = false;
  } else {
    flag = true;
  }
  if(name === "version" || name === "buildNumber") {
    flag = !/^([1-9]{1}[0-9]{0,})+((\.[0-9]{1,})*)$/.test(value); 
  }

  return updateValidation(errors, flag, name, message);
}


const validate = (errors, name,value,message) => {
  let flag=false;
  if(typeof value !='boolean')
     flag = !(value && value.trim()) ? true:false;
  return updateValidation(errors,flag,name,message);  
}

const validateLength = (errors, name, value,length, message) => {
  let flag = !(value && value.length<length) ? true:false;
  return updateValidation(errors, flag, name, message);
}

const validateSplChars = (errors, name, value, message) => {
  let flag=false;
  if(typeof value !='boolean' && value){
    flag = /[^a-zA-Z0-9\s\_\/]/.test(value)?true:false; 
  }
  return updateValidation(errors, flag, name, message);
}

const validateSplCharsLength = (errors, name, value,length, message) => {
  let flag=false;
  if(typeof value !='boolean'){
     flag = !(value && value.length<length) ? true:false;
    if(!flag)
      flag = /[^a-zA-Z0-9\s\_\/]/.test(value)?true:false; 
  }
  return updateValidation(errors, flag, name, message);
}

const validateEmail = (errors, name, value, message) => {
  let flag = !(value && value.trim()) ? true : false;
  if (!flag)
    flag = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? false : true;
  return updateValidation(errors, flag, name, message);
}

const validateLink = (errors, name, value, message) => {
  let flag = false;
  if(!flag) 
    flag = /[a-z0-9]+\.[a-z]{2,}$/i.test(value) ? false : true
  return updateValidation(errors, flag, name, message);
}

const updateValidation = (errors,flag,name,msg) => {
  if(flag){
    errors.set(name,msg);
    return false;
  }
  errors.clear(name)
  return true;
}

export {required, validateAppIdentifierName, validateName,validate,
  validateLength,validateNumeric,validateSplChars,validateSplCharsLength,
  validateEmail,updateValidation, validateFileSize, validateFileType, 
  validateImageResolution, validateLink,
}
