import { validate, required, validateName, validateNumeric, validateAppIdentifierName, validateFileSize, validateFileType, validateImageResolution  } from '../../../../../../common/validations/Validations';
import projectMessages from '../../../../../../common/validationMessages/project';

const   validateValue = (errors, name, value) => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    switch(name){
        case "appIdentifier":
            if(value=="") {
                msg = projectMessages.E8020;
                isValid = required(errors,name,value,msg)
            }
            else {
                msg = projectMessages.E8021;
                isValid = validateAppIdentifierName(errors,name,value,msg)
            }
            break;
        case "version":
            if(value=="") {
                msg = projectMessages.E8020;
                isValid = required(errors,name,value,msg)
            }
            else {
                msg = projectMessages.E8022;
                isValid = validateNumeric(errors,name,value,msg)
            }
            break;
        case "buildNumber":
            if(value=="") {
                msg = projectMessages.E8020;
                isValid = required(errors, name, value, msg)
            }
            else {
                msg = projectMessages.E8023;
                isValid = validateNumeric(errors,name,value,msg)
            }
            break;
        case "loginApplication":
            if(value==":") {
                msg = projectMessages.E8030;
                isValid = required(errors,name,"",msg)
            }
            else {
                isValid = required(errors,name,value,msg)
            }
            break;

        default:
            break;
    }
    return isValid;
}

const validateImage = async (errors, name, value, fileSize="", fileType="", imgWidth=0, imgHeight=0) => {
    let isValid = true;
    let msg = 'Please enter valid!!';
    if(value=="") {
        msg = projectMessages.E8026;
        isValid = required(errors, name, value, msg)
    }
    else if (typeof value == "object") {
    if(value.size>fileSize){
        msg = projectMessages.E8027;
        isValid = validateFileSize(errors, name, value, msg, fileSize)
    }
    else if(value.type!==fileType){
        msg = projectMessages.E8029;
        isValid = validateFileType(errors, name, value, msg, fileType)
    }else{
        msg = projectMessages.E8028;
        isValid = await validateImageResolution(errors, name, value, msg, imgWidth, imgHeight)
    }
    }
    return isValid
}

const validateValues = async (values, errors, fields, fileSize="", fileType="", imgWidth=0, imgHeight=0) => {
    let validate = [];
    await Promise.all(Object.keys(values).map(async (name) => {
        const value = values[name]
        if(fields.includes(name)){
            if(name=="icon"){
                validate.push(await validateImage(errors,name,value, fileSize, fileType, imgWidth, imgHeight))
            }
            else {
                validate.push(validateValue(errors,name,value))
            }
        }
    }))
    return validate.includes(false);
}

export {validateValue, validateImage, validateValues}
