import { getUrl, get, put } from "../url";

export async function projectNameAndDescription (projectName) {
    let path = `${getUrl()}/configurator/v1.0/project/${projectName}`
    const [error, data] = await get(path);
    return [error, data]
}

export async function updateProjectNameAndDescription (projectName, payload) {
    let path = `${getUrl()}/configurator/v1.0/project/${projectName}`
    const [error, data] = await put(path, payload)
    return [error, data]
}