import React, { useState, useEffect, useRef } from 'react'
import styles from './advancedConfiguration.module.css'
import { useForm } from '../../../../../../components/forms/Form';
import PopUp from '../../../../../../components/layouts/PopUp';
import { validateValues, validateValue } from './validations';
import { ProjectSettingsActions } from '../../../projectsettings.actions';
import { useAppContext } from '../../../../../../components/activity/AppContext';
import {ReactComponent as DeleteSVG} from '../../../../../../images/icons/cag/deleteIcon.svg'
import {ReactComponent as AddSVG} from '../../../../../../images/icons/cag/addIcon.svg'
import SmallPopup from '../../../../../../components/layouts/smallPopup';
import { v4 as uuidv4 } from 'uuid';


function AdvancedConfiguration({ pageNumber, handleClose, rolePopUp, projectName, containerAppDetails, setContainerAppDetails, resetCADetails }) {
    let preselectedDeviceFeature = containerAppDetails?.containerAppCertificates?.filter( (e) => ["TOUCH","CAMERA","PLOCATION","CONTACTS","BLUETOOTH"].includes(e?.kind)).map(e => e?.kind)
    const [deviceFeatures, setDeviceFeatures] = useState(preselectedDeviceFeature.length?preselectedDeviceFeature:containerAppDetails?.otaUrl||containerAppDetails.containerAppBinaries.length?[]:["TOUCH","CAMERA","PLOCATION","CONTACTS","BLUETOOTH"])
    const [externalLinks, setExternalLinks] = useState(containerAppDetails?.containerAppCertificates?.filter( (e) => ["EXTLINK_URL"].includes(e?.kind)).map(e => e?.certificate)||["",])
    const [externalAccessories, setExternalAccessories] = useState(containerAppDetails?.containerAppCertificates?.filter( (e) => ["EXTERNAL_ACCESSORIES"].includes(e?.kind)).map(e => e?.certificate)||["",])
    const app = useAppContext()
    const [hadEdit, setHadEdit] = useState(0)
    const [clickedBack, setClickedBack] = useState(false)
    const [saveChangesPopup, setSaveChangesPopup] = useState(false)

    const containerAppName = containerAppDetails?.containerApp?.name||""

    const otherContainerAppCertificates = [...containerAppDetails?.containerAppCertificates?.filter( (e) => !["TOUCH","CAMERA","PLOCATION","CONTACTS","BLUETOOTH","EXTLINK_URL","EXTERNAL_ACCESSORIES"].includes(e?.kind))]

    const touchDefault = {
        certificate: ``,
        fileName: "",
        kind: "TOUCH",
        password: "",
        ...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="TOUCH")[0]
    }

    const cameraDefault = {
        certificate: `This will allow ${containerAppName} to access your camera roll to take/upload photos`,
        fileName: "",
        kind: "CAMERA",
        password: "",
        ...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="CAMERA")[0]
    }

    const gpsDefault = {
        certificate: `This will allow ${containerAppName} to show the best result based on your location`,
        fileName: "",
        kind: "PLOCATION",
        password: "",
        ...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="PLOCATION")[0]
    }

    const bluetoothDefault = {
        certificate: `This will allow ${containerAppName} to find and connect to Bluetooth accessories`,
        fileName: "",
        kind: "BLUETOOTH",
        password: "",
        ...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="BLUETOOTH")[0]
    }

    const contactsDefault = {
        certificate: `This will allow ${containerAppName} to show your friends information on your contact list`,
        fileName: "",
        kind: "CONTACTS",
        password: "",
        ...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="CONTACTS")[0]
    }

    const externalLinkDefault = externalLinks.length?[...containerAppDetails?.containerAppCertificates?.filter((e) => e?.kind=="EXTLINK_URL")]:[{
        certificate: "",
        fileName: "",
        kind: "EXTLINK_URL",
        password: "",
        id: uuidv4()
    }]

    const externalAccessoryDefault = {
        certificate: "",
        fileName: "",
        kind: "EXTERNAL_ACCESSORIES",
        password: "",
        // id: uuidv4()
    }

    const [showDescription, setShowDescription] = useState({camera:deviceFeatures.includes("CAMERA"), gps:deviceFeatures.includes("PLOCATION"), contacts:deviceFeatures.includes("CONTACTS"), touch:deviceFeatures.includes("TOUCH"), bluetooth:deviceFeatures.includes("BLUETOOTH")})
    
    const [cameraDescription, ] = useState(cameraDefault.certificate)
    const [gpsDescription, ] = useState(gpsDefault.certificate)
    const [contactsDescription, ] = useState(contactsDefault.certificate)
    const [bluetoothDescription, ] = useState(bluetoothDefault.certificate)
    const [externalLinkValues, setExternalLinkValues] =useState(externalLinkDefault)
    const [showAddLink, setShowAddLink] = useState(false) //changed from false to true
    const [externalLinkData, setExternalLinkData] = useState(externalLinkValues.map((e,idx) => {
        return {[`externalLink-${e.id}`]:e.certificate}
    }))
    const [data, setData] = useState({})
    const inlineStyles = {
        checkbox : {marginTop:"-5px"}
    }

    useEffect ( () => {
        setData({
            cameraDescription: cameraDescription,
            gpsDescription: gpsDescription,
            contactsDescription: contactsDescription,
            bluetoothDescription: bluetoothDescription,
            // deviceFeatures: [...containerAppDetails?.containerAppCertificates?.filter( (e) => ["TOUCH","CAMERA","PLOCATION","CONTACTS","EXTLINK_URL"].includes(e?.kind)).map((e => e.kind))]||[],
            deviceFeatures: deviceFeatures,
            externalAccessories: externalAccessories,
            ...externalLinkData.reduce((a, c)=> {a={...a,...c}; return a},{}) //reducing array of objects into single object
    })
    },[externalLinkData])

    const [watch, setWatch] = useState(
                                Object.keys(showDescription)
                                .filter(e=>showDescription[e])
                                .map(e1=>e1+"Description")
                                )
    
    const updateContainerAppDetails = async (newValues, errors) => {
            const prjAction = ProjectSettingsActions(app());
            const [err, data] = await prjAction.createNewCA(projectName, {...newValues})
            if(!err){
                const [err2, data2] = await prjAction.getContainerAppDetails(projectName, newValues.containerApp.id)
                    if(!err2) {
                        setContainerAppDetails({...data2.detail, genarate:false})
                    }

                if(data.status.type!=="E") {
                    if(!saveChangesPopup){
                        if (data2.detail.containerApp.deviceType.includes("iOS")) pageNumber(clickedBack?3:5)
                        else pageNumber(clickedBack?3:6)
                    }else closePopup(false)
                }
            }

    }

    const generateContainerApp = async (newValues, errors) => {
        const prjAction = ProjectSettingsActions(app());
        pageNumber(7)
        const [err, data] = await prjAction.createNewCA(projectName, {...newValues, genarate:true})
        // console.log("genarate results error data", err, data)
        if(!err) {
            // console.log("data", data)
            pageNumber(8)
            // handleClose(false)
        }
        else {
            // console.log("error", err)
            handleClose(false)
        }
    }
    
    const onSubmit = (values, errors) => {
        let deviceType = containerAppDetails.containerApp.deviceType
        let fields = getValidationFields(values);
        let result = validateValues(values, errors, fields)
        if(!result) {
            let updateDeviceFeature = []
            let updateExternalAccessories = []
            if(showDescription.touch) updateDeviceFeature = [...updateDeviceFeature,{...touchDefault,certificate:""}]
            if(showDescription.camera) updateDeviceFeature = [...updateDeviceFeature,{...cameraDefault,certificate:values.cameraDescription}]
            if(showDescription.gps) updateDeviceFeature = [...updateDeviceFeature,{...gpsDefault,certificate:values.gpsDescription}]
            if(showDescription.bluetooth) updateDeviceFeature = [...updateDeviceFeature,{...bluetoothDefault,certificate:values.bluetoothDescription}]
            if(showDescription.contacts) updateDeviceFeature = [...updateDeviceFeature,{...contactsDefault,certificate:values.contactsDescription}]
            if(externalAccessories.length) updateExternalAccessories = externalAccessories.map(e=>({...externalAccessoryDefault,certificate:e}))
            let newValues={...containerAppDetails,
                containerAppCertificates:[
                    ...otherContainerAppCertificates,
                    ...updateDeviceFeature,
                    ...updateExternalAccessories,
                    ...externalLinkValues.filter( (e)=> e["certificate"].length!==0).map( e => { 
                                                                                                if(e['id'].toString().includes("-")){
                                                                                                    delete e.id;
                                                                                                    return e}
                                                                                                    else return e}),
                ]}
                if(!clickedBack && !saveChangesPopup) {
                    if(deviceType.includes("Web")) generateContainerApp(newValues, errors)
                    else {
                        hadEdit || preselectedDeviceFeature.length!==deviceFeatures.length
                        ?updateContainerAppDetails(newValues,errors)
                        :deviceType.includes("iOS")
                            ?pageNumber(5)
                            :pageNumber(6)
                    }
                }
                else {
                    updateContainerAppDetails(newValues, errors)
                }
        }
        else {
            if(clickedBack) setClickedBack(false)
        }

    }

    const onWatch = (errors, values) => {
        setHadEdit(1)
        // validateValue(errors, values.name, values.value)
        if(values.name=="deviceFeatures") {
            if (values.value=="CAMERA") {
                if(values.checked){
                    setShowDescription((prev) => ({...prev, camera:true}))
                    setWatch((prev) => [...prev, "cameraDescription"])
                    setDeviceFeatures((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setShowDescription((prev) => ({...prev, camera:false}))
                    setWatch((prev) => prev.filter(e => e!="cameraDescription"))
                    setDeviceFeatures((prev) => prev.filter( e => e != values.value))
                }
            } 
            else if (values.value=="PLOCATION") {
                if(values.checked){
                    setShowDescription((prev) => ({...prev, gps:true}))
                    setWatch((prev) => [...prev, "gpsDescription"])
                    setDeviceFeatures((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setShowDescription((prev) => ({...prev, gps:false}))
                    setWatch((prev) => prev.filter(e => e!="gpsDescription"))
                    setDeviceFeatures((prev) => prev.filter( e => e != values.value))
                }
            }
            else if (values.value=="CONTACTS") {
                if(values.checked){
                    setShowDescription((prev) => ({...prev, contacts:true}))
                    setWatch((prev) => [...prev, "contactsDescription"])
                    setDeviceFeatures((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setShowDescription((prev) => ({...prev, contacts:false}))
                    setWatch((prev) => prev.filter(e => e!="contactsDescription"))
                    setDeviceFeatures((prev) => prev.filter( e => e != values.value))
                }
            }
            else if (values.value=="BLUETOOTH") {
                if(values.checked){
                    setShowDescription((prev) => ({...prev, bluetooth:true}))
                    setWatch((prev) => [...prev, "bluetoothDescription"])
                    setDeviceFeatures((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setShowDescription((prev) => ({...prev, bluetooth:false}))
                    setWatch((prev) => prev.filter(e => e!="bluetoothDescription"))
                    setDeviceFeatures((prev) => prev.filter( e => e != values.value))
                }
            }
            else if (values.value == "TOUCH") {
                if(values.checked) {
                    setShowDescription((prev) => ({...prev, touch:true}))
                    setDeviceFeatures((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setShowDescription((prev) => ({...prev, touch:false}))
                    setDeviceFeatures((prev) => prev.filter( e => e != values.value))
                }
            }
        }
        else if(values.name.includes("externalLink")) {
            values.value = values.value.replace(/\s/g, '')
            // externalLinkValues[values.name.split("-")[1]].certificate=values.value
            externalLinkValues.find(e => e.id==values.name.split("externalLink-")[1]).certificate = values.value
            if(values.value) {
                setWatch((prev) => [...new Set([...prev, values.name])])
            } else {
                setWatch((prev) => prev.filter(e => e!=values.name))
            }
            if(values.value){
                setShowAddLink(true)
            }else {
                setShowAddLink(false) //commented it
    }

        }
        else if(values.name.includes("externalAccessories")) {
            if (values.value=="ZebraScanner") {
                if(values.checked){
                    setExternalAccessories((prev) => ([...new Set([...prev, values.value])]))
                }else {
                    setExternalAccessories((prev) => prev.filter( e => e != values.value))
                }
            } 
        }
        validateValue(errors, values.name, values.value)
    }

    const getValidationFields = (values) => {
        return [...watch];
    }

    const handleAddLink = (e) => {
        setExternalLinkValues( (prev) => [...prev, {certificate: "",fileName: "",kind: "EXTLINK_URL",password: "", id:uuidv4()}])
        setShowAddLink(false) //commented it
    }

    const handleDeleteLink = (e, idx, arr) => {
        let newExternlLinkValues = externalLinkValues.filter((item,i) => {;return item.id!=e.target.value})
        setExternalLinkValues(newExternlLinkValues)
        // setExternalLinkData(newExternlLinkValues.map((e,idx) => {
        //     return {[`externalLink-${e.id}`]:e.certificate}
        // }))
        setShowAddLink(true)
    }


    const handleClosePopup = (bool) => {
        if(hadEdit) setSaveChangesPopup(true)
        else closePopup(bool)
    }

    const closePopup = (bool) => {
        resetCADetails();
        handleClose(bool)
    }

    const handleBack = (e) => {
        setClickedBack(true)
        setTimeout(()=>{
            if(hadEdit){
                submitHandler(e)
            }
            else {
        pageNumber(3)
    }
        },0)     
    }
    const { Form, Label, Input, Error, errors, TextArea, Checkbox, submitHandler } = useForm(data, onSubmit, onWatch);
    return (
        <PopUp
            popUp={rolePopUp}
            setPopUp={handleClosePopup}
            titleClassName={styles.afModuleTitle}
            testId={"devicePermissions"}
            title="Let’s capture some device permissions"
            subtitle=" If you are distributing the app via public app stores, then this description will be used by them in the approval process"
                >
                <Form style={{ width: '100%' }}>
                    <div className={styles.formContent}>
                        <div className={styles.contentWrapper}>
                            <div className={styles.contents}>
                                <h4
                                    className={styles.afModuleSubTitleVaried}
                                >
                                    Device Features
                                </h4>
                                <div className={styles.individualContent}>
                                    <Checkbox type="checkbox" name="deviceFeatures" testId={"touch"} value={"TOUCH"} style={inlineStyles.checkbox} selecteditem={deviceFeatures}/>
                                    <Label text="Biometric Authentication" />
                                </div>
                                <div className={styles.individualContent}>
                                    <div>
                                        <Checkbox type="checkbox"  name="deviceFeatures" testId={"camera"} value={"CAMERA"} style={inlineStyles.checkbox} selecteditem={deviceFeatures}/>
                                    </div>
                                    <div style={{ width: "80%" }}>
                                        <Label text="Camera" />
                                        {showDescription.camera && (<><TextArea style={{ width: "100%", height:"81px" }} maxLength="200" name="cameraDescription" placeholder="Please elaborate why this app needs access to the device camera"/>
                                        <Error name={"cameraDescription"}/></>)}
                                    </div>
                                </div>
                                <div className={styles.individualContent}>
                                    <div>
                                        <Checkbox type="checkbox" name="deviceFeatures" testId={"gps"} value={"PLOCATION"} style={inlineStyles.checkbox} selecteditem={deviceFeatures}/>
                                    </div>
                                    <div style={{ width: "80%" }}>
                                        <Label text="GPS" />
                                        {showDescription.gps && (<><TextArea style={{ width: "100%", height:"81px" }} maxLength="200" name="gpsDescription" placeholder="Please elaborate why this app needs access to the device GPS"/>
                                        <Error name="gpsDescription"/></>)}
                                    </div>
                                </div>
                                <div className={styles.individualContent}>
                                    <div>
                                        <Checkbox type="checkbox" style={inlineStyles.checkbox} name="deviceFeatures" testId={"bluetooth"} value={"BLUETOOTH"} selecteditem={deviceFeatures}/>
                                    </div> 
                                    <div style={{ width: "80%" }}>
                                        <Label text="Bluetooth" />
                                        {showDescription.bluetooth && (<><TextArea style={{ width: "100%", height:"81px" }} maxLength="200" name="bluetoothDescription" placeholder="Please elaborate why this app needs access to the device Bluetooth"/>
                                        <Error name="bluetoothDescription"/></>)}
                                    </div>
                                </div>
                                <div className={styles.individualContent}>
                                    <div>
                                        <Checkbox type="checkbox" name="deviceFeatures" testId={"contacts"} value={"CONTACTS"} style={inlineStyles.checkbox} selecteditem={deviceFeatures}/>
                                    </div>
                                    <div style={{ width: "80%" }}>
                                        <Label text="Contacts" />
                                        {showDescription.contacts &&(<><TextArea style={{ width: "100%", height:"81px" }} maxLength="200" name="contactsDescription" placeholder="Please elaborate why this app needs access to the device contacts"/>
                                        <Error name={"contactsDescription"}/></>)}
                                </div>
                                </div> 
                            </div>
                            <div className={styles.contents}>
                                    {/* <div className={styles.individualContent}>
                                        <div>
                                            <Checkbox type="checkbox" name="deviceFeatures" testId={"contacts"} value={"CONTACTS"} style={inlineStyles.checkbox} selecteditem={deviceFeatures}/>
                                        </div>
                                        <div style={{ width: "80%" }}>
                                            <Label text="Contacts" />
                                            {showDescription.contacts &&(<><TextArea style={{ width: "100%", height:"81px" }} maxLength="200" name="contactsDescription" placeholder="Please elaborate why this app needs access to the device contacts"/>
                                            <Error name={"contactsDescription"}/></>)}
                                        </div>
                                    </div> */}
                                {/* <div style={{display:"flex", alignItems: "center", height:"60px"}}> */}
                                <h4
                                    className={styles.afModuleSubTitleVaried}
                                >
                                    External Links
                                </h4>
                                {/* {showAddLink?
                                        <span style={{ display: "inline-block", marginLeft: "24px"}}>
                                            <Label text={<AddSVG style={{width:"16px", verticalAlign:"middle"}} />} className={`secondary ${styles.addLink}`} htmlFor={"addLink"}/>
                                            <Input type="button" onClick={handleAddLink} value={"addLink"} name={"addLink"} id={"addLink"} style={{display:"none"}} />
                                        </span>
                                        :<span style={{ display: "inline-block", marginLeft: "24px", height:"60px"}}>
                                    </span>
                                    } */}
                                {/* </div> */}

                                {externalLinkValues.map( (e, idx, arr) => {
                                    return (
                                        <div className={styles.individualContent} key={e.id}>
                                            <div style={{flexBasis:"87%", paddingLeft:"4px"}}>
                                            <Input type="text" name={`externalLink-${e.id}`} placeholder="url" style={{ width: "100%" }} />
                                            <Error name={`externalLink-${e.id}`} />
                                            </div>
                                            {idx!==0&&
                                                <>
                                                    <Label text={<DeleteSVG style={{width:"24px", verticalAlign:"middle"}} />} className={`secondary ${styles.deleteLink}`} htmlFor={`externalLink-${e.id}`}/>
                                                    <Input type="button" data-clickable-testid="delete-link" onClick={(e)=>{handleDeleteLink(e,idx,arr)}} value={e.id} name={`externalLink-${e.id}`} id={`externalLink-${e.id}`} style={{display:"none"}} />
                                                </>   
                                            }
                                        </div>)})}
                                    {showAddLink&&
                                        <div className={styles.individualContent}>
                                            <Label text={<AddSVG style={{width:"24px", verticalAlign:"middle"}} />} className={`secondary ${styles.addLink}`} htmlFor={"addLink"}/>
                                            <Input type="button" onClick={handleAddLink} value={"addLink"} name={"addLink"} id={"addLink"} style={{display:"none"}} />
                                        </div>
                                    }
                                <h4
                                    className={styles.afModuleSubTitleVaried}
                                >
                                    External Accessories
                                </h4>

                                <div className={styles.individualContent}>
                                        <div>
                                            <Checkbox type="checkbox" name="externalAccessories" testId={"zebraScanner"} value={"ZebraScanner"} style={inlineStyles.checkbox} selecteditem={externalAccessories}/>
                                        </div>
                                        <div style={{ width: "80%" }}>
                                            <Label text="Zebra Bluetooth Scanner (iOS)" />
                                        </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                    <div className='afPopupFooter'>
                        <div>
                            <Input data-clickable-testid="back" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Back"} onClick={handleBack} />
                            <Input type="submit" value={containerAppDetails.containerApp.deviceType.includes("Web")?"Generate":"Next"} style={{width:"fit-content",padding:"calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px)"}}/>
                        </div>
                    </div>
                </Form>
                {saveChangesPopup&&<SmallPopup testId={"saveChangesConfirmationPopup"} title={"Draft Changes"} subtitle={"Do you want to draft your changes?"} handleSubmit={(e)=>{submitHandler(e)}} icon={"saveChanges"} buttonTestId2={"Yes"} buttonTestId1={"No"} buttonName1={"No"} buttonName2={"Yes"} popUp={saveChangesPopup} handlePopUp={(e)=>{setSaveChangesPopup(false);if(e.target.tagName==="BUTTON") closePopup(false)}}/>}
        </PopUp>
    )
}

export default AdvancedConfiguration