import { TableHeaderSelect } from "../tableHeaderSelect/tableHeaderSelect";
import TableHeaderInput from "../tableHeaderInput/tableHeaderInput";
// import { TableHeaderSelect } from "../tableheaderSelect/tableHeaderSelect";
// import TableHeaderInput from "../tableheaderInput/tableHeaderInput";
import UserListRoles from "../../pages/administration/userManagement/usersAndRoles/userListRoles";
import CheckBox from "../tableCheckBox/tableCheckBox";
import imageFile from "../../images/imageFile.png";
import folderFile from "../../images/folder.svg";
import textFile from "../../images/textFile.png";
import EditPopUp from "../EditPopup/editPopUp";
import React, { useEffect } from "react";
import "./table.css";

export default function Table(props) {
    const {
        data = [],
        roleData = [],
        width = "100%",
        modifyPopUp = [],
        headerColumns = [],
        onRowClick = () => null,
        contextOnChange = () => null,
        onRowDoubleClick = () => null,
        handleHeaderInputChange = () => null,
        handleHeaderSelectChange = () => null,
        checkBoxOnChange = () => null,
        name = "",
        handleSingleAndDoubleClick = () => null,
    } = props;

    // console.log(data)

    return (
        <table
            data-table-testid={name}
            className="userlist-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ width: width }}
        >
            <thead className="userlist-thead">
                <tr>
                    {headerColumns &&
                        headerColumns.map((dt, index) => {
                            if (dt.type === "input")
                                return (
                                    <TableHeaderInput
                                        id={dt.id}
                                        width={dt.width}
                                        placeholder={dt.name}
                                        index={index}
                                        handleHeaderInputChange={handleHeaderInputChange}
                                        disabled={dt.disabled}
                                        style={dt?.style}
                                    />
                                );
                            else if (dt.type === "select" || dt.type === "checkboxSelect")
                                return (
                                    <TableHeaderSelect
                                        id={dt.id}
                                        index={index}
                                        width={dt.width}
                                        // value={dt.value}
                                        placeholder={dt.name}
                                        style={dt?.style?dt.style:{}}
                                        value={
                                            dt.value ? Object.values(dt.value).map((val) => val) : ""
                                        }
                                        handleHeaderSelectChange={handleHeaderSelectChange}
                                        disabled={dt.disabled}
                                    />
                                );
                            else if (dt.type === "role")
                                return (
                                    <TableHeaderSelect
                                        id={dt.id}
                                        index={index}
                                        width={dt.width}
                                        placeholder={dt.name}
                                        value={roleData.map((dt) => dt.name)}
                                        handleHeaderSelectChange={handleHeaderSelectChange}
                                        type="role"
                                    />
                                );
                            else if (dt.type === "moreVertIcon")
                                return (
                                    <th
                                        index={index}
                                        style={{ color: "#CDD4E4", width: dt.width, ...dt.style }}
                                        data-col-testid={"contextmenu"}
                                    >
                                        {dt?.headerMenu ?
                                            <EditPopUp
                                                value={""}
                                                options={
                                                    typeof modifyPopUp == "function"
                                                        ? modifyPopUp()
                                                        : modifyPopUp
                                                }
                                                onChange={(option, value, key) =>
                                                    contextOnChange(option, value, key)
                                                }
                                            /> : ""}
                                    </th>
                                );
                            else if (dt.type === "checkBox")
                                return <th index={index} style={{ width: dt.width }}></th>;
                            else if (dt.type === "radio")
                                return <th index={index} style={{ width: dt.width }}></th>;
                            else if (dt.type === "image")
                                return <th index={index} style={{ width: dt.width }}></th>;
                            else if (dt.type === "button") {
                                return <th index={index} style={{ width: dt.width }}>
                                    <button className={"tableHeaderButton"}>
                                        {dt.name}
                                    </button>
                                </th>;
                            }
                            else if (dt.layoutType === "dataGrid")
                                return (
                                    <th
                                        className="datagrid-th"
                                        index={index}
                                        data-tablehead-testid={dt.name}
                                        style={{ maxWidth: dt.width, fontWeight: "500" }}
                                    >
                                        {" "}
                                        {dt.name}
                                    </th>
                                );
                            else
                                return (
                                    <th data-tablehead-testid={dt.name} index={index} style={dt.style}>
                                        {" "}
                                        {dt.name}
                                    </th>
                                );
                        })}
                </tr>
            </thead>
            <tbody className="tableBody">
                {data.length > 0 ? (
                    <>
                        {data.map((item, index) => {
                            return (
                                <tr
                                    data-row-testid={index}
                                    key={index}
                                    className="userlist-tr-td"
                                    style={item?.isActive!==undefined?item.isActive?({}):({color:"rgb(0, 0, 0, 0.5)"}):({})}
                                // onClick={(event) => onRowClick(item, event)}

                                >
                                    {headerColumns.map((header, index) => {
                                        if (
                                            header.id === "roles" ||
                                            header.id === "type" ||
                                            header.id === "projects" ||
                                            header.id === "usedIn" ||
                                            header.id === "modifiedUser" ||
                                            header.id === "loginName" ||
                                            header.id === "displayname"
                                        )
                                            return (
                                                <td
                                                    className="userlist-td-role"
                                                    key={index}
                                                    onClick={(event) => onRowClick(item, event)}
                                                    data-col-testid={header.id}
                                                    onDoubleClick={(event) =>
                                                        onRowDoubleClick(item, event)
                                                    }

                                                >
                                                    <UserListRoles userRoles={item[header.id]} />
                                                </td>
                                            );
                                        else if (header.id === "moreVertIcon")
                                            return (
                                                <td
                                                    className="userlist-td-icon"
                                                    key={index}
                                                    data-col-testid={"contextmenu"}
                                                    style={header.style}
                                                >
                                                    <EditPopUp
                                                        value={item}
                                                        options={
                                                            typeof modifyPopUp == "function"
                                                                ? modifyPopUp(item)
                                                                : modifyPopUp
                                                        }
                                                        onChange={(option, value, key) =>
                                                            contextOnChange(option, value, key)
                                                        }
                                                    />
                                                </td>
                                            );
                                        else if (header.type === "checkBox")
                                            return (
                                                <td
                                                    data-col-testid={item[header.id]}
                                                    className="userlist-td-icon"
                                                    key={index}
                                                >
                                                    <div style={item?.style}>
                                                        <CheckBox 
                                                            value={item?.value}
                                                            name={item?.name}
                                                            defaultCheck={item?.isChecked}
                                                            onChange={checkBoxOnChange}
                                                            checkboxValue={item?.checkboxValue}
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        else if (header.type === "checkboxSelect") {
                                            return (
                                                <td
                                                    // data-col-testid={item[header.id]}
                                                    data-col-testid={name === "permissions-list" ? header.name : item[header.id]}
                                                    className="userlist-td-icon"
                                                    key={index}
                                                >
                                                    <div>
                                                        <CheckBox
                                                            onChange={checkBoxOnChange}
                                                            defaultCheck={item[header.id]}
                                                            name={item["project"] || item['uid']}
                                                            value={header.name}
                                                            listName={name}
                                                            optionTestId={header.name}
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        } else if (header.type === "statusIcon" ) {
                                            return (
                                                <td 
                                                    onClick={() => {header.handleImagePopup(item.id)}}
                                                >
                                                    {item[header.id]}
                                                </td>
                                            )

                                        } else if (header.type === "image") {
                                            let imageData = "";
                                            if (item[header.id] === ".txt") imageData = textFile;
                                            else if (!item[header.id]) imageData = folderFile;
                                            else imageData = imageFile;
                                            return (
                                                <td
                                                    data-col-testid={item[header.id]}
                                                    className="userlist-td-icon"
                                                    key={index}
                                                >
                                                    <img
                                                        src={imageData}
                                                        alt={""}
                                                        style={{ width: "28px", height: "24px" }}
                                                    />
                                                </td>
                                            );
                                        } else if (header.layoutType === "dataGrid") {
                                            return (
                                                <td
                                                    data-col-testid={header.id}
                                                    key={index}
                                                    style={{ maxWidth: header.width }}
                                                >
                                                    {item[header.id]}
                                                </td>
                                            );
                                        }
                                        else if (header.type === "upload") {
                                            return <td>
                                                <button className="userlist-upload-button" onClick={() => header.OnClick(item)}>
                                                    <img src={header?.icon} className="userlist-upload-image" />
                                                    Upload</button>
                                            </td>
                                        }
                                        else if (header?.image) {
                                            var urlChar = header.image.slice(-2)
                                            // console.log(urlChar)
                                            return (
                                                <td
                                                    className="userlist-td-icon"
                                                    data-clickable-testid={urlChar === "==" ? "view-schedule" : "edit-schedule"}
                                                    // data-clickable-testId="view-schedule"
                                                    onClick={() =>
                                                        header.handleImagePopup.handleImagePopup(
                                                            header.name,
                                                            item
                                                        )
                                                    }
                                                >
                                                    <img src={header.image}  />
                                                </td>
                                            );
                                        } 
                                        else if (header?.svg) {
                                     
                                            // console.log(urlChar)
                                            return (
                                                <td
                                                    className="userlist-td-icon"
                                                    data-clickable-testid={urlChar === "==" ? "view-schedule" : "edit-schedule"}
                                                    // data-clickable-testId="view-schedule"
                                                    onClick={() =>
                                                        header.handleImagePopup.handleImagePopup(
                                                            header.name,
                                                            item
                                                        )
                                                    }
                                                >
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fontSize: '24px' }} width="24" height="24">
                                                    <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" fill="#0062FF"></path>
                                                </svg>
                                                </td>
                                            );
                                        } 
                                        
                                        else if (header?.icon) {
                                            if (item.isEnabled === 1) {
                                                return (
                                                    <td
                                                        data-clickable-testid="enable-disable-schedule"
                                                        style={{pointerEvents:header?.disabled?"none":"auto"}}
                                                        className="userlist-td-icon"
                                                        onChange={() =>
                                                            header.handleImagePopup(header.name, item)
                                                        }
                                                    >
                                                        <div>
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked={true} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                );
                                            } else if (item.isEnabled === 0) {
                                                return (
                                                    <td
                                                        className="userlist-td-icon"
                                                        data-clickable-testid="enable-disable-schedule"
                                                        style={{pointerEvents:header?.disabled?"none":"auto"}}
                                                        onChange={() =>
                                                            header.handleImagePopup(header.name, item)
                                                        }
                                                    >
                                                        <label className="switch" >
                                                            <input type="checkbox" defaultChecked={false} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                );
                                            }
                                            else if (item.isEnabled === 2) {
                                                return (
                                                    <td
                                                        className="userlist-td-icon"
                                                        data-clickable-testid="enable-disable-schedule"
                                                        style={{ fontSize: "13px" }}
                                                    >
                                                        Draft
                                                    </td>
                                                );
                                            }

                                            else if (item.baasEnable || !item.baasEnable) {
                                                return (
                                                    <td
                                                        className="userlist-td-icon"
                                                        data-text-testid="enable-disable-switch"
                                                        style={{pointerEvents:header?.disabled?"none":"auto"}}
                
                                                        key={item.id}
                                                    >
                                                        {/* <h5 style={{ color: "rgb(164, 164, 164)", fontFamily: "Saira", fontWeight: "normal", lineHeight: "24px" }}>Draft</h5> */}
                                                        <label className="switch"  
                                                         onChange={() =>
                                                            header.handleImagePopup(header.name, item)
                                                        }>
                                                            <input data-option-testid="checkbox" type="checkbox" defaultChecked={item.baasEnable} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td className="userlist-td-icon">
                                                        <p>Draft</p>
                                                    </td>
                                                );
                                            }
                                        } else if (header?.toggleImage) {
                                            if (item.status === "F") {
                                                return (
                                                    <td
                                                        className="userlist-td-icon"
                                                        onClick={() =>
                                                            header.handleImagePopup(header.name, item)
                                                        }
                                                        data-col-testid={"report-icon"}
                                                    >
                                                       <svg xmlns="http://www.w3.org/2000/svg" className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fontSize: '24px' }} width="24" height="24">
                                                        <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="#0062FF" />
                                                      </svg>

                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td
                                                        onClick={() =>
                                                            header.handleImagePopup(header.name, item)
                                                        }
                                                        data-col-testid={"scheduler-icon"} className="userlist-td-icon" title={"No logs available"}>
                                                       <svg xmlns="http://www.w3.org/2000/svg" className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fontSize: '24px' }} width="24" height="24">
                                                            <path d="M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-3h-2v-2h2v2z" fill="#DB252E" />
                                                        </svg>

                                                    </td>
                                                );
                                            }
                                        } else
                                            return ( 
                                                <td
                                                    className="userlist-td"
                                                    key={index}
                                                    data-col-testid={
                                                        header.id === "description" &&
                                                            name === "role-permissions-table"
                                                            ? "name"
                                                            : header.type === "select" && name === "userlist"
                                                                ? header.name
                                                                : header.id
                                                    }
                                                    style={item?.style?item.style[header.id]:{}}
                                                    onClick={(event) => handleSingleAndDoubleClick(item, event)}
                                                // onClick={(event) => onRowClick(item, event)}
                                                // onDoubleClick={(event) =>
                                                //     onRowDoubleClick(item, event)
                                                // }
                                                >
                                                    {typeof item[header.id] === "boolean" ? (
                                                        <>
                                                            {item[header.id] === true ? "Is Msp" : "Not Msp"}
                                                        </>
                                                    ) : (
                                                        item[header.id]
                                                    )}
                                                </td>
                                            );
                                    })}
                                </tr>
                            );
                        })}
                    </>
                ) : (
                    <tr>
                        <td
                            data-col-testid={"No Record Exists"}
                            colSpan={headerColumns.length}
                            style={{ textAlign: "center", paddingTop: "20px", color: "lightgray" }}
                        >
                            No Record Exists
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}