import { Shape } from './shape';
import { Parameter, Metadata } from './application';
import { DialogBox } from "./dialogbox"
import { util, utils } from "../js/services"
import { Functions, Property, NodeProperty } from './functions';
import {download, getData, getData as serviceGetData} from '../../../../../helpers/integrationbuilder';
import { postData as servicePostData } from '../../../../../helpers/integrationbuilder';
import {
	AssignmentShape,
	PolyShape,
	MathShape,
	ConditionShape,
	IfElseShape,
	IfShape,
	EllipseShape,
	MiniShape,
	SapFunctionShape,
	ShapeDownload,
	ShapeGenerateFile,
	FreedomDBSelectShape,
	FreedomDBShape,
	FunctionShape,
	DriveShape,
	FTPShape,
	SAPShape,
	ServicesShape,
	UserShape,
	NotificationShape,
	BosShape,
	SortShape,
	CustomBlockShape,
	EmptyShape,
	SqlStatementShape,
	commentBlockShape,
	TerminateShape,
	AuthenticationShape,
	WorkflowShape,
	UserListShape,
	UserUpdateShape,
	PasswordPolicyShape,
	LDAPShape
} from "./shape"

import {
	ChangeCommand,
	SpliceCommand,
	PopCommand,
	CommentCommand,
	UnCommentCommand,
	DeleteNodeCommand,
	ChangeGroupCommand
} from "./commands"

import {
	SystemsEvent,
	ConfigEvent,
	FunctionInputEvent,
	FunctionOutputEvent,
	FDBTableEvent,
	FDBSelectColumnEvent,
	AssignToColumnEvent,
	LocalTableColoumnsEvent,
	ConditionEvent,
	LocalTableGetEvent,
	ChangeEvent,
	SalutationListEvent,
	BosEvent,
	MappingTableColoumnsEvent,
	SFTableEvent,
	SFSelectColumnEvent,
	BOSParamEvent,
	PluginListEvent,
	ModuleListEvent,
	WebServiceParamEvent,
	SAPModuleListEvent,
	SAPColumnEvent,
	variableDropDownEvent,
	TableColoumnsEvent,
	deleteNode,
	PlugInSystemsEvent,
	UserPropertiesListEvent,
	UserUpdatePropertiesListEvent,
	SnowFlakeDbEvent,
	SnowFlakeSchemaEvent,
	undoEvent,
	AppsEvent,
	BusinessFunctionEvent,
	PushVariablesListEvent,
	AssignToTableEvent
} from "./builderEvents"
import { Messages, NonDropables, LoopTypes, BaseURL, DriveListsColumns } from './message';
import { Properties } from './properties';

function Node(name, x, y, type) {
	this.shape = new Shape(x, y, name, type);
	this.name = name;
	this.type = type;
	this.childs = new Array();
	this.parameter = new Parameter();
	this.callback = null;
	this.no = -1;
	this.parent = null;
	this.minimize = 0;
	this.lane = "";
	this.metadata = new Metadata();
	// this.input = [];
	// this.output = [];
	this.uiRenderTags = [];
	this.dataType = '';
	this.active = 0;
	this.isCommented = 0;
	this.haveChild = false;
	this.plugin = '';
	this.connection = '';
	this.identity = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase();
	this.nodeLeveluiRenderTags = ['minimize', 'lane', 'isCommented', 'mapped', 'mapping','ifChildType','parentValue', 'parentKey', 'parentposition'];
}

Node.prototype = {
	addClickHandler: function () {
		var n = this;
		var temp = null;
		if (this.shape.group) {
			this.shape.group.on("selected", function () {
				if (window.application.selectedNode)
					window.application.selectedNode.shape.objs[0].set({ strokeWidth: 0 })
				window.application.selectedNode = n;
				n.shape.objs[0].set({ stroke: '#FFF021', strokeWidth: 2 });
				// window.application.refresh();
			});
			this.shape.group.on("dragenter", function (obj) {
				//window.application.dragoverNode=null;
				/*if ( !window.application.moveEvent && !(n instanceof EmptyNode)
					&& NonDropables.indexOf(window.application.dragType) == -1) {
					//console.log("---------- dragenter");
					obj.e.preventDefault();
					window.application.moveEvent = true;
					window.application.removePlaceHolder();
					const x = obj.e.layerX;
					const y = obj.e.layerY;
					// console.log("x : ",x,", y : ", y );
					window.application.findPosition(n,y);
					window.application.moveEvent = false;
				}*/
			});
			this.shape.group.on("dragover", function (obj) {
				if (!window.application.moveEvent && !(n instanceof EmptyNode || n.identity == window.application.dragoverNode)
					&& NonDropables.indexOf(window.application.dragType) == -1) {
					console.log(n.name + n.type);
					obj.e.preventDefault();
					window.application.moveEvent = true;
					window.application.removePlaceHolder();
					const x = obj.e.layerX;
					const y = obj.e.layerY;
					// console.log("x : ",x,", y : ", y );
					window.application.findPosition(n,y);
					window.application.moveEvent = false;
					window.application.dragoverNode=n.identity;
				}
			});
			this.shape.group.on("drop", function (obj) {
				console.log("drop");
				let origin = obj.e.dataTransfer.getData("origin");
				let data = obj.e.dataTransfer;
				//window.application.moveEvent = false;
				if (origin === 'leftMenu' && window.application.targetNode) {
					let x, y;
					let title = data.getData("caption");
					let type = data.getData("type");
					if (NonDropables.indexOf(type) == -1) {
						x = obj.e.layerX;
						y = obj.e.layerY;
						window.application.moveEvent = true;
						window.application.selectedNode = n;
						window.application.removePlaceHolder();
						window.application.createNode(title, x, y, type);
						window.application.audio.play();
						window.application.moveEvent = false;
					}
				} else {
					if (window.application.targetNode) {
						window.application.targetNode = null;
					}
				}
				window.application.dragoverNode=null;
			});
			this.shape.group.on("mousedown", function (event) {
				event.e.preventDefault();
				console.log("---------- mousedown");
				if (event.button === 3) {
					window.application.movingOnNode=null;
					window.application.selectedNode = n;
					window.application.selectedObj = null;
					// const top=window.application.canvas.canvas._offset.top;
					// window.application.showContextMenu(event.e.clientX,event.e.clientY-105);
					window.application.handleDropDownClose();
					window.application.showContextMenu(event.e);
				} else if (event.button == 1) {
				}
			});
			this.shape.group.on("moving", function (event) {
				// window.application.moveEvent = true;
				console.log("---------- moving");
				if (!window.application.moveEvent && n.active == 0) {
					let index = -1;
					window.application.moveEvent = true;
					if (n.parent?.type == 'ie') {
						index = n.parent.ifChilds.indexOf(n);
						if (index > -1) {
							window.application.childType = 'if';
							n.parent.ifChilds.splice(index, 1);
						} else {
							window.application.childType = 'else';
							index = n.parent.elseChilds.indexOf(n);
							n.parent.elseChilds.splice(index, 1);
						}
					} else {
						index = n.parent?.childs.indexOf(n);
						if (index > -1) {
							n.parent.childs.splice(index, 1);
						}
					}
					window.application.moveNodeIndex = index;
					window.application.refresh();
					n.active = 1;
					window.application.moveEvent = false;
				}
				if (!window.application.moveEvent) {
					window.application.moveEvent = true;
					window.application.highlightNode(event.e.layerX, event.e.layerY + 5);
					window.application.canvas.add(n.shape.group);
					window.application.moveEvent = false;
				}
			});
			this.shape.group.on("moved", function (e) {
				console.log("---------- moved");
				window.application.moveEvent = true;
				window.application.removePlaceHolder();
				window.application.moveNode();
				n.active = 0;
				window.application.audio.play();
				window.application.moveEvent = false;
			});

		}
		// if (this.hasOwnProperty("system") &&  (this.shape instanceof SapFunctionShape || this.shape instanceof FreedomDBShape)) {
		// 	this.addSystemEvent(n);
		// } 
		// if (this.hasOwnProperty('sort')) {
		// 	this.addSortHandler(n);
		// } if (this.hasOwnProperty("where")) {
		// 	this.addWhereHandler(n);
		// } if (this.hasOwnProperty("table")) {
		// 	this.addTableHandler(n);
		// } if (this.hasOwnProperty('columns') && !(this instanceof FreedomDBSelectNode || this instanceof DBAdapterSelectNode)) {
		// 	this.addColumnHandler(n);
		// } 
		// if (this.hasOwnProperty("to")) {
		// 	this.addToHandler(n);
		// }
		this.addHandler();
		if(this.minimize != 1)
			this.addEvents();
	},
	addEvents: function () {
	},
	constructParams: function () {
	},
	addToGroup: function (objs) {
		if (this.childs && this.childs.length > 0) {
			for (const child of this.childs) {
				window.application.canvas.remove(child.shape.group);
				for (const o of child.shape.objs) {
					o.set({ left: child.shape.x, top: child.shape.y });
					objs.push(o);
				}
				objs = child.addToGroup(objs);
			}
		}
		return objs;
	},
	addSystemEvent: function (node) {
		let obj = new Object();
		obj.shape = [this.shape.system, this.shape.system_arrow, this.shape.system_text];
		this.addDropDownHandler(obj, node, 'system', function (e) {
			let x = e.e.layerX;
			let y = e.e.layerY + 30;
			let temp = window.application.createDropdownMenu(new SystemsEvent(e.e, x, y));
			temp.load(node, 0);
		});
		if (this.shape.username) {
			let obj = new Object();
			obj.styleIn = { fill: '#E2D0FF' };
			obj.styleOut = { fill: '#FFFFFF' };
			obj.shape = this.shape.username;
			obj.origin = 'rightMenu';
			obj.prop = this.username;
			this.addVariableHandler(obj, this);
		}
		if (this.shape.password && this.shape.password1) {
			obj = new Object();
			obj.shape = this.shape.password1;
			obj.shape1 = this.shape.password;
			obj.property = 'password';
			obj.shapeVariable = 'password1';
			this.addVariableValueHandler(obj, this);
		}

	},
	addHandler: function () {
	},
	addPropertyHandler: function (object, handler) {
		const { parameter, canvas } = window.application;
		let shape = object.dragShape ? object.dragShape : object.shape;
		object.shape.on('dragenter', function (event) {
			//if(NonDropables.indexOf(window.application.dragType) > -1){
			let origin = event.e.dataTransfer.getData("origin");
			if (object.origin === 'rightMenu') {
				shape.set(object.styleIn);
				window.application.canvas.renderAll();
			}
			//}
		});
		object.shape.on('dragleave', function (obj) {
			shape.set(object.styleOut);
			window.application.canvas.renderAll();
		});
		object.shape.on('drop', handler);
	},
	addConditionHandler: function (object, node) {
		this.addPropertyHandler(object, function (event) {
			let flag = false;
			let data = event.e.dataTransfer;
			if (data.getData("origin") === 'rightMenu' && node.type != 'la') {
				let val = 'BOS$' + data.getData("scope") + '.$' + data.getData("caption");
				let dataTypeCheck = true;
				if (object.dataType) {
					if (object.dataType != data.getData("type"))
						dataTypeCheck = false;
				}
				if (object.propertyValue && dataTypeCheck) {
					if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey], node[object.property]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue], node],
							[object.propertyKey, object.property], prev_val, [node[object.propertyValue][object.position][object.propertyKey], node[object.property]], object.position));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue], node[object.property][object.propertyKey]];
						node[object.propertyValue][object.propertyKey] = val;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue], node], [object.propertyKey, object.property],
							prev_val, [node[object.propertyValue], node[object.property][object.propertyKey]]));
					}
					else {
						let prev_val = [node[object.propertyValue], node[object.property]];
						node[object.propertyValue] = val;
						window.application.addCommand(new ChangeCommand(node, [object.propertyValue, object.property],
							prev_val, [node[object.propertyValue], node[object.property]]));
					}
				} else {
					flag = true;
				}
				node[object.property] = null;
				if (object.mapped)
					node[object.mapped] = false;
				if (object.mapping)
					node[object.mapping] = null;
			} else if (data.getData("origin") === 'leftMenu' && (data.getData("type") == 'c' || data.getData("type") == 'la')) {
				let title = data.getData("caption");
				let type = data.getData("type");
				if ((node.type == 'la' && data.getData("type") == 'c') || ((node.type == 'lp' || node.type == 'if' || node.type == 'ie') && (data.getData("type") == 'c'
					|| data.getData("type") == 'la'))) {
					if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.property], node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.property] = window.utils.createNode(title, 15, 4, type);
						if (object.propertyValue)
							node[object.propertyValue][object.position][object.propertyKey] = '';
						// node.shape.calculateHeight(node);
						window.application.addCommand(new ChangeCommand(node[object.propertyValue],
							[object.property, object.propertyKey], prev_val, [node[object.propertyValue][object.position][object.property], node[object.propertyValue][object.position][object.propertyKey]], object.position));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.property], node[object.propertyValue][object.propertyKey]];
						node[object.propertyValue][object.property] = window.utils.createNode(title, 15, 4, type);
						if (object.propertyValue)
							node[object.propertyValue][object.propertyKey] = '';
						// node.shape.calculateHeight(node);
						window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.property, object.propertyKey],
							prev_val, [node[object.propertyValue][object.property], node[object.propertyValue][object.propertyKey]]));
					}
					else {
						let prev_val = [node[object.property], node[object.propertyValue]];
						node[object.property] = window.utils.createNode(title, 15, 4, type);
						node[object.property].parent = node;
						node[object.property].parentValue = object.property;
						// node.shape.calculateHeight(node);
						if (object.propertyValue) {
							node[object.propertyValue] = '';
						}
						window.application.addCommand(new ChangeCommand(node, [object.property, object.propertyValue],
							prev_val, [node[object.property], node[object.propertyValue]]));
					}
				} else {
					flag = true;
				}
			} else {
				flag = true;
			}
			if (flag === true)
				window.application.handleError("Error", "E003");
			window.application.refresh();
			window.application.audio.play();
			if (node[object.property])
				node[object.property].addEvents();
		});
	},
	addMathHandler: function (object, node) {
		const { parameter, canvas } = window.application;
		this.addPropertyHandler(object, function (event) {
			let data = event.e.dataTransfer;
			let flag = false;
			if (data.getData("origin") === 'rightMenu') {
				let val = 'BOS$' + data.getData("scope") + '.$' + data.getData("caption");
				let dataTypeCheck = true;
				if (object.dataType) {
					if (object.dataType != data.getData("type"))
						dataTypeCheck = false;
				}
				if (object.propertyValue && dataTypeCheck) {
					if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue],
							[object.propertyKey], prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.propertyKey]];
						node[object.propertyValue][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
							prev_val, [node[object.propertyValue][object.propertyKey]]));
					}
					else {
						let prev_val = [node[object.propertyValue]];
						node[object.propertyValue] = val;
						window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
							prev_val, [node[object.propertyValue]]));
					}
				}
				else if (object.prop) {
					if (object.dataType && object.dataType == data.getData("type")) {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
					else {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
				} else {
					flag = true;
				}
			} else if (data.getData("origin") === 'leftMenu' && (data.getData("type") == 'm' || data.getData("type") == 'fun')) {
				let title = data.getData("caption");
				let type = data.getData("type");

				let val = window.utils.createNode(title, 15, 4, type);
				if (object.propertyValue) {
					if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey].parent,
						node[object.propertyValue][object.position][object.propertyKey].parentValue,
						node[object.propertyValue][object.position][object.propertyKey].parentposition, node[object.propertyValue][object.position][object.propertyKey].parentKey,
						node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						node[object.propertyValue][object.position][object.propertyKey].parent = node;
						node[object.propertyValue][object.position][object.propertyKey].parentValue = object.propertyValue;
						node[object.propertyValue][object.position][object.propertyKey].parentposition = object.position;
						node[object.propertyValue][object.position][object.propertyKey].parentKey = object.propertyKey;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue], node[object.propertyValue],
						node[object.propertyValue], node[object.propertyValue], node[object.propertyValue]],
							['parent', 'parentValue', 'parentposition', 'parentKey', object.propertyKey], prev_val, [node[object.propertyValue][object.position][object.propertyKey].parent,
							node[object.propertyValue][object.position][object.propertyKey].parentValue,
							node[object.propertyValue][object.position][object.propertyKey].parentposition, node[object.propertyValue][object.position][object.propertyKey].parentKey,
							node[object.propertyValue][object.position][object.propertyKey]], object.position, [object.propertyKey, object.propertyKey, object.propertyKey,
							object.propertyKey, '']));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey].parent, node[object.propertyValue][object.propertyKey].parentValue,
						node[object.propertyValue][object.propertyKey].parentKey];
						node[object.propertyValue][object.propertyKey] = val;
						node[object.propertyValue][object.propertyKey].parent = node;
						node[object.propertyValue][object.propertyKey].parentValue = object.propertyValue;
						node[object.propertyValue][object.propertyKey].parentKey = object.propertyKey;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue], node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey],
						node[object.propertyValue][object.propertyKey]], [object.propertyKey, 'parent', 'parentValue', 'parentKey'],
							prev_val, [node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey].parent, node[object.propertyValue][object.propertyKey].parentValue,
							node[object.propertyValue][object.propertyKey].parentKey]));
					}
					else {
						let prev_val = [node[object.propertyValue], node[object.propertyValue].parent, node[object.propertyValue].parentValue];
						node[object.propertyValue] = val;
						node[object.propertyValue].parent = node;
						node[object.propertyValue].parentValue = object.propertyValue;
						window.application.addCommand(new ChangeGroupCommand([node, node[object.propertyValue], node[object.propertyValue]], [object.propertyValue, 'parent', 'parentValue'],
							prev_val, [node[object.propertyValue], node[object.propertyValue].parent, node[object.propertyValue].parentValue]));
					}
				}
				else if (object.prop) {
					let prev_val = [object.prop['value']];
					val.prop = object.prop;
					val.parent = node;
					val.parentValue = 'value';
					object.prop.setVariable(val);
					window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
				} else {
					flag = true;
				}
			} else {
				flag = true;
			}
			if (flag === true)
				window.application.handleError("Error", "E003");
			if (object.mapped)
				node[object.mapped] = false;
			if (object.mapping)
				node[object.mapping] = null;
			window.application.refresh();
			window.application.audio.play();
		});
	},
	addFunctionHandler: function (object, node) {
		const { parameter, canvas } = window.application;
		this.addPropertyHandler(object, function (event) {
			let data = event.e.dataTransfer;
			let flag = false;
			if (data.getData("origin") === 'rightMenu') {
				let val = 'BOS$' + data.getData("scope") + '.$' + data.getData("caption");
				let dataTypeCheck = true;
				if (object.dataType) {
					if (object.dataType != data.getData("type"))
						dataTypeCheck = false;
				}
				if (object.propertyValue && dataTypeCheck) {
					if (object.prop) {
						let prev_val = [object.prop['value']];
						object.prop.setValue(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'],
							prev_val, [object.prop['value']]));
					}
					else if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue],
							[object.propertyKey], prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.propertyKey]];
						node[object.propertyValue][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
							prev_val, [node[object.propertyValue][object.propertyKey]]));
					}
					else {
						let prev_val = [node[object.propertyValue]];
						node[object.propertyValue] = val;
						window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
							prev_val, [node[object.propertyValue]]));
					}
				} else if (object.prop) {
					if (object.dataType && object.dataType == data.getData("type")) {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
					else {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
				} else {
					flag = true;
				}
			} else if (data.getData("origin") === 'leftMenu' && (data.getData("type") == 'fun')) {
				let title = data.getData("caption");
				let type = data.getData("type");
				let val = window.utils.createNode(title, 15, 4, type);
				if (object.propertyValue) {
					if (object.prop) {
						let prev_val = [object.prop['value']];
						object.prop.setValue(val);
						object.prop['value'].parent = node;

						window.application.addCommand(new ChangeCommand(object.prop, ['value'],
							prev_val, [object.prop['value']]));
					}
					else if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey].parent,
						node[object.propertyValue][object.position][object.propertyKey].parentValue,
						node[object.propertyValue][object.position][object.propertyKey].parentposition, node[object.propertyValue][object.position][object.propertyKey].parentKey,
						node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						node[object.propertyValue][object.position][object.propertyKey].parent = node;
						node[object.propertyValue][object.position][object.propertyKey].parentValue = object.propertyValue;
						node[object.propertyValue][object.position][object.propertyKey].parentposition = object.position;
						node[object.propertyValue][object.position][object.propertyKey].parentKey = object.propertyKey;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue][object.position][object.propertyKey], node[object.propertyValue][object.position][object.propertyKey],
						node[object.propertyValue][object.position][object.propertyKey], node[object.propertyValue][object.position][object.propertyKey],
						node[object.propertyValue][object.position]],
							[object.propertyKey, 'parent', 'parentValue', 'parentposition', 'parentKey'], prev_val, [node[object.propertyValue][object.position][object.propertyKey].parent,
							node[object.propertyValue][object.position][object.propertyKey].parentValue,
							node[object.propertyValue][object.position][object.propertyKey].parentposition, node[object.propertyValue][object.position][object.propertyKey].parentKey,
							node[object.propertyValue][object.position][object.propertyKey]]));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey].parent, node[object.propertyValue][object.propertyKey].parentValue,
						node[object.propertyValue][object.propertyKey].parentKey];
						node[object.propertyValue][object.propertyKey] = val;
						node[object.propertyValue][object.propertyKey].parent = node;
						node[object.propertyValue][object.propertyKey].parentValue = object.propertyValue;
						node[object.propertyValue][object.propertyKey].parentKey = object.propertyKey;
						window.application.addCommand(new ChangeGroupCommand([node[object.propertyValue], node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey],
						node[object.propertyValue][object.propertyKey]], [object.propertyKey, 'parent', 'parentValue', 'parentKey'],
							prev_val, [node[object.propertyValue][object.propertyKey], node[object.propertyValue][object.propertyKey].parent, node[object.propertyValue][object.propertyKey].parentValue,
							node[object.propertyValue][object.propertyKey].parentKey]));
					}
					else {
						let prev_val = [node[object.propertyValue], node[object.propertyValue].parent, node[object.propertyValue].parentValue];
						node[object.propertyValue] = val;
						node[object.propertyValue].parent = node;
						node[object.propertyValue].parentValue = object.propertyValue;
						window.application.addCommand(new ChangeGroupCommand([node, node[object.propertyValue], node[object.propertyValue]], [object.propertyValue, 'parent', 'parentValue'],
							prev_val, [node[object.propertyValue], node[object.propertyValue].parent, node[object.propertyValue].parentValue]));
					}
				} else {
					flag = true;
				}
			} else {
				flag = true;
			}
			if (flag === true)
				window.application.handleError("Error", "E003");
			window.application.refresh();
			window.application.audio.play();
			if (object.mapped)
				node[object.mapped] = false;
			if (object.mapping)
				node[object.mapping] = null;
			// if (node[object.property])
			// 	node[object.property].addEvents();
		});
	},
	addVariableHandler: function (object, node) {
		const { parameter, canvas } = window.application;
		this.addPropertyHandler(object, function (event) {
			let data = event.e.dataTransfer;
			let flag = false;
			if (data.getData("origin") === 'rightMenu') {
				let val = 'BOS$' + data.getData("scope") + '.$' + data.getData("caption");
				let dataTypeCheck = true;
				if (object.dataType) {
					if (object.dataType != data.getData("type"))
						dataTypeCheck = false;
				}
				if (object.propertyValue && dataTypeCheck) {
					if (object.propertyKey && object.position) {
						let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
						node[object.propertyValue][object.position][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
							prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
					}
					else if (object.propertyKey) {
						let prev_val = [node[object.propertyValue][object.propertyKey]];
						node[object.propertyValue][object.propertyKey] = val;
						window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
							prev_val, [node[object.propertyValue][object.propertyKey]]));
					}
					else {
						let prev_val = [node[object.propertyValue]];
						node[object.propertyValue] = val;
						window.application.addCommand(new ChangeCommand(node, [object.propertyValue], prev_val, [node[object.propertyValue]]));
					}
				}
				else if (object.prop) {
					if (object.dataType && object.dataType == data.getData("type")) {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
					else {
						let prev_val = [object.prop['value']];
						object.prop.setVariable(val);
						window.application.addCommand(new ChangeCommand(object.prop, ['value'], prev_val, [object.prop['value']]));
					}
				} else {
					flag = true;
				}
				if (object.mapped)
					node[object.mapped] = false;
				if (object.mapping)
					node[object.mapping] = null;
				let _temp = object.propertyValue == "leftValue" ? "lval" : (object.propertyValue == "rightValue" ? "rval" : "");
				window.application.refresh();
				window.application.audio.play();
			} else {
				flag = true;
			}
			if (flag === true)
				window.application.handleError("Error", "E003");
		});
	},
	addVariableValueHandler(object, node, callback) {
		object.shape.on('mousedown', function () {
			window.application.handleBlocksDropdownClose();
			// if (object.property instanceof Property ){
			// 	if(object.property.getPropertyValue().trim() == '')
			// 		node.shape[object.shapeVariable].set({text:''})
			// }
			object.node = node;
			if (object.property instanceof Property) {
				if (object.property.getValue().trim() == '') {
					if (object.blur) {
						node.shape[object.shapeVariable].set({ text: '' });
					}
				}
				else {
					object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else if (object.propertyKey && object.position) {
				if (node[object.propertyValue][object.position][object.propertyKey].trim() == '') {
					if (object.blur) {
						node.shape[object.shapeVariable].set({ text: '' });
					}
				}
				else {
					object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else if (object.propertyKey) {
				if (node[object.propertyValue][object.propertyKey].trim() == '') {
					if (object.blur) {
						node.shape[object.shapeVariable].set({ text: '' });
					}
				}
				else {
					object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else {
				if (node[object.propertyValue].trim() == '') {
					if (object.blur) {
						node.shape[object.shapeVariable].set({ text: '' });
					}
				} else {
					object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			window.application.canvas.renderAll();
			window.application.canvas.setActive(node.shape[object.shapeVariable]);
			node.shape[object.shapeVariable].enterEditing();
		});
		if (object.shape1) {
			object.shape1.on('mousedown', function () {
				window.application.handleBlocksDropdownClose();
				// if (object.property instanceof Property){
				// 	if(object.property.getPropertyValue().trim() == '')
				// 		node.shape[object.shapeVariable].set({text:''})
				// }
				object.node = node;
				if (object.property instanceof Property) {
					if (object.property.getValue().trim() == '') {
						if (object.blur) {
							node.shape[object.shapeVariable].set({ text: '' });
						}
					}
					else {
						object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
						window.application.selectedShape = object.shape;
						window.application.selectedObj = object;
					}
				}
				else if (object.propertyKey && object.position) {
					if (node[object.propertyValue][object.position][object.propertyKey].trim() == '') {
						if (object.blur) {
							node.shape[object.shapeVariable].set({ text: '' });
						}
					}
					else {
						object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
						window.application.selectedShape = object.shape;
						window.application.selectedObj = object;
					}
				}
				else if (object.propertyKey) {
					if (node[object.propertyValue][object.propertyKey].trim() == '') {
						if (object.blur) {
							node.shape[object.shapeVariable].set({ text: '' });
						}
					}
					else {
						object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
						window.application.selectedShape = object.shape;
						window.application.selectedObj = object;
					}
				}
				else {
					if (node[object.propertyValue].trim() == '') {
						if (object.blur) {
							node.shape[object.shapeVariable].set({ text: '' });
						}
					} else {
						object.shape1.set({ stroke: '#FFF021', strokeWidth: 2 });
						window.application.selectedShape = object.shape;
						window.application.selectedObj = object;
					}
				}
				window.application.canvas.renderAll();
				window.application.canvas.setActive(node.shape[object.shapeVariable]);
				node.shape[object.shapeVariable].enterEditing();
			});
		}

		if (object.shapes && object.shapeName) {
			for (let text of object.shapes) {
				text = object.shapeName + text;
				if (this.shape[text]) {
					this.shape[text].on('mousedown', function () {
						window.application.handleBlocksDropdownClose();
						if (object.property instanceof Property) {
							if (object.property.getPropertyValue().trim() == '')
								node.shape[object.shapeVariable].set({ text: '' })
						}
						if (object.blur) {
							if (object.property instanceof Property) {
								if (object.property.getValue().trim() == '')
									node.shape[object.shapeVariable].set({ text: '' });
							}
							else if (object.propertyKey && object.position) {
								if (node[object.propertyValue][object.position][object.propertyKey].trim() == '')
									node.shape[object.shapeVariable].set({ text: '' });
							}
							else if (object.propertyKey) {
								if (node[object.propertyValue][object.propertyKey].trim() == '')
									node.shape[object.shapeVariable].set({ text: '' });
							}
							else {
								if (node[object.propertyValue].trim() == '')
									node.shape[object.shapeVariable].set({ text: '' });
							}
						}
						window.application.canvas.setActive(node.shape[object.shapeVariable]);
						node.shape[object.shapeVariable].enterEditing();
					});
				}
			}
		}

		if (callback) {
			object.shape.on('changed', callback);
		}
		else {
			object.shape.on('changed', function () {
				if (object.property instanceof Property) {
					let prev_val = object.property.getValue();
					// window.application.commands.add(new ChangeCommand(node[object.property], null, node[object.property], window.application.canvas.canvas.getActiveObject().text));
					object.property.setValue(window.application.canvas.canvas.getActiveObject().text);
					window.application.addCommand(new ChangeCommand(object.property, ['value'], prev_val, object.property.getValue()));
				}
				else if (object.propertyKey && object.position) {
					let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
					// window.application.addCommand(new ChangeCommand(node[object.propertyValue][object.position], [object.propertyKey], 
					// 	[node[object.propertyValue][object.position][object.propertyKey]], [ window.application.canvas.canvas.getActiveObject().text]));
					node[object.propertyValue][object.position][object.propertyKey] = window.application.canvas.canvas.getActiveObject().text;
					window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
						prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
				}
				else if (object.propertyKey) {
					let prev_val = [node[object.propertyValue][object.propertyKey]];
					// window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey], 
					// 	[node[object.propertyValue][object.propertyKey]], [ window.application.canvas.canvas.getActiveObject().text]));
					node[object.propertyValue][object.propertyKey] = window.application.canvas.canvas.getActiveObject().text;
					window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
						prev_val, [node[object.propertyValue][object.propertyKey]]));
				}
				else {
					let prev_val = [node[object.propertyValue]];
					// window.application.addCommand(new ChangeCommand(node, [object.propertyValue], 
					// 	[node[object.propertyValue]], [ window.application.canvas.canvas.getActiveObject().text]));
					node[object.propertyValue] = window.application.canvas.canvas.getActiveObject().text;
					window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
						prev_val, [node[object.propertyValue]]));
				}
				if (object.mapped)
					node[object.mapped] = false;
				if (object.mapping)
					node[object.mapping] = null;
				window.application.handleBlocksDropdownClose();
				window.application.refresh();
				// window.application.audio.play();
			});
		}
		object.shape.on('editing:exited', function () {
			node.editingExited();
		});
	},
	addDropDownHandler(object, node, type, callback) {
		for (let i = 0; i < object.shape.length; i++) {
			if (!callback && type == 'condition') {
				object.shape[i].on('mousedown', function (e) {
					if (e.button == 1){
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new ConditionEvent(e.e, x, y));
						temp.load(node, 0);
					}
				});
			}
			else {
				object.shape[i].on('mousedown', function (e) {
					if (e.button == 1){
						callback(e);
					}
				});
			}
		}
	},
	addWorkflowHandler : function () {
		let obj = null;
		let node = this;

		if (this.hasOwnProperty('table')) {
			if (this.type == 'ar') {
				obj = new Object();
				obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = window.application.createDropdownMenu(new LocalTableGetEvent(e.e, 'table', 'tableObj'));
					temp.load(node, 0);
				});
			} else {
				obj = new Object();
				obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = window.application.createDropdownMenu(new AssignToTableEvent(e.e, x, y, 'tableObj'));
					temp.load(node, 0);
				});
			}
		}

		/*if (this.hasOwnProperty('selectColumnsLists')) {
			obj = new Object();
			obj.shape = [this.shape['columnList'], this.shape['columnList_arrow'], this.shape['columnList_text']];
			this.addDropDownHandler(obj, this, 'columnListType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new AssignToColumnEvent(e.e, x, y, 'tableObj', 'select', 'selectColumnsLists'));
				temp.load(node, 0);
			});
		}*/

		if (typeof (this.source) == 'string') {
			if (this.shape.source) {
				obj = new Object();
				obj.shape = this.shape.source;
				obj.shapeName = 'source';
				obj.shapeText = this.shape.source_text;
				obj.propertyValue = 'source';
				obj.shapeVariable = 'source_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.source)
				this.source.addClickHandler();
		}

		if (typeof (this.assigndata) == 'string') {
			if (this.shape.assigndata) {
				obj = new Object();
				obj.shape = this.shape.assigndata;
				obj.shapeName = 'assigndata';
				obj.shapeText = this.shape.assigndata_text;
				obj.propertyValue = 'assigndata';
				obj.shapeVariable = 'assigndata_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.assigndata)
				this.assigndata.addClickHandler();
		}
		if (typeof (this.destination) == 'string') {
			if (this.shape.destination) {
				obj = new Object();
				obj.shape = this.shape.destination;
				obj.shapeName = 'destination';
				obj.shapeText = this.shape.destination_text;
				obj.propertyValue = 'destination';
				obj.shapeVariable = 'destination_text';
				this.addEditVariableHandler(obj);
			}
			
		}else {
			if (this.destination)
				this.destination.addClickHandler();
		}

		if (this.shape.output) {
			obj = new Object();
			obj.shape = this.shape.output;
			obj.shapeName = 'output';
			obj.shapeText = this.shape.output_text;
			obj.propertyValue = 'output';
			obj.shapeVariable = 'output_text';
			this.addVariableGroupHandler(obj);
		}
		if (this.hasOwnProperty('to')) {
			obj = new Object();
			obj.shape = this.shape.to;
			obj.shapeName = 'to';
			obj.shapeText = this.shape.to_text;
			obj.propertyValue = 'to';
			obj.shapeVariable = 'to_text';
			obj.dataType = 'Table';
			obj.mapped = 'mapped';
			this.addVariableGroupHandler(obj);
		}

        if (this.shape.mapping_icon) {
			this.shape.mapping_icon.on('mousedown', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let obj = {};
				obj.setTable = node.to;
				obj.toTable = node.buildedName + '$Output.$Table';
				obj.toType = 'to';
				if (node.selectColumnsLists)
					obj.toCol = node.selectColumnsLists;
				else
					obj.toCol = node.getColumns();
				obj.mapProperty = 'mapping';
				obj.mapped = 'mapped';
				let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
				temp.load(node, 0);
			});
		}
			
	},
	addDriveHandler: function () {
		let obj = null;
		let node = this;
		if (typeof (this.source) == 'string') {
			if (this.shape.source) {
				obj = new Object();
				obj.shape = this.shape.source;
				obj.shapeName = 'source';
				obj.shapeText = this.shape.source_text;
				obj.propertyValue = 'source';
				obj.shapeVariable = 'source_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.source)
				this.source.addClickHandler();
		}

		if (this.shape.selectColumnsLists) {
				obj = new Object();
			obj.shape = [this.shape.selectColumnsLists, this.shape.selectColumnsLists_arrow, this.shape.selectColumnsLists_text];
			this.addDropDownHandler(obj, node, 'selectColumnsLists', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'ColumnsLists', 'selectColumnsLists', null, null, 'multiple'));
				temp.load(node, 0);
			});
		}

		if (typeof (this.extention) == 'string') {
			if (this.shape.extention) {
				obj = new Object();
				obj.shape = this.shape.extention;
				obj.shapeName = 'extention';
				obj.shapeText = this.shape.extention_text;
				obj.propertyValue = 'extention';
				obj.shapeVariable = 'extention_text';
				if (this.type == 'dlst') {
					obj.dataType = 'Table';
					obj.mapped = 'mapped';
					obj.mapping = 'mapping';
					this.addVariableGroupHandler(obj);
				}
				else{
				this.addEditVariableHandler(obj);
			}
		}
		}
		else {
			if (this.extention)
				this.extention.addClickHandler();
		}

		if (this.shape.mapping_icon) {
			this.shape.mapping_icon.on('mousedown', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let obj = {};
				obj.setTable = node.extention;
				obj.toTable = node.buildedName + '$Output.$Table';
				obj.toType = 'to';
				if (node.selectColumnsLists)
					obj.toCol = node.selectColumnsLists;
				else
					obj.toCol = node.getColumns();
				obj.mapProperty = 'mapping';
				obj.mapped = 'mapped';
				let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
				temp.load(node, 0);
			});
		}
		if (typeof (this.destination) == 'string') {
			if (this.shape.destination) {
				obj = new Object();
				obj.shape = this.shape.destination;
				obj.shapeName = 'destination';
				obj.shapeText = this.shape.destination_text;
				obj.propertyValue = 'destination';
				obj.shapeVariable = 'destination_text';
				this.addEditVariableHandler(obj);
			}
			
		}else {
			if (this.destination)
				this.destination.addClickHandler();
		}

			if (this.shape.url) {
				obj = new Object();
				obj.shape = this.shape.url;
				obj.shapeName = 'url';
				obj.shapeText = this.shape.url_text;
				obj.propertyValue = 'url';
				obj.shapeVariable = 'url_text';
			if (this.type == 'fp')
				obj.dataType = 'Table';
			this.addVariableGroupHandler(obj);
			
		}

		if (this.shape.output) {
			obj = new Object();
			obj.shape = this.shape.output;
			obj.shapeName = 'output';
			obj.shapeText = this.shape.output_text;
			obj.propertyValue = 'output';
			obj.shapeVariable = 'output_text';
			this.addVariableGroupHandler(obj);
		}

		if (this.shape.mandatory) {
			obj = new Object();
			obj.shape = [this.shape.mandatory, this.shape.mandatory_arrow, this.shape.mandatory_text];
			let node = this;
			this.addDropDownHandler(obj, this, 'select', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'mandatoryList', 'mandatory'));
				temp.load(node, 0);
			});
		}
	},
	addAuthHandler: function () {
		let obj = null;
		let node = this;
		if (typeof (this.user) == 'string') {
			if (this.shape.user) {
				obj = new Object();
				obj.shape = this.shape.user;
				obj.shapeName = 'user';
				obj.shapeText = this.shape.user_text;
				obj.propertyValue = 'user';
				obj.shapeVariable = 'user_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.user)
				this.user.addClickHandler();
		}

		if (typeof (this.password) == 'string') {
			if (this.shape.password) {
				obj = new Object();
				obj.shape = this.shape.password;
				obj.shapeName = 'password';
				obj.shapeText = this.shape.password_text;
				obj.propertyValue = 'password';
				obj.shapeVariable = 'password_text';
				this.addEditVariableHandler(obj);
			}
		}
		else {
			if (this.extention)
				this.extention.addClickHandler();
		}
 
		if (typeof (this.url) == 'string') {
			if (this.shape.url) {
				obj = new Object();
				obj.shape = this.shape.url;
				obj.shapeName = 'url';
				obj.shapeText = this.shape.url_text;
				obj.propertyValue = 'url';
				obj.shapeVariable = 'url_text';
				this.addEditVariableHandler(obj);
			}
			
		}else {
			if (this.url)
				this.url.addClickHandler();
		}

		if (typeof (this.clientId) == 'string') {
			if (this.shape.clientId) {
				obj = new Object();
				obj.shape = this.shape.clientId;
				obj.shapeName = 'clientId';
				obj.shapeText = this.shape.clientId_text;
				obj.propertyValue = 'clientId';
				obj.shapeVariable = 'clientId_text';
				this.addEditVariableHandler(obj);
			}
			
		}else {
			if (this.clientId)
				this.clientId.addClickHandler();
		}


		if (this.shape.status) {
			obj = new Object();
			obj.shape = this.shape.status;
			obj.shapeName = 'status';
			obj.shapeText = this.shape.status_text;
			obj.propertyValue = 'status';
			obj.shapeVariable = 'status_text';
			this.addVariableGroupHandler(obj);
		}

		if (this.shape.msg) {
			obj = new Object();
			obj.shape = this.shape.msg;
			obj.shapeName = 'msg';
			obj.shapeText = this.shape.msg_text;
			obj.propertyValue = 'msg';
			obj.shapeVariable = 'msg_text';
			this.addVariableGroupHandler(obj);
		}

		if (this.shape.authKey) {
			obj = new Object();
			obj.shape = this.shape.authKey;
			obj.shapeName = 'authKey';
			obj.shapeText = this.shape.authKey_text;
			obj.propertyValue = 'authKey';
			obj.shapeVariable = 'authKey_text';
			this.addVariableGroupHandler(obj);
		}

	},
	addSortNodeHandler: function () {
		let obj = null;
		let node = this;

		if (node.hasOwnProperty('table')) {
			obj = new Object();
			obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
			this.addDropDownHandler(obj, this, 'inputType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new LocalTableGetEvent(e.e, 'table', 'tableObj'));
				temp.load(node, 0);
			});
		}

		if (node.hasOwnProperty('input')) {
			if (Array.isArray(node.input)) {
				for (let i in node.input) {
					obj = new Object();
					obj.shape = [this.shape['input_name' + [i]], this.shape['input_name' + [i] + '_arrow'], this.shape['input_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new LocalTableColoumnsEvent(e.e, x, y, 'input', 'tableObj', i));
						temp.load(node, 0);
					});

					obj = new Object();
					obj.shape = [this.shape['input_Type' + [i]], this.shape['input_Type' + [i] + '_arrow'], this.shape['input_Type' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'inputTypes', 'input', 'type', i));
						temp.load(node, 0);
					});

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.input.push(new FDBColumn());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.input, new FDBColumn()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.input.splice(i, 1);
								if (node.input.length === 0)
									node.input.push(new FDBColumn());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.input, i, oldVal[0]));
							}
						});
					}
				}
			}
		}
		this.addGroupMouseOverHandler();
	},
	addFtpHandler: function () {
		let obj = null;
		let node = this;
		if (typeof (this.source) == 'string') {
			if (this.shape.source) {
				obj = new Object();
				obj.shape = this.shape.source;
				obj.shapeName = 'source';
				obj.shapeText = this.shape.source_text;
				obj.propertyValue = 'source';
				obj.shapeVariable = 'source_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.source)
				this.source.addClickHandler();
		}

		if (typeof (this.destination) == 'string') {
			if (this.shape.destination) {
				obj = new Object();
				obj.shape = this.shape.destination;
				obj.shapeName = 'destination';
				obj.shapeText = this.shape.destination_text;
				obj.propertyValue = 'destination';
				obj.shapeVariable = 'destination_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.destination)
				this.destination.addClickHandler();
		}

		if (this.shape.url) {
			obj = new Object();
			obj.shape = this.shape.url;
			obj.shapeName = 'url';
			obj.shapeText = this.shape.url_text;
			obj.propertyValue = 'url';
			obj.shapeVariable = 'url_text';
			this.addVariableGroupHandler(obj);
		}

		if (this.shape.logicalDes) {
			obj = new Object();
			obj.shape = [this.shape.logicalDes, this.shape.logicalDes_arrow, this.shape.logicalDes_text];
			this.addDropDownHandler(obj, node, 'logicalDes', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new SystemsEvent(e.e, x, y));
				temp.load(node, 0);
			});
		}

		if (this.shape.operation) {
			obj = new Object();
			obj.shape = [this.shape.operation, this.shape.operation_arrow, this.shape.operation_text];
			this.addDropDownHandler(obj, node, 'operation', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'operations', 'operation'));
				temp.load(node, 0);
			});
		}
	},
	addEditVariableHandler: function (object, type) {
		let obj = new Object();
		let node = this;
		if (!object.isPassword ) {
			if (!object.shapeName) {
				obj.styleIn = { fill: '#E2D0FF' };
				obj.styleOut = { fill: '#FFFFFF' };
				obj.shape = object.shape;
				obj.origin = 'rightMenu';
				obj.propertyValue = object.propertyValue;
				if (object.prop)
					obj.prop = object.prop;
				if (object.position)
					obj.position = object.position;
				if (object.propertyKey)
					obj.propertyKey = object.propertyKey;
				if (object.dataType)
					obj.dataType = object.dataType;
				if (object.property)
					obj.property = object.property;
				if (object.mapped)
					obj.mapped = object.mapped;
				if (object.mapping)
					obj.mapping = object.mapping;
				if (type && type == 'cond')
					this.addConditionHandler(obj, this);
				else{
					this.addMathHandler(obj, this);
				}
			}

			if (!object.shapeName && object.shapeText) {
				obj = new Object();
				obj.styleIn = { fill: '#E2D0FF' };
				obj.styleOut = { fill: '#FFFFFF' };
				obj.shape = object.shapeText;
				obj.dragShape = object.shape;
				obj.propertyValue = object.propertyValue;
				if (object.prop)
					obj.prop = object.prop;
				if (object.position)
					obj.position = object.position;
				if (object.propertyKey)
					obj.propertyKey = object.propertyKey;
				if (object.dataType)
					obj.dataType = object.dataType;
				if (object.property)
					obj.property = object.property;
				if (object.mapped)
					obj.mapped = object.mapped;
				if (object.mapping)
					obj.mapping = object.mapping;
				if (type && type == 'cond')
					this.addConditionHandler(obj, this);
				else{
					this.addMathHandler(obj, this);
				}
			}

			let shapes = ['', '_text', '_icon', '_child_arrow', '_child_text', '_child_plus_icon', '_child_close_icon'];

			if (object.shapeName) {
				for (let text of shapes) {
					text = object.shapeName + text;
					if (this.shape[text]) {
						obj = new Object();
						obj.styleIn = { fill: '#E2D0FF' };
						obj.styleOut = { fill: '#FFFFFF' };
						obj.shape = this.shape[text];
						obj.dragShape = object.shape;
						obj.propertyValue = object.propertyValue;
						if (object.prop)
							obj.prop = object.prop;
						if (object.position)
							obj.position = object.position;
						if (object.propertyKey)
							obj.propertyKey = object.propertyKey;
						if (object.dataType)
							obj.dataType = object.dataType;
						if (object.property)
							obj.property = object.property;
						if (object.mapped)
							obj.mapped = object.mapped;
						if (object.mapping)
							obj.mapping = object.mapping;
						if (type && type == 'cond')
							this.addConditionHandler(obj, this);
						else{
							this.addMathHandler(obj, this);
						}
					}
				}
			}

			if (object.shapeName) {
				if (this.shape[object.shapeName + '_child_plus_icon']) {
					this.shape[object.shapeName + '_child_plus_icon'].on('mousedown', function () {
						
						let value = '';
						if (object.prop) {
							value = object.prop['value'];
							if (!value.endsWith('$_')) {
								let prev_val = value;
								object.prop['value'] += '$_';
								window.application.addCommand(new ChangeCommand(object.prop, ['value'], [prev_val], [object.prop['value']]));
							}

						}
						else if (object.position && object.propertyKey) {
							value = node[object.propertyValue][object.position][object.propertyKey];
							if (!value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
								node[object.propertyValue][object.position][object.propertyKey] += '$_';
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
									prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
							}
						}
						else if (object.propertyKey) {
							value = node[object.propertyValue][object.propertyKey];
							if (!value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.propertyKey]];
								node[object.propertyValue][object.propertyKey] += '$_';
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
									prev_val, [node[object.propertyValue][object.propertyKey]]));
							}
						}
						else if (object.position) {
							value = node[object.propertyValue][object.position];
							if (!value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.position]];
								node[object.propertyValue][object.position] += '$_';
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position],
									prev_val, [node[object.propertyValue][object.position]]));
							}
						}
						else {
							value = node[object.propertyValue];
							if (!value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue]];
								node[object.propertyValue] += '$_';
								window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
									prev_val, [node[object.propertyValue]]));
							}
						}
						window.application.refresh();
					});
				}
				if (this.shape[object.shapeName + '_child_close_icon']) {
					this.shape[object.shapeName + '_child_close_icon'].on('mousedown', function () {
						let value = '';
						if (object.prop) {
							value = object.prop['value'];
							if (value.endsWith('$_')) {
								let prev_val = value;
								object.prop['value'] = value.substring(0, value.length - 2);
								window.application.addCommand(new ChangeCommand(object.prop, ['value'], [prev_val], [object.prop['value']]));
							}

						}
						else if (object.position && object.propertyKey) {
							value = node[object.propertyValue][object.position][object.propertyKey];
							if (value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
								node[object.propertyValue][object.position][object.propertyKey] = value.substring(0, value.length - 2);
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
									prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
							}
						}
						else if (object.propertyKey) {
							value = node[object.propertyValue][object.propertyKey];
							if (value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.propertyKey]];
								node[object.propertyValue][object.propertyKey] = value.substring(0, value.length - 2);
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
									prev_val, [node[object.propertyValue][object.propertyKey]]));
							}
						}
						else if (object.position) {
							value = node[object.propertyValue][object.position];
							if (value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue][object.position]];
								node[object.propertyValue][object.position] = value.substring(0, value.length - 2);
								window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position],
									prev_val, [node[object.propertyValue][object.position]]));
							}
						}
						else {
							value = node[object.propertyValue];
							if (value.endsWith('$_')) {
								let prev_val = [node[object.propertyValue]];
								node[object.propertyValue] = value.substring(0, value.length - 2);
								window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
									prev_val, [node[object.propertyValue]]));
							}
						}
						window.application.refresh();
					});
				}
				shapes = ['_child_text', '_child_drop_arrow', '_child_arrow'];
				for (let text of shapes) {
					text = object.shapeName + text;
					let value = '';
					if (object.prop) {
						value = object.prop['value'];
					}
					else if (object.position && object.propertyKey) {
						value = node[object.propertyValue][object.position][object.propertyKey];
					}
					else if (object.propertyKey) {
						value = node[object.propertyValue][object.propertyKey];
					}
					else if (object.position)
						value = node[object.propertyValue][object.position];
					else
						value = node[object.propertyValue];
					value = value.endsWith('$_') ? value.substring(0, value.length - 2) : value;
					let variable = value.split('$');
					let tableObj = {};
					if (variable.length > 2) {
						tableObj = {
							name: variable[2],
							value: value,
							type: variable[1].endsWith('.') ? variable[1].substring(0, variable[1].length - 1) : variable[1]
						};
					}
					let tableName = '';
					if (object.prop) {
						tableName = object.prop['name'];
					}
					else if (!object.position) {
						tableName = object.propertyValue + '_table';
					}
					else {
						tableName = object.propertyValue + object.position + '_table';
					}
					node[tableName] = tableObj;
					if (this.shape[text]) {
						this.shape[text].on('mousedown', function (e) {
							let x = e.e.layerX;
							let y = e.e.layerY + 30;
							let temp = window.application.createDropdownMenu(new LocalTableColoumnsEvent(e.e, x, y, object.propertyValue, tableName, object.position, object.propertyKey, object.prop));
							temp.load(node, 0);
						});
					}
				}
			}
		}
		obj = new Object();
		obj.shape = this.shape[object.shapeVariable];
		obj.shape1 = object.shape;
		obj.shapeName = object.shapeName;
		obj.shapes = ['_text', '_icon'];
		obj.property = object.property;
		obj.propertyValue = object.propertyValue;
		obj.shapeVariable = object.shapeVariable;
		if (object.position)
			obj.position = object.position;
		if (object.propertyKey)
			obj.propertyKey = object.propertyKey;
		if (object.blur)
			obj.blur = object.blur;
		this.addVariableValueHandler(obj, this);

		if (object.shape && !object.isPassword) {
			object.shape.on('mousedown', function (e) {
				e.e.preventDefault();
				let obj = {};
				obj.setValue = object.propertyValue;
				if (object.position)
					obj.i = object.position;
				if (object.propertyKey)
					obj.valueKey = object.propertyKey;
				let temp = window.application.createDropdownMenu(new variableDropDownEvent(e.e, obj));
				temp.load(node, 0);
			});
		}
	},
	addVariableGroupHandler: function (object, type) {
		let node = this;
		let obj = new Object();
		obj.styleIn = { fill: '#E2D0FF' };
		obj.styleOut = { fill: '#FFFFFF' };
		obj.shape = object.shape;
		obj.origin = 'rightMenu';
		obj.propertyValue = object.propertyValue;
		if (object.position)
			obj.position = object.position;
		if (object.propertyKey)
			obj.propertyKey = object.propertyKey;
		if (object.dataType)
			obj.dataType = object.dataType;
		if (object.property)
			obj.property = object.property;
		if (object.mapped)
			obj.mapped = object.mapped;
		if (object.mapping)
			obj.mapping = object.mapping;
		if (type && type == 'cond')
			this.addConditionHandler(obj, this);
		else
			this.addVariableHandler(obj, this);

		if (object.shapeText) {
			obj = new Object();
			obj.styleIn = { fill: '#E2D0FF' };
			obj.styleOut = { fill: '#FFFFFF' };
			obj.shape = object.shapeText;
			obj.dragShape = object.shape;
			obj.propertyValue = object.propertyValue;
			if (object.position)
				obj.position = object.position;
			if (object.propertyKey)
				obj.propertyKey = object.propertyKey;
			if (object.dataType)
				obj.dataType = object.dataType;
			if (object.property)
				obj.property = object.property;
			if (object.mapped)
				obj.mapped = object.mapped;
			if (object.mapping)
				obj.mapping = object.mapping;
			if (type && type == 'cond')
				this.addConditionHandler(obj, this);
			else
				this.addVariableHandler(obj, this);
		}

		let shapes = ['_text', '_icon'];

		if (object.shapeName) {
			for (let text of shapes) {
				text = object.shapeName + text;
				if (this.shape[text]) {
					obj = new Object();
					obj.styleIn = { fill: '#E2D0FF' };
					obj.styleOut = { fill: '#FFFFFF' };
					obj.shape = this.shape[text];
					obj.dragShape = object.shape;
					obj.propertyValue = object.propertyValue;
					if (object.position)
						obj.position = object.position;
					if (object.propertyKey)
						obj.propertyKey = object.propertyKey;
					if (object.dataType)
						obj.dataType = object.dataType;
					if (object.mapped)
						obj.mapped = object.mapped;
					if (object.mapping)
						obj.mapping = object.mapping;
					if (type && type == 'cond')
						this.addConditionHandler(obj, this);
					else
						this.addVariableHandler(obj, this);
				}
			}
		}
		if (object.shapeName) {
			if (this.shape[object.shapeName + '_child_plus_icon']) {
				this.shape[object.shapeName + '_child_plus_icon'].on('mousedown', function () {
					
					let value = '';
					if (object.prop) {
						value = object.prop['value'];
						if (!value.endsWith('$_')) {
							let prev_val = value;
							object.prop['value'] += '$_';
							window.application.addCommand(new ChangeCommand(object.prop, ['value'], [prev_val], [object.prop['value']]));
						}

					}
					else if (object.position && object.propertyKey) {
						value = node[object.propertyValue][object.position][object.propertyKey];
						if (!value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue][object.position], [object.propertyKey], 
							// 	[node[object.propertyValue][object.position][object.propertyKey]], [value += '$_']));
							node[object.propertyValue][object.position][object.propertyKey] += '$_';
							window.application.addCommand(new ChangeCommand(node[object.propertyValue][object.propertyKey],
								prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
						}
					}
					else if (object.propertyKey) {
						value = node[object.propertyValue][object.propertyKey];
						if (!value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.propertyKey]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey], 
							// 	[node[object.propertyValue][object.propertyKey]], [value += '$_']));
							node[object.propertyValue][object.propertyKey] += '$_';
							window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
								prev_val, [node[object.propertyValue][object.propertyKey]]));
						}
					}
					else if (object.position) {
						value = node[object.propertyValue][object.position];
						if (!value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.position]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position], 
							// 	[node[object.propertyValue][object.position]], [value += '$_']));
							node[object.propertyValue][object.position] += '$_';
							window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position],
								prev_val, [node[object.propertyValue][object.position]]));
						}
					}
					else {
						value = node[object.propertyValue];
						if (!value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue]];
							// window.application.addCommand(new ChangeCommand(node, [object.propertyValue], 
							// 	[node[object.propertyValue]], [value += '$_']));
							node[object.propertyValue] += '$_';
							window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
								prev_val, [node[object.propertyValue]]));
						}
					}
					window.application.refresh();
				});
			}
			if (this.shape[object.shapeName + '_child_close_icon']) {
				this.shape[object.shapeName + '_child_close_icon'].on('mousedown', function () {
					let value = '';
					if (object.prop) {
						value = object.prop['value'];
						if (value.endsWith('$_')) {
							let prev_val = value;
							object.prop['value'] = value.substring(0, value.length - 2);
							window.application.addCommand(new ChangeCommand(object.prop, ['value'], [prev_val], [object.prop['value']]));
						}

					}
					else if (object.position && object.propertyKey) {
						value = node[object.propertyValue][object.position][object.propertyKey];
						if (value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.position][object.propertyKey]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue][object.position], [object.propertyKey], 
							// 	[node[object.propertyValue][object.position][object.propertyKey]], [value.substring(0,value.length-2)]));
							node[object.propertyValue][object.position][object.propertyKey] = value.substring(0, value.length - 2);
							window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
								prev_val, [node[object.propertyValue][object.position][object.propertyKey]], object.position));
						}
					}
					else if (object.propertyKey) {
						value = node[object.propertyValue][object.propertyKey];
						if (value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.propertyKey]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey], 
							// 	[node[object.propertyValue][object.propertyKey]], [value.substring(0,value.length-2)]));
							node[object.propertyValue][object.propertyKey] = value.substring(0, value.length - 2);
							window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.propertyKey],
								prev_val, [node[object.propertyValue][object.propertyKey]]));
						}
					}
					else if (object.position) {
						value = node[object.propertyValue][object.position];
						if (value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue][object.position]];
							// window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position], 
							// 	[node[object.propertyValue][object.position]], [value.substring(0,value.length-2)]));
							node[object.propertyValue][object.position] = value.substring(0, value.length - 2);
							window.application.addCommand(new ChangeCommand(node[object.propertyValue], [object.position],
								prev_val, [node[object.propertyValue][object.position]]));
						}
					}
					else {
						value = node[object.propertyValue];
						if (value.endsWith('$_')) {
							let prev_val = [node[object.propertyValue]];
							// window.application.addCommand(new ChangeCommand(node, [object.propertyValue], 
							// 	[node[object.propertyValue]], [value.substring(0,value.length-2)]));
							node[object.propertyValue] = value.substring(0, value.length - 2);
							window.application.addCommand(new ChangeCommand(node, [object.propertyValue],
								prev_val, [node[object.propertyValue]]));
						}
					}
					window.application.refresh();
				});
			}
			shapes = ['_child_text', '_child_drop_arrow', '_child_arrow'];
			for (let text of shapes) {
				text = object.shapeName + text;
				let value = '';
				if (object.prop) {
					value = object.prop['value'];
				}
				else if (object.position && object.propertyKey) {
					value = node[object.propertyValue][object.position][object.propertyKey];
				}
				else if (object.propertyKey) {
					value = node[object.propertyValue][object.propertyKey];
				}
				else if (object.position)
					value = node[object.propertyValue][object.position];
				else
					value = node[object.propertyValue];
				// value = value.endsWith('$_')?value.substring(0,value.length - 2) : value;
				let variable = value.split('$');
				let tableObj = {};
				if (variable.length > 2) {
					tableObj = {
						name: variable[2],
						value: value,
						type: variable[1].endsWith('.') ? variable[1].substring(0, variable[1].length - 1) : variable[1]
					};
				}
				let tableName = '';
				if (object.prop) {
					tableName = object.prop['name'];
				}
				else if (!object.position) {
					tableName = object.propertyValue + '_table';
				}
				else {
					tableName = object.propertyValue + object.position + '_table';
				}
				node[tableName] = tableObj;
				if (this.shape[text]) {
					this.shape[text].on('mousedown', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new LocalTableColoumnsEvent(e.e, x, y, object.propertyValue, tableName, object.position, object.propertyKey, object.prop));
						temp.load(node, 0);
					});
				}
			}
		}

		let selectAction = function () {
			object.node = node;
			if (object.property instanceof Property) {
				if (object.property.getValue().trim() != '') {
					object.shape.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else if (object.propertyKey && object.position) {
				if (node[object.propertyValue][object.position][object.propertyKey].trim() != '') {
					object.shape.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else if (object.propertyKey) {
				if (node[object.propertyValue][object.propertyKey].trim() != '') {
					object.shape.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			else {
				if (node[object.propertyValue].trim() != '') {
					object.shape.set({ stroke: '#FFF021', strokeWidth: 2 });
					window.application.selectedShape = object.shape;
					window.application.selectedObj = object;
				}
			}
			window.application.canvas.renderAll();
		};

		if (object.shape) {
			object.shape.on('mousedown', selectAction);
		}
		if (object.shapeText) {
			object.shapeText.on('mousedown', selectAction);
		}
		else if (object.shapeVariable) {
			node.shape[object.shapeVariable].on('mousedown', selectAction);
		}

	},
	addBlockDropDown: function (object) {
		let node = this;
		if (object.shape && !object.isPassword) {
			object.shape.on('mousedown', function (e) {
				e.e.preventDefault();
				let obj = {};
				obj.setValue = object.propertyValue;
				if (object.position)
					obj.i = object.position;
				if (object.propertyKey)
					obj.valueKey = object.propertyKey;
				let temp = window.application.createDropdownMenu(new variableDropDownEvent(e.e, obj));
				temp.load(node, 0);
			});
		}
	},
	addUserHandler: function () {
		let obj = null;
		if (typeof (this.updateLoginId) == 'string') {
			if (this.shape.updateLoginId) {
				obj = new Object();
				obj.shape = this.shape.updateLoginId;
				obj.shapeName = 'updateLoginId';
				obj.shapeText = this.shape.updateLoginId_text;
				obj.propertyValue = 'updateLoginId';
				obj.shapeVariable = 'updateLoginId_text';
				obj.blur = true;
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.updateLoginId)
				this.updateLoginId.addClickHandler();
		}

		if (typeof (this.role) == 'string') {
			if (this.shape.role) {
				obj = new Object();
				obj.shape = this.shape.role;
				obj.shapeName = 'role';
				obj.shapeText = this.shape.role_text;
				obj.propertyValue = 'role';
				obj.shapeVariable = 'role_text';
				obj.blur = true;
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.role)
				this.role.addClickHandler();
		}

		if (typeof (this.title) == 'string') {
			if (this.shape.title) {
				obj = new Object();
				obj.shape = this.shape.title;
				obj.shapeName = 'title';
				obj.shapeText = this.shape.title_text;
				obj.propertyValue = 'title';
				obj.shapeVariable = 'title_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.title)
				this.title.addClickHandler();
		}

		if (typeof (this.fName) == 'string') {
			if (this.shape.fName) {
				obj = new Object();
				obj.shape = this.shape.fName;
				obj.shapeName = 'fName';
				obj.shapeText = this.shape.fName_text;
				obj.propertyValue = 'fName';
				obj.shapeVariable = 'fName_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.fName)
				this.fName.addClickHandler();
		}

		if (typeof (this.lName) == 'string') {
			if (this.shape.lName) {
				obj = new Object();
				obj.shape = this.shape.lName;
				obj.shapeName = 'lName';
				obj.shapeText = this.shape.lName_text;
				obj.propertyValue = 'lName';
				obj.shapeVariable = 'lName_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.lName)
				this.lName.addClickHandler();
		}

		if (typeof (this.email) == 'string') {
			if (this.shape.email) {
				obj = new Object();
				obj.shape = this.shape.email;
				obj.shapeName = 'email';
				obj.shapeText = this.shape.email_text;
				obj.propertyValue = 'email';
				obj.shapeVariable = 'email_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.email)
				this.email.addClickHandler();
		}

		if (typeof (this.loginId) == 'string') {
			if (this.shape.loginId) {
				obj = new Object();
				obj.shape = this.shape.loginId;
				obj.shapeName = 'login';
				obj.shapeText = this.shape.loginId_text;
				obj.propertyValue = 'loginId';
				obj.shapeVariable = 'loginId_text';
				this.addVariableGroupHandler(obj);
			}
		}else {
			if (this.loginId)
				this.loginId.addClickHandler();
		}

		if (typeof (this.password) == 'string') {
			if (this.shape.password) {
				obj = new Object();
				obj.shape = this.shape.password;
				obj.shapeName = 'password';
				obj.shapeText = this.shape.password_text;
				obj.propertyValue = 'password';
				obj.shapeVariable = 'password_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.password)
				this.password.addClickHandler();
		}

		// if (typeof (this.language) == 'string') {
		// 	if (this.shape.language) {
		// 		obj = new Object();
		// 		obj.shape = this.shape.language;
		// 		obj.shapeName = 'login';
		// 		obj.shapeText = this.shape.language_text;
		// 		obj.propertyValue = 'language';
		// 		obj.shapeVariable = 'language_text';
		// 		this.addEditVariableHandler(obj);
		// 	}
		// }else {
		// 	if (this.language)
		// 		this.language.addClickHandler();
		// }

		if (this.shape.language) {
			obj = new Object();
			obj.shape = [this.shape['language'], this.shape['language' + '_arrow'], this.shape['language' + '_text']];
			this.addDropDownHandler(obj, this, 'select', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'languageList', 'language'));
				temp.load(node, 0);
			});
		}

		if (this.shape.sendEmail) {
			obj = new Object();
			obj.shape = [this.shape['sendEmail'], this.shape['sendEmail' + '_arrow'], this.shape['sendEmail' + '_text']];
			this.addDropDownHandler(obj, this, 'select', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'sendEmailList', 'sendEmail'));
				temp.load(node, 0);
			});
		}


		if (typeof (this.inActive) == 'string') {
			if (this.shape.inActive) {
				obj = new Object();
				obj.shape = this.shape.inActive;
				obj.shapeName = 'inActive';
				obj.shapeText = this.shape.inActive_text;
				obj.propertyValue = 'inActive';
				obj.shapeVariable = 'inActive_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.inActive)
				this.inActive.addClickHandler();
		}

		if (typeof (this.inActive) == 'string') {
			if (this.shape.inActive) {
				obj = new Object();
				obj.shape = this.shape.inActive;
				obj.shapeName = 'inActive';
				obj.shapeText = this.shape.inActive_text;
				obj.propertyValue = 'inActive';
				obj.shapeVariable = 'inActive_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.inActive)
				this.inActive.addClickHandler();
		}

		// if (this.shape.language) {
		// 	obj = new Object();
		// 	obj.shape = [this.shape['language'], this.shape['language' + '_arrow'], this.shape['language' + '_text']];
		// 	this.addDropDownHandler(obj, this, 'select', function (e) {
		// 		let x = e.e.layerX;
		// 		let y = e.e.layerY + 30;
		// 		let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'languageList', 'language'));
		// 		temp.load(node, 0);
		// 	});
		// }

		if (typeof (this.inActive) == 'string') {
			if (this.shape.inActive) {
				obj = new Object();
				obj.shape = this.shape.inActive;
				obj.shapeName = 'inActive';
				obj.shapeText = this.shape.inActive_text;
				obj.propertyValue = 'inActive';
				obj.shapeVariable = 'inActive_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.inActive)
				this.inActive.addClickHandler();
		}

		let node = this;
		if (typeof (this.salutation) == 'string') {
			if (this.shape.salutation) {
				obj = new Object();
				obj.shape = this.shape.salutation;
				obj.shapeName = 'salutation';
				obj.shapeText = this.shape.salutation_text;
				obj.propertyValue = 'salutation';
				obj.shapeVariable = 'salutation_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.salutation)
				this.salutation.addClickHandler();
		}

		if (node.hasOwnProperty('customProperties')) {
			if (Array.isArray(node.customProperties)) {
				for (let i in node.customProperties) {
					obj = new Object();
					obj.shape = [this.shape['customProperties_name' + [i]], this.shape['customProperties_name' + [i] + '_arrow'], this.shape['customProperties_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new UserPropertiesListEvent(e.e, 'customProperties', i));
						temp.load(node, 0);
					});

					if (typeof (this.customProperties[i]['value']) == 'string') {
						obj = new Object();
						obj.shape = this.shape['customProperties_value' + i];
						obj.shapeName = 'customProperties_value' + i;
						obj.shapeText = this.shape['customProperties_value' + i + '_text'];
						obj.propertyValue = 'customProperties';
						obj.shapeVariable = 'customProperties_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						this.customProperties[i]['value'].addClickHandler();
					}

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.customProperties.push(new UserProperties());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.customProperties, new UserProperties()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.customProperties.splice(i, 1);
								if (node.customProperties.length === 0)
									node.customProperties.push(new UserProperties());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.customProperties, i, oldVal[0]));
							}
						});
					}
				}
			}
		}
		this.addGroupMouseOverHandler();

	},
	addUserUpdateHandler: function () {
		let obj = null;
		if (typeof (this.updateLoginId) == 'string') {
			if (this.shape.updateLoginId) {
				obj = new Object();
				obj.shape = this.shape.updateLoginId;
				obj.shapeName = 'updateLoginId';
				obj.shapeText = this.shape.updateLoginId_text;
				obj.propertyValue = 'updateLoginId';
				obj.shapeVariable = 'updateLoginId_text';
				obj.blur = true;
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.updateLoginId)
				this.updateLoginId.addClickHandler();
		}
		let node = this;
		if (node.hasOwnProperty('customProperties')) {
			if (Array.isArray(node.customProperties)) {
				for (let i in node.customProperties) {
					obj = new Object();
					obj.shape = [this.shape['customProperties_name' + [i]], this.shape['customProperties_name' + [i] + '_arrow'], this.shape['customProperties_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new UserUpdatePropertiesListEvent(e.e, 'customProperties', i));
						temp.load(node, 0);
					});

					if (typeof (this.customProperties[i]['value']) == 'string') {
						obj = new Object();
						obj.shape = this.shape['customProperties_value' + i];
						obj.shapeName = 'customProperties_value' + i;
						obj.shapeText = this.shape['customProperties_value' + i + '_text'];
						obj.propertyValue = 'customProperties';
						obj.shapeVariable = 'customProperties_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						this.customProperties[i]['value'].addClickHandler();
					}

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.customProperties.push(new UserProperties());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.customProperties, new UserProperties()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.customProperties.splice(i, 1);
								if (node.customProperties.length === 0)
									node.customProperties.push(new UserProperties());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.customProperties, i, oldVal[0]));
							}
						});
					}
				}
			}
		}
		this.addGroupMouseOverHandler();

	},
	addNotificationHandler: function () {
		let obj = null;
		if (typeof (this.tos) == 'string') {
			if (this.shape.tos) {
				obj = new Object();
				obj.shape = this.shape.tos;
				obj.shapeName = 'tos';
				obj.shapeText = this.shape.tos_text;
				obj.propertyValue = 'tos';
				obj.shapeVariable = 'tos_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.tos)
				this.tos.addClickHandler();
		}

		if (typeof (this.cc) == 'string') {
			if (this.shape.cc) {
				obj = new Object();
				obj.shape = this.shape.cc;
				obj.shapeName = 'cc';
				obj.shapeText = this.shape.cc_text;
				obj.propertyValue = 'cc';
				obj.shapeVariable = 'cc_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.cc)
				this.cc.addClickHandler();
		}

		if (typeof (this.subject) == 'string') {
			if (this.shape.subject) {
				obj = new Object();
				obj.shape = this.shape.subject;
				obj.shapeName = 'subject';
				obj.shapeText = this.shape.subject_text;
				obj.propertyValue = 'subject';
				obj.shapeVariable = 'subject_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.subject)
				this.subject.addClickHandler();
		}

		if (typeof (this.message) == 'string') {
			if (this.shape.message) {
				obj = new Object();
				obj.shape = this.shape.message;
				obj.shapeName = 'message';
				obj.shapeText = this.shape.message_text;
				obj.propertyValue = 'message';
				obj.shapeVariable = 'message_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.message)
				this.message.addClickHandler();
		}

		if (typeof (this.link) == 'string') {
			if (this.shape.link) {
				obj = new Object();
				obj.shape = this.shape.link;
				obj.shapeName = 'link';
				obj.shapeText = this.shape.link_text;
				obj.propertyValue = 'link';
				obj.shapeVariable = 'link_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.link)
				this.link.addClickHandler();
		}

		if (typeof (this.bos) == 'string') {
			if (this.shape.bos) {
				obj = new Object();
				obj.shape = this.shape.bos;
				obj.shapeName = 'bos';
				obj.shapeText = this.shape.bos_text;
				obj.propertyValue = 'bos';
				obj.shapeVariable = 'bos_text';
				this.addEditVariableHandler(obj);
			}
		}else {
			if (this.bos)
				this.bos.addClickHandler();
		}

		// if (this.shape.output){
		// 	obj = new Object();
		// 	obj.shape = this.shape.output;
		// 	obj.shapeText = this.shape.output_text;
		// 	obj.propertyValue = 'output';
		// 	obj.shapeVariable = 'output_text';
		// 	this.addVariableGroupHandler(obj);
		// }

		if (this.shape.triggerSync) {
			obj = new Object();
			obj.shape = [this.shape.triggerSync, this.shape.triggerSync_arrow, this.shape.triggerSync_text];
			let node = this;
			this.addDropDownHandler(obj, this, 'select', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'triggerSyncList', 'triggerSync'));
				temp.load(node, 0);
			});
		}

		if (this.shape.showNotifcation) {
			obj = new Object();
			obj.shape = [this.shape.showNotifcation, this.shape.showNotifcation_arrow, this.shape.showNotifcation_text];
			let node = this;
			this.addDropDownHandler(obj, this, 'select', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'showNotifcationList', 'showNotifcation'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('table')) {
			let node=this;
			obj = new Object();
			obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
			this.addDropDownHandler(obj, this, 'inputType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new AppsEvent(e.e, x, y, 'tableObj'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('selectColumnsLists')) {
			let node=this;
			obj = new Object();
			obj.shape = [this.shape['columnList'], this.shape['columnList_arrow'], this.shape['columnList_text']];
			this.addDropDownHandler(obj, this, 'columnListType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new BusinessFunctionEvent(e.e, x, y, 'tableObj', 'select', 'selectColumnsLists'));
				temp.load(node, 0);
			});
		}

		let node = this;
		if (node.hasOwnProperty('customProperties')) {
			if (Array.isArray(node.customProperties)) {
				for (let i in node.customProperties) {
					obj = new Object();
					obj.shape = [this.shape['customProperties_name' + [i]], this.shape['customProperties_name' + [i] + '_arrow'], this.shape['customProperties_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new PushVariablesListEvent(e.e, 'customProperties', i));
						temp.load(node, 0);
					});

					if (typeof (this.customProperties[i]['value']) == 'string') {
						obj = new Object();
						obj.shape = this.shape['customProperties_value' + i];
						obj.shapeName = 'customProperties_value' + i;
						obj.shapeText = this.shape['customProperties_value' + i + '_text'];
						obj.propertyValue = 'customProperties';
						obj.shapeVariable = 'customProperties_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						this.customProperties[i]['value'].addClickHandler();
					}

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.customProperties.push(new UserProperties());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.customProperties, new UserProperties()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.customProperties.splice(i, 1);
								if (node.customProperties.length === 0)
									node.customProperties.push(new UserProperties());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.customProperties, i, oldVal[0]));
							}
						});
					}
				}
			}
		}
		this.addGroupMouseOverHandler();
		this.addSystemDropDownEvent();
	},
	addBosHandler: function () {
		let obj = null;
		let node = this;
		if (node.hasOwnProperty('input')) {
			if (Array.isArray(node.input)) {
				for (let i in node.input) {

					if (typeof (node.input[i].value) == 'string') {
						obj = new Object();
						obj.shape = this.shape['input_value' + i];
						obj.shapeName = 'input_value' + i;
						obj.shapeText = this.shape['input_value' + i + '_text'];
						obj.propertyValue = 'input';
						obj.shapeVariable = 'input_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						obj.mapped = 'input_mapped' + i;
						obj.mapping = 'input_mapping' + i;
						if (node.input[i]['type'] == 'table')
							obj.dataType = 'Table';
						this.addEditVariableHandler(obj);
					}
					else {
						node.input[i].value.addClickHandler();
					}

					obj = new Object();
					obj.shape = [this.shape['input_name' + [i]], this.shape['input_name' + [i] + '_arrow'], this.shape['input_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new BOSParamEvent(e.e, 'input', i));
						temp.load(node, 0);
					});

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.input.push(new InputOutObj());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.input, new InputOutObj()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.input.splice(i, 1);
								if (node.input.length === 0)
									node.input.push(new InputOutObj());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.input, i, oldVal[0]));
							}
						});
					}

					if (this.shape['input_mapping_icon' + i]) {
						this.shape['input_mapping_icon' + i].on('mousedown', function (e) {
							let obj = {};
							obj.toTable = node.input[i]['value'];
							obj.setTable = node.buildedName + '$Input.$' + node.input[i]['name'];
							obj.toType = 'set';
							obj.setCol = node.input[i]['tableObj'].field;
							obj.mapProperty = 'input_mapping' + i;
							obj.mapped = 'input_mapped' + i;
							let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
							temp.load(node, 0);
						});
					}
				}
			}
		}

		if (node.hasOwnProperty('output')) {
			if (Array.isArray(node.output)) {
				for (let i in node.output) {

					obj = new Object();
					obj.shape = this.shape['output_value' + i];
					obj.shapeName = 'output_value' + i;
					obj.shapeText = this.shape['output_value' + i + '_text'];
					obj.propertyValue = 'output';
					obj.shapeVariable = 'output_value' + i + '_text';
					obj.propertyKey = 'value';
					obj.position = i;
					obj.mapped = 'output_mapped' + i;
					obj.mapping = 'output_mapping' + i;
					if (node.output[i]['type'] == 'table')
						obj.dataType = 'Table';
					this.addVariableGroupHandler(obj);

					obj = new Object();
					obj.shape = [this.shape['output_name' + [i]], this.shape['output_name' + [i] + '_arrow'], this.shape['output_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'outputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new BOSParamEvent(e.e, 'output', i));
						temp.load(node, 0);
					});

					if (this.shape['output_plus_' + i]) {
						this.shape['output_plus_' + i].on('mousedown', function (e) {
							
							node.output.push(new InputOutObj());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.output, new InputOutObj()));
						});
					}

					if (this.shape['output_minus_' + i]) {
						this.shape['output_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.output.splice(i, 1);
							if (node.output.length === 0) {
								node.output.push(new InputOutObj());
							}
							else {
								node.shape.height -= 40;
							}
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.output, i, oldVal[0]));
						});
					}

					if (this.shape['output_mapping_icon' + i]) {
						this.shape['output_mapping_icon' + i].on('mousedown', function (e) {
							let obj = {};
							obj.setTable = node.output[i]['value'];
							obj.toTable = node.buildedName + '$Output.$' + node.output[i]['name'];
							obj.toType = 'to';
							obj.toCol = node.output[i]['tableObj'].field;
							obj.mapProperty = 'output_mapping' + i;
							obj.mapped = 'output_mapped' + i;
							let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
							temp.load(node, 0);
						});
					}
				}
			}
		}

		if (this.shape.bos) {
			obj = new Object();
			obj.shape = [this.shape.bos, this.shape.bos_arrow, this.shape.bos_text];
			this.addDropDownHandler(obj, this, 'bos', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new BosEvent(e.e, x, y, 'bos'));
				temp.load(node, 0);
			});
		}
		this.addGroupMouseOverHandler();
	},
	addSapFunctionHandler: function () {
		let obj = null;
		let node = this;
		if (node.hasOwnProperty('input')) {
			if (Array.isArray(node.input)) {
				for (let i in node.input) {

					if (typeof (node.input[i].value) == 'string') {
						obj = new Object();
						obj.shape = this.shape['input_value' + i];
						obj.shapeName = 'input_value' + i;
						obj.shapeText = this.shape['input_value' + i + '_text'];
						obj.propertyValue = 'input';
						obj.shapeVariable = 'input_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						obj.mapping = 'input_mapping' + i;
						obj.mapped = 'input_mapped' + i;
						this.addEditVariableHandler(obj);
					}
					else {
						node.input[i].value.addClickHandler();
					}

					obj = new Object();
					obj.shape = [this.shape['input_name' + [i]], this.shape['input_name' + [i] + '_arrow'], this.shape['input_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'inputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = null;
						if (node.type != 'ws')
							temp = window.application.createDropdownMenu(new SAPColumnEvent(e.e, 'input', i));
						else {
							let obj = {};
							obj.columns = node.inputColumns;
							obj.idx = i;
							obj.event = e.e;
							obj.setValue = 'input';
							temp = window.application.createDropdownMenu(new WebServiceParamEvent(obj));
						}
						temp.load(node, 0);
					});

					if (this.shape['input_name' + [i] + '_child_plus_icon']) {
						this.shape['input_name' + [i] + '_child_plus_icon'].on('mousedown', function (e) {
							
							if (!node.input[i].name.endsWith('$_')) {
								let preVal = node.input[i].name;
								node.input[i].name += '$_'
								window.application.addCommand(new ChangeCommand(node.input, ['name'], [preVal], [node.input[i].name], i));
							}
							window.application.refresh();
						});
					}

					if (this.shape['input_name' + [i] + '_child_close_icon']) {
						this.shape['input_name' + [i] + '_child_close_icon'].on('mousedown', function (e) {
							if (node.input[i].name.endsWith('$_')) {
								let preVal = node.input[i].name;
								node.input[i].name = node.input[i].name.substring(0, node.input[i].name.length - 2);
								window.application.addCommand(new ChangeCommand(node.input, ['name'], [preVal], [node.input[i].name], i));
							}
							window.application.refresh();
						});
					}

					let shapesFlag = ['input_name' + [i] + '_child_text', 'input_name' + [i] + '_child_drop_arrow', 'input_name' + [i] + '_child_arrow'];
					for (let flag of shapesFlag) {
						if (this.shape[flag]) {
							this.shape[flag].on('mousedown', function (e) {
								let temp = window.application.createDropdownMenu(new TableColoumnsEvent(e.e, 'input', 'tableObj', i, 'name'));
								temp.load(node, 0);
							});
						}
					}

					if (this.shape['input_plus_' + i]) {
						this.shape['input_plus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								node.input.push(new InputOutObj());
								node.shape.height += 40;
								window.application.refresh();
								window.application.addCommand(new PopCommand(node.input, new InputOutObj()));
							}
						});
					}

					if (this.shape['input_minus_' + i]) {
						this.shape['input_minus_' + i].on('mousedown', function (e) {
							if (window.application.canCreate || window.application.canUpdate) {
								let oldVal = node.input.splice(i, 1);
								if (node.input.length === 0)
									node.input.push(new InputOutObj());
								else
									node.shape.height -= 40;
								window.application.refresh();
								window.application.addCommand(new SpliceCommand(node.input, i, oldVal[0]));
							}
						});
					}

					if (this.shape['input_mapping_icon' + i]) {
						this.shape['input_mapping_icon' + i].on('mousedown', function (e) {
							let obj = {};
							obj.toTable = node.input[i]['value'];
							obj.setTable = node.input[i].tableObj.Xpath;
							//obj.setTable = node.buildedName + '$Input.$' + node.input[i]['name'];
							obj.toType = 'set';
							obj.setCol = node.input[i]['tableObj'].field;
							obj.mapProperty = 'input_mapping' + i;
							obj.mapped = 'input_mapped' + i;
							let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
							temp.load(node, 0);
						});
					}
				}
			}
		}

		if (node.hasOwnProperty('output')) {
			if (Array.isArray(node.output)) {
				for (let i in node.output) {

					obj = new Object();
					obj.shape = this.shape['output_value' + i];
					obj.shapeName = 'output_value' + i;
					obj.shapeText = this.shape['output_value' + i + '_text'];
					obj.propertyValue = 'output';
					obj.shapeVariable = 'output_value' + i + '_text';
					obj.propertyKey = 'value';
					obj.position = i;
					obj.mapping = 'output_mapping' + i;
					obj.mapped = 'output_mapped' + i;
					this.addVariableGroupHandler(obj);

					obj = new Object();
					obj.shape = [this.shape['output_name' + [i]], this.shape['output_name' + [i] + '_arrow'], this.shape['output_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'outputType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = null;
						if (node.type != 'ws')
							temp = window.application.createDropdownMenu(new SAPColumnEvent(e.e, 'output', i));
						else {
							let obj = {};
							obj.columns = node.outputColumns;
							obj.idx = i;
							obj.event = e.e;
							obj.setValue = 'output';
							temp = window.application.createDropdownMenu(new WebServiceParamEvent(obj));
						}
						temp.load(node, 0);
					});

					if (this.shape['output_name' + [i] + '_child_plus_icon']) {
						this.shape['output_name' + [i] + '_child_plus_icon'].on('mousedown', function (e) {
							
							if (!node.output[i].name.endsWith('$_')) {
								let preVal = node.output[i].name;
								node.output[i].name += '$_'
								window.application.addCommand(new ChangeCommand(node.output, ['name'], [preVal], [node.output[i].name], i));
							}
							window.application.refresh();
						});
					}

					if (this.shape['output_name' + [i] + '_child_close_icon']) {
						this.shape['output_name' + [i] + '_child_close_icon'].on('mousedown', function (e) {
							if (node.output[i].name.endsWith('$_')) {
								let preVal = node.output[i].name;
								node.output[i].name = node.output[i].name.substring(0, node.output[i].name.length - 2);
								window.application.addCommand(new ChangeCommand(node.output, ['name'], [preVal], [node.output[i].name], i));
							}
							window.application.refresh();
						});
					}

					let shapesFlag = ['output_name' + [i] + '_child_text', 'output_name' + [i] + '_child_drop_arrow', 'output_name' + [i] + '_child_arrow'];
					for (let flag of shapesFlag) {
						if (this.shape[flag]) {
							this.shape[flag].on('mousedown', function (e) {
								let temp = window.application.createDropdownMenu(new TableColoumnsEvent(e.e, 'output', 'tableObj', i, 'name'));
								temp.load(node, 0);
							});
						}
					}

					if (this.shape['output_plus_' + i]) {
						this.shape['output_plus_' + i].on('mousedown', function (e) {
							
							node.output.push(new InputOutObj());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.output, new InputOutObj()));
						});
					}

					if (this.shape['output_minus_' + i]) {
						this.shape['output_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.output.splice(i, 1);
							if (node.output.length === 0) {
								node.output.push(new InputOutObj());
							}
							else {
								node.shape.height -= 40;
							}
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.output, i, oldVal[0]));
						});
					}

					if (this.shape['output_mapping_icon' + i]) {
						this.shape['output_mapping_icon' + i].on('mousedown', function (e) {
							let obj = {};
							obj.setTable = node.output[i]['value'];
							obj.toTable = node.output[i].tableObj.Xpath;
							obj.toType = 'to';
							obj.toCol = node.output[i]['tableObj'].field;
							obj.mapProperty = 'output_mapping' + i;
							obj.mapped = 'output_mapped' + i;
							let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
							temp.load(node, 0);
						});
					}
				}
			}
		}

		if (this.shape.module) {
			let self = this;
			obj = new Object();
			obj.shape = [this.shape.module, this.shape.module_arrow, this.shape.module_text];
			this.addDropDownHandler(obj, this, 'module', function (e) {
				let temp = window.application.createDropdownMenu(self.nodeName == 'wsList' ? new ModuleListEvent(e.e, 'moduleList', 'module') : new SAPModuleListEvent(e.e, 'moduleList', 'module'));
				temp.load(node, 0);
			});
		}

		if (this.shape.include) {
			obj = new Object();
			obj.shape = [this.shape.include, this.shape.include_arrow, this.shape.include_text];
			this.addDropDownHandler(obj, this, 'include', function (e) {
				let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'includeTypes', 'include'));
				temp.load(node, 0);
			});
		}

		// if (this.shape.attachment){
		// 	obj = new Object();
		// 	obj.shape = [this.shape.attachment, this.shape.attachment_arrow, this.shape.attachment_text];
		// 	this.addDropDownHandler(obj, this, 'include', function (e) {
		// 		let temp = window.application.createDropdownMenu(new ChangeEvent(e.e,'attachmentTypes', 'attachment'));
		// 		temp.load(node, 0);
		// 	});
		// }

		this.addSystemDropDownEvent();
		this.addGroupMouseOverHandler();
	},
	addFreedomDbHandler: function () {
		let node = this;
		let obj = null;
		let isSF = (node.nodeName == "sfList" || node.nodeName == "sfCreate" || node.nodeName == "sfUpdate" || node.nodeName == "sfDelete") ? true : false;
		if (this.hasOwnProperty('db')) {
			obj = new Object();
			obj.shape = [this.shape['db'], this.shape['db_arrow'], this.shape['db_text']];
			this.addDropDownHandler(obj, this, 'inputType', function (e) {
				let temp = window.application.createDropdownMenu(new SnowFlakeDbEvent(e.e, 'dbObj'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('schema')) {
			obj = new Object();
			obj.shape = [this.shape['schema'], this.shape['schema_arrow'], this.shape['schema_text']];
			this.addDropDownHandler(obj, this, 'inputType', function (e) {
				let temp = window.application.createDropdownMenu(new SnowFlakeSchemaEvent(e.e, 'schemaObj'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('table')) {
			if (this.type == 'ar') {
				obj = new Object();
				obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = window.application.createDropdownMenu(new LocalTableGetEvent(e.e, 'table', 'tableObj'));
					temp.load(node, 0);
				});
			} else {
				obj = new Object();
				obj.shape = [this.shape.table, this.shape.table_arrow, this.shape.table_text];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = window.application.createDropdownMenu(isSF ? new SFTableEvent(e.e, x, y, 'tableObj') : new FDBTableEvent(e.e, x, y, 'tableObj'));
					temp.load(node, 0);
				});
			}
		}

		if (this.hasOwnProperty('selectColumnsLists')) {
			obj = new Object();
			obj.shape = [this.shape['columnList'], this.shape['columnList_arrow'], this.shape['columnList_text']];
			this.addDropDownHandler(obj, this, 'columnListType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(isSF ? new SFSelectColumnEvent(e.e, x, y, 'tableObj', 'select', 'selectColumnsLists') : new FDBSelectColumnEvent(e.e, x, y, 'tableObj', 'select', 'selectColumnsLists'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('selectColumn')) {
			obj = new Object();
			obj.shape = [this.shape['selectColumn'], this.shape['selectColumn_arrow'], this.shape['selectColumn_text']];
			this.addDropDownHandler(obj, this, 'columnListType', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu( new FDBSelectColumnEvent(e.e, x, y, 'tableObj', 'single', null, null, 'selectColumn'));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('column')) {
			if (Array.isArray(this.column)) {
				for (let i in this.column) {
					if (this.type == 'ar') {
						obj = new Object();
						obj.shape = [this.shape['column_name' + [i]], this.shape['column_name' + [i] + '_arrow'], this.shape['column_name' + [i] + '_text']];
						this.addDropDownHandler(obj, this, 'columnType', function (e) {
							let x = e.e.layerX;
							let y = e.e.layerY + 30;
							let temp = window.application.createDropdownMenu(new LocalTableColoumnsEvent(e.e, x, y, 'column', 'tableObj', i));
							temp.load(node, 0);
						});
					}
					else {
						obj = new Object();
						obj.shape = [this.shape['column_name' + [i]], this.shape['column_name' + [i] + '_arrow'], this.shape['column_name' + [i] + '_text']];
						this.addDropDownHandler(obj, this, 'columnType', function (e) {
							let x = e.e.layerX;
							let y = e.e.layerY + 30;
							let temp = window.application.createDropdownMenu(isSF ? new SFSelectColumnEvent(e.e, x, y, 'tableObj', 'other', 'column', i, 'name', 'column') : new FDBSelectColumnEvent(e.e, x, y, 'tableObj', 'other', 'column', i, 'name', 'column'));
							temp.load(node, 0);
						});
					}

					if (typeof (this.column[i]['value']) == 'string') {
						obj = new Object();
						obj.shape = this.shape['column_value' + i];
						obj.shapeName = 'column_value' + i;
						obj.shapeText = this.shape['column_value' + i + '_text'];
						obj.propertyValue = 'column';
						obj.shapeVariable = 'column_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						this.column[i]['value'].addClickHandler();
					}

					if (this.shape['column_plus_' + i]) {
						this.shape['column_plus_' + i].on('mousedown', function (e) {
							
							node.column.push(new FDBColumn());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.column, new FDBColumn()));
						});
					}

					if (this.shape['column_minus_' + i]) {
						this.shape['column_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.column.splice(i, 1);
							if (node.column.length === 0)
								node.column.push(new FDBColumn());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.column, i, oldVal[0]));
						});
					}
				}
			}
		}

		if (this.hasOwnProperty('where')) {
			if (Array.isArray(this.where)) {
				for (let i in this.where) {
					obj = new Object();
					obj.shape = [this.shape['where_name' + [i]], this.shape['where_name' + [i] + '_arrow'], this.shape['where_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'whereType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(isSF ? new SFSelectColumnEvent(e.e, x, y, 'tableObj', 'where', 'where', i, 'name', 'column') : new FDBSelectColumnEvent(e.e, x, y, 'tableObj', 'other', 'where', i, 'name', 'column'));
						temp.load(node, 0);
					});

					obj = new Object();
					obj.shape = [this.shape['where_Type' + [i]], this.shape['where_Type' + [i] + '_arrow'], this.shape['where_Type' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'whereType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'operatorsList', 'where', 'operator', i));
						temp.load(node, 0);
					});

					if (typeof (this.where[i]['value']) == 'string') {
						obj = new Object();
						obj.shape = this.shape['where_value' + i];
						obj.shapeName = 'where_value' + i;
						obj.shapeText = this.shape['where_value' + i + '_text'];
						obj.propertyValue = 'where';
						obj.shapeVariable = 'where_value' + i + '_text';
						obj.propertyKey = 'value';
						obj.property = 'child';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						this.where[i]['value'].addClickHandler();
					}

					if (this.shape['where_plus_' + i]) {
						this.shape['where_plus_' + i].on('mousedown', function (e) {
							
							node.where.push(new FDBWhere());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.where, new FDBWhere()));
						});
					}

					if (this.shape['where_minus_' + i]) {
						this.shape['where_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.where.splice(i, 1);
							if (node.where.length === 0)
								node.where.push(new FDBWhere());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.where, i, oldVal[0]));
						});
					}
				}
			}
		}
		if (this.hasOwnProperty('sort')) {
			if (Array.isArray(this.sort)) {
				for (let i in this.sort) {
					obj = new Object();
					obj.shape = [this.shape['sort_name' + [i]], this.shape['sort_name' + [i] + '_arrow'], this.shape['sort_name' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'sortType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new FDBSelectColumnEvent(e.e, x, y, 'tableObj', 'other', 'sort', i, 'sortColumn'));
						temp.load(node, 0);
					});

					obj = new Object();
					obj.shape = [this.shape['sort_Type' + [i]], this.shape['sort_Type' + [i] + '_arrow'], this.shape['sort_Type' + [i] + '_text']];
					this.addDropDownHandler(obj, this, 'sortType', function (e) {
						let x = e.e.layerX;
						let y = e.e.layerY + 30;
						let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'sortOrderList', 'sort', 'sortOrder', i));
						temp.load(node, 0);
					});

					if (this.shape['sort_plus_' + i]) {
						this.shape['sort_plus_' + i].on('mousedown', function (e) {
							
							node.sort.push(new FunctionSort());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.sort, new FunctionSort()));
						});
					}

					if (this.shape['sort_minus_' + i]) {
						this.shape['sort_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.sort.splice(i, 1);
							if (node.sort.length === 0)
								node.sort.push(new FunctionSort());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.sort, i, oldVal[0]));
						});
					}
				}
			}
		}
		if (this.hasOwnProperty('system'))
			this.addSystemDropDownEvent();
		if (this.hasOwnProperty('to')) {
			obj = new Object();
			obj.shape = this.shape.to;
			obj.shapeName = 'to';
			obj.shapeText = this.shape.to_text;
			obj.propertyValue = 'to';
			obj.shapeVariable = 'to_text';
			if (this.type != 'fdbcreate' && this.type != 'dbacreate' && this.type != 'cfq')
				obj.dataType = 'Table';
			obj.mapped = 'mapped';
			obj.mapping = 'mapping';
			if (this.type != 'fdbcreate' && this.type != 'dbacreate' && this.type != 'cfq')
				this.addVariableGroupHandler(obj);
			else
				this.addVariableGroupHandler(obj);
		}

		this.addGroupMouseOverHandler();

		if (this.shape.mapping_icon) {
			this.shape.mapping_icon.on('mousedown', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let obj = {};
				obj.setTable = node.to;
				obj.toTable = node.buildedName + '$Output.$Table';
				obj.toType = 'to';
				obj.toCol = node.selectColumnsLists;
				obj.mapProperty = 'mapping';
				obj.mapped = 'mapped';
				let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
				temp.load(node, 0);
			});
		}

	},
	addFreedomDbQueryHandler: function () {
		let obj = null;
		let node = this;
		if (node.hasOwnProperty('param')) {
			if (Array.isArray(node.param)) {
				for (let i in node.param) {

					if (typeof (node.param[i].value) == 'string') {
						obj = new Object();
						obj.shape = this.shape['param_' + i];
						obj.shapeName = 'param_' + i;
						obj.shapeText = this.shape['param_' + i + '_text'];
						obj.propertyValue = 'param';
						obj.shapeVariable = 'param_' + i + '_text';
						obj.propertyKey = 'value';
						obj.position = i;
						this.addEditVariableHandler(obj);
					}
					else {
						node.param[i].value.addClickHandler();
					}

					if (this.shape['param_plus_' + i]) {
						this.shape['param_plus_' + i].on('mousedown', function (e) {
							
							node.param.push(new InputOutObj());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.param, new InputOutObj()));
						});
					}

					if (this.shape['param_minus_' + i]) {
						this.shape['param_minus_' + i].on('mousedown', function (e) {
							
							let oldVal = node.param.splice(i, 1);
							if (node.param.length === 0)
								node.param.push(new InputOutObj());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.param, i, oldVal[0]));
						});
					}
				}
			}
		}

		if (this.hasOwnProperty('to') && this.hasTo) {
			obj = new Object();
			obj.shape = this.shape.to;
			obj.shapeText = this.shape.to_text;
			obj.propertyValue = 'to';
			obj.shapeVariable = 'to_text';
			if (this.sqlType == 'select')
				obj.dataType = 'Table';
			obj.mapped = 'mapped';
			obj.mapping = 'mapping';
			this.addVariableGroupHandler(obj);
		}

		if (this.shape.mapping_icon) {
			this.shape.mapping_icon.on('mousedown', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let obj = {};
				obj.setTable = node.to;
				obj.toTable = node.buildedName + '$Output.$Table';
				obj.toType = 'to';
				obj.toCol = node.selectColumnsLists;
				obj.mapProperty = 'mapping';
				obj.mapped = 'mapped';
				let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
				temp.load(node, 0);
			});
		}

		if (this.hasOwnProperty('query')) {
			obj = new Object();
			obj.shape = this.shape.query_text_text;
			obj.shape1 = this.shape.query_text;
			obj.propertyValue = 'query';
			obj.shapeName = 'query_text';
			obj.shapeVariable = 'query_text_text';
			this.addVariableValueHandler(obj, this);
			// obj = new Object();
			// obj.shape = this.shape.query_text;
			// obj.shapeName = 'query_text';
			// obj.shapeText = this.shape.query_text_text;
			// obj.propertyValue = 'query';
			// obj.shapeVariable = 'query_text_text';
			// this.addEditVariableHandler(obj);
		}

		this.addGroupMouseOverHandler();
	},
	addRestEvents: function(){
		let obj = null;
	let node = this;
	if (node.hasOwnProperty('input')) {
		if (Array.isArray(node.input)) {
			for (let i in node.input) {
				if(node.nodeName != 'rolelist'){
					if (!node.parameterWithoutKey || node.parameterWithoutKey.indexOf(node.input[i].objType) == -1){
						if (typeof (node.input[i].name) == 'string') {
							obj = new Object();
							obj.shape = this.shape['input_name' + i];
							obj.shapeName = 'input_name' + i;
							obj.shapeText = this.shape['input_name' + i + '_text'];
							obj.propertyValue = 'input';
							obj.propertyKey = 'name';
							obj.shapeVariable = 'input_name' + i + '_text';
							obj.position = i;
							this.addEditVariableHandler(obj);
						}
						else {
							node.input[i].name.addClickHandler();
						}
					}
				}

				if (typeof (node.input[i].value) == 'string') {
					obj = new Object();
					obj.shape = this.shape['input_value' + i];
					obj.shapeName = 'input_value' + i;
					obj.shapeText = this.shape['input_value' + i + '_text'];
					obj.propertyValue = 'input';
					obj.propertyKey = 'value';
					obj.shapeVariable = 'input_value' + i + '_text';
					obj.position = i;
					this.addEditVariableHandler(obj);
				}
				else {
					node.input[i].value.addClickHandler();
				}

				obj = new Object();
				obj.shape = [this.shape['input_type' + [i]], this.shape['input_type' + [i] + '_arrow'], this.shape['input_type' + [i] + '_text']];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let type = node.type == 'odl'? 'once':'single';
					let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'parameterTypes', 'input', 'objType', i, type));
					temp.load(node, 0);
				});

				if (this.shape['input_plus_' + i]) {
					this.shape['input_plus_' + i].on('mousedown', function (e) {
						if (window.application.canCreate || window.application.canUpdate) {
							node.input.push(new InputOutObj());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.input, new InputOutObj()));
						}
					});
				}

				if (this.shape['input_minus_' + i]) {
					this.shape['input_minus_' + i].on('mousedown', function (e) {
						if (window.application.canCreate || window.application.canUpdate) {
							let oldVal = node.input.splice(i, 1);
							if (node.input.length === 0)
								node.input.push(new InputOutObj());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.input, i, oldVal[0]));
						}
					});
				}

			}
		}
	}

	if (node.hasOwnProperty('output')) {

		if (Array.isArray(node.output)) {
			for (let i in node.output) {
				obj = new Object();
				obj.shape = this.shape['output_value' + i];
				obj.shapeName = 'output_value' + i;
				obj.shapeText = this.shape['output_value' + i + '_text'];
				obj.propertyValue = 'output';
				obj.propertyKey = 'value';
				obj.shapeVariable = 'output_value' + i + '_text';
				obj.position = i;
				obj.mapped = 'output_mapped' + i;
				obj.mapping = 'output_mapping' + i;
				if (node.output[i]['type'] == 'table')
					obj.dataType = 'Table';
				this.addVariableGroupHandler(obj);

				obj = new Object();
				obj.shape = [this.shape['output_type' + [i]], this.shape['output_type' + [i] + '_arrow'], this.shape['output_type' + [i] + '_text']];
				this.addDropDownHandler(obj, this, 'outputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'outputTypes', 'output', 'objType', i));
					temp.load(node, 0);
				});

				if (this.shape['output_plus_' + i]) {
					this.shape['output_plus_' + i].on('mousedown', function (e) {
						
						node.output.push(new InputOutObj());
						node.shape.height += 40;
						window.application.refresh();
						window.application.addCommand(new PopCommand(node.output, new InputOutObj()));
					});
				}

				if (this.shape['output_minus_' + i]) {
					this.shape['output_minus_' + i].on('mousedown', function (e) {
						
						let oldVal = node.output.splice(i, 1);
						if (node.output.length === 0)
							node.output.push(new InputOutObj());
						else
							node.shape.height -= 40;
						window.application.refresh();
						window.application.addCommand(new SpliceCommand(node.output, i, oldVal[0]));
					});
				}

				if (this.shape['output_mapping_icon' + i]) {
					this.shape['output_mapping_icon' + i].on('mousedown', function (e) {
						let obj = {};
						obj.toTable = node.output[i]['value'];
						obj.setTable = node.buildedName + '$Output.$' + node.output[i]['name'];
						obj.toType = 'set';
						obj.toCol = node.output[i]['tableObj'].field;
						obj.mapProperty = 'output_mapping' + i;
						obj.mapped = 'output_mapped' + i;
						let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
						temp.load(node, 0);
					});
				}
			}
		}

	}
	if (node.hasOwnProperty('basedn')) {
		if (typeof (this.basedn) == 'string') {
			obj = new Object();
			obj.shape = this.shape['basedn'];
			obj.shapeName = 'basedn';
			obj.shapeText = this.shape['basedn_text'];
			obj.propertyValue = 'basedn';
			obj.shapeVariable = 'basedn_text';
			this.addEditVariableHandler(obj);
		}
		else {
			this.basedn.addClickHandler();
		}
	}
	if (node.hasOwnProperty('filter')) {
		if (typeof (this.filter) == 'string') {
			obj = new Object();
			obj.shape = this.shape['filter'];
			obj.shapeName = 'filter';
			obj.shapeText = this.shape['filter_text'];
			obj.propertyValue = 'filter';
			obj.shapeVariable = 'filter_text';
			this.addEditVariableHandler(obj);
		}
		else {
			this.filter.addClickHandler();
		}
	}
	if (node.hasOwnProperty('url')) {
		if (typeof (this.url) == 'string') {
			obj = new Object();
			obj.shape = this.shape['url'];
			obj.shapeName = 'url';
			obj.shapeText = this.shape['url_text'];
			obj.propertyValue = 'url';
			obj.shapeVariable = 'url_text';
			this.addEditVariableHandler(obj);
		}
		else {
			this.url.addClickHandler();
		}
	}
	if (this.hasOwnProperty('to')) {
		obj = new Object();
		obj.shape = this.shape.to;
		obj.shapeName = 'to';
		obj.shapeText = this.shape.to_text;
		obj.propertyValue = 'to';
		obj.shapeVariable = 'to_text';
		obj.dataType = 'Table';
		obj.mapped = 'mapped';
		this.addVariableGroupHandler(obj);

		if (this.shape.mapping_icon) {
			this.shape.mapping_icon.on('mousedown', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let obj = {};
				obj.setTable = node.to;
				obj.toTable = node.buildedName + '$Output.$Table';
				obj.toType = 'to';
				if (node.selectColumnsLists)
					obj.toCol = node.selectColumnsLists;
				else
					obj.toCol = node.getColumns();
				obj.mapProperty = 'mapping';
				obj.mapped = 'mapped';
				let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
				temp.load(node, 0);
			});
		}
	}

	this.addSystemDropDownEvent();
	this.addGroupMouseOverHandler();

	},
	addSystemDropDownEvent: function () {
		let node = this;
		let obj = null;
		if (this.shape.system) {
			let obj = new Object();
			obj.shape = [this.shape.system, this.shape.system_arrow, this.shape.system_text];
			this.addDropDownHandler(obj, node, 'system', function (e) {
				let x = e.e.layerX;
				let y = e.e.layerY + 30;
				let temp = window.application.createDropdownMenu(new SystemsEvent(e.e));
				temp.load(node, 0);
			});
		}

		if (this.shape.password) {
			obj = new Object();
			obj.shape = this.shape.password;
			obj.shapeName = 'password';
			obj.shapeText = this.shape.password_text;
			obj.propertyValue = 'system';
			obj.propertyKey = 'password';
			obj.shapeVariable = 'password_text';
			obj.isPassword = true;
			this.addEditVariableHandler(obj);
		}

		// if (this.shape.userName){
		// 	obj = new Object();
		// 	obj.shape = this.shape.userName;
		// 	obj.shapeText = this.shape.userName_text;
		// 	obj.propertyValue = 'system';
		// 	obj.propertyKey = 'username';
		// 	obj.shapeVariable = 'userName_text';
		// 	this.addVariableGroupHandler(obj);
		// }
	},
	addGroupMouseOverHandler: function () {
		let node = this;
		if (this.shape.group) {
			this.shape.group.on('mouseover', function () { 
				if (!node.shape.mouseOver) {
					node.shape.mouseOver = true; 
					window.application.refresh(); 
				}
			});
			this.shape.group.on('mouseout', function () { 
				if (node.shape.mouseOver) {
					node.shape.mouseOver = false; 
					window.application.refresh(); 
				}
			});
		}
	},
	addChild: function (node, index) {
		if (node.l && node.l.indexOf(this.lane) == -1) {
			window.application.handleError("Error", "E001");
			return false;
		} else {
			this.childs.splice(index, 0, node);
			return true;
		}
	},
	deleteChild: function (node) {
		const index = this.childs.indexOf(node);
		if (index > -1) {
			this.childs.splice(index, 1);
		}
		window.application.addCommand(new DeleteNodeCommand(node, index));
	},
	setParent: function (node) {
		this.parent = node;
	},
	getChilds : function(){
		return this.childs;
	},
	showProperties: function () {

	},
	editProperties: function () {

	},
	getColor: function () {
		return "#ddd";
	},
	toObject: function () {
	},
	set: function (obj) {
	},
	selectedNodeParamSize: function () {
		var par = this.parameter.Input;
		var inputsize = 0;
		for (var key in par) {
			// console.log("par key: "+key);
			inputsize++;
		}
		return inputsize;
	},
	updateSystem: function (oldValue, newValue) {
		this.parameter.update("RfcDestination", oldValue, newValue);
	},
	copy: function () {
		var cpynode = window.utils.createNode(this.name, this.shape.x,
			this.shape.y, this.type);
		this.copyParams(cpynode);
		// cpynode.shape = this.shape.copy();
		cpynode.childs = new Array();
		for (var i = 0; i < this.childs.length; i++) {
			cpynode.childs.push(this.childs[i].copy());
		}
		cpynode.isCommented = this.isCommented;
		cpynode.minimize = this.minimize;
		return cpynode;
	},
	copyParams: function () {

	},
	editingExited: function () {

	},
	isValid: function (name) {

	},
	hasLoop: function () {
		if (LoopTypes.indexOf(this.type))
			return true;
		return false
	},
	setParameter: function (node, type, exprssion, xpath, name, val) {
		node.parameter[type][name] = {};
		node.parameter[type][name]["Xpath"] = xpath;
		node.parameter[type][name]["Expression"] = exprssion;
		node.parameter[type][name]["name"] = name;
		node.parameter[type][name]["value"] = val;
		//node.parameter[type][xpath] --> node.parameter[type][name]  xpath should be lhs value 
	},
	setInputParameter: function () {
		for (let i = 0; i < this.input.length; i++) {
			if (this.input[i].rhsValue != "") {
				let xpath = this.nodeName + '$input.$' + this.input[i].rhsValue;
				let expression = 'BOS$' + this.input[i].lhsVariableScope + '.$' + this.input[i].lhsVariableValue;
				this.setParameter(this, "Input", expression, xpath, this.input[i].rhsValue, window.application.parse(expression));
			}
		}
	},
	setOutputParameter: function () {
		for (let i = 0; i < this.output.length; i++) {
			if (this.output[i].rhsVariableValue != "") {
				let xpath = 'BOS$' + this.output[i].rhsVariableScope + '.$' + this.output[i].rhsVariableValue;
				let expression = this.nodeName + '$output.$' + this.output[i].lhsValue
				this.setParameter(this, "Output", expression, xpath, this.output[i].rhsVariableValue, window.application.parse(expression));
			}
		}
	},
	setTableParameter: function () {
		this.parameter.Input["table"] = {}
		this.parameter.Input["table"] = window.utils.constructObject("table", this.table);
	},
	setSortParameter: function () {
		let _sort = "";
		for (let i = 0; i < this.sort.length; i++) {
			if (this.sort[i].sortColumn != '') {
				_sort = _sort + (_sort == '' ? '' : ' , ') + this.sort[i].sortColumn + ' ' + ((this.sort[i].sortOrder == '' || this.sort[i].sortOrder == 'ascending') ? ' asc' : ' desc') + ' ';
			}
		}
		this.parameter.Input["sort"] = window.utils.constructObject("sort", _sort);
	},
	setWhereParameter: function () {
		this.parameter.Input["where"] = {};
		let where = "";
		for (let i = 0; i < this.where.length; i++) {
			if (this.where[i].column != '') {
				let x = "";
				x = this.where[i].column + " " + this.where[i].operator + " "
				if (this.where[i].child && Object.keys(this.where[i].child).length > 0) {
					if (this.where[i].child.lval.name != '') {
						let _temp = {}
						_temp["lhs"] = "BOS$." + this.where[i].child.lval.scope + ".$" + this.where[i].child.lval.name;
						_temp["operator"] = this.where[i].child.conditionExpression;
						_temp["rhs"] = "BOS$." + this.where[i].child.rval.scope + ".$" + this.where[i].child.rval.name;
						x += " ( " + window.application.parse(_temp["lhs"] + " " + _temp["operator"] + " " + _temp["rhs"]) + " ) ";
					}
				} else {
					x += "BOS$." + this.where[i]["scope"] + ".$" + this.where[i].name;
				}
				where = (where == "") ? x : (where + " and " + x);
			}
		}
		var obj = new Object();
		obj.name = "where";
		obj.Xpath = "$" + "where";
		obj.Expression = "\"" + where + "\"";
		obj.value = window.application.parse(where);
		this.parameter.Input["where"] = obj;
	},
	setToParameter: function () {
		let expression = 'BOS$' + this.to["scope"] + '.$' + this.to["name"];
		let xpath = 'fdbselect$output.$table'
		//this.setParameter(this,"Output",expression,xpath,this.to.getValue("name"),window.application.parse(expression));
		this.setParameter(this, "Output", expression, xpath, xpath, window.application.parse(expression));
	},
	setColumnParameter: function () {
		for (let i = 0; i < this.columns.length; i++) {
			if (this.columns[i].column != "") {
				let expression = 'fdbCreate$input.$' + this.columns[i].column;
				let xpath = 'BOS$' + this.columns[i].scope + '.$' + this.columns[i].name;
				//this.setParameter(this, "Input", expression, xpath, this.columns[i].column, window.application.parse(expression));
				this.setParameter(this, "Input", expression, xpath, xpath, window.application.parse(expression));
			}
		}
	},
	toJSON: function () {
		let uiObj = {};
		let tags = [...this.nodeLeveluiRenderTags, ...this.uiRenderTags];
		for (let tag of tags){
			if (tag == 'system' && this[tag] && this[tag].system){
				let system = JSON.parse(JSON.stringify(this[tag]));
				// system.username = '';
				system.password = '';
				uiObj[tag] = system;
			} else {
				uiObj[tag] = this[tag];
			}
		}
		return uiObj;
	},
	getObject: function (type) {
		switch (type) {
			case 'where':
				return new FDBWhere();
			case 'sort':
				return new FunctionSort();
			case 'column':
				return new FDBColumn();
			case 'variables':
				return new Property();
			case 'system':
				return new System();
			case 'lval':
			case 'rval':
				return new FunctionVariable();
			default:
				return new Object();

		}
	},
	fromJSON: function (obj) {
		let tags = [...this.nodeLeveluiRenderTags, ...this.uiRenderTags];
		//let arrObj = {'where':new FDBWhere(),'sort':new FunctionSort(),'column':new FDBColumn(),'variables':new Property()};
		let arrObj = ['where', 'sort', 'column', 'variables', 'param', 'input'];
		//let valObj = {'system':new System(),'lval':new FunctionVariable(),'rval':new FunctionVariable()};
		let valObj = ['system', 'lval', 'rval'];
		let valueObj = ['toExpression', 'left', 'right', 'source', 'destination', 'tos', 'cc', 'subject', 'message', 'link', 'bos', 'url', 'updateLoginId',
			'title', 'fName', 'lName', 'email', 'loginId', 'role', 'toValue'];
		for (let tag of tags) {
			if (arrObj.includes(tag)) {
				let temp = (this.nodeName == "sort" && tag == 'input') ? 'column' : tag;
				let arr = []
				let i = 0;
				for (let val of obj[tag]) {
					arr.push(Object.assign(this.getObject(temp), JSON.parse(JSON.stringify(val))));
					if (arr[i].value && arr[i].value.hasOwnProperty('functionName') && arr[i].value.hasOwnProperty('variables')) {
						arr[i].value = this.getFunctionBlock(arr[i].value);
						// arr[i].value = this.createInnerNode(arr[i].value, arr[i].value.functionName, "fun");
						// arr[i].value.parent = this;
					} else if (arr[i].value && arr[i].value.hasOwnProperty('leftValue') && arr[i].value.hasOwnProperty('rightValue')) {
						arr[i].value = this.getMathBlock(arr[i].value);
						// arr[i].value = this.createInnerNode(arr[i].value, "Math", "m");
						// arr[i].value.parent = this;
					}
					if (this.type == 'rs') {
						if (arr[i].name && arr[i].name.hasOwnProperty('functionName') && arr[i].name.hasOwnProperty('variables')) {
							arr[i].name = this.getFunctionBlock(arr[i].name);
							// arr[i].name = this.createInnerNode(arr[i].name, arr[i].value.functionName, "fun");
							// arr[i].name.parent = this;
						} else if (arr[i].name && arr[i].name.hasOwnProperty('leftValue') && arr[i].name.hasOwnProperty('rightValue')) {
							arr[i].name = this.getMathBlock(arr[i].name);
							// arr[i].name = this.createInnerNode(arr[i].name, "Math", "m");
							// arr[i].name.parent = this;
						}
					}
					i++;
				}

				this[tag] = arr;
			} else if (valObj.includes(tag)) {
				if (obj[tag])
					this[tag] = Object.assign(this.getObject(tag), JSON.parse(JSON.stringify(obj[tag])));
				else
					this[tag] = this.getObject(tag);
			}
			else if (this.nodeName == "sort" && tag == 'input')
				this[tag] = Object.assign(this.getObject(tag), JSON.parse(JSON.stringify(obj[tag])));
			else if (['cond', 'leftCondition', 'rightCondition'].includes(tag) && obj[tag]) {
				this[tag] = this.getConditionBlock(obj[tag]);
				// this[tag] = this.createInnerNode(obj[tag], "condition", obj[tag].type);
				// this[tag].parent = this;
			} else if ((valueObj.includes(tag) && obj[tag]) ||
				(['toValue', 'leftValue', 'rightValue'].includes(tag) && (obj[tag].hasOwnProperty('functionName') && obj[tag].hasOwnProperty('variables'))) ||
				(['toValue', 'leftValue', 'rightValue'].includes(tag) && obj[tag].hasOwnProperty('conditionExpression'))
			) {
				if (obj[tag] && obj[tag].hasOwnProperty('functionName') && obj[tag].hasOwnProperty('variables')) {
					this[tag] = this.getFunctionBlock(obj[tag]);
					// this[tag] = this.createInnerNode(obj[tag], obj[tag].functionName, "fun");
					// this[tag].parent = this;
				} else if (obj[tag] && obj[tag].hasOwnProperty('leftValue') && obj[tag].hasOwnProperty('rightValue')) {
					this[tag] = this.getMathBlock(obj[tag]);
					// this[tag] = this.createInnerNode(obj[tag], "Math", "m");
					// this[tag].parent = this;
				}
				else
					// this[tag] = obj[tag];
					this[tag] = obj[tag] ? obj[tag] : this[tag] ? this[tag] : '';
			}
			else
				// this[tag] = obj[tag];
				this[tag] = obj[tag] ? obj[tag] : this[tag] ? this[tag] : '';
		}
	},
	createInnerNode: function (details, caption, type) {
		let childNode = window.utils.createNode(caption, 15, 4, type);
		childNode.fromJSON(details);
		return childNode;
	},
	getMinimizeText: function () {
		return this.name;
	},
	getMathBlock: function (val) {
		val = this.createInnerNode(val, "Math", "m");
		val.parent = this;
		// if (val.leftValue && typeof (val.leftValue) != 'string' && val.leftValue.hasOwnProperty('leftValue') && val.leftValue.hasOwnProperty('rightValue')){
		// 	val.leftValue = this.getMathBlock(val.leftValue);
		// } else if (val.leftValue && typeof (val.leftValue) != 'string' && val.leftValue.hasOwnProperty('functionName') && val.leftValue.hasOwnProperty('variables')){
		// 	val.leftValue = this.getFunctionBlock(val.leftValue);
		// }
		// if (val.rightValue && typeof (val.rightValue) != 'string' && val.rightValue.hasOwnProperty('leftValue') && val.rightValue.hasOwnProperty('rightValue')){
		// 	val.rightValue = this.getMathBlock(val.rightValue);
		// } else if (val.rightValue && typeof (val.rightValue) != 'string' && val.rightValue.hasOwnProperty('functionName') && val.rightValue.hasOwnProperty('variables')){
		// 	val.rightValue = this.getFunctionBlock(val.rightValue);
		// }
		return val;
	},
	getConditionBlock: function (val) {
		val = this.createInnerNode(val, "condition", val.type);
		val.parent = this;
		// if (val.leftCondition && typeof (val.leftCondition) != 'string' && val.leftCondition.hasOwnProperty('leftCondition') && val.leftCondition.hasOwnProperty('rightCondition')){
		// 	val.leftCondition = this.getConditionBlock(val.leftCondition);
		// } else 
		// if (val.leftValue && typeof (val.leftValue) != 'string' && val.leftValue.hasOwnProperty('leftValue') && val.leftValue.hasOwnProperty('rightValue')){
		// 	val.leftValue = this.getMathBlock(val.leftValue);
		// } else if (val.leftValue && typeof (val.leftValue) != 'string' && val.leftValue.hasOwnProperty('functionName') && val.leftValue.hasOwnProperty('variables')){
		// 	val.leftValue = this.getFunctionBlock(val.leftValue);
		// }
		// if (val.rightCondition && typeof (val.rightCondition) != 'string' && val.rightCondition.hasOwnProperty('leftCondition') && val.rightCondition.hasOwnProperty('rightCondition')){
		// 	val.rightCondition = this.getConditionBlock(val.rightCondition);
		// } else 
		// if (val.rightValue && typeof (val.rightValue) != 'string' && val.rightValue.hasOwnProperty('leftValue') && val.rightValue.hasOwnProperty('rightValue')){
		// 	val.rightValue = this.getMathBlock(val.rightValue);
		// } else if (val.rightValue && typeof (val.rightValue) != 'string' && val.rightValue.hasOwnProperty('functionName') && val.rightValue.hasOwnProperty('variables')){
		// 	val.rightValue = this.getFunctionBlock(val.rightValue);
		// }
		return val;
	},
	getFunctionBlock: function (val) {
		val = this.createInnerNode(val, val.functionName, "fun");
		val.parent = this;
		return val;
	}
};

function EmptyNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new EmptyShape(x, y, name, type);
}
EmptyNode.prototype = Object.create(Node.prototype);

function LaneNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = (name == "On device" ? 'runondevice' : (name == "On plugin" ? 'spr' : (name == "On cloud" ? 'runoncloud' : '')));
	this.plugin = '';
	this.connection = '';
	this.getLanes();
	if (this.type == 'rpr') {
		this.system = new System("", "D", "", "", "");
		this.systemTypes = ['ERP', 'SRM', 'CRM', 'DB', 'SF', 'MSD', 'SFTP', 'WS', 'RS', 'ECC'];
	}
	this.uiRenderTags = ["plugin", "system", 'connection'];
	this.haveChild = true;
}
LaneNode.prototype = Object.create(Node.prototype);
LaneNode.prototype.getLanes = function () {
	if (this.type == 'rpr') {
		this.l = ["rc"];
	} else {
		this.l = [];
	}
}

LaneNode.prototype.addEvents = function () {
	this.addSystemDropDownEvent();
	if (this.shape.plugin) {
		let obj = new Object();
		obj.shape = [this.shape.systemPlugin, this.shape.systemPlugin_text, this.shape.systemPlugin_arrow];
		let node = this;
		this.addDropDownHandler(obj, this, 'select', function (e) {
			let temp = window.application.createDropdownMenu(new PluginListEvent(e.e));
			temp.load(node, 0);
		});
	}
}

LaneNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.plugin;
}

LaneNode.prototype.copyParams = function (cpynode) {
	if (this.type == 'rpr') {
		cpynode.system = window.utils.cloneObjs('System', this.system);
		cpynode.plugin = window.utils.cloneObjs('', this.plugin);
	}
}

function AssignmentNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new AssignmentShape(name, x, y, type);
	this.nodeName = "assignment";
	this.properties = ['toExpression'];
	this.setValue = '';
	this.toValue = '';
	// this.toExpression = null;
	this.isTable = false;
	this.mapping = null;
	this.uiRenderTags = ["setValue", "toValue", "isTable", "mapping"];
}

AssignmentNode.prototype = Object.create(Node.prototype);

AssignmentNode.prototype.addEvents = function () {
	let node = this;

	let obj = new Object();
	obj.shape = this.shape.source;
	obj.shapeText = this.shape.source_text;
	obj.shapeName = 'source';
	obj.propertyValue = 'setValue';
	obj.shapeVariable = 'source_text';
	this.addVariableGroupHandler(obj);

	if (typeof (this.toValue) == 'string') {
		obj = new Object();
		obj.shape = this.shape.destination;
		obj.shapeText = this.shape.destination_text;
		obj.shapeName = 'destination';
		obj.propertyValue = 'toValue';
		// obj.property = 'toExpression';
		obj.shapeVariable = 'destination_text';
		this.addEditVariableHandler(obj, 'math');
	}
	else {
		this.toValue.addClickHandler();
	}

	if (this.shape.mapping_icon) {
		this.shape.mapping_icon.on('mousedown', function (e) {
			let x = e.e.layerX;
			let y = e.e.layerY + 30;
			let obj = {};
			obj.property = 'mapping';
			obj.setTable = node['setValue'];
			obj.toTable = node['toValue'];
			obj.mapProperty = 'mapping';
			obj.mapped = 'mapped';
			let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
			temp.load(node, 0);
		});
	}

	// if(this.shape.destination){
	// 	this.shape.destination.on('mousedblclick',function (e) {
	// 		let obj = {};
	// 		obj.setValue = 'toValue';
	// 		let temp = window.application.createDropdownMenu(new variableDropDownEvent(e.e, obj));
	// 		temp.load(node, 0);
	// 	});
	// }
}
AssignmentNode.prototype.constructParams = function () {
	let xpath = window.utils.variableSlice(this.setValue);
	let expr = '';
	if (typeof (this.toValue) != 'string') {
		expr = this.toValue.constructParams();
	} else {
		expr = (window.utils.variableSlice(this.toValue));
	}
	this.parameter.Input = {};
	if (typeof (this.toValue) == 'string'){
		let setVal = this.setValue.split('$');
		let toVal = this.toValue.split('$');
		if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1]) && toVal.length == 3 
				&& window.application.ui.parameter.checkTable(toVal[2], toVal[1])){
			this.setParameter(this, "Output", expr, xpath, xpath, expr);
		}
		else {
			this.setParameter(this, "Input", expr, xpath, xpath, window.application.parse(expr));
		}
	}
	else {
		this.setParameter(this, "Input", expr, xpath, xpath, window.application.parse(expr));
	}
}

AssignmentNode.prototype.copyParams = function (cpynode) {
	cpynode.setValue = this.setValue;
	if (typeof (this.toValue) == 'string') {
		cpynode.toValue = this.toValue;
	} else {
		cpynode.toValue = this.toValue.copy();
	}

	cpynode.isTable = this.isTable;
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
}

function IfNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new PolyShape(x, y, name, type);
	this.nodeName = "condition";
	this.cond = null;
	this.uiRenderTags = ["cond"];
	this.haveChild = true;
}
IfNode.prototype = Object.create(Node.prototype);
IfNode.prototype.addEvents = function () {
	let n = this;
	if (!this.cond) {
		var obj = new Object();
		obj.styleIn = { fill: '#E2D0FF' };
		obj.styleOut = { fill: '#FFFFFF' };
		obj.shape = this.shape.hexagon;
		obj.origin = 'rightMenu';
		obj.property = 'cond';
		// obj.propertyValue = 'condValue';
		this.addConditionHandler(obj, n);
	} else
		this.cond.addEvents();
};

IfNode.prototype.initilizeInputOutput = function () {
	this.input = new Array();
	this.output = new Array();
};

IfNode.prototype.constructParams = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.constructParams();
	}
	if (exp)
		this.parameter.Input["IfElse$Input.$Expression"] = window.utils.constructObject("IfElse$Input.$Expression", exp);
};
IfNode.prototype.getMinimizeText = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.getMinimizeText();
	}
	return this.name + " " + (exp ? ("( " + exp + " )") : '');
}

IfNode.prototype.copyParams = function (cpynode) {
	if (this.cond)
		cpynode.cond = this.cond.copy();
}

function MathNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new MathShape(x, y, name, type);
	this.nodeName = "math";
	this.leftValue = '';
	this.rightValue = '';
	this.properties = ['left', 'right'];
	this.conditions = ['+', '-', 'x', '/', '%'];
	this.conditionExpression = '+';
	this.lval = new FunctionVariable();
	this.rval = new FunctionVariable();
	this.uiRenderTags = ["leftValue", "rightValue", "conditionExpression", "lval", "rval"];
}

MathNode.prototype = Object.create(Node.prototype);

MathNode.prototype.addEvents = function () {
	let n = this;
	let obj = new Object();

	if (typeof (this.leftValue) == 'string') {
		obj = new Object();
		obj.shape = this.shape.leftVariable;
		obj.shapeName = 'leftVariable';
		obj.propertyValue = 'leftValue';
		obj.property = 'left';
		obj.shapeVariable = 'leftVariable_text';
		this.addEditVariableHandler(obj, 'function');
	}
	else {
		this.leftValue.addClickHandler();
	}

	if (typeof (this.rightValue) == 'string') {
		obj = new Object();
		obj.shape = this.shape.rightVariable;
		obj.shapeName = 'rightVariable';
		obj.propertyValue = 'rightValue';
		obj.property = 'right';
		obj.shapeVariable = 'rightVariable_text';
		this.addEditVariableHandler(obj, 'function');
	}
	else {
		this.rightValue.addClickHandler();
	}

	obj = new Object();
	obj.shape = [this.shape.operation, this.shape.operation_arrow, this.shape.operation_text];
	this.addDropDownHandler(obj, n, "condition");

	let mathDelete = function (event) {
		event.e.preventDefault();
		n.delete();
	};

	let components = ['ellipse', 'operation', 'operation_arrow', 'operation_text']
	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedblclick", mathDelete);
	}

	let mathDeleteEnable = function (event) {
		event.e.preventDefault();
		n.shape.objs[0].set({ stroke: '#FFF021', strokeWidth: 2 });
		let obj = new Object();
		obj.node = n;
		obj.type = 'node';
		window.application.selectedObj = obj;
		window.application.canvas.renderAll();
	};
	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedown", mathDeleteEnable);
	}

}

MathNode.prototype.delete = function () {
	if (this.parentValue) {
		if (this.parentKey && this.parentposition) {
			let preVal = this.parent[this.parentValue][this.parentposition][this.parentKey];
			this.parent[this.parentValue][this.parentposition][this.parentKey] = '';
			window.application.addCommand(new ChangeCommand(this.parent[this.parentValue], [this.parentKey], [preVal], [''], this.parentposition));
		}
		else if (this.parentKey) {
			let preVal = this.parent[this.parentValue][this.parentKey];
			this.parent[this.parentValue][this.parentKey] = '';
			window.application.addCommand(new ChangeCommand(this.parent[this.parentValue], [this.parentKey], [preVal], ['']));
		}
		else {
			let preVal = this.parent[this.parentValue];
			this.parent[this.parentValue] = '';
			window.application.addCommand(new ChangeCommand(this.parent, [this.parentValue], [preVal], ['']));
		}
		window.application.refresh();
	}
}


MathNode.prototype.constructParams = function () {
	let expr = "";
	if (typeof (this.leftValue) != 'string') {
		expr += "(" + this.leftValue.constructParams() + ")"
	} else if (this.leftValue != '') {
		expr += window.utils.variableSlice(this.leftValue);
	}
	if (this.conditionExpression){
		if (this.conditionExpression == 'x')
			expr += '*';
		else if (this.conditionExpression == '/')
			expr += '/';
		else
			expr += this.conditionExpression;
	}
	if (typeof (this.rightValue) != 'string') {
		expr += "(" + this.rightValue.constructParams() + ")"
	} else if (this.rightValue != '') {
		expr += window.utils.variableSlice(this.rightValue);
	}
	return expr;
}

MathNode.prototype.copyParams = function (cpynode) {
	if (typeof (this.leftValue) == 'string') {
		cpynode.leftValue = this.leftValue;
	}
	else {
		cpynode.leftValue = this.leftValue.copy();
	}
	if (typeof (this.rightValue) == 'string') {
		cpynode.rightValue = this.rightValue;
	}
	else {
		cpynode.rightValue = this.rightValue.copy();
	}
	cpynode.lval = this.lval;
	cpynode.rval = this.rval;
	cpynode.conditionExpression = this.conditionExpression;
}

function ConditionalNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new ConditionShape(x, y, name, type);
	this.leftCondition = null;
	this.rightCondition = null;
	this.nodeName = "condition";
	this.leftValue = '';
	this.rightValue = '';
	if (this.type == 'c') {
		this.conditions = ['<', '>', '<=', '>=', '==', '<>'];
		this.conditionExpression = '<';
	}
	else if (this.type == 'la') {
		this.conditions = ['and', 'or'];
		this.conditionExpression = 'and';
	}
	this.uiRenderTags = ["leftCondition", "rightCondition", "leftValue", "rightValue", "conditionExpression", "type"];
}

ConditionalNode.prototype = Object.create(Node.prototype);

ConditionalNode.prototype.addEvents = function () {
	let n = this;
	let obj = new Object();
	if ((!this.leftCondition && !this.leftValue) || (typeof this.leftValue == 'string')) {
		obj = new Object();
		obj.shape = this.shape.leftVariable;
		obj.shapeName = 'leftVariable';
		obj.property = 'leftCondition';
		obj.propertyValue = 'leftValue';
		if (typeof this.leftCondition == 'string' || (!this.leftCondition && typeof this.leftValue == 'string')) {
			if (this.type == 'c') {
				obj.shapeVariable = 'leftVariable_text';
				this.addEditVariableHandler(obj);
			}
			else
				this.addVariableGroupHandler(obj, 'cond');
		}
		else
			this.leftCondition.addEvents();
	} else if (this.leftValue) {
		if (typeof this.leftValue != 'string') 
			this.leftValue.addEvents();
	}
	else
		this.leftCondition.addEvents();
	if ((!this.rightCondition && !this.rightValue) || (typeof this.rightValue == 'string')) {
		obj = new Object();
		obj.shape = this.shape.rightVariable;
		obj.shapeName = 'rightVariable';
		obj.property = 'rightCondition';
		obj.propertyValue = 'rightValue';
		if (typeof this.rightCondition == 'string' || (!this.rightCondition && typeof this.rightValue == 'string')) {
			if (this.type == 'c') {
				obj.shapeVariable = 'rightVariable_text';
				this.addEditVariableHandler(obj);
			}
			else
				this.addVariableGroupHandler(obj, 'cond');
		}
		else
			this.rightCondition.addEvents();
	} else if (this.rightValue) {
		if (typeof this.rightValue != 'string') 
			this.rightValue.addEvents();
	} else
		this.rightCondition.addEvents();

	obj = new Object();
	obj.shape = [this.shape.operator, this.shape.operator_arrow, this.shape.operator_text];
	this.addDropDownHandler(obj, n, 'condition');

	let conditionDelete = function (event) {
		event.e.preventDefault();
		n.delete();
	};

	let components = ['polygon', 'operation', 'operation_arrow', 'operation_text'];
	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedblclick", conditionDelete);
	}

	let conditionDeleteEnable = function (event) {
		event.e.preventDefault();
		n.shape.objs[0].set({ stroke: '#FFF021', strokeWidth: 2 });
		let obj = new Object();
		obj.node = n;
		obj.type = 'node';
		window.application.selectedObj = obj;
		window.application.canvas.renderAll();
	};

	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedown", conditionDeleteEnable);
	}

};
ConditionalNode.prototype.constructParams = function () {
	let expr = "";
	if (this.leftCondition) {
		expr += "(" + this.leftCondition.constructParams() + ")"
	} else if (this.leftValue != '') {
		expr += window.utils.variableSlice(this.leftValue);
	}
	expr += this.conditionExpression;
	if (this.rightCondition) {
		expr += "(" + this.rightCondition.constructParams() + ")"
	} else if (this.rightValue != '') {
		expr += window.utils.variableSlice(this.rightValue);
	}
	return expr;
}
ConditionalNode.prototype.getMinimizeText = function () {
	let expr = "";
	if (this.leftCondition) {
		expr += "(" + this.leftCondition.getMinimizeText() + ")"
	} else if (this.leftValue != '') {
		expr += window.utils.getVariableName(this.leftValue);
	}
	expr += this.conditionExpression;
	if (this.rightCondition) {
		expr += "(" + this.rightCondition.getMinimizeText() + ")"
	} else if (this.rightValue != '') {
		expr += window.utils.getVariableName(this.rightValue);
	}
	return expr;
}
ConditionalNode.prototype.delete = function () {
	if (this.parentValue) {
		let preVal = this.parent[this.parentValue];
		this.parent[this.parentValue] = null;
		window.application.addCommand(new ChangeCommand(this.parent, [this.parentValue], [preVal], [null]));
		window.application.refresh();
	}
}

ConditionalNode.prototype.copyParams = function (cpynode) {
	cpynode.leftValue = this.leftValue;
	cpynode.rightValue = this.rightValue;
	if (this.leftCondition)
		cpynode.leftCondition = this.leftCondition.copy();
	if (this.rightCondition)
		cpynode.rightCondition = this.rightCondition.copy();
	cpynode.lval = this.lval;
	cpynode.rval = this.rval;
	cpynode.conditionExpression = this.conditionExpression;
}


function FunctionNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new FunctionShape(x, y, name, type);
	this.nodeName = "Function";
	this.variables = Functions.getFunction(name);
	this.functionName = name;
	this.uiRenderTags = ['functionName', 'variables'];
}

FunctionNode.prototype = Object.create(Node.prototype);

FunctionNode.prototype.addEvents = function () {
	let n = this;
	for (const i in this.variables) {
		if (typeof (this.variables[i]['value']) == 'string'){
			let obj = new Object();
			obj.shape = this.shape['varEllipse_' + i];
			obj.shapeName = 'varEllipse_' + i;
			obj.shapeText = this.shape['varEllipse_' + i + '_text'];
			obj.prop = this.variables[i];
			obj.property = this.variables[i];
			obj.shapeVariable = 'varEllipse_' + i + '_text';
			obj.blur = true;
			this.addEditVariableHandler(obj);
		}
		else{
			this.variables[i]['value'].addClickHandler();
		}
	}
	let functionDelete = function (event) {
		event.e.preventDefault();
		n.delete();
	};

	let components = ['ellipse', 'titleText', 'opBrak', 'clBrak'];
	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedblclick", functionDelete);
	}

	let functionDeleteEnable = function (event) {
		event.e.preventDefault();
		n.shape.objs[0].set({ stroke: '#FFF021', strokeWidth: 2 });
		let obj = new Object();
		obj.node = n;
		obj.type = 'node';
		window.application.selectedObj = obj;
		window.application.canvas.renderAll();
	};

	for (let i of components) {
		if (this.shape[i])
			this.shape[i].on("mousedown", functionDeleteEnable);
	}
}

FunctionNode.prototype.copyParams = function (cpynode) {
	cpynode.variables = window.utils.cloneObjs('Functions', this.variables);
	for (const i in cpynode.variables){
		cpynode.variables[i] = Object.assign(new Property(), cpynode.variables[i]);
	}
	cpynode.functionName = this.functionName;
}

FunctionNode.prototype.delete = function () {
	if (this.parentValue) {
		if (this.prop){
			let prev_val = [this.prop['value']];
			this.prop.setVariable("");
			window.application.addCommand(new ChangeCommand(this.prop, ['value'], prev_val, [this.prop['value']]));
		}
		else if (this.parentKey && this.parentposition) {
			let preVal = this.parent[this.parentValue][this.parentposition][this.parentKey];
			this.parent[this.parentValue][this.parentposition][this.parentKey] = '';
			window.application.addCommand(new ChangeCommand(this.parent[this.parentValue], [this.parentKey], [preVal], [''], this.parentposition));
		}
		else if (this.parentKey) {
			let preVal = this.parent[this.parentValue][this.parentKey];
			this.parent[this.parentValue][this.parentKey] = '';
			window.application.addCommand(new ChangeCommand(this.parent[this.parentValue], [this.parentKey], [preVal], ['']));
		}
		else {
			let preVal = this.parent[this.parentValue];
			this.parent[this.parentValue] = '';
			window.application.addCommand(new ChangeCommand(this.parent, [this.parentValue], [preVal], ['']));
		}
		window.application.refresh();
	}
}

FunctionNode.prototype.constructParams = function () {
	let expr = this.functionName.toUpperCase();
	let b = false;
	if (expr == "CURRENTDATE") {
		return "CURRENTDATE('')";
	}
	if (expr == "TABLETOSTRING" || expr == "TABLETOJSON" || expr == "LOOKUP" || expr == "COUNT" || expr == "CLEARTABLE") {
		b = true;
	}
	expr += "(";
	for (const variable of this.variables) {
		if (variable['value']) {
			if ((b && variable.name == 'table') || (expr.startsWith("LOOKUP") && variable.name !== 'value'))
				expr += "'" + window.utils.variableSlice(variable['value']) + "'";
			else
				expr += window.utils.variableSlice(variable['value']);
		}
		else {
			expr += '';
		}
		expr += ",";
	}
	if (expr.endsWith(','))
		expr = expr.substring(0, expr.length - 1);
	expr += ")";
	return expr;

}


function IfElseNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new IfElseShape(x, y, name, type);
	this.nodeName = "condition";
	this.ifChilds = new Array();
	this.elseChilds = new Array();
	this.cond = null;
	this.childType = null;
	this.uiRenderTags = ["cond", "childType"];
	this.haveChild = true;
}

IfElseNode.prototype = Object.create(Node.prototype);
IfElseNode.prototype.addEvents = function () {
	let n = this;
	let obj = new Object();

	if (!this.cond) {
		obj = new Object();
		obj.styleIn = { fill: '#E2D0FF' };
		obj.styleOut = { fill: '#FFFFFF' };
		obj.shape = this.shape.hexagon;
		obj.origin = 'rightMenu';
		obj.property = 'cond';
		this.addConditionHandler(obj, n);
	} else
		this.cond.addEvents();

	// obj.styleIn = { opacity: 0.5 };
	// obj.styleOut = { opacity: 1 };
	// obj.shape = this.shape.if;
	// obj.origin = 'leftMenu';

	// this.addPropertyHandler(obj, function (event) {
	// 	let data = event.e.dataTransfer;
	// 	if (data.getData("origin") === 'leftMenu') {
	// 		let title = data.getData("caption");
	// 		let type = data.getData("type");
	// 		const x = event.e.layerX;
	// 		const y = event.e.layerY;
	// 		window.application.targetNode = n;
	// 		window.application.selectedNode.childType = "if";
	// 		window.application.createNode(title, x, y, type);
	// 		window.application.refresh();
	// 	}
	// });

	// obj = new Object();
	// obj.styleIn = { opacity: 0.5 };
	// obj.styleOut = { opacity: 1 };
	// obj.origin = 'leftMenu';
	// obj.shape = this.shape.else;

	// this.addPropertyHandler(obj, function (event) {
	// 	let data = event.e.dataTransfer;
	// 	if (data.getData("origin") === 'leftMenu') {
	// 		let title = data.getData("caption");
	// 		let type = data.getData("type");
	// 		const x = event.e.layerX;
	// 		const y = event.e.layerY;
	// 		window.application.targetNode = n;
	// 		window.application.selectedNode.childType = "else";
	// 		window.application.createNode(title, x, y, type);
	// 		window.application.refresh();
	// 	}
	// });
};
IfElseNode.prototype.addChild = function (node, index) {

	if (node.l && node.l.indexOf(this.lane) == -1) {
		window.application.handleError("Error", "E001");
		return false;
	} else {
		node.ifChildType = this.childType;
		if (this.childType == 'else')
			this.elseChilds.splice(index, 0, node);
		else
			this.ifChilds.splice(index, 0, node);
		return true;
	}
};
IfElseNode.prototype.deleteChild = function (node) {
	let index = this.ifChilds.indexOf(node);
	if (index > -1) {
		this.ifChilds.splice(index, 1);
	}
	index = this.elseChilds.indexOf(node);
	if (index > -1) {
		this.elseChilds.splice(index, 1);
	}
};
IfElseNode.prototype.getChilds = function(){
	return this.childType=='else'?this.elseChilds:this.ifChilds;
}

IfElseNode.prototype.constructParams = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.constructParams();
	}
	if (exp)
		this.parameter.Input["IfElse$Input.$Expression"] = window.utils.constructObject("IfElse$Input.$Expression", exp);
};

IfElseNode.prototype.getMinimizeText = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.getMinimizeText();
	}
	return "If " + (exp ? ("( " + exp + " )") : '') + " else ?";
}

IfElseNode.prototype.copyParams = function (cpynode) {
	cpynode.childType = this.childType;
	if (this.cond)
		cpynode.cond = this.cond.copy();
	cpynode.ifChilds = [];
	for (let ifCh of this.ifChilds)
		cpynode.ifChilds.push(ifCh.copy());
	cpynode.elseChilds = [];
	for (let elCh of this.elseChilds)
		cpynode.elseChilds.push(elCh.copy());
}


// function IfBlockNode(name, x, y, type) {
// 	Node.call(this, name, x, y, type);
// 	this.shape=new IfShape(x,y,name,type);
// 	this.cond = null;
// 	this.nodeName = "loop";

// }

// IfBlockNode.prototype = Object.create(Node.prototype);

function LoopNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new PolyShape(x, y, name, type);
	this.cond = null;
	this.condValue = '';
	this.nodeName = "loopuntil";
	this.uiRenderTags = ["cond", "condValue"];
	this.haveChild = true;
}

LoopNode.prototype = Object.create(Node.prototype);
LoopNode.prototype.addEvents = function () {
	let n = this;
	if (!this.cond) {
		var obj = new Object();
		obj.styleIn = { fill: '#E2D0FF' };
		obj.styleOut = { fill: '#FFFFFF' };
		obj.shape = this.shape.hexagon;
		obj.origin = 'rightMenu';
		obj.property = 'cond';
		obj.propertyValue = 'condValue';
		this.addConditionHandler(obj, n);
	} else
		this.cond.addEvents();
};
LoopNode.prototype.constructParams = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.constructParams();
	}
	if (exp)
		this.parameter.Input["LoopUntil$Input.$Expression"] = window.utils.constructObject("LoopUntil$Input.$Expression", exp);
};
LoopNode.prototype.getMinimizeText = function () {
	let exp = null;
	if (this.cond) {
		exp = this.cond.getMinimizeText();
	}
	return this.name + " " + (exp ? ("( " + exp + " )") : '');
}

LoopNode.prototype.copyParams = function (cpynode) {
	cpynode.condValue = this.condValue;
	if (this.cond)
		cpynode.cond = this.cond.copy();
}


function RepeaterNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new EllipseShape(x, y, name, type);
	this.nodeName = "repeater";
	this.conditionValue = '';
	this.uiRenderTags = ["conditionValue"];
	this.haveChild = true;
}

RepeaterNode.prototype = Object.create(Node.prototype);
RepeaterNode.prototype.addEvents = function () {
	let n = this;

	let obj = new Object();
	obj.shape = this.shape.conditionValue;
	// obj.shapeName = 'conditionValue';
	obj.propertyValue = 'conditionValue';
	obj.shapeVariable = 'conditionValue_text';
	obj.dataType = 'Table';
	this.addVariableGroupHandler(obj);

	// let obj = new Object();
	// obj.styleIn = { fill: '#E2D0FF' };
	// obj.styleOut = { fill: '#FFFFFF' };
	// obj.shape = this.shape.ellipse;
	// obj.origin = 'rightMenu';
	// obj.dataType = 'Table';
	// obj.propertyValue = 'conditionValue';
	// this.addVariableHandler(obj,this);

	// if(this.shape.conditionValue){
	// 	obj = new Object();
	// 	obj.styleIn = { fill: '#E2D0FF' };
	// 	obj.styleOut = { fill: '#FFFFFF' };
	// 	obj.shape = this.shape.conditionValue;
	// 	obj.origin = 'rightMenu';
	// 	obj.dataType = 'Table';
	// 	obj.propertyValue = 'conditionValue';
	// 	this.addVariableHandler(obj,this);
	// }
};
RepeaterNode.prototype.constructParams = function () {
	//this.parameter.Input["Table"]=window.utils.constructObject("Repeater$Input.$Table",this.conditionValue);
	this.parameter.Input["Repeater$Input.$Table"] = window.utils.constructObject("Repeater$Input.$Table", window.utils.variableSlice(this.conditionValue));
};

RepeaterNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.conditionValue);
}

RepeaterNode.prototype.copyParams = function (cpynode) {
	cpynode.conditionValue = this.conditionValue;
}

function TerminateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "terminate";
	this.shape = new TerminateShape(x, y, name, type, this);
	this.message = "";
	this.uiRenderTags = ["message"];
}

TerminateNode.prototype = Object.create(Node.prototype);
// TerminateNode.prototype.showProperties = function () {
// 	this.clearandfillProperties();
// 	this
// 		.constructLeftTree(
// 			"Terminate",
// 			"<Terminate><Input struct=\"table\"></Input><Output struct=\"table\"></Output></Terminate>");
// };
TerminateNode.prototype.addEvents = function () {
	let node = this;

	let obj = new Object();
	obj.shape = this.shape.message;
	obj.shapeText = this.shape.message_text;
	obj.shapeName = 'message';
	obj.propertyValue = 'message';
	obj.shapeVariable = 'message_text';
	this.addEditVariableHandler(obj);

}
TerminateNode.prototype.constructParams = function () {
	this.parameter.Input["$message"] = window.utils.constructObject("$message", window.utils.variableSlice(this.message));
}

TerminateNode.prototype.copyParams = function (cpynode) {
	cpynode.message = this.message;
}


function BreakNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new MiniShape(x, y, name, type, this);
	this.nodeName = "Break";
}

BreakNode.prototype = Object.create(Node.prototype);
// BreakNode.prototype.showProperties = function () {
// 	this.clearandfillProperties();
// 	this.constructLeftTree(
// 		"Break",
// 		"<Break><Input struct=\"table\"></Input><Output struct=\"table\"></Output></Break>");
// };

function RowNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "row";
}

RowNode.prototype = Object.create(Node.prototype);
RowNode.prototype.showProperties = function () {
	if (this.selectedNodeParamSize() > 0) {
		this.clearandfillProperties();
	} else {
		var dialog = new DialogBox();
		dialog.setText("Row Configuration");
		/*var rowWindow = new RowWindow(this);
		dialog.setBody(rowWindow);
		dialog.show();
		*/
	}
};

function ReferenceNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "reference";
	this.l = "FM Lane";
}

ReferenceNode.prototype = Object.create(Node.prototype);
ReferenceNode.prototype.showProperties = function () {
	if (!this.reference) {
		var dialog = new DialogBox();
		dialog.setText("Reference");
		/*var refWind = new RefWindow(this);
		dialog.setBody(refWind);
		dialog.show();
		*/
	} else {
		this.clearandfillProperties();
		//prop.setLeftTree(util.createDoc(this.reference.toXML()), this.name);
	}
};
ReferenceNode.prototype.setReference = function (node) {
	this.reference = node;
};
ReferenceNode.prototype.addEvent = function () {
	var t = this;
	this.shape.group.on("mouseup", function () {
		window.application.hideLink(t);
		t.showProperties();
	});
	this.shape.group.on("mousedown", function () {
		window.application.showLink(t);
	});
};
ReferenceNode.prototype.getLink = function () {
	var x = this.shape.x + this.shape.width / 2;
	var y = this.shape.y + this.shape.height;
	this.line1 = new Shape(x, y, "");
	this.line1.x1 = this.reference.shape.x + this.reference.shape.width / 2;
	this.line1.y1 = this.reference.shape.y - 10;
	this.line1.width = this.line1.x1 - x;
	this.line1.height = this.line1.y1 - y;
	return this.line1;
};
ReferenceNode.prototype.toObject = function () {
	var obj = new Object();
	var parameter = this.parameter.toObject(false);
	obj.input = parameter.Input;
	obj.output = parameter.Output;
	obj.metadata = parameter.metadata;
	obj.name = this.name;
	obj.type = this.type;
	obj.reference = this.reference.nodeName;
	obj.shape = this.shape.toObject();
	if (this.line)
		obj.line = this.line.toObject();
	obj.child = new Array();
	for (var i = 0; i < this.childs.length; i++) {
		var o = new Object();
		o[this.childs[i].nodeName] = this.childs[i].toObject();
		obj.child.push(o);
	}
	obj.isCommented = this.isCommented;
	return obj;
};


function LookupNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "lookup";
}
LookupNode.prototype = Object.create(Node.prototype);
LookupNode.prototype.showProperties = function () {
	if (this.selectedNodeParamSize() > 0) {
		this.clearandfillProperties();
	} else {
		var xmldoc = "<"
			+ this.nodeName
			+ ">"
			+ "<Input><Table></Table><inputColumn></inputColumn><value></value><outputColumn></outputColumn></Input><Output><value></value></Output>"
		xmldoc = xmldoc + "</" + this.nodeName + ">";
		this.constructLeftTree(this.nodeName, xmldoc);
	}
};

function GenerateFileNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new DriveShape(x, y, name, type);
	this.nodeName = "genFile";
	this.nodeLabel = "Generate";
	this.sourceTitle = 'File';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'with extension';
	this.destination = '';
	this.urlTitle = '/ url';
	this.url = '';
	this.outputTitle = 'to';
	this.output = '';
	this.mandatoryTitle = 'using encoding';
	this.mandatory = 'Base64';
	this.mandatoryList = ['Hex', 'Base64'];
	// this.shape.calculateHeight(this);
	this.uiRenderTags = ["source", "destination", "url", "output", "mandatory"];
}
GenerateFileNode.prototype = Object.create(Node.prototype);

GenerateFileNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

GenerateFileNode.prototype.constructParams = function () {
	this.parameter.Input = {};
	this.parameter.Output = {};
	this.parameter.Input["generate_file$Input.$filestream"] = window.utils.constructObject("generate_file$Input.$filestream", window.utils.variableSlice(this.source));
	this.parameter.Input["generate_file$Input.$filename"] = window.utils.constructObject("generate_file$Input.$filename", window.utils.variableSlice(this.destination));
	this.parameter.Input["$isHex"] = window.utils.constructObj("$isHex", this.mandatory == 'Hex' ? 'true' : 'false');
	this.parameter.Output[this.output] = window.utils.constructObject(window.utils.variableSlice(this.output), 'generate_file$Output.$path');
	this.parameter.Output[this.url] = window.utils.constructObject(window.utils.variableSlice(this.url), 'generate_file$Output.$url');
}
GenerateFileNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.destination) + " with " + window.utils.getVariableName(this.source);
}
GenerateFileNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
	cpynode.mandatory = this.mandatory;
	cpynode.output = this.output;
	cpynode.url = this.url;
}

function DownloadFile(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new DriveShape(x, y, name, type);
	this.nodeName = "downloadFile";
	this.nodeLabel = "Download";
	this.sourceTitle = 'File';
	this.source = '';
	this.l = ["rc"];
	this.outputTitle = 'to';
	this.output = '';
	this.mandatoryTitle = 'using encoding';
	this.mandatory = 'Base64';
	this.mandatoryList = ['Hex', 'Base64'];
	// this.shape.calculateHeight(this);
	this.uiRenderTags = ["source", "output", "mandatory"];
}
DownloadFile.prototype = Object.create(Node.prototype);

DownloadFile.prototype.addEvents = function () {
	this.addDriveHandler();
};

DownloadFile.prototype.constructParams = function () {
	this.parameter.Input = {};
	this.parameter.Output = {};
	this.parameter.Input["file_download$Input.$File"] = window.utils.constructObject("file_download$Input.$File", window.utils.variableSlice(this.source));
	this.parameter.Input["file_download$Input.$encoding"] = window.utils.constructObj("file_download$Input.$encoding", this.mandatory == 'Hex' ? 'hexadecimal' : 'base64');
	this.parameter.Output[this.output] = window.utils.constructObject(window.utils.variableSlice(this.output), 'file_download$Output.$Stream');
};
DownloadFile.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

DownloadFile.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.mandatory = this.mandatory;
	cpynode.output = this.output;
}

function GenerateQRCode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.shape = new DriveShape(x, y, name, type);
	this.nodeName = "qrcode";
	this.nodeLabel = "qrcode";
	this.sourceTitle = 'Data';
	this.source = '';
	this.l = ["rc"];
	this.urlTitle = 'to';
	this.url = '';
	// this.shape.calculateHeight(this);
	this.uiRenderTags = ["source", "url"];
}
GenerateQRCode.prototype = Object.create(Node.prototype);

GenerateQRCode.prototype.addEvents = function () {
	this.addDriveHandler();
};

GenerateQRCode.prototype.constructParams = function () {
	this.parameter.Input = {};
	this.parameter.Output = {};
	this.parameter.Input["qrcode$Input.$data"] = window.utils.constructObject("qrcode$Input.$data", window.utils.variableSlice(this.source));
	this.parameter.Output[this.url] = window.utils.constructObject(window.utils.variableSlice(this.url), 'qrcode$Output.$path');
};
GenerateQRCode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

GenerateQRCode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.url = this.url;
}

function FTPDownloadNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "ftpDownload";
	this.l = new Array();
	this.l.push("rc");
}
FTPDownloadNode.prototype = Object.create(Node.prototype);
FTPDownloadNode.prototype.showProperties = function () {
	if (this.selectedNodeParamSize() > 0) {
		this.clearandfillProperties();
	} else {
		this.editProperties();
	}
};
FTPDownloadNode.prototype.editProperties = function () {
	var dialog = new DialogBox();
	dialog.setText("FTP Configuration");
	/*var dbWindow = new FTPDownloadWindow(this);
	dialog.setBody(dbWindow);
	dialog.show();*/
};
FTPDownloadNode.prototype.updateSystem = function (value, nwvalue) {
	//this.parameter.update("System", value, nwvalue);
};




//Bluetooth
//List of Devices.
function BluetoothListNode(name, x, y, type) {
	Node.call(this, name, x, y - 3, type);
	this.nodeName = "btlist";
	this.l = new Array();
	this.l.push("rd");
	this.callback = new BossNode("Service", x + 85, y + 12, "boss");
	this.loaded = false;
}

BluetoothListNode.prototype = Object.create(Node.prototype);
BluetoothListNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input></Input><Output><devices><Id></Id><Name></Name><Address></Address><Paired></Paired></devices></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
//Pairing
function BluetoothPairingNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "btpair";
	this.l = new Array();
	this.l.push("FA Lane");
	this.loaded = false;
}

BluetoothPairingNode.prototype = Object.create(Node.prototype);
BluetoothPairingNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address></Input><Output></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BluetoothListenerNode(name, x, y, type) {
	Node.call(this, name, x, y - 3, type);
	this.nodeName = "btlistener";
	this.l = new Array();
	this.l.push("FA Lane");
	this.callback = new BossNode("Service", x + 85, y + 12, "boss");
	this.loaded = false;
}

BluetoothListenerNode.prototype = Object.create(Node.prototype);
BluetoothListenerNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address><delimiter></delimiter><dataType></dataType></Input><Output><response></response></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BarcodeScannerNode(name, x, y, type) {
	Node.call(this, name, x, y - 3, type);
	this.nodeName = "sbcs";
	this.l = new Array();
	this.l.push("FA Lane");
	this.callback = new BossNode("Service", x + 85, y + 12, "boss");
	this.loaded = false;
}

BarcodeScannerNode.prototype = Object.create(Node.prototype);
BarcodeScannerNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input></Input><Output><data></data></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BluetoothConnectNode(name, x, y, type) {
	Node.call(this, name, x, y - 3, type);
	this.nodeName = "btconnect";
	this.l = new Array();
	this.l.push("FA Lane");
	this.loaded = false;
}

BluetoothConnectNode.prototype = Object.create(Node.prototype);
BluetoothConnectNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address></Input><Output></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BluetoothDisconnectNode(name, x, y, type) {
	Node.call(this, name, x, y - 3, type);
	this.nodeName = "btdisconnect";
	this.l = new Array();
	this.l.push("FA Lane");
	this.loaded = false;
}

BluetoothDisconnectNode.prototype = Object.create(Node.prototype);
BluetoothDisconnectNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address></Input><Output></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BluetoothUnpairingNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "btunpair";
	this.l = new Array();
	this.l.push("FA Lane");
	this.loaded = false;
}

BluetoothUnpairingNode.prototype = Object.create(Node.prototype);
BluetoothUnpairingNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address></Input><Output></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};
function BluetoothCommandNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "btcom";
	this.l = new Array();
	this.l.push("FA Lane");
	this.loaded = false;
}

BluetoothCommandNode.prototype = Object.create(Node.prototype);
BluetoothCommandNode.prototype.showProperties = function () {
	if (this.parameter.metadata != '') {
		this.clearandfillProperties();
	} else {
		this
			.constructLeftTree(
				this.name,
				"<" + this.name + "><Input><Address></Address><command></command><dataType></dataType></Input><Output><response></response></Output></" + this.name + ">");
		this.loaded = true;
	}
	//this.clearandfillProperties();

};

function SapFunctionNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sapFunction";
	this.nodeLabel = "function";
	this.shape = new SapFunctionShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.inputColumns = [];
	this.outputColumns = [];
	this.moduleTitle = 'BAPI/RFC';
	this.module = '';
	this.moduleDefault = 'select';
	this.moduleList = [];

	this.includeTitle = 'Include Non-Remote FM';
	this.include = 'yes';
	this.includeTypes = ['yes', 'no'];

	// this.attachmentTitle = 'Is Attachment';
	// this.attachment = 'yes';
	// this.attachmentTypes = ['yes', 'no'];
	this.isRfcEnabled = "";

	this.l = ["rpr"];
	// this.config = {};
	// this.config['module'] = new Config();
	// this.config.module.data = ['modulelist10', 'modulelist11'];

	// this.metadata.input['data'] = ['input1', 'input2', 'input3'];
	// this.metadata.output['data'] = ['output1', 'output2', 'output3'];
	// this.initilizeInputOutput();
	this._system = window.application.getPluginOrSystem('system', this);
	this.uiRenderTags = ["_system", "config", "input", "output", "module", "include", "attachment", "isRfcEnabled", "inputColumns", "outputColumns"];
	this.buildedName = 'sapfunction';
}


SapFunctionNode.prototype = Object.create(Node.prototype);

SapFunctionNode.prototype.addEvents = function () {
	this.addSapFunctionHandler();
}

SapFunctionNode.prototype.constructParams = function () {
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Output = {};
	if (this.isRfcEnabled != '')
		this.parameter.Input["isRfcEnabled"] = window.utils.constructObj("$isRfcEnabled", this.isRfcEnabled);
	//this.parameter.Input["isAttachment"] = window.utils.constructObject("$isAttachment", this.attachment=="yes"?"true":"false");
	this.parameter.Input["isAttachment"] = window.utils.constructObject("$isAttachment", "false");
	this.parameter.Input["RfcDestination"] = window.utils.constructObj("$RfcDestination", this._system ? this._system.system : "");
	if (this.module && this.module != "")
		this.parameter.Input["Function"] = window.utils.constructObj("$Function", this.module);

	if (this.input && Array.isArray(this.input) && this.input.length > 0) {
		for (let i in this.input) {
			let input = this.input[i];
			if (input.value && input.name) {
				if(!this['input_mapping' + i]){
					this.parameter.Input[input.name] = window.utils.constructObject(input.name, input.value);
				}else{
					if (input.type == "table"){
						this.parameter.Input[input.name] = window.utils.constructObject(input.name, input.value);
						this.parameter.Input[input.name]['field'] = [];
						if(this['input_mapping' + i]["toTable"]){
							let im = this['input_mapping' + i]["toTable"];
							//console.log(im); 
							if (im && Array.isArray(im) && im.length > 0) {
								for (let j in im) {
									let imi = im[j];
									if(imi.name!="No match available"){
										//console.log("name=",imi.name," xpath=",imi.xpath);
										this.parameter.Input[input.name]['field'].push(window.utils.constructObject(input.name + "$"+imi.name, imi.xpath));
									}
									
								}
							}
						}
						
						
					}
				}
				
			}
		}
	}
	if (this.output && Array.isArray(this.output) && this.output.length > 0) {
		for (let i in this.output) {
			let output = this.output[i];
			if (output.value && output.name && !this['output_mapping' + i]) {
				this.parameter.Output[output.value] = window.utils.constructObject(output.value, output.name);
			} else {
				this.parameter.Output[output.value] = window.utils.constructObjectWithoutParse(output.value, output.name);
			}
		}
	}
}

SapFunctionNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.module;
}

SapFunctionNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.inputColumns = window.utils.cloneObjs('', this.inputColumns);
	cpynode.outputColumns = window.utils.cloneObjs('', this.outputColumns);
	cpynode.module = this.module;
	cpynode.moduleList = window.utils.cloneObjs('', this.moduleList);
	cpynode.include = this.include;
	cpynode.isRfcEnabled = this.isRfcEnabled;

	for (let i in this.input) {
		cpynode['input_mapping' + i] = window.utils.cloneObjs('', this['input_mapping' + i]);
		cpynode['input_mapped' + i] = false;
	}
	for (let i in this.output) {
		cpynode['output_mapping' + i] = window.utils.cloneObjs('', this['output_mapping' + i]);
		cpynode['output_mapped' + i] = false;
	}
}

function System(name, type, system, username, password) {
	this.name = name;
	this.type = type;
	this.system = system;
	this.username = username;
	this.password = password;
}

System.prototype.getUserNameDisplayText = function () {
	return this.username.substr(0, 10) + (this.username.length > 10 ? '...' : '')
};
System.prototype.setValue = function (key, val) {
	this[key] = val;
};
System.prototype.getValue = function (key) {
	return this[key];
};


function Config(name, type, value) {
	this.name = name;
	this.type = type;
	this.value = value;
	this.data = [];
}
Config.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

function FunctionInput() {
	this.rhsValue = "";
	this.islhsVariable = false;
	this.lhsVariableType = '';
	this.lhsVariableValue = '';
	this.lhsVariableScope = '';
}

FunctionInput.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

function FunctionOutput() {
	this.lhsValue = "";
	this.isrhsVariable = false;
	this.rhsVariableType = '';
	this.rhsVariableValue = '';
	this.rhsVariableScope = '';
}

FunctionOutput.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

function FreedomDBSelectNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "fdbList";
	this.nodeLabel = "select";
	this.l = ['rc', 'rd'];
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.sort = [new FunctionSort()];
	this.sortTitle = 'sort';
	this.sortNameDefault = 'select';
	this.sortTypeDefault = 'select';
	this.sortOrderList = ['asc', 'desc'];
	this.to = '';
	this.toTitle = 'to';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.selectColumnsLists = [];
	this.uiRenderTags = ["table", "tableObj", "selectColumnsLists", "where", "sort", "to", 'mapped', 'mapping'];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_select';
}
FreedomDBSelectNode.prototype = Object.create(Node.prototype);

FreedomDBSelectNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
FreedomDBSelectNode.prototype.getValue = function (key) {
	return this[key];
};

FreedomDBSelectNode.prototype.getColumns = function () {
	if (this.selectColumnsLists.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.selectColumnsLists.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.selectColumnsLists[i];
		}
		return _val;
	}
};
FreedomDBSelectNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
FreedomDBSelectNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	//this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table)
	let query = "'select " + this.getColumns() + " from " + this.table + " '";
	let where = "";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			where += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			where += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	where += (where != "") ? "+'\"'" : "";
	if (where != "") {
		query += "+' where '" + where;
	}
	let sort = "";
	for (let idx in this.sort) {
		if (this.sort[idx].sortColumn && this.sort[idx].sortColumn != "") {
			sort += (sort == "" ? "" : " , ") + this.sort[idx].sortColumn + "  " + this.sort[idx].sortOrder;
		}
	}
	if (sort != "")
		query += "+ 'order by " + sort + "' ";

		this.parameter.Input["sql_select$Input.$Query"] = window.utils.constructObject("sql_select$Input.$Query", query);
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_select$Output.$Table");
	//this.parameter[this.to.includes("$Local.$")?"Local":"Output"][this.to] = window.utils.constructObject(this.to, "sql_select$Output.$Table");
}

FreedomDBSelectNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

FreedomDBSelectNode.prototype.copyParams = function (cpynode) {
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	cpynode.sort = window.utils.cloneObjs('FunctionSort', this.sort);
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.to = this.to;
	if (this.selectColumnsLists)
		cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
	cpynode.mapped = false;

}

function FreedomDBCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.l = ['rc', 'rd'];
	this.nodeName = "fdbCreate";
	this.nodeLabel = "create record in";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.to = '';
	this.toTitle = 'to';
	this.uiRenderTags = ["table", "tableObj", "column", 'to'];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_insert';
}
FreedomDBCreateNode.prototype = Object.create(Node.prototype);

FreedomDBCreateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
FreedomDBCreateNode.prototype.getValue = function (key) {
	return this[key];
};

FreedomDBCreateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


FreedomDBCreateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
FreedomDBCreateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column && this.column[col].value)
			this.parameter.Input["sql_insert$Input.$" + this.column[col].column] = window.utils.constructObject("sql_insert$Input.$" + this.column[col].column, window.utils.variableSlice(value));
	}
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_insert$Output.$key");
	}
}
FreedomDBCreateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

FreedomDBCreateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);

}

function FreedomDBUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.l = ['rc', 'rd'];
	this.nodeName = "fdbUpdate";
	this.nodeLabel = "update record in";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'set';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>']
	this.uiRenderTags = ["table", "tableObj", "column", "where"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_update';
}
FreedomDBUpdateNode.prototype = Object.create(Node.prototype);

FreedomDBUpdateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
FreedomDBUpdateNode.prototype.getValue = function (key) {
	return this[key];
};

FreedomDBUpdateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

FreedomDBUpdateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

FreedomDBUpdateNode.prototype.constructParams = function () {
	let query = "'update " + this.table + " set '";
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column) {
			query += "+' " + this.column[col].column + " = \"'+" + window.utils.variableSlice(value);
			query += ((col != this.column.length - 1) ? "+'\" , '" : "");
		}

	}
	query += "+'\" where '"
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input['sql_update$Input.$Query'] = window.utils.constructObject("sql_update$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_update$Output.$message");
}

FreedomDBUpdateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

FreedomDBUpdateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}

function FreedomDBDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.l = ['rc', 'rd'];
	this.nodeName = "fdbDelete";
	this.nodeLabel = "delete from";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.uiRenderTags = ["table", "tableObj", "where"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_delete';
}
FreedomDBDeleteNode.prototype = Object.create(Node.prototype);

FreedomDBDeleteNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
FreedomDBDeleteNode.prototype.getValue = function (key) {
	return this[key];
};

FreedomDBDeleteNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

FreedomDBDeleteNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

FreedomDBDeleteNode.prototype.constructParams = function () {
	let query = "'delete from " + this.table + " where ' ";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this.parameter.Input['sql_delete$Input.$Query'] = window.utils.constructObject("sql_delete$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_delete$Output.$message");
}

FreedomDBDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

FreedomDBDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}

function DBAdapterSelectNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "dbList";
	this.nodeLabel = "DB select";
	this.l = ['rpr'];
	this.shape = new FreedomDBShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.sort = [new FunctionSort()];
	this.sortTitle = 'sort';
	this.sortNameDefault = 'select';
	this.sortTypeDefault = 'select';
	this.sortOrderList = ['asc', 'desc'];
	this.to = '';
	this.toTitle = 'to';
	this.uiRenderTags = ["system", "table", "columns", "where", "to"];
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.selectColumnsLists = [];
	this.uiRenderTags = ["_system", "table", "tableObj", "selectColumnsLists", "where", "sort", "to"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_select';
}
DBAdapterSelectNode.prototype = Object.create(Node.prototype);

DBAdapterSelectNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
DBAdapterSelectNode.prototype.getValue = function (key) {
	return this[key];
};

DBAdapterSelectNode.prototype.getColumns = function () {
	if (this.selectColumnsLists.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.selectColumnsLists.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.selectColumnsLists[i];
		}
		return _val;
	}
};
DBAdapterSelectNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
	// this.shape.columns.on('mousedown', function (e) {
	// 	let temp = window.application.createDropdownMenu(new FDBSelectColumnEvent());
	// 	temp.load(node, 0);
	// });
};

DBAdapterSelectNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);

	let query = "'select " + this.getColumns() + " from " + this.table + " '";
	let where = "";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			where += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			where += ((idx != this.where.length - 1) ? "+'\" and '" : "")
		}
	}
	where += (where != "") ? "+'\"'" : "";
	if (where != "") {
		query += "+' where '" + where;
	}
	let sort = "";
	for (let idx in this.sort) {
		if (this.sort[idx].sortColumn && this.sort[idx].sortColumn != "") {
			sort += (sort == "" ? "" : " , ") + this.sort[idx].sortColumn + "  " + this.sort[idx].sortOrder;
		}
	}
	if (sort != "")
		query += "+ 'order by " + sort + "' ";

	this.parameter.Input["sql_select$Input.$Query"] = window.utils.constructObject("sql_select$Input.$Query", query);

	//this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_select$Output.$Table");
		for (let x in this.selectColumnsLists) {
			let paramFields = window.application.ui.parameter.Output[this.to.split("$")[2]].field;
			for (let y in paramFields) {
				let _name = paramFields[y].name.split("$")[3] ? paramFields[y].name.split("$")[3] : paramFields[y].name;
				if (_name == this.selectColumnsLists[x]) {
					paramFields[y]["value"] = "sql_select$Output.$Table$" + _name;
				}
			}
		}
	}
}

DBAdapterSelectNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

DBAdapterSelectNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	cpynode.to = this.to;
	cpynode.sort = window.utils.cloneObjs('FunctionSort', this.sort);
	if (this.selectColumnsLists)
		cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
	cpynode.mapped = false;
}

function DBAdapterCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = "create record in";
	this.nodeName = "dbCreate";
	this.shape = new FreedomDBShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.l = ['rpr'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.to = '';
	this.toTitle = 'to';
	this.uiRenderTags = ["_system", "table", "tableObj", "column", 'to'];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_insert';
}
DBAdapterCreateNode.prototype = Object.create(Node.prototype);

DBAdapterCreateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
DBAdapterCreateNode.prototype.getValue = function (key) {
	return this[key];
};

DBAdapterCreateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


DBAdapterCreateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
DBAdapterCreateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);
	this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column && this.column[col].value)
			this.parameter.Input["sql_insert$Input.$" + this.column[col].column] = window.utils.constructObject("sql_insert$Input.$" + this.column[col].column, window.utils.variableSlice(this.column[col].value));
	}
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_insert$Output.$key");
	}
}

DBAdapterCreateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

DBAdapterCreateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	if (this.column)
		cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
}

function DBAdapterUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "dbUpdate";
	this.nodeLabel = "update record in";
	this.shape = new FreedomDBShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.l = ['rpr'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'set';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.uiRenderTags = ["_system", "table", "tableObj", "column", "where"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_update';
}
DBAdapterUpdateNode.prototype = Object.create(Node.prototype);

DBAdapterUpdateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
DBAdapterUpdateNode.prototype.getValue = function (key) {
	return this[key];
};

DBAdapterUpdateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

DBAdapterUpdateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

DBAdapterUpdateNode.prototype.constructParams = function () {
	let query = "'update " + this.table + " set '";
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column) {
			query += "+' " + this.column[col].column + " = \"'+" + window.utils.variableSlice(value);
			query += ((col != this.column.length - 1) ? "+'\" , '" : "");
		}

	}
	query += "+'\" where '"
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);
	//this.parameter.Input["Database"] = window.utils.constructObj("$Database", "123_dev");
	this.parameter.Input['sql_update$Input.$Query'] = window.utils.constructObject("sql_update$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_update$Output.$message");
}

DBAdapterUpdateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

DBAdapterUpdateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}


function DBAdapterDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "dbDelete";
	this.nodeLabel = "delete from";
	this.shape = new FreedomDBShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.l = ['rpr'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>']
	this.uiRenderTags = ["_system", "table", "tableObj", "where"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'sql_delete';
}
DBAdapterDeleteNode.prototype = Object.create(Node.prototype);

DBAdapterDeleteNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
DBAdapterDeleteNode.prototype.getValue = function (key) {
	return this[key];
};

DBAdapterDeleteNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

DBAdapterDeleteNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

DBAdapterDeleteNode.prototype.constructParams = function () {
	let query = "'delete from " + this.table + " where ' ";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);
	//this.parameter.Input["Database"] = window.utils.constructObj("$Database", "123_dev");
	this.parameter.Input['sql_delete$Input.$Query'] = window.utils.constructObject("sql_delete$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_delete$Output.$message");
}

DBAdapterDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

DBAdapterDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}


function SNFSelectNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "snfList";
	this.nodeLabel = "Snowflake select";
	this.l = ['rc'];
	this.shape = new FreedomDBShape(x, y, name, type);
	// this._system = window.application.getPluginOrSystem('system', this);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SNF'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'table';
	this.tableObj = null;
	this.db = "";
	this.dbTitle = 'DB';
	this.dbDefault = 'DB';
	this.dbObj = null;
	this.schema = "";
	this.schemaTitle = 'schema';
	this.schemaDefault = 'schema';
	this.schemaObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.sort = [new FunctionSort()];
	this.sortTitle = 'sort';
	this.sortNameDefault = 'select';
	this.sortTypeDefault = 'select';
	this.sortOrderList = ['asc', 'desc'];
	this.to = '';
	this.toTitle = 'to';
	this.uiRenderTags = ["system", "table", "columns", "where", "to"];
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.selectColumnsLists = [];
	this.uiRenderTags = ["system", "table", "tableObj", "selectColumnsLists", "where", "sort", "to", "db", "dbObj", "schema", "schemaObj"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'snf_select';
}
SNFSelectNode.prototype = Object.create(Node.prototype);

SNFSelectNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SNFSelectNode.prototype.getValue = function (key) {
	return this[key];
};

SNFSelectNode.prototype.getColumns = function () {
	if (this.selectColumnsLists.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.selectColumnsLists.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.selectColumnsLists[i];
		}
		return _val;
	}
};
SNFSelectNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
	// this.shape.columns.on('mousedown', function (e) {
	// 	let temp = window.application.createDropdownMenu(new FDBSelectColumnEvent());
	// 	temp.load(node, 0);
	// });
};

SNFSelectNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);

	let query = "'select " + this.getColumns() + " from " + this.table + " '";
	let where = "";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			where += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			where += ((idx != this.where.length - 1) ? "+'\" and '" : "")
		}
	}
	where += (where != "") ? "+'\"'" : "";
	if (where != "") {
		query += "+' where '" + where;
	}
	let sort = "";
	for (let idx in this.sort) {
		if (this.sort[idx].sortColumn && this.sort[idx].sortColumn != "") {
			sort += (sort == "" ? "" : " , ") + this.sort[idx].sortColumn + "  " + this.sort[idx].sortOrder;
		}
	}
	if (sort != "")
		query += "+ 'order by " + sort + "' ";

	this.parameter.Input["Query"] = window.utils.constructObject("$Query", query)
	if (this.db)
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", this.db);
	else
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", "");
	if (this.schema)
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", this.schema);
	else
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", "");
	//this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "snf_select$Output.$Table")
		for (let x in this.selectColumnsLists) {
			let paramFields = window.application.ui.parameter.Output[this.to.split("$")[2]].field;
			for (let y in paramFields) {
				let _name = paramFields[y].name.split("$")[3] ? paramFields[y].name.split("$")[3] : paramFields[y].name;
				if (_name == this.selectColumnsLists[x]) {
					paramFields[y]["value"] = "snf_select$Output.$Table$" + _name;
				}
			}
		}
	}
}

SNFSelectNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SNFSelectNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.db = this.db;
	if (this.dbObj)
		cpynode.dbObj = window.utils.cloneObjs('', this.dbObj);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	cpynode.to = this.to;
	cpynode.sort = window.utils.cloneObjs('FunctionSort', this.sort);
	if (this.selectColumnsLists)
		cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
	cpynode.mapped = false;
}

function SNFCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = "create record in";
	this.nodeName = "snfCreate";
	this.shape = new FreedomDBShape(x, y, name, type);
	// this._system = window.application.getPluginOrSystem('system', this);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SNF'];
	this.l = ['rc'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'table';
	this.tableObj = null;
	this.db = "";
	this.dbTitle = 'DB';
	this.dbDefault = 'DB';
	this.dbObj = null;
	this.schema = "";
	this.schemaTitle = 'schema';
	this.schemaDefault = 'schema';
	this.schemaObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.to = '';
	this.toTitle = 'to';
	this.uiRenderTags = ["system", "table", "tableObj", "column", 'to', "db", "dbObj", "schema", "schemaObj"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'snf_insert';
}
SNFCreateNode.prototype = Object.create(Node.prototype);

SNFCreateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SNFCreateNode.prototype.getValue = function (key) {
	return this[key];
};

SNFCreateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


SNFCreateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
SNFCreateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column && this.column[col].value)
			this.parameter.Input["snf_insert$Input.$" + this.column[col].column] = window.utils.constructObj("snf_insert$Input.$" + this.column[col].column, window.utils.variableSlice(this.column[col].value));
	}
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "snf_insert$Output.$key");
	}
	if (this.db)
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", this.db);
	else
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", "");
	if (this.schema)
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", this.schema);
	else
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", "");
}

SNFCreateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SNFCreateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.db = this.db;
	if (this.dbObj)
		cpynode.dbObj = window.utils.cloneObjs('', this.dbObj);
	if (this.column)
		cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
}

function SNFUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "snfUpdate";
	this.nodeLabel = "update record in";
	this.shape = new FreedomDBShape(x, y, name, type);
	// this._system = window.application.getPluginOrSystem('system', this);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SNF'];
	this.l = ['rc'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'table';
	this.tableObj = null;
	this.db = "";
	this.dbTitle = 'DB';
	this.dbDefault = 'DB';
	this.dbObj = null;
	this.schema = "";
	this.schemaTitle = 'schema';
	this.schemaDefault = 'schema';
	this.schemaObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'set';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.uiRenderTags = ["system", "table", "tableObj", "column", "where", "db", "dbObj", "schema", "schemaObj"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'snf_update';
}
SNFUpdateNode.prototype = Object.create(Node.prototype);

SNFUpdateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SNFUpdateNode.prototype.getValue = function (key) {
	return this[key];
};

SNFUpdateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

SNFUpdateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

SNFUpdateNode.prototype.constructParams = function () {
	let query = "'update " + this.table + " set '";
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column) {
			query += "+' " + this.column[col].column + " = \"'+" + window.utils.variableSlice(value);
			query += ((col != this.column.length - 1) ? "+'\" , '" : "");
		}

	}
	query += "+'\" where '"
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.db)
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", this.db);
	else
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", "");
	if (this.schema)
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", this.schema);
	else
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", "");
	//this.parameter.Input["Database"] = window.utils.constructObj("$Database", "123_dev");
	this.parameter.Input['snf_update$Input.$Query'] = window.utils.constructObject("snf_update$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_update$Output.$message");
}

SNFUpdateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SNFUpdateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.db = this.db;
	if (this.dbObj)
		cpynode.dbObj = window.utils.cloneObjs('', this.dbObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}


function SNFDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "snfDelete";
	this.nodeLabel = "delete from";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SNF'];
	this.l = ['rc'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'table';
	this.tableObj = null;
	this.db = "";
	this.dbTitle = 'DB';
	this.dbDefault = 'DB';
	this.dbObj = null;
	this.schema = "";
	this.schemaTitle = 'schema';
	this.schemaDefault = 'schema';
	this.schemaObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>']
	this.uiRenderTags = ["system", "table", "tableObj", "where", "db", "dbObj", "schema", "schemaObj"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'snf_delete';
}
SNFDeleteNode.prototype = Object.create(Node.prototype);

SNFDeleteNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SNFDeleteNode.prototype.getValue = function (key) {
	return this[key];
};

SNFDeleteNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

SNFDeleteNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

SNFDeleteNode.prototype.constructParams = function () {
	let query = "'delete from " + this.table + " where ' ";
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			query += "+' " + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			query += ((idx != this.where.length - 1) ? "+'\" and '" : "");
		}
	}
	query += "+'\"'";
	this.parameter.Input = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.db)
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", this.db);
	else
		this.parameter.Input["Database"] = window.utils.constructObj("$Database", "");
	if (this.schema)
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", this.schema);
	else
		this.parameter.Input["Schema"] = window.utils.constructObj("$Schema", "");
	//this.parameter.Input["Database"] = window.utils.constructObj("$Database", "123_dev");
	this.parameter.Input['snf_delete$Input.$Query'] = window.utils.constructObject("snf_delete$Input.$Query", query);
	//this.parameter.Output['BOS$Output.$out_msg'] = window.utils.constructObject("BOS$Output.$out_msg", "sql_delete$Output.$message");
}

SNFDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SNFDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.db = this.db;
	if (this.dbObj)
		cpynode.dbObj = window.utils.cloneObjs('', this.dbObj);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}


function AddRowNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "row";
	this.nodeLabel = "Add Row";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.uiRenderTags = ["table", "tableObj", "column"];
	// this.shape.calculateHeight(this);
}
AddRowNode.prototype = Object.create(Node.prototype);

AddRowNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
AddRowNode.prototype.getValue = function (key) {
	return this[key];
};

AddRowNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


AddRowNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
AddRowNode.prototype.constructParams = function () {
	this.parameter.Input = [];
	if (this.tableObj) {
		this.parameter.Input["Table"] = window.utils.constructObj("$Table", this.tableObj.value);
		for (let i = 0; i < this.column.length; i++) {
			if (this.column[i].column != "") {
				let value = '';
				if (typeof (this.column[i].value) == 'string') {
					value = this.column[i].value;
				}
				else {
					value = this.column[i].value.constructParams();
				}
				let expression = window.utils.variableSlice(this.column[i].value);
				let xpath = 'row$Input.$' + this.column[i].column;
				this.setParameter(this, "Input", expression, xpath, xpath, window.application.parse(expression));
			}
		}
	}
}

AddRowNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.tableObj.value);
}

AddRowNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
}

function SapCommitNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sapCommit";
	this.nodeLabel = "SAP Commit";
	this.shape = new SAPShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.l = ["rpr"];
	this.uiRenderTags = ["_system"];
}
SapCommitNode.prototype = Object.create(Node.prototype);

SapCommitNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SapCommitNode.prototype.getValue = function (key) {
	return this[key];
};

SapCommitNode.prototype.addEvents = function () {
	// this.addSystemDropDownEvent();
};
SapCommitNode.prototype.constructParams = function () {
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["RfcDestination"] = window.utils.constructObj("$RfcDestination", this._system.system);
}

SapCommitNode.prototype.getMinimizeText = function () {
	return this.name + " " + this._system.system;
}

function SapRollbackNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sapRollback";
	this.nodeLabel = "SAP Rollback";
	this.shape = new SAPShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.uiRenderTags = ["_system"];
	this.l = ["rpr"];
}
SapRollbackNode.prototype = Object.create(Node.prototype);

SapRollbackNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SapRollbackNode.prototype.getValue = function (key) {
	return this[key];
};

SapRollbackNode.prototype.addEvents = function () {
	// this.addSystemDropDownEvent();
};
SapRollbackNode.prototype.constructParams = function () {
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["RfcDestination"] = window.utils.constructObj("$RfcDestination", this._system.system);
}

SapRollbackNode.prototype.getMinimizeText = function () {
	return this.name + " " + this._system.system;
}

function SapTransactionNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "startTransaction";
	this.nodeLabel = "SAP Start Transaction";
	this.shape = new SAPShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.uiRenderTags = ["_system"];
	this.l = ["rpr"];
}
SapTransactionNode.prototype = Object.create(Node.prototype);

SapTransactionNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SapTransactionNode.prototype.getValue = function (key) {
	return this[key];
};

SapTransactionNode.prototype.addEvents = function () {
	// this.addSystemDropDownEvent();
};
SapTransactionNode.prototype.constructParams = function () {
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["RfcDestination"] = window.utils.constructObj("$RfcDestination", this._system.system);
}

SapTransactionNode.prototype.getMinimizeText = function () {
	return this.name + " " + this._system.system;
}

function SapStopTransactionNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "endTransaction";
	this.nodeLabel = "SAP Stop Transaction";
	this.shape = new SAPShape(x, y, name, type);
	this._system = window.application.getPluginOrSystem('system', this);
	this.uiRenderTags = ["_system"];
	this.l = ["rpr"];
}
SapStopTransactionNode.prototype = Object.create(Node.prototype);

SapStopTransactionNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SapStopTransactionNode.prototype.getValue = function (key) {
	return this[key];
};

SapStopTransactionNode.prototype.addEvents = function () {
	// this.addSystemDropDownEvent();
};
SapStopTransactionNode.prototype.constructParams = function () {
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["RfcDestination"] = window.utils.constructObj("$RfcDestination", this._system.system);
}

SapStopTransactionNode.prototype.getMinimizeText = function () {
	return this.name + " " + this._system.system;
}

function SFListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = "SF list";
	this.nodeName = "sfList";
	this.l = ['rc'];
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['SF'];
	this.table = "";
	this.tableUrl = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.to = '';
	this.toTitle = 'to';
	this.operatorsList = ['=']
	this.selectColumnsLists = [];
	this.uiRenderTags = ["system", "table", "tableUrl", "tableObj", "where", "selectColumnsLists", "to"];
	// this.shape.calculateHeight(this);
}
SFListNode.prototype = Object.create(Node.prototype);

SFListNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SFListNode.prototype.getValue = function (key) {
	return this[key];
};

SFListNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};
SFListNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

SFListNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["url"] = window.utils.constructObj("$url", "/services/data/v50.0")
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system)
	this.parameter.Input["Plr_SFObject"] = window.utils.constructObj("$Plr_SFObject", this.table)
	for (const idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column != "" && this.where[idx].value != "")
			this.parameter.Input[this.where[idx].column] = window.utils.constructObject(this.where[idx].column, window.utils.variableSlice(value));
	}
	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_select$Output.$Table")
		for (let x in this.selectColumnsLists) {
			let paramFields = window.application.ui.parameter.Output[this.to.split("$")[2]].field;
			for (let y in paramFields) {
				let _name = paramFields[y].name.split("$")[3] ? paramFields[y].name.split("$")[3] : paramFields[y].name;
				if (_name == this.selectColumnsLists[x]) {
					paramFields[y]["value"] = "sql_select$Output.$Table$" + _name;
				}
			}
		}
	}
}

SFListNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SFListNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	if (this.column)
		cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.tableUrl = this.tableUrl;
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	cpynode.to = this.to;
	cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
	cpynode.mapped = false;
	if (this.system) {
		cpynode.system = window.utils.cloneObjs('System', this.system);
		//cpynode.systemObjs = window.utils.cloneObjs('FDBColumn', this.systemObjs);
	}
}

function SFCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sfCreate";
	this.nodeLabel = "SF create";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SF'];
	this.l = ['rc'];
	this.table = "";
	this.tableUrl = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.uiRenderTags = ["system", "table", "tableUrl", "tableObj", "column"];
	// this.shape.calculateHeight(this);
}
SFCreateNode.prototype = Object.create(Node.prototype);

SFCreateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SFCreateNode.prototype.getValue = function (key) {
	return this[key];
};

SFCreateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


SFCreateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
SFCreateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["url"] = window.utils.constructObj("$url", "/services/data/v50.0")
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system)
	this.parameter.Input["Plr_SFObject"] = window.utils.constructObj("$Plr_SFObject", this.table)
	for (const idx in this.column) {
		let value = '';
		if (typeof (this.column[idx].value) == 'string') {
			value = this.column[idx].value;
		}
		else {
			value = this.column[idx].value.constructParams();
		}
		if (this.column[idx].column != "" && this.column[idx].value != "")
			this.parameter.Input[this.column[idx].column] = window.utils.constructObject("sql_create$Input.$" + this.column[idx].column, window.utils.variableSlice(value));
	}
}

SFCreateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SFCreateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.tableUrl = this.tableUrl;
	if (this.system) {
		cpynode.system = window.utils.cloneObjs('System', this.system);
		cpynode.systemObjs = window.utils.cloneObjs('', this.systemObjs);
	}
}
function SFUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sfUpdate";
	this.nodeLabel = "SF update";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SF'];
	this.l = ['rc'];
	this.table = "";
	this.tableUrl = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['='];
	this.uiRenderTags = ["system", "table", "tableUrl", "tableObj", "column", "where"];
	// this.shape.calculateHeight(this);
}
SFUpdateNode.prototype = Object.create(Node.prototype);

SFUpdateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SFUpdateNode.prototype.getValue = function (key) {
	return this[key];
};

SFUpdateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

SFUpdateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

SFUpdateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["url"] = window.utils.constructObj("$url", "/services/data/v50.0")
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system)
	this.parameter.Input["Plr_SFObject"] = window.utils.constructObj("$Plr_SFObject", this.table)
	for (const idx in this.column) {
		let value = '';
		if (typeof (this.column[idx].value) == 'string') {
			value = this.column[idx].value;
		}
		else {
			value = this.column[idx].value.constructParams();
		}
		if (this.column[idx].column != "" && this.column[idx].value != "")
			this.parameter.Input[this.column[idx].column] = window.utils.constructObject("sql_update$Input.$" + this.column[idx].column, window.utils.variableSlice(value));
	}
	for (const idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column != "" && this.where[idx].value != "")
			this.parameter.Input[this.where[idx].column] = window.utils.constructObject("sql_update$Input.$" + this.where[idx].column, window.utils.variableSlice(value));
	}
}

SFUpdateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SFUpdateNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.tableUrl = this.tableUrl;
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	if (this.system) {
		cpynode.system = window.utils.cloneObjs('System', this.system);
		cpynode.systemObjs = window.utils.cloneObjs('', this.systemObjs);
	}
}

function SFDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sfDelete";
	this.nodeLabel = "SF delete";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SF'];
	this.l = ['rc'];
	this.table = "";
	this.tableUrl = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', '<>'];
	this.uiRenderTags = ["system", "table", "tableUrl", "tableObj", "where"];
	// this.shape.calculateHeight(this);
}
SFDeleteNode.prototype = Object.create(Node.prototype);

SFDeleteNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
SFDeleteNode.prototype.getValue = function (key) {
	return this[key];
};

SFDeleteNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

SFDeleteNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

SFDeleteNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["url"] = window.utils.constructObj("$url", "/services/data/v50.0")
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system)
	this.parameter.Input["Plr_SFObject"] = window.utils.constructObj("$Plr_SFObject", this.table)
	for (const idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column != "" && this.where[idx].value != "")
			this.parameter.Input[this.where[idx].column] = window.utils.constructObject("sql_delete$Input.$" + this.where[idx].column, window.utils.variableSlice(value));
	}
}

SFDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

SFDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.tableUrl = this.tableUrl;
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
	if (this.system) {
		cpynode.system = window.utils.cloneObjs('System', this.system);
		cpynode.systemObjs = window.utils.cloneObjs('', this.systemObjs);
	}
}

function MSDListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "mscrmList";
	this.nodeLabel = "MS list";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['MSD'];
	this.l = ['rpr'];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.to = '';
	this.toTitle = 'to';
	this.operatorsList = ['=', '>=', '<=', '<', '>', '<>']
	this.selectColumnsLists = [];
	this.uiRenderTags = ["system", "systemObjs", "table", "tableObj", "selectColumnsLists", "where", "to"];
	// this.shape.calculateHeight(this);
}
MSDListNode.prototype = Object.create(Node.prototype);

MSDListNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
MSDListNode.prototype.getValue = function (key) {
	return this[key];
};

MSDListNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};
MSDListNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

MSDListNode.prototype.updateParameters = function () {
	// this.updateParameter();
	// this.parameter.Input["columns"] = {}
	// this.parameter.Input["columns"] = window.utils.constructObject("columns", this.getColumns());
}

MSDListNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = this.tableObj;
	cpynode.column = this.column;
	cpynode.tableUrl = this.tableUrl;
	cpynode.where = this.where;
	cpynode.to = this.to;
	if (this.system) {
		cpynode.system = this.system;
		cpynode.systemObjs = this.systemObjs;
	}
}

function MSDCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "mscrmCreate";
	this.nodeLabel = "MS create";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SF'];
	this.l = ["rpr"];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.uiRenderTags = ["system", "systemObjs", "tableObj", "table", "column"];
	// this.shape.calculateHeight(this);
}
MSDCreateNode.prototype = Object.create(Node.prototype);
MSDCreateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
MSDCreateNode.prototype.getValue = function (key) {
	return this[key];
};

MSDCreateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};


MSDCreateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
MSDCreateNode.prototype.updateParameters = function () {
	// this.updateParameter();
}

function MSDUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "mscrmUpdate";
	this.nodeLabel = "MS update";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['ERP'];
	this.l = ["rpr"];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'columns';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', '<>'];
	this.uiRenderTags = ["system", "systemObjs", "table", "tableObj", "column", "where"];
	// this.shape.calculateHeight(this);
}
MSDUpdateNode.prototype = Object.create(Node.prototype);

MSDUpdateNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
MSDUpdateNode.prototype.getValue = function (key) {
	return this[key];
};

MSDUpdateNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

MSDUpdateNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

MSDUpdateNode.prototype.updateParameters = function () {
	// this.updateParameter();
}

function MSDDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "mscrmDelete";
	this.nodeLabel = "MS delete";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemObjs = null;
	this.systemTypes = ['SF'];
	this.l = ["rpr"];
	this.table = "";
	this.tableTitle = 'table';
	this.tableDefault = 'select';
	this.tableObj = null;
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', '<>']
	this.uiRenderTags = ["system", "systemObjs", "table", "tableObj", "where"];
	// this.shape.calculateHeight(this);
}
MSDDeleteNode.prototype = Object.create(Node.prototype);

MSDDeleteNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
MSDDeleteNode.prototype.getValue = function (key) {
	return this[key];
};

MSDDeleteNode.prototype.getColumns = function () {
	if (this.columns.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.columns.length; i++) {
			_val += (_val == '' ? '' : ' , ') + this.columns[i];
		}
		return _val;
	}
};

MSDDeleteNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};

MSDDeleteNode.prototype.updateParameters = function () {
	// this.updateParameter();
}

function DriveUploadNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveUpload";
	this.nodeLabel = "Drive Upload";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'to destination';
	this.destination = '';
	this.outputTitle = 'destinationPath';
	this.output = '';
	this.mandatoryTitle = 'with overwrite';
	this.mandatory = 'yes';
	this.mandatoryList = ['yes', 'no'];
	this.uiRenderTags = ["source", "destination", "mandatory", "output"];
	// this.shape.calculateHeight(this);
}

DriveUploadNode.prototype = Object.create(Node.prototype);

DriveUploadNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveUploadNode.prototype.constructParams = function () {
	this.parameter.Input = {};
	this.parameter.Output = {};
	if (this.source)
		this.parameter.Input["drive_upload$Input.$source"] = window.utils.constructObject("drive_upload$Input.$source", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["drive_upload$Input.$destination"] = window.utils.constructObject("drive_upload$Input.$destination", window.utils.variableSlice(this.destination));
	if (this.mandatory)
		this.parameter.Input["drive_upload$Input.$overwrite"] = window.utils.constructObject("drive_upload$Input.$overwrite", (this.mandatory == 'yes' ? 'true' : 'false'));

	if (this.output)
		this.parameter.Output[this.output] = window.utils.constructObject(window.utils.variableSlice(this.output), "drive_upload$Output.$destinationPath");

	//this.parameter.Output["BOS$Output.$dest_path"] = window.utils.constructObject("BOS$Output.$dest_path", "drive_upload$Output.$destinationPath");
	//this.parameter.Output["BOS$Output.$out_msg"] = window.utils.constructObject("BOS$Output.$out_msg", "drive_upload$Output.$message");

}

DriveUploadNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

DriveUploadNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
	cpynode.mandatory = this.mandatory;
	cpynode.output = this.output;
}

function DriveDownloadNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveDownload";
	this.nodeLabel = "Drive Download";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.l = ["rc"];
	this.outputTitle = 'to';
	this.output = '';
	this.urlTitle = '/ url';
	this.url = '';
	this.uiRenderTags = ["source", "output", "url"];
	// this.shape.calculateHeight(this);
}

DriveDownloadNode.prototype = Object.create(Node.prototype);

DriveDownloadNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveDownloadNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["source"] = window.utils.constructObject("source", window.utils.variableSlice(this.source));
	if (this.output)
		this.parameter.Output[this.output] = window.utils.constructObject(window.utils.variableSlice(this.output), "drive_download$Output.$path");
	if (this.url)
		this.parameter.Output[this.url] = window.utils.constructObject(window.utils.variableSlice(this.url), "drive_download$Output.$url");
}

DriveDownloadNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

DriveDownloadNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.output = this.output;
	cpynode.url = this.url;
}

function DriveCopyNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveCopy";
	this.nodeLabel = "Drive Copy";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'to destination';
	this.destination = '';
	this.uiRenderTags = ["source", "destination"];
	// this.shape.calculateHeight(this);
}

DriveCopyNode.prototype = Object.create(Node.prototype);

DriveCopyNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveCopyNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["destination"] = window.utils.constructObject("$destination", window.utils.variableSlice(this.destination));
	this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

DriveCopyNode.prototype.getMinimizeText = function () {
	return this.name + " from " + window.utils.getVariableName(this.source) + " to " + window.utils.getVariableName(this.destination);
}
DriveCopyNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
}

function DriveMoveNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveMove";
	this.nodeLabel = "Drive Move";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'to destination';
	this.destination = '';
	this.uiRenderTags = ["source", "destination"];
	// this.shape.calculateHeight(this);
}

DriveMoveNode.prototype = Object.create(Node.prototype);

DriveMoveNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveMoveNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["destination"] = window.utils.constructObject("$destination", window.utils.variableSlice(this.destination));
	this.parameter.Input["drive_move$Input.$overwrite"] = window.utils.constructObject("drive_move$Input.$overwrite", 'false');
}
DriveMoveNode.prototype.getMinimizeText = function () {
	return this.name + " from " + window.utils.getVariableName(this.source) + " to " + window.utils.getVariableName(this.destination);
}

DriveMoveNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
}


function DriveDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveDelete";
	this.nodeLabel = "Drive Delete";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.l = ["rc"];
	this.uiRenderTags = ["source"];
	// this.shape.calculateHeight(this);
}

DriveDeleteNode.prototype = Object.create(Node.prototype);

DriveDeleteNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveDeleteNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
}

DriveDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

DriveDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
}

function DriveListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "driveList";
	this.nodeLabel = "Drive List";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.extentionTitle = 'to';
	this.extention = '';
	this.l = ["rc"];
	this.selectColumnsLists = [];
	this.ColumnsLists = DriveListsColumns;
	this.selectColumnsListsDefault = 'properties';
	this.uiRenderTags = ["source", "extention", 'selectColumnsLists'];
	// this.shape.calculateHeight(this);
	this.buildedName = 'drive_list';
}

DriveListNode.prototype = Object.create(Node.prototype);

DriveListNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

DriveListNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
	// if(this.extention)
	// {
	// 	this.parameter.Output[this.extention] = window.utils.constructObject(this.extention, "sql_select$Output.$Table")
	// 	let paramFields = window.application.ui.parameter.Output[this.extention.split("$")[2]].field;
	// 	for(let y in paramFields)
	// 	{
	// 		let _name = paramFields[y].name.split("$")[3]?paramFields[y].name.split("$")[3]:paramFields[y].name;
	// 		paramFields[y]["value"] = "sql_select$Output.$Table$"+_name;
	// 	}
	// }
}

DriveListNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

DriveListNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.extention = this.extention;
	cpynode.mandatory = this.mandatory;
	cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	cpynode.ColumnsLists = window.utils.cloneObjs('', this.ColumnsLists);
}

function FileParserNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "fparser";
	this.nodeLabel = "Parse CSV";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'File';
	this.source = '';
	this.destinationTitle = 'with Delimiter';
	this.destination = '';
	this.mandatoryTitle = 'Include header';
	this.mandatory = 'yes';
	this.mandatoryList = ['yes', 'no'];
	this.outputTitle = 'to';
	this.output = '';
	this.l = ["rc"];
	this.uiRenderTags = ["source", "destination", "mandatory", "output"];
	// this.shape.calculateHeight(this);
}

FileParserNode.prototype = Object.create(Node.prototype);

FileParserNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

FileParserNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["file_parser$Input.$File"] = window.utils.constructObject("file_parser$Input.$File", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["file_parser$Input.$Delimiter"] = window.utils.constructObject("file_parser$Input.$Delimiter", window.utils.variableSlice(this.destination));
	if (this.mandatory)
		this.parameter.Input["file_parser$Input.$Has_Header"] = window.utils.constructObject("file_parser$Input.$Has_Header", this.mandatory == 'yes' ? "true" : "false");
	if (this.output && this.output != "") {
		this.parameter.Output[this.output] = window.utils.constructObject(this.output, "file_parser$Output.$Outputs")
		let paramFields = window.application.ui.parameter.Output[this.output.split("$")[2]].field;
		for (let y in paramFields) {
			let _name = paramFields[y].name.split("$")[3] ? paramFields[y].name.split("$")[3] : paramFields[y].name;
			paramFields[y]["value"] = "file_parser$Output.$Outputs$" + _name;
		}
	}
}

FileParserNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

FileParserNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
	cpynode.mandatory = this.mandatory;
	cpynode.output = this.output;
}

function FtpNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "ftpDownload";
	this.nodeLabel = "FTP";
	this.shape = new FTPShape(x, y, name, type);
	this.sourceTitle = 'source';
	this.source = '';
	this.destinationTitle = 'to destination';
	this.destination = '';
	this.urlTitle = 'url';
	this.url = '';
	this.l = ["rc"];
	this.operation = '';
	this.operations = ['Upload', 'Download', 'List', 'Copy', 'Move', 'Delete'];
	this.logicalDes = '';
	this.logicalDesDefault = 'system';
	this.operationDafault = 'operation';
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['SFTP'];
	this.uiRenderTags = ["system", "source", "destination", "operation", "logicalDes", "url", "urlTitle"];
	// this.shape.calculateHeight(this);
}

FtpNode.prototype = Object.create(Node.prototype);

FtpNode.prototype.addEvents = function () {
	this.addFtpHandler();
};

// FtpNode.prototype.getConnectionDetails = function () {
// 	let _qryString="project_name="+window.application.projectName;
// 	let postData = {};
// 	postData["systemname"]=this.system.system;
// 	servicePostData(BaseURL.configurator+"ftp/metadata",_qryString,postData,this.onPOSTSuccess,this);
// }
// FtpNode.prototype.onPOSTSuccess = function (resp,node){
// 	if(resp.data.status.type == 'S'){
// 		let self = node;
// 		let data = JSON.parse(resp.data.object.parameters);
// 		node.system.username = data.userId;
// 		node.system.password = data.password || '';
// 		node.server = data.host;
// 		node.port = data.port;
// 		node.sshFilePath = data.sshKey ||'';
// 		node.ftptype = resp.data.type;
// 	}
// }

FtpNode.prototype.constructParams = function () {
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.operation)
		this.parameter.Input["af_ftpcmd"] = window.utils.constructObj("$af_ftpcmd", this.operation);
	if (this.destination)
		this.parameter.Input["af_ftpdest"] = window.utils.constructObject("$af_ftpdest", window.utils.variableSlice(this.destination));
	if (this.source)
		this.parameter.Input["af_ftpremote"] = window.utils.constructObject("$af_ftpremote", window.utils.variableSlice(this.source));

	if (this.operation == 'List') {
		if (this.url) {
			this.parameter.Output[this.url] = window.utils.constructObj(this.url, "af_ftp$Output.$files");
			let paramFields = window.application.ui.parameter.Output[this.url.split("$")[2]].field;
			paramFields[0]["value"] = "af_ftp$Output.$files$name";
		}
	}
	else
		this.parameter.Output[this.url] = window.utils.constructObject(this.url, "af_ftp$Output.$url");
}

FtpNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.operation;
}

FtpNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
	cpynode.mandatory = this.mandatory;
	cpynode.operation = this.operation;
	cpynode.url = this.url;
	cpynode.server = this.server;
	cpynode.port = this.port;
	cpynode.ftptype = this.ftptype;
	cpynode.sshFilePath = this.sshFilePath;
	cpynode.logicalDes = this.logicalDes;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}

function WebServiceNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "wsList";
	this.nodeLabel = "WS";
	this.shape = new SapFunctionShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.system = new System("", "D", "", "", "");

	this.systemTypes = ['WS'];
	this.l = ["rc", "rpr"];

	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.inputColumns = [];
	this.outputColumns = [];
	this.moduleTitle = 'Method';
	this.module = '';
	this.moduleObj = null;
	this.moduleDefault = 'select';
	this.moduleList = [];
	this.uiRenderTags = ["system", "config", "input", "output", "module"];
	this.buildedName = 'web_service';
}


WebServiceNode.prototype = Object.create(Node.prototype);

WebServiceNode.prototype.showProperties = function () {
	if (this.selectedNodeParamSize() > 0) {
		this.clearandfillProperties();
	} else {
		this.editProperties();
	}
};
WebServiceNode.prototype.addEvents = function () {
	this.addSapFunctionHandler();
};

WebServiceNode.prototype.constructParams = function () {
	//this.updateParameter();
	// this.parameter.Input = {}
	// this.parameter.Output = {}
	if (this.system.system)
		this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.serviceDetails)
		this.parameter.Input["SoapUri"] = window.utils.constructObj("$SoapUri", this.serviceDetails.value);
	if (this.serviceDetails)
		this.parameter.Input["sample"] = window.utils.constructObj("$sample", this.serviceDetails.output);
	this.parameter.Input["version"] = window.utils.constructObj("$version", 2);
	if (this.module)
		this.parameter.Input["module"] = window.utils.constructObj("$module", this.module);
	for (let obj of this.input) {
		if (obj.value  && obj.value != '')
			this.parameter.Input[obj.name] = window.utils.constructObject(obj.name,  obj.value);
	}

	for (let obj of this.output) {
		if (obj.value  && obj.value != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value,  obj.name);
	}

	// this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
}
WebServiceNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

WebServiceNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.inputColumns = this.inputColumns;
	cpynode.outputColumns = this.outputColumns;
	cpynode.module = this.module;
	cpynode.moduleList = this.moduleList;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}

function RestServiceNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "rsList";
	//this.nodeLabel = "REST";
	this.nodeLabel = name;
	this.shape = new ServicesShape(x, y, name, type);
	this.l = ["rc", "rpr"];
	this.domain = '';
	this.methodName = '';
	this.outputtype = '';
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ['postParams', 'queryParams', 'headers', 'filePath', 'needResponseType']
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.outputTypes = ['status', 'responseString', 'responseXML', 'statuscode'];
	this.system = new System("", "D", "", "", "");
	if(type == 'rs')
		this.systemTypes = ['RS'];
	else if(type == 'arb')
		this.systemTypes = ['AR'];
	else if(type == 'kr')
		this.systemTypes = ['KR'];
	else if(type == 'shp')
		this.systemTypes = ['SHP'];	
	this.url = '';
	this.urlTitle = 'Url';
	this.uiRenderTags = ["system", "input", "output", "url", "methodName", "domain"];
	// this.shape.calculateHeight(this);
}

RestServiceNode.prototype = Object.create(Node.prototype);

RestServiceNode.prototype.addEvents = function () {
	this.addRestEvents();
};

RestServiceNode.prototype.getConnectionDetails = function () {
	let _qryString = "project_name=" + window.application.projectName;
	let postData = {};
	postData["systemname"] = this.system.system;
	servicePostData(BaseURL.configurator + "restservice/metadata", _qryString, postData, this.onPOSTSuccess, this);
}
RestServiceNode.prototype.onPOSTSuccess = function (resp, node) {
	if (resp.data.status.type == 'S') {
		let self = node;
		let data = JSON.parse(resp.data.object.parameters);
		node.url = "'" + data.url + "'";
		node.domain = data.domain;
		node.methodName = data.method;
		node.outputtype = data.outputType;
		window.application.refresh();
	}
}

RestServiceNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["url"] = window.utils.constructObject("$url", "'" + this.domain + "'+" + this.url);
	this.parameter.Input["methodName"] = window.utils.constructObj("$methodName", this.methodName);
	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
	let tempObj = { postParams: '', headers: '', queryParams: '', filePath: '', needResponseType: '' }
	let status, responseString, responseXML;
	status = responseString = responseXML = '';

	for (let obj of this.input) {
		if (['headers', 'queryParams'].includes(obj.objType) && obj.name && obj.value && obj.name != '' && obj.value != '') {
			tempObj[obj.objType] += (tempObj[obj.objType] == "" ? "" : (obj.objType == 'headers' ? "+'$'+" : "+'&'+")) + obj.name + "+'='+" + obj.value;
		} else if (['needResponseType', 'filePath', 'postParams'].includes(obj.objType) && obj.name && obj.name != '') {
			tempObj[obj.objType] = obj.name
		}

	}
	if (tempObj.postParams && tempObj.postParams != '')
		this.parameter.Input["rest_service$Input.$postParams"] = window.utils.constructObject("rest_service$Input.$postParams", tempObj.postParams);
	else
		this.parameter.Input["rest_service$Input.$postParams"] = window.utils.constructObj("rest_service$Input.$postParams", "");

	if (tempObj.headers && tempObj.headers != "")
		this.parameter.Input["rest_service$Input.$headers"] = window.utils.constructObject("rest_service$Input.$headers", tempObj.headers);
	else
		this.parameter.Input["rest_service$Input.$headers"] = window.utils.constructObj("rest_service$Input.$headers", "");
	if (tempObj.queryParams && tempObj.queryParams != "")
		this.parameter.Input["rest_service$Input.$queryParams"] = window.utils.constructObject("rest_service$Input.$queryParams", tempObj.queryParams);
	else
		this.parameter.Input["rest_service$Input.$queryParams"] = window.utils.constructObj("rest_service$Input.$queryParams", "");

	if (tempObj.filePath && tempObj.filePath != "")
		this.parameter.Input["rest_service$Input.$filePath"] = window.utils.constructObject("rest_service$Input.$filePath", tempObj.filePath);
	else
		this.parameter.Input["rest_service$Input.$filePath"] = window.utils.constructObj("rest_service$Input.$filePath", "");
	if (tempObj.needResponseType && tempObj.needResponseType != "")
		this.parameter.Input["rest_service$Input.$needResponseType"] = window.utils.constructObject("rest_service$Input.$needResponseType", tempObj.needResponseType);
	else
		this.parameter.Input["rest_service$Input.$needResponseType"] = window.utils.constructObj("rest_service$Input.$needResponseType", "");
	for (let obj of this.output) {
		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "rest_service$Output.$" + obj.objType);
	}
}

RestServiceNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

RestServiceNode.prototype.copyParams = function (cpynode) {
	cpynode.domain = this.domain;
	cpynode.methodName = this.methodName;
	cpynode.outputtype = this.outputtype;
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.url = this.url;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}


function CreateUserNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "createUser";
	this.nodeLabel = "create User";
	this.shape = new UserShape(x, y, name, type);
	this.l = ["rc"];
	this.salutationTitle = 'salutation';
	this.salutationDefault = 'select';
	this.salutation = '';
	this.fName = '';
	this.fNameTitle = 'First Name';
	this.lName = '';
	this.lNameTitle = 'Last Name';
	this.email = '';
	this.emailTitle = 'Email';
	this.title = '';
	this.titleTitle = 'Title';
	this.loginId = '';
	this.loginIdTitle = 'to';
	this.updateLoginIdTitle = 'Login-ID';
	this.updateLoginIdDefault = '';
	this.updateLoginId = '';
	this.password = '';
	this.passwordTitle = 'Password';
	this.language = '';
	this.languageDefault = 'select';
	this.languageList = Object.keys(window.application.LanguageLists); 
	this.languageTitle = 'Language';
	this.sendEmailList = ['yes', 'no']; 
	this.sendEmail = 'yes';
	this.sendEmailDefault = 'yes';
	this.sendEmailTitle = 'Send Email';
	this.customProperties = [new UserProperties()];
	this.customPropertiesTitle = 'custom properties';
	this.customPropertiesDefault = 'property';
	this.customPropertiesValueDefault = 'select';
	this.uiRenderTags = ["salutation", "fName", "lName", "email", "title", "loginId", "updateLoginId","password", "language", "customProperties", "sendEmail"];
	// this.shape.calculateHeight(this);
}

CreateUserNode.prototype = Object.create(Node.prototype);

CreateUserNode.prototype.addEvents = function () {
	this.addUserHandler();
}

CreateUserNode.prototype.constructParams = function () {
	if (this.fName)
		this.parameter.Input["firstname"] = window.utils.constructObject("$firstname", window.utils.variableSlice(this.fName));
	if (this.lName)
		this.parameter.Input["lastname"] = window.utils.constructObject("$lastname", window.utils.variableSlice(this.lName));
	if (this.email)
		this.parameter.Input["email"] = window.utils.constructObject("$email", window.utils.variableSlice(this.email));
	if (this.title)
		this.parameter.Input["title"] = window.utils.constructObject("$title", window.utils.variableSlice(this.title));
	if (this.updateLoginId)
		this.parameter.Input["loginid"] = window.utils.constructObject("$loginid", window.utils.variableSlice(this.updateLoginId));
	if (this.password)
		this.parameter.Input["password"] = window.utils.constructObject("$password", window.utils.variableSlice(this.password));
	if (this.salutation)
		this.parameter.Input["salutation"] = window.utils.constructObject("$salutation", window.utils.variableSlice(this.salutation));
	this.parameter.Input["timezone"] = window.utils.constructObj("$timezone", "1");
	if (this.language)
		this.parameter.Input["language"] = window.utils.constructObject("$language", window.application.LanguageLists[window.utils.variableSlice(this.language)]);
	else
		this.parameter.Input["language"] = window.utils.constructObj("$language", "en");
	if (this.sendEmail)
		this.parameter.Input["sendEmail"] = window.utils.constructObject("$sendEmail", this.sendEmail == 'yes' ? "true" : "false");
	else
		this.parameter.Input["sendEmail"] = window.utils.constructObject("$sendEmail", "true");
	this.parameter.Input["dateformat"] = window.utils.constructObj("$dateformat", "2");
	if (this.loginId)
		this.parameter.Output[this.loginId] = window.utils.constructObject(window.utils.variableSlice(this.loginId), "create_user$Output.$id");

	if (this.customProperties){
		let properties = {};
		let propertyNames = [];
		for (let obj of this.customProperties){
			properties[obj['propertyUuid']] = window.application.parse(obj['value']);
			propertyNames.push(obj['propertyUuid']);
		}
		this.parameter.Input["properties"] = window.utils.constructObj("$properties", JSON.stringify(properties));
		this.parameter.Input["propertyNames"] = window.utils.constructObj("$propertyNames", JSON.stringify(propertyNames));
	}
	
}

CreateUserNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.updateLoginId);
}

CreateUserNode.prototype.copyParams = function (cpynode) {
	cpynode.fName = this.fName;
	cpynode.salutation = this.salutation;
	cpynode.lName = this.lName;
	cpynode.email = this.email;
	cpynode.title = this.title;
	cpynode.updateLoginId = this.updateLoginId;
	cpynode.loginId = this.loginId;
	cpynode.password = this.password;
	cpynode.language = this.language;
	cpynode.sendEmail = this.sendEmail;
	cpynode.customProperties = window.utils.cloneObjs('UserProperties', this.customProperties);
}

function UpdateUserNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "updateUser";
	this.nodeLabel = "update User";
	this.shape = new UserUpdateShape(x, y, name, type);
	this.l = ["rc"];
	this.updateLoginIdTitle = 'Login-ID';
	this.updateLoginIdDefault = '';
	this.updateLoginId = '';
	this.customProperties = [new UserProperties()];
	this.customPropertiesTitle = 'User Details';
	this.customPropertiesDefault = 'property';
	this.customPropertiesValueDefault = 'select';
	this.uiRenderTags = ["updateLoginId", "customProperties"];
	// this.shape.calculateHeight(this);
}

UpdateUserNode.prototype = Object.create(Node.prototype);

UpdateUserNode.prototype.addEvents = function () {
	this.addUserUpdateHandler();
}

UpdateUserNode.prototype.constructParams = function () {
	if (this.updateLoginId)
		this.parameter.Input["loginid"] = window.utils.constructObject("$loginid", window.utils.variableSlice(this.updateLoginId));
	
	if (this.customProperties){
		let properties = {};
		let propertyNames = [];
		
		for (let obj of this.customProperties){
			properties[obj['propertyUuid']] = window.application.parse(obj['value']);
			propertyNames.push(obj['propertyUuid']);
		}
		this.parameter.Input["properties"] = window.utils.constructObj("$properties", JSON.stringify(properties));
		this.parameter.Input["propertyNames"] = window.utils.constructObj("$propertyNames", JSON.stringify(propertyNames));
	}
}

UpdateUserNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.updateLoginId);
}

UpdateUserNode.prototype.copyParams = function (cpynode) {
	cpynode.updateLoginId = this.updateLoginId;
	cpynode.customProperties = window.utils.cloneObjs('UserProperties', this.customProperties);
}

function ResetPasswordNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "resetPwd";
	this.nodeLabel = "Reset Password";
	this.shape = new UserListShape(x, y, name, type);
	this.l = ["rc"];
	this.updateLoginIdTitle = 'Login-ID';
	this.updateLoginIdDefault = '';
	this.updateLoginId = '';
	this.sendEmailList = ['yes', 'no']; 
	this.sendEmail = 'yes';
	this.sendEmailDefault = 'yes';
	this.sendEmailTitle = 'Send Email';
	this.loginId = '';
	this.loginIdTitle = 'to';
	this.uiRenderTags = ["updateLoginId", "sendEmail","loginId"];
	//this.shape.calculateHeight(this);
}

ResetPasswordNode.prototype = Object.create(Node.prototype);

ResetPasswordNode.prototype.addEvents = function () {
	this.addUserHandler();
}

ResetPasswordNode.prototype.constructParams = function () {
	if (this.updateLoginId)
		this.parameter.Input["loginid"] = window.utils.constructObject("$loginid", window.utils.variableSlice(this.updateLoginId));
	
	if (this.sendEmail)
		this.parameter.Input["sendEmail"] = window.utils.constructObject("$sendEmail", this.sendEmail == 'yes' ? "true" : "false");
	else
		this.parameter.Input["sendEmail"] = window.utils.constructObject("$sendEmail", "true");

		if (this.loginId)
		this.parameter.Output[this.loginId] = window.utils.constructObject(window.utils.variableSlice(this.loginId), "resetPwd$Output.$result");
}

ResetPasswordNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.updateLoginId);
}

ResetPasswordNode.prototype.copyParams = function (cpynode) {
	cpynode.updateLoginId = this.updateLoginId;
	cpynode.sendEmail = this.sendEmail;
	cpynode.loginId = this.loginId;
}

function PasswordPolicyNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "pwdpolicy";
	this.nodeLabel = "Password Policy";
	this.shape = new PasswordPolicyShape(x, y, name, type);
	this.l = ["rc"];
	//this.source = '';
	//this.sourceTitle="loginName";
	this.to = '';
	this.toTitle = 'to';
	this.selectColumnsLists=["length", "uppercase_allowed", "lowercase_allowed", "number_allowed", "nonalpha_allowed","validity","user_change","reuse","admin_reset","lockout_threshhold","lockout_time","lockout_warning"];
	this.uiRenderTags = ["to"];
	// this.shape.calculateHeight(this);
}

PasswordPolicyNode.prototype = Object.create(Node.prototype);

PasswordPolicyNode.prototype.addEvents = function () {
	this.addWorkflowHandler()
}

PasswordPolicyNode.prototype.constructParams = function () {

	this.parameter.Output = {};
	
	if (this.to)
	this.parameter.Output[this.to] = window.utils.constructObject(this.to, "pwdpolicy$Output.$Table");
}

PasswordPolicyNode.prototype.getMinimizeText = function () {
	return this.name;
}

PasswordPolicyNode.prototype.copyParams = function (cpynode) {
	cpynode.to = this.to;
}

function AssignGroupNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "assignGroup";
	this.nodeLabel = "add";
	this.shape = new UserShape(x, y, name, type);
	this.l = ["rc"];
	this.updateLoginIdTitle = 'Login-ID';
	this.updateLoginId = '';
	// this.role = '';
	// this.roleTitle = 'to role';
	// this.roleDefault = 'role-name';
	this.fName = '';
	this.fNameTitle = 'User-ID';
	this.lName = '';
	this.lNameTitle = 'to role';
	// this.uiRenderTags = ["updateLoginId", "role"];
	this.uiRenderTags = ["updateLoginId", "fName", "lName"];
	// this.shape.calculateHeight(this);
}

AssignGroupNode.prototype = Object.create(Node.prototype);

AssignGroupNode.prototype.addEvents = function () {
	this.addUserHandler();
}

AssignGroupNode.prototype.constructParams = function () {
	if (this.updateLoginId)
		this.parameter.Input["loginid"] = window.utils.constructObject("$loginid", window.utils.variableSlice(this.updateLoginId));
	if (this.fName)
		this.parameter.Input["userid"] = window.utils.constructObject("$userid", window.utils.variableSlice(this.fName));
	if (this.lName)
		this.parameter.Input["rolename"] = window.utils.constructObject("$rolename", window.utils.variableSlice(this.lName));
}

AssignGroupNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.updateLoginId);
}

AssignGroupNode.prototype.copyParams = function (cpynode) {
	cpynode.fName = this.fName;
	cpynode.updateLoginId = this.updateLoginId;
	cpynode.lName = this.lName;
}


function RemoveGroupNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "removeGroup";
	this.nodeLabel = "remove";
	this.shape = new UserShape(x, y, name, type);
	this.l = ["rc"];
	this.updateLoginIdTitle = 'Login-ID';
	this.updateLoginIdDefault = 'Login-ID';
	this.updateLoginId = '';
	this.role = '';
	this.roleTitle = 'remove role';
	this.roleDefault = 'role-name';
	this.uiRenderTags = ["updateLoginId", "role"];
}

RemoveGroupNode.prototype = Object.create(Node.prototype);

RemoveGroupNode.prototype.addEvents = function () {
	this.addUserHandler();
}

RemoveGroupNode.prototype.constructParams = function () {
	if (this.updateLoginId)
		this.parameter.Input["userid"] = window.utils.constructObject("$userid", window.utils.variableSlice(this.updateLoginId));
	if (this.role)
		this.parameter.Input["rolename"] = window.utils.constructObject("$rolename", window.utils.variableSlice(this.role));
}

RemoveGroupNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.updateLoginId);
}

RemoveGroupNode.prototype.copyParams = function (cpynode) {
	cpynode.role = this.role;
	cpynode.updateLoginId = this.updateLoginId;
}

function UserListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "usrlist";
	this.nodeLabel = name;
	this.buildedName="usrlist";
	this.shape = new UserListShape(x, y, name, type);
	this.l = ["rc"];
	this.inputTitle = 'Input';
	this.to = '';
	this.toTitle = 'to';
	this.selectColumnsLists=["firstName", "lastName", "title", "loginName", "email","role","language","salutation","dateFormat","currency"];
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ["firstName", "lastName", "title", "loginName", "email","role", "customProperties"]
	this.uiRenderTags = ["input", "to"];
}

UserListNode.prototype = Object.create(Node.prototype);

UserListNode.prototype.addEvents = function () {
	this.addRestEvents();
};


UserListNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
    //"firstName", "lastName", "title", "loginName", "email","role", "customProperties"
	let tempObj = { firstName: '', customProperties: '', lastName: '', title: '', loginName: '',email:'',role:'' }
	console.log("UserListNode 111");
	for (let obj of this.input) {
		console.log("UserListNode 222 - obj:",obj);
		if (['customProperties'].includes(obj.objType) && obj.name && obj.value && obj.name != '' && obj.value != '') {
			tempObj[obj.objType] += (tempObj[obj.objType] == "" ? "" : (obj.objType == 'headers' ? "+'$'+" : "+'&'+")) + obj.name + "+'='+" + obj.value;
		} else if (["firstName", "lastName", "title", "loginName", "email","role"].includes(obj.objType) && obj.name && obj.name != '') {
			tempObj[obj.objType] = obj.name
		}
	}
	console.log("UserListNode 333 - tempObj:",tempObj);
	if (tempObj.firstName != ''){
		
		this.parameter.Input["usrlist$Input.$firstName"] = window.utils.constructObject("usrlist$Input.$firstName", tempObj.firstName);
	}else{
		this.parameter.Input["usrlist$Input.$firstName"] = window.utils.constructObj("usrlist$Input.$firstName", "");
	}
    

	if (tempObj.customProperties != "")
		this.parameter.Input["usrlist$Input.$customProperties"] = window.utils.constructObject("usrlist$Input.$customProperties", tempObj.customProperties);
	else
		this.parameter.Input["usrlist$Input.$customProperties"] = window.utils.constructObj("usrlist$Input.$customProperties", "");
	
    if (tempObj.lastName != '')
        this.parameter.Input["usrlist$Input.$lastName"] = window.utils.constructObject("usrlist$Input.$lastName", tempObj.lastName);
	else
        this.parameter.Input["usrlist$Input.$lastName"] = window.utils.constructObj("usrlist$Input.$lastName", "");

    if (tempObj.title != '')
        this.parameter.Input["usrlist$Input.$title"] = window.utils.constructObject("usrlist$Input.$title", tempObj.title);
	else
        this.parameter.Input["usrlist$Input.$title"] = window.utils.constructObj("usrlist$Input.$title", "");
    
    if (tempObj.loginName != '')
        this.parameter.Input["usrlist$Input.$loginName"] = window.utils.constructObject("usrlist$Input.$loginName", tempObj.loginName);
	else
        this.parameter.Input["usrlist$Input.$loginName"] = window.utils.constructObj("usrlist$Input.$loginName", "");
    
    if (tempObj.email != '')
        this.parameter.Input["usrlist$Input.$email"] = window.utils.constructObject("usrlist$Input.$email", tempObj.email);
	else
        this.parameter.Input["usrlist$Input.$email"] = window.utils.constructObj("usrlist$Input.$email", "");

    if (tempObj.role != '')
        this.parameter.Input["usrlist$Input.$role"] = window.utils.constructObject("usrlist$Input.$role", tempObj.role);
	else
        this.parameter.Input["usrlist$Input.$role"] = window.utils.constructObj("usrlist$Input.$role", "");

	if (this.to)
	this.parameter.Output[this.to] = window.utils.constructObject(this.to, "usrlist$Output.$Table");
}

UserListNode.prototype.getMinimizeText = function () {
	return this.name ;
}

UserListNode.prototype.copyParams = function (cpynode) {
	console.log("copyParams this.input - ",this.input);
	console.log("copyParams this.to - ",this.to);
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.to = this.to;
	/*if (this.selectColumnsLists)
		cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
	if (this.mapping)
		cpynode.mapping = window.utils.cloneObjs('', this.mapping);
	cpynode.mapped = false;
	console.log("copyParams cpynode - ",cpynode);*/
	
}

function RoleListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "rolelist";
	this.nodeLabel = name;
	this.buildedName="rolelist";
	this.shape = new UserListShape(x, y, name, type);
	this.l = ["rc"];
	this.inputTitle = 'Input';
	this.to = '';
	this.toTitle = 'to';
	this.selectColumnsLists=["name","description"];
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ["businessFunction","user"]
	this.uiRenderTags = ["input", "to"];
}

RoleListNode.prototype = Object.create(Node.prototype);

RoleListNode.prototype.addEvents = function () {
	this.addRestEvents();
};


RoleListNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
    let tempObj = { businessFunction:"",user:"" }
	for (let obj of this.input) {
			tempObj[obj.objType] = obj.value;
	}
	if (tempObj.businessFunction != ''){
		this.parameter.Input["rolelist$Input.$businessFunction"] = window.utils.constructObject("rolelist$Input.$businessFunction", tempObj.businessFunction);
	}else{
		this.parameter.Input["rolelist$Input.$businessFunction"] = window.utils.constructObj("rolelist$Input.$businessFunction", "");
	}

	if (tempObj.user != '')
        this.parameter.Input["rolelist$Input.$user"] = window.utils.constructObject("rolelist$Input.$user", tempObj.user);
	else
        this.parameter.Input["rolelist$Input.$user"] = window.utils.constructObj("rolelist$Input.$user", "");

	if (this.to)
	this.parameter.Output[this.to] = window.utils.constructObject(this.to, "usrlist$Output.$Table");
}

RoleListNode.prototype.getMinimizeText = function () {
	return this.name ;
}

RoleListNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.to = this.to;
}

function UserDetailNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "usrdtl";
	this.nodeLabel = name;
	this.buildedName="usrdtl";
	this.shape = new WorkflowShape(x, y, name, type);
	this.shape.color = "#AAE2FF";
	this.l = ["rc"];
	this.to = '';
	this.toTitle = 'to';
	this.source = '';
	this.sourceTitle="loginName";
	this.selectColumnsLists=["firstName", "lastName", "title", "loginName", "email","role","language","salutation","dateFormat","currency"];
	//this.parameterTypes = ["firstName", "lastName", "title", "loginName", "email","role", "customProperties"]
	this.uiRenderTags = ["source", "to"];
	this.properties = new Map();
	this.getUserProperties();
}

UserDetailNode.prototype = Object.create(Node.prototype);

UserDetailNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

UserDetailNode.prototype.getUserProperties = function(){
	let _urlKey = BaseURL.configurator+"userproperties/list";
	let _qryString="$select=propertyUuid,name,value";
	const n=this;
	getData(_urlKey,_qryString,function (resp,event){
		if(resp.data.status.type == 'S'){
			console.log("called userdetails getData");
			console.log(resp.data.data);
			resp.data.data.map((obj,index)=>{
				n.selectColumnsLists.push(obj.name);
				n.properties[obj.name]=obj.propertyUuid;
			})
		}
	},this);
}

UserDetailNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	if(this.source)
	this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
	console.log("this.properties",this.properties);
	//if(this.properties)
	this.parameter.Input["properties"] = window.utils.constructObj("$properties", window.utils.variableSlice(JSON.stringify(this.properties)));
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "usrdtl$Output.$Table");
}

UserDetailNode.prototype.getMinimizeText = function () {
	return this.name ;
}

UserDetailNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.properties = this.properties;
	cpynode.to = this.to;
	//cpynode.mapped = false;
		
}

function UserPropertyData(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "updata";
	this.nodeLabel = name;
	this.buildedName="updata";
	this.shape = new WorkflowShape(x, y, name, type);
	this.shape.color = "#AAE2FF";
	this.l = ["rc"];
	this.to = '';
	this.toTitle = 'to';
	this.source = '';
	this.sourceTitle="Name";
	this.selectColumnsLists=["Key","Value"];
	this.uiRenderTags = ["source", "to"];
}

UserPropertyData.prototype = Object.create(Node.prototype);

UserPropertyData.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

UserPropertyData.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	if(this.source)
	this.parameter.Input["source"] = window.utils.constructObject("$source", window.utils.variableSlice(this.source));
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "updata$Output.$Table");
}

UserPropertyData.prototype.getMinimizeText = function () {
	return this.name ;
}

UserPropertyData.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.to = this.to;
	//cpynode.mapped = false;
		
}


function MailNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "mail";
	this.nodeLabel = "Mail";
	this.shape = new NotificationShape(x, y, name, type);
	this.tos = '';
	this.tosTitle = 'to';
	this.cc = '';
	this.l = ["rc"];
	this.ccTitle = 'cc';
	this.subject = '';
	this.subjectTitle = 'subject';
	this.message = '';
	this.messageTitle = 'message';
	this.system = new System("", "D", "", "", "");
	this.systemDefault = 'system';
	this.systemTypes = ['EMAIL'];
	this.uiRenderTags = ["tos", "cc", "subject", "message", "system"];
	// this.shape.calculateHeight(this);
}

MailNode.prototype = Object.create(Node.prototype);

MailNode.prototype.addEvents = function () {
	this.addNotificationHandler();
}

MailNode.prototype.constructParams = function () {
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.tos)
		this.parameter.Input["Mail$Input.$to"] = window.utils.constructObject("Mail$Input.$to", window.utils.variableSlice(this.tos));
	if (this.cc)
		this.parameter.Input["Mail$Input.$cc"] = window.utils.constructObject("Mail$Input.$cc", window.utils.variableSlice(this.cc));
	if (this.subject)
		this.parameter.Input["Mail$Input.$subject"] = window.utils.constructObject("Mail$Input.$subject", window.utils.variableSlice(this.subject));
	if (this.message)
		this.parameter.Input["Mail$Input.$message"] = window.utils.constructObject("Mail$Input.$message", window.utils.variableSlice(this.message));
}

MailNode.prototype.getMinimizeText = function () {
	return this.name + " to " + window.utils.getVariableName(this.tos);
}

MailNode.prototype.copyParams = function (cpynode) {
	cpynode.tos = this.tos;
	cpynode.cc = this.cc;
	cpynode.subject = this.subject;
	cpynode.message = this.message;
}

function PushNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "push";
	this.nodeLabel = "Push";
	this.shape = new NotificationShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'Params';
	this.tos = '';
	this.tosTitle = 'user';
	this.cc = '';
	this.ccTitle = 'count';
	this.subject = '';
	this.subjectTitle = 'message';
	this.l = ["rc"];
	this.selectColumnsLists = '';
	this.variables = [];
	this.customProperties = [new UserProperties()];
	this.customPropertiesTitle = 'BF Variables';
	this.customPropertiesDefault = 'variable';
	this.customPropertiesValueDefault = 'select';
	this.uiRenderTags = ["tos", "cc", "subject","selectColumnsLists","tableObj","table","column","customProperties"];
	
}

PushNode.prototype = Object.create(Node.prototype);

PushNode.prototype.addEvents = function () {
	this.addNotificationHandler();
}

PushNode.prototype.constructParams = function () {
	console.log("push node constructParams called - ",this.table+"."+this.selectColumnsLists)
	if (this.tos)
		this.parameter.Input["Push$Input.$user"] = window.utils.constructObject("Push$Input.$user", window.utils.variableSlice(this.tos));
	if (this.cc)
		this.parameter.Input["Push$Input.$count"] = window.utils.constructObject("Push$Input.$count", window.utils.variableSlice(this.cc));
	if (this.table && this.selectColumnsLists)
		this.parameter.Input["Push$Input.$appName"] = window.utils.constructObj("Push$Input.$appName", this.table+"."+this.selectColumnsLists);
	if (this.subject)
		this.parameter.Input["Push$Input.$message"] = window.utils.constructObject("Push$Input.$message", window.utils.variableSlice(this.subject));
	if (this.link)
		this.parameter.Input["Push$Input.$link"] = window.utils.constructObject("Push$Input.$link", window.utils.variableSlice(this.link));
	if (this.bos)
		this.parameter.Input["Push$Input.$bos"] = window.utils.constructObject("Push$Input.$bos", window.utils.variableSlice(this.bos));
	
		if (this.customProperties){
			let properties = {};
			let propertyNames = [];
			
			for (let obj of this.customProperties){
				properties[obj['propertyUuid']] = window.application.parse(obj['value']);
				propertyNames.push(obj['propertyUuid']);
			}
			this.parameter.Input["properties"] = window.utils.constructObj("$properties", JSON.stringify(properties));
			this.parameter.Input["propertyNames"] = window.utils.constructObj("$propertyNames", JSON.stringify(propertyNames));
		}
}

PushNode.prototype.copyParams = function (cpynode) {
	cpynode.tos = this.tos;
	cpynode.cc = this.cc;
	cpynode.subject = this.subject;
	//cpynode.message = this.message;
	cpynode.link = this.link;
	cpynode.bos = this.bos;
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.customProperties = window.utils.cloneObjs('UserProperties', this.customProperties);
}

function DeviceSyncNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "devsync";
	this.nodeLabel = "Device Sync";
	this.shape = new NotificationShape(x, y, name, type);
	this.tos = '';
	this.tosTitle = 'user';
	this.cc = '';
	this.ccTitle = 'count';
	this.subject = '';
	this.subjectTitle = 'app name';
	this.message = '';
	this.messageTitle = 'message';
	this.link = '';
	this.l = ["rc"];
	this.linkTitle = 'command';
	this.triggerSyncTitle = 'trigger_sync';
	this.triggerSyncDefault = 'select';
	this.triggerSyncList = ['yes', 'no'];
	this.triggerSync = '';
	this.showNotifcationTitle = 'show_notifcation';
	this.showNotifcationDefault = 'select';
	this.showNotifcationList = ['yes', 'no'];
	this.showNotifcation = '';
	this.uiRenderTags = ["tos", "cc", "subject", "message", "link", "triggerSync", "showNotifcation"];
	// this.shape.calculateHeight(this);
}

DeviceSyncNode.prototype = Object.create(Node.prototype);

DeviceSyncNode.prototype.addEvents = function () {
	this.addNotificationHandler();
}

DeviceSyncNode.prototype.constructParams = function () {
	if (this.tos)
		this.parameter.Input["DeviceSync$Input.$user"] = window.utils.constructObject("DeviceSync$Input.$user", window.utils.variableSlice(this.tos));
	if (this.cc)
		this.parameter.Input["DeviceSync$Input.$count"] = window.utils.constructObject("DeviceSync$Input.$count", window.utils.variableSlice(this.cc));
	if (this.subject)
		this.parameter.Input["DeviceSync$Input.$appName"] = window.utils.constructObject("DeviceSync$Input.$appName", window.utils.variableSlice(this.subject));
	if (this.message)
		this.parameter.Input["DeviceSync$Input.$message"] = window.utils.constructObject("DeviceSync$Input.$message", window.utils.variableSlice(this.message));
	if (this.link)
		this.parameter.Input["DeviceSync$Input.$command"] = window.utils.constructObject("DeviceSync$Input.$command", window.utils.variableSlice(this.link));
	if (this.triggerSync)
		this.parameter.Input["DeviceSync$Input.$trigger_sync"] = window.utils.constructObject("DeviceSync$Input.$trigger_sync", this.triggerSync);
	if (this.showNotifcation)
		this.parameter.Input["DeviceSync$Input.$show_notification"] = window.utils.constructObject("DeviceSync$Input.$show_notification", this.showNotifcation);
}

DeviceSyncNode.prototype.copyParams = function (cpynode) {
	cpynode.tos = this.tos;
	cpynode.cc = this.cc;
	cpynode.subject = this.subject;
	cpynode.message = this.message;
	cpynode.link = this.link;
	cpynode.triggerSync = this.triggerSync;
	cpynode.showNotifcation = this.showNotifcation;
}

function BossNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "bosService";
	this.nodeLabel = "BOS";
	this.shape = new BosShape(x, y, name, type);
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.bos = '';
	this.uuid = '';
	this.bosDefault = 'select';
	this.l = ["rc", "rd"];
	this.inputColumns = {};
	this.outputColumns = {};
	this.systemsArray = [];
	this.uiRenderTags = ["input", "output", "uuid", "bos"];
	// this.shape.calculateHeight(this);
	this.buildedName = 'service';
}

BossNode.prototype = Object.create(Node.prototype);

BossNode.prototype.addEvents = function () {
	this.addBosHandler();
}

BossNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	if (this.bos) {
		this.parameter.Input["Bosname"] = window.utils.constructObj("$Bosname", this.bos)
	}
	if (this.uuid) {
		this.parameter.Input["BOS_uuid"] = window.utils.constructObj("$BOS_uuid", this.uuid)
	}
	this.parameter.Input["Prjname"] = window.utils.constructObj("$Prjname", "cricket")
	//"$Bosname","key":"Bosname"
	//this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table)
	for (const y in this.input) {
		let obj = this.input[y];
		if (obj.name && obj.value && obj.name != '' && obj.value != '') {
			this.parameter.Input["service$Input.$" + obj.name] = window.utils.constructObject("service$Input.$" + obj.name, obj.value);
			if (obj.type == "table"){
				this.parameter.Input["service$Input.$" + obj.name]['field'] = [];
				for (const z in obj.tableObj.field) {
					let objField = obj.tableObj.field[z];
					this.parameter.Input["service$Input.$" + obj.name]['field'].push(window.utils.constructObject("service$Input.$" + obj.name + "$" + objField.name, objField.Xpath));
				}
			}
		}
	}
	for (const y in this.output) {
		let obj = this.output[y];

		if (obj.name && obj.value && obj.name != '' && obj.value != '') {
			this.parameter.Output["service$Output.$" + obj.name] = window.utils.constructObject(obj.value, "service$Output.$" + obj.name);
			// if (obj.type == "table"){
			// 	this.parameter.Output["service$Output.$" + obj.name]['field'] = [];
			// 	for (const z in obj.tableObj.field) {
			// 		let objField = obj.tableObj.field[z];
			// 		this.parameter.Output["service$Output.$" + obj.name]['field'].push(window.utils.constructObject(objField.Xpath, "service$Output.$" + obj.name + "$" + objField.name));
			// 	}
			// }
		}
	}
	if (this.systemsArray.length > 0) {
		for (let x in this.systemsArray) {
			window.application.ui.addSystem(this.systemsArray[x]);
		}
	}
}

BossNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.bos;
}

BossNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.bos = this.bos;
	cpynode.uuid = this.uuid;
	cpynode.inputColumns = this.inputColumns;
	cpynode.outputColumns = this.outputColumns;
	cpynode.systemsArray = window.utils.cloneObjs('System', this.systemsArray);
}

function SortNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sort";
	this.nodeLabel = "sort";
	this.shape = new SortShape(x, y, name, type);
	this.tableDefault = 'select';
	this.table = '';
	this.tableObj = null;
	this.input = [new FDBColumn()];
	this.inputTitle = 'columns';
	this.inputNameDefault = 'select';
	this.inputTypeDefault = 'select';
	this.inputTypes = ['asc', 'desc'];
	this.uiRenderTags = ["table", "tableObj", "input"];
	// this.shape.calculateHeight(this);
}

SortNode.prototype = Object.create(Node.prototype);

SortNode.prototype.addEvents = function () {
	this.addSortNodeHandler();
}

SortNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	if (this.table) {
		this.parameter.Input["Table"] = window.utils.constructObj("$Table", this.table);
		let paramFields = window.application.ui.parameter[this.table.split("$")[1].substring(0, this.table.split("$")[1].length - 1)][this.table.split("$")[2]].field;
		for (let idx in this.input) {
			if (this.input[idx].column && this.input[idx].type) {
				for (let j in paramFields) {
					if (this.input[idx].column == paramFields[j].name) {
						paramFields[j]["isSortedCol"] = "true";
						paramFields[j]["isAsc"] = (this.input[idx].type == "asc") ? "true" : "false";
					} else {
						paramFields[j]["isSortedCol"] = "false";
						paramFields[j]["isAsc"] = "false";
					}
				}
			}
		}
	}
}

SortNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.table);
}

SortNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('FDBColumn', this.input);
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
}


function CustomNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeName = "catalog";
	this.nodeIcon = '';
	this.shape = new CustomBlockShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = ["rc"];
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.clazz = this.getClazz();
	this.inputColumns = this.getDropDownData("Input");
	this.outputColumns = this.getDropDownData("Output");
	this.uiRenderTags = ["input", "output"];
	this.buildedName = 'catalog';
}


CustomNode.prototype = Object.create(Node.prototype);
CustomNode.prototype.getDropDownData = function (type) {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.meta[type]["field"];
		}
	}
}
CustomNode.prototype.getClazz = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.clazz;
		}
	}
}
CustomNode.prototype.getJarName = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return x.jarName;
		}
	}
}
CustomNode.prototype.addEvents = function () {
	let obj = null;
	let node = this;
	if (node.hasOwnProperty('input')) {
		if (Array.isArray(node.input)) {
			for (let i in node.input) {

				if (typeof (node.input[i].value) == 'string') {
					obj = new Object();
					obj.shape = this.shape['input_value' + i];
					obj.shapeName = 'input_value' + i;
					obj.shapeText = this.shape['input_value' + i + '_text'];
					obj.propertyValue = 'input';
					obj.shapeVariable = 'input_value' + i + '_text';
					obj.propertyKey = 'value';
					obj.position = i;
					this.addEditVariableHandler(obj);
				}
				else {
					node.input[i].value.addClickHandler();
				}

				obj = new Object();
				obj.shape = [this.shape['input_name' + [i]], this.shape['input_name' + [i] + '_arrow'], this.shape['input_name' + [i] + '_text']];
				this.addDropDownHandler(obj, this, 'inputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = null;
					temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'inputColumns', 'input', 'name', i));
					temp.load(node, 0);
				});

				if (this.shape['input_plus_' + i]) {
					this.shape['input_plus_' + i].on('mousedown', function (e) {
						if (window.application.canCreate || window.application.canUpdate) {
							node.input.push(new InputOutObj());
							node.shape.height += 40;
							window.application.refresh();
							window.application.addCommand(new PopCommand(node.input, new InputOutObj()));
						}
					});
				}

				if (this.shape['input_minus_' + i]) {
					this.shape['input_minus_' + i].on('mousedown', function (e) {
						if (window.application.canCreate || window.application.canUpdate) {
							let oldVal = node.input.splice(i, 1);
							if (node.input.length === 0)
								node.input.push(new InputOutObj());
							else
								node.shape.height -= 40;
							window.application.refresh();
							window.application.addCommand(new SpliceCommand(node.input, i, oldVal[0]));
						}
					});
				}

				if (this.shape['input_mapping_icon' + i]) {
					this.shape['input_mapping_icon' + i].on('mousedown', function (e) {
						let obj = {};
						obj.toTable = node.input[i]['value'];
						obj.setTable = node.input[i].tableObj.Xpath;
						//obj.setTable = node.buildedName + '$Input.$' + node.input[i]['name'];
						obj.toType = 'set';
						obj.setCol = node.input[i]['tableObj'].field;
						obj.mapProperty = 'input_mapping' + i;
						obj.mapped = 'input_mapped' + i;
						let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
						temp.load(node, 0);
					});
				}
			}
		}
	}

	if (node.hasOwnProperty('output')) {
		if (Array.isArray(node.output)) {
			for (let i in node.output) {

				obj = new Object();
				obj.shape = this.shape['output_value' + i];
				obj.shapeName = 'output_value' + i;
				obj.shapeText = this.shape['output_value' + i + '_text'];
				obj.propertyValue = 'output';
				obj.shapeVariable = 'output_value' + i + '_text';
				obj.propertyKey = 'value';
				obj.position = i;
				this.addVariableGroupHandler(obj);

				obj = new Object();
				obj.shape = [this.shape['output_name' + [i]], this.shape['output_name' + [i] + '_arrow'], this.shape['output_name' + [i] + '_text']];
				this.addDropDownHandler(obj, this, 'outputType', function (e) {
					let x = e.e.layerX;
					let y = e.e.layerY + 30;
					let temp = null;
					temp = window.application.createDropdownMenu(new ChangeEvent(e.e, 'outputColumns', 'output', 'name', i));
					temp.load(node, 0);
				});

				if (this.shape['output_plus_' + i]) {
					this.shape['output_plus_' + i].on('mousedown', function (e) {
						
						node.output.push(new InputOutObj());
						node.shape.height += 40;
						window.application.refresh();
						window.application.addCommand(new PopCommand(node.output, new InputOutObj()));
					});
				}

				if (this.shape['output_minus_' + i]) {
					this.shape['output_minus_' + i].on('mousedown', function (e) {
						
						let oldVal = node.output.splice(i, 1);
						if (node.output.length === 0) {
							node.output.push(new InputOutObj());
						}
						else {
							node.shape.height -= 40;
						}
						window.application.refresh();
						window.application.addCommand(new SpliceCommand(node.output, i, oldVal[0]));
					});
				}

				if (this.shape['output_mapping_icon' + i]) {
					this.shape['output_mapping_icon' + i].on('mousedown', function (e) {
						let obj = {};
						obj.setTable = node.output[i]['value'];
						obj.toTable = node.output[i].tableObj.Xpath;
						obj.toType = 'set';
						obj.toCol = node.output[i]['tableObj'].field;
						obj.mapProperty = 'output_mapping' + i;
						obj.mapped = 'output_mapped' + i;
						let temp = window.application.createDropdownMenu(new MappingTableColoumnsEvent(obj));
						temp.load(node, 0);
					});
				}
			}
		}
	}

	this.addGroupMouseOverHandler();
};

CustomNode.prototype.constructParams = function () {
	this.parameter.Input["catalog$Input.$Program"] = window.utils.constructObj("catalog$Input.$Program",  this.getJarName());
	for (const y in this.input) {
		let obj = this.input[y];
		if (obj.name && obj.value && obj.name != '' && obj.value != '') {
			let _obj = window.utils.constructObject("catalog$Input.$" + obj.name, obj.value);
			_obj["dataType"] = obj.type;
			this.parameter.Input["catalog$Input.$" + obj.name] = _obj;

		}
	}
	for (const y in this.output) {
		let obj = this.output[y];
		if (obj.name && obj.value && obj.name != '' && obj.value != '') {
			let _obj = window.utils.constructObject(obj.value, "catalog$Output.$" + obj.name);
			_obj["dataType"] = obj.type;
			this.parameter.Output[obj.value] = _obj;
		}
	}
}

CustomNode.prototype.copyParams = function (cpynode) {
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
}

function FreedomDBQueryNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeLabel2 = 'Statement';
	this.nodeName = "fdbQuery";
	this.shape = new SqlStatementShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = ['rc', 'rd'];
	this.paramTitle = 'Parameters';
	this.param = [new InputOutObj()];
	this.toTitle = 'to';
	this.toDefault = '';
	this.to = '';
	this.hasTo = true;
	this.query = '';
	this.selectColumnsLists = [];
	this.mapped = false;
	this.mapping = null;
	this.uiRenderTags = ["query", "to", "param", "mapped", "mapping", "selectColumnsLists"];
	this.buildedName = 'sql_statement';
}


FreedomDBQueryNode.prototype = Object.create(Node.prototype);
FreedomDBQueryNode.prototype.getClazz = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.clazz;
		}
	}
}
FreedomDBQueryNode.prototype.addEvents = function () {
	this.addFreedomDbQueryHandler();
};

// FreedomDBQueryNode.prototype.getColumns = function () {
// 	let arr=new Array();
// 	if(this.query != '' ){
// 		const searchPattern = new RegExp('^select ','i');
// 		if (searchPattern.test(this.query)){
// 			const qry1 = this.query.replace(/^select/i, " ");
// 			const res = qry1.split(/ from /i);
// 			if (res[0].trim() != '*') {
// 				let cols = res[0].trim().split(",");
// 				let val = "";
// 				for ( var i = 0; i < cols.length; i++) {
// 					let alia = cols[i].split(/ as /i);
// 					let colname = cols[i];
// 					if(alia.length==2){
// 						colname = alia[1];
// 					}
// 					arr.push(colname);
// 				}
// 			} else {
// 				window.application.handleErrorMessage("Error",
// 						"* not allowed in the Query");
// 			}


// 		} else {
// 			window.application.handleErrorMessage("Error",
// 					"Only Select query can be allowed");
// 		}
// 	}
// 	return arr;
// };

FreedomDBQueryNode.prototype.copyParams = function (cpynode) {
	cpynode.param = window.utils.cloneObjs('InputOutObj', this.param);
	cpynode.query = this.query;
	cpynode.to = this.to;
	cpynode.selectColumnsLists = this.selectColumnsLists;
}

FreedomDBQueryNode.prototype.editingExited = function () {
	this.selectColumnsLists = window.utils.getQueryColumns(this.query);
}

FreedomDBQueryNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	let queryPart = this.query.split(/from/i);
	if (queryPart.length > 1){
		window.application.ui.removeTable(this.tableName);
		let tableName = queryPart[queryPart.length - 1].split(" ")[1];
		tableName = tableName.replace("'","").replace(";","");
		this.tableName = tableName;
		window.application.ui.addTable(tableName);
	}
	if (this.query != '')
		this.parameter.Input['sql_statement$Input.$Query'] = window.utils.constructObj("sql_statement$Input.$Query", this.query);
	let x = 1;
	for (let val of this.param) {
		if (val.value != '') {
			let expr = '';
			if (typeof (val.value) != 'string') {
				expr = val.value.constructParams();
			} else {
				expr = (window.utils.variableSlice(val.value));
			}
			this.parameter.Input['sql_statement$Input.$param_' + x] = window.utils.constructObject("sql_statement$Input.$param_" + x, expr);
			x++;
		}
	}
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_statement$Output.$Table");
}
function DBAdapterQueryNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeLabel2 = 'Statement';
	this.nodeName = "dbQuery";
	this.shape = new SqlStatementShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = [ 'rpr'];
	this.paramTitle = 'Parameters';
	this.param = [new InputOutObj()];
	this.toTitle = 'to';
	this.toDefault = '';
	this.to = '';
	this.query = '';
	this.hasTo = true;
	this.selectColumnsLists = [];
	this.mapped = false;
	this.mapping = null;
	this.uiRenderTags = ["query", "to", "param", "mapped", "mapping", "selectColumnsLists"];
	this.buildedName = 'sql_statement';
}


DBAdapterQueryNode.prototype = Object.create(Node.prototype);
DBAdapterQueryNode.prototype.getClazz = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.clazz;
		}
	}
}
DBAdapterQueryNode.prototype.addEvents = function () {
	this.addFreedomDbQueryHandler();
};

DBAdapterQueryNode.prototype.copyParams = function (cpynode) {
	cpynode.param = window.utils.cloneObjs('InputOutObj', this.param);
	cpynode.query = this.query;
	cpynode.to = this.to;
	cpynode.selectColumnsLists = this.selectColumnsLists;
}

DBAdapterQueryNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);
	if (this.query != '')
		this.parameter.Input["sql_statement$Input.$Query"] = window.utils.constructObj("sql_statement$Input.$Query", this.query)

	let x = 1;
	for (let val of this.param) {
		if (val.value != '') {
			let expr = '';
			if (typeof (val.value) != 'string') {
				expr = val.value.constructParams();
			} else {
				expr = (window.utils.variableSlice(val.value));
			}
			this.parameter.Input['sql_statement$Input.$param_' + x] = window.utils.constructObject("sql_statement$Input.$param_" + x, expr);
			x++;
		}
	}
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "sql_statement$Output.$Table");
}

DBAdapterQueryNode.prototype.editingExited = function () {
	this.selectColumnsLists = window.utils.getQueryColumns(this.query);
}

function CommentBlockNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeName = "comment";
	this.shape = new commentBlockShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = ['rc', 'rpr', 'rd'];
	this.code = '';
	this.uiRenderTags = ["code"];
	this.buildedName = 'comment_statement';
}


CommentBlockNode.prototype = Object.create(Node.prototype);
CommentBlockNode.prototype.addEvents = function () {
	let obj = null;
	let node = this;
	if (this.hasOwnProperty('code')) {
		obj = new Object();
		obj.shape = this.shape.code_text;
		obj.shape1 = this.shape.code;
		obj.shapeName = 'code';
		obj.propertyValue = 'code';
		obj.shapeVariable = 'code_text';
		this.addVariableValueHandler(obj, this);
	}
};

CommentBlockNode.prototype.copyParams = function (cpynode) {
	cpynode.code = this.code;
}

CommentBlockNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	if (this.code != '')
		this.parameter.Input["Program"] = window.utils.constructObject("$Program", this.code);
}

function FreedomDBQuerywithoutParamNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeLabel2 = 'Statement';
	this.nodeName = "fdbQuery";
	this.shape = new SqlStatementShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = ['rc', 'rd'];
	this.toTitle = 'to';
	this.toDefault = '';
	this.to = '';
	this.query = '';
	this.selectColumnsLists = [];
	this.mapped = false;
	this.mapping = null;
	this.sqlType = 'select';
	this.uiRenderTags = ["query", "to", "mapped", "mapping", "selectColumnsLists", "sqlType", "nodeName", "buildedName"];
	this.buildedName = 'sql_statement';
}


FreedomDBQuerywithoutParamNode.prototype = Object.create(Node.prototype);
FreedomDBQuerywithoutParamNode.prototype.getClazz = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.clazz;
		}
	}
}
FreedomDBQuerywithoutParamNode.prototype.addEvents = function () {
	if (this.sqlType == 'select' || this.sqlType == 'insert' || !this.sqlType)
		this.hasTo = true;
	else 
		this.hasTo = false;
	this.addFreedomDbQueryHandler();
};

FreedomDBQuerywithoutParamNode.prototype.copyParams = function (cpynode) {
	cpynode.query = this.query;
	cpynode.to = this.to;
	cpynode.selectColumnsLists = this.selectColumnsLists;
}

FreedomDBQuerywithoutParamNode.prototype.editingExited = function () {
	this.selectColumnsLists = window.utils.getQueryColumns(this.query);
}

FreedomDBQuerywithoutParamNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	let title = '';
	let queryPart = this.query.split(/from/i);
	if (queryPart.length > 1){
		window.application.ui.removeTable(this.tableName);
		let tableName = queryPart[queryPart.length - 1].split(" ")[1];
		tableName = tableName.replace("'","").replace(";","");
		this.tableName = tableName;
		window.application.ui.addTable(tableName);
	}
	if (this.query != '')
		this.parameter.Input[this.buildedName + '$Input.$Query'] = window.utils.constructObject(this.buildedName + "$Input.$Query", this.query);
	
	if (this.to){
		if (this.sqlType == 'select')
			this.parameter.Output[this.to] = window.utils.constructObject(this.to, this.buildedName +"$Output.$Table");
		else if (this.sqlType == 'insert')
			this.parameter.Output[this.to] = window.utils.constructObject(this.to, this.buildedName +"$Output.$key");
	}
}

FreedomDBQuerywithoutParamNode.prototype.editingExited = function () {
	this.selectColumnsLists = window.utils.getQueryColumns(this.query, true);
	let chk = true;
	let searchPattern = new RegExp('^(\'|")select ', 'i');
	if (searchPattern.test(this.query)){
		this.buildedName = 'sql_select';
		this.nodeName = "fdbList";
		chk = false;
		this.sqlType = 'select';
	}
	searchPattern = new RegExp('^(\'|")update ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_update';
		this.nodeName = "fdbUpdate";
		chk = false;
		this.sqlType = 'update';
		this['to'] = '';
	}
	searchPattern = new RegExp('^(\'|")insert ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_insert';
		this.nodeName = "fdbCreate";
		chk = false;
		this.sqlType = 'insert';
	}
	searchPattern = new RegExp('^(\'|")delete ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_delete';
		this.nodeName = "fdbDelete";
		chk = false;
		this.sqlType = 'delete';
		this['to'] = '';
	}
	window.application.refresh();
}


function DBAdapterQueryWithoutParamNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeLabel = name;
	this.nodeLabel2 = 'Statement';
	this.nodeName = "dbQuery";
	this.shape = new SqlStatementShape(x, y, name, type);
	this.shape.color = '#AAE2FF';
	this.l = [ 'rpr'];
	this.toTitle = 'to';
	this.toDefault = '';
	this.to = '';
	this.query = '';
	this.selectColumnsLists = [];
	this.hasTo = true;
	this.mapped = false;
	this.mapping = null;
	this.uiRenderTags = ["query", "to", "mapped", "mapping", "selectColumnsLists", "sqlType", "nodeName", "buildedName"];
	this.buildedName = 'sql_statement';
}


DBAdapterQueryWithoutParamNode.prototype = Object.create(Node.prototype);
DBAdapterQueryWithoutParamNode.prototype.getClazz = function () {
	for (let x of window.application.customBlockDetails) {
		for (let y of x.actions) {
			if (y.name == this.nodeLabel)
				return y.clazz;
		}
	}
}
DBAdapterQueryWithoutParamNode.prototype.addEvents = function () {
	if (this.sqlType == 'select' || this.sqlType == 'insert' || !this.sqlType)
		this.hasTo = true;
	else 
		this.hasTo = false;
	this.addFreedomDbQueryHandler();
};

DBAdapterQueryWithoutParamNode.prototype.copyParams = function (cpynode) {
	cpynode.query = this.query;
	cpynode.to = this.to;
	cpynode.selectColumnsLists = this.selectColumnsLists;
}

DBAdapterQueryWithoutParamNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this._system = window.application.getPluginOrSystem('system', this);
	this.parameter.Input["System"] = window.utils.constructObj("$System", this._system.system);
	if (this.query != '')
		this.parameter.Input[this.buildedName +"$Input.$Query"] = window.utils.constructObject(this.buildedName +"$Input.$Query", this.query)

	if (this.to){
		if (this.sqlType == 'select')
			this.parameter.Output[this.to] = window.utils.constructObject(this.to, this.buildedName +"$Output.$Table");
		else if (this.sqlType == 'insert')
			this.parameter.Output[this.to] = window.utils.constructObject(this.to, this.buildedName +"$Output.$key");
	}
}

DBAdapterQueryWithoutParamNode.prototype.editingExited = function () {
	this.selectColumnsLists = window.utils.getQueryColumns(this.query, true);
	let chk = true;
	let searchPattern = new RegExp('^(\'|")select ', 'i');
	if (searchPattern.test(this.query)){
		this.buildedName = 'sql_select';
		this.nodeName = "dbList";
		chk = false;
		this.sqlType = 'select';
	}
	searchPattern = new RegExp('^(\'|")update ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_update';
		this.nodeName = "dbUpdate";
		chk = false;
		this.sqlType = 'update';
		this['to'] = '';
	}
	searchPattern = new RegExp('^(\'|")insert ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_insert';
		this.nodeName = "dbCreate";
		chk = false;
		this.sqlType = 'insert';
	}
	searchPattern = new RegExp('^(\'|")delete ', 'i');
	if (searchPattern.test(this.query) && chk){
		this.buildedName = 'sql_delete';
		this.nodeName = "dbDelete";
		chk = false;
		this.sqlType = 'delete';
		this['to'] = '';
	}
	window.application.refresh();
}

function PdfGenerateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "pdfCreate";
	this.nodeLabel = "Pdf Generate";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'Xml Data';
	this.source = '';
	this.extentionTitle = 'Xsl Data';
	this.extention = '';
	this.outputTitle = 'PDF';
	this.output = '';
	this.l = ["rc"];
	this.uiRenderTags = ["source", "extention", "output"];
	// this.shape.calculateHeight(this);
}

PdfGenerateNode.prototype = Object.create(Node.prototype);

PdfGenerateNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

PdfGenerateNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["xml_data"] = window.utils.constructObject("$xml_data", this.source);
	if (this.extention)
		this.parameter.Input["xsl_data"] = window.utils.constructObject("$xsl_data", this.extention);

	if (this.output && this.output != "") {
		this.parameter.Output[this.output] = window.utils.constructObject(this.output, "pdf_create$Output.$pdf_content");
	}
}


PdfGenerateNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.extention = this.extention;
	cpynode.output = this.output;
}

function FMAuthenticationNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "fmAuthentication";
	this.nodeLabel = "FM Authentication";
	this.shape = new DriveShape(x, y, name, type);
	this.sourceTitle = 'UserId';
	this.source = '';
	this.extentionTitle = 'Password';
	this.extention = '';
	this.destinationTitle = 'TenantId';
	this.destination = '';
	this.l = ["rc"];
	this.outputTitle = 'status';
	this.output = '';
	this.urlTitle = 'message';
	this.url = '';
	this.uiRenderTags = ["source", "output", "url", "extention", "destination"];
}

FMAuthenticationNode.prototype = Object.create(Node.prototype);

FMAuthenticationNode.prototype.addEvents = function () {
	this.addDriveHandler();
};

FMAuthenticationNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["fm_auth$Input.$userId"] = window.utils.constructObject("fm_auth$Input.$userId", window.utils.variableSlice(this.source));
	if (this.extention)
		this.parameter.Input["fm_auth$Input.$password"] = window.utils.constructObject("fm_auth$Input.$password", window.utils.variableSlice(this.extention));
	if (this.destination)
		this.parameter.Input["fm_auth$Input.$tenantId"] = window.utils.constructObject("fm_auth$Input.$tenantId", window.utils.variableSlice(this.destination));
	if (this.output)
		this.parameter.Output[this.output] = window.utils.constructObject(window.utils.variableSlice(this.output), "fm_auth$Output.$outStatus");
	if (this.url)
		this.parameter.Output[this.url] = window.utils.constructObject(window.utils.variableSlice(this.url), "fm_auth$Output.$msg");
}

FMAuthenticationNode.prototype.getMinimizeText = function () {
	return this.name + " " + window.utils.getVariableName(this.source);
}

FMAuthenticationNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.source = this.extention;
	cpynode.output = this.destination;
	cpynode.output = this.output;
	cpynode.url = this.url;
}

// function OAuthAuthenticationeNode(name, x, y, type) {
// 	Node.call(this, name, x, y, type);
// 	this.nodeName = "rsList";
// 	this.nodeLabel = name;
// 	this.shape = new ServicesShape(x, y, name, type);
// 	this.l = ["rc", "rpr"];
// 	// this.domain = '';
// 	// this.methodName = '';
// 	this.outputtype = '';
// 	this.inputTitle = 'Input';
// 	this.inputDefault = 'select';
// 	this.input = [new InputOutObj()];
// 	this.parameterTypes = ['postParams', 'queryParams', 'headers', 'filePath', 'needResponseType']
// 	this.output = [new InputOutObj()];
// 	this.outputTitle = 'Output';
// 	this.outputDefault = 'select';
// 	this.outputTypes = ['status', 'response', 'statuscode'];
// 	this.url = '';
// 	this.urlTitle = 'Url';
// 	// this.uiRenderTags = [ "input", "output", "url", "methodName", "domain"];
// 	this.uiRenderTags = [ "input", "output", "url"];
// }

// OAuthAuthenticationeNode.prototype = Object.create(Node.prototype);

// OAuthAuthenticationeNode.prototype.addEvents = function () {
// 	this.addRestEvents();
// };

// OAuthAuthenticationeNode.prototype.constructParams = function () {
// 	this.parameter.Input = {}
// 	this.parameter.Output = {}
// 	this.parameter.Input["url"] = window.utils.constructObject("$url", this.url);
// 	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
// 	let tempObj = { postParams: '', headers: '', queryParams: '', filePath: '', needResponseType: '' }
// 	let status, responseString, responseXML;
// 	status = responseString = responseXML = '';

// 	for (let obj of this.input) {
// 		if (['headers', 'queryParams'].includes(obj.objType) && obj.name && obj.value && obj.name != '' && obj.value != '') {
// 			tempObj[obj.objType] += (tempObj[obj.objType] == "" ? "" : (obj.objType == 'headers' ? "+'$'+" : "+'&'+")) + obj.name + "+'='+" + obj.value;
// 		} else if (['needResponseType', 'filePath', 'postParams'].includes(obj.objType) && obj.name && obj.name != '') {
// 			tempObj[obj.objType] = obj.name
// 		}

// 	}
// 	if (tempObj.postParams && tempObj.postParams != '')
// 		this.parameter.Input["rest_service$Input.$postParams"] = window.utils.constructObject("rest_service$Input.$postParams", tempObj.postParams);
// 	else
// 		this.parameter.Input["rest_service$Input.$postParams"] = window.utils.constructObj("rest_service$Input.$postParams", "");

// 	if (tempObj.headers && tempObj.headers != "")
// 		this.parameter.Input["rest_service$Input.$headers"] = window.utils.constructObject("rest_service$Input.$headers", tempObj.headers);
// 	else
// 		this.parameter.Input["rest_service$Input.$headers"] = window.utils.constructObj("rest_service$Input.$headers", "");
// 	if (tempObj.queryParams && tempObj.queryParams != "")
// 		this.parameter.Input["rest_service$Input.$queryParams"] = window.utils.constructObject("rest_service$Input.$queryParams", tempObj.queryParams);
// 	else
// 		this.parameter.Input["rest_service$Input.$queryParams"] = window.utils.constructObj("rest_service$Input.$queryParams", "");

// 	if (tempObj.filePath && tempObj.filePath != "")
// 		this.parameter.Input["rest_service$Input.$filePath"] = window.utils.constructObject("rest_service$Input.$filePath", tempObj.filePath);
// 	else
// 		this.parameter.Input["rest_service$Input.$filePath"] = window.utils.constructObj("rest_service$Input.$filePath", "");
// 	if (tempObj.needResponseType && tempObj.needResponseType != "")
// 		this.parameter.Input["rest_service$Input.$needResponseType"] = window.utils.constructObject("rest_service$Input.$needResponseType", tempObj.needResponseType);
// 	else
// 		this.parameter.Input["rest_service$Input.$needResponseType"] = window.utils.constructObj("rest_service$Input.$needResponseType", "");
// 	for (let obj of this.output) {
// 		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
// 			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "rest_service$Output.$" + obj.objType);
// 	}
// }


// OAuthAuthenticationeNode.prototype.copyParams = function (cpynode) {
// 	cpynode.domain = this.domain;
// 	cpynode.methodName = this.methodName;
// 	cpynode.outputtype = this.outputtype;
// 	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
// 	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
// 	cpynode.url = this.url;
// }


function OAuthAuthenticationeNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "oAuthAuthentication";
	this.nodeLabel = "OAuth Authentication";
	this.shape = new AuthenticationShape(x, y, name, type);
	this.urlTitle = 'URL';
	this.url = '';
	this.userTitle = 'UserId';
	this.user = '';
	this.passwordTitle = 'Password';
	this.password = '';
	this.authKeyTitle = 'AuthKey';
	this.authKey = '';
	this.clientIdTitle = 'clientId';
	this.clientId = '';
	this.l = ["rc"];
	this.statusTitle = 'status';
	this.status = '';
	this.msgTitle = 'message';
	this.msg = '';
	this.uiRenderTags = ["user", "password", "url", "authKey", "msg", "status", "clientId"];
}

OAuthAuthenticationeNode.prototype = Object.create(Node.prototype);

OAuthAuthenticationeNode.prototype.addEvents = function () {
	this.addAuthHandler();
};

OAuthAuthenticationeNode.prototype.constructParams = function () {
	if (this.user)
		this.parameter.Input["saml_auth$Input.$userId"] = window.utils.constructObject("saml_auth$Input.$userId", window.utils.variableSlice(this.user));
	if (this.password)
		this.parameter.Input["saml_auth$Input.$password"] = window.utils.constructObject("saml_auth$Input.$password", window.utils.variableSlice(this.password));
	if (this.url)
		this.parameter.Input["saml_auth$Input.$url"] = window.utils.constructObject("saml_auth$Input.$url", window.utils.variableSlice(this.url));
	if (this.clientId)
		this.parameter.Input["saml_auth$Input.$clientId"] = window.utils.constructObject("saml_auth$Input.$clientId", window.utils.variableSlice(this.clientId));
	if (this.status)
		this.parameter.Output[this.status] = window.utils.constructObject(window.utils.variableSlice(this.status), "saml_auth$Output.$outStatus");
	if (this.msg)
		this.parameter.Output[this.msg] = window.utils.constructObject(window.utils.variableSlice(this.msg), "saml_auth$Output.$msg");
	if (this.authKey)
		this.parameter.Output[this.authKey] = window.utils.constructObject(window.utils.variableSlice(this.authKey), "saml_auth$Output.$authKey");
}

OAuthAuthenticationeNode.prototype.copyParams = function (cpynode) {
	cpynode.user = this.user;
	cpynode.password = this.password;
	cpynode.status = this.status;
	cpynode.msg = this.msg;
	cpynode.url = this.url;
	cpynode.clientId = this.clientId;
	cpynode.authKey = this.authKey;
}
function SamlAuthenticationNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "samlAuthentication";
	this.nodeLabel = "Saml Authentication";
	this.shape = new AuthenticationShape(x, y, name, type);
	this.urlTitle = 'URL';
	this.url = '';
	this.userTitle = 'UserId';
	this.user = '';
	this.passwordTitle = 'Password';
	this.password = '';
	this.authKeyTitle = 'AuthKey';
	this.authKey = '';
	this.l = ["rc"];
	this.statusTitle = 'status';
	this.status = '';
	this.msgTitle = 'message';
	this.msg = '';
	this.uiRenderTags = ["user", "password", "url", "authKey", "msg", "status"];
}

SamlAuthenticationNode.prototype = Object.create(Node.prototype);

SamlAuthenticationNode.prototype.addEvents = function () {
	this.addAuthHandler();
};

SamlAuthenticationNode.prototype.constructParams = function () {
	if (this.user)
		this.parameter.Input["saml_auth$Input.$userId"] = window.utils.constructObject("saml_auth$Input.$userId", window.utils.variableSlice(this.user));
	if (this.password)
		this.parameter.Input["saml_auth$Input.$password"] = window.utils.constructObject("saml_auth$Input.$password", window.utils.variableSlice(this.password));
	if (this.url)
		this.parameter.Input["saml_auth$Input.$url"] = window.utils.constructObject("saml_auth$Input.$url", window.utils.variableSlice(this.url));
	if (this.status)
		this.parameter.Output[this.status] = window.utils.constructObject(window.utils.variableSlice(this.status), "saml_auth$Output.$outStatus");
	if (this.msg)
		this.parameter.Output[this.msg] = window.utils.constructObject(window.utils.variableSlice(this.msg), "saml_auth$Output.$msg");
	if (this.authKey)
		this.parameter.Output[this.authKey] = window.utils.constructObject(window.utils.variableSlice(this.authKey), "saml_auth$Output.$authKey");
}

SamlAuthenticationNode.prototype.copyParams = function (cpynode) {
	cpynode.user = this.user;
	cpynode.password = this.password;
	cpynode.status = this.status;
	cpynode.msg = this.msg;
	cpynode.url = this.url;
	cpynode.authKey = this.authKey;
}

function SapAuthenticationNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "sapAuthentication";
	this.nodeLabel = "SAP Authentication";
	this.shape = new AuthenticationShape(x, y, name, type);
	this.urlTitle = 'URL';
	this.url = '';
	this.userTitle = 'UserId';
	this.user = '';
	this.passwordTitle = 'Password';
	this.password = '';
	this.authKeyTitle = 'AuthKey';
	this.authKey = '';
	this.l = ["rc"];
	this.statusTitle = 'status';
	this.status = '';
	this.msgTitle = 'message';
	this.msg = '';
	this.uiRenderTags = ["user", "password", "url", "authKey", "msg", "status"];
}

SapAuthenticationNode.prototype = Object.create(Node.prototype);

SapAuthenticationNode.prototype.addEvents = function () {
	this.addAuthHandler();
};

SapAuthenticationNode.prototype.constructParams = function () {
	if (this.user)
		this.parameter.Input["sap_auth$Input.$userId"] = window.utils.constructObject("sap_auth$Input.$userId", window.utils.variableSlice(this.user));
	if (this.password)
		this.parameter.Input["sap_auth$Input.$password"] = window.utils.constructObject("sap_auth$Input.$password", window.utils.variableSlice(this.password));
	if (this.url)
		this.parameter.Input["sap_auth$Input.$url"] = window.utils.constructObject("sap_auth$Input.$url", window.utils.variableSlice(this.url));
	if (this.status)
		this.parameter.Output[this.status] = window.utils.constructObject(window.utils.variableSlice(this.status), "sap_auth$Output.$outStatus");
	if (this.msg)
		this.parameter.Output[this.msg] = window.utils.constructObject(window.utils.variableSlice(this.msg), "sap_auth$Output.$msg");
	if (this.authKey)
		this.parameter.Output[this.authKey] = window.utils.constructObject(window.utils.variableSlice(this.authKey), "sap_auth$Output.$authKey");
}

SapAuthenticationNode.prototype.copyParams = function (cpynode) {
	cpynode.user = this.user;
	cpynode.password = this.password;
	cpynode.status = this.status;
	cpynode.msg = this.msg;
	cpynode.url = this.url;
	cpynode.authKey = this.authKey;
}

function ODataListNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "odataList";
	this.nodeLabel = name;
	this.shape = new ServicesShape(x, y, name, type);
	this.l = ["rc", "rpr"];
	this.domain = '';
	this.outputtype = '';
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ['header', 'select', 'filter', 'orderby', 'expand', 'search', 'count', 'top', 'skip'];
	this.parameterWithoutKey = ['select', 'filter', 'orderby', 'expand', 'search', 'count', 'top', 'skip'];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.outputTypes = ['status', 'response', 'statuscode'];
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['OD'];
	this.url = '';
	this.urlTitle = 'Url';
	this.uiRenderTags = ["system", "input", "output", "url", "domain"];
}

ODataListNode.prototype = Object.create(Node.prototype);

ODataListNode.prototype.addEvents = function () {
	this.addRestEvents();
};

ODataListNode.prototype.getConnectionDetails = function () {
	let _qryString = "project_name=" + window.application.projectName;
	let postData = {};
	postData["systemname"] = this.system.system;
	servicePostData(BaseURL.configurator + "restservice/metadata", _qryString, postData, this.onPOSTSuccess, this);
}
ODataListNode.prototype.onPOSTSuccess = function (resp, node) {
	if (resp.data.status.type == 'S') {
		let self = node;
		let data = JSON.parse(resp.data.object.parameters);
		node.url = "'" + data.url + "'";
		node.domain = data.domain;
		node.outputtype = data.outputType;
		window.application.refresh();
	}
}

ODataListNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["url"] = window.utils.constructObject("$url", "'" + this.domain + "'+" + this.url);
	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
	let tempObj = { headers: '', queryParams: ''}


	let tmp = 0;
	let tmph = 0;
	for (let obj of this.input) {
		if ('headers' == obj.objType && obj.name && obj.value && obj.name != '' && obj.value != '') {
			tempObj[obj.objType] += tmph != 0 ? "+'$'+" : '';
			tempObj[obj.objType] +=  obj.name + "+'='+" + obj.value;
			tmph++;
		} else {
			if (obj.value && obj.value != '') {
				tempObj['queryParams'] += tmp != 0 ? "+'&'+" : '';
				tempObj['queryParams'] += "'$" + obj.objType + "'+'='+" + obj.value;
				tmp++;
			}
		}
	}
	if (tempObj.headers && tempObj.headers != '')
		this.parameter.Input["odate_list$Input.$headers"] = window.utils.constructObject("odate_list$Input.$headers", tempObj.headers);
	else
		this.parameter.Input["odate_list$Input.$headers"] = window.utils.constructObj("odate_list$Input.$headers", "");

	if (tempObj.queryParams && tempObj.queryParams != "")
		this.parameter.Input["odate_list$Input.$queryParams"] = window.utils.constructObject("odate_list$Input.$queryParams", tempObj.queryParams);
	else
		this.parameter.Input["odate_list$Input.$queryParams"] = window.utils.constructObj("odate_list$Input.$queryParams", "");
	
		
	
	for (let obj of this.output) {
		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "odate_list$Output.$" + obj.objType);
	}
}

ODataListNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

ODataListNode.prototype.copyParams = function (cpynode) {
	cpynode.domain = this.domain;
	cpynode.outputtype = this.outputtype;
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.url = this.url;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}

function ODataCreateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "odataCreate";
	this.nodeLabel = name;
	this.shape = new ServicesShape(x, y, name, type);
	this.l = ["rc", "rpr"];
	this.domain = '';
	this.outputtype = '';
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ['header', 'postparams'];
	this.parameterWithoutKey = ['postparams'];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.outputTypes = ['status', 'response', 'statuscode'];
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['OD'];
	this.url = '';
	this.urlTitle = 'Url';
	this.uiRenderTags = ["system", "input", "output", "url", "domain"];
}

ODataCreateNode.prototype = Object.create(Node.prototype);

ODataCreateNode.prototype.addEvents = function () {
	this.addRestEvents();
};

ODataCreateNode.prototype.getConnectionDetails = function () {
	let _qryString = "project_name=" + window.application.projectName;
	let postData = {};
	postData["systemname"] = this.system.system;
	servicePostData(BaseURL.configurator + "restservice/metadata", _qryString, postData, this.onPOSTSuccess, this);
}
ODataCreateNode.prototype.onPOSTSuccess = function (resp, node) {
	if (resp.data.status.type == 'S') {
		let self = node;
		let data = JSON.parse(resp.data.object.parameters);
		node.url = "'" + data.url + "'";
		node.domain = data.domain;
		node.outputtype = data.outputType;
		window.application.refresh();
	}
}

ODataCreateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["url"] = window.utils.constructObject("$url", "'" + this.domain + "'+" + this.url);
	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
	let tempObj = { header: '', postparams: ''};

	let tmph = 0;
	for (let obj of this.input) {
		if ('header' == obj.objType && obj.name && obj.value && obj.name != '' && obj.value != '') {
			tempObj[obj.objType] += tmph != 0 ? "+'$'+" : '';
			tempObj[obj.objType] += obj.name + "+'='+" + obj.value;
			tmph++;
		} else 
			tempObj[obj.objType] = obj.value;
	}
	if (tempObj.header && tempObj.header != '')
		this.parameter.Input["odate_create$Input.$header"] = window.utils.constructObject("odate_create$Input.$header", tempObj.header);
	else
		this.parameter.Input["odate_create$Input.$header"] = window.utils.constructObj("odate_create$Input.$header", "");

	if (tempObj.postparams && tempObj.postparams != "")
		this.parameter.Input["odate_create$Input.$postparams"] = window.utils.constructObject("odate_create$Input.$postparams", tempObj.postparams);
	else
		this.parameter.Input["odate_create$Input.$postparams"] = window.utils.constructObj("odate_create$Input.$postparams", "");
	
	for (let obj of this.output) {
		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "odate_create$Output.$" + obj.objType);
	}
}

ODataCreateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

ODataCreateNode.prototype.copyParams = function (cpynode) {
	cpynode.domain = this.domain;
	cpynode.outputtype = this.outputtype;
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.url = this.url;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}

function ODataUpdateNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "odataUpdate";
	this.nodeLabel = name;
	this.shape = new ServicesShape(x, y, name, type);
	this.l = ["rc", "rpr"];
	this.domain = '';
	this.outputtype = '';
	this.inputTitle = 'Input';
	this.inputDefault = 'select';
	this.input = [new InputOutObj()];
	this.parameterTypes = ['header', 'postparams'];
	this.parameterWithoutKey = ['postparams'];
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.outputTypes = ['status', 'response', 'statuscode'];
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['OD'];
	this.url = '';
	this.urlTitle = 'Url';
	this.uiRenderTags = ["system", "input", "output", "url", "domain"];
}

ODataUpdateNode.prototype = Object.create(Node.prototype);

ODataUpdateNode.prototype.addEvents = function () {
	this.addRestEvents();
};

ODataUpdateNode.prototype.getConnectionDetails = function () {
	let _qryString = "project_name=" + window.application.projectName;
	let postData = {};
	postData["systemname"] = this.system.system;
	servicePostData(BaseURL.configurator + "restservice/metadata", _qryString, postData, this.onPOSTSuccess, this);
}
ODataUpdateNode.prototype.onPOSTSuccess = function (resp, node) {
	if (resp.data.status.type == 'S') {
		let self = node;
		let data = JSON.parse(resp.data.object.parameters);
		node.url = "'" + data.url + "'";
		node.domain = data.domain;
		node.outputtype = data.outputType;
		window.application.refresh();
	}
}

ODataUpdateNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["url"] = window.utils.constructObject("$url", "'" + this.domain + "'+" + this.url);
	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
	let tempObj = { header: '', postparams: ''};

	let tmph = 0;
	for (let obj of this.input) {
		if ('header' == obj.objType && obj.name && obj.value && obj.name != '' && obj.value != '') {
			tempObj[obj.objType] += tmph != 0 ? "+'$'+" : '';
			tempObj[obj.objType] += obj.name + "+'='+" + obj.value;
			tmph++;
		} else 
			tempObj[obj.objType] = obj.value;
	}
	if (tempObj.header && tempObj.header != '')
		this.parameter.Input["odate_update$Input.$header"] = window.utils.constructObject("odate_update$Input.$header", tempObj.header);
	else
		this.parameter.Input["odate_update$Input.$header"] = window.utils.constructObj("odate_update$Input.$header", "");

	if (tempObj.postparams && tempObj.postparams != "")
		this.parameter.Input["odate_update$Input.$postparams"] = window.utils.constructObject("odate_update$Input.$postparams", tempObj.postparams);
	else
		this.parameter.Input["odate_update$Input.$postparams"] = window.utils.constructObj("odate_update$Input.$postparams", "");
	
	for (let obj of this.output) {
		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "odate_update$Output.$" + obj.objType);
	}
}

ODataUpdateNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

ODataUpdateNode.prototype.copyParams = function (cpynode) {
	cpynode.domain = this.domain;
	cpynode.outputtype = this.outputtype;
	cpynode.input = window.utils.cloneObjs('InputOutObj', this.input);
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.url = this.url;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}


function ODataDeleteNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "odataDelete";
	this.nodeLabel = name;
	this.shape = new ServicesShape(x, y, name, type);
	this.l = ["rc", "rpr"];
	this.domain = '';
	this.outputtype = '';
	this.output = [new InputOutObj()];
	this.outputTitle = 'Output';
	this.outputDefault = 'select';
	this.outputTypes = ['status', 'response', 'statuscode'];
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['OD'];
	this.url = '';
	this.urlTitle = 'Url';
	this.uiRenderTags = ["system", "output", "url", "domain"];
}

ODataDeleteNode.prototype = Object.create(Node.prototype);

ODataDeleteNode.prototype.addEvents = function () {
	this.addRestEvents();
};

ODataDeleteNode.prototype.getConnectionDetails = function () {
	let _qryString = "project_name=" + window.application.projectName;
	let postData = {};
	postData["systemname"] = this.system.system;
	servicePostData(BaseURL.configurator + "restservice/metadata", _qryString, postData, this.onPOSTSuccess, this);
}
ODataDeleteNode.prototype.onPOSTSuccess = function (resp, node) {
	if (resp.data.status.type == 'S') {
		let self = node;
		let data = JSON.parse(resp.data.object.parameters);
		node.url = "'" + data.url + "'";
		node.domain = data.domain;
		node.outputtype = data.outputType;
		window.application.refresh();
	}
}

ODataDeleteNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	this.parameter.Input["url"] = window.utils.constructObject("$url", "'" + this.domain + "'+" + this.url);
	this.parameter.Input["outputtype"] = window.utils.constructObj("$outputtype", this.outputtype);
	
	for (let obj of this.output) {
		if (obj.value && obj.objType && obj.value != '' && obj.objType != '')
			this.parameter.Output[obj.value] = window.utils.constructObject(obj.value, "odate_update$Output.$" + obj.objType);
	}
}

ODataDeleteNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

ODataDeleteNode.prototype.copyParams = function (cpynode) {
	cpynode.domain = this.domain;
	cpynode.outputtype = this.outputtype;
	cpynode.output = window.utils.cloneObjs('InputOutObj', this.output);
	cpynode.url = this.url;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}

function ConflictQueueNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.l = ['rc', 'rd'];
	this.nodeName = "conflictQueue";
	this.nodeLabel = "Update with Check";
	this.shape = new FreedomDBShape(x, y, name, type);
	this.table = "";
	this.tableDefault = 'select table';
	this.tableObj = null;
	this.column = [new FDBColumn()];
	this.columnDefault = 'select';
	this.columnTitle = 'Set';
	this.to = '';
	this.toTitle = 'to';
	this.where = [new FDBWhere()];
	this.whereTitle = 'where';
	this.whereNameDefault = 'select';
	this.whereOperatorDefault = 'select';
	this.operatorsList = ['=', '>=', '<=', '<', '>', 'LIKE', '<>'];
	this.selectColumn = "select digest";
	this.uiRenderTags = ["table", "tableObj", "column", 'to', 'where', 'selectColumn'];
	this.buildedName = 'sql_insert';
}
ConflictQueueNode.prototype = Object.create(Node.prototype);

ConflictQueueNode.prototype.setValue = function (key, value) {
	this[key] = value;
};
ConflictQueueNode.prototype.getValue = function (key) {
	return this[key];
};


ConflictQueueNode.prototype.addEvents = function () {
	this.addFreedomDbHandler();
};
ConflictQueueNode.prototype.constructParams = function () {
	this.parameter.Input = {}
	this.parameter.Output = {}
	this.parameter.Input["Name"] = window.utils.constructObj("$Name", this.table);

	let secCol = this.selectColumn != '' || this.selectColumn != 'select digest' ? this.selectColumn : "" ;
	let setVals = '';
	let columns = '';
	for (let col in this.column) {
		let value = '';
		if (typeof (this.column[col].value) == 'string') {
			value = this.column[col].value;
		}
		else {
			value = this.column[col].value.constructParams();
		}
		if (this.column[col].column) {
			columns += window.utils.variableSlice(value);
			setVals += "'" + this.column[col].column + " = \"'+" + window.utils.variableSlice(value);
			setVals += ((col != this.column.length - 1) ? "+'\" , '+" : "+'\"'");
			columns += (col != this.column.length - 1) ? "+" : "";
		}
	}

	this.parameter.Input["conflict_update$Input.$set"] = window.utils.constructObject("conflict_update$Input.$set" , window.utils.variableSlice(setVals));
	this.parameter.Input["conflict_update$Input.$column"] = window.utils.constructObj("conflict_update$Input.$column" , window.utils.variableSlice(secCol));
	this.parameter.Input["conflict_update$Input.$setValues"] = window.utils.constructObj("conflict_update$Input.$setValues" , window.utils.variableSlice(columns));

	let whereVals = ""
	for (let idx in this.where) {
		let value = '';
		if (typeof (this.where[idx].value) == 'string') {
			value = this.where[idx].value;
		}
		else {
			value = this.where[idx].value.constructParams();
		}
		if (this.where[idx].column) {
			whereVals += " '" + this.where[idx].column + " " + this.where[idx].operator + " \"'+" + window.utils.variableSlice(value);
			whereVals += ((idx != this.where.length - 1) ? "+'\" and '+" : "+'\"'");
		}
	}

	this.parameter.Input["conflict_update$Input.$where"] = window.utils.constructObject("conflict_update$Input.$where" , window.utils.variableSlice(whereVals));

	if (this.to) {
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "conflict_update$Output.$key");
	}
}
ConflictQueueNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.table;
}

ConflictQueueNode.prototype.copyParams = function (cpynode) {
	cpynode.table = this.table;
	cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.column = window.utils.cloneObjs('FDBColumn', this.column);
	cpynode.where = window.utils.cloneObjs('FDBWhere', this.where);
}

function FDBColumn() {
	this.column = '';
	this.name = '';
	this.scope = '';
	this.type = '';
	this.value = '';
}
FDBColumn.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
}

function FDBWhere() {
	this.operator = '=';
	this.column = '';
	this.name = '';
	this.scope = '';
	this.type = '=';
	this.child = {};
	this.value = '';
	this.setValue = function (key, value) {
		this[key] = value;
	};
	this.getValue = function (key) {
		return this[key];
	};
}

function FunctionSort() {
	this.sortColumn = "";
	this.sortOrder = "asc";
}

FunctionSort.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

function FunctionVariable() {
	this.name = "";
	this.scope = "";
	this.type = "";
}

FunctionVariable.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

// export function VariableObj() {
// 	this.name = "";
// 	this.scope = "";
// 	this.type = "";
// 	this.value = "";
// }

// VariableObj.prototype = {
// 	setVariableObj: function (name, scope, type, value){
// 		this.name = name;
// 		this.scope = scope;
// 		this.type = type;
// 		this.value = value;
// 	},
// 	setValue: function (key, value) {
// 		this[key] = value;
// 	},
// 	getValue: function (key) {
// 		return this[key];
// 	}
// };

function InputOutObj() {
	this.name = "";
	this.value = "";
	this.objType = "";
	this.type = "";
	this.bosVarType = "";
	this.Xpath = "";
	this.tableObj = {};
}

InputOutObj.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	}
};

function UserProperties() {
	this.name = "";
	this.values = [];
	this.value = "";
	this.propertyUuid = "";
}

UserProperties.prototype = {
	setValue: function (key, value) {
		this[key] = value;
	},
	getValue: function (key) {
		return this[key];
	},
	setProperty: function (name, value, propertyUuid){
		this.name = name;
		this.values = JSON.parse(value);
		this.propertyUuid = propertyUuid;
	}
};

function Workspace() {
	this.InputOutObj = InputOutObj;
	this.FDBColumn = FDBColumn;
	this.FunctionVariable = FunctionVariable;
	this.FunctionSort = FunctionSort;
	this.FDBWhere = FDBWhere;
	this.System = System;
	this.Functions = Functions;
}

function WorkflowRestartNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowRestart";
	this.nodeLabel = "Re-start";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	//this.destinationTitle = 'RequestId';
	//this.destination = '';
	this.uiRenderTags = ["source"];
	// this.shape.calculateHeight(this);
}

WorkflowRestartNode.prototype = Object.create(Node.prototype);

WorkflowRestartNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowRestartNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
	//if (this.destination)
	//	this.parameter.Input["requestid"] = window.utils.constructObject("$requestid", window.utils.variableSlice(this.destination));
	//this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

WorkflowRestartNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowRestartNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	//cpynode.destination = this.destination;
}

function WorkflowRetryNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowRetry";
	this.nodeLabel = "Re-try";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'Data';
	this.destination = '';
	this.uiRenderTags = ["source","destination"];
	// this.shape.calculateHeight(this);
}

WorkflowRetryNode.prototype = Object.create(Node.prototype);

WorkflowRetryNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowRetryNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["data"] = window.utils.constructObject("$data", window.utils.variableSlice(this.destination));
	//this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

WorkflowRetryNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowRetryNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
}


function WorkflowRevertNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowRevert";
	this.nodeLabel = "Revert";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	this.destinationTitle = 'Data';
	this.destination = '';
	this.uiRenderTags = ["source","destination"];
	
	// this.shape.calculateHeight(this);
}

WorkflowRevertNode.prototype = Object.create(Node.prototype);

WorkflowRevertNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowRevertNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
	if (this.destination)
		this.parameter.Input["data"] = window.utils.constructObject("$data", window.utils.variableSlice(this.destination));
	//this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

WorkflowRevertNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowRevertNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.destination = this.destination;
}

function WorkflowAssignToNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowAssignTo";
	this.nodeLabel = "Assign";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'Workitem';
	this.source = '';
	this.l = ["rc"];
	this.table = "";
	this.tableDefault = 'Select';
	this.tableObj = null;
	//this.selectColumnsLists = [];
	this.assigndata = '';
	this.uiRenderTags = ["source","table", "tableObj","assigndata"];
	
	
}

WorkflowAssignToNode.prototype = Object.create(Node.prototype);

WorkflowAssignToNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowAssignToNode.prototype.getUsersOrRoles = function () {
	if (this.selectColumnsLists.length == 0)
		return "";
	else {
		let _val = '';
		for (let i = 0; i < this.selectColumnsLists.length; i++) {
			_val += (_val == '' ? '' : ',') + this.selectColumnsLists[i];
		}
		return _val;
	}
};

WorkflowAssignToNode.prototype.constructParams = function () {
		this.parameter.Input = {}
		this.parameter.Output = {}
		//let data = this.getUsersOrRoles()
		if(this.source){
			this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
		}
			
		if(this.data!='')
			this.parameter.Input["data"] = window.utils.constructObject("$data", window.utils.variableSlice(this.assigndata));

		if(this.table){			
			this.parameter.Input["assignTo"] = window.utils.constructObj("$assignTo", (this.table == "User") ? window.utils.variableSlice("user") : window.utils.variableSlice("role"));
		}
	
}

WorkflowAssignToNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowAssignToNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.assigndata = this.assigndata;
	//if (this.selectColumnsLists)
		//cpynode.selectColumnsLists = window.utils.cloneObjs('', this.selectColumnsLists);
}

function WorkflowContinueNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowContinue";
	this.nodeLabel = "Continue";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	//this.destinationTitle = 'RequestId';
	//this.destination = '';
	this.uiRenderTags = ["source"];
	// this.shape.calculateHeight(this);
}

WorkflowContinueNode.prototype = Object.create(Node.prototype);

WorkflowContinueNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowContinueNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
	//if (this.destination)
	//	this.parameter.Input["requestid"] = window.utils.constructObject("$requestid", window.utils.variableSlice(this.destination));
	//this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

WorkflowContinueNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowContinueNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	//cpynode.destination = this.destination;
}

function WorkflowEndNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowEnd";
	this.nodeLabel = "End";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	//this.destinationTitle = 'RequestId';
	//this.destination = '';
	this.uiRenderTags = ["source"];
	// this.shape.calculateHeight(this);
}

WorkflowEndNode.prototype = Object.create(Node.prototype);

WorkflowEndNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowEndNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
	//if (this.destination)
	//	this.parameter.Input["requestid"] = window.utils.constructObject("$requestid", window.utils.variableSlice(this.destination));
	//this.parameter.Input["drive_copy$Input.$overwrite"] = window.utils.constructObject("drive_copy$Input.$overwrite", 'false');
}

WorkflowEndNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowEndNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	//cpynode.destination = this.destination;

}
function WorkflowArchiveNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowArchive";
	this.nodeLabel = "Archive";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'WorkflowId';
	this.source = '';
	this.l = ["rc"];
	this.uiRenderTags = ["source"];
}

WorkflowArchiveNode.prototype = Object.create(Node.prototype);

WorkflowArchiveNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowArchiveNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["workflowid"] = window.utils.constructObject("$workflowid", window.utils.variableSlice(this.source));
}

WorkflowArchiveNode.prototype.getMinimizeText = function () {
	return this.name + " workflowid " + window.utils.getVariableName(this.source);
}
WorkflowArchiveNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
}

function WorkflowStartNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "workflowStart";
	this.nodeLabel = "Start";
	this.shape = new WorkflowShape(x, y, name, type);
	this.sourceTitle = 'Function';
	this.source = '';
	this.l = ["rc"];
	this.table = "";
	this.tableDefault = 'Select';
	this.tableObj = null;
	//this.selectColumnsLists = [];
	this.assigndata = '';
	this.uiRenderTags = ["source","table", "tableObj","assigndata"];

}

WorkflowStartNode.prototype = Object.create(Node.prototype);

WorkflowStartNode.prototype.addEvents = function () {
	this.addWorkflowHandler();
};

WorkflowStartNode.prototype.constructParams = function () {
	if (this.source)
		this.parameter.Input["function"] = window.utils.constructObject("$function", window.utils.variableSlice(this.source));

	if(this.data!='')
		this.parameter.Input["data"] = window.utils.constructObject("$data", window.utils.variableSlice(this.assigndata));

	if(this.table)
		this.parameter.Input["assignTo"] = window.utils.constructObj("$assignTo", (this.table == "User") ? window.utils.variableSlice("user") : window.utils.variableSlice("role"));

	
}

WorkflowStartNode.prototype.getMinimizeText = function () {
	return this.name + " function " + window.utils.getVariableName(this.source);
}
WorkflowStartNode.prototype.copyParams = function (cpynode) {
	cpynode.source = this.source;
	cpynode.table = this.table;
	if (this.tableObj)
		cpynode.tableObj = window.utils.cloneObjs('', this.tableObj);
	cpynode.assigndata = this.assigndata;
}

function LDAPSearchNode(name, x, y, type) {
	Node.call(this, name, x, y, type);
	this.nodeName = "ldapsearch";
	this.nodeLabel = "LDAP Search";
	this.shape = new LDAPShape(x, y, name, type);
	this.system = new System("", "D", "", "", "");
	this.systemTypes = ['LDAP'];
	this.l = ["rc"];
	this.basedn = '';
	this.basednTitle = 'Base DN';
	this.filter = '';
	this.filterTitle = 'Filter';
	this.to = '';
	this.toTitle = 'to';
	this.selectColumnsLists=["cn","company","name","title","co","mail"];
	this.uiRenderTags = ["basedn", "filter", "to", "system"];
	// this.shape.calculateHeight(this);
}

LDAPSearchNode.prototype = Object.create(Node.prototype);

LDAPSearchNode.prototype.addEvents = function () {
	this.addRestEvents();
}

LDAPSearchNode.prototype.constructParams = function () {
	this.parameter.Input["System"] = window.utils.constructObj("$System", this.system.system);
	if (this.basedn)
		this.parameter.Input["basedn"] = window.utils.constructObject("$basedn", window.utils.variableSlice(this.basedn));
	if (this.filter)
		this.parameter.Input["filter"] = window.utils.constructObject("$filter", window.utils.variableSlice(this.filter));	
	if (this.to)
		this.parameter.Output[this.to] = window.utils.constructObject(this.to, "ldapsearch$Output.$Table");
}

LDAPSearchNode.prototype.getMinimizeText = function () {
	return this.name + " " + this.system.system;
}

LDAPSearchNode.prototype.copyParams = function (cpynode) {
	cpynode.basedn = this.basedn;
	cpynode.filter = this.filter;
	cpynode.to = this.to;
	cpynode.system = window.utils.cloneObjs('System', this.system);
}


export {
	Node,
	LaneNode,
	SapTransactionNode,
	SapStopTransactionNode,
	SapCommitNode,
	SapRollbackNode,
	AssignmentNode,
	IfNode,
	MathNode,
	ConditionalNode,
	IfElseNode,
	LoopNode,
	RepeaterNode,
	TerminateNode,
	BreakNode,
	RowNode,
	ReferenceNode,
	WebServiceNode,
	RestServiceNode,
	LookupNode,
	SortNode,
	BossNode,
	CustomNode,
	MailNode,
	PushNode,
	DeviceSyncNode,
	MSDListNode,
	MSDCreateNode,
	MSDUpdateNode,
	MSDDeleteNode,
	GenerateFileNode,
	DownloadFile,
	DriveUploadNode,
	DriveDownloadNode,
	DriveDeleteNode,
	DriveMoveNode,
	DriveCopyNode,
	DriveListNode,
	FTPDownloadNode,
	FileParserNode,
	CreateUserNode,
	UpdateUserNode,
	AssignGroupNode,
	BluetoothListNode,
	BluetoothPairingNode,
	BluetoothListenerNode,
	BarcodeScannerNode,
	BluetoothConnectNode,
	BluetoothDisconnectNode,
	BluetoothUnpairingNode,
	BluetoothCommandNode,
	SapFunctionNode,
	System,
	Config,
	FunctionInput,
	FunctionOutput,
	FreedomDBSelectNode,
	FreedomDBCreateNode,
	FreedomDBUpdateNode,
	FreedomDBDeleteNode,
	DBAdapterSelectNode,
	DBAdapterCreateNode,
	DBAdapterUpdateNode,
	DBAdapterDeleteNode,
	AddRowNode,
	// SapCommitNode,
	FunctionNode,
	SFListNode,
	SFCreateNode,
	SFUpdateNode,
	SFDeleteNode,
	FtpNode,
	FDBColumn,
	InputOutObj,
	FunctionVariable,
	FunctionSort,
	FDBWhere,
	Workspace,
	EmptyNode,
	FreedomDBQueryNode,
	DBAdapterQueryNode,
	CommentBlockNode,
	UserProperties,
	SNFCreateNode,
	SNFUpdateNode,
	SNFSelectNode,
	SNFDeleteNode,
	FreedomDBQuerywithoutParamNode,
	DBAdapterQueryWithoutParamNode,
	PdfGenerateNode,
	RemoveGroupNode,
	FMAuthenticationNode as FMAuthNode,
	OAuthAuthenticationeNode,
	SamlAuthenticationNode,
	SapAuthenticationNode,
	ODataListNode,
	ODataCreateNode,
	ODataUpdateNode,
	ODataDeleteNode,
	ConflictQueueNode,
	WorkflowRestartNode,
	WorkflowRetryNode,
	WorkflowContinueNode,
	WorkflowRevertNode,
	WorkflowEndNode,
	WorkflowArchiveNode,
	UserListNode,
	UserDetailNode,
	UserPropertyData,
	ResetPasswordNode,
	PasswordPolicyNode,
	WorkflowAssignToNode,
	RoleListNode,
	LDAPSearchNode,
	GenerateQRCode,
	WorkflowStartNode
  }

